<template>
  <!-- 首页用户登录页面 -->
  <div>
    <el-form v-if="formModule == 1" :model="loginForm" :rules="loginFormRules" ref="loginForm" class="form_module"
      key="login">
      <h3 class="form_title">欢迎登录</h3>
      <el-form-item prop="account">
        <el-input v-model.trim="loginForm.account" placeholder="手机号" @keyup.enter.native="postLogin"
          prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input show-password v-model.trim="loginForm.password" placeholder="密码" @keyup.enter.native="postLogin"
          prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-form-item prop="phone_code" v-if="verification == 1">
        <div class="form_code_div">
          <div class="form_code_input">
            <el-input v-model.trim="loginForm.phone_code" placeholder="请输入验证码" size="mini"
              @keyup.enter.native="postLogin" prefix-icon="el-icon-lock"></el-input>
          </div>
          <div class="form_code_button">
            <el-button type="primary" size="mini" :disabled="phoneData.phone_loading" @click="getPhoneCode_3()"
              class="form_button_3">
              {{ phoneData.codeText }}
            </el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="postLogin" class="form_button_1" :loading="loading_1">
          登录
        </el-button>
      </el-form-item>
      <div class="form_bottom">
        <!-- <span class="form_button_btn_2">没有账号，去 <span class="form_button_2" @click="handleSignIn()"> 注册</span> </span> -->
        <span @click="forgetPasswordEvent()" class="form_button_2">忘记密码</span>
      </div>
    </el-form>

    <el-form v-if="formModule == 2" :model="forgetPasswordForm" :rules="forgetPasswordFormRules"
      ref="forgetPasswordForm" class="form_module" key="forgetPassword">
      <h3 class="form_title">找回密码</h3>
      <el-form-item prop="phone">
        <el-input v-model.trim="forgetPasswordForm.phone" placeholder="请输入手机号" size="mini"
          @keyup.enter.native="postResetPassword" prefix-icon="el-icon-mobile-phone"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <div class="form_code_div">
          <div class="form_code_input">
            <el-input v-model.trim="forgetPasswordForm.code" placeholder="请输入验证码" size="mini"
              @keyup.enter.native="postResetPassword" prefix-icon="el-icon-lock"></el-input>
          </div>
          <div class="form_code_button">
            <el-button type="primary" size="mini" :disabled="phoneData.phone_loading" @click="getPhoneCode_1()"
              class="form_button_3">
              {{ phoneData.codeText }}
            </el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <el-input show-password v-model.trim="forgetPasswordForm.password" :placeholder="password_name" size="mini"
          @keyup.enter.native="postResetPassword" prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-form-item prop="configPass">
        <el-input show-password v-model.trim="forgetPasswordForm.configPass" placeholder="请再次确认密码" size="mini"
          @keyup.enter.native="postResetPassword" prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="postResetPassword" class="form_button_1"
          :loading="loading_2">重置密码</el-button>
      </el-form-item>
      <div class="form_bottom">
        <span></span>
        <span @click="backLogin()" class="form_button_2">返回登录</span>
      </div>
    </el-form>

    <el-form v-if="formModule == 3" :model="signInForm" :rules="signInFormRules" ref="signInForm" class="form_module"
      key="forgetPassword">
      <h3 class="form_title">用户注册</h3>
      <el-form-item prop="realname">
        <el-input v-model.trim="signInForm.realname" placeholder="请输入真实姓名" size="mini"
          @keyup.enter.native="postResetSignIn" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input v-model.trim="signInForm.phone" placeholder="请输入手机号" size="mini" @keyup.enter.native="postResetSignIn"
          prefix-icon="el-icon-mobile-phone"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input show-password v-model.trim="signInForm.password" :placeholder="'请输入密码（' + password_name + '）'"
          size="mini" @keyup.enter.native="postResetSignIn" prefix-icon="el-icon-lock">
        </el-input>
      </el-form-item>
      <el-form-item prop="configPass">
        <el-input show-password v-model.trim="signInForm.configPass" placeholder="请再次确认密码" size="mini"
          @keyup.enter.native="postResetSignIn" prefix-icon="el-icon-lock">
        </el-input>
      </el-form-item>
      <el-form-item prop="phone_code">
        <div class="form_code_div">
          <div class="form_code_input">
            <el-input v-model.trim="signInForm.phone_code" placeholder="请输入验证码" size="mini"
              @keyup.enter.native="postResetSignIn" prefix-icon="el-icon-lock"></el-input>
          </div>
          <div class="form_code_button">
            <el-button type="primary" size="mini" :disabled="phoneData.phone_loading" @click="getPhoneCode_2()"
              class="form_button_3">
              {{ phoneData.codeText }}
            </el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="postResetSignIn" class="form_button_1"
          :loading="loading_3">注册</el-button>
      </el-form-item>
      <div class="form_bottom">
        <span></span>
        <span @click="backLogin()" class="form_button_2">返回登录</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import CryptoJS from "./crypto";
const key = CryptoJS.enc.Utf8.parse("Dcit400887638100"); //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse("Dcit400887638100"); //十六位十六进制数作为密钥偏移量
const cryptoEncryption = (str) => {
  let encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  return encodeURIComponent(encrypted);
};
const qs = require("qs");
import { localGet, localSet, localRemove } from "@/utils/utils.js";
import { debounce, throttle, validCellIphome } from "@/utils/utils.js";
export default {
  props: {},
  data() {
    //验证手机号码
    const validatePhoneAndIdcard = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (value.length < 11) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    // 校验手机号码
    const validateForgetPasswordPhone = (rule, value, callback) => {
      if (!validCellIphome(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    //验证找回密码两次输入密码是否一致
    const validateForgetPasswordAgain_1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.forgetPasswordForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    //验证找回密码两次输入密码是否一致
    const validateForgetPasswordAgain_2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.signInForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    // 校验修改密码
    const passwordValidator = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码（" + this.password_name + "）"));
      } else {
        callback();
      }
    };
    return {
      clear_setInterval_id: null,
      phoneData: {
        codeText: "获取验证码",
        countDownNumber: 60, //获取手机验证码倒计时
        phone_loading: false, //获取手机验证码按钮是否可以点击
      },
      //登录模块类型（1.用户登录 2.找回密码）
      formModule: 1,
      //登录表单参数
      loginForm: {
        account: "",
        password: "",
      },
      //登录表单验证
      loginFormRules: {
        account: [
          {
            required: true,
            validator: validatePhoneAndIdcard,
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        phone_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "6 位验证码", trigger: "blur" },
        ],
      },
      //忘记密码表单参数
      forgetPasswordForm: {
        phone: "",
        code: "",
        password: "",
        configPass: "",
      },
      //忘记密码表单验证
      //【1级】6-18位字符。【2级】6-18位字符，含数字+英文。【3级】8-18位字符，含数字+大小写英文+特殊符号。
      forgetPasswordFormRules: {
        phone: [
          {
            required: true,
            validator: validateForgetPasswordPhone,
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "6 位验证码", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            validator: passwordValidator,
            trigger: "blur",
          },
        ],
        configPass: [
          {
            required: true,
            validator: validateForgetPasswordAgain_1,
            trigger: "blur",
          },
        ],
      },
      loading_1: false, //登录按钮
      loading_2: false, //密码编辑
      loading_3: false, //用户注册
      // 用户注册
      signInForm: {
        realname: "",
        phone: "",
        phone_code: "",
        password: "",
        configPass: "",
      },
      //用户注册 表单验证
      signInFormRules: {
        realname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          {
            min: 2,
            max: 14,
            message: "长度在 2 到 14 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validateForgetPasswordPhone,
            trigger: "blur",
          },
        ],
        phone_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "6 位验证码", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            validator: passwordValidator,
            trigger: "blur",
          },
        ],
        configPass: [
          {
            required: true,
            validator: validateForgetPasswordAgain_2,
            trigger: "blur",
          },
        ],
      },
      verification: null, //1.开启验证码 2.关闭验证码
      password_level: "",
      password_name: "",
    };
  },
  created() {
    this.getPwdLevel()
    this.getIsOpenCode();
  },
  mounted() { },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
    if (this.clear_setInterval_id) {
      clearInterval(this.clear_setInterval_id);
    }
  },
  methods: {
    getPwdLevel() {
      this.$axios
        .get("/Password/getPwdLevel")
        .then((res) => {
          this.password_level = res.data.data.password_level
          if (this.password_level == 1) {
            this.password_name = '6-18位密码'
          }
          if (this.password_level == 2) {
            this.password_name = '6-18位密码，含数字+英文'
          }
          if (this.password_level == 3) {
            this.password_name = '8-18位密码，含数字+大小写英文+特殊符号'
          }
          //【1级】6-18位字符。【2级】6-18位字符，含数字+英文。【3级】8-18位字符，含数字+大小写英文+特殊符号。
        });
    },
    // 获取是否开启验证码
    getIsOpenCode() {
      this.$axios
        .get("/index/SystemSecurityConfig/SystemSecurityConfig/getConfig")
        .then((res) => {
          const resData = res.data;
          if (resData.code === 900) {
            this.verification = resData.data.verification;
          }
        });
    },
    // 登录成功返回的
    succeedReturned(dataObj) {
      // UC登录2022年11月17日09点17分
      let hostName = location.host;
      let domes = "https://uc.dc-it.cn";
      if (hostName.indexOf("teachercloud.com.cn") != -1) {
        domes = "https://dcuc.teachercloud.com.cn";
      }
      // 添加UC登录存储cookies 2022年11月4日15点37分
      $("body").append(
        '<iframe id="autologin" src=' +
        domes +
        "/api/Login/" +
        dataObj.auth +
        ' style="width: 500px;height: 500px;z-index: 99999999;border: 10px solid #333;opacity: 0;"></iframe>'
      );
      $("#autologin").on("load", function () {
        $(this).remove();
      });
      // uc登录
      // let iframe = document.getElementById('iframe');
      // console.log(iframe);
      // iframe.contentWindow.postMessage({
      //   "type": "login",
      //   "auth": dataObj.auth,
      // }, '*');
      sessionStorage.removeItem("webAll");
      // localRemove("webAll");
      localSet("unserInfoData", dataObj);
      localStorage.setItem("aes_code", dataObj.aes_code);
      localStorage.setItem("user_name", dataObj.username);
      localStorage.setItem("token", dataObj.token);
      localStorage.setItem("id", dataObj.id);
      this.isShow = 1;
      localStorage.setItem("isShow", this.isShow);
      localStorage.setItem("photo_url", dataObj.photo_url);
      setTimeout(() => {
        this.loading_1 = false;
        this.loading_3 = false;
        if (this.loginForm.appId1 && this.loginForm.url) {
          this.$router.push({
            path: this.loginForm.url,
          });
        } else {
          if (localStorage.getItem("oldUrl")) {
            let url = JSON.parse(localStorage.getItem("oldUrl"));
            setTimeout(() => {
              localStorage.removeItem("oldUrl");
              window.location.href = url;
            }, 500);
          } else {
            this.$router.go(-1);
          }
        }
      }, 500);
      // 打开投屏登录
      // window.open(`DCYJTP://Type:0_User:${escape(dataObj.username)}`);
    },
    //忘记密码切换
    forgetPasswordEvent() {
      this.formModule = 2;
      this.$set(this.loginForm, "account", "");
      this.$set(this.loginForm, "password", "");
      this.$set(this.loginForm, "phone_code", "");
      this.$set(this.phoneData, "codeText", "获取验证码");
      this.$set(this.phoneData, "countDownNumber", "60");
      this.$set(this.phoneData, "phone_loading", false);

      if (this.clear_setInterval_id) {
        clearInterval(this.clear_setInterval_id);
      }
    },
    // 去注册
    handleSignIn() {
      this.formModule = 3;
      this.$set(this.loginForm, "account", "");
      this.$set(this.loginForm, "password", "");
      this.$set(this.loginForm, "phone_code", "");
      this.$set(this.phoneData, "codeText", "获取验证码");
      this.$set(this.phoneData, "countDownNumber", "60");
      this.$set(this.phoneData, "phone_loading", false);

      if (this.clear_setInterval_id) {
        clearInterval(this.clear_setInterval_id);
      }
    },
    //登录切换
    backLogin() {
      this.formModule = 1;
      this.$set(this.forgetPasswordForm, "phone", "");
      this.$set(this.forgetPasswordForm, "code", "");
      this.$set(this.forgetPasswordForm, "password", "");
      this.$set(this.forgetPasswordForm, "configPass", "");
      this.$set(this.signInForm, "realname", "");
      this.$set(this.signInForm, "phone", "");
      this.$set(this.signInForm, "phone_code", "");
      this.$set(this.signInForm, "password", "");
      this.$set(this.signInForm, "configPass", "");
      this.$set(this.phoneData, "codeText", "获取验证码");
      this.$set(this.phoneData, "countDownNumber", "60");
      this.$set(this.phoneData, "phone_loading", false);

      if (this.clear_setInterval_id) {
        clearInterval(this.clear_setInterval_id);
      }
    },
    // 手机验证码时间
    getCodeTime() {
      this.$set(
        this.phoneData,
        "countDownNumber",
        this.phoneData.countDownNumber - 1
      );
      this.$set(
        this.phoneData,
        "codeText",
        this.phoneData.countDownNumber + "秒"
      );
      this.$set(this.phoneData, "phone_loading", true);
      if (this.phoneData.countDownNumber == 0) {
        this.$set(this.phoneData, "phone_loading", false);
        this.$set(this.phoneData, "codeText", "重新发送");
        this.$set(this.phoneData, "countDownNumber", 60);
        clearInterval(this.clear_setInterval_id);
      }
    },
    //获取验证码
    getPhoneCode_1: throttle(function () {
      if (this.$refs.forgetPasswordForm) {
        this.$refs.forgetPasswordForm.validateField("phone");
        if (!validCellIphome(this.forgetPasswordForm.phone)) {
          return false;
        }
      }
      this.$set(this.phoneData, "phone_loading", true);
      // this.forgetPasswordForm.phone
      let forgetPasswordForm = JSON.parse(
        JSON.stringify(this.forgetPasswordForm)
      );
      let phones = cryptoEncryption(forgetPasswordForm.phone);
      this.$axios
        .get("/sendCode", { params: { phone: phones } })
        .then((res) => {
          this.$message({
            message: "发送验证码成功",
            type: "success",
          });
          this.getCodeTime();
          this.clear_setInterval_id = setInterval(() => {
            this.getCodeTime();
          }, 1000);
        })
        .catch((err) => {
          this.$set(this.phoneData, "phone_loading", false);
        });
    }),
    //获取 注册 验证码
    getPhoneCode_2: throttle(function () {
      if (this.$refs.signInForm) {
        this.$refs.signInForm.validateField("phone");
        if (!validCellIphome(this.signInForm.phone)) {
          return false;
        }
      }
      this.$set(this.phoneData, "phone_loading", true);
      this.$axios
        .get("/phoneRSendCode", { params: { phone: this.signInForm.phone } })
        .then((res) => {
          this.$message({
            message: "发送验证码成功",
            type: "success",
          });
          this.getCodeTime();
          this.clear_setInterval_id = setInterval(() => {
            this.getCodeTime();
          }, 1000);
        })
        .catch((err) => {
          this.$set(this.phoneData, "phone_loading", false);
        });
    }),
    //获取 登录 验证码
    getPhoneCode_3: throttle(function () {
      if (this.$refs.loginForm) {
        this.$refs.loginForm.validateField("account");
        if (!validCellIphome(this.loginForm.account)) {
          return false;
        }
      }
      this.$set(this.phoneData, "phone_loading", true);
      let phones = cryptoEncryption(this.loginForm.account);
      this.$axios
        .get("/sendCode", { params: { phone: phones } })
        .then((res) => {
          this.$message({
            message: "发送验证码成功",
            type: "success",
          });
          this.getCodeTime();
          this.clear_setInterval_id = setInterval(() => {
            this.getCodeTime();
          }, 1000);
        })
        .catch((err) => {
          this.$set(this.phoneData, "phone_loading", false);
        });
    }),
    //提交重置密码
    postResetPassword: throttle(function () {
      if (this.loading_2) {
        return false;
      }
      this.$refs["forgetPasswordForm"].validate((valid) => {
        if (valid) {
          this.loading_2 = true;
          let forgetPasswordForm = JSON.parse(
            JSON.stringify(this.forgetPasswordForm)
          );
          forgetPasswordForm.phone = cryptoEncryption(forgetPasswordForm.phone);
          forgetPasswordForm.password = cryptoEncryption(
            forgetPasswordForm.password
          );
          forgetPasswordForm.configPass = cryptoEncryption(
            forgetPasswordForm.configPass
          );
          this.$axios
            .post("/resetPassword", forgetPasswordForm)
            .then((res) => {
              this.$message({
                message: "修改密码成功",
                type: "success",
              });
              this.backLogin();
              this.loading_2 = false;
            })
            .catch(() => {
              this.loading_2 = false;
            });
        }
      });
    }),
    // 用户注册
    postResetSignIn: throttle(function () {
      if (this.loading_3) {
        return false;
      }
      this.$refs["signInForm"].validate((valid) => {
        if (valid) {
          this.loading_3 = true;
          this.$axios
            .post("/phoneRegister", this.signInForm)
            .then((res) => {
              this.$message({
                message: "注册成功",
                type: "success",
              });
              this.backLogin();
              let dataObj = res.data.data;
              this.succeedReturned(dataObj);
            })
            .catch(() => {
              this.loading_3 = false;
            });
        }
      });
    }),
    //提交登录
    postLogin: throttle(function () {
      if (this.loading_1) {
        return false;
      }
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          if (this.$route.query.appId1) {
            this.loginForm.appId1 = this.$route.query.appId1;
          }
          if (this.$route.query.token) {
            this.loginForm.token = this.$route.query.token;
          }
          if (this.$route.query.url) {
            this.loginForm.url = this.$route.query.url;
          }
          let loginObj = JSON.parse(JSON.stringify(this.loginForm));
          loginObj.account = cryptoEncryption(loginObj.account);
          loginObj.password = cryptoEncryption(loginObj.password);
          const from_data = qs.stringify(loginObj, {
            allowDots: true,
          });
          this.loading_1 = true;
          this.$axios
            .post("/login", from_data)
            .then((res) => {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              let dataObj = res.data.data;
              this.succeedReturned(dataObj);
            })
            .catch(() => {
              this.loading_1 = false;
            });
        }
      });
    }),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.form_module {
  .form_title {
    font-size: 30px;
    line-height: 30px;
    color: #111;
    text-align: left;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .el-form-item {
    margin-bottom: 32px;

    /deep/.el-input__inner {
      font-size: 16px;
      color: #2e3d49;
      height: 60px !important;
      line-height: 60px !important;
      border-radius: 8px;
      background: #f3f5f9;
      border-color: #f3f5f9;

      &::placeholder {
        font-size: 16px;
        color: #a7b2bb;
      }
    }

    &.is-error /deep/.el-input__inner {
      border-color: #f56c6c !important;
    }

    /deep/.el-form-item__error {
      font-size: 14px;
    }

    /deep/.el-input--prefix .el-input__inner {
      padding-left: 40px;
    }

    /deep/.el-input--suffix .el-input__inner {
      padding-right: 40px;
    }

    /deep/.el-input__icon {
      width: 40px;
      font-size: 22px;
      line-height: 60px;
    }
  }

  .form_code_div {
    display: flex;

    .form_code_input {
      flex: 1;
      overflow: hidden;
    }

    .form_code_button {
      margin-left: 10px;
    }
  }

  .form_button_1 {
    height: 60px;
    line-height: 60px;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #3489ff;
    border-color: #3489ff;
    font-size: 24px;
    margin-top: 36px;
    border-radius: 60px;

    &:hover {
      background-color: #3399ff;
    }

    &:active {
      background-color: #007af2;
    }
  }

  .form_button_3 {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    margin: 0;
    font-size: 18px;

    &:hover {
      background-color: #3399ff;
    }

    &:active {
      background-color: #007af2;
    }
  }
}

.form_bottom {
  display: flex;
  justify-content: space-between;

  .form_button_2 {
    font-size: 16px;
    color: #2e3d49;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .form_button_btn_2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>