<template>
  <div class="Lecture">
    <div class="TopClass">
      <div class="df">
        <span class="mr50 title">四名讲堂</span>
        <el-radio-group style="margin-top: -5px;" v-model="form.search_type" size="small" @change="init">
          <el-radio-button label="ab">全部</el-radio-button>
          <el-radio-button label="0">直播课</el-radio-button>
          <el-radio-button label="all">录播课</el-radio-button>
        </el-radio-group>
      </div>
      <span class="cursor" @click="toMore()">更多>></span>
    </div>
    <!-- //循环的课程 -->
    <div class="forTeach" v-if="listData.length > 0">
      <div class="cursor forBox" v-for="(item, index) in listData" :key="index" @click="onGoDetail(item)">
        <!-- //封面图片 -->
        <div class="avtor">
          <img :src="item.cover_url" alt="" />
        </div>
        <!-- 下面文字 -->
        <div class="txtContent">
          <p class="t_box">{{ item.name }}</p>

          <div class="tags" v-if="!item.live">
            <div class="box1">{{ item.status_text }}</div>
            <span>{{ item.create_time }}</span>
          </div>
          <p v-else>
            <span style="margin-right: 10px">{{ item.type_text }}</span>
            {{ item.stage_name }} | {{ item.subject_name }}
          </p>
          <p class="lastBox ellipsis" :title="item.teaching_studio_name">{{ item.teaching_studio_name }}</p>
        </div>
      </div>
    </div>
    <CommonEmpty v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      total: 0,
      form: {
        limit: 4,
        page: 1,
        search_teaching_studio_id: localStorage.getItem("studioId"),
        search_type: 'ab',
        user_id: localStorage.getItem("id"),
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.listData = []
      this.getTeachList();
    },
    //四名讲堂列表
    getTeachList() {
      let param = JSON.parse(JSON.stringify(this.form))
      param.search_type = param.search_type == 'ab' ? "" : param.search_type
      this.$axios
        .get("index/home/listTeachingStudioCourseLive", {
          params: param,
        })
        .then((res) => {
          this.listData = res.data.data.data;
          this.listData.map(e => {
            let leng = e.create_time.length
            e.create_time = e.create_time.substring(0, leng - 3)
          })
          this.total = res.data.data.total;
        });
    },
    // 去详情
    onGoDetail(item) {
      window.location.href = item.go_url;
      // if (item.live) {
      //   // 录播详情
      //   switch (Number(item.type)) {
      //     //上课
      //     case 1:
      //       this.$router.push({
      //         name: "goclassdetail",
      //         query: {
      //           id: item.id,
      //           is_from: 2,
      //           is_show_head: 1,
      //           teaching_studio_id: item.teaching_studio_id || "",
      //         },
      //       });
      //       break;
      //     // 说课
      //     case 2:
      //       this.$router.push({
      //         name: "goclassdetail",
      //         query: {
      //           id: item.id,
      //           is_from: 2,
      //           is_show_head: 1,
      //           teaching_studio_id: item.teaching_studio_id || "",
      //         },
      //       });
      //       break;
      //     // 讲座
      //     case 3:
      //       this.$router.push({
      //         name: "goclassdetail",
      //         query: {
      //           id: item.id,
      //           is_from: 2,
      //           is_show_head: 1,
      //           teaching_studio_id: item.teaching_studio_id || "",
      //         },
      //       });
      //       break;
      //     // 听评课
      //     case 4:
      //       this.$router.push({
      //         name: "listeningcommentdetail",
      //         query: {
      //           id: item.id,
      //           is_from: 2,
      //           is_show_head: 1,
      //           teaching_studio_id: item.teaching_studio_id || "",
      //         },
      //       });
      //       break;
      //     // 集中备课
      //     case 5:
      //       this.$router.push({
      //         name: "preparelessons",
      //         query: {
      //           id: item.id,
      //           is_from: 2,
      //           is_show_head: 1,
      //           teaching_studio_id: item.teaching_studio_id || "",
      //         },
      //       });
      //       break;
      //     // 其他
      //     case 6:
      //       this.$router.push({
      //         name: "goclassdetail",
      //         query: {
      //           id: item.id,
      //           is_from: 2,
      //           is_show_head: 1,
      //           teaching_studio_id: item.teaching_studio_id || "",
      //         },
      //       });
      //       break;
      //   }
      // } else {
      //   // 直播详情
      //   this.$router.push({
      //     path: "/AdminLiveDetail",
      //     query: {
      //       lid: item.id,
      //       is_show_head: 1,
      //       teaching_studio_id: item.teaching_studio_id || "",
      //     },
      //   });
      // }
    },
    //跳转更多四名讲堂
    toMore() {
      this.$router.push("/TeachIndex");
    },
  },
};
</script>

<style lang="less" scoped>
.Lecture {
  width: 1106px;
  height: 384px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 20px;
  padding: 26px 26px;

  //顶头标题
  .TopClass {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    >div {
      span {
        font-size: 16px;
        font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
      }
    }

    >span {
      font-size: 16px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
    }
  }

  .forTeach {
    display: flex;
    margin-top: 23px;

    .forBox {
      width: 248px;
      height: 288px;
      opacity: 1;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 0px 6px 2px rgba(113, 113, 113, 0.12);
      margin-right: 20px;
      margin-bottom: 20px;

      //封面
      .avtor {
        width: 248px;
        height: 149px;
        background: #cfcfcf;
        border-radius: 4px 4px 0px 0px;

        img {
          width: 248px;
          height: 149px;
        }
      }

      //底部文字
      .txtContent {
        padding: 20px;

        .t_box {
          width: 224px;
          // height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          margin-bottom: 12px;
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .tags {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          margin-top: 10px;
          font-size: 14px;
          border: 1px solid #FF8201;

          .box1 {
            padding: 2px 4px;
            background: #FF8201;
            border-radius: 2px;
            text-align: center;
            margin-right: 6px;
            color: #fff;
          }

          span {
            color: #FF8201;
          }
        }

        //直播最后一行
        .lastBox {
          font-size: 14px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>