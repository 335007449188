<template>
  <div class="researchlive">
    <div class="w_d center" style="margin:0 auto;">
      <!-- <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">教研直播</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div> -->
      <div class="navBox">
        <p>
          当前位置：研修中心&nbsp;>&nbsp; <span>教研直播</span>
        </p>
      </div>
      <div class="select">
        <div class="select_left">
          <!-- 学段 -->
          <div class="xueduan">
            <p>学段：</p>
            <!-- 这个是全部按钮样式 -->
            <div :class="[
                pharseid == '' ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" @click="handleStage('')">
              全部
            </div>
            <!-- end -->

            <!-- 这个是按钮样式 -->
            <div :class="[
                pharseid == item.id ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" v-for="(item, index) in stage" :key="index" @click="handleStage(item.id)">
              {{ item.name }}
            </div>
            <!-- end -->

          </div>
          <!-- end -->

          <!-- 学科 -->
          <div class="xueduan2">
            <p>学科：</p>
            <div class="xueduan2_one">
              <div :class="[
                  search_subject_id == '' ? 'active_block' : '',
                  'xueduan_block',
                  'cursor',
                ]" @click="handleSubject('')">
                全部
              </div>
              <div :class="[
                  search_subject_id == item.subjectId ? 'active_block' : '',
                  'xueduan_block',
                  'cursor',
                ]" v-for="(item, index) in subjectData" :key="index" @click="handleSubject(item.subjectId)">
                {{ item.subjectName }}
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- 状态 -->
          <div class="xueduan">
            <p>状态：</p>
            <div :class="[
                search_status == '' ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" @click="handlestatus('')">
              全部
            </div>
            <div :class="[
                search_status == item.id ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" v-for="(item, index) in statusData" :key="index" @click="handlestatus(item.id)">
              {{ item.statusName }}
            </div>
          </div>
          <!-- end -->
          <div class="sousuo">
            <p>查找：</p>
            <el-input placeholder="输入直播主题" prefix-icon="el-icon-search" class="sousuo_one" v-model="search_title" size='medium'></el-input>
            <el-button type="primary" size='medium' @click="screensousuo">搜索</el-button>
          </div>
        </div>
        <div class="select_right">
          <!-- <div class="create cursor" @click="getinto('/createlive')">+ 创建直播</div> -->
          <div class="create"></div>
          <div class="sousuo_one">
            <el-input placeholder="通过访问码查找" prefix-icon="el-icon-search" v-model="search_visit_code" size='medium'></el-input>
            <el-button type="primary" size='medium' @click="rightsousuo">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间和分页 -->
    <div class="CenterPage">
      <div v-if="liveData && liveData.length != 0">
        <div class="main">
          <el-card class="live_block cursor" shadow="hover" :body-style="{ padding: '0px' }" v-for="(item, index) in liveData" :key="index">
            <div @click="getintoLive(item)">
              <div class="img" :style="{ backgroundImage: 'url(' + item.cover_url + ')' }">
                <!-- <div class="writer">
                  <p
                    v-if="item.status == '2'"
                    :class="{ yellow: ifNowDate(item.start_date) }"
                  >
                    <template v-if="ifNowDate(item.start_date)">
                      今日 {{ item.start_time }}开播
                    </template>
                    <template v-else>
                      {{ item.start_date }} {{ item.start_time }}开播
                    </template>
                  </p>
                  <p v-else-if="item.status == '1'" class="yellow">直播中</p>
                  <p v-else-if="item.status == '3'">直播回顾</p>
                </div> -->
              </div>
              <div class="middles">
                <p class="word1">{{ item.title }}</p>
                <div class="word2">
                  <span v-if="item.initiator_type == '1'">{{
                        item.username
                      }}</span>
                  <span v-if="item.initiator_type == '1'">{{
                        item.org_name
                      }}</span>
                  <span v-if="item.initiator_type == '2'">{{
                        item.group_name
                      }}</span>
                </div>
                <!-- 新增最后一行 -->
                <div class="lastContent">
                  <span class="oneC1" v-if="item.status == 2">预告</span>
                  <span class="oneC" v-if="item.status == 1">直播中</span>
                  <span class="oneC1" v-if="item.status == 3">回顾</span>
                  <span :class="item.status == 1 ? 'twoC' : 'twoC1'">{{ item.start_date }}</span>
                  <span :class="item.status == 1 ? 'threeC' : 'threeC1'">{{ item.start_time }}</span>
                </div>
              </div>
            </div>
          </el-card>
          <div class="pick"></div>
        </div>
        <div class="pagin">
          <el-pagination background :current-page="page" :page-size="limit" layout="prev, pager, next, jumper" :total="total"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
      <div v-else class="nodata">暂无数据.....</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //学段数据
      stage: [],
      //科目数据
      subjectData: [],
      // 选中的学段id
      pharseid: "",
      // 选中的学科id
      search_subject_id: "",
      // 状态数据
      statusData: [
        {
          id: "1",
          statusName: "直播中",
        },
        {
          id: "2",
          statusName: "预告",
        },
        {
          id: "3",
          statusName: "回顾",
        },
      ],
      // 选中的状态
      search_status: "",
      // 搜索标题数据
      search_title: "",
      // 搜索访问码
      search_visit_code: "",
      // 每页条数
      limit: 20,
      // 页数
      page: 1,
      // 总数据
      total: 0,
      // 直播总数据
      liveData: [],
    };
  },
  created() {
    this.init();
    this.ifNowDate();
  },
  methods: {
    // 初始化函数
    init() {
      this.getStateList();
      this.leftsousuo();
      //初始化显示全部学段
      // this.handleStage(3);
    },
    // 请求后台获取学段信息
    getStateList() {
      this.$axios.get("stage/stage_list").then((res) => {
        var resdata = res.data.data;
        this.stage = resdata;
      });
    },
    // 请求后台获取科目列表
    getSubjectList() {
      var obj = {
        // pharseid:'1'
        params: {
          pharseid: this.pharseid,
        },
      };
      this.$axios.get("subject/list", obj).then((res) => {
        var resdata = res.data;
        if (resdata.code == "900") {
          this.subjectData = resdata.data;
        } else {
          this.$message({
            message: resdata.msg,
          });
        }
      });
    },
    // 点击切换所选学段函数
    handleStage(id) {
      this.pharseid = id;
      if (id != "") {
        this.getSubjectList();
      }

      // this.search_subject_id = "";
      // this.leftsousuo();
      this.screensousuo();
    },
    // 点击切换所选学科函数
    handleSubject(id) {
      this.search_subject_id = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 点击切换所选状态函数
    handlestatus(id) {
      this.search_status = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 请求后台获取直播数据
    getLiveData(obj) {
      //  const loading = this.$loading({
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.$axios.get("moreLives", obj).then((res) => {
        var resdata = res.data;
        //  loading.close();
        if (resdata.code == "900") {
          this.liveData = resdata.data.data;
          this.total = resdata.data.total;
        } else {
          this.$message({
            message: resdata.msg,
          });
        }
      });
      //  setTimeout(() => {
      //   loading.close();
      // }, 5000);
    },
    // 左边搜索函数
    leftsousuo() {
      // const loading = this.$loading({
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      // setTimeout(() => {
      //   loading.close();
      // }, 2000);
      var obj = {
        limit: this.limit,
        page: this.page,
        search_stage_id: this.pharseid,
        search_subject_id: this.search_subject_id,
        search_status: this.search_status,
        search_title: this.search_title,
        search_visit_code: "",
      };

      this.getLiveData({ params: obj });
    },
    // 右边访问码搜索函数
    rightsousuo() {
      var obj = {
        limit: 20,
        page: 1,
        search_stage_id: "",
        search_subject_id: "",
        search_status: "",
        search_title: "",
        search_visit_code: this.search_visit_code,
      };
      this.getLiveData({ params: obj });
    },
    //筛选搜索函数
    screensousuo() {
      this.page = 1;
      var obj = {
        limit: this.limit,
        page: 1,
        search_stage_id: this.pharseid,
        search_subject_id: this.search_subject_id,
        search_status: this.search_status,
        search_title: this.search_title,
        search_visit_code: "",
      };

      this.getLiveData({ params: obj });
    },
    // 判断是否是当前日期函数
    ifNowDate(item) {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;

      if (String(month).length < 2) {
        month = "0" + month;
      }
      if (String(date).length < 2) {
        date = "0" + date;
      }
      // console.log(year, month, date);
      if (item) {
        var array = item.split("-");
        if (array[0] == year) {
          if (array[1] == month) {
            if (array[2] == date) {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    //   切换当前页码时触发
    handleCurrentChange(val) {
      this.page = val;
      this.leftsousuo();
    },
    // 跳转到其他页面
    getinto(path) {
      this.$router.push(path);
    },
    //点击跳转到直播详情页
    getintoLive(item) {
      this.$router.push({
        name: "livedetail",
        query: {
          liveid: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.researchlive {
  // display: flex;
  // justify-content: center;
  .navBox {
    width: 1400px;
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 18px;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      span {
        color: #ff8201;
      }
    }
  }
  .center {
    margin-bottom: 60px;
    // border: 1px solid black;
    .select {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      .select_left {
        .xueduan {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          p {
            color: #999999;
            margin-right: 20px;
          }
          .xueduan_block {
            width: 72px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }
        .xueduan2 {
          display: flex;
          // align-items: center;
          // margin-bottom: 39px;
          width: 780px;
          p {
            color: #999999;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .xueduan2_one {
            display: flex;
            align-items: center;
            width: 780px;
            flex-wrap: wrap;
          }

          .xueduan_block {
            // width: 72px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 26px;
            padding: 0 20px;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }

        .sousuo {
          display: flex;
          align-items: center;
          margin-top: 20px;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            // border-radius: 4px 0px 0px 4px;
            border-radius: 4px 4px 4px 4px;
          }
          /deep/ .el-button {
            // border-radius: 0px;
            border-radius: 4px 4px 4px 4px;
          }

          p {
            color: #999999;
            flex-shrink: 0;
            margin-right: 20px;
          }
          .sousuo_one {
            width: 388px;
            margin-right: 20px;
          }
        }
      }
      .select_right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .create {
          width: 140px;
          height: 38px;
          // background: #ff8201;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: 700;
          // display: none;
        }
        .sousuo_one {
          display: flex;
          align-items: center;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            border-radius: 4px 0px 0px 4px;
          }
          /deep/ .el-button {
            // border-radius: 0px;
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }
  }
  .CenterPage {
    width: 100%;
    background: #f7f7f7;
    padding: 24px 0;
    .main {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .live_block {
        margin-right: 13px;
        width: 264px;
        height: 286px;
        margin-bottom: 26px;
        .img {
          width: 264px;
          height: 149px;
          // background-image: url(../../../assets/images/live.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column-reverse;
          // .writer {
          //   width: 100%;
          //   height: 32px;
          //   background: rgba(0, 0, 0, 0.5);
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   p {
          //     color: #fffffe;
          //   }
          //   .yellow {
          //     color: #ff8201;
          //   }
          // }
        }
        .middles {
          padding: 20px;
          .word1 {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 12px;
          }
          .word2 {
            color: #999999;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 18px;
            span:nth-child(1) {
              margin-right: 30px;
            }
          }
          // 直播最后一行内容
          .lastContent {
            display: flex;
            justify-content: left;
            .oneC {
              width: 36px;
              height: 20px;
              opacity: 1;
              background: #ff8201;
              border-radius: 2px;
              color: #fff;
              padding: 2px;
              font-size: 14px;
              text-align: center;
            }
            .oneC1 {
              width: 36px;
              height: 20px;
              opacity: 1;
              background: #e8eaed;
              border-radius: 2px;
              color: #999;
              padding: 2px;
              font-size: 14px;
              display: flex;
              justify-content: center;
              // margin-left: 20px;
            }
            .twoC {
              color: #ff8201;
              margin-left: 3px;
              margin-right: 6px;
            }
            .twoC1 {
              color: #999999;
              margin-left: 3px;
              margin-right: 6px;
            }
            .threeC {
              color: #ff8201;
              margin-left: 6px;
            }
            .threeC1 {
              color: #999999;
              margin-left: 6px;
            }
          }
        }
      }
    }
    .nodata {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #888;
      margin-top: 100px;
    }
    .main:after {
      content: "";
      width: 335px;
    }
    .pick {
      width: 336px;
      border: 1px solid transparent;
      overflow: hidden;
    }
  }
}
</style>