//跳转外站
global.skipOutstation = (path, query = {}, type) => {
  let website = '';
  website = "https://www.bxwang.net";
  const aescode = localStorage.getItem("aes_code") ? localStorage.getItem("aes_code") : "";
  let url = `${website}${path}`;
  if (aescode) {
    url = url + `?aescode=${aescode}`;
  }
  if (Object.keys(query).length > 0) {
    let index = 0
    let queryStr = "";
    for (const key in query) {
      if (aescode || index > 0) {
        queryStr = queryStr + `&${key}=${query[key]}`;
      } else {
        queryStr = queryStr + `?${key}=${query[key]}`;
      }
      index++
    }
    url = url + queryStr;
  }
  window.open(url);
};

export function isOffice(ext) {
  if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    return true;
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    return true;
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    return true;
  }
};

// 图片
function isAssetTypeAnImage(ext) {
  return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
};
// 视频
function isAssetTypeAnVideo(ext) {
  return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
};
// 压缩包
function isComprimer(ext) {
  return ["zip", "rar", "7z", "jgz"].includes(ext.toLowerCase());
};

// 根据图片格式显示不同图片
export function onShowImages(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/images/personal/repalces/JPG.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/images/personal/repalces/word.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/images/personal/repalces/excel.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/images/personal/repalces/ppt.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/images/personal/repalces/PDF.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/images/personal/repalces/Video.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/images/personal/repalces/ZIP.png");
  } else if (ext.toLowerCase() === "mp3") {
    // mp3
    return require("@/assets/images/personal/repalces/Audio.png");
  } else {
    return require("@/assets/images/personal/repalces/Audio.png");
  }
};
export function onShowImages2(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/images/teacherStudio/img.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/images/teacherStudio/word.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/images/teacherStudio/excel.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/images/teacherStudio/ppt.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/images/teacherStudio/pdf.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/images/teacherStudio/video.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/images/teacherStudio/zip.png");
  } else {
    return require("@/assets/images/teacherStudio/txt.png");
  }
};
export function onShowImages3(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/images/teacherStudio/jpg_2.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/images/teacherStudio/word_2.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/images/teacherStudio/excel_2.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/images/teacherStudio/ppt_2.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/images/teacherStudio/pdf_2.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/images/teacherStudio/video_2.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/images/teacherStudio/zip_2.png");
  } else {
    return require("@/assets/images/teacherStudio/audio_2.png");
  }
};
export function onShowImages4(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/live/img.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/live/word.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/live/excel.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/live/ppt.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/live/pdf.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/live/video.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/live/zip.png");
  } else {
    return require("@/assets/live/unknown.png");
  }
};

// 根据图片格式显示不同图片
export function onShowImages5(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/fileIcon/pic.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/fileIcon/w.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/fileIcon/excel.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/fileIcon/PPT.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/fileIcon/pdf.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/fileIcon/video.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/fileIcon/package.png");
  } else if (ext.toLowerCase() === "mp3") {
    // mp3
    return require("@/assets/fileIcon/audio.png");
  } else {
    return require("@/assets/fileIcon/other.png");
  }
};

//根据文件后缀类型返回格式图片
export function getExtImg(url) {
  //先截取后缀
  let ext = url.substring(url.indexOf('.') + 1)
  if (ext == 'doc' || ext == "docx") {
    return require("@/assets/images/index_img/doc.png");
  } else if (ext == "xls" || ext == "xlsx") {
    return require("@/assets/images/index_img/excel.png");
  } else if (ext == "ppt" || ext == "pptx") {
    return require("@/assets/images/index_img/ppt.png");
  } else if (ext == "pdf") {
    return require("@/assets/images/index_img/pdf.png");
  } else if (ext == "mp4") {
    return require("@/assets/images/index_img/video.png");
  } else if (ext == "zip") {
    return require("@/assets/images/index_img/zip.png");
  } else if (isAssetTypeAnImage(ext)) {
    return require("@/assets/images/index_img/jpg.png");
  } else if (ext == "txt") {
    return require("@/assets/images/index_img/txt.png");
  } else if (ext == "mp3") {
    return require("@/assets/images/index_img/mp3.png");
  }
};

/* 判断是否对象 */
export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

/* 另一个深拷贝 */
export function deepClone(source) {
  const typeList = ['Array', 'Object']
  const type = /\s(.*)\]/.exec(Object.prototype.toString.call(source))[1]
  if (typeList.includes(type)) {
    const res = source.constructor()
    for (let key in source) {
      res[key] = deepClone(source[key])
    }
    return res
  }
  return source
};

// 检测 纯数字 没有标点符号
export function isPureNumber(str) {
  const regex = /^[0-9]+$/;
  return regex.test(str);
}

/* 防抖 一般用在 搜索框 input事件上 【等待执行 N秒后执行】*/
export function debounce(fn, delay = 1000) {
  let timer = null;
  return function (...args) {
    let context = this;
    if (timer) {
      clearTimeout(timer)
    };
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
};

/* 节流  一般用在 按钮点击 click事件上 防止多次点击 【立即执行】 */
export function throttle(fn, interval = 2000) {
  let last = 0;
  return function (...args) {
    let context = this;
    let now = +new Date();
    if (now - last < interval) {
      return;
    };
    last = now;
    fn.apply(context, args);
  }
};

// 过滤排除null 和 "" 过滤参数
export function ObjfilterNull(obj) {
  let params = deepClone(obj);
  if (isObject(params)) {
    for (let key in params) {
      if ([null, undefined, '', ' '].includes(params[key])) {
        delete params[key];
      };
    };
    return params;
  } else {
    return params;
  };
};

// 过滤图片类型【新版】 根据图片格式显示不同图片 【小图】
export function filterImgTypeMini(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/file_type_img/jpg_xianxing.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/file_type_img/word_xianxing.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/file_type_img/excel_xianxing.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/file_type_img/ppt_xianxing.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/file_type_img/pdf_xianxing.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/file_type_img/video_xianxing.png");
  } else if (ext.toLowerCase() === "mp3") {
    // mp3
    return require("@/assets/file_type_img/audio_xianxing.png");
  } else if (ext.toLowerCase() === "txt") {
    // txt
    return require("@/assets/file_type_img/txt_xianxing.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/file_type_img/yasuowenjian_xianxing.png");
  } else {
    // 其他
    return require("@/assets/file_type_img/weizhiwenjian_xianxing.png");
  }
};
// 过滤图片类型【新版】 根据图片格式显示不同图片 【资源用】
export function filterImgTypeResource(ext) {
  ext = ext ? ext : '';
  if (isAssetTypeAnImage(ext)) {
    // 图片
    return require("@/assets/imgFIle_4/pic.png");
  } else if (ext.toLowerCase() === "doc" || ext.toLowerCase() === "docx") {
    // word
    return require("@/assets/imgFIle_4/word.png");
  } else if (ext.toLowerCase() === "xls" || ext.toLowerCase() === "xlsx") {
    // excel
    return require("@/assets/imgFIle_4/excel.png");
  } else if (ext.toLowerCase() === "ppt" || ext.toLowerCase() === "pptx") {
    // ppt
    return require("@/assets/imgFIle_4/PPT.png");
  } else if (ext.toLowerCase() === "pdf") {
    // pdf
    return require("@/assets/imgFIle_4/pdf.png");
  } else if (isAssetTypeAnVideo(ext)) {
    // 视频
    return require("@/assets/imgFIle_4/video.png");
  } else if (ext.toLowerCase() === "mp3") {
    // mp3
    return require("@/assets/imgFIle_4/audio.png");
  } else if (ext.toLowerCase() === "txt") {
    // txt
    return require("@/assets/imgFIle_4/text.png");
  } else if (isComprimer(ext)) {
    // 压缩包
    return require("@/assets/imgFIle_4/package.png");
  } else {
    // 其他
    return require("@/assets/imgFIle_4/weizhi.png");
  }
};

// 过滤图片类型【新版】 根据图片格式显示不同图片 【大图】
export function filterImgTypeSmall(ext) {
  ext = ext ? ext : '';
  let typeAllType = ['doc', 'docx', 'gif', 'jpeg', 'jpg', 'mp3', 'mp4', 'pdf', 'png', 'ppt', 'pptx', 'txt', 'xls', 'xlsx', 'zip'];
  if (typeAllType.includes(ext.toLowerCase())) {
    return require("@/assets/images/iconFile/" + ext + ".png");
  } else {
    return require("@/assets/images/iconFile/other.png");
  };
};


const localNameKey = ""//"hlwjy_"; // 【 本地存储 公用字段 】
/* 获取 本地储存 【localStorage】 */
export function localGet(key) {
  const value = window.localStorage.getItem(localNameKey + key)
  try {
    return JSON.parse(window.localStorage.getItem(localNameKey + key))
  } catch (error) {
    return value
  }
}
/* 设置 本地储存 【localStorage】 */
export function localSet(key, value) {
  window.localStorage.setItem(localNameKey + key, JSON.stringify(value))
}
/* 删除 本地储存 【localStorage】 */
export function localRemove(key) {
  window.localStorage.removeItem(localNameKey + key)
}

/* 获取 本地储存 【sessionStorage】 */
export function sessionGet(key) {
  const value = window.sessionStorage.getItem(localNameKey + key)
  try {
    return JSON.parse(window.sessionStorage.getItem(localNameKey + key))
  } catch (error) {
    return value
  }
}
/* 设置 本地储存 【sessionStorage】 */
export function sessionSet(key, value) {
  window.sessionStorage.setItem(localNameKey + key, JSON.stringify(value))
}
/* 删除 本地储存 【sessionStorage】 */
export function sessionRemove(key) {
  window.sessionStorage.removeItem(localNameKey + key)
}

// 字节 B 转化成KB、MB、GB
export function fileSizeFilter(val) {
  let num = 1024.00; //byte
  let size = val || 0;
  if (size < num) {
    return size + "B";
  } else if (size < Math.pow(num, 2)) {
    return (size / num).toFixed(2) + "KB"; //kb
  } else if (size < Math.pow(num, 3)) {
    return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M
  } else if (size < Math.pow(num, 4)) {
    return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
  } else {
    return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
  };
};

// 是否有教研组ID
export function isHasStudioIdFn(_that) {
  let teaching_studio_id = null;
  let query = _that.$route.query;
  if (query && (query.teaching_studio_id || query.studioId)) {
    teaching_studio_id = query.teaching_studio_id || query.studioId;
  } else {
    teaching_studio_id = localStorage.getItem("studioId") || false;
  };
  if (teaching_studio_id) {
    return true;
  } else {
    return false;
  }
};

// 手机号校验
export function validCellIphome(mobile) {
  return RegExp(/^1[345789]\d{9}$/).test(mobile);
}

//对象转&拼接
export function queryString(params) {
  let str = '';
  for (let Key in params) {
    str += Key + '=' + params[Key] + '&';
  }
  return '?' + str.substr(0, str.length - 1);
}