<template>
  <!-- 活动资源上传页面 -->
  <div class="resourceuploadbrowsing study-active-introduce">
    <div class="title">{{ resourceData.name }}</div>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="one_box">
        <span class="link-date">环节时间：{{ resourceData.start_date }} 至 {{ resourceData.end_date }}</span>

        <el-popover placement="top" width="" trigger="click" v-if="isEdit">
          <el-date-picker v-model="activityInfoTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeTImeEvent">
          </el-date-picker>
          <div class="change_time_div_btn" slot="reference" @click="openTImeSelectBox">
            更改时间
          </div>
        </el-popover>
        <el-dialog title="" :visible.sync="time_visible" width="500px" top="20vh">
          <div style="text-align: center;">
            <el-button type="primary" @click="handleSync(2)" v-if="activityInfoData.audit_status == 1">确定</el-button>
            <template v-else>
              <el-button type="primary" @click="handleSync(1)">确认并同步活动状态</el-button>
              <el-button type="primary" @click="handleSync(2)">不同步活动状态</el-button>
            </template>
            <el-button @click="time_visible = false">取消</el-button>
          </div>
        </el-dialog>

        <div v-if="resourceData.status ==1" class="conduct nostart"> 未开始 </div>
        <div v-if="resourceData.status ==2" class="conduct going"> 进行中 </div>
        <div v-if="resourceData.status ==3" class="conduct end"> 已结束 </div>
        <EndLink :id="form.link_id" :is_from="is_from" :status="resourceData.status" @success="endLinkSuccess" v-if="isEdit"></EndLink>
      </div>
      <div class="two">
        <span>环节类型：资源上传</span>
        <span>开展方式：{{
            resourceData.implement_type == "1" ? "线上" : "线下"
          }}</span>
        <span>参与人数:{{ resourceData.user_count }}人</span>
      </div>
      <div class="public-line mb30"></div>
      <div class="three">环节描述</div>
      <div class="four">
        <!-- <p>{{ resourceData.description }}</p> -->
        <div v-bind:class="{ expansion: textActive }" class="show_text_all" ref="textNum">
          <p class="rich_text_div_img" v-html="resourceData.description"></p>
        </div>
      </div>
      <div class="public-line mb30"></div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->

      <div v-if="resourceData.implement_type == '1'">
        <div class="six">
          <p>资源列表（{{ total }}）</p>
          <div class="new_upload cursor" @click="showUp()" v-if="$store.state.activity_isJoin && resourceData.status ==2">上传资源</div>
        </div>
        <ul class="uu">
          <li v-for="(item, index) in cgList" :key="index" class="cursor" @click="onSearchCg(item.id)">
            <img :src="item.id == active ? item.icon1 : item.icon2" alt="" />
            <span>{{ item.name }}</span>
          </li>
        </ul>
        <div v-if="total > 0">
          <!-- ----资源列表 -->
          <div class="eight">
            <ul>
              <li v-for="(item, index) in resourceList" :key="index" class="fsbc">
                <div class="left df">

                  <!-- //资源图片 -->
                  <img :src="getExtImg(item.mime)" />

                  <div>
                    <p class="l_title ellipsis">{{ item.name }}</p>
                    <div class="username">
                      <span>{{ item.username }}</span>
                      <span>{{ item.create_time }}</span>
                    </div>
                  </div>
                </div>
                <div class="fc right" v-if="$store.state.activity_isJoin">
                  <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :fileTitle="item.name"
                    :fileSize="item.size" :fileTime="item.create_time" :itemData="item" fileNav="活动详情 > 查看">
                    <el-button type="text cursor">查看</el-button>
                  </new-viewonline>
                  <p class="line"></p>
                  <el-button type="text cursor" @click="onUpload(item)">下载</el-button>
                </div>
              </li>
            </ul>
          </div>
          <!-- 分页 -->
          <div class="fcc pt24">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
              :page-sizes="[10, 20, 30, 50]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>

        <!-- 暂无资源数据 -->
        <div class="noData" v-else>暂无资源数据</div>
      </div>
      <!-- 线下区域 -->
      <UnderLineDetail :is_from="+is_from" :resourceData="resourceData" v-else-if="resourceData.implement_type == '2'"
        @getResourceData="getResourceData" :activityId="+form.activity_id" :linkId="+form.link_id" />
    </div>

    <!-- 上传资源弹窗 -->
    <MyDialog :open="updownDialog" title="上传资源" @onClose="onCloseUpload" width="50%">
      <div>
        <UploadResource ref="uploadRef" :flag="false" @onChangeStatus="onChangeStatus" :activity_id="+form.activity_id" :link_id="+form.link_id"
          :is_who='3' :is_from="is_from" />
      </div>
    </MyDialog>
  </div>
</template>

<script>
import {
  cancelGroupActivity,
  cancelTeachActivity
} from "@/api/teacherStudio";
import { getExtImg } from '@/utils/utils.js'
import UploadResource from "@/components/uploadResource";
import UnderLineDetail from "./components/underlineDetail/index.vue";
import * as researchApi from '@/api/researchApi.js';
import EndLink from "./components/endLink/endLink.vue";
export default {
  components: {
    UploadResource,
    UnderLineDetail,
    EndLink
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      time_visible: false,
      time_reqObj: {},
      activityInfoTime: [],
      // 当前登录的用户名
      username: "",
      // 资源浏览数据
      resourceData: {},
      // 所有资源列表
      // 资源列表
      resourceList: [],
      total: 0,
      // 资源上传对话框
      updownDialog: false,
      // 文本是否隐藏
      textActive: true,
      cgList: [
        {
          name: "全部资源",
          id: 1,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
        {
          name: "我的资源",
          id: 2,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
      ],
      active: 1,
      // 1教研活动模块，2四名工作室活动模块
      is_from: 1,
      form: {
        page: 1,
        limit: 10,
        // 活动id
        activity_id: "",
        // 环节id
        link_id: "",
        user_id: localStorage.getItem("id"),
      },
    };
  },
  created() {
    this.init();
    // this._getAddress();
  },

  methods: {
    // 点击更改时间
    openTImeSelectBox() {
      this.activityInfoTime = [`${this.resourceData.start_date}`, `${this.resourceData.end_date}`]
    },
    // 同步迟环节
    async handleSync(num) {
      try {
        let time_reqObj = this.time_reqObj;
        time_reqObj['is_sync_status'] = num;
        time_reqObj['is_personid'] = this.is_from;
        let res_1 = await researchApi.get_activity_activitylink_update_start_end_date(time_reqObj);
        this.time_visible = false;
        if (res_1.data.data.status) {
          this.$set(this.resourceData, "status", res_1.data.data.status);
          this.$set(this.resourceData, "start_date", res_1.data.data.start_date);
          this.$set(this.resourceData, "end_date", res_1.data.data.end_date);
        };
        this.$message.success("操作成功");
        this.$parent.$refs.linkNav.init();
      } catch (error) {
        this.time_visible = false;
      }
    },
    // 确定更改l环节时间
    changeTImeEvent(e) {
      if (e) {
        let time_reqObj = {
          id: this.$route.query.id,
          start_date: e[0],
          end_date: e[1],
        };
        this.time_visible = true;
        this.time_reqObj = time_reqObj;
      }
    },
    //完成环节
    cancelLink() {
      // console.log("完成环节在弄");
      if (this.is_from == 1) {
        let obj = {
          activityId: this.$route.query.activityId,
          id: this.$route.query.id,
        };
        cancelGroupActivity(obj).then(() => {
          this.init();
        })
      } else if (this.is_from == 2) {
        cancelTeachActivity(obj).then(() => {
          this.init();
        })
      }
      this.$parent.$refs.linkNav.init();
    },
    // 初始化函数
    init() {
      this.form.activity_id = this.$route.query.activityId;
      this.form.link_id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("username");
      //   this.userId = window.localStorage.getItem("id");
      this.getResourceData();
      this.onGetList();
      // console.log(getExtImg,"方法");
    },
    getExtImg,
    // 关闭弹窗后的操作
    onCloseUpload() {
      this.updownDialog = false;
      this.$refs.uploadRef.form = this.$refs.uploadRef.$options.data().form;
      this.onGetList();
    },

    //完成资源上传
    onChangeStatus() {
      this.updownDialog = false;
      this.cancelLink();
      this.onGetList();
    },
    onGetList() {
      if (this.active == 1) {
        this.getAllResource();
      } else {
        this.getMyData();
      }
    },
    // 全部资源和我的资源
    onSearchCg(id) {
      this.active = id;
      this.form.page = 1;
      this.onGetList();
    },
    // 分页
    handleSizeChange(val) {
      this.form.limit = val;
      this.onGetList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.onGetList();
    },
    //请求后台获取此环节数据
    getResourceData() {
      if (this.is_from == 1) {
        this.$axios
          .get(
            `activity/${this.form.activity_id}/activityLink/${this.form.link_id}`
          )
          .then((res) => {
            this.resourceData = res.data.data;
          });
      } else {
        this.$axios
          .get(
            `TeachingStudioactivity/${this.form.activity_id}/activityLink/${this.form.link_id}`
          )
          .then((res) => {
            this.resourceData = res.data.data;
            // this.resourceData.status = '2'
          });
      }
    },
    //请求后台获取全部资源列表
    getAllResource() {
      delete this.form.user_id;
      if (this.is_from == 1) {
        this.$axios.get("ActivityUserResource/get_source_list", { params: this.form }).then((res) => {
          this.resourceList = res.data.data.data;
          this.total = res.data.data.total;
        });
      } else {
        this.$axios.get("TeachingStudioActivityUserResource/get_source_list", { params: this.form, }).then((res) => {
          this.resourceList = res.data.data.data;
          this.total = res.data.data.total;
        });
      }
    },
    // 请求后台获取我的资源数据
    getMyData() {
      this.form.user_id = localStorage.getItem("id");
      if (this.is_from == 1) {
        this.$axios.get("ActivityUserResource/get_my_source_list", { params: this.form }).then((res) => {
          this.resourceList = res.data.data.data;
          this.total = res.data.data.total;
        });
      } else {
        this.$axios.get("TeachingStudioActivityUserResource/get_my_source_list", { params: this.form, }).then((res) => {
          this.resourceList = res.data.data.data;
          this.total = res.data.data.total;
        });
      }
    },
    // 查看
    onLook(url) {
      //   window.open(url, "_blank");
    },
    // 下载
    onUpload(item) {
      // console.log(item,"121212");
      window.location.href = item.resource_url;
      // this.AddLookNum(item.id)
    },

    // // 添加资源浏览记录
    // AddLookNum(rid){
    //       let obj={
    //                           type:this.is_from,
    //               group_studio_id:this.$route.query.activityId,
    //               activity_link_resource_id:rid
    //       }
    //        this.$axios.post("ResourceRecord/add",obj)
    //       .then(() => {
    //           console.log("浏览加1");
    //       });
    // },

    // 上传组件提交函数
    handleSubmit(val) {
      this.init();
    },
    // 不展现上传对话框的点击函数
    showUp() {
      if (this.resourceData.status == "2") {
        this.updownDialog = true;
      } else if (this.resourceData.status == "1") {
        this.$message.info("活动环节未开始不可上传");
      } else if (this.resourceData.status == "3") {
        this.$message.info("活动环节已结束不可上传");
      }
    },

    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    // 点击处理其他状态函数
    handleOtherStatus() {
      if (this.resourceData.status == "1") {
        this.$message.info("活动环节未开始");
      } else if (this.resourceData.status == "3") {
        this.$message.info("活动环节已结束");
      }
    },
    //结束环节
    endLinkSuccess(status) {
      this.$set(this.resourceData, "status", status);
      this.$parent.$refs.linkNav.init();
    },
  },
  computed: {
    //判断是否能编辑
    isEdit() {
      let uid = localStorage.getItem('id');
      if (uid == this.resourceData.user_id) {
        return true
      } else {
        return false
      }
    },
  },
};
</script>

<style scoped lang="less">
.resourceuploadbrowsing {
  width: 1116px;
  //   height: 2157px;
  background: #ffffff;
  border: NaNpx solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 0px 4px 7px 0px #f5f5f5;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    font-size: 20px;
    font-weight: 700;
    margin: 49px 0px 45px 0px;
    display: flex;
    justify-content: center;
  }
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    padding: 0 30px;
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .one_box {
      display: flex;
      margin: 41px 0px 23px;
      align-items: center;
      .conduct {
        width: 51px;
        height: 18px;
        background: #ff8201;
        border-radius: 9px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 18px;
      }
      .going {
        background: #ff8201;
      }
      .nostart {
        background: #3489fe;
      }
      .end {
        background: #cecece;
      }
    }
    .two {
      color: #000000;
      margin-bottom: 39px;
      span {
        margin-right: 56px;
      }
    }
    .three {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 23px;
    }
    .four {
      margin-bottom: 25px;
      // p {
      //   position: relative;
      //   line-height: 28px;
      //   // max-height: 40px;
      //   overflow: hidden;
      //   width: 986px;
      //   height: 81px;
      // }
      // p::after {
      //   content: "...";
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      //   padding-left: 40px;
      //   background: -webkit-linear-gradient(left, transparent, #fff 55%);
      //   background: -o-linear-gradient(right, transparent, #fff 55%);
      //   background: -moz-linear-gradient(right, transparent, #fff 55%);
      //   background: linear-gradient(to right, transparent, #fff 55%);
      // }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 29px;
      p {
        font-size: 18px;
        font-weight: 700;
        color: #1a1a1a;
      }
      .upload {
        margin-right: 30px;
        width: 95px;
        height: 27px;
        justify-content: center;
        align-items: center;
        background-color: #3489fe;
        color: #ffffff;
        display: flex;
        border-radius: 3px;
      }
    }
    .uu {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      li {
        margin-right: 10%;
        > img {
          vertical-align: text-bottom;
          margin-right: 16px;
        }
      }
    }
    .eight {
      ul {
        li {
          border: 1px solid #ebebeb;
          border-radius: 6px;
          padding: 22px 40px 22px 22px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            .l_title {
              color: #3489ff;
            }
          }
          .left {
            > img {
              width: 50px;
              //height: 50px;
              margin-right: 30px;
            }
            .l_title {
              line-height: 24px;
              width: 480px;
            }
            .username {
              color: #9a9ea5;
              font-size: 14px;
              padding-top: 6px;
              span {
                margin-right: 23px;
              }
            }
          }
          .right {
            .line {
              width: 1px;
              height: 15px;
              background: #ececec;
              margin: 0 40px;
            }
          }
        }
      }
    }
    // 没数据的样式
    .noData {
      width: 100%;
      height: 100px;
      text-align: center;
      line-height: 100px;
      font-size: 15px;
    }
    .nine {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .more {
        width: 140px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #9a9ea5;
        border-radius: 6px;
        color: #999999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .except {
    width: 30px;
    height: 30px;
    // border: 1px solid black;
    position: absolute;
    bottom: -160px;
    right: 0px;
  }
  .new_upload {
    width: 98px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #409eff;
    color: #ffffff;
    font-size: 14px;
  }
}
</style>