<template>
  <div class="public_main_bgc">
    <div class="bannerSty">
      <el-carousel trigger="click" height="320px">
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/shouye_banner.mingshi.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/mingshi_banner.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="public-width pb15">
      <!-- 四名活动 -->
      <div class="public2box">
        <div class="publicLeft">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>四名活动</p>
            </div>
            <div class="titleRight">
              <p class="cp" @click="goMsSctiList">更多></p>
            </div>
          </div>

          <div class="leftMain1">
            <div class="mainCenter">
              <div class="main1Left">
                <div class="mtitle">
                  <p>四名沙龙</p>
                </div>
                <template v-if="dataList1.length">
                  <div class="imgBox cp">
                    <img class="imgbig" v-if="dataList1.length && dataList1[active1].thumb"
                      :src="dataList1[active1].thumb" alt="" @click="goMsDetails(dataList1[active1].id)">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    <div class="abdian">
                      <div class="diandf">
                        <div v-for="(its, ind) in dataList1" v-show="ind <= 2" :key="ind" class="dian"
                          :class="ind == active1 ? 'activeDian' : ''" @mouseover.stop="changeActive1(ind)"></div>
                      </div>
                    </div>
                  </div>
                  <div class="txtBox cp" @click="goMsDetails(dataList1[active1].id)">
                    <p class="ellipsis-1 mb5">{{ dataList1[active1].title }}</p>
                    <p class="ellipsis-2">{{ setText(dataList1[active1].content) }}</p>
                  </div>
                </template>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              </div>
              <div class="main1Right">
                <div class="mtitle">
                  <p> </p>
                </div>
                <div class="slrightTop df" v-if="dataList1.length >= 3">
                  <div class="imgBox cp" @click="goMsDetails(dataList1[3].id)">
                    <img class="imgbig" v-if="dataList1[3].thumb" :src="dataList1[3].thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox cp" @click="goMsDetails(dataList1[3].id)">
                    <h4 class="ellipsis-1 mb11">{{ dataList1[3].title }}</h4>
                    <p class="ellipsis-3">{{ setText(dataList1[3].content) }}</p>
                  </div>
                </div>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                <div class="mt20">
                  <template v-for="(its, ind) in dataList1">
                    <p class="ellipsis-1 cp line-h32 hoverBlue" :key="ind" v-if="ind > 3" @click="goMsDetails(its.id)">
                      <span class="fwb"><i class="el-icon-caret-right fz12 lanse"></i></span>
                      {{ its.title }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="leftMain2">
            <div>
              <div class="mtitle">
                <p>四名论坛</p>
              </div>
              <div class="main2Center">
                <div class="slrightTop df" v-if="dataList2.length">
                  <div class="imgBox cp" @click="goMsDetails(dataList2[0].id)">
                    <img class="imgbig" v-if="dataList2[0].thumb" :src="dataList2[0].thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox cp" @click="goMsDetails(dataList2[0].id)">
                    <h4 class="ellipsis-1 mb11">{{ dataList2[0].title }}</h4>
                    <p class="ellipsis-3">{{ setText(dataList2[0].content) }}</p>
                  </div>
                </div>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                <div class="mt20">
                  <template v-for="(its, ind) in dataList2">
                    <p class="ellipsis-1 cp line-h32 hoverBlue" :key="ind" v-if="ind > 0" @click="goMsDetails(its.id)">
                      <span class="fwb"><i class="el-icon-caret-right fz12 lanse"></i></span>
                      {{ its.title }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <div>
              <div class="mtitle">
                <p></p>
              </div>
              <div class="main2Center">
                <div class="slrightTop df" v-if="dataList3.length">
                  <div class="imgBox cp" @click="goMsDetails(dataList3[0].id)">
                    <img class="imgbig" v-if="dataList3[0].thumb" :src="dataList3[0].thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox cp" @click="goMsDetails(dataList3[0].id)">
                    <h4 class="ellipsis-1 mb11">{{ dataList3[0].title }}</h4>
                    <p class="ellipsis-3">{{ setText(dataList3[0].content) }}</p>
                  </div>
                </div>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                <div class="mt20">
                  <template v-for="(its, ind) in dataList3">
                    <p class="ellipsis-1 cp line-h32" :key="ind" v-if="ind > 0" @click="goMsDetails(its.id)">
                      <span class="fwb"><i class="el-icon-caret-right fz12 lanse"></i></span>
                      {{ its.title }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="publicRight">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>活动直播</p>
            </div>
            <div class="titleRight">
              <p class="cp" @click="goMsLiveList">更多></p>
            </div>
          </div>
          <div class="rightMain1">
            <template v-if="dataList4.length">
              <div v-for="(item, index) in dataList4" :key="index">
                <div class="rightTop df dfb">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    <span class="status"
                        :class="item.status == 1 ? 'bg1' : item.status == 2 ? 'bg2' : item.playback_resource_url ? 'bg3' : 'bg4'">{{
                          item.status == 1 ? '预告' : item.status == 2 ? '直播中' : item.playback_resource_url ? "回放" : "已结束"
                        }}</span>
                  </div>
                  <div class="txtbox">
                      <h4 class="ellipsis-1 mb5">{{ item.title }}</h4>
                      <p class="ellipsis-2">{{ setText(item.abstract) }}</p>
                    </div>
                </div>
                <div class="rightBom mt14 df dfb">
                  <div class="bom1">
                      <p class="mb5">{{ item.start_date }} {{ item.start_time.substring(0, 5) }}~{{
                        item.end_time.substring(0, 5) }}</p>
                      <p>观看人次：{{ item.view_count }}</p>
                    </div>
                  <div class="bom2">
                    <div class="btn cp" @click="goMsLiveDetails(item.id)">马上看</div>
                  </div>
                </div>
              </div>
            </template>
            <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
          </div>

        </div>
      </div>

      <!-- 竞赛活动 -->
      <div class="public5box mt16">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>竞赛活动</p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goMsSctiList">更多></p>
          </div>
        </div>
        <div class="public5main df" v-if="dataList6.length">
          <div class="cp" v-for="(item, index) in dataList6" :key="index" @click="goMsDetails(item.id)">
            <div class="imgBox">
              <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
              <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
            </div>
            <div class="txtBox">
              <h4 class="ellipsis-1 mb6">{{ item.title }}</h4>
              <p class="ellipsis-2 mt10">{{ setText(item.content) }}</p>
            </div>
          </div>
        </div>
        <div class="baise borderr8" v-else>
          <el-empty :image-size="50" description="暂无数据"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      dataList1: [],
      active1: 0,
      dataList2: [],
      dataList3: [],
      dataList4: [],
      dataList5: [],
      active5: 0,
      dataList6: [],
    };
  },
  created() {
    this.getAllList()
  },
  methods: {
    getAllList() {
      // 1四名沙龙
      this.getList1()
      // 2四名论坛
      this.getList2()
      // 3竞赛活动
      this.getList3()
      // 4活动直播
      this.getList4()
      // 5活动预告
      this.getList5()
      // 6竞赛活动
      this.getList6()
    },
    // 1四名沙龙
    getList1() {
      let param = {
        show_type: 1,
        type1: 1,
        limit: 9,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList1 = res.data.data.data
        if (this.dataList1.length > 0) {
          setInterval(() => {
            this.active1 = this.active1 + 1
            if (this.active1 > 2) {
              this.active1 = 0
            }
          }, 5000);
        }
      })
    },
    changeActive1(index) {
      this.active1 = index
    },

    // 2四名论坛
    getList2() {
      let param = {
        show_type: 1,
        type1: 2,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList2 = res.data.data.data
      })
    },
    // 3竞赛活动
    getList3() {
      let param = {
        show_type: 1,
        type1: 2,
        limit: 6,
        page: 2,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList3 = res.data.data.data
      })
    },

    // 4活动直播
    getList4() {
      let param = {
        limit: 3,
      }
      msAllApi.LiveList(param).then(res => {
        this.dataList4 = res.data.data.data
      })
    },

    // 5活动预告
    getList5() {
      let param = {
        limit: 3,
      }
      msAllApi.AdvanceList(param).then(res => {
        this.dataList5 = res.data.data.data
        if (this.dataList5.length > 0) {
          setInterval(() => {
            this.active5 = this.active5 + 1
            if (this.active5 > this.dataList5.length) {
              this.active5 = 0
            }
          }, 5000);
        }
      })
    },
    changeActive5(index) {
      this.active5 = index
    },

    // 6竞赛活动
    getList6() {
      let param = {
        show_type: 1,
        type1: 3,
        limit: 10,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList6 = res.data.data.data
      })
    },

    // 跳转活动列表
    goMsSctiList() {
      this.$router.push({
        name: "msSctiList",
      })
    },
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },

    // 跳转直播详情
    goMsLiveDetails(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id }
      })
    },
    // 跳转直播列表
    goMsLiveList() {
      this.$router.push({
        name: "MsLiveList",
      })
    },

    // 活动预告详情
    goMsAdvanceDetails(id) {
      this.$router.push({
        name: "MsAdvanceDetails",
        query: { id: id }
      })
    },

    setText(str) {
      if (str) {
        let words = str
          .replace(/<[^<>]+>/g, "")
          .replace(/&nbsp;/gi, "")
          .replace(/&rdquo;/gi, "")
          .replace(/&ldquo;/gi, ""); //这里是去除标签
        return words.replace(/\s/g, ""); //这里是去除空格
      }
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: left;
}

.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

// 内容样式开始
.public2box {
  display: flex;
  justify-content: space-between;

  .publicLeft {
    width: 956px;
    height: 768px;

    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .titleLeft {
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .titleRight {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #667280;
          line-height: 29px;
        }
      }
    }

    .leftMain1 {
      width: 956px;
      height: 358px;
      padding: 19px 24px;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;

      .mainCenter {
        display: flex;
        justify-content: space-between;

        .main1Left {
          width: 330px;
          height: 283px;
          border-radius: 8px;

          .mtitle {
            margin-bottom: 14px;
            height: 24px;

            p {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #222427;
            }
          }

          .imgBox {
            width: 330px;
            height: 186px;
            border-radius: 8px 8px 0 0;
            position: relative;

            img {
              border-radius: 8px 8px 0 0;
            }

            .abdian {
              width: 100%;
              position: absolute;
              bottom: 12px;
              left: 0;

              .diandf {
                width: 100%;
                display: flex;
                justify-content: center;

                .dian {
                  cursor: pointer;
                  border: 1px solid #ccc;
                  border-radius: 50%;
                  background: #84b8fb;
                  width: 10px;
                  height: 10px;
                  margin: 0 5px;

                  &:hover {
                    background: #3489FF;
                  }
                }

                .activeDian {
                  background: #3489FF;
                }
              }

            }
          }

          .txtBox {
            padding: 18px 16px;
            box-sizing: border-box;
            width: 330px;
            height: 97px;
            border-radius: 0 0 8px 8px;
            background: rgba(87, 168, 239, 1);

            p {
              color: #ffffff;

              &:first-child {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
              }

              &:last-child {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
              }
            }
          }
        }

        .main1Right {
          width: 550px;
          height: 283px;
          border-radius: 8px;

          .mtitle {
            margin-bottom: 14px;
            height: 24px;

            p {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #222427;
            }
          }

          .slrightTop {
            justify-content: space-between;

            &:hover {
              .txtbox {
                h4 {
                  color: #3489FF;
                }
              }
            }

            .imgBox {
              width: 168px;
              height: 95px;

              img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
              }
            }

            .txtbox {
              width: 360px;

              h4 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2a3037;
              }

              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
                color: #666666;
              }
            }
          }
        }
      }
    }

    .leftMain2 {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;

      >div {
        width: 470px;
        height: 342px;
        padding: 19px 24px;
        box-sizing: border-box;

        .mtitle {
          margin-bottom: 14px;
          height: 24px;

          p {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #222427;
          }
        }

        .main2Center {
          .slrightTop {
            justify-content: space-between;

            &:hover {
              .txtbox {
                h4 {
                  color: #3489FF;
                }
              }
            }

            .imgBox {
              width: 168px;
              height: 95px;

              img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
              }
            }

            .txtbox {
              width: 240px;

              h4 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2a3037;
              }

              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }

  .publicRight {
    width: 308px;
    height: 768px;

    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .titleLeft {
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .titleRight {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #667280;
          line-height: 29px;
        }
      }
    }

    .rightMain1 {
      width: 308px;
      height: 724px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;
      overflow: hidden;

      >div {
        width: 292px;
        height: 148px;
        background: rgba(238, 246, 255, 1);
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 16px 7px;
        box-sizing: border-box;

        .rightTop {
          .imgBox {
            width: 111px;
            height: 62px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .status {
              width: 52px;
              height: 20px;
              border-radius: 0 0 8px 0;
              font-size: 12px;
              color: #fff;
              text-align: center;
              line-height: 20px;
              position: absolute;
              left: 0;
              top: 0;
            }

            .bg1 {
              background: #409eff;
            }

            .bg2 {
              background: #51B875;
            }

            .bg3 {
              background: #ff7a29;
            }

            .bg4 {
              background: #999;
            }
          }

          .txtbox {
            width: 155px;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
            }
          }
        }

        .rightBom {
          .bom1 {
            p {
              &:first-child {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ff7a29;
              }

              &:last-child {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
                color: #666666;
              }

            }
          }

          .bom2 {

            div {
              margin-top: 7px;
              width: 70px;
              height: 28px;
              line-height: 28px;
              background: rgba(52, 137, 255, 1);
              border-radius: 14px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #ffffff;
              text-align: center;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .rightMain2 {
      width: 308px;
      height: 229px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      margin-top: 16px;
      padding: 18px 10px;
      box-sizing: border-box;

      .mtitle {
        margin-bottom: 14px;

        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .main2Img {
        width: 287px;
        height: 164px;
        background: linear-gradient(-45deg, rgba(180, 229, 250, 1) 0%, rgba(215, 239, 250, 1) 100%);
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 8px;
        position: relative;

        img {
          border-radius: 8px;
        }

        .abdian {
          width: 100%;
          position: absolute;
          bottom: 12px;
          left: 0;

          .diandf {
            width: 100%;
            display: flex;
            justify-content: center;

            .dian {
              cursor: pointer;
              border: 1px solid #ccc;
              border-radius: 50%;
              background: #fff;
              width: 10px;
              height: 10px;
              margin: 0 5px;

              &:hover {
                background: #029df6;
              }
            }

            .activeDian {
              background: #029df6;
            }
          }

        }
      }
    }

    .rightMain3 {
      width: 308px;
      height: 721px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;

      >div {
        width: 292px;
        height: 134px;
        background: rgba(238, 246, 255, 1);
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 16px 7px;
        box-sizing: border-box;

        h4 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2a3037;
        }

        .rightTop {
          .imgBox {
            width: 111px;
            height: 62px;

            img {
              border-radius: 4px;
            }
          }

          .txtbox {
            width: 155px;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.public5box {

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }

  .public5main {
    width: 100%;
    flex-wrap: wrap;

    >div {
      width: 243px;
      height: 246px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      margin-right: 16px;
      margin-bottom: 16px;

      &:hover {
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      .imgBox {
        width: 100%;
        height: 157px;
        padding: 12px;
        box-sizing: border-box;

        img {
          border-radius: 8px;
        }
      }

      .txtBox {
        padding: 5px 16px;
        box-sizing: border-box;
        width: 100%;

        p {
          color: #666666;

          &:first-child {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
          }

          &:last-child {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>