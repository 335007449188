import request from '@/api/request.js'
import {
  ObjfilterNull
} from "@/utils/utils.js";

export function postsidLogin(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/sidLogin',
    method: 'post',
    data: params,
  })
};

// 获取学段
export function getStage_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/stage/stage_list',
    method: 'get',
    // data: params,
    params,
  })
};
// 获取学科
export function get_subject_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/subject/list',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研活动
export function get_activities_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/activities',
    method: 'get',
    // data: params,
    params,
  })
};
export function get_activities_2_list(params) {
  // params = ObjfilterNull(params);
  return request({
    url: '/cloud/TeachingStudioActivitylist',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研直播
export function get_moreLives_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/moreLives',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研直播
export function get_moreLives_2_list(params) {
  // params = ObjfilterNull(params);
  return request({
    url: '/cloud/listTeachingStudioCourseLive',
    method: 'get',
    // data: params,
    params,
  })
};
// 研修视频
export function get_courses_more_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/courses/more',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研活动 类型
export function get_get_activity_type(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/activity_type',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 首页教研组列表
export function get_TeacherGroupIndex_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/index_list',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 教研直播
export function get_TeacherGroup_live(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/live',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 教研活动
export function get_TeacherGroup_activity(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/activity',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 教研课程
export function get_TeacherGroup_course(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/course',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 研修成果
export function get_TeacherGroup_resource_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/resource_list',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 话题研讨
export function get_TeacherGroup_groupGambit_lists(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/groupGambit/groupGambit_lists',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 教研组动态
export function get_TeacherGroup_ListTeacherGroupUserLog(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/ListTeacherGroupUserLog',
    method: 'get',
    // data: params,
    params,
  })
};
// 教研组 教研组排行
export function get_TeacherGroup_listTeacherGroupViewCount(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/listTeacherGroupViewCount',
    method: 'get',
    // data: params,
    params,
  })
};
export function get_message_get_unread_message(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/messageDel/get_unread_message',
    method: 'get',
    // data: params,
    params,
  })
};
// 收藏的资源
export function get_resource_get_get_my_collect_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/resource/get_my_collect_list',
    method: 'get',
    // data: params,
    params,
  })
};
// 我上传得
export function get_resource_get_get_my_found_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/resource/get_my_found_list',
    method: 'get',
    // data: params,
    params,
  })
};
export function get_resource_activitylink_update_status(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/activity/activitylink/update_status',
    method: 'post',
    data: params,
    // params,
  })
};
export function get_resource_TeachingStudioactivity_update_status(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioactivity/activitylink/update_status',
    method: 'post',
    data: params,
    // params,
  })
};
export function get_activity_activitylink_update_start_end_date(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/activity/activitylink/update_start_end_date',
    method: 'put',
    data: params,
    // params,
  })
};
export function get_activity_activitylink_live_update_start_end_date(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/activity/activitylink/updateLive_start_end_date',
    method: 'put',
    data: params,
    // params,
  })
};
export function get_activity_TeachingStudio_idnexTeachingStudio(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudio/idnexTeachingStudio',
    method: 'get',
    // data: params,
    params,
  })
};
export function get_activity_TeachingStudio_reception_list_nav(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudio/reception_list',
    method: 'get',
    // data: params,
    params,
  })
};
export function get_activity_TeachingStudio_edit_reception_list(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudio/edit_reception_list',
    method: 'put',
    data: params,
    // params,
  })
};
export function get_activity_TeachingStudio_edit_reception_div(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudio/edit_reception',
    method: 'post',
    data: params,
    // params,
  })
};
export function get_activity_TeachingStTeacherGroup_audit_status_count(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeacherGroup/TeacherGroup_audit_status_count',
    method: 'get',
    // data: params,
    params,
  })
};
// 审核
export function get_activity_TeachingStudiolive_audit_status_count(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudiolive/audit_status',
    method: 'put',
    data: params,
    // params,
  })
};
// 置顶
export function get_activity_TeachingStudiolive_top_div(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudiolive/top',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核
export function get_activity_TeachingStudioCourse_status_count(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioCourse/audit_status',
    method: 'put',
    data: params,
    // params,
  })
};
// 置顶
export function get_activity_TeachingStudioCourse_top_div(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioCourse/top',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核
export function get_activity_TeachingStudioActivity_status_count(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioActivity/audit_status',
    method: 'put',
    data: params,
    // params,
  })
};
// 置顶
export function get_activity_TeachingStudioActivity_top_div(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioActivity/top',
    method: 'put',
    data: params,
    // params,
  })
};
// 置顶
export function get_activity_TeachingStudioResource_top_div(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioResource/top',
    method: 'put',
    data: params,
    // params,
  })
};
// 置顶
export function get_activity_TeachingStudioResource_statusResource(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioResource/statusResource',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核直播
export function get_activity_my_1_group_live_review(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/my_group_live_review',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核直播
export function get_activity_my_1_audit_status(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudiolive/audit_status',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核活动
export function get_activity_my_2_my_group_activity_review(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/my_group_activity_review',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核活动
export function get_activity_my_2_my_audit_status(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioActivity/audit_status',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核课程
export function get_course_my_2_my_group_course_review(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/my_group_course_review',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核课程
export function get_course_my_2_my_group_course_3_review(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudioCourse/audit_status',
    method: 'put',
    data: params,
    // params,
  })
};
// 审核话题
export function get_course_my_3_my_group_gambit_review(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/my_group_gambit_review',
    method: 'put',
    data: params,
    // params,
  })
};
export function get_course_my_4_my_group_gambit_review(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/TeachingStudio/statusGambit',
    method: 'post',
    data: params,
    // params,
  })
};

export function getUser_tencent_docs_url_get(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/tencent/auth_collaborators_add',
    method: 'post',
    data: params,
    // params,
  })
};
export function getUser_tencent_docs_login(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/tencent/docs_login',
    method: 'get',
    // data: params,
    params,
  })
};
export function getUser_activitylink_docs_addTencentDocs(params) {
  // params = ObjfilterNull(params);
  return request({
    url: '/activity/activitylink/addTencentDocs',
    method: 'post',
    data: params,
    // params,
  })
};
export function getUser_tencent_resource_info_show(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/resource/show',
    method: 'get',
    // data: params,
    params,
  })
};
// 收藏/取消收藏资源
export function getUser_activitylink_resource_post_collect_resource(params) {
  // params = ObjfilterNull(params);
  return request({
    url: '/resource/collect_resource',
    method: 'post',
    data: params,
    // params,
  })
};

// 绩效管理 横幅获取
export function jx_api_TeachingStudioAppraisal_my_partake(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudioAppraisal/my_partake',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 列表
export function jx_api_TeachingStudioAppraisal_get_lists(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudioAppraisal/lists',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 详情
export function jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInfoUser_show(params) {
  params = ObjfilterNull(params);
  return request({
    url: '/index/TeachingStudioAppraisal/TeachingStudioAppraisalInfoUser/show',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 保存
export function jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInfoUser_post(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/TeachingStudioAppraisalInfoUser/push',
    method: 'post',
    data: params,
  })
};
// 绩效管理 绩效考核详情
export function jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInf_get_show(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/show',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 审核日志
export function jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalExamine_examine_log(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/TeachingStudioAppraisalExamine/examine_log',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 指标详情
export function jx_api_TeachingStudioAppraisal_zb_detailList_examine_log(params) {
  return request({
    url: '/index/zb/detailList',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 保存/提交指标数据
export function jx_api_TeachingStudioAppraisal_goSubmitlInfoUser_post(params) {
  return request({
    url: '/index/zb/goSubmit',
    method: 'post',
    data: params,
  })
};

// 绩效管理 获取工作室审核状态
export function jx_api_TeachingStudioAppraisal_zb_detailList_get_examine_st(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/TeachingStudioAppraisalExamine/get_examine',
    method: 'get',
    // data: params,
    params,
  })
};
// 绩效管理 提交
export function jx_api_TeachingStudioAppraisal_zb_detailList_post_examine_submit(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/TeachingStudioAppraisalExamine/submit',
    method: 'put',
    data: params,
    // params,
  })
};

// 绩效管理 获取工作室审核状态
export function jx_api_export_appraisal(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/export_appraisal',
    method: 'get',
    // data: params,
    params,
  })
};

// 绩效管理 获取工作室审核状态
export function jx_api_logs_filesList(params) {
  return request({
    url: '/index/TeachingStudioAppraisal/get_logs_export_files_list',
    method: 'get',
    // data: params,
    params,
  })
};