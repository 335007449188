<template>
  <div class="problem-finish">
    <div class="finish-icon">
      <img src="../../../../../assets/images/personal/success.png" />
    </div>
    <h4 class="finish-title">创建成功</h4>
    <p class="finish-operation">
      <span class="operation-button" @click="createButton()">继续创建>></span>
    </p>

    <div class="finish-close">
      <p class="close-text">{{countdown}}秒后自动关闭页面</p>
      <span class="close-button" @click="closeButton()">直接关闭页面>></span>
    </div>
  </div>
</template>

<script>
import Finish from "./finish.js";
export default Finish;
</script>

<style lang="less" scoped>
@import "./finish.less";
</style>