<template>
  <div class="public_css_module livePage personal_right_box" v-if="is_hide == 1 ? showPage : true">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的直播</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'"
          @click="showData(item.id)">
          <span v-if="item.id != 2 && item.id != 4">{{ item.name }}</span>
          <el-dropdown trigger="click" v-if="item.id == 2" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">审核中</el-dropdown-item>
              <el-dropdown-item command="4">已通过</el-dropdown-item>
              <el-dropdown-item command="5">未通过</el-dropdown-item>
              <!-- <el-dropdown-item command="6">已过期</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="hover" v-if="item.id == 4" @command="handleCommand2">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="">全部</el-dropdown-item>
              <el-dropdown-item command="1">未开始</el-dropdown-item>
              <el-dropdown-item command="2">进行中</el-dropdown-item>
              <el-dropdown-item command="3">已结束</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="rightLink" @click="goAdd">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>创建直播</span>
      </div>
    </div>

    <div class="item_list_3_box" v-loading="listLoading">
      <div class="listBox_div">
        <template v-for="(item, index) in getList">
          <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="2"
            :currentIndex="index" :item="item" @editClickLive="editClickLive" @delClick_3="delClick_3"
            @live_RefreshPage="init()"></PublicTtemDiv>
        </template>
      </div>

      <CommonEmpty v-if="!listLoading && !getList.length" hVal="200px"></CommonEmpty>

      <div v-show="total" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { deepClone } from "@/utils/utils.js";
export default {
  data() {
    return {
      is_hide: "",
      showPage: false,
      listLoading: true, //表格Loading
      currentNum: 0,
      total: 0,
      uid: 0,
      getList: [],
      form: {
        limit: 12,
        page: 1,
        type: 0,
        audit_status: "",
        status: "",
        search_status: ""
      },
      // 筛选
      selectData: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 2,
          name: "我创建的",
        },
        {
          id: 1,
          name: "我参与的",
        },
        {
          id: 3,
          name: "待我审核",
        },
        {
          id: 4,
          name: "状态",
        },
      ],
      yun_url: ""
    };
  },
  created() {
    this.is_hide = sessionStorage.getItem('is_hide')
    let query = this.$route.query;
    if (query.pageType) {
      this.$set(this.form, "type", Number(query.pageType));
      this.currentNum = Number(query.pageType);
    };
    if (query.statusType) {
      this.$set(this.form, "audit_status", Number(query.statusType));
    };
    // 从缓存中得到用户id
    this.uid = localStorage.getItem("id");
    if (localStorage.getItem('yun_url')) {
      this.yun_url = localStorage.getItem('yun_url')
    }
    this.init();
  },
  methods: {
    goAdd() {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/newLive`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/newLive"
        })
      }
    },
    // 初始化数据
    async init() {
      this.listLoading = true;
      try {
        let reqObj = deepClone(this.form);
        if (reqObj.type == 3) {
          this.$set(reqObj, "status", 1);
          this.$set(reqObj, "type", 0);
        };
        let res = await this.$axios.get("/live/get_my_list", {
          params: reqObj,
        });
        this.total = res.data.data.total;
        this.getList = res.data.data.data;
        this.$nextTick(() => {
          this.showPage = true
          this.listLoading = false;
        });
      } catch (error) {
        this.getList = [];
        this.total = 0;
        this.$nextTick(() => {
          this.showPage = true
          this.listLoading = false;
        });
      }
    },
    // 下拉
    handleCommand(type) {
      this.currentNum = 2;
      this.$set(this.form, "type", 2);
      this.$set(this.form, "page", 1);
      this.$set(this.form, "audit_status", type);
      this.init();
    },
    handleCommand2(type) {
      this.currentNum = 4;
      this.$set(this.form, "type", 0);
      this.$set(this.form, "page", 1);
      this.$set(this.form, "search_status", type);
      this.init();
    },
    // 切换
    showData(id) {
      if (id != 2 && id != 4) {
        this.currentNum = id;
        this.$set(this.form, "type", id);
        this.$set(this.form, "page", 1);
        this.$set(this.form, "audit_status", "");
        this.$set(this.form, "status", "");
        this.init();
      }
    },
    //跳转直播详情页
    toLiveDetail(id, item) {
      if (item.is_personid == 1) {
        // 教研组直播详情
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/livedetail?liveid=${id}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/livedetail?liveid=${id}`);
        }
      } else {
        // 四名直播详情
        localStorage.setItem("studioId", item.teaching_studio_id);
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/AdminLiveDetail?lid=${id}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/AdminLiveDetail?lid=${id}`);
        }
      }
    },
    //跳转到编辑页
    editClickLive(id) {
      // 从路由原信息得到直播id传过去
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/newLive?id=${id}`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/newLive",
          query: {
            id: id,
          },
        });
      }
    },
    //删除 直播
    delClick_3(id, item) {
      this.$confirm("此操作将删除该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_3(id, item);
      }).catch(() => { });
    },
    //删除直播
    async delActivity_3(id, item) {
      let user_id = localStorage.getItem("id");
      let res = await this.$axios.delete(`/live/delLive/${id}/${user_id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.init();
    },
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.listBox_div {
  padding-top: 18px;
  padding-left: 34px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .list_item {
    width: 356px;
  }

  /deep/ .list_item {
    width: 356px;
  }
}

.livePage {
  width: 100%;
  background-color: #fff;
}
</style>