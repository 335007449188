export default {
  props: {
    //题目
    subject: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      subjectList: [], //题目列表
    };
  },
  created() {
    this.intSubject();
  },
  mounted() {},
  methods: {
    //初始化题目
    intSubject() {
      this.subject.topics.forEach((item) => {
        /**选择题 单选 */
        if (item.type == 1 && item.selected_type == 1) {
          item.options.forEach((item1) => {
            if (item1.selected) {
              item.option_id = item1.id;
            }
          });
        }
        /**选择题 多选 */
        if (item.type == 1 && item.selected_type == 2) {
          item.options.forEach((item1) => {
            if (item1.selected) {
              item1.select = true;
            }
          });
        }
        /**量表题 */
        if (item.type == 4) {
          if (item.selected_type == 1 || item.selected_type == 3) {
            item.lines.forEach((item1) => {
              console.log(item1,"数据问题");
              if(item1.selected.length !=0){
                item1.option_id = item1.selected[0].option_id;
                item1.option_content = item1.selected[0].option_content;
              }
            });
          }
          //量标题 多选矩阵
          if (item.selected_type == 2) {

            //设置禁止选中
            item.options.forEach((item1) => {
              item1.select = false;
            });

            //循环题目
            item.lines.forEach((item1) => {
              //设置选项
              this.$set(item1,"selectOption",item.options)
              //设置选中的
              this.$set(item1,"select",[])

              //多选
              item1.selected.forEach((s)=>{
                  item1.select.push(s.option_id)
                  //寻找下标
                  let idx3=item1.selectOption.findIndex((item3)=>{
                    return item3.id == s.option_id
                  })
                  // console.log(idx3,"下标");
                  if(s.option_content && idx3 != -1){
                    item1.selectOption[idx3].option_content=s.option_content
                  }
              })
              // let idx2=item1.selectOption.
              console.log(item1,"测试问题");
          });

          }
        }
      });

      this.subjectList = this.subject.topics;
    },
  },
  watch: {},
  filters: {
    //题型过滤
    subjectType(type, single) {
      if (type == 1) {
        if (single == 1) {
          return "单选题";
        } else {
          return "多选题";
        }
      }
      if (type == 2) {
        return "填空题";
      }
      if (type == 3) {
        return "排序题";
      }
      if (type == 4) {
        return "量表题";
      }
    },
  },
};
