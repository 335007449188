<template>
  <!-- 修改资源 -->
  <div class="updateBox personal_right_box">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">修改资源</div>
      </div>
    </div>

    <div class="updateDiv">
      <div class="one_a">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="上传文件" :required="true">
            <dc-upload class="upload-demo" ref="upload" :dcAutoUpload="true" dcdom="ceshi01" :dcConfig="dcConfig"
              @dc-success="dcFile" v-if="isUpOk">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>

              <div slot="tip" class="el-upload__tip"></div>
            </dc-upload>
            <!-- 文件 -->
            <div class="getfile" v-if="isHide == 0">
              <div class="item_list">
                <!-- 左边 -->
                <div class="_left">
                  {{ getAll.title }}
                </div>
                <!-- 右边 -->
                <div class="_right">
                  <span style="color: #409eff" @click="isHide = 1">删除</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="资源名称" :required="true">
            <el-input v-model="getAll.title" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="资源类型" :required="true">
            <el-select v-model="getAll.sources" placeholder="请选择资源">
              <el-option v-for="item in List" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段学科" :required="true">
            <el-select v-model="getAll.pharse_id" placeholder="请选择学段" style="margin-right: 10px"
              @change="changeModel(1)">
              <el-option v-for="item in List1" :key="item.id" :label="item.name" :value="item.id"
                @click.native="getList2(item.name)">
              </el-option>
            </el-select>

            <el-select v-model="getAll.subject" placeholder="请选择学科" style="margin-right: 10px" @change="changeModel(2)">
              <el-option v-for="item in List2" :key="item.subjectId" :label="item.subjectName" :value="item.subjectId"
                @click.native="getList3()">
              </el-option>
            </el-select>
            <el-select v-model="getAll.grade" placeholder="请选择年级">
              <el-option v-for="item in List3" :key="item.gradeId" :label="item.gradeName" :value="item.gradeId"
                @click.native="getList4()">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="适用教材" :required="true">
            <el-select v-model="getAll.edtion" placeholder="请选择版本" style="margin-right: 10px">
              <el-option v-for="item in List4" :key="item.Id" :label="item.Name" :value="item.Id" @click.native="getList5()">
              </el-option>
            </el-select>
            <el-select v-model="getAll.chapter_id" placeholder="请选择章节">
              <el-option v-for="item in List5" :key="item.oid" :label="item.name" :value="item.oid" @click.native="getList6()">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区年份" :required="true">
            <el-select v-model="getAll.area" placeholder="请选择地区" style="margin-right: 10px">
              <el-option v-for="item in List6" :key="item.area" :label="item.area" :value="item.area" @click.native="getList7()">
              </el-option>
            </el-select>
            <el-select v-model="getAll.year" placeholder="请选择年份">
              <el-option v-for="item in List7" :key="item.year" :label="item.year" :value="item.year">
              </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="six">
        <p>注意事项：</p>
        <p>
          1.文件支持格式：
          doc、docx、xls、xlsx、ppt、pptx、pdf、txt、flv、mp4、mpg、gif、jpg、jpeg、png
        </p>
        <p>2.单个文件大小：≤ 200M</p>
      </div>
      <div class="save">
        <div class="save_one cursor" @click="goBack()">取消</div>
        <div class="save_two cursor" @click="editSource()">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 控制删除
      isHide: 0,
      //初始化数据
      getAll: "",
      //  资源类型数组
      List: "",
      List1: "", //  资源类型数组
      List2: "", //  资源类型数组
      List3: "", //  资源类型数组
      List4: "", //  资源类型数组
      List5: "", //  资源类型数组
      List6: "", //  资源类型数组
      List7: "", //  资源类型数组
      List8: "", //  资源类型数组
      List9: "", //  资源类型数组

      isUpOk: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      form: {
        id: "",
        subject: "",
        grade: "",
        chapter_id: "",
        title: "",
        area: "",
        year: "",
        sources: "",
        uri: "",
        term: "",
      },
    };
  },

  created() {
    let eid = this.$route.query.id;
    //初始化时把资源id给到form.id
    this.form.id = this.$route.query.id;
    this.getEditData(eid);
    //是初始化金山云
    this._getAddress();
    // 获取资源类型
    this.getResourceType();
    //获取学段
    this.getList1();
  },
  methods: {
    changeModel(val) {
      if (val == 1) {
        this.getAll.subject = ""
        this.getAll.grade = ""
      }
      if (val == 2) {
        this.getAll.grade = ""
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    //编辑资源
    async editSource() {
      let obj = {
        title: this.getAll.title,
        sources: this.getAll.sources.toString(),
        grade: this.getAll.grade.toString(),
        subject: this.getAll.subject.toString(),
        pharse: this.getAll.pharse_id.toString(),
        url: this.form.uri,
        resource_id: this.form.resource_id,
        id: Number(this.form.id),
      };
      console.log(obj);
      let res = await this.$axios.put("resources/editResource", obj);
      if (res.status == 200) {
        this.$message.success("修改成功");
        this.$router.push("/resource");
      }
    },
    //获取stage/stage_list
    async getList1() {
      let res = await this.$axios.get("stage/stage_list");
      this.List1 = res.data.data;
    },
    //获取学科
    async getList2() {
      let res = await this.$axios.get("subject/list", {
        params: {
          pharseid: this.getAll.pharse_id,
        },
      });
      this.List2 = res.data.data;
    },
    //获取年级
    async getList3() {
      let res = await this.$axios.get("grade/list", {
        params: {
          pharseid: this.getAll.pharse_id,
          subjectId: this.getAll.subject,
        },
      });
      this.List3 = res.data.data;
    },
    //版本
    async getList4() {
      let res = await this.$axios.get("versions/list", {
        params: {
          pharseid: this.getAll.pharse_id,
          subjectId: this.getAll.subject,
          gradeId: this.getAll.grade,
        },
      });
      this.List4 = res.data.data;
    },
    // 章节
    async getList5() {
      let res = await this.$axios.get("chapter/list", {
        params: {
          pharseid: this.getAll.pharse_id,
          subjectid: this.getAll.subject,
          gradeid: this.getAll.grade,
          editionid: this.getAll.edtion,
        },
      });
      this.List5 = res.data.data;
    },
    // 地区
    async getList6() {
      let res = await this.$axios.get("resourceArea", {
        params: {
          pharse: "",
          subject: "",
        },
      });
      this.List6 = res.data.data;
    },
    // 年份
    async getList7() {
      let res = await this.$axios.get("resourceYear", {
        params: {
          pharse: "",
          subject: "",
        },
      });
      this.List7 = res.data.data;
    },
    // 资源类型
    async getResourceType() {
      let res = await this.$axios.get("resourceTypes");
      this.List = res.data.data;
    },
    // 获取编辑的编辑资源信息
    async getEditData(eid) {
      let res = await this.$axios.get("resources/getEditResourceInfo", {
        params: {
          id: eid,
        },
      });
      console.log(res.data.data, "编辑资源信息");
      this.getAll = res.data.data;
      this.form.uri = this.getAll.url;
      this.form.resource_id = this.getAll.resource_id;
      //将获取到的资源类型转为数字
      this.getAll.sources = Number(this.getAll.sources_id);
      //将获取到的学段学科之类的id转化为数字,才能自动选中默认项
      this.getAll.pharse_id = Number(this.getAll.pharse);
      this.getAll.subject = Number(this.getAll.subject);
      this.getAll.grade = Number(this.getAll.grade);
      // this.getAll.edtion = Number(this.getAll.edtion);
      // this.getAll.chapter_id = Number(this.getAll.chapter_id);
      //调用接口
      this.getList2();
      this.getList3();
      // this.getList4();
      // this.getList5();
      // this.getList6();
      // this.getList7();
    },
    //   //修改资源
    // async editResource(){
    //    let res = await this.$axios.put("resources/editResource",this.form2)

    //  },
    //上传失败的时候会出现Bug，故在上传失败的时候也进行一下判断
    error: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传视频资源成功回调
    async dcFile(data) {
      //上传成功就清空原始数据
      // this.getAll="",
      this.loading = true;
      console.log(data);
      var etagString = data.upload.CompleteMultipartUploadResult.ETag;

      var param = {
        etag: etagString.slice(1, etagString.length - 1),
      };
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: param.etag,
        },
      });
      if (res.status == 200) {
        this.isHide = 1;
      }
      this.form.resource_id = res.data.data.id;
      this.form.uri = data.upload.CompleteMultipartUploadResult.Location;
    },
  },
};
</script>

<style lang="less" scoped>
.updateBox {
  background-color: #fff;
}

.updateDiv {
  border: 1px solid #fff;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;

  .el-form {
    .getfile {
      .item_list {
        width: 600px;
        justify-content: space-between;
        cursor: pointer;
        display: flex;

        &:hover {
          box-shadow: 1px 1px 1px 1px#ececec;
        }

        // 左边
        ._left {
          flex: 1;
        }

        //  右边
        ._right {
          width: 50px;
        }
      }
    }
  }

  .six {
    border-top: 1px dotted#999999;
    padding-top: 20px;
    margin-top: 20px;

    // margin-bottom:50px;
    p:nth-child(2) {
      color: #999999;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    p:nth-child(3) {
      color: #999999;
    }
  }

  .save {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    .save_one {
      width: 148px;
      height: 42px;
      background: #f7f7f7;
      border: 1px solid #ececec;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      margin-right: 22px;
    }

    .save_two {
      width: 148px;
      height: 42px;
      background: #ff8201;
      border-radius: 6px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>