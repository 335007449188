<template>
  <div class="TeachIndex">
    <!-- 导航 -->
    <div class="dh">
      <div class="w_layout topText">
        <div class="title">
          <p> 当前位置：四名工作室 > <span>四名讲堂</span> </p>
        </div>
        <!-- 筛选 -->
        <div class="selectBox">
          <div class="selectNavBox">
            <div class="sTitle">类型：</div>
            <div class="listBox" :class="activeClass == item.id ? 'activeBox' : ''" v-for="(item, index) in typeList"
              :key="index" @click="changeType(item)">
              {{ item.name }}
            </div>
          </div>
          <div v-if="form.search_type === 0" class="selectNavBox active">
            <div class="sTitle">活动态状：</div>
            <div class="listBox" :class="form.search_status == item.id ? 'activeBox' : ''"
              v-for="(item, index) in stateArrList" :key="index" @click="handleStateChange(item)">
              {{ item.name }}
            </div>
          </div>

          <div v-if="form.search_type == 'all'" class="selectNavBox active">
            <div class="sTitle">课程分类：</div>
            <div class="listBox" :class="form.search_type2 == item.id ? 'activeBox' : ''"
              v-for="(item, index) in newTypeList" :key="index" @click="newtypeChange(item)">
              {{ item.name }}
            </div>
          </div>

          <div v-if="detailData.role != 0" class="creBtn_div">
            <el-button type="primary" style="margin-right:10px;background:#3489fe"
              @click="handlePageAdd(2)">创建直播</el-button>
            <el-button type="primary" style="background:#3489fe" @click="handlePageAdd(1)">创建录播课</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="w_layout middleClass">
      <div v-if="total > 0">
        <div class="mainBox">
          <template v-for="(item, index) in listData">
            <PublicTtemDiv v-if="item.live == 1" :key="index" :isStudioModule="1" :activeBor="true" :handleBtn="true"
              :navActive="3" :currentIndex="index" :item="item" @editClick_1="editClick_1" @delClick_1="delClick_1"
              @pushClick="pushClick" @toDetailPage="toDetailPage" @course_RefreshPage="getTeachList()"
              @toPageEvent_3="onGoDetail">
            </PublicTtemDiv>

            <PublicTtemDiv v-if="item.live != 1" :key="index" :isStudioModule="1" :activeBor="true" :handleBtn="true"
              :navActive="2" :currentIndex="index" :item="item" @editClickLive="editClickLive" @delClick_3="delClick_3"
              @live_RefreshPage="getTeachList()" @toPageEvent_3="onGoDetail">
            </PublicTtemDiv>
          </template>
        </div>

        <div v-show="total" class="pagina_div" style="height:100px">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>

      </div>
      <CommonEmpty v-else />
    </div>
  </div>
</template>

<script>
import { deepClone } from "@/utils/utils.js";
export default {
  data() {
    return {
      stateArrList: [
        { id: "", name: '全部' },
        { id: 1, name: '未开始' },
        { id: 2, name: '进行中' },
        { id: 3, name: '已结束' },
      ],
      newTypeList: [
        { id: "", name: '全部' },
        { id: 1, name: '上课' },
        { id: 2, name: '说课' },
        { id: 3, name: '讲座' },
        // { id: 4, name: '听评课' },
        // { id: 5, name: '集中备课' },
        { id: 6, name: '其他' },
      ],
      //列表数据
      listData: [],
      total: 0,
      form: {
        limit: 12,
        page: 1,
        search_teaching_studio_id: localStorage.getItem("studioId"),
        search_type: "",
        search_type2: "",
        user_id: localStorage.getItem("id"),
        search_status: "",
      },
      activeClass: "996",
      typeList: [
        {
          id: "996",
          name: "全部",
        },
        {
          id: 0,
          name: "直播课",
        },
        {
          id: 'all',
          name: "录播课",
        },
      ],
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
    };
  },
  created() {
    this.init();
    this.getAdminData();
  },
  methods: {
    //删除 直播
    delClick_3(id, item) {
      this.$confirm("此操作将删除该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_3(id, item);
      }).catch(() => { });
    },
    //删除直播
    async delActivity_3(id, item) {
      let user_id = localStorage.getItem("id");
      let res = await this.$axios.delete(`/live/delLive/${id}/${user_id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.getTeachList();
    },
    //跳转到编辑页
    editClickLive(id) {
      // 从路由原信息得到直播id传过去
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/newLive?id=${id}`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/newLive",
          query: {
            id: id,
          },
        });
      }
    },
    //跳转到详情页
    toDetailPage(id, tid, fid, item, studioId) {
      let is_from = "";
      localStorage.setItem("studioId", studioId);
      // group_id，其他：教研组活动详情,0：四名活动详情
      if (item.is_personid == 1) {
        is_from = 1;
      } else {
        is_from = 2;
      }
      //  group_id == 0 ? 2 : 1;
      // 常规4块
      if (tid == 1 || tid == 2 || tid == 3 || tid == 0) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/goclassdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/goclassdetail?id=${id}&is_from=${is_from}`);
        }
      } else if (tid == 4 && fid == 1) {
        // 线下听评课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/listeningcommentdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(
            `/listeningcommentdetail?id=${id}&is_from=${is_from}`
          );
        }
      } else if (tid == 4 && fid == 2) {
        // 线上听评课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/listeningcommentdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(
            `/listeningcommentdetail?id=${id}&is_from=${is_from}`
          );
        }
      } else if (tid == 5) {
        //集中备课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/preparelessons?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/preparelessons?id=${id}&is_from=${is_from}`);
        }
      }
    },
    //发布课程
    pushClick(id, item) {
      this.$axios.put(`courses/${id}/publish?is_personid=${item.is_personid}`).then(() => {
        this.$message({
          message: "发布成功",
          type: "success",
        });
        //初始化课程列表
        this.getTeachList();
      });
    },
    // 添加课程
    handlePageAdd(num) {
      if (num == 1) {
        this.$router.push({
          path: "/CreateRecordSet",
          query: {
            is_personid: 2,
            teaching_studio_id: localStorage.getItem("studioId"),
          },
        });
      };
      if (num == 2) {
        this.$router.push({
          path: "/CreateLiveSet",
          query: {
            is_personid: 2,
            studioId: localStorage.getItem("studioId"),
          },
        });
      };
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    //跳转到编辑页
    editClick_1(id) {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/updataCourseInfo?id=${id}`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push(`/updataCourseInfo?id=${id}`);
      }
    },
    //删除课程
    async delActivity_1(id, item) {
      try {
        let res = await this.$axios.delete("/courses/delCourse", {
          params: {
            id: id,
            is_personid: item.is_personid,
          },
        });
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getTeachList();
      } catch (error) {
      }
    },
    //删除课程
    delClick_1(id, item) {
      this.$confirm("此操作将删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_1(id, item);
      }).catch(() => { });
    },
    init() {
      this.getTeachList();
    },
    //四名讲堂列表
    getTeachList() {
      let reqObj = deepClone(this.form);
      if (reqObj.search_type == 996) {
        reqObj.search_type = "";
      }
      if (reqObj.search_type == 'all' && reqObj.search_type2) {
        reqObj.search_type = reqObj.search_type2;
      }
      this.$axios.get("index/home/listTeachingStudioCourseLive", { params: reqObj, }).then((res) => {
        this.listData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    // 去详情
    onGoDetail(item) {
      if (item.live) {
        // 录播详情
        switch (Number(item.type)) {
          //上课
          case 1:
            let routeData1 = this.$router.resolve({
              name: "goclassdetail",
              query: {
                id: item.id,
                is_from: 2,
                is_show_head: 1,
                teaching_studio_id: item.teaching_studio_id || "",
              },
            });
            window.open(routeData1.href, '_blank');
            break;
          // 说课
          case 2:
            let routeData2 = this.$router.resolve({
              name: "goclassdetail",
              query: {
                id: item.id,
                is_from: 2,
                is_show_head: 1,
                teaching_studio_id: item.teaching_studio_id || "",
              },
            });
            window.open(routeData2.href, '_blank');
            break;
          // 讲座
          case 3:
            let routeData3 = this.$router.resolve({
              name: "goclassdetail",
              query: {
                id: item.id,
                is_from: 2,
                is_show_head: 1,
                teaching_studio_id: item.teaching_studio_id || "",
              },
            });
            window.open(routeData3.href, '_blank');
            break;
          // 听评课
          case 4:
            let routeData4 = this.$router.resolve({
              name: "listeningcommentdetail",
              query: {
                id: item.id,
                is_from: 2,
                is_show_head: 1,
                teaching_studio_id: item.teaching_studio_id || "",
              },
            });
            window.open(routeData4.href, '_blank');
            break;
          // 集中备课
          case 5:
            let routeData5 = this.$router.resolve({
              name: "preparelessons",
              query: {
                id: item.id,
                is_from: 2,
                is_show_head: 1,
                teaching_studio_id: item.teaching_studio_id || "",
              },
            });
            window.open(routeData5.href, '_blank');
            break;
          // 其他
          case 6:
            let routeData6 = this.$router.resolve({
              name: "goclassdetail",
              query: {
                id: item.id,
                is_from: 2,
                is_show_head: 1,
                teaching_studio_id: item.teaching_studio_id || "",
              },
            });
            window.open(routeData6.href, '_blank');
            break;
        }
      } else {
        // 直播详情
        let routeData6 = this.$router.resolve({
          path: "/livedetail",
          query: {
            liveid: item.id,
            is_personid: 2,
            is_from: 2
          },
        });
        window.open(routeData6.href, '_blank');
      }
    },
    changeType(item) {
      this.activeClass = item.id;
      this.$set(this.form, "search_status", "");
      this.$set(this.form, 'search_type2', "")
      this.$set(this.form, "search_type", item.id);
      this.$set(this.form, "page", 1);
      this.init();
    },
    handleStateChange(item) {
      this.$set(this.form, "search_status", item.id);
      this.$set(this.form, "page", 1);
      this.init();
    },
    newtypeChange(item) {
      this.$set(this.form, "search_type2", item.id);
      this.$set(this.form, "page", 1);
      this.init();
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    // 跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 90px;
  height: 62px;
  top: -20px;
  right: -36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  transform: rotate(45deg);
  background-color: #ff8201;
  color: #fff;

  &.tios_div_box_2_div {
    background-color: #0080ff;
  }

  &.tios_div_box_3_div {
    background-color: #ff8201;
  }

  &.tios_div_box_4_div {
    background-color: #ff0607;
  }

  &.tios_div_box_5_div {
    background-color: #0080ff;
  }

  &.tios_div_box_6_div {
    background-color: #ff0607;
  }

  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }
}

.TeachIndex {
  padding-bottom: 30px;

  .dh {
    width: 100%;

    .topText {
      .title {
        width: 100%;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #999999;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;

        p {
          span {
            color: #ff8201;
          }
        }
      }

      .selectNavBox {
        width: 100%;
        display: flex;
        align-items: center;

        &.active {
          margin-top: 20px;
        }
      }

      //筛选
      .selectBox {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        margin-bottom: 25px;
        padding: 25px;
        border-radius: 6px;
        position: relative;

        .creBtn_div {
          position: absolute;
          top: 20px;
          right: 20px;
        }

        .sTitle {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }

        .listBox {
          display: flex;
          align-items: center;
          height: 30px;
          margin-right: 10px;
          border-radius: 4px 3px 4px 4px;
          padding: 0 8px;
        }

        .activeBox {
          display: flex;
          align-items: center;
          height: 30px;
          background: #3489fe;
          border-radius: 4px 3px 4px 4px;
          padding: 1px 8px;
          color: #fff;
        }
      }
    }
  }
}

.middleClass {
  .mainBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    /deep/ .active_2 {
      height: 416px !important;
    }

    /deep/ .details_div {
      box-sizing: border-box;
      padding-right: 6px !important;
    }
  }
}
</style>