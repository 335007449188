<template>
  <div class="subject-body">
    <div class="subject-tabs">
      <span :class="index == tabsIndex ? 'subject-tabs-item-activity' : 'subject-tabs-item'" v-for="(item, index) in tabs" :key="index" @click="tabsChange(index)">{{item}}</span>
    </div>
    <div class="subject-main">
      <h3 class="subject-title">{{data.title}}</h3>
      <SubjectList
        :subjectId="data.questionnaire_id"
        :subject="data.questionnaire_data"
        :peroration="data.closingstxt"
        :state="state"
        :appName="appName"
        @onSubmit="onSubmit"
        v-if="!data.finish_status && tabsIndex == 0"
        v-bind="$attrs"
      ></SubjectList>

      <SubjectAnswer
        :subject="data.questionnaire_data"
        v-if="data.finish_status && tabsIndex == 0"
      ></SubjectAnswer>

      <template v-if="tabsIndex == 1">
        <SubjectStatistics
          :statistics="data.options_questionnaire_data"
          v-if="isCreator || isOpenResult"
        ></SubjectStatistics>
        <div v-else>
          <p class="subject-tips">{{appName}}结果未公开</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Subject from "./subject.js";
export default Subject;
</script>

<style lang="less" scoped>
@import "./subject.less";
</style>