export default {
  props: {
    //统计
    statistics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormTitle: "",
      getSubjectWritingStatistics: "",
      filter: {
        topic_id: "",
        option_id: "",
        search_content: "",
      },

      // isSelect:true,
    };
  },
  created() {
    this.getSubjectWritingStatistics = function Getchapter_second(params) {
      return this.$axios({
        url: "/questionnaire/fill_in_contents",
        method: "get",
        params,
      }).then((res) => {
        return res.data;
      });
    };
  },
  mounted() {},
  methods: {
    //填空题查看详情
    details(option_id, topic_id, title) {
      this.dialogFormTitle = title;
      this.filter.topic_id = topic_id;
      this.filter.option_id = option_id;
      this.dialogFormVisible = true;
    },
  },
  watch: {},
  filters: {
    //题型过滤
    subjectType(type, single) {
      if (type == 1) {
        if (single == 1) {
          return "单选题";
        } else {
          return "多选题";
        }
      }
      if (type == 2) {
        return "填空题";
      }
      if (type == 3) {
        return "排序题";
      }
      if (type == 4) {
        return "量表题";
      }
    },
  },
};
