<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth1">
      <div class="public-width">

        <div class="public-width pb30">
          <div class="mainBox5 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aserviceImg/pinggu.png" alt="">
            </div>
            <div class="pingguBox mt20">
              <p>评估服务依托四名工作室绩效考核系统进行，该系统可实现各地区工作室年度考核、
                周期考核的全流程信息化管理，将过程性考核与结果性评价相结合，使考核的过程更加科学，
                考核的结果更加公平、公正。系统支持考核环节自主配置、考核指标体系本地导入、考评方案灵活设置、
                评审专家自动指派、考评材料一键导出等功能，同时提供考核过程管理和数据统计汇总功能。
              </p>
              <div class="img1Box mt27">
                <img src="@/assets/banner_img/aservice4/neirong.png" alt="">
                <div class="aboutBox">
                  <h4 class="mb28">
                    <div></div>服务内容
                  </h4>
                  <div class="mb15">
                    <div></div>佐证材料汇聚
                  </div>
                  <div class="mb15">
                    <div></div>评估报告生成
                  </div>
                  <div class="mb15">
                    <div></div>组织专家评审
                  </div>
                  <div>
                    <div></div>优秀成果共享
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="bgWidth2 mb40">
      <div class="public-width">
        <div class="mainBox5">
          <div class="pingguBox pt20">
            <h4 class="mb10">四名绩效考核系统主要功能特点：</h4>
            <div class="img1Box mb20">
              <img src="@/assets/banner_img/aservice4/tedian.png" alt="">
            </div>
            <div class="img1Box mb30">
              <img src="@/assets/banner_img/aservice4/fuwu.png" alt="">
              <div class="aboutBox aboutBox2">
                <h4 class="mb28">
                  <div></div>服务特色
                </h4>
                <div class="mb15">
                  <div></div>过程性考核材料可追溯
                </div>
                <div class="mb15">
                  <div></div>评估过程管理信息化
                </div>
                <div class="mb15">
                  <div></div>评估报告自动生成
                </div>
                <div>
                  <div></div>个性化改进建设提供
                </div>
              </div>
            </div>
            <h4>工作室绩效考核系统效果图（部分）:</h4>
            <div class="gongzuoBox mt30">
              <div>
                <img src="@/assets/banner_img/aservice4/xg1.png" alt="">
                <p>发布项目-考核项目管理</p>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice4/xg2.png" alt="">
                <p>材料申报-基本信息表</p>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice4/xg3.png" alt="">
                <p>材料初审-审核界面</p>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice4/xg4.png" alt="">
                <p>结果呈现-各指标考核</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.mainBox5 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .pingguBox {
    >p {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      text-indent: 2em
    }

    h4 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .img1Box {
      position: relative;

      .aboutBox {
        position: absolute;
        top: 25px;
        left: 40px;
        background: #145BB9;
        padding: 64px 0 0 86px;
        width: 340px;
        height: 350px;
        box-sizing: border-box;

        h4 {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }

        >div {
          font-size: 22px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;

          >div {
            width: 6px;
            height: 6px;
            background: rgba(255, 255, 255, 1);
            border-radius: 3px;
            margin-right: 12px;
          }
        }
      }

      .aboutBox2 {
        background: rgba(234, 30, 112, 1);
        right: 40px;
        left: auto;
        padding: 64px 0 0 50px;
      }
    }

    .gongzuoBox {
      padding: 64px 78px 0 78px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: rgba(20, 91, 185, 0.1);

      >div {
        margin-bottom: 40px;

        img {
          width: 542px;
          height: 362px;
        }

        p {
          font-size: 18px;
          font-family: Source Han Sans SC;
          color: rgba(102, 102, 102, 1);
          text-align: center;
          margin-top: 20px;
        }
      }
    }

  }
}
</style>