<template>
  <div class="public_css_module personal_right_box activity_div">

    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的活动</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'"
          @click="showData(item.id)">
          <span v-if="item.id != 2">{{ item.name }}</span>
          <el-dropdown trigger="click" v-if="item.id == 2" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">审核中</el-dropdown-item>
              <el-dropdown-item command="4">已通过</el-dropdown-item>
              <el-dropdown-item command="5">未通过</el-dropdown-item>
              <!-- <el-dropdown-item command="6">已过期</el-dropdown-item> -->
              <el-dropdown-item command="8">待提交</el-dropdown-item>
              <el-dropdown-item command="9">待发布</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <router-link to="/newActivity" class="rightLink">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>创建活动</span>
      </router-link>
    </div>

    <div class="item_list_3_box" v-loading="listLoading">
      <div class="listBox_div">
        <template v-for="(item, index) in getList">
          <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="1"
            :currentIndex="index" :item="item" @researchActivityEditClick="researchActivityEditClick"
            @delClick_2="researchActivityDelClick" @activity_RefreshPage="init()">
          </PublicTtemDiv>
        </template>
      </div>

      <CommonEmpty v-if="!listLoading && !getList.length" hVal="200px"></CommonEmpty>

      <div v-show="total" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="formList.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="formList.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      listLoading: true, //表格Loading
      total: 0,
      getList: [],
      formList: {
        limit: 12,
        page: 1,
        search_list_type: 0,
        audit_status: "",
      },
      //通用筛选
      selectData: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 2,
          name: "我创建的",
        },
        {
          id: 1,
          name: "我参与的",
        },
        {
          id: 3,
          name: "待我审核",
        },
      ],
      currentNum: 0, //筛选索引
    };
  },
  created() {
    let query = this.$route.query;
    if (query.pageType) {
      this.$set(this.formList, "search_list_type", Number(query.pageType));
      this.currentNum = Number(query.pageType);
    };
    if (query.statusType) {
      this.$set(this.formList, "audit_status", Number(query.statusType));
    };
    this.init();
  },
  methods: {
    //下拉
    handleCommand(type) {
      this.currentNum = 2;
      this.$set(this.formList, "search_list_type", 2);
      this.$set(this.formList, "page", 1);
      this.$set(this.formList, "audit_status", type);
      this.init();
    },
    //筛选
    showData(id) {
      if (id != 2) {
        this.currentNum = id;
        this.$set(this.formList, "search_list_type", id);
        this.$set(this.formList, "page", 1);
        this.$set(this.formList, "audit_status", "");
        this.init();
      }
    },
    //初始化
    async init() {
      this.listLoading = true;
      try {
        let res = await this.$axios.get("/myActivities", { params: this.formList });
        this.total = res.data.data.total;
        this.getList = res.data.data.data;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      } catch (error) {
        this.getList = [];
        this.total = 0;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
    //删除活动
    async delActivity(id, item) {
      const res = await this.$axios.delete(`/activity/${id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.init();
    },
    //修改教研活动按钮
    researchActivityEditClick(id) {
      this.$router.push({
        path: "/newActivity",
        query: { id: id },
      });
    },
    //删除教研活动按钮
    researchActivityDelClick(id, item) {
      this.$confirm("此操作将删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity(id, item);
      }).catch(() => { });
    },
    //教研活动详情
    activityDetails(id, item) {
      let is_from = "";
      if (this.currentNum != 3) {
        if (item.is_personid != 1) {
          localStorage.setItem("studioId", item.teaching_studio_id);
          is_from = 2;
        } else {
          is_from = 1; //其他：1教研组活动详情,2：四名活动详情
        }
      } else if (this.currentNum == 3) {
        is_from = 1;
      }
      this.$router.push({
        path: "/basicinfo",
        query: { activityId: id, is_from },
      });
    },
    handleSizeChange(val) {
      this.$set(this.formList, "page", 1);
      this.$set(this.formList, "limit", val);
      this.init();
    },
    handleCurrentChange(val) {
      this.$set(this.formList, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.listBox_div {
  padding-top: 18px;
  padding-left: 34px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .list_item {
    width: 356px;
  }

  /deep/ .list_item {
    width: 356px;
  }
}

.activity_div {
  width: 100%;
  background-color: #fff;
}
</style>