<template>
  <!-- 备课 -->
  <div class="NextListingOffLine">
    <!-- 头部 -->
    <div class="nextBox">
      <!-- 左边图片 -->
      <div class="CourseOff_left">
        <img :src="form.cover_url" alt="" />
      </div>
      <!-- 右边 -->
      <div class="TopRight">
        <!-- 标题 -->
        <div class="titleTop">
          <h4>
            {{ form.name }}
          </h4>
        </div>
        <!-- 便签 -->
        <div class="bq">
          <div>
            课程分类：<span>{{ form.course_type }}</span>
          </div>
          <div>授课形式：<span>线下</span></div>
          <div>
            学段学科：<span>{{ form.stage_name }} &nbsp;&nbsp; {{ form.subject_name }}</span>
          </div>
          <div>
            年级：<span>{{ form.grade_name }}</span>
          </div>
        </div>
        <!-- 最后一行 -->
        <div class="bq_last">
          <p>
            教材版本：<span style="margin-right: 20px">{{
              form.edition_name
            }}</span>
            适用章节：<span style="margin-right: 20px">{{
              form.chapter_name
            }}</span>
            授课老师：<span style="margin-right: 20px">{{ form.teacher }}</span>
            授课地点：<span style="margin-right: 20px">{{
              form.course_address
            }}</span>
          </p>
          <p style="margin-top: 20px">
            授课时间：<span style="margin-right: 20px">
              {{ form.start_date }} &nbsp;&nbsp; {{ form.start_time }}&nbsp; -
              &nbsp; {{ form.end_date }} &nbsp;&nbsp; {{ form.end_time }}
            </span>
            评课时间：<span>{{ form.lesson_start_date }} &nbsp;&nbsp;
              {{ form.lesson_start_time }}&nbsp; - &nbsp;
              {{ form.lesson_end_date }} &nbsp;&nbsp; {{ form.lesson_end_time }}
            </span>
          </p>
        </div>
        <el-button type="primary" @click="PushOffLine(form.id)">发布课程</el-button>
        <el-button type="success" @click="UpdataCourseOff(form.id, form.group_id)">修改课程</el-button>
        <el-button type="info" @click="deleteCourse(form.id, form.group_id)">删除课程</el-button>
      </div>
    </div>
    <!-- 内容 -->
    <div class="dbBox">
      <div class="boxContent">
        <div class="Box_top">
          <h3>授课说明</h3>
          <div class="topContent">
            <p class="rich_text_div_img" v-html="form.presentation"></p>
          </div>
        </div>
        <div class="Box_bottom">
          <h3>上课资源</h3>
          <div class="forResource">
            <!-- 循环部分 -->
            <div class="ResourceItem" v-for="(item, index) in form.class_resource" :key="index">
              <!-- 左边图片 -->
              <div class="Re_left">
                <img src="../../../../assets/images/word.png" alt="" />
              </div>
              <!-- 右边内容 -->
              <div class="Re_right">
                <p>{{ item.resource_name }}</p>
                <div class="Re_text">
                  <div>
                    上传时间：<span>{{ item.create_time }}</span>
                  </div>

                  <div style="display: flex; align-items: center">
                    <new-viewonline :viewSrc="item.resource_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext"
                      :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time">
                      <el-link targer="_blank" :underline="false">
                        <span style="color: #3489fe; margin-right: 10px">查看</span>|
                      </el-link>
                    </new-viewonline>

                    <div>
                      <span><a :href="item.resource_url" target="_blank">下载
                        </a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LuBoDetail, SendCourse } from "@/api/teacherStudio";
export default {
  data() {
    return {
      form: "",
      is_personid: 1,
    };
  },
  created() {
    if (this.$route.query.is_personid) {
      this.is_personid = this.$route.query.is_personid
    };
    this.init();
  },
  methods: {
    async init() {
      let cid = this.$route.query.id;
      if (this.is_personid == 1) {
        let res = await this.$axios.get(`/course/get_info/${cid}?is_personid=${this.is_personid}`);
        let resData = res.data.data;
        this.form = resData;
      } else {
        LuBoDetail({ id: cid, is_personid: this.is_personid }).then((res) => {
          this.form = res.data;
        });
      }
    },
    //跳转到修改线下听评课
    UpdataCourseOff(c_id, c_groupId) {
      this.$router.push({
        path: '/createCourse',
        query: {
          id: c_id,
          is_personid: this.form.is_personid,
          groupId: this.form.group_id,
        },
      });
    },
    // 发布线下听评课课程
    async PushOffLine(id) {
      this.$confirm("是否发布课程，发布后不可修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //发送删除请求
          if (this.is_personid == 1) {
            p = this.$axios.put(`courses/${id}/publish`).then((res) => {
              this.$message({
                type: "success",
                message: "发布成功!",
              });
              setTimeout(() => {
                this.$router.push(`/listeningcommentdetail?id=${id}`);
              }, 1000);
            });
          } else {
            p = SendCourse(id).then((res) => {
              this.$message({
                type: "success",
                message: "发布成功!",
              });
              setTimeout(() => {
                this.$router.push(`/RecordManage`);
              }, 1000);
            });
          }
        })
        .catch(() => {
          //   this.$message({
          //     type: "info",
          //     message: "已取消发布",
          //   });
        });
    },

    //删除
    deleteCourse(id) {
      this.$confirm("删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //发送删除请求
          let p = null;
          if (this.is_personid == 1) {
            p = this.$axios.delete("courses/delCourse", {
              params: {
                id: id,
              },
            });
          } else {
            p = this.$axios.delete(
              "index/TeachingStudioCourse/deleteTeachingStudioCourse",
              {
                params: {
                  id: id,
                },
              }
            );
          }
          p.then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less">
//num=2
.NextListingOffLine {
  width: 100%;
  //头部
  .nextBox {
    width: 80%;
    height: 335px;
    margin-left: 10%;
    display: flex;
    margin-bottom: 10px;
    // 左边图片
    .CourseOff_left {
      width: 42%;
      height: 293px;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 293px;
      }
    }
    //头部右边
    .TopRight {
      width: 75%;
      margin: auto 0;
      font-size: 19px;
      .titleTop {
        height: 30px;
        display: flex;
        margin-bottom: 30px;
        h4 {
          line-height: 28px;
          margin-right: 15px;
        }
        div {
          margin-right: 5px;
        }
      }
      //  便签
      .bq {
        font-size: 19px;
        display: flex;
        height: 30px;
        margin-bottom: 20px;
        div {
          margin-right: 20px;
          span {
            color: #999999;
          }
        }
      }
      //最后一行
      .bq_last {
        font-size: 18px;
        height: 100px;
        span {
          color: #999999;
        }
      }
    }
    //  按钮
    #wzt {
      font-size: 14px;
      color: red;
    }
  }
  .dbBox {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f7f7f7;
    .boxContent {
      width: 80%;
      margin-left: 10%;
      padding-bottom: 50px;
      h3 {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .Box_top {
        .topContent {
          box-sizing: border-box;
          margin: 0;
          padding: 15px 15px;
          background: #ffffff;
          width: 100%;
        }
      }
      .Box_bottom {
        h4 {
          margin-top: 20px;
        }
        .forResource {
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          margin: 0;
          padding: 15px 15px;
          background: #ffffff;
          width: 100%;
          //a标签去掉
          a {
            color: rgb(52, 137, 254);
            text-decoration: none; //去掉默认下滑线
          }
          // height: 400px;
          //循环
          .ResourceItem {
            display: flex;
            width: 50%;
            height: 60px;
            margin-bottom: 20px;
            .Re_left {
              width: 65px;
              img {
                width: 60px;
                height: 50px;
              }
            }
            .Re_right {
              width: 70%;
              //  margin:auto 0;
              color: #999999;
              p {
                margin-left: 20px;
              }
              .Re_text {
                display: flex;
                margin-left: 20px;
                div {
                  font-size: 14px;
                  span:nth-of-type(1) {
                    margin-left: 10px;
                  }
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>