<template>
  <div class="groupdetailmember">
    <div v-if="false" class="content_one">
      <span>审核教研组成员（{{ total }}）</span>
    </div>
    <div class="content_two">
      <CommonEmpty v-if="!examineData.length" hVal="200px"></CommonEmpty>
      <div class="two_block" v-for="(item, index) in examineData" :key="index">
        <div class="block_left">
          <img :src="item.photo_url" alt="" />
        </div>
        <div class="block_right">
          <div class="block_right_left">
            <p>
              <span>{{ item.user_name }}</span><span>{{ item.stage_name }} | {{ item.subject_name }} |
                {{ item.org_name }}</span>
            </p>

            <div class="block_right_left_word">
              <span>申请原因：</span>
              <el-input type="textarea" :rows="2" placeholder="" :value="item.reason" style="width: 465px">
              </el-input>
            </div>
          </div>
          <div class="block_right_right">
            <el-button size="mini" type="primary" @click="adoptExamine(item)">通过</el-button>

            <el-button size="mini" type="success" @click="showRefuse(item)">拒绝</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="total" class="pagin">
      <el-pagination background :current-page="page" :page-size="limit" layout="prev, pager, next, jumper" :total="total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
    <!-- 拒绝申请对话框 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" @close="closeDialog">
      <el-input type="textarea" :rows="6" placeholder="请填写拒绝原因" v-model="refuseData.reason">
      </el-input>
      <div class="refuse">
        <el-button type="primary" @click="submitRefuse">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前教研组id
      group_id: "",
      // 每页条数
      limit: 10,
      // 当前页数
      page: 1,
      // 总数据数目
      total: 0,
      // 审核总数据
      examineData: [],
      //拒绝申请对话框现实与否
      dialogVisible: false,
      // 对话框数据
      refuseData: {
        id: "",
        reason: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化函数
    init() {
      this.group_id = this.$route.query.groupId;
      this.getExamine();
    },
    // 切换当前页码时触发
    handleCurrentChange(val) {
      this.page = val;
      this.getExamine();
    },
    // 请求后台获取审核数据列表
    getExamine() {
      var obj = {
        params: {
          limit: this.limit,
          page: this.page,
          group_id: this.group_id,
        },
      };
      this.$axios.get("TeachingGroupUserApply/get_list", obj).then((res) => {
        var resdata = res.data;
        this.total = resdata.data.total;
        this.examineData = resdata.data.data;
      });
    },
    // 请求后台通过申请
    adoptExamine(item) {
      var obj = {
        id: item.id,
      };
      this.$axios.post("group/adoptApply", obj).then((res) => {
        this.getExamine();
      });
    },
    // 展示拒绝对话框
    showRefuse(item) {
      this.dialogVisible = true;
      this.refuseData.id = item.id;
    },
    // 请求后台提交拒绝原因
    submitRefuse() {
      var obj = this.refuseData;
      this.$axios.post("group/refuseApply", obj).then((res) => {
        this.dialogVisible = false;
        this.getExamine();
      });
    },
    // 关闭对话框函数
    closeDialog() {
      this.refuseData = {
        id: "",
        reason: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.groupdetailmember {
  background: #ffffff;
  border-radius: 6px;
  width: 100% !important;
  padding: 0 !important;
  .content_one {
    font-weight: 700;
    color: #333333;
    margin-bottom: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content_two {
    .two_block {
      display: flex;
      margin-bottom: 36px;
      .block_left {
        margin-right: 15px;
        img {
          width: 68px;
          height: 68px;
        }
      }
      .block_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 897px;
        padding-bottom: 31px;
        border-bottom: 1px dashed#ECECEC;
        margin-top: 10px;
        .block_right_left {
          p:nth-child(1) {
            margin-bottom: 10px;
            span:nth-child(1) {
              font-weight: 700;
              color: #ff8201;
              margin-right: 16px;
            }
          }
          //   p:nth-child(2) {
          //     font-size: 14px;
          //     color: #9a9ea5;
          //   }
          .block_right_left_word {
            display: flex;
            font-size: 14px;
            color: #9a9ea5;
          }
        }
        .block_right_right {
        }
      }
    }
  }
  .refuse {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>