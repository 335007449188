<template>
  <!-- 【首页模块 index模块】 -->
  <div class="public_main_bgc">
    <div class="public_width">
      <div class="bannerSty">
        <el-carousel trigger="click" height="348px">
          <el-carousel-item v-for="(item, index) in carimgList" :key="index">
            <img style="width: 100%; height: 100%; border-radius: 10px" :src="item.banner_url" alt="" @click="toBanner(item.external_url)" />
          </el-carousel-item>
        </el-carousel>

        <div class="banTxtBox pt32 pl12">
          <div class="textList" v-for="item in homeLinkList" :key="item.id">
            <p v-if="item.sub.length">{{ item.text }}</p>
            <div class="df">
              <p v-for="items in item.sub" :key="items.id" @click="goPage(items.url)">
                {{ items.name }}
              </p>
            </div>
          </div>
          <div class="bantitle">快捷工具</div>
        </div>
      </div>

      <div class="xinxinum">
        <div class="df dfb">
          <div class="xx1 df dfb" v-if="nowUrl != 1">
            <div class="lef tc">
              <p>{{ statistics.TeachingStudio }}</p>
              <p>特级四名工作室</p>
            </div>
            <div class="imgbox">
              <img src="@/assets/images/myImg/zongji1.png" alt="" />
            </div>
          </div>
          <div class="xx2 df dfb" :class="nowUrl == 1 ? 'bigWidth' : ''">
            <div class="lef tc">
              <p>{{ statistics.TeachingGroup }}</p>
              <p>学科教研组</p>
            </div>
            <div class="imgbox">
              <img src="@/assets/images/myImg/zongji2.png" alt="" />
            </div>
          </div>
          <div class="xx3 df dfb" :class="nowUrl == 1 ? 'bigWidth' : ''">
            <div class="lef tc">
              <p>{{ statistics.resource_count }}</p>
              <p>在线共享资源</p>
            </div>
            <div class="imgbox">
              <img src="@/assets/images/myImg/zongji3.png" alt="" />
            </div>
          </div>
          <div class="xx4 df dfb" :class="nowUrl == 1 ? 'bigWidth' : ''">
            <div class="lef tc">
              <p>{{ statistics.activity_course_live }}</p>
              <p>直播+活动+课程</p>
            </div>
            <div class="imgbox">
              <img src="@/assets/images/myImg/zongji4.png" alt="" />
            </div>
          </div>
          <div class="xx5 df dfb" :class="nowUrl == 1 ? 'bigWidth' : ''">
            <div class="lef tc">
              <p>{{ statistics.gambit }}</p>
              <p>热门研讨话题</p>
            </div>
            <div class="imgbox">
              <img src="@/assets/images/myImg/zongji5.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 通知公告/教研资讯 -->
      <div class="zhengxin df dfb">
        <div class="public-module-list-1">
          <div class="module-head">
            <div class="head-title">
              <img class="title-icon" src="@/assets/images/tzgg.png" alt="" />
              <h4 class="title-text">通知公告</h4>
            </div>
            <p class="head-more" @click="goOther">更多>></p>
          </div>
          <div class="module-content">
            <CommonEmpty v-if="!noticeList.length" hVal="100%"></CommonEmpty>
            <div class="list-item" v-for="(item, index) in noticeList" :key="index" @click="toInforDetail(item.id)">
              <i class="item-icon"></i>
              <p class="item-title ellipsis">{{ item.title }}</p>
              <span class="item-date">{{ item.create_time }}</span>
            </div>
          </div>
        </div>
        <div class="public-module-list-1">
          <div class="module-head">
            <div class="head-title">
              <img class="title-icon" src="@/assets/images/home_jiaoyanzixun.png" alt="" />
              <h4 class="title-text">教研资讯</h4>
            </div>
            <p class="head-more" @click="goMoreInfor">更多>></p>
          </div>
          <div class="module-content">
            <CommonEmpty v-if="!getInfor.length" hVal="100%"></CommonEmpty>
            <div class="list-item" v-for="(item, index) in getInfor" :key="index" @click="toNoticeDetail(item.id)">
              <i class="item-icon"></i>
              <p class="item-title ellipsis">{{ item.title }}</p>
              <span class="item-date">{{ item.create_time }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 教研直播 -->
      <div class="publicBox mt30">
        <div class="publicTitle df dfb mb20">
          <div class="df">
            <h4>教研直播</h4>
            <div class="titbtn df">
              <p @click="changeNewList(1)" :class="isNewList == 1 ? 'pacti' : ''">
                最新
              </p>
              <p @click="changeNewList(2)" :class="isNewList == 2 ? 'pacti' : ''">
                最热
              </p>
            </div>
          </div>
          <p class="cp" @click="goMoreListEvent(2)">查看更多>></p>
        </div>
        <!-- <div class="kechengliBox huodongliBox df">
          <template v-for="(item, index) in getNewList">
            <PublicTtemDiv
              :key="index"
              :navActive="2"
              :currentIndex="index"
              :item="item"
            ></PublicTtemDiv>
          </template>
        </div> -->

        <div class="kechengliBox huodongliBox mb42 df">
          <CommonEmpty v-if="!getNewList.length" hVal="100%"></CommonEmpty>
          <div class="kechengli huodongli nowlive cp" v-for="(item, index) in getNewList" :key="index" @click="go_urls(item.go_url)">
            <!-- <div class="state_bag" :style="
                item.status == 3
                  ? 'background: #ff8029'
                  : item.status == 2
                  ? 'background: #1ebb9e'
                  : 'background: #0080ff'
              " v-if="index != 0">
              {{ item.status_text }}
            </div> -->
            <template v-if="index != 0">
              <div v-if="item.status == 1" class="tios_div_box_1_div">进行中 </div>
              <div v-if="item.status == 2" class="tios_div_box_1_div tios_div_box_2_div">未开始 </div>
              <div v-if="item.status == 3" class="tios_div_box_1_div tios_div_box_3_div">已结束 </div>
            </template>
            <img class="titleImg" :src="item.cover_url" alt="" />
            <!-- 正在直播 -->
            <div class="livebox3" v-if="item.status == 1 && index == 0">
              <div class="title ellipsis">
                {{ item.title }}
              </div>
              <div class="df zhuangt">
                <img src="@/assets/images/iconFile/home_zhibo_ing.gif" alt="" />
                <p>正在直播</p>
              </div>
              <div class="btnbox">立即学习</div>
              <div class="fotbotm df dfb">
                <div class="botm1 df">
                  <img :src="item.photo_url" alt="" v-if="item.photo_url" />
                  <img v-else src="@/assets/images/user.png" alt="" />
                  <p>{{ item.username }}</p>
                  <span>{{ item.org_name }}</span>
                </div>
                <div class="botm2 df">
                  <!-- <p>已有<span>120</span>人参与</p> -->
                  <p>{{ item.org_name }}</p>
                </div>
              </div>
            </div>
            <!-- 未开始 -->
            <div class="livebox3" v-if="item.status == 2 && index == 0">
              <div class="title ellipsis">
                {{ item.title }}
              </div>
              <div class="df zhiTime">
                <p>
                  距离直播剩
                  <span class="lanse chu600 rich_text_div_img" v-html="timeDown"></span>
                </p>
              </div>
              <div class="btnbox2">立即了解</div>
              <div class="fotbotm df dfb">
                <div class="botm1 df">
                  <img :src="item.photo_url" alt="" v-if="item.photo_url" />
                  <img v-else src="@/assets/images/user.png" alt="" />
                  <p>{{ item.username }}</p>
                  <span>{{ item.org_name }}</span>
                </div>
                <div class="botm2 df">
                  <!-- <p>已有<span>120</span>人参与</p> -->
                  <p>{{ item.org_name }}</p>
                </div>
              </div>
            </div>
            <!-- 未开始 -->
            <div class="livebox4" v-if="item.status == 3 && index == 0">
              <div class="bofang df dfc">
                <img src="@/assets/images/bofang.png" alt="" />
              </div>
              <div class="boxbox">
                <p class="titless ellipsis-1 mb15">
                  {{ item.title }}
                </p>
                <div class="pt10 boxBomimg df">
                  <img class="mr12" :src="item.photo_url" alt="" v-if="item.photo_url" />
                  <img v-else class="mr12" src="@/assets/images/user.png" alt="" />
                  <p>{{ item.username }}</p>
                  <p class="ml22">{{ item.org_name }}</p>
                </div>
                <div class="boxZiyu mt25">
                  <div class="df" v-for="(em, idx) in item.resource" :key="idx">
                    <div class="mr6">
                      <img :src="em.mime | fileTypeMini" alt="" />
                    </div>
                    <p class="ellipsis">{{ em.resource_name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="index != 0">
              <p class="titles ellipsis-2">
                {{ item.title }}
              </p>
              <p class="times">
                直播时间:{{ item.start_date }} {{ item.start_time }}
              </p>

              <div class="ziyubox mb10">
                <div class="df" v-for="(em, idx) in item.resource" :key="idx">
                  <div>
                    <img :src="em.mime | fileTypeMini" alt="" />
                  </div>
                  <p class="ellipsis">{{ em.resource_name }}</p>
                </div>
              </div>
              <div class="bomimgBox df dfb">
                <div class="df">
                  <img :src="item.photo_url" alt="" v-if="item.photo_url" />
                  <img v-else src="@/assets/images/user.png" alt="" />
                  <p>{{ item.username }}</p>
                </div>
                <p>{{ item.org_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 研修活动 -->
      <div class="publicBox mt20">
        <div class="publicTitle df dfb mb20">
          <div class="df">
            <h4>研修活动</h4>
            <div class="titbtn df">
              <p @click="activeScreenChange(1)" :class="activeIndex == 1 ? 'pacti' : ''">
                最新
              </p>
              <p @click="activeScreenChange(2)" :class="activeIndex == 2 ? 'pacti' : ''">
                最热
              </p>
            </div>
          </div>
          <p class="cp" @click="goMoreListEvent(1)">查看更多>></p>
        </div>
        <div class="kechengliBox huodongliBox jyhdbox df">
          <CommonEmpty v-if="!getNewActiveList.length" hVal="100%"></CommonEmpty>
          <template v-for="(item, index) in getNewActiveList">
            <PublicTtemDiv :key="index" :navActive="1" :currentIndex="index" :item="item"></PublicTtemDiv>
          </template>
        </div>
      </div>

      <!-- 研修视频 -->
      <div class="publicBox mt20">
        <div class="publicTitle df dfb mb20">
          <div class="df">
            <h4>研修视频</h4>
            <div class="titbtn df">
              <p @click="courseScreenChange(1)" :class="courseIndex == 1 ? 'pacti' : ''">
                最新
              </p>
              <p @click="courseScreenChange(2)" :class="courseIndex == 2 ? 'pacti' : ''">
                最热
              </p>
            </div>
          </div>
          <p class="cp" @click="goMoreListEvent(3)">查看更多>></p>
        </div>
        <div class="kechengliBox huodongliBox jyhdbox mb0 df">
          <CommonEmpty v-if="!getNewCourseList.length" hVal="100%"></CommonEmpty>
          <template v-for="(item, index) in getNewCourseList">
            <PublicTtemDiv :key="index" :navActive="3" :currentIndex="index" :item="item"></PublicTtemDiv>
          </template>
        </div>
      </div>

      <!-- 四名工作室 -->
      <div class="publicBox mt20" v-if="nowUrl != 1">
        <div class="publicTitle df dfb mb20">
          <h4>四名工作室</h4>
          <p class="cp" @click="getMoreTeach">查看更多>></p>
        </div>
        <div class="gongzuoBox">
          <div class="gongzuoTop df dfb">
            <CommonEmpty v-if="!StudioArr2.length" hVal="100%"></CommonEmpty>
            <div class="gongzuoli" v-for="(item, index) in StudioArr2" :key="index">
              <div class="box1 df mb22">
                <div class="imgbox">
                  <img :src="item.photo_url" alt="" />
                  <img src="@/assets/images/iconFile/home_msgzs_huangguan.png" alt="" class="imgabs" />
                </div>
                <div class="txtbox">
                  <h4 class="ellipsis mb14">{{ item.teaching_studio_name }}</h4>
                  <p>{{ item.module_text }}</p>
                </div>
              </div>
              <div class="box2">
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioCourse.go_url)">
                  【课程推荐】{{ item.TeachingStudioCourse.name || "-" }}
                </p>
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioArticle.go_url)">
                  【活动推荐】{{ item.TeachingStudioArticle.name || "-" }}
                </p>
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioUserResource.go_url)">
                  【资源推荐】{{ item.TeachingStudioUserResource.name || "-" }}
                </p>
                <div class="df">
                  <p class="mr24">
                    {{ item.stage_name }}{{ item.subject_name }}
                  </p>
                  <i class="line"></i>
                  <p class="ml24 mr24">访问人数：{{ item.view_count }}</p>
                  <i class="line"></i>
                  <p class="ml24">成员：{{ item.user_count }}</p>
                </div>
                <p class="pbtn cp" @click="toStudioIndex(item.id)">进入工作室></p>
              </div>
              <div class="abBox">
                <p>特级教师</p>
                <img v-if="index == 1" src="@/assets/images/myImg/mingshiicon2.png" alt="" />
                <img v-else src="@/assets/images/myImg/mingshiicon1.png" alt="" />
              </div>
            </div>
          </div>

          <div class="gongzuoBom df dfb">
            <div class="gongzuoLis" v-for="(item, index) in StudioArr" :key="index">
              <div class="litop df cp" @click="toStudioIndex(item.id)">
                <div class="lileft">
                  <img :src="item.photo_url" alt="" />
                </div>
                <div class="liright">
                  <h4 class="ellipsis">{{ item.teaching_studio_name }}</h4>
                  <div class="stadbox df">
                    <p class="stad">
                      {{ item.stage_name || "年级"
                      }}{{ item.subject_name || "学科" }}
                    </p>
                    <span>访问人数{{ item.view_count }}</span>
                  </div>
                  <div class="libn">
                    <i class="el-icon-arrow-right ml3"></i>
                  </div>
                </div>
              </div>
              <div class="libom">
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioCourse.go_url)">
                  【课程推荐】{{ item.TeachingStudioCourse.name || "-" }}
                </p>
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioArticle.go_url)">
                  【活动推荐】{{ item.TeachingStudioArticle.name || "-" }}
                </p>
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioUserResource.go_url)">
                  【资源推荐】{{ item.TeachingStudioUserResource.name || "-" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 推荐教研组 -->
      <div class="publicBox mt30">
        <div class="publicTitle df dfb mb20">
          <h4>推荐教研组</h4>
          <p class="cp" @click="goMoreGroup">查看更多>></p>
        </div>
        <div class="jiaoyanBox">
          <div class="jiaoyanTop df dfb">
            <CommonEmpty v-if="!getJyGroupList2.length" hVal="100%"></CommonEmpty>
            <div class="jiaoyanli" v-for="(item, index) in getJyGroupList2" :key="index">
              <div class="box1 df mb18">
                <div class="imgbox">
                  <img :src="item.cover_url" alt="" />
                </div>
                <div class="txtbox">
                  <h4 class="ellipsis mb14">{{ item.name }}</h4>
                  <p>{{ item.label }}</p>
                </div>
              </div>
              <div class="box2">
                <template v-if="false">
                  <div class="resource-list" v-for="(item, index) in item.resource" :key="index">
                    <span><img :src="item.mime | fileTypeMini" alt="" /></span>
                    <p class="ellipsis">
                      {{ item.resource_name }}
                    </p>
                  </div>
                </template>
                <div class="resource-list">
                  <p class="ellipsis cp" @click="handlePageEvent(item.live.go_url)">【直播推荐】{{ item.live.name || "-" }} </p>
                </div>
                <div class="resource-list">
                  <p class="ellipsis cp" @click="handlePageEvent(item.activity.go_url)">【活动推荐】{{ item.activity.name || "-" }} </p>
                </div>
                <div class="resource-list">
                  <p class="ellipsis cp" @click="handlePageEvent(item.resource.go_url)">
                    【资源推荐】{{ item.resource.name || item.resource.title?item.resource.title:'-' }}
                  </p>
                </div>
                <div class="df">
                  <p class="mr24">
                    {{ item.stage_name }}{{ item.subject_name }}
                  </p>
                  <i class="line"></i>
                  <p class="ml24 mr24">访问人数：{{ item.result_count }}</p>
                  <i class="line"></i>
                  <p class="ml24">成员：{{ item.user_count }}</p>
                </div>

                <p class="pbtn cp" @click="toGroupDetail(item.id)">进入教研组</p>
              </div>
            </div>
          </div>

          <div class="jiaoyanBom df dfb">
            <div class="jiaoyanLis" v-for="(item, index) in getJyGroupList" :key="index">
              <div class="litop df cp" @click="toGroupDetail(item.id)">
                <div class="lileft">
                  <img :src="item.cover_url" alt="" />
                </div>
                <div class="liright">
                  <h4 class="ellipsis">{{ item.name }}</h4>
                  <div class="stadbox df mb8">
                    <span class="mr30">组长：{{ item.username }}</span>
                    <span>成员：{{ item.user_count }}</span>
                  </div>
                  <div class="stadbox df">
                    <p class="stad">
                      {{ item.type_text }}
                    </p>
                    <p class="stad">
                      {{ item.stage_name || "年级"
                      }}{{ item.subject_name || "学科" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="libom">
                <template v-if="false">
                  <div class="df" v-for="(item, index) in item.resource" :key="index">
                    <span><img :src="item.mime | fileTypeMini" alt="" /></span>
                    <p class="ellipsis">
                      {{ item.resource_name }}
                    </p>
                  </div>
                </template>
                <template>
                  <div class="df">
                    <p class="ellipsis cp" @click="handlePageEvent(item.live.go_url)">【直播推荐】{{ item.live.name || "-" }} </p>
                  </div>
                  <div class="df">
                    <p class="ellipsis cp" @click="handlePageEvent(item.activity.go_url)">【活动推荐】{{ item.activity.name || "-" }} </p>
                  </div>
                  <div class="df">
                    <p class="ellipsis cp" @click="handlePageEvent(item.resource.go_url)">【资源推荐】{{ item.resource.name || "-" }} </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 推荐资源/试题/试卷 -->
      <div class="zhengxin df dfb zhengx_div">
        <div class="zhengBox">
          <div class="publicBox">
            <div class="publicTitle df dfb mb20">
              <h4>推荐资源</h4>
              <p v-if="getResourceList.length" class="cp" @click="goMoreResources_1">查看更多>></p>
            </div>
          </div>
          <div class="zitiBox">
            <CommonEmpty v-if="!getResourceList.length" hVal="100%"></CommonEmpty>
            <div class="zitiLi df dfb" v-for="(item, index) in getResourceList" :key="index">
              <div class="ziti1">
                <img :src="item.ext | fileTypeSmall" alt="">
              </div>
              <div class="ziti2">
                <h4 @click="toResourceDetail(item.id, item)" class="ellipsis cp">
                  {{ item.title }}
                </h4>
                <p>
                  {{ item.stage }}
                  <template v-if="item.category">
                    <i class="line"></i>
                    {{ item.category }}
                  </template>
                  <i class="line"></i>
                  {{ item.subject }}
                  <i class="line"></i>
                  {{ item.type }}
                </p>
              </div>
              <div class="ziti3">
                <button class="cp z3btn" @click="goDownUrl(item.url)">
                  下载
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="xinBox">
          <div class="publicBox">
            <div class="publicTitle df dfb mb20">
              <h4>试卷</h4>
              <p v-if="getHotTopicList.length" class="cp" @click="goMoreResources_2">查看更多>></p>
            </div>
          </div>
          <div class="zitiBox">
            <CommonEmpty v-if="!getHotTopicList.length" hVal="100%"></CommonEmpty>
            <div class="zitiLi df" v-for="(item, index) in getHotTopicList" :key="index">
              <div class="ziti1">
                <img src="@/assets/imgFIle_4/shijuan.png" />
              </div>
              <div class="ziti2">
                <h4 @click="toResourceDetail(item.id, item)" class="ellipsis cp">
                  {{ item.title }}
                </h4>
                <p>
                  {{ item.stage }}
                  <template v-if="item.category">
                    <i class="line"></i>
                    {{ item.category }}
                  </template>
                  <i class="line"></i>
                  {{ item.subject }}
                  <i class="line"></i>
                  {{ item.type }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="false" class="xinBox">
          <div class="publicBox">
            <div class="publicTitle df dfb mb20">
              <h4>试卷</h4>
              <!-- <p class="cp" @click="goMoreTopic">查看更多>></p> -->
            </div>
          </div>
          <div class="zitiBox">
            <CommonEmpty hVal="100%"></CommonEmpty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { localGet, localSet, localRemove } from "@/utils/utils.js";
export default {
  data() {
    return {
      nowUrl: "",
      carimgList: [],
      userInfo: {},
      homeLinkList: [
        {
          type: 1,
          text: "教研工具",
          sub: [],
        },
        {
          type: 2,
          text: "培训工具",
          sub: [],
        },
        {
          type: 3,
          text: "备课工具",
          sub: [],
        },
      ],
      //公告信息
      noticeList: [],
      getInfor: [],
      getNewList: [],
      isNewList: 1,
      getNewActiveList: [],
      activeIndex: 1,
      //最新课程
      getNewCourseList: [],
      courseIndex: 1,
      form1: {
        limit: "10",
        order_newest: 0,
        order_hottest: 0,
      },
      //四名工作室的列表
      StudioArr: [],
      StudioArr2: [],
      //四名工作室表单
      ruleForm: {
        page: 1,
        limit: 10,
        search_stage_id: "",
        search_subject_id: "",
        search_name: "",
        is_homepage: 1, // 是否首页推荐 1是 2否,
      },
      //得到教研组推荐
      getJyGroupList: [],
      getJyGroupList2: [],
      //资源列表
      getResourceList: [],
      // 热门话题列表
      getHotTopicList: [],
      statistics: {}, //统计
      timeDown: "",
      unserInfoData: null,//登录信息
    };
  },
  created() {
    let unserInfoData = localGet("unserInfoData");
    if (unserInfoData && unserInfoData.id && unserInfoData.token) {
      this.unserInfoData = unserInfoData;
    } else {
      this.unserInfoData = null;
    }
    let nowPath = window.location.host;
    if (nowPath.indexOf("gy.teachercamp.net") != -1) {
      this.nowUrl = 1;
    }
    this.getStatistics();
    this.getQuickTool();
    this.getImgs();
    this.getNoticesList();
    this.getInformation();
    this.getNewLiveList();
    this.getActiveList();
    this.getNewCourse();
    this.getMasterStudio();
    this.getJyGroup();
    this.getZyList();
    this.getHotTopic();
    // if (localStorage.getItem("userInfo")) {
    //   this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   this._getPageHome();
    // }
    // this.teacherObj = this.imgList[0];
  },
  mounted() {
    // this._getDictionary();
    // if (this.$route.query.name) {
    //   let name = JSON.parse(JSON.stringify(this.$route.query.name));
    //   this.getScroll(name);
    // }
  },
  methods: {
    //获取数据统计
    getStatistics() {
      this.$axios.get("StatisticsList").then((res) => {
        if (res.data.code == 900) {
          this.statistics = res.data.data;
        }
      });
    },
    //获取快捷工具
    getQuickTool() {
      this.$axios.get("get_navigation").then((res) => {
        let list = res.data.data;
        if (res.data.code == 900) {
          if (list.length > 0) {
            list.forEach((item) => {
              this.homeLinkList[item.type - 1].sub.push(item);
            });
          }
        }
      });
    },
    getImgs() {
      this.$axios.get("banner").then((res) => {
        this.carimgList = res.data.data;
      });
    },
    //广告跳转
    toBanner(url) {
      let a = "https://" + url;
      //打开窗口
      window.open(a);
    },
    //初始化公告信息
    async getNoticesList() {
      let res = await this.$axios.get("/notices");
      this.noticeList = res.data.data;
      this.noticeList = this.noticeList.slice(0, 4);
    },
    goOther() {
      this.$router.push({
        path: "/noticelist",
        query: { type: "notice" },
      });
    },
    //跳转到信息详情
    toInforDetail(id) {
      this.$router.push(`/NoticesDetail?type=notice&id=${id}`);
    },
    //跳转到资讯详情
    toNoticeDetail(id) {
      this.$router.push(`/NoticesDetail?type=news&id=${id}`);
    },
    //初始化信息
    async getInformation() {
      let res = await this.$axios.get("/informations");
      //赋值列表
      this.getInfor = res.data.data;
      this.getInfor = this.getInfor.slice(0, 4);
    },
    //跳转到资讯页面
    goMoreInfor() {
      this.$router.push("/noticelist?type=news");
    },

    //初始化new直播内容
    async getNewLiveList() {
      let res = await this.$axios.get("/live/get_new_list");
      let list = res.data.data || [];
      this.getNewList = [];
      if (list.length) {
        if (list[0].status == 1) {
          list.forEach((e, i) => {
            if (i < 3) {
              this.getNewList.push(e);
            }
          });
        } else {
          list.forEach((e, i) => {
            if (i < 3) {
              this.getNewList.push(e);
            }
          });
        }
        this.$nextTick(() => {
          if (this.getNewList[0].status == 2) {
            this.minReturn(this.getNewList[0].start_date_time);
          }
        });
      };
    },
    //初始化最热直播
    async getHotLiveList() {
      let res = await this.$axios.get("/live/get_hot_list");
      let list = res.data.data || [];
      this.getNewList = [];
      if (list.length) {
        if (list[0].status == 1) {
          list.forEach((e, i) => {
            if (i < 3) {
              this.getNewList.push(e);
            }
          });
        } else {
          list.forEach((e, i) => {
            if (i < 3) {
              this.getNewList.push(e);
            }
          });
        }
        this.$nextTick(() => {
          if (this.getNewList[0].status == 2) {
            this.minReturn(this.getNewList[0].start_date_time);
          }
        });
      };
    },
    // 跳转更多
    goMoreListEvent(num) {
      this.$router.push({
        path: "/researchCenter",
        query: {
          navActive: num,
        },
      });
    },
    //跳转直播详情
    toLiveDetail(id) {
      this.$router.push({
        path: "/livedetail",
        query: {
          liveid: id,
        },
      });
    },
    changeNewList(val) {
      this.isNewList = val;
      if (val == 1) {
        this.getNewLiveList();
      }
      if (val == 2) {
        this.getHotLiveList();
      }
    },

    //初始化教研活动
    async getActiveList() {
      let res = await this.$axios.get("/activities", {
        params: this.form1,
      });
      let result = res.data.data.data;
      this.getNewActiveList = result.slice(0, 4);
    },
    //得到最新
    getNewActive() {
      this.form1.order_newest = 1;
      this.form1.order_hottest = 0;
      this.getActiveList();
    },
    //得到最热
    getHotActive() {
      this.form1.order_newest = 0;
      this.form1.order_hottest = 1;
      this.getActiveList();
    },
    //教研活动更多跳转
    goMoreActive() {
      this.$router.push("/researchactivity");
    },
    //跳转活动详情
    toActiveDetail(id) {
      this.$router.push(`basicinfo?activityId=${id}`);
    },
    //教研活动筛选回调
    activeScreenChange(index) {
      this.activeIndex = index;
      if (this.activeIndex == 1) {
        this.getNewActive();
      }
      if (this.activeIndex == 2) {
        this.getHotActive();
      }
    },

    //研修视频更多跳转
    goMoreCourse() {
      this.$router.push("/researchcource");
    },
    //跳转课程
    toCourseDetail(id) {
      this.$router.push(`/goclassdetail?id=${id}`);
    },
    //获取最新课程数据
    async getNewCourse() {
      let res = await this.$axios.get("/courses/new");
      let result = res.data.data;
      this.getNewCourseList = result.slice(0, 4);
    },
    //获取最热课程数据
    async getHotCourse() {
      let res = await this.$axios.get("/courses/hot");
      let result = res.data.data;
      this.getNewCourseList = result.slice(0, 4);
    },
    //研修视频筛选回调
    courseScreenChange(index) {
      this.courseIndex = index;
      if (this.courseIndex == 1) {
        this.getNewCourse();
      }
      if (this.courseIndex == 2) {
        this.getHotCourse();
      }
    },

    //获取更多四名列表信息
    getMoreTeach() {
      this.$router.push("/StudioListOld");
    },
    // 进入详情
    handlePageEvent(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    //跳转四名工作室首页
    toStudioIndex(id) {
      //将工作室id存在缓存里
      localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/StudioIndex",
      });
    },
    //四名工作室首页4个
    getMasterStudio() {
      this.$axios
        .get("index/TeachingStudio/listTeachingStudio", {
          params: this.ruleForm,
        })
        .then((res) => {
          this.StudioArr = res.data.data.data.slice(2, 5);
          this.StudioArr2 = res.data.data.data.slice(0, 2);
        });
    },

    //教研组推荐更多跳转
    goMoreGroup() {
      this.$router.push("/GroupListOld");
    },
    //跳转教研组详情
    toGroupDetail(id) {
      this.$router.push(`/groupdetailshouye?groupId=${id}`);
    },
    //初始化教研组推荐
    async getJyGroup() {
      let res = await this.$axios.get("/TeacherGroup/index_list");
      this.getJyGroupList = res.data.data.slice(2, 5);
      this.getJyGroupList2 = res.data.data.slice(0, 2);
    },
    goMoreResources_1() {
      skipOutstation("/home/#/resourcePool/resourceHome", {}, 1);
    },
    goMoreResources_2() {
      skipOutstation("/home/#/questionBank/paperTopic", {});
    },
    //跳转 瓣学网
    toResourceDetail(id, item) {
      window.open(`${item.host}${item.go_url}`);
      return
      skipOutstation("/resource/#/courseware/Viewcourseware", {
        id: id,
        type: "courseware",
        resourceId: id,
      });
    },
    //初始化资源推荐
    async getZyList() {
      let res = await this.$axios.get("/resource/get_index_list");
      // 切割数据
      let result = res.data.data.data;
      // 切割显示6条资源
      this.getResourceList = result.slice(0, 6);
    },
    // 调整新页面/下载
    goDownUrl(url) {
      window.open(url, "_blank");
    },
    // 去新建
    goPage(url) {
      if (!this.unserInfoData) {
        this.$message.info("请先登录")
        setTimeout(() => {
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return false;
      };
      window.open(url, "_blank");
    },
    //跳转更多热门话题
    goMoreTopic() {
      this.$router.push("/community");
    },
    //跳转到话题详情
    toTopiDetail(id) {
      this.$router.push(`/topicDetails?id=${id}`);
    },
    //初始化热门话题
    async getHotTopic() {
      let res = await this.$axios.get("/index/paper/index", {
        params: {
          limit: 6,
          page: 1,
        },
      });
      this.getHotTopicList = res.data.data.data || [];
    },
    getImgUrl(item) {
      return require("@/assets/images/iconFile/" + item + ".png");
    },
    // 定时器
    minReturn(limit) {
      let t2 = Math.trunc(new Date().getTime() / 1000);
      let time_limit = Number(limit - t2);
      console.log(t2);
      console.log(limit);
      //参数为秒*60，参数为分钟不需要
      let time = time_limit;
      console.log(time);
      let t = setInterval(() => {
        time--;
        //定义全局变量 timeDown 作为在页面上展示
        //下面一行业可以更改为 return this.formatSeconds(time)  没尝试过
        this.timeDown = this.formatSeconds(time);
        if (time <= 0) {
          clearInterval(t);
          this.zhiboList[0].status = 1;
        }
      }, 1000);
    },
    // 秒转时分秒，求模很重要，数字的下舍入
    formatSeconds(time) {
      let min = Math.floor(time % 3600);
      let val =
        this.formatBit(Math.floor(time / 3600)) +
        `<span class="baise chu500"> 时 </span>` +
        this.formatBit(Math.floor(min / 60)) +
        `<span class="baise chu500"> 分 </span>` +
        this.formatBit(time % 60) +
        `<span class="baise chu500"> 秒 </span>`;
      return val;
    },
    // 时间转换
    formatBit(val) {
      val = +val;
      return val > 9 ? val : "0" + val;
    },
    // 查看详情
    go_urls(url, val) {
      if (val == 1) {
        let routeData = this.$router.resolve({
          name: "previewFiles",
          query: { url: url },
        });
        window.open(routeData.href, "_blank");
      } else {
        window.open(url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  width: 90px !important;
  height: 62px !important;
  top: -20px !important;
  right: -36px !important;
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  color: rgba(255, 255, 255, 1);
  transform: rotate(45deg);
  background-color: rgba(255, 128, 41, 1);

  &.tios_div_box_2_div {
    background-color: #0080ff;
  }

  &.tios_div_box_3_div {
    background-color: #cecece;
  }
}

.public-module-list-1 {
  height: 225px;
  padding: 0 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(235, 242, 248, 0.16);

  .module-head {
    height: 64px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .head-title {
      display: flex;
      align-items: center;

      .title-icon {
        margin-right: 10px;
      }

      .title-text {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
    }

    .head-more {
      font-size: 16px;
      color: #999;
      cursor: pointer;

      &:hover {
        color: #3489fe;
      }
    }
  }

  .module-content {
    padding-top: 8px;

    .list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 12px;

      &:hover {
        .item-icon {
          background: #3489fe;
        }

        .item-title,
        .item-date {
          color: #3489fe;
        }
      }

      .item-icon {
        width: 4px;
        height: 4px;
        background: #666;
        border-radius: 100%;
        margin-right: 8px;
        display: block;
      }

      .item-title {
        flex: 1;
        overflow: hidden;
        font-size: 16px;
        color: #333;
      }

      .item-date {
        font-size: 16px;
        color: #999;
      }
    }
  }
}

// 轮播图框内样式
/deep/.el-carousel {
  border-radius: 10px;
}

.public_width {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  box-sizing: border-box;
}

.bannerSty {
  border-radius: 10px;
  position: relative;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 334px;
    height: 348px;
    background: rgba(17, 45, 84, 0.9);
    z-index: 666;
    box-sizing: border-box;
    border-radius: 10px 0 0 10px;

    .textList {
      > p {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        padding-top: 20px;
        padding-bottom: 6px;
      }

      > div {
        flex-wrap: wrap;

        > p {
          width: 95px;
          height: 26px;
          background: #e0fffc;
          border-radius: 4px;
          font-size: 14px;
          color: #333;
          text-align: center;
          line-height: 26px;
          cursor: pointer;
          margin: 0 12px 12px 0;

          &:hover {
            color: #ff8201;
          }
        }
      }

      .df {
        margin-bottom: -12px;
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: #ff8201;
      border-radius: 10px 0 10px 0;
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.xinxinum {
  margin-top: 20px;

  > div {
    > div {
      width: 264px;
      height: 100px;
      background: rgba(255, 246, 235, 1);
      border-radius: 8px;
      align-items: center;
      padding: 22px;
      box-sizing: border-box;
      background-size: 100% 100%;
      position: relative;
      border: 1px solid #fff;

      .imgbox {
        position: absolute;
        right: 0;
        bottom: -4px;

        img {
          width: 120px;
          height: 80px;
        }
      }

      &.xx1 {
        // background-image: url("~@/assets/images/myImg/zongji1.png");
        background: #fcf2f2;
      }

      &.xx2 {
        // background-image: url("~@/assets/images/myImg/zongji2.png");
        background: #fff6eb;
      }

      &.xx3 {
        // background-image: url("~@/assets/images/myImg/zongji3.png");
        background: #f0edfc;
      }

      &.xx4 {
        // background-image: url("~@/assets/images/myImg/zongji4.png");
        background: #e8fbfc;
      }

      &.xx5 {
        // background-image: url("~@/assets/images/myImg/zongji5.png");
        background: #ecfdf8;
      }

      .lef {
        p {
          &:first-child {
            font-size: 24px;
            font-weight: bold;
            color: #e19f4e;
            margin-bottom: 10px;
          }

          &:last-child {
            font-size: 16px;
            color: #333333;
          }
        }
      }

      .rimg {
        img {
          width: 69px;
          height: 58px;
        }
      }
    }

    .bigWidth {
      width: 330px;
    }
  }
}

// 政策文件/新闻公告
.zhengxin {
  margin-top: 40px;
  margin-bottom: 40px;

  &.zhengx_div {
    margin-bottom: 0px;
    padding-bottom: 40px;
  }

  > div {
    width: 690px;

    box-sizing: border-box;

    .tet {
      margin-bottom: 24px;

      h4 {
        height: 19px;
        font-size: 19px;
        font-weight: bold;
        color: rgba(55, 54, 55, 1);
      }

      .titleBr {
        height: 19px;
        line-height: 19px;

        .actp {
          font-size: 19px;
          font-weight: bold;
          color: rgba(55, 54, 55, 1);
        }

        p {
          font-size: 16px;
          font-weight: bold;
          color: rgba(127, 136, 156, 1);
        }
      }

      p {
        height: 13px;
        font-size: 15px;
        font-weight: normal;
        color: rgba(127, 136, 156, 1);
        margin-right: 10px;
      }
    }

    .commSty {
      width: 690px;
      height: 177px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      align-items: center;
      box-sizing: border-box;
      padding: 24px;

      > div {
        width: 100%;
        cursor: pointer;

        &:hover {
          p,
          span {
            color: #1e6fff;
          }
        }

        p {
          font-size: 16px;
          color: rgba(17, 26, 56, 1);
          line-height: 30px;

          span {
            font-size: 25px;
            margin-right: 10px;
          }
        }
      }
    }

    //  推荐资源/试题/试卷
    .zitiBox {
      width: 690px;
      height: 469px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 16px 12px;
      box-sizing: border-box;

      .zitiLi {
        height: 72px;
        align-items: center;
        border-radius: 8px;

        &:hover {
          background: rgba(232, 243, 255, 1);

          .ziti3 .z3btn {
            opacity: 1;
          }
        }

        .ziti1 {
          margin-left: 20px;
          margin-right: 14px;

          img {
            width: 40px;
            height: 40px;
            display: block;
          }
        }

        .ziti2 {
          width: calc(100% - 164px);

          h4 {
            font-size: 16px;
            color: #111a38;
            font-weight: normal;
            margin-bottom: 6px;
          }

          p {
            font-size: 14px;
            color: #666666;
            display: flex;
            align-items: center;

            .line {
              width: 1px;
              height: 12px;
              background: #dcdfe6;
              margin: 0 15px;
              display: block;
            }

            .census {
              color: #909399;
              margin-right: 15px;
            }
          }
        }

        .ziti3 {
          margin-left: 12px;
          margin-right: 20px;

          .z3btn {
            opacity: 0;
          }

          button {
            width: 58px;
            height: 30px;
            background: rgba(0, 128, 255, 1);
            border-radius: 8px;
            font-size: 16px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 30px;
            border: none;
          }
        }
      }
    }
  }
}

.publicBox {
  .publicTitle {
    display: flex;
    align-items: flex-end;

    h4 {
      font-size: 24px;
      font-weight: bold;
      color: #373637;
    }

    p {
      font-size: 16px;
      color: #7f889c;

      &:hover {
        color: #3489fe;
      }
    }

    .titbtn {
      margin-left: 32px;
      align-content: center;

      p {
        width: 64px;
        height: 28px;
        border-radius: 64px;
        font-size: 18px;
        text-align: center;
        line-height: 28px;
        color: #666;
        cursor: pointer;
      }

      .pacti {
        background: rgba(52, 137, 254, 1);
        color: #fff;
      }
    }
  }

  //推荐课程
  .kechengliBox {
    .kechengli {
      width: 335px;
      height: 434px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      box-sizing: border-box;
      background: #fff;
      margin-right: 20px;
      overflow: hidden;

      .titleImg {
        width: 335px;
        height: 188px;
        display: block;
      }

      > div {
        padding: 0 20px;

        .titles {
          margin-top: 22px;
          font-size: 15px;
          font-weight: 700;
          color: #111a38;
        }

        .ohers {
          div {
            border-radius: 4px;
            padding: 3px 8px;
            font-size: 14px;

            &:nth-child(1) {
              color: #0493ff;
              background: rgba(229, 244, 255, 1);
            }

            &:nth-child(2) {
              color: #51b875;
              background: rgba(237, 247, 241, 1);
            }
          }
        }

        .ohersff {
          div {
            border-radius: 4px;
            padding: 3px 8px;
            color: #0493ff;
            background: rgba(229, 244, 255, 1);
            font-size: 14px;
          }
        }

        .stepBox {
          > div {
            align-items: center;
            margin: 6px 0;

            div {
              width: 6px;
              height: 6px;
              border: 3px solid #51b875;
              border-radius: 50%;
            }

            p {
              font-size: 14px;
              color: rgba(102, 102, 102, 1);
            }
          }
        }

        .ziyubox {
          > div {
            display: flex;
            align-items: center;
            margin-top: 12px;

            div {
              img {
                width: 20px;
                height: 20px;
                display: block;
              }
            }

            p {
              font-size: 14px;
              line-height: 20px;
              color: #666;
              margin-left: 10px;
            }
          }
        }

        .times {
          height: 24px;
          line-height: 24px;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #666;
          padding: 10px 0 4px 0;
        }

        .timesab {
          position: absolute;
          top: 160px;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          width: 100%;
          font-size: 14px;
          color: rgba(255, 255, 255, 1);
          padding: 5px 10px;
          box-sizing: border-box;
        }

        .zhutis {
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
          height: 18px;
        }

        .bomBox {
          border-top: 1px solid rgba(220, 223, 230, 1);

          p {
            line-height: 17px;

            &:first-child {
              font-size: 20px;
              font-weight: bold;
              color: rgba(255, 128, 41, 1);
            }

            &:last-child {
              font-size: 15px;
              font-weight: bold;
              color: rgba(0, 128, 255, 1);
            }
          }
        }

        .bomimgBox {
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          left: 0;
          bottom: 24px;

          div {
            display: flex;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              border-radius: 100%;
              display: block;
            }

            p {
              margin-left: 12px;
              font-size: 14px;
              color: #333;
            }
          }

          > p {
            font-size: 14px;
            color: #999;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .zhenli {
      &:hover {
        margin-top: -3px;
        box-shadow: 0 3px 8px 0 rgba(18, 139, 251, 0.26);
      }
    }
  }

  //教研直播
  .huodongliBox {
    .kechengli {
      position: relative;
    }

    .state_bag {
      position: absolute;
      width: 56px;
      height: 24px;
      top: 8px;
      right: 0;
      background: #0080ff;
      border-radius: 4px 0 0 4px;
      font-size: 12px;
      line-height: 24px;
      font-weight: 700;
      text-align: center;
      color: #fff;
      padding: 0 !important;

      // position: absolute;
      // right: 0;
      // top: 15px;
      // width: 56px;
      // height: 24px;
      // line-height: 24px;
      // background: #0080ff;
      // border-radius: 4px 0 0 4px;
      // font-size: 12px;
      // font-family: Microsoft YaHei;
      // font-weight: bold;
      // color: rgba(255, 255, 255, 1);
      // text-align: center;
      // padding: 0 !important;
    }

    .huodongli {
      height: 406px;
      position: relative;

      .titles {
        margin-top: 22px;
        font-size: 15px;
        font-weight: 700;
        color: #111a38;
      }

      .state_bag {
        background: #ff8029;
      }

      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }
    }

    .nowlive {
      &:first-child {
        width: 690px;
        padding: 10px;
        box-sizing: border-box;
        background: linear-gradient(
          -38deg,
          rgba(36, 95, 244, 1) 0%,
          rgba(36, 163, 244, 1) 100%
        );
        position: relative;
        cursor: auto;

        .titleImg {
          position: absolute;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          top: 10px;
          left: 10px;
          z-index: 22;
          border-radius: 8px;
          overflow: hidden;
        }

        .livebox3 {
          cursor: pointer;
          position: absolute;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          top: 10px;
          left: 10px;
          z-index: 25;
          padding-top: 90px;
          text-align: center;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 8px;
          overflow: hidden;

          .title {
            font-size: 30px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
          }

          .zhuangt {
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            margin-top: 21px;
            width: 134px;
            height: 37px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 8px;

            img {
              width: 21px;
              height: 15px;
              margin-right: 10px;
            }

            p {
              font-size: 18px;
              color: rgba(255, 179, 0, 1);
            }
          }

          .zhiTime {
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            margin-top: 21px;
            width: 278px;
            height: 37px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 8px;

            p {
              font-size: 18px;
              color: rgba(255, 255, 255, 1);
            }
          }

          .btnbox {
            margin: 0 auto;
            margin-top: 30px;
            width: 195px;
            height: 48px;
            background: rgba(255, 128, 41, 1);
            border-radius: 8px;
            font-size: 24px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 48px;
          }

          .btnbox2 {
            margin: 0 auto;
            margin-top: 30px;
            width: 195px;
            height: 48px;
            background: linear-gradient(
              -38deg,
              rgba(36, 95, 244, 1) 0%,
              rgba(36, 163, 244, 1) 100%
            );
            border-radius: 8px;
            font-size: 24px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 48px;
          }

          .fotbotm {
            position: absolute;
            width: 100%;
            height: 68px;
            background: rgba(0, 0, 0, 0.7);
            bottom: 0;
            left: 0;
            padding: 0 25px;
            box-sizing: border-box;

            .botm1 {
              align-items: center;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }

              p {
                font-size: 18px;
                color: rgba(242, 242, 242, 1);
                margin: 0 20px 0 12px;
              }

              span {
                height: 24px;
                background: #51b875;
                border-radius: 4px;
                font-size: 14px;
                font-weight: bold;
                line-height: 24px;
                color: #ffffff;
                padding: 0 12px;
              }
            }

            .botm2 {
              align-items: center;

              p {
                font-size: 16px;
                color: #fff;

                span {
                  color: #ff8029;
                }
              }
            }
          }
        }

        .livebox4 {
          cursor: pointer;
          position: absolute;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          top: 10px;
          left: 10px;
          z-index: 25;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 8px;
          overflow: hidden;

          .bofang {
            position: absolute;
            width: 38px;
            height: 38px;
            right: 24px;
            bottom: 15px;
            align-items: center;

            img {
              width: 36px;
              height: 36px;
              background: rgba(52, 137, 254, 1);
              border-radius: 18px;
            }
          }

          .boxbox {
            padding: 95px 82px;
            box-sizing: border-box;

            .titless {
              font-size: 30px;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
            }

            .boxBomimg {
              align-items: center;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: block;
              }

              p {
                color: #fff;
                font-size: 18px;

                &:last-child {
                  font-size: 16px;
                  color: rgba(255, 255, 255, 1);
                }
              }
            }

            .boxZiyu {
              > div {
                > div {
                  img {
                    width: 20px;
                    height: 20px;
                  }
                }

                p {
                  font-size: 14px;
                  color: rgba(255, 255, 255, 1);
                }
              }
            }
          }
        }
      }
    }
  }

  // //教研直播
  // .huodongliBox {
  //   .huodongli {
  //     height: 406px;

  //     &:hover {
  //       margin-top: -3px;
  //       box-shadow: 0 3px 8px 0 rgba(18, 139, 251, 0.26);
  //     }
  //   }
  //   .nowlive {
  //     &:first-child {
  //       width: 690px;
  //       padding: 10px;
  //       box-sizing: border-box;
  //       background: linear-gradient(
  //         -38deg,
  //         rgba(36, 95, 244, 1) 0%,
  //         rgba(36, 163, 244, 1) 100%
  //       );
  //       position: relative;
  //       cursor: auto;
  //       .titleImg {
  //         position: absolute;
  //         width: calc(100% - 20px);
  //         height: calc(100% - 20px);
  //         top: 10px;
  //         left: 10px;
  //         z-index: 22;
  //       }

  //       .livebox3 {
  //         position: absolute;
  //         width: calc(100% - 20px);
  //         height: calc(100% - 20px);
  //         top: 10px;
  //         left: 10px;
  //         z-index: 25;
  //         padding-top: 90px;
  //         text-align: center;
  //         box-sizing: border-box;
  //         background: rgba(0, 0, 0, 0.7);
  //         .title {
  //           font-size: 30px;
  //           color: rgba(255, 255, 255, 1);
  //           text-align: center;
  //         }
  //         .zhuangt {
  //           margin: 0 auto;
  //           justify-content: center;
  //           align-items: center;
  //           margin-top: 21px;
  //           width: 134px;
  //           height: 37px;
  //           background: rgba(0, 0, 0, 0.7);
  //           border-radius: 8px;
  //           img {
  //             width: 21px;
  //             height: 15px;
  //             margin-right: 10px;
  //           }
  //           p {
  //             font-size: 18px;
  //             color: rgba(255, 179, 0, 1);
  //           }
  //         }
  //         .btnbox {
  //           cursor: pointer;
  //           margin: 0 auto;
  //           margin-top: 30px;
  //           width: 195px;
  //           height: 48px;
  //           background: rgba(255, 128, 41, 1);
  //           border-radius: 8px;
  //           font-size: 24px;
  //           font-weight: bold;
  //           color: rgba(255, 255, 255, 1);
  //           text-align: center;
  //           line-height: 48px;
  //         }
  //         .fotbotm {
  //           position: absolute;
  //           width: 100%;
  //           height: 68px;
  //           background: rgba(0, 0, 0, 0.7);
  //           bottom: 0;
  //           left: 0;
  //           padding: 0 25px;
  //           box-sizing: border-box;
  //           .botm1 {
  //             align-items: center;
  //             img {
  //               width: 40px;
  //               height: 40px;
  //               border-radius: 50%;
  //             }
  //             p {
  //               font-size: 18px;
  //               font-weight: bold;
  //               color: rgba(242, 242, 242, 1);
  //               margin: 0 12px 0 16px;
  //             }
  //             span {
  //               background: rgba(81, 184, 117, 1);
  //               border-radius: 4px;
  //               padding: 3px 6px;
  //             }
  //           }
  //           .botm2 {
  //             align-items: center;
  //             p {
  //               font-size: 16px;
  //               font-weight: normal;
  //               color: rgba(242, 242, 242, 1);
  //               span {
  //                 color: #ff8029;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .jyhdbox {
    .jyhdli {
      height: 416px;
      position: relative;
    }
  }

  // 四名工作室
  .gongzuoBox {
    .gongzuoTop {
      .gongzuoli {
        width: 690px;
        height: 360px;
        background-image: url("~@/assets/images/myImg/gongzuobg1.png");
        background-size: 100% 100%;
        border-radius: 8px;
        box-sizing: border-box;
        position: relative;
        padding: 48px 48px 0 48px;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
        }

        &:last-child {
          background-image: url("~@/assets/images/myImg/gongzuobg2.png");
          background-size: 100% 100%;

          .box1 {
            .txtbox {
              p {
                background: rgba(0, 159, 221, 1);
              }
            }
          }
        }

        > div {
          box-sizing: border-box;
        }

        .box1 {
          width: 100%;
          align-items: center;

          .imgbox {
            position: relative;
            margin-right: 22px;

            img {
              width: 80px;
              height: 80px;
              border: 2px solid rgba(255, 255, 255, 1);
              border-radius: 50%;
              box-shadow: 0 4px 16px 0 rgba(234, 168, 17, 0.3);
            }

            .imgabs {
              position: absolute;
              left: -8px;
              top: -8px;
              width: 27px;
              height: 27px;
              border: none;
            }
          }

          .txtbox {
            width: calc(100% - 106px);

            h4 {
              font-size: 24px;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
            }

            p {
              background: rgba(228, 159, 0, 1);
              border-radius: 4px;
              font-size: 14px;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              padding: 2px 5px;
              display: inline-block;
            }
          }
        }

        .box2 {
          > p {
            font-size: 14px;
            color: rgba(255, 255, 255, 1);
            margin-bottom: 16px;
          }

          > div {
            color: #fff;
            margin-bottom: 24px;
            margin-top: 8px;
            display: flex;
            align-items: center;

            p {
              font-size: 14px;
              font-weight: bold;
              color: rgba(252, 255, 0, 1);
            }

            .line {
              width: 1px;
              height: 12px;
              background: #ffffff;
              display: block;
            }
          }

          .pbtn {
            font-size: 16px;
            color: #fff;
          }
        }

        .abBox {
          position: absolute;
          top: 20px;
          right: 0;

          p {
            position: absolute;
            top: 10px;
            left: 46px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(255, 150, 66, 1);
          }

          img {
            width: 120px;
            height: 40px;
            border-radius: 20px 0 0 20px;
          }
        }
      }
    }

    .gongzuoBom {
      margin-top: 25px;

      .gongzuoLis {
        width: 453px;
        height: 226px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 24px 32px;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
          //box-shadow: 0 3px 8px 0 rgba(18, 139, 251, 0.26);
        }

        .litop {
          margin-bottom: 22px;

          .lileft {
            margin-right: 28px;

            img {
              width: 80px;
              height: 80px;
              border-radius: 8px;
              display: block;
            }
          }

          .liright {
            padding-top: 12px;
            position: relative;
            width: calc(100% - 112px);

            h4 {
              font-size: 16px;
              font-weight: bold;
              color: rgba(17, 26, 56, 1);
              margin-bottom: 10px;
              width: 250px;
            }

            .stadbox {
              display: flex;
              align-items: center;

              .stad {
                background: rgba(237, 247, 241, 1);
                border-radius: 4px;
                font-size: 14px;
                color: #51b875;
                padding: 3px 5px;
                margin-right: 12px;
              }

              span {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
              }
            }

            .libn {
              position: absolute;
              top: 10px;
              right: 0px;
              width: 24px;
              height: 24px;
              background: rgba(255, 122, 41, 1);
              border-radius: 12px;
              box-shadow: 0 2px 8px 0 rgba(255, 223, 204, 1);
              color: rgba(255, 255, 255, 1);
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .libom {
          p {
            font-size: 14px;
            color: #666;
            margin-bottom: 10px;

            span {
              color: #487aac;
            }
          }
        }
      }
    }
  }

  // 推荐教研组
  .jiaoyanBox {
    .jiaoyanTop {
      .jiaoyanli {
        width: 690px;
        height: 360px;
        background-image: url("~@/assets/images/myImg/gongzuobg3.png");
        background-size: 100% 100%;
        border-radius: 8px;
        box-sizing: border-box;
        position: relative;
        padding: 48px 48px 0 48px;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
        }

        &:last-child {
          background-image: url("~@/assets/images/myImg/gongzuobg4.png");
          background-size: 100% 100%;

          .box1 {
            .txtbox {
              p {
                background: #5184e9;
              }
            }
          }
        }

        > div {
          box-sizing: border-box;
        }

        .box1 {
          width: 100%;
          align-items: center;

          .imgbox {
            position: relative;
            margin-right: 18px;

            img {
              width: 150px;
              height: 84px;
              background: rgba(255, 255, 255, 1);
              border-radius: 8px;
              box-shadow: 0 4px 16px 0 rgba(6, 183, 140, 0.3);
              display: block;
            }
          }

          .txtbox {
            width: calc(100% - 106px);

            h4 {
              font-size: 24px;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
            }

            p {
              background: #09b58b;
              border-radius: 4px;
              font-size: 14px;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              padding: 2px 5px;
              display: inline-block;
            }
          }
        }

        .box2 {
          > p {
            font-size: 14px;
            color: rgba(255, 255, 255, 1);
            margin-bottom: 16px;
          }

          > div {
            color: #fff;
            margin-bottom: 24px;
            margin-top: 22px;
            display: flex;
            align-items: center;

            p {
              font-size: 14px;
              font-weight: bold;
              color: rgba(252, 255, 0, 1);
            }

            .line {
              width: 1px;
              height: 12px;
              background: #ffffff;
              display: block;
            }
          }

          .pbtn {
            font-size: 16px;
            color: #fff;
          }

          .resource-list {
            display: flex;
            align-items: center;
            margin-top: 0px;
            margin-bottom: 16px;

            img {
              width: 20px;
              height: 20px;
              margin-right: 6px;
              display: block;
            }

            p {
              font-size: 14px;
              color: #ffffff;
              font-weight: normal;
            }
          }
        }
      }
    }

    .jiaoyanBom {
      margin-top: 25px;

      .jiaoyanLis {
        width: 453px;
        height: 226px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 24px 32px;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
          //box-shadow: 0 3px 8px 0 rgba(18, 139, 251, 0.26);
        }

        .litop {
          margin-bottom: 15px;

          .lileft {
            margin-right: 16px;

            img {
              width: 150px;
              height: 84px;
              border-radius: 8px;
              display: block;
            }
          }

          .liright {
            position: relative;
            width: calc(100% - 182px);

            h4 {
              font-size: 16px;
              font-weight: bold;
              color: rgba(17, 26, 56, 1);
              margin-bottom: 8px;
              width: 250px;
            }

            .stadbox {
              align-content: center;

              .stad {
                background: rgba(237, 247, 241, 1);
                border-radius: 4px;
                font-size: 14px;
                color: #51b875;
                padding: 3px 5px;
                margin-right: 12px;

                &:first-child {
                  background: rgba(229, 244, 255, 1);
                  color: #0493ff;
                }
              }

              span {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
              }
            }

            .libn {
              position: absolute;
              top: 20px;
              right: 0px;
              width: 24px;
              height: 24px;
              background: rgba(255, 122, 41, 1);
              border-radius: 12px;
              box-shadow: 0 2px 8px 0 rgba(255, 223, 204, 1);
              color: rgba(255, 255, 255, 1);
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .libom {
          > div {
            margin-bottom: 11px;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 6px;
              display: block;
            }

            p {
              font-size: 14px;
              color: #666;
            }
          }
        }
      }
    }
  }
}
</style>
