<template>
  <div class="link-nav">
    <div class="introduce">
      <!-- <p class="introduce_one">活动介绍</p> -->
      <div class="introduceSty df dfb" :class="link_name == 'basicinfo' ? 'active' : 'introduce_two'" @click="intoBasic">
        <p>活动基本信息</p>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <!-- <div class="heng_one"></div> -->
    <p class="link_title">活动环节
      <span class="temp_class_span_div">-797</span>
    </p>
    <template v-if="false">
      <div class="link_block cursor" @click="getintoOther(item, index)" v-for="(item, index) in linkData" :key="index">
        <!--活动环节 样式 内嵌样式-->
        <div :class="link_index === index ? 'activeOne' : 'block_one'">
          <i class="el-icon-circle-check ifin" v-if="item.finish_status == '2'"></i>
          <i class="el-icon-remove-outline inot" v-if="item.finish_status == '1'"></i>
          <p>{{ item.name }}</p>
        </div>
        <!-- 样式end -->
        <div class="block_two finish" v-if="item.status == '3'">已结束</div>
        <div class="block_two notstart" v-else-if="item.status == '1'">未开始</div>
        <div class="block_two going" v-else-if="item.status == '2'">进行中</div>
      </div>
    </template>

    <div class="linkBox">
      <template v-for="(item, index) in linkData">
        <div :key="index" class="link_item" @click="getintoOther(item, index)">
          <div class="link_item-head">
            <div class="icon_box">
              <img v-if="item.type == 1" src="@/assets/huodon_img/资源浏览@2x.png" alt="" />
              <img v-if="item.type == 2" src="@/assets/huodon_img/资源上传@2x.png" alt="" />
              <img v-if="item.type == 3" src="@/assets/huodon_img/讨论@2x.png" alt="" />
              <img v-if="item.type == 4" src="@/assets/huodon_img/问卷@2x.png" alt="" />
              <img v-if="item.type == 5" src="@/assets/huodon_img/投票@2x.png" alt="" />
              <img v-if="item.type == 6" src="@/assets/huodon_img/在线文档@2x.png" alt="" />
              <img v-if="item.type == 7" src="@/assets/huodon_img/在线直播@2x.png" alt="" />
              <img v-if="item.type == 8" src="@/assets/huodon_img/教研会议@2x.png" alt="" />
            </div>
            <div class="name_box" :class="link_index === index ? 'active' : ''">
              {{ item.type | linkType }}
            </div>
            <div v-if="item.status == 1" class="name_state_box name_state_3">未开始</div>
            <div v-if="item.status == 2" class="name_state_box name_state_1">进行中</div>
            <div v-if="item.status == 3" class="name_state_box name_state_2">已结束</div>
          </div>
          <p class="link_item-title">{{ item.name }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      //定义一个控制样式的变量
      activeClass: -1,
      activeClass0: -1,
      // 活动id
      activityId: "",
      // 环节列表
      linkData: [],
      // 环节名字
      link_name: "",
      // 活动环节活动项
      link_index: "",
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    //初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.is_from = this.$route.query.is_from || 1;
      this.getActivityLink();
      this.link_name = this.$route.name;
      if (this.link_name != "basicinfo") {
        this.link_index = Number(window.localStorage.getItem("link_index"));
      }
    },

    //子向父刷新
    toInit(val) {
      if (val) {
        this.init();
      }
    },
    // 跳转到活动详情页面
    intoBasic() {
      this.activeClass = 1;
      this.$router.push({
        name: "basicinfo",
        query: {
          activityId: this.activityId,
          is_from: this.is_from,
        },
      });
    },
    // 请求后台获取环节列表
    getActivityLink() {
      if (this.is_from == 1) {
        this.$axios.get(`activity/${this.activityId}/activityLinks`).then((res) => {
          this.linkData = res.data.data;
        });
      } else {
        this.$axios
          .get(`TeachingStudioactivity/${this.activityId}/activityLinks`)
          .then((res) => {
            this.linkData = res.data.data;
          });
      }
    },
    one(str) {
      this.$router.push(str);
    },
    // 跳转到其他页面
    getintoOther(item, index) {
      // if (!localStorage.getItem("activity_isJoin")) {
      //   // 没有浏览权限
      //   return this.$message.warning("请先报名参与");
      // }
      //index是下标,
      this.activeClass0 = index;
      window.localStorage.setItem("link_index", index);

      let query = {
        activityId: this.activityId,
        is_from: this.is_from,
        id: item.id,
        t: Date.now(),
      }

      if (this.is_from == 2) {
        query.studioId = this.$route.query.studioId;
        query.is_show_head = 1
      }
      
      switch (Number(item.type)) {
        //资源浏览环节1
        case 1:
          this.$router.push({
            name: "resourcebrowsing",
            query: query,
          });
          break;
        //资源上传环节1
        case 2:
          this.$router.push({
            name: "resourceuploadbrowsing",
            query: query
          });
          break;
        //讨论环节1
        case 3:
          this.$router.push({
            name: "discussbrowsing",
            query: query
          });
          break;
        // 问卷环节1
        case 4:
          this.$router.push({
            name: "questionbrowsing",
            query: query
          });
          break;
        // 投票环节1
        case 5:
          this.$router.push({
            name: "votebrowsing",
            query: query
          });
          break;
        // 协作文档环节1
        case 6:
          this.$router.push({
            name: "collaborationbrowsing",
            query: query
          });
          break;
        // 在线直播环节1
        case 7:
          this.$router.push({
            name: "livebrowsing",
            query: query
          });
          break;
        // 教研会议环节1
        case 8:
          this.$router.push({
            name: "meetbrowsing",
            query: query
          });
          break;
        // 在线文档环节
        case 9:
          this.$router.push({
            name: "OnLineWord",
            query: query
          });
          break;
      }
    },
  },
  watch: {},
  filters: {
    linkType(type) {
      let text = "";
      if (type == 1) {
        text = "资源浏览";
      }
      if (type == 2) {
        text = "资源上传";
      }
      if (type == 3) {
        text = "讨论";
      }
      if (type == 4) {
        text = "问卷";
      }
      if (type == 5) {
        text = "投票";
      }
      if (type == 6) {
        text = "协作文档";
      }
      if (type == 7) {
        text = "在线直播";
      }
      if (type == 8) {
        text = "教研会议";
      }
      return text;
    }
  }
};
</script>

<style lang="less" scoped>
.link-nav {
  width: 264px;
  background: #ffffff;
  border-radius: 8px;
  padding-bottom: 10px;
  .introduce {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 24px 14px;
    .introduceSty {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      align-items: center;
      padding: 0 16px;
      background-color: #e9f3ff;
      p {
        font-size: 16px;
        font-weight: bold;
        color: #3489fe;
      }
      i {
        font-size: 12px;
        font-weight: bold;
        color: #3489fe;
      }
    }

    .introduce_one {
      margin: 30px 0px 24px 30px;
      font-weight: 700;
    }
    .introduce_two {
      cursor: pointer;
      display: flex;
    }
    .active {
      cursor: pointer;
      color: #3489fe;
      display: flex;
    }
  }
  .heng_one {
    width: 100%;
    height: 1px;
    background: #ebebeb;
    margin-bottom: 30px;
  }
  .link_title {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    padding: 0 28px 18px 28px;
  }
  .link_block {
    // height: 83px;
    padding: 0 27px;
    // line-height: 83px;
    // margin: 0px 15px 0px 30px;
    // border-bottom: 1px dashed #ececec;
    margin-top: 20px;
    .block_one {
      display: flex;
      align-items: center;
      // margin-top: 24px;
      margin-bottom: 12px;
      // i:nth-child(1) {
      //   color: #ffa84f;
      //   margin-right: 14px;
      // }
      .ifin {
        color: #ffa84f;
        margin-right: 14px;
      }
      .inot {
        color: #999999;
        margin-right: 14px;
      }
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 36px;
      }
    }
    //点击样式
    .activeOne {
      display: flex;
      align-items: center;
      // margin-top: 24px;
      margin-bottom: 12px;
      color: #3489fe;
      .ifin {
        color: #ffa84f;
        margin-right: 14px;
      }
      .inot {
        color: #999999;
        margin-right: 14px;
      }
      p {
        width: 203px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 36px;
      }
    }
    .block_two {
      margin-left: 32px;
      width: 51px;
      height: 18px;
      border-radius: 9px;
      margin-bottom: 17px;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .finish {
      background: #cecece;
    }
    .notstart {
      background: #3489fe;
    }
    .going {
      background: #ff8201;
    }
  }
  .link_block:last-child {
    border-bottom: 0px;
  }
  .linkBox {
    width: 100%;
    padding: 0 28px;
    box-sizing: border-box;
    .link_item {
      margin-bottom: 20px;
      //height: 70px;
      cursor: pointer;
      .link_item-head {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
      .link_item-title {
        font-size: 16px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding-left: 28px;
      }
    }
    .icon_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name_box {
      flex: 1;
      margin-left: 8px;
      font-size: 16px;
      line-height: 20px;
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      &.active {
        color: #3489fe;
        font-weight: bold;
      }
    }
    .name_state_box {
      width: 51px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      border-radius: 4px;
      font-size: 12px;
      font-weight: normal;
    }
    .name_state_1 {
      border: 1px solid rgba(255, 136, 14, 1);
      color: rgba(255, 136, 14, 1);
    }
    .name_state_2 {
      border: 1px solid #cecece;
      color: #cecece;
    }
    .name_state_3 {
      border: 1px solid #3489fe;
      color: #3489fe;
    }
  }
}
</style>
