<template>
  <div class="HonorIndex">
    <div class="TopClass">
      <span>成果展示</span>
      <span @click="toMoreBook" class="cursor">更多>></span>
    </div>
    <!-- 图片样式 -->
    <div class="ImgBox" v-if="HonorList.length > 0">
      <div v-for="(item, index) in HonorList" class="cursor cardBox" :key="index" @click="toHonorDetail(item.id)">
        <img class="img_cover" :src="item.resource_url" alt="">
        <div class="txt">
          <h4 class="ellipsis-1">{{ item.name }}</h4>
          <p class="ellipsis-2">{{ item.synopsis }}</p>
        </div>
      </div>
    </div>
    <CommonEmpty v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      HonorList: [],
      form: {
        page: 1,
        limit: 10,
        teaching_studio_id: localStorage.getItem('studioId'),
      }

    }
  },
  created() {
    //初始化
    this.init();
  },
  methods: {
    //跳转更多
    toMoreBook() {
      this.$router.push('/Certificate')
    },
    //初始化
    init() {
      this.HonorData();
    },
    //荣誉证书列表
    HonorData() {
      this.$axios.get('indexTeachingStudioCertificate/lists', {
        params: this.form
      }).then((res) => {
        this.HonorList = res.data.data.data.slice(0, 4);
      })
    },
    //跳转荣誉证书详情
    toHonorDetail(id) {
      this.$router.push({
        path: '/CertificateDetail',
        query: {
          id: id,
          is_from: 1
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.HonorIndex {
  width: 1106px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 26px;
  padding: 24px 24px;

  //顶头标题
  .TopClass {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:nth-of-type(1) {
      font-size: 16px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 700;
      text-align: left;
      color: #333333;
    }

    span:nth-of-type(2) {
      font-size: 16px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      cursor: pointer;
    }
  }

  //图片
  .ImgBox {
    display: flex;
    padding-top: 27px;

    .cardBox {
      width: 248px;
      margin-right: 20px;

      img {
        width: 248px;
        height: 159px;
        border-radius: 8px;
      }

      h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(42, 48, 55, 1);
      }

      p {
        font-size: 14px;
        font-weight: normal;
        color: rgba(102, 102, 102, 1);
      }
    }
  }
}
</style>