<template>
  <!-- 备课 -->
  <div class="NextFocus">
    <!-- 头部 -->
    <div class="NextFocus_box">
      <!-- 左边图片 -->
      <div class="TopLeft">
        <img :src="form.cover_url" alt="" />
      </div>
      <!-- 右边 -->
      <div class="FocusContent">
        <!-- 标题 -->
        <div class="titleTop">
          <h3>
            {{form.name}}
          </h3>
        </div>
        <!-- 便签 -->
        <div class="type_v">
          <div>课程分类：<span>{{form.course_type}}</span></div>
          <div>学段学科：<span>{{form.stage_name}} &nbsp;&nbsp; {{form.subject_name}}</span></div>
          <div>年级：<span>{{form.grade_name}}</span></div>
          <div>教材版本：<span>{{form.edition_name}}</span></div>
        </div>
        <!-- 最后一行 -->
        <div class="bq_last">
          <p style="margin-bottom:20px">
            适用章节：<span>{{form.chapter_name}}</span>
            <span v-if="form.sign_type==1" style="color:#333333;margin-left:20px">作者：<span style="color:#999999">{{username}}</span></span>
            <span v-if="form.sign_type==2" style="color:#333333;margin-left:20px">作者：<span style="color:#999999">{{form.author}}</span></span>
            <span v-if="form.sign_type==3" style="color:#333333;margin-left:20px">作者：<span style="color:#999999">{{form.author}}</span></span>
            <span v-if="form.sign_type==4" style="color:#333333;margin-left:20px">作者：<span style="color:#999999">{{form.sign_text}}</span></span>

          </p>
          <p>
            备课时间：<span>{{form.start_date}}&nbsp;&nbsp;{{form.start_time}}&nbsp;&nbsp; -
              &nbsp;&nbsp;{{form.end_date}}&nbsp;&nbsp;{{form.end_time}}</span>
          </p>
        </div>
        <el-button type="primary" @click="sendFocusCourse">发布课程</el-button>
        <el-button type="success" @click="toRepalceFocus()">修改课程</el-button>
        <el-button type="info" @click="deleteInforMation">删除课程</el-button>
      </div>
    </div>
    <!-- 内容 -->
    <div class="dbBox">
      <div class="boxContent">
        <div class="Box_top">
          <div :class="num == 0 ? 'active' : ''" @click="getOne">视频介绍</div>
          <div :class="num == 1 ? 'active' : ''" @click="getTwo">视频内容</div>
        </div>
        <!-- 视频介绍 -->
        <div v-if="num ==0">
          <div class="Box_middle">
            <h4>课程概述</h4>
            <div>
              <p class="rich_text_div_img" v-html="form.presentation" style="margin-left:36px;margin-top:10px;margin-right:30px">
              </p>
            </div>
          </div>
          <div class="Box_bottom">
            <h4>作者简介</h4>
            <div class="fix_one">
              <div class="itemBox" v-for="(item,index) in  AutherList" :key="index">
                <div class="list_left">
                  <img :src="item.photo_url" alt="">
                </div>
                <div class="list_right">
                  <span style="margin-right:5px">{{item.realname}}</span>
                  <span>{{item.stage_text}}&nbsp;&nbsp;{{item.subject_text}}</span>
                  <p>{{item.org_name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 视频内容 -->
        <div v-if="num ==1">
          <!-- 循环部分 -->
          <div class="forBox" v-for="(item,index) in chapterArry" :key="index">
            <div class="titleItem">
              <div class="one">
                <span>{{item.chapter}}</span>
                <span>{{item.title}}</span>
              </div>

              <div class="two">
                <!-- 修改章节内容 -->
                <img src="../../../../assets/images/personal/bj.png" alt="" @click="editZJ(item.id)">
                <!-- 删除 -->
                <img src="../../../../assets/images/personal/ljt.png" alt="" @click="deleteBox(item.id)">
                <span @click="addSmall(item.id)">添加小节</span>
              </div>
            </div>
            <!-- 添加小节 -->
            <div class="add_dialog_XJ">
              <el-dialog title="添加小节" :visible.sync="add_dialog_XJ" style="width:1200px;margin:0 auto">
                <h4>添加基础内容</h4>
                <div class="one">
                  <div class="one_one">
                    <div @click="addText()">
                      <img src="../../../../assets/images/add-wenben.png" alt="">
                    </div>
                    <div @click="addWords()">
                      <img src="../../../../assets/images/add-word.png" alt="">
                    </div>
                    <div @click="addVideo()">
                      <img src="../../../../assets/images/add-video.png" alt="">
                    </div>
                  </div>
                  <p>
                    <span @click="addText()">添加文本</span>
                    <span @click="addWords()">添加文档</span>
                    <span @click="addVideo()">添加视频</span>
                  </p>
                </div>
                <h4>添加互动环节</h4>
                <div class="two">
                  <div class="two_one">
                    <div @click="addTopic()">
                      <img src="../../../../assets/images/add-comment.png" alt="">
                    </div>
                    <div @click="toUrlCreate()">
                      <img src="../../../../assets/images/add-paper.png" alt="">
                    </div>

                  </div>
                  <p>
                    <span @click="addTopic()">添加讨论</span>
                    <span @click="toUrlCreate()">添加试题试卷</span>
                  </p>
                </div>

              </el-dialog>
            </div>

            <!-- 修改章节 -->
            <div>
              <el-dialog title="修改" :visible.sync="dialogZJ" style="width:1200px;">
                <span>标题</span>
                <el-input v-model="form5.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <el-button type="primary" @click="noRepalce">取消</el-button>
                <el-button type="success" @click="updataInfos(item.id)">修改</el-button>
              </el-dialog>
            </div>
            <div class="forOneBox" v-for="(item1,index1) in item.child" :key="index1">
              <div class="one">
                <span>{{item1.title}}</span>
              </div>
              <div class="two">
                <img src="../../../../assets/images/personal/bj.png" alt="" @click="editChild(item1.id)">
                <img src="../../../../assets/images/personal/ljt.png" alt="" @click="deleteBox(item1.id)">
              </div>
            </div>

          </div>
          <!-- 修改小节内嵌套五个 -->
          <div class="add_dialog_XJ_one">
            <!-- 修改文本 -->
            <div class="itemBox1">
              <el-dialog title="修改文本" :visible.sync="c1" style="width:1600px;">

                <span>小节标题</span>
                <el-input v-model="form4.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <span>小节内容</span>
                <Editor v-model="form4.presentation"></Editor>
                <br>
                <el-button type="primary" @click="change1()" style="margin-top:100px">确定</el-button>
                <el-button type="success" @click="c1=false;">取消</el-button>

              </el-dialog>
            </div>
            <!-- 修改文档 -->
            <div class="itemBox2">

              <el-dialog title="修改文档" :visible.sync="c2" style="width:1200px;">

                <span>小节标题</span>
                <el-input v-model="form4.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <div style="display:flex;margin-bottom:20px">
                  <span style="margin-right:10px">文档内容</span>
                  <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource> <span
                    style="color:#999999;margin-left:10px">提示：支持doc、docx、xls、xls、ppt、pptx、txt格式</span>
                </div>
                <!-- 循环的资源 -->

                <div v-for="(a1,indx1) in wordList" :key="indx1" style="margin-left:50px;margin-bottom:10px;display:flex">
                  <p style="margin-right:60px;width:250px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;word-break:keep-all;">
                    {{a1.title}}</p>
                  <!--  -->
                  <span style="margin-right:10px;cursor: pointer;"> <a :href="a1.resource_url" target="_blank" style="">预览 </a></span><span
                    style="margin-right:10px">|</span>

                  <span @click="deleteList0(indx1)" style="cursor:pointer;">删除</span>

                </div>
                <el-button style="margin-left:160px;margin-top:20px" type="primary" @click="change1()">确定</el-button>
                <el-button type="success" @click="clearWord();">取消</el-button>

              </el-dialog>
            </div>

            <!-- 修改视频 -->

            <div class="itemBox3">

              <el-dialog title="修改视频" :visible.sync="c3" style="width:1350px;">

                <span>小节标题</span>
                <el-input v-model="form4.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <div style="display:flex;margin-bottom:20px">
                  <span style="margin-right:10px">视频内容</span>
                  <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource> <span
                    style="color:#999999;margin-left:10px">提示：支持avi、mp4、rm、rmvb、wmv、3gp、flv、mpeg、swf格式</span>
                </div>
                <!-- 循环的资源 -->
                <div v-for="(a2,indx2) in resourceList" :key="indx2" style="margin-left:50px;margin-bottom:10px">
                  <div style="display:flex">

                    <p style="margin-right:60px;width:100px">{{a2.title}}</p> <span style="margin-right:10px;cursor: pointer;">预览</span> <span
                      style="margin-right:10px">|</span>
                    <span @click="deleteList2(indx2)" style="cursor: pointer;">删除</span>
                  </div>
                </div>
                <el-button style="margin-left:160px;margin-top:20px" type="primary" @click="change1()">确定</el-button>
                <el-button type="success" @click="clearVideo()">取消</el-button>

              </el-dialog>

            </div>
            <!-- 修改讨论 -->
            <div class="itemBox4">
              <el-dialog title="修改讨论" :visible.sync="c4" style="width:1200px;">
                <span>小节标题</span>
                <el-input v-model="form4.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <span>讨论内容</span><br>
                <el-input type="textarea" v-model="form4.presentation" placeholder="请输入内容" :rows="6"
                  style="width:500px;margin-bottom:20px;margin-left:60px"></el-input><br>
                <el-button type="primary" @click="change1()" style="margin-left:60px">确定</el-button>
                <el-button type="success" @click="c4=false;">取消</el-button>
              </el-dialog>

            </div>
            <div class="itemBox5"></div>
          </div>
          <!-- 修改小节end -->
          <!-- 添加章节-->
          <div class="btn1">
            <el-button type="primary" @click="dialogFormVisible0=true">添加章节</el-button>
          </div>
          <div class="addZJ">
            <el-dialog title="添加章节" :visible.sync="dialogFormVisible0">
              <el-form>
                <el-form-item label="标题">
                  <el-input v-model="form1.title" auto-complete="off" style="width:450px"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="dialogFormVisible0 = false" style="margin-left:170px">取 消</el-button>
                  <el-button type="primary" @click="addChapter">确 定</el-button>
                </el-form-item>
              </el-form>

            </el-dialog>
          </div>
          <!-- 添加小节内嵌套五个 -->
          <div class="add_dialog_XJ_one">
            <!-- 文本 -->
            <div class="itemBox1" style="">
              <el-dialog title="添加文本" :visible.sync="b1" customClass="customWidth">
                <span>小节标题</span>
                <el-input v-model="form3.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <span>小节内容</span>
                <Editor v-model="form3.presentation"></Editor>
                <br>
                <el-button type="primary" @click="insertPrinter()" style="margin-top:100px">确定</el-button>
                <el-button type="success" @click="b1=false;">取消</el-button>

              </el-dialog>
            </div>
            <!-- 文档 -->
            <div class="itemBox2">

              <el-dialog title="添加文档" :visible.sync="b2" style="width:1200px;margin:0 auto">

                <span>小节标题</span>
                <el-input v-model="form3.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <div style="display:flex;margin-bottom:20px">
                  <span style="margin-right:10px">文档内容</span>
                  <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource> <span
                    style="color:#999999;margin-left:10px">提示：支持doc、docx、xls、xls、ppt、pptx、txt格式</span>
                </div>
                <!-- 循环的资源 -->
                <div v-for="(a1,indx1) in resourceList" :key="indx1" style="margin-left:50px;margin-bottom:10px;display:flex">
                  <p style="margin-right:60px;width:250px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;word-break:keep-all;">
                    {{a1.title}}</p>

                  <new-viewonline :viewSrc="a1.view_url" :teaching_studio_user_resource_id="a1.teaching_studio_user_resource_id"
                    :resourceId="a1.resource_id" :fileExt="a1.ext" :itemData="a1" :fileTitle="a1.title" :fileSize="a1.size"
                    :fileTime="a1.create_time">
                    <el-link targer="_blank" :underline="false">
                      <span style="margin-right:10px;cursor:pointer;">预览</span> <span style="margin-right:10px">|</span>
                    </el-link>
                  </new-viewonline>

                  <span @click="deleteList1(indx1)" style="cursor:pointer;">删除</span>

                </div>
                <el-button style="margin-left:160px;margin-top:20px" type="primary" @click="insertPrinter1()">确定</el-button>
                <el-button type="success" @click="b2=false;">取消</el-button>

              </el-dialog>
            </div>

            <!-- 视频 -->

            <div class="itemBox3">

              <el-dialog title="添加视频" :visible.sync="b3" style="width:1350px;margin:0 auto">

                <span>小节标题</span>
                <el-input v-model="form3.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <div style="display:flex;margin-bottom:20px">
                  <span style="margin-right:10px">视频内容</span>
                  <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource> <span
                    style="color:#999999;margin-left:10px">提示：支持avi、mp4、rm、rmvb、wmv、3gp、flv、mpeg、swf格式</span>
                </div>
                <!-- 循环的资源 -->
                <div v-for="(a2,indx2) in resourceList" :key="indx2" style="margin-left:50px;margin-bottom:10px">
                  <div style="display:flex">
                    <div><span style="margin-right:100px">{{a2.title}}</span> </div>
                    <new-viewonline :viewSrc="a2.view_url" :teaching_studio_user_resource_id="a2.teaching_studio_user_resource_id"
                      :resourceId="a2.resource_id" :fileExt="a2.ext" :itemData="a2" :fileTitle="a2.title" :fileSize="a2.size"
                      :fileTime="a2.create_time">
                      <el-link targer="_blank" :underline="false" style="display:flex">

                        <span style="margin-right:10px;cursor:pointer;">预览</span>
                      </el-link>
                    </new-viewonline>

                    <div>
                      <span @click="deleteList2(indx2)" style="cursor:pointer;margin-left:20px">删除</span>
                    </div>
                  </div>
                </div>
                <el-button style="margin-left:160px;margin-top:20px" type="primary" @click="insertPrinter2()">确定</el-button>
                <el-button type="success" @click="b3=false;">取消</el-button>

              </el-dialog>

            </div>
            <div class="itemBox4">
              <el-dialog title="添加讨论" :visible.sync="b4" style="width:1200px;margin:0 auto">
                <span>小节标题</span>
                <el-input v-model="form3.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <span>讨论内容</span>
                <el-input type="textarea" v-model="form3.topic" placeholder="请输入内容" :rows="6" style="width:500px;margin-bottom:20px;margin-left:60px">
                </el-input><br>
                <el-button type="primary" @click="insertPrinter3()" style="margin-left:60px">确定</el-button>
                <el-button type="success" @click="b4=false;">取消</el-button>
              </el-dialog>

            </div>
            <div class="itemBox5"></div>
          </div>
          <!-- end -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //课程id
      id: "",
      //is_one
      is_one: true,
      //authoerList
      AutherList: [],
      //添加试卷地址
      toUrl: "/resource/#/ExaminationPaper",
      groupName: "",
      dialogFormVisible0: false,
      //视频存放
      resourceList: [],
      //文档存放
      wordList: [],
      // 五个小节添加框
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b5: false,
      // 五小节修改个框
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      c5: false,
      //小节内容
      // 添加小节
      add_dialog_XJ: false,
      //章弹出框
      dialogZJ: false,
      //修改小节弹出框
      dialogXJ: false,
      //章节
      chapterArry: "",

      // 署名为3单位
      arryList: "",
      groupId: "",
      orgName: "",
      username: "",
      infoList: "",
      num: 0,
      // 要修改的提交的数据
      form: "",
      //学段
      //单位成员
      form2: {
        limit: 10,
        page: 1,
        stage_id: "",
        subject_id: "",
        province_id: "",
        city_id: "",
        county_id: "",
        realname: "",
        org_name: "",
        group_name: "",
      },
      // 章节
      form1: {
        course_id: 0,
        user_id: 0,
        title: "",
        sort: 1
      },
      // 添加小节
      form3: {
        course_id: 0,
        user_id: "",
        title: "",
        sort: 1,
        pid: "",
        type: "",
        presentation: "",
        questions_ids: "",
        resource_id: ""
      },
      //修改小节
      form4: "",
      //修改小节
      form6: {
        title: "",
        presentation: "",
        resource_id: "",
        questions_ids: "",
      },
      //form5章节内容
      form5: "",
      //作者列表
      form6: {
        id: "",
        limit: 10,
        page: 1,
      },
      zid: 0,
      is_personid: 1,
    }
  },
  created() {
    if (this.$route.query.is_personid) {
      this.is_personid = this.$route.query.is_personid
    };
    //从路由得到课程id
    this.id = this.$route.query.id;
    this.init();
    //通过缓存得到用户名
    this.username = localStorage.getItem("user_name")
    this.getTitle();
    //初始化章节
    this.getChaterList()
    //获取课程id
    this.getAuther()
  },
  methods: {
    //移除文档
    deleteList0(indx1) {
      this.wordList.splice(index1, 1);
    },
    //打开添加文本框
    addText() {
      this.form3.title = ""
      this.form3.presentation = ""
      this.b1 = true
    },
    addWords() {
      this.form3.title = ""
      this.form3.resourceList = []
      this.b2 = true
    },
    addVideo() {
      this.form3.title = ""
      this.resourceList = []
      this.b3 = true
    },
    //添加小节
    addSmall(id) {
      this.zid = id
      this.form3.course_id = 0;
      this.form3.user_id = "";
      this.form3.title = "";
      this.form3.sort = 1;
      this.form3.pid = "";
      this.form3.type = "";
      this.form3.presentation = "";
      this.form3.questions_ids = "";
      this.form3.resource_id = "";
      this.add_dialog_XJ = true
    },
    // 文本
    async insertPrinter() {
      this.form3.pid = this.zid;
      this.form3.course_id = this.id;
      //  用户id
      this.form3.user_id = localStorage.getItem("id");
      //  类型一为文本
      this.form3.type = 1;
      this.form3.is_personid = this.is_personid;
      let res = await this.$axios.post("courseLink/insertSection", this.form3);
      if (res.status == 200) {
        this.$message.success("添加小节成功");
      }
      this.b1 = false
      this.add_dialog_XJ = false
      //重新获取章节
      this.getChaterList()
    },
    //  文档添加
    async insertPrinter1() {
      this.form3.pid = this.zid;
      //  课程id
      this.form3.course_id = this.id;
      //  用户id
      this.form3.user_id = localStorage.getItem("id");
      //  类型二为文档
      this.form3.type = 2;
      //  得到id
      let arr = this.resourceList.map((item) => {
        return item.id
      })
      this.form3.resource_id = arr;
      this.form3.resource_id = this.form3.resource_id.toString();
      this.form3.is_personid = this.is_personid;
      let res = await this.$axios.post("courseLink/insertSection", this.form3);
      if (res.status == 200) {
        this.$message.success("添加小节成功");
      }
      this.b2 = false
      this.add_dialog_XJ = false
      //重新获取章节
      this.getChaterList()
    },
    //  视频添加
    async insertPrinter2() {
      this.form3.pid = this.zid;
      //  课程id
      this.form3.course_id = this.id;
      //  用户id
      this.form3.user_id = localStorage.getItem("id");
      //  类型三为视频
      this.form3.type = 3;
      //  得到id
      let arr = this.resourceList.map((item) => {
        return item.id
      })
      this.form3.resource_id = arr;
      this.form3.resource_id = this.form3.resource_id.toString();
      this.form3.is_personid = this.is_personid;
      let res = await this.$axios.post("courseLink/insertSection", this.form3);
      if (res.status == 200) {
        this.b3 = false
        this.add_dialog_XJ = false
        //重新获取章节
        this.getChaterList()
        this.$message.success("添加小节成功");
      }
    },
    // 添加讨论
    async insertPrinter3() {
      this.form3.pid = this.zid;
      //  课程id
      this.form3.course_id = this.id;
      //  用户id
      this.form3.user_id = localStorage.getItem("id");
      //  类型一为文本
      this.form3.type = 4;
      this.form3.presentation = this.form3.topic;
      this.form3.is_personid = this.is_personid;
      let res = await this.$axios.post("courseLink/insertSection", this.form3);
      if (res.status == 200) {
        this.$message.success("添加小节成功");
      }
      this.b4 = false
      this.add_dialog_XJ = false
      //重新获取章节
      this.getChaterList()

    },
    //添加讨论
    addTopic() {
      this.b4 = true;
      //清空标题
      this.form3.title = "";
      //打开的时清空内容
      this.form3.presentation = "";
    },
    //获取作者列表
    async getAuther() {
      //从路由得到课程id
      this.form6.id = this.id;
      this.form6.is_personid = this.is_personid;
      let res = await this.$axios.get("course/get_author", {
        params: this.form6
      })
      // 判断是否是数组
      if (Array.isArray(res.data.data.data)) {
        // 判断是数组
        this.AutherList = res.data.data.data;
      } else {
        this.AutherList.push(res.data.data.data)
      }
    },

    toUrlCreate() {
      let toUrl = this.toUrl;
      skipOutstation(toUrl)
    },
    //资源组件
    getResource(data) {
      this.resourceList = []
      this.resourceList.push(data[0])
    },
    // 弹出章节修改框
    async editZJ(id) {
      //获取章节详情
      let res = await this.$axios.get(`courseLink/get_info/${id}?is_personid=${this.is_personid}`)
      this.form5 = res.data.data;
      this.dialogZJ = true
    },
    //修改章节
    async updataInfos(id) {
      this.form5.is_personid = this.is_personid;
      let res = await this.$axios.put(`courseLink/${id}?is_personid=${this.is_personid}`, this.form5)
      // 关闭修改章节框
      if (res.status == 200) {
        this.$message.success("修改成功")
        this.dialogZJ = false
        //重新获取章节
        this.getChaterList()
      } else {
        this.$message.warning("修改失败")
      }
    },
    // 关闭的同时清除videoid
    clearVideo() {
      this.c3 = false;
      this.resourceList = [];
    },
    //关闭修改文档的同时清空数组
    clearWord() {
      this.c2 = false
      this.wordList = [];
    },
    //修改小节
    async change1() {
      this.form6.title = this.form4.title
      this.form6.presentation = this.form4.presentation
      //将数组之中的id提取出来
      if (this.resourceList.length != 0) {

        let arr1 = this.resourceList.map((item) => {
          return item.id
        })
        this.form6.resource_id = arr1;
      }
      if (this.wordList.length != 0) {

        let arr1 = this.wordList.map((item) => {
          return item.id
        })
        this.form6.resource_id = arr1;
        this.form6.resource_id = this.form6.resource_id.toString();
      }
      //  this.form6.resource_id=this.form4.resource_id
      //将文档id转为字符串

      this.form6.questions_ids = this.form4.questions_ids;
      this.form6.is_personid = this.is_personid;
      let res = await this.$axios.put(`courseLink/${this.form4.id}`, this.form6);
      if (res.status == 200) {
        this.$message.success("修改成功");
        // 关闭文本框弹框
        this.c1 = false;
        this.c2 = false;
        this.c3 = false;
        this.c4 = false;
        // 刷新章节
        this.getChaterList();
      } else {
        this.$message.warning("修改失败")
      }
    },
    // 获取小节详情并显示框
    async editChild(id) {
      let res = await this.$axios.get(`courseLink/${id}?is_personid=${this.is_personid}`);
      this.form4 = res.data.data;

      //  判断类型
      if (this.form4.type == 1) {
        //  类型为1，就显示文本框

        this.c1 = true;
      } else if (this.form4.type == 2) {
        this.wordList.push(this.form4)
        //  this.resourceList=res.data.data.data

        //  类型为2，就显示文档
        this.c2 = true;
        // 刷新章节
      } else if (this.form4.type == 3) {
        this.resourceList.push(res.data.data)
        //  类型为3，就显示视频
        this.c3 = true;
      } else if (this.form4.type == 4) {
        //  类型为4，就显示讨论
        this.form4 = res.data.data;
        this.c4 = true;
      }
    },
    //  视频删除
    deleteList2(a) {
      this.resourceList.splice(a, 1);
    },
    //  添加文档的删除
    deleteList1(index1) {
      this.resourceList.splice(index1, 1);
    },
    //获取章节列表
    async getChaterList() {
      let res = await this.$axios.get("courseLink/get_list", {
        params: {
          course_id: this.id,
          is_personid: this.is_personid,
        }
      })
      this.chapterArry = res.data.data
    },
    //跳转到集中备课修改页面
    toRepalceFocus() {
      this.$router.push({
        path: '/updataCourseInfo',
        query: {
          id: this.id,
          is_personid: this.form.is_personid,
          groupId: this.form.group_id,
        },
      });
    },
    //  初始化数据
    async init() {
      let res = await this.$axios.get(`course/get_info/${this.id}?is_personid=${this.is_personid}`)
      let resData = res.data.data;
      this.form = resData;
      //调用个人信息
      this.getOneInfo()
    },
    //发布集中备课
    sendFocusCourse() {
      this.$confirm('确定要发布吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.put(`courses/${this.form.id}/publish?is_personid=${this.is_personid}`);
        this.$message.success("发布课程成功")
        this.$router.push(`/preparelessons?id=${this.form.id}&is_personid=${this.is_personid}`)
      }).catch(() => {
        this.$message.warning("已取消发布课程")
      });
    },
    // 关闭修改章节框
    noRepalce() {
      this.dialogZJ = false
    },
    //删除节
    deleteKnob(index, index1) {
      this.chapterArry[index].knobChild.splice(index1, 1)
    },
    // 添加章
    async addChapter() {

      //从缓存中得到用户id
      let uid = localStorage.getItem("id")
      //课程id
      this.form1.course_id = this.id;
      //用户id
      this.form1.user_id = uid;
      this.form1.is_personid = this.is_personid;
      let res = await this.$axios.post("courseLink/insertChapter", this.form1)
      if (res.status == 200) {
        this.$message.success("添加章节成功");
      }
      this.dialogFormVisible0 = false;
      //重新获取章节列表
      this.getChaterList();
    },
    //删除章
    async deleteBox(id) {
      let res = await this.$axios.delete(`courseLink/${id}?is_personid=${this.is_personid}`);
      if (res.status == 200) {
        this.$message.success("删除成功");
        //重新获取章节列表
        this.getChaterList();
      }
    },
    //  得到指定成员
    async getWorkPoeple() {
      this.form2.is_personid = this.is_personid;
      let res = await this.$axios.get("get_appoint_user", this.form2);
      this.arryList = res.data.data.data;
    },
    //  删除
    deleteInforMation() {
      this.$confirm('删除该课程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //删除课程
        this.$axios.delete("courses/delCourse", {
          params: {
            id: this.form.id,
            is_personid: this.is_personid,
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        setTimeout(() => {
          this.$router.go(-2)
        }, 1000)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //得到用户简介
    async getOneInfo() {
      // 从缓存里得到用户信息
      let userId = this.form.user_id;
      let res = await this.$axios.get(`/user/${userId}/edit?is_personid=${this.is_personid}`)
      this.orgName = res.data.data.org_name
    },
    //得到个人简介
    async getTitle() {
      // 从缓存里得到用户信息
      let userId = localStorage.getItem("id")
      let res = await this.$axios.get(`user/${userId}/little?is_personid=${this.is_personid}`)
      this.infoList = res.data.data
      this.form2.org_name = res.data.data.org_name
    },
    //    视频介绍
    getOne() {
      this.num = 0;
    },
    //    视频内容
    getTwo() {
      this.num = 1;
    }
  }
}
</script>

<style lang="less">
//num=2
.NextFocus {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  //头部
  .NextFocus_box {
    width: 80%;
    height: 320px;
    margin-top: 10px;
    margin-left: 10%;
    display: flex;
    margin-bottom: 10px;
    padding-top: 30px;
    // 左边图片
    .TopLeft {
      margin-right: 20px;
      img {
        width: 520px;
        height: 293px;
      }
    }
    //头部右边
    .FocusContent {
      margin-left: 20px;
      .titleTop {
        padding-top: 20px;
        margin-bottom: 30px;
        height: 30px;
        display: flex;
        margin-bottom: 30px;
        h3 {
          line-height: 28px;
          margin-right: 15px;
        }
        div {
          margin-right: 5px;
        }
      }
      //  便签
      .type_v {
        display: flex;
        margin-bottom: 30px;
        div {
          font-size: 18px;
          margin-right: 10px;
          span {
            color: #999999;
          }
        }
      }
      //最后一行
      .bq_last {
        font-size: 18px;
        margin-bottom: 30px;
        span {
          color: #999999;
        }
      }
    }
    //  按钮
    #wzt {
      font-size: 14px;
      color: red;
    }
  }
  .dbBox {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f7f7f7;
    .boxContent {
      width: 80%;
      margin-left: 10%;
      background: #ffffff;
      padding-bottom: 50px;
      //循环box
      .forBox {
        width: 900px;
        margin-top: 20px;

        margin-left: 40px;
        line-height: 40px;
        padding: 5px;
        //    章样式
        .titleItem {
          display: flex;
          justify-content: space-between;
          background: rgba(248, 248, 248, 1);
          .one {
            span {
              margin-right: 20px;
            }
          }
          .two {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 10px;
            }
            font-size: 17px;
            span {
              width: 80px;
              height: 30px;
              color: #ffffff;
              line-height: 30px;
              text-align: center;
              border-radius: 6px;
              border: 1px solid #3489fe;
              background: #3489fe;
              margin-right: 10px;
            }
          }
        }
        ///添加小节弹出框
        .add_dialog_XJ {
          h4 {
            color: #999999;
          }
          .one {
            .one_one {
              display: flex;
              justify-content: center;
              div {
                margin-right: 80px;

                img {
                  width: 80px;
                  height: 80px;
                }
              }
            }
            p {
              text-align: center;
              span {
                cursor: pointer;
                margin-left: 15px;
                margin-right: 92px;
              }
              span:nth-of-type(3) {
                margin-left: 12px;
              }
            }
          }
          .two {
            .two_one {
              display: flex;
              margin-left: 33px;
              div {
                margin-right: 100px;

                img {
                  width: 80px;
                  height: 80px;
                }
              }
              div:nth-of-type(2) {
                margin-left: -10px;
              }
            }
            p {
              span {
                cursor: pointer;
                margin-left: 40px;
              }
              span:nth-of-type(2) {
                margin-left: 110px;
              }
            }
          }
        }
        //添加小节内嵌套五个
        .add_dialog_XJ_one {
          .itemBox2 {
            .customWidth {
              width: 70%;
              height: 600px;
            }
          }
          //  .itemBox3{

          //  }
          //  .itemBox4{

          //  }
          //  .itemBox5{

          //  }
        }
        //    节样式
        .forOneBox {
          display: flex;
          width: 80%;
          margin-left: 10%;
          margin-top: 20px;
          border: 1px solid #f7f7f7;
          background: #f7f7f7;
          justify-content: space-between;
          .one {
            span {
              margin-right: 20px;
            }
          }
          .two {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 10px;
            }
          }
        }
      }
      .btn1 {
        width: 900px;
        margin-top: 80px;
        text-align: center;
      }
      .addZJ {
      }
      .Box_top {
        display: flex;
        div {
          margin-top: 20px;
          margin-left: 20px;
          line-height: 50px;
          text-align: center;
          width: 100px;
          height: 50px;
          font-weight: bold;
        }
        .active {
          color: #409eff;
          border-bottom: 2px solid #409eff;
        }
      }
      .Box_middle {
        margin-top: 20px;
        h4 {
          margin-left: 35px;
        }
        .text_box {
          box-sizing: border-box;
          margin-left: 35px;
          padding: 5px 5px;
          p {
            width: 99%;
            margin-left: 20px;
            margin-top: 10px;
            text-indent: 2em;
            overflow: hidden; /* 2.超出部分隐藏 */
            text-overflow: ellipsis; /* 3.超出文本用省略号表示(...) */
            display: -webkit-box; /*设置元素为 弹性盒子*/
            -webkit-line-clamp: 13; /*设置省略号所在行数*/
            -webkit-box-orient: vertical; /* 设置排版方向*/
          }
        }
        .text_box1 {
          box-sizing: border-box;
          margin-left: 35px;
          padding: 5px 5px;
          p {
            width: 99%;
            margin-top: 10px;
            text-indent: 2em;
            // overflow: hidden; /* 2.超出部分隐藏 */
            // text-overflow: ellipsis; /* 3.超出文本用省略号表示(...) */
            // display: -webkit-box; /*设置元素为 弹性盒子*/
            // -webkit-line-clamp: 13; /*设置省略号所在行数*/
            // -webkit-box-orient: vertical; /* 设置排版方向*/
          }
        }
      }
      .Box_bottom {
        margin-top: 20px;
        h4 {
          margin-left: 35px;
        }
        .fix_one {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          .itemBox {
            margin-left: 35px;
            margin-right: 70px;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            .list_left {
              margin-right: 20px;
              img {
                border-radius: 50%;
                width: 50px;
                height: 50px;
              }
            }
            .list_right {
              span:nth-of-type(1) {
                font-size: 15px;
                font-weight: bold;
              }
              span:nth-of-type(2) {
                font-size: 15px;
                color: #ccc;
              }
              p {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
</style>