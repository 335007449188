<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth1">
      <div class="public-width">
        <div class="mainBox1 pb50">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/luntan.png" alt="">
          </div>
          <p class="mt40 mb40">以县、市两级为单位组织开展主题式四名沙龙活动，服务一线，形式灵活多样</p>
          <div class="txtBox">
            <div>
              <div>
                <img src="@/assets/banner_img/aservice1/icon1.png" alt="">
              </div>
              <div>
                <div class="secor1">活动目的</div>
                <div>发展高质量教育，培养高素质人才</div>
              </div>
            </div>
            <div>
              <div>
                <img src="@/assets/banner_img/aservice1/icon2.png" alt="">
              </div>
              <div>
                <div class="secor2">活动主题</div>
                <div>教学改革、课程改革、教育治理、学生发展、教师发展等</div>
              </div>
            </div>
            <div>
              <div>
                <img src="@/assets/banner_img/aservice1/icon3.png" alt="">
              </div>
              <div>
                <div class="secor2">活动形式</div>
                <div>主题分享、互动交流、专家微讲座等</div>
              </div>
            </div>
            <div>
              <div>
                <img src="@/assets/banner_img/aservice1/icon4.png" alt="">
              </div>
              <div>
                <div class="secor1">活动内容</div>
                <div>经验分享、成果展示、学术研讨、热点探讨等</div>
              </div>
            </div>
            <div>
              <div>
                <img src="@/assets/banner_img/aservice1/icon5.png" alt="">
              </div>
              <div>
                <div class="secor1">宣传方式</div>
                <div>一个渠道投稿，八个渠道传播。通过媒体报道、录播直播、视频回放等形式，带动区域教师参与研讨，共享成果</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth2">
      <div class="public-width">
        <div class="mainBox2 pt30 pb10">
          <div class="titleBox mb30">
            <h4>预期成果:</h4>
          </div>
          <div class="chengguoBox">
            <div>
              <h1>平台</h1>
              <p>构建一个区域“四名”网络工作室平台</p>
              <p>构建N个“四名”网络工作室空间</p>
            </div>
            <div>
              <h1>队伍</h1>
              <p>助力建设一支中小学名教师、名校（园）长、名班主任队伍</p>
            </div>
            <div>
              <h1>资源</h1>
              <p>形成一批优质的教研活动资源</p>
            </div>
            <div>
              <h1>案例</h1>
              <p>输出一本沙龙活动案例集</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth1">
      <div class="public-width">
        <div class="mainBox3 pt30 pb30">
          <div class="titleBox mb30">
            <h4>活动形式:</h4>
          </div>
          <div class="huodongBox">
            <div>
            </div>
            <div>
              <h1 class="mt30">高峰论坛活动</h1>
              <p class="mt40 mb10">搭建“省级-国家级-国际”三级创新交流平台，逐级扩大交流圈，使四名更有获得感和成就感。</p>
              <p>鼓励四名创新教学实践，创造更优更好的教改成果，并通过三级平台宣传个人或团队成果，扩大个人影响力。</p>
            </div>
            <div>
              <h1>四名伴我行</h1>
              <div class="mt30 mb30">四名公益活动</div>
              <p><span>四名大讲堂： </span>聚焦教育教学及教师发展的热点问题，以分享教育教学思想</p>
              <p><span>四名公开课： </span>为四名搭建公开课展示平台，促进四名名课示范效应最大化</p>
              <p><span>送教下乡： </span>为四名提供送教下乡资源，帮助乡村教师解决教学教改的问题</p>
              <p><span>教育帮扶： </span>促成四名与乡村学校的教育帮扶结对，为乡村教育发展添砖加瓦</p>
            </div>
            <div>
            </div>
            <div>
            </div>
            <div>
              <h1 class="mt20">竞赛活动</h1>
              <p class="chu600 mt30 mb10">参与形式：</p>
              <p>参与比赛活动、担任比赛评委</p>
              <p class="chu600 mt30 mb10">活动主题：</p>
              <p>以县、市两级为单位组织命题比赛、教学技能比赛、课程资源开发竞赛等</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}

.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.secor1 {
  color: #145bb9;
}

.secor2 {
  color: #ea1e70;
}

.mainBox1 {
  margin-top: 20px;

  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .txtBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >div {
      display: flex;
      width: 49%;
      height: 80px;
      margin-bottom: 20px;
      box-sizing: border-box;

      &:last-child {
        width: 100%;
        margin-bottom: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        >div {
          &:first-child {
            background: #ea1e70;
          }
        }
      }

      >div {
        &:first-child {
          width: 80px;
          background: #145bb9;
          color: #fff;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:last-child {
          background: #fff;
          padding: 12px;
          width: calc(100% - 110px);

          >div {
            &:first-child {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .btnBox {
    display: flex;

    >div {
      flex: 1;
      width: calc(100% / 3 - 2px);
      height: 127px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      p {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 36px;
      }

      img {
        margin-top: -40px;
        width: 34px;
        height: 10px;
      }

      &:nth-child(1n) {
        background: #145bb9;
      }

      &:nth-child(2n) {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
      }

      &:hover {
        background: #ea1e70;
      }
    }
  }
}

.mainBox2 {
  .titleBox {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }

  .chengguoBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >div {
      width: 624px;
      height: 200px;
      background-size: 100% 100%;
      margin-bottom: 30px;
      padding: 30px 58px;
      box-sizing: border-box;

      &:nth-child(1) {
        background: url('~@/assets/banner_img/aservice1/bg1.png') no-repeat;
      }

      &:nth-child(2) {
        background: url('~@/assets/banner_img/aservice1/bg2.png') no-repeat;
      }

      &:nth-child(3) {
        background: url('~@/assets/banner_img/aservice1/bg3.png') no-repeat;
      }

      &:nth-child(4) {
        background: url('~@/assets/banner_img/aservice1/bg4.png') no-repeat;
      }

      h1 {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 254, 254, 1);
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 10px;
      }
    }
  }
}

.mainBox3 {
  .titleBox {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }

  .huodongBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    >div {
      width: 640px;
      height: 400px;
      box-sizing: border-box;
      padding: 50px;

      &:nth-child(1) {
        background: url('~@/assets/banner_img/aservice1/img1.png') no-repeat;
      }

      &:nth-child(2) {
        background: #145bb9;

        h1 {
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
          text-indent: 2em;
        }
      }

      &:nth-child(3) {
        background: #ea1e70;

        h1 {
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }

        div {
          width: 142px;
          height: 32px;
          background: rgba(255, 255, 255, 1);
          border-radius: 16px;
          text-align: center;
          line-height: 32px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(234, 30, 112, 1);
        }

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 10px;

          span {
            font-weight: bold;
          }
        }
      }

      &:nth-child(4) {
        background: url('~@/assets/banner_img/aservice1/img2.png') no-repeat;
      }

      &:nth-child(5) {
        background: url('~@/assets/banner_img/aservice1/img3.png') no-repeat;
      }

      &:nth-child(6) {
        background: #145bb9;

        h1 {
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>