<template>
  <div class="homePage_div" ref="main_cont">
    <!-- 公用头部 -->
    <PublicHeader v-if="is_hide != 1 && routeName.indexOf('serviceEight') == -1" :logo1_url="web_all.logo1_url">
    </PublicHeader>
    <div class="serveStyHea" v-if="routeName.indexOf('serviceEight') != -1">
      <div class="topheader">
        <div class="sanHeader">
          <div class="imgBox">
            <img src="@/assets/banner_img/aserviceImg/sanLogo.png" alt="" />
          </div>
          <div class="txtBox">
            <p>为四名服务，向四名学习，与四名共成长</p>
          </div>
        </div>
      </div>
      <div class="navBox">
        <ul class="ulBox">
          <li :class="item.url == routeActive ? 'active' : ''" v-for="(item, index) in navList" :key="index"
            @click="gourl(item.url)">
            {{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div v-if="routeName.indexOf('serviceEight') != -1" style="height: 132px;"></div>
    <div v-else style="height: 64px;"></div>
    <!-- 正文 -->
    <div class="main_body_div">
      <router-view :key="$route.query.t"></router-view>
    </div>

    <!-- 底部 -->
    <div class="footer_div" v-if="is_hide != 1">
      <div class="footer-box-main">
        <div class="box-left">
          <div class="footer-logo" @click="toNavEvent">
            <img :src="web_all.logo2_url" />
          </div>
          <i class="footer-line"></i>
          <div class="footer-info">
            <!-- <h2>广东德诚科教有限公司</h2> -->
            <h2>{{ web_all.name }}</h2>
            <p v-if="web_all.address">
              <i class="footer-icon footer-icon-address"></i>
              <!-- 广东省肇庆市端州区古塔中路16号 -->
              {{ web_all.address }}
            </p>
            <p class="footer-tel" v-if="web_all.phone">
              <i class="footer-icon footer-icon-tel"></i>
              <!-- 0758-2762626 -->
              {{ web_all.phone }}
            </p>
          </div>
        </div>
        <div class="box-right">
          <div class="footer-link">
            <!-- <p class="link-a">关于我们</p>
            <i class="footer-text-line">|</i>
            <p class="link-a">网站声明</p> -->
          </div>
          <p>
            {{ web_all.name }} 版权所有
            <i class="footer-text-line" v-if="web_all.icp">|</i>
            {{ web_all.icp }}
          </p>
          <p>Copyright @ 2023 {{ web_all.web_host }}</p>
        </div>
      </div>
    </div>

    <!-- <DialogLogin></DialogLogin> -->
  </div>
</template>

<script>
import DialogLogin from "@/components/dialogLogin/dialogLogin.vue";
import PublicHeader from "@/views/PublicHeader/PublicHeader.vue";
import elementResizeDetectorMaker from "element-resize-detector"
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",
  components: {
    DialogLogin,
    PublicHeader,
  },
  data() {
    return {
      is_hide: "",
      hometrue: true,
      //定义导航数组
      navArry: [],
      userId: 0, //用户id
      isLogin: false, //是否登录
      //控制信息显示
      isInfo: false,
      //信息参数
      message: {
        total: 0,
        limit: 20,
        page: 1,
      },
      //   routerActive: "", //当前路由地址
      // 网站所有信息
      web_all: {},
      beianStr: "粤ICP备09132439号-5",
      erd: null,
      routeName: "",
      routeActive: "serviceEight",
      navList: [
        { name: "首页", url: 'serviceEight' },
        { name: "论坛活动", url: 'serveTab1' },
        { name: "书籍出版", url: 'serveTab2' },
        { name: "课题服务", url: 'serveTab3' },
        { name: "培训服务", url: 'serveTab4' },
        { name: "评估服务", url: 'serveTab5' },
        { name: "平台服务", url: 'serveTab6' },
        { name: "基地服务", url: 'serveTab7' },
        { name: "媒体宣传", url: 'serveTab8' },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.routeActive = to.name
    }
  },
  created() {
    this.routeActive = this.$route.name
    this.routeName = this.$route.path
    this.is_hide = sessionStorage.getItem("is_hide");
    //初始化权限
    this.getPower();
  },
  mounted() {
    this.erd = elementResizeDetectorMaker();
    this.$nextTick(() => {
      this.erd.listenTo(this.$refs.main_cont, element => {
        if (this.is_hide == 1) {
          if (this.$route.name == "basicinfo" || this.$route.name == "CertificateDetail" || this.$route.name == "TopicDetail") {
            window.parent.postMessage(
              {
                type: "hashH",
                data: 985,
              },
              "*"
            );
          } else {
            window.parent.postMessage(
              {
                type: "hashH",
                data: element.clientHeight,
              },
              "*"
            );
          }
        }
      });
    })
  },
  methods: {
    gourl(url) {
      this.$router.push({
        name: url
      })
    },
    // 导航切换
    toNavEvent() {
      this.$router.push({
        path: "/",
      });
    },
    //获取网站所有信息
    async getPower() {
      let res = await this.$axios.get("webConfig/getWebConfig");
      this.web_all = res.data.data;
      if (this.web_all.web_host.indexOf("xbyx") != -1) {
        this.beianStr = "粤ICP备09132439号-19";
      }
      if (localStorage.getItem("hometrue") != 1) {
        this.hometrue = true;
        this.navArry = res.data.data.auth.slice(0, 5);
        this.navArry.push(res.data.data.auth[6]);
      } else {
        this.hometrue = false;
        let arrs = [
          {
            identity: "https://" + this.web_all.web_host + "/home/#/index",
            is_permit: 1,
            name: "门户首页",
            children: [],
          },
          {
            identity: "https://" + this.web_all.web_host + "/home/#/TeachShow",
            is_permit: 1,
            name: "教研展示",
            children: [],
          },
          {
            identity: "https://" + this.web_all.web_host + "/home/#/StudioList",
            is_permit: 1,
            name: "四名网络工作室",
            children: [],
          },
          {
            identity: "https://" + this.web_all.web_host + "/home/#/groupindex",
            is_permit: 1,
            name: "教研组",
            children: [],
          },
        ];
        this.navArry = arrs;
      }

      let web_name = this.web_all.web_name;
      //判断浏览器地址www.teachercamp.net
      if (window.location.host.indexOf("www.teachercamp.net") != -1) {
        web_name = "广东教师研修网";
      }
      document.title = web_name;
      this.changeFavicon(this.web_all.icon_url);
    },
    // 动态修改页面图标
    changeFavicon(link) {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    },
    ...mapActions(["getUserInfo"]),
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>

<style lang="less">
.homePage_div {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }

  .el-container {
    .el-header {
      padding: 0;
    }

    .el-footer {
      padding: 0;
    }
  }

  .main_body_div {
    width: 100%;
    flex: 1;
    // background-color: #eef2fb;
    box-sizing: border-box;
  }

  .footer_div {
    background: #2f3234;
    min-width: 1400px;
    height: 180px !important;
    flex: 0 0 180px;
    
    .footer-box-main {
      width: 1400px;
      margin: auto;
      padding: 24px 20px 32px 20px;
      box-sizing: border-box;
      display: flex;

      .box-left {
        display: flex;
        align-items: center;

        .footer-logo {
          width: 160px;
          height: 104px;
          border: 1px solid #2f3234;
          cursor: pointer;

          img {
            width: 160px;
            height: 104px;
            border: 1px solid #2f3234;
          }
        }

        .footer-line {
          width: 1px;
          height: 110px;
          background: #3a3c3e;
          display: block;
          margin: 0 72px 0 55px;
        }

        .footer-info {
          h2 {
            font-size: 20px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 24px;
          }

          p {
            font-size: 14px;
            color: #999999;
            display: flex;
            align-items: center;
            margin-bottom: 6px;
          }

          .footer-tel {
            color: #ff8201;
          }

          .footer-icon {
            width: 12px;
            height: 12px;
            display: block;
            margin-right: 4px;
          }

          .footer-icon-address {
            background: url("../assets/images/dizhi.png") no-repeat center;
          }

          .footer-icon-tel {
            background: url("../assets/images/dianhua.png") no-repeat center;
          }
        }
      }

      .box-right {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .footer-link {
          font-size: 14px;
          color: #fff;
          margin-bottom: 30px;
          display: flex;

          .link-a {
            color: #fff;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .footer-text-line {
            margin: 0 30px;
          }
        }

        p {
          font-size: 14px;
          color: #999;
          margin-bottom: 6px;
        }
      }
    }

    .footer-text-line {
      font-style: normal;
      margin: 0 6px;
    }
  }
}

body {
  /deep/.el-menu--horizontal {
    .el-menu {
      padding: 0;
      margin: 0;

      .el-menu-item {
        width: 124px;
        height: 69px;
        font-size: 16px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #ebebeb;

        &:last-of-type {
          border: 0;
        }

        &:hover {
          color: #3489fe !important;
        }
      }

      .is-active {
        font-size: 16px;
        font-weight: bold;
        color: #3489fe !important;
      }
    }
  }
}

.top_logo_word {
  display: flex;
  justify-content: flex-end;
  color: #4d4d4d;
}

.loBtn {
  background: linear-gradient(-24deg,
      rgba(30, 111, 255, 1) 0%,
      rgba(30, 162, 255, 1) 100%);
  border-radius: 8px;
  border: none;
  color: #fff;
  padding: 6px 10px;
}

.serveStyHea {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: #ffffff;
}

.topheader {
  width: 100%;

  .sanHeader {
    width: 1280px;
    background-color: #fff;
    height: 64px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 0 auto;

    .imgBox {
      width: 168px;
      height: 48px;

      img {
        width: 168px;
        height: 48px;
      }
    }

    .txtBox {
      height: 40px;
      margin-left: 10px;

      p {
        line-height: 40px;
        color: #333;
      }
    }
  }
}

.navBox {
  width: 100%;
  height: 68px;
  background: rgba(255, 255, 255, 1);
  background: #145BB9;

  .ulBox {
    width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    margin: 0 auto;

    li {
      font-size: 16px;
      color: #ffffff;
      padding: 8px 15px;
      margin-right: 20px;
      border-radius: 20px;
      font-family: Microsoft YaHei;
      cursor: pointer;

      &.active {
        background: #fff;
        color: #145BB9;
        font-weight: bold;
      }

      &:hover {
        background: #fff;
        color: #145BB9;
        font-weight: bold;
      }
    }
  }
}
</style>
