<template>
  <div class="activityDetail">
    <!-- 正文 -->
    <div class="items" style="background-color:#F7F7F7">
      <el-container style="background-color:#F7F7F7; width:80%;margin:5px auto">
        <!-- 左侧导航 -->
        <el-aside>
          <div>

          </div>
          <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
            <el-menu-item index="1" style="">
              <h4 style="line-height:35px;">活动介绍</h4>
              <div style="line-height:30px">
                <span style="margin-left:10px;margin-right:170px;">基本信息</span>
                <img src="../../assets/images/personal/yjt.png" alt="">
              </div>
            </el-menu-item>

            <el-menu-item index="2" style="">
              <h4 style="line-height:35px;">活动环节</h4>
              <div style="line-height:30px">
                <img src="../../assets/images/personal/gz.png" alt="">
                <span style="margin-left:10px;margin-right:120px;">资源浏览环节</span>
                <img src="../../assets/images/personal/yjt.png" alt="">
              </div>
              <div
                style="background-color:#CECECE ;color:#FFFFFF;margin-left:25px;line-height:18px;width:51px;height:18px;border:1px solid #CECECE;border-radius:9px">
                <span style="margin-left:3px;">已结束</span>
              </div>
            </el-menu-item>

            <el-menu-item index="3" style="">
              <div style="line-height:70px">
                <img src="../../assets/images/personal/gz.png" alt="">
                <span style="margin-left:10px;margin-right:120px;">资源浏览环节</span>
                <img src="../../assets/images/personal/yjt.png" alt="">
              </div>
              <div
                style="background-color:#FF8201;color:#FFFFFF;;margin-left:25px;line-height:18px;width:51px;height:18px;border:1px solid #CECECE;border-radius:9px">
                <span style="margin-left:3px;">进行中</span>
              </div>
            </el-menu-item>

            <el-menu-item index="4" style="">
              <div style="line-height:70px">
                <img src="../../assets/images/personal/gz.png" alt="">
                <span style="margin-left:10px;margin-right:120px;">资源浏览环节</span>
                <img src="../../assets/images/personal/yjt.png" alt="">
              </div>
              <div
                style="background-color: #3489FE;color:#FFFFFF;margin-left:25px;line-height:18px;width:51px;height:18px;border:1px solid #CECECE;border-radius:9px">
                <span style="margin-left:3px;background-color: ;">未开始</span>
              </div>
            </el-menu-item>

            <el-menu-item index="5" style="">
              <div style="line-height:70px">
                <img src="../../assets/images/personal/gz.png" alt="">
                <span style="margin-left:10px;margin-right:120px;">资源浏览环节</span>
                <img src="../../assets/images/personal/yjt.png" alt="">
              </div>
              <div
                style="background-color: #3489FE;color:#FFFFFF;margin-left:25px;line-height:18px;width:51px;height:18px;border:1px solid #CECECE;border-radius:9px">
                <span style="margin-left:3px;">未开始</span>
              </div>
            </el-menu-item>

          </el-menu>

        </el-aside>
        <!-- 右边部分 -->
        <el-container>
          <!-- 右边头部 -->
          <el-header style="height: 274px;">

            <div class="h_left">
              <img src="" alt="">
            </div>
            <div class="h_right">
              <h4>标题精通全集</h4>
              <p>活动时间：2019-5-1 13:00至</p>
              <p>
                <span>活动类型：主题研讨</span>
                <span>开展方式：线上</span>
                <span>学段学科：小学语文</span>
              </p>
              <p>
                <span>活动发起人：静儿</span>
                <span>参与范围：指定成员</span>
                <span>参与人数：1025人</span>
              </p>
              <el-button type="primary">邀请</el-button>
            </div>

          </el-header>
          <!-- 右边主体内容 -->
          <el-main>
            <div class="m_title">
              <h4>活动描述</h4>
            </div>
            <div class="m_content">
              <p>
                <span>活动主题：</span>
                <span>微课题研究</span>
              </p>
              <p>
                <span>活动目标：</span>
                <br>
                <span>自主解决教育</span>
              </p>
              <p>
                <span>活动方式：</span>
                <br>
                <span>讨论会</span>
              </p>
              <p>
                <span>活动环节：</span>
                <br>
                <span>1.依据科学实践中的问题</span><br>
                <span>2.依据科学实践中的问题</span><br>
                <span>3.依据科学实践中的问题</span><br>
                <span>4.依据科学实践中的问题</span><br>
                <span>5.依据科学实践中的问题</span>
              </p>
              <p>
                <span>活动时间：</span>
                <span>*****年*月*日——***年*月*日</span>
              </p>
              <p>
                <span>活动组织：</span><br>
                <span>主持人：xxxx</span>
                <span>成员：xxxx</span>
              </p>
              <p>
                <span>预期成果：</span>
                <br>
                <span>实践中的问题通过一个个微研究得到解决</span><br>
                <span>在解决问题的过程中教师的行动改进能力得到提升</span>
              </p>
              <p>
                <span>温馨提示：</span>
                <span>教育教学实践中的问题很多，筛选出可以研究的微型问题是关键</span><br>
                <span>教育教学实践中的问题很多，筛选出可以研究的微型问题是关键</span><br>
                <span>教育教学实践中的问题很多，筛选出可以研究的微型问题是关键</span><br>
                <span>教育教学实践中的问题很多，筛选出可以研究的微型问题是关键</span><br>
                <span>教育教学实践中的问题很多，筛选出可以研究的微型问题是关键</span><br>
              </p>
            </div>

          </el-main>

        </el-container>
      </el-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 活动下拉框
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }],
      //表格数组
      tableData: [{
        index: 1,
        date: '2016-05-03',
        name: '王小虎',
        ways: '上海',

      }],
      // 活动下一步变量
      active: 1,
      // form表单数据
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入教研组名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],

        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写教研组简介', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    // 表格查看
    handleClick(row) {
      console.log(row);
    },
    // 保存下一步方法
    next() {
      if (this.active++ > 2) this.active = 0;
    }
  }
}
</script>

<style lang="less"  >
.items {
  padding-top: 20px;

  .el-aside {
    width: 335px;
    height: 646px;
    margin-right: 20px;
    background-color: #ffffff;
    .el-menu {
      border: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .el-menu-item {
        width: 90%;
        height: 100px;
        line-height: 100px;
        border-bottom: 1px solid #ececec;
      }
    }
  }
  // 右边头部
  .el-header {
    width: 1045px;
    margin-bottom: 20px;
    background-color: #ffffff;
    display: flex;

    align-items: center;
    .h_left {
      width: 380px;
      height: 214px;
      border: 1px solid aqua;
      margin-left: 30px;
      img {
        width: 100%;
      }
    }
    .h_right {
      margin-left: 50px;
      h4 {
        margin-bottom: 18px;
      }
      p:nth-of-type(1) {
        color: #ff7e24;
        font-size: 16px;
      }
      p {
        margin-bottom: 18px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .el-main {
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 1045px;
    .m_title {
      width: 100%;
      height: 88px;
      line-height: 88px;
      border-bottom: 1px solid #ececec;
      h4 {
        margin-left: 30px;
      }
    }
    .m_content {
      padding: 30px 30px;
      p {
        margin-bottom: 10px;
        span:nth-of-type(1) {
          font-size: 14px;
          font-weight: bold;
        }
        span {
          font-size: 15px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
.activityDetail {
  .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }
  .el-container {
    .el-header {
      padding: 0;
    }
    .el-footer {
      padding: 0;
    }
  }

  .header_content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    border: NaNpx solid #f0f0f0;
    box-shadow: 0px 4px 7px 0px #f5f5f5;

    .header_main_content {
      width: 1600px;
      // border: 1px solid black;
      font-weight: 400;
      color: #333333;
      display: flex;
      justify-content: space-between;
      .left_one {
        height: 100%;
        display: flex;
        align-items: center;
        img {
          width: 315px;
          height: 60px;
          margin-right: 170px;
        }
        .left_word {
          font-weight: 400;
          font-size: 16px;
          /deep/ .el-menu-item {
            padding: 0 31px;
          }
        }
      }
    }
    .right_content {
      display: inline-flex;
      align-items: center;

      img {
        width: 34px;
        height: 34px;
      }
      p {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .foot_content {
    height: 100%;
    width: 100%;
    .foot_one {
      height: 340px;
      background-color: #ececec;
      display: flex;
      justify-content: center;
      .one_content {
        // border: 1px solid black;
        padding-top: 92px;
        display: flex;
        justify-content: center;
        .pic {
          margin-right: 90px;
          img {
            width: 145px;
            height: 152px;
          }
        }
        .erwei {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 100px;
          img {
            width: 85px;
            height: 85px;
            margin-bottom: 14px;
          }
          .one_p {
            font-size: 12px;
            color: #999999;
            margin-bottom: 17px;
          }
          .two_p {
            font-size: 18px;
            font-weight: 700;
            color: #ff7e24;
          }
        }
        .shuxian {
          width: 1px;
          height: 144px;
          border-left: 1px dashed #ffffff;
          margin-right: 101px;
        }
        p {
          font-weight: 700;
          margin-bottom: 38px;
        }
        li {
          font-size: 14px;
          color: #999999;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .word1 {
          margin-right: 144px;
        }
        .word2 {
          margin-right: 91px;
        }
        .word3 {
          li {
            color: #ff7e24;
          }
        }
      }
    }
    .foot_two {
      height: 81px;
      background-color: #3489fe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
.sub_one {
  width: 115px;

  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: 115px;
  }
}
</style>