<template>
  <div class="groupdetailshouye">
    <div class="content_one">
      <div class="images">
        <img :src="groupInfoData.photo_url" />
        <img class="oImg" src="@/assets/jiaoyanzu_touxiang.png" alt="" />
      </div>
      <div class="word_div">
        <div class="word_one">
          <p>{{ groupInfoData.username }}</p>
          <el-popover v-if="groupInfoData.user_id != userId" width="230" trigger="click">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入..."
              v-model="message.content"
            >
            </el-input>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="send()">发送</el-button>
            </div>
            <button slot="reference" class="btnsty cp">私信TA</button>
          </el-popover>
        </div>
        <div class="word_two">
          <p>{{ groupInfoData.org_name }}</p>
        </div>
      </div>

      <div class="other_div">
        <el-dropdown v-if="groupInfoInfo.is_show == 1" trigger="click">
          <div class="daishenhe_div">
            <div class="text_1">{{ toAuditData.count || 0 }}</div>
            <div class="text_2">待审核</div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="toPageEvent(1)"
              >成员待审核({{ toAuditData.user_count || 0 }})</el-dropdown-item
            >
            <el-dropdown-item @click.native="toPageEvent(2)"
              >活动待审核({{ toAuditData.activity_count || 0 }})</el-dropdown-item
            >
            <el-dropdown-item @click.native="toPageEvent(3)"
              >直播待审核({{ toAuditData.live_count || 0 }})</el-dropdown-item
            >
            <el-dropdown-item @click.native="toPageEvent(4)"
              >课程待审核({{ toAuditData.course_count || 0 }})</el-dropdown-item
            >
            <el-dropdown-item v-if="false" @click.native="toPageEvent(5)"
              >话题待审核({{ toAuditData.gambit_count || 0 }})</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <div class="other_one" @click="detailsLink(1)">
          <p>{{ groupInfoData.user_count }}</p>
          <p>成员</p>
        </div>
        <div class="other_one" @click="detailsLink(2)">
          <p>{{ groupInfoData.live_count }}</p>
          <p>教研直播</p>
        </div>
        <div class="other_one" @click="detailsLink(3)">
          <p>{{ groupInfoData.activity_count }}</p>
          <p>教研活动</p>
        </div>
        <div class="other_one" @click="detailsLink(4)">
          <p>{{ groupInfoData.course_count }}</p>
          <p>教学视频</p>
        </div>
        <div class="other_one" @click="detailsLink(5)">
          <p>{{ groupInfoData.result_count }}</p>
          <p>研修成果</p>
        </div>
        <div v-if="false" class="other_one" @click="detailsLink(6)">
          <p>{{ groupInfoData.gambit_count }}</p>
          <p>话题研讨</p>
        </div>
      </div>
    </div>

    <div class="content_two mb20">
      <div class="new_content_two_head">
        <div class="content_two_title">直播预告</div>
        <span class="content_two_more" @click="detailsLink(2)">更多>></span>
      </div>
      <div class="new_content_two">
        <CommonEmpty v-if="!liveList.length" hVal="100%"></CommonEmpty>
        <template v-for="(item, index) in liveList">
          <PublicTtemDiv
            :key="index"
            :currentMargin="false"
            :activeBor="true"
            :handleBtn="true"
            :navActive="2"
            :isGroupModule="1"
            :currentIndex="index"
            :item="item"
            @editClickLive="editClickLive"
            @delClick_3="delClick_3"
            @live_RefreshPage="getLiveList()"
            @toPageEvent_live="toDetailPage"
          >
          </PublicTtemDiv>
        </template>
      </div>
    </div>

    <div class="content_two mb20">
      <div class="new_content_two_head">
        <div class="content_two_title">活动进行时</div>
        <span class="content_two_more" @click="detailsLink(3)">更多>></span>
      </div>
      <div class="new_content_two">
        <CommonEmpty v-if="!ArryList.length" hVal="100%"></CommonEmpty>
        <template v-for="(item, index) in ArryList">
          <PublicTtemDiv
            :key="index"
            :currentMargin="false"
            :activeBor="true"
            :handleBtn="true"
            :navActive="1"
            :currentIndex="index"
            :item="item"
            @researchActivityEditClick="researchActivityEditClick"
            @delClick_2="researchActivityDelClick"
            @activity_RefreshPage="getList()"
            @toPageEvent_5="activityDetails"
          >
          </PublicTtemDiv>
        </template>
      </div>
    </div>

    <div class="content_two mb20">
      <div class="new_content_two_head">
        <div class="content_two_title">最新研修成果</div>
        <span class="content_two_more" @click="detailsLink(5)">更多>></span>
      </div>
      <CommonEmpty v-if="!getData.length" hVal="100%"></CommonEmpty>
      <el-carousel height="200px" direction="vertical">
        <el-carousel-item v-for="(item, index) in getData" :key="index">
          <div class="main_block">
            <div class="images">
              <div>
                <!-- <img :src="item.mime | fileTypeSmall " alt=""> -->
                <img :src="onShowImages(item.mime)" alt="" />
              </div>
              <span>{{ item.sources_name || "-" }}</span>
            </div>
            <div class="block_word">
              <new-viewonline
                :viewSrc="item.view_url"
                :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
                :resourceId="item.r_resource_id || item.resource_id"
                :fileExt="item.mime || item.ext"
                :itemData="item"
                :fileTitle="item.title"
                :fileSize="item.size"
                :fileTime="item.create_time"
                fileNav="教研组 > 教研组列表 > 教研组详情 > 查看"
              >
                <p style="cursor: pointer">{{ item.title }}</p>
              </new-viewonline>
              <p>
                {{ item.pharse_name || "-" }}
                {{ item.subject_name || "-" }} /
                {{ item.grade_name || "-" }}
              </p>
              <div v-if="false" class="block_gion">
                <div class="block_gion_one">{{ item.area }}</div>
                <div class="block_gion_one">{{ item.year }}</div>
              </div>
              <div class="block_time">
                <span>上传时间：{{ item.create_time || "-" }}</span>
                <span>收藏次数：{{ item.collect_count || 0 }}</span>
                <span>文件大小：{{ item.size | fileSizeFilter }}</span>
              </div>
            </div>
            <div class="rightbOx" v-if="item.user_id == userId">
              <el-checkbox
                @change="changeRecommend(item.id, item)"
                v-model="item.is_recommend"
                >置顶首页显示</el-checkbox
              >
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="content_two">
      <div class="content_two_title">研修动态</div>
      <ul class="dynamic-list-box">
        <CommonEmpty v-if="!dynamicList.length" hVal="100%"></CommonEmpty>
        <li class="dynamic-list" v-for="(item, index) in dynamicList" :key="index">
          <div class="dynamic-portrait">
            <img :src="item.photo_url" />
          </div>
          <div class="dynamic-info">
            <div class="info-top">
              <div class="info-user">
                <span class="info-name">
                  {{ item.username }}
                </span>
                <span class="info-org">
                  {{ item.org_name }}
                </span>
              </div>
              <div class="info-time">
                {{ item.create_time }}
              </div>
            </div>
            <p class="info-bottom">
              {{ item.type_text }}
              <span class="info-title" @click="goUrl(item.go_url)"
                >#{{ item.title }}#</span
              >
            </p>
          </div>
        </li>
      </ul>
      <div class="pagin" v-if="this.dynamic.page < last_page">
        <div @click="currentChange">加载更多>></div>
      </div>
    </div>
  </div>
</template>

<script>
import { onShowImages } from "@/utils/utils";
import * as researchApi from "@/api/researchApi.js";
export default {
  data() {
    return {
      groupId: 0, //教研组id
      userId: 0, //用户id
      dynamicList: [], //研修动态列表
      //研修动态参数
      dynamic: {
        limit: 10,
        page: 1,
        total: 0,
        group_id: "",
      },
      last_page: 0,
      //私信参数
      message: {
        receive_user_id: "",
        content: "",
      },
      groupInfoInfo: {},
      toAuditData: {},
      liveList: [], //直播列表
      live: {
        //教研直播参数
        limit: 3,
        page: 1,
        total: 0,
        current_group_id: "",
        search_status: "",
      },
      ArryList: [],
      ListData: {
        total: 0,
        limit: 3,
        page: 1,
        search_status: 0,
      },
      getData: [],
      form: {
        limit: 10,
        page: 1,
        group_id: "",
        type: 0,
        source_id: "",
      },
    };
  },
  created() {
    this.groupId = this.$route.query.groupId ? this.$route.query.groupId : 0;
    this.userId = localStorage.getItem("id") ? localStorage.getItem("id") : 0;
    this.dynamic.group_id = this.groupId;
    this.live.current_group_id = this.groupId;
    this.form.group_id = this.groupId;
    this.getDynamic();
    this.getGroupInfo();
    this.toAudit_Fn();
    this.getLiveList();
    this.getList();
    this.getAchievementList();
  },
  methods: {
    onShowImages,
    // 待审核数据
    toAudit_Fn() {
      let params = {
        group_id: this.groupId,
        id: this.userId,
      };
      researchApi
        .get_activity_TeachingStTeacherGroup_audit_status_count(params)
        .then((res) => {
          this.toAuditData = res.data.data;
        });
    },
    //获取教研组信息
    async getGroupInfo() {
      const res = await this.$axios.get(`/group/${this.groupId}`);
      this.groupInfoInfo = res.data.data;
    },
    // 跳转审核
    toPageEvent(num) {
      switch (Number(num)) {
        case 1:
          this.$router.push({
            path: "/groupdetailmember",
            query: {
              groupId: this.$route.query.groupId,
              pageType: 2,
              navCurrentType: "member",
            },
          });
          break;
        case 2:
          this.$router.push({
            path: "/GroupTearmAudit",
            query: {
              groupId: this.$route.query.groupId,
              type: "2",
              navCurrentType: "activity",
            },
          });
          break;
        case 3:
          this.$router.push({
            path: "/GroupTearmAudit",
            query: {
              groupId: this.$route.query.groupId,
              type: "1",
              navCurrentType: "live",
            },
          });
          break;
        case 4:
          this.$router.push({
            path: "/GroupTearmAudit",
            query: {
              groupId: this.$route.query.groupId,
              type: "3",
              navCurrentType: "course",
            },
          });
          break;
        case 5:
          this.$router.push({
            path: "/GroupTearmAudit",
            query: {
              groupId: this.$route.query.groupId,
              type: "4",
              navCurrentType: "talk",
            },
          });
          break;
        default:
          break;
      }
      this.$emit("changeNavCurrent", true);
    },
    //获取研修动态
    async getDynamic() {
      const res = await this.$axios.get("/TeacherGroup/ListTeacherGroupUserLog", {
        params: this.dynamic,
      });
      this.dynamicList = res.data.data.data;
      this.dynamic.total = res.data.data.total;
      this.last_page = res.data.data.last_page;
    },
    //当前页码改变
    async currentChange() {
      this.dynamic.page = this.dynamic.page + 1;
      const res = await this.$axios.get("/TeacherGroup/ListTeacherGroupUserLog", {
        params: this.dynamic,
      });
      let arrr = res.data.data.data;
      arrr.forEach((item) => {
        this.dynamicList.push(item);
      });
    },
    //请求后台发送私信
    send() {
      if (this.message.content) {
        this.$axios.post("private/sendMessage", this.message).then((res) => {
          this.$message.success("发送成功");
          this.message.content = "";
        });
      } else {
        this.$message({
          message: "请输入私信内容",
          type: "warning",
        });
      }
    },
    goUrl(url) {
      window.open(url);
    },
    detailsLink(type) {
      let path = "";
      if (type == 1) {
        path = `groupdetailmember?groupId=${this.groupId}&navCurrentType=member`;
      }
      if (type == 2) {
        path = `groupDetailLive?groupId=${this.groupId}&navCurrentType=live`;
      }
      if (type == 3) {
        path = `groupDetailActivity?groupId=${this.groupId}&navCurrentType=activity`;
      }
      if (type == 4) {
        path = `groupDetailCourse?groupId=${this.groupId}&navCurrentType=course`;
      }
      if (type == 5) {
        path = `groupDetailResult?groupId=${this.groupId}&navCurrentType=achievement`;
      }
      this.$router.push({ path });
    },
    //获取直播列表
    async getLiveList() {
      const res = await this.$axios.get("live/get_group_list", { params: this.live });
      this.liveList = res.data.data.data;
      this.live.total = res.data.data.total;
    },
    //跳转到直播详情页
    toDetailPage(item) {
      this.$router.push({
        path: "/livedetail",
        query: {
          liveid: item.id,
          groupId: this.$route.query.groupId,
        },
      });
    },
    //删除 直播
    delClick_3(id, item) {
      this.$confirm("此操作将删除该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivity_3(id, item);
        })
        .catch(() => {});
    },
    //删除直播
    async delActivity_3(id, item) {
      let user_id = localStorage.getItem("id");
      let res = await this.$axios.delete(`/live/delLive/${id}/${user_id}`, {
        params: { is_personid: item.is_personid },
      });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.getLiveList();
    },
    //跳转到编辑页
    editClickLive(id) {
      // 从路由原信息得到直播id传过去
      if (sessionStorage.getItem("is_hide") == 1) {
        let hostes = location.origin;
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/newLive?id=${id}`);
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`);
      } else {
        this.$router.push({
          path: "/createGroupNewLive",
          query: {
            id: id,
            groupId: this.$route.query.groupId,
          },
        });
      }
    },
    // 得到教研活动列表
    async getList() {
      let res = await this.$axios.get(`/group/${this.groupId}/Activities`, {
        params: this.ListData,
      });
      this.ArryList = res.data.data.data;
      this.ListData.total = res.data.data.total;
    },
    //教研活动详情
    activityDetails(item) {
      this.$router.push({
        path: "/basicinfo",
        query: { activityId: item.id },
      });
    },
    //删除教研活动按钮
    researchActivityDelClick(id) {
      this.$confirm("此操作将删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivity(id);
        })
        .catch(() => {});
    },
    //删除活动
    async delActivity(id) {
      const res = await this.$axios.delete(`/activity/${id}`);
      if (res.data.code == 900) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList();
      }
    },
    //修改教研活动按钮
    researchActivityEditClick(id) {
      this.$router.push({
        path: "/newActivity",
        query: { groupId: this.groupId, id: id },
      });
    },
    //获取研修成果列表
    async getAchievementList() {
      try {
        let gid = this.$route.query.groupId;
        this.form.group_id = gid;
        let res = await this.$axios.get("/TeacherGroup/get_resource_list", {
          params: {
            limit: this.form.limit,
            page: this.form.page,
            group_id: this.form.group_id,
            type: this.form.type,
            source_id: this.form.source_id,
          },
        });
        this.getData = res.data.data.data;
        this.getData.forEach((item) => {
          if (item.is_recommend == 2) {
            this.$set(item, "is_recommend", true);
          } else {
            this.$set(item, "is_recommend", false);
          }
        });
        this.total = res.data.data.total;
      } catch (error) {}
    },
    async changeRecommend(id, item) {
      try {
        let reqObj = {
          group_id: this.form.group_id,
          resource_id: id,
          id: id,
          type: item.type,
        };
        if (item.is_recommend) {
          reqObj["recommend"] = 2;
        } else {
          reqObj["recommend"] = 1;
        }
        await this.$axios.put("/TeacherGroup/recommend_group_resource", reqObj);
        this.$message.success("操作成功");
        this.getAchievementList();
      } catch (error) {
        this.getAchievementList();
      }
    },
  },
  computed: {
    //监听教研组信息
    groupInfoData() {
      const researchGroupInfo = this.$store.state.researchGroupInfo;
      this.message.receive_user_id = researchGroupInfo.user_id;
      return researchGroupInfo;
    },
  },
  destroyed() {
    this.$store.commit("setResearchGroupInfo", {});
  },
};
</script>

<style scoped lang="less">
.groupdetailshouye {
  //   border: 1px solid pink;
  .content_one {
    width: 100%;
    height: 128px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .images {
      position: relative;
      margin: 0px 19px 0px 30px;
      img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
      }
      .oImg {
        position: absolute;
        width: 55px;
        height: 23px;
        bottom: 3px;
        left: 6px;
      }
    }
    .word_div {
      .word_one {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        p:nth-child(1) {
          font-weight: 700;
          color: #ff8201;
          margin-right: 11px;
        }
        .position {
          width: 42px;
          height: 18px;
          background: #ffffff;
          border: 1px solid #3489fe;
          border-radius: 2px;
          font-size: 12px;
          font-weight: 700;
          color: #3489fe;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
        }
        .btnsty {
          width: 58px;
          height: 20px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(52, 137, 254, 1);
          border-radius: 2px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(52, 137, 254, 1);
        }
      }
      .word_two {
        display: flex;
        font-size: 14px;
        color: #9a9ea5;
        display: flex;
        p:nth-child(1) {
          margin-right: 37px;
        }
      }
    }
    .daishenhe_div {
      width: 84px;
      height: 64px;
      border-radius: 8px;
      margin-right: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      .text_1 {
        font-size: 18px;
        color: #3489ff;
        line-height: 18px;
        font-weight: bold;
      }
      .text_2 {
        font-size: 14px;
        line-height: 14px;
        margin-top: 11px;
      }
      &:hover {
        .text_1,
        .text_2 {
          color: #ff8201;
        }
        background: rgba(255, 130, 1, 0.08);
      }
    }
    .other_div {
      display: flex;
      margin-left: auto;
      margin-right: 10px;
      cursor: pointer;
      .other_one {
        padding: 0 28px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: 1px dashed #ebebeb;
        p:nth-child(1) {
          font-size: 18px;
          line-height: 18px;
          font-weight: 700;
          margin-bottom: 10px;
          color: #3489ff;
        }
        p:nth-child(2) {
          font-size: 14px;
          line-height: 14px;
        }
        &:hover {
          background: rgba(255, 130, 1, 0.08);
          border-radius: 8px;
          p {
            color: #ff8201;
          }
        }
      }
    }
  }
  .content_two {
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    padding: 30px;
    .content_two_title {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #333333;
      margin-bottom: 36px;
    }
    .dynamic-list-box {
      .dynamic-list {
        display: flex;
        margin-bottom: 20px;
        .dynamic-portrait {
          width: 68px;
          height: 68px;
          border-radius: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .dynamic-info {
          flex: 1;
          overflow: hidden;
          margin-left: 18px;
          padding-bottom: 28px;
          border-bottom: 1px solid #ececec;
          .info-top {
            font-size: 14px;
            font-weight: 400;
            color: #9a9ea5;
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            .info-user {
              .info-name {
                font-size: 16px;
                color: #ff8201;
                margin-right: 16px;
              }
            }
          }
          .info-bottom {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: #333333;
            .info-title {
              color: #3489fe;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.pagin {
  > div {
    width: 140px;
    height: 34px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(154, 158, 165, 1);
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: normal;
    color: rgba(153, 153, 153, 1);
    line-height: 34px;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(52, 137, 254, 1);
      color: rgba(52, 137, 254, 1);
    }
  }
}

.new_content_two_head {
  display: flex;
  justify-content: space-between;
  .content_two_more {
    font-size: 16px;
    color: #999;
    cursor: pointer;
  }
}
.new_content_two {
  display: flex;
  flex-wrap: wrap;
  /deep/ .details_div {
    box-sizing: border-box;
    padding-right: 6px !important;
  }
  /deep/ .PublicTtem_Div:nth-child(3n + 3) {
    .list_item {
      margin-right: 0;
    }
  }
}

.main_block {
  width: 100%;
  height: 200px;
  border: 1px solid #ececec;
  border-radius: 6px;
  display: flex;
  margin-bottom: 20px;
  box-sizing: border-box;
  .images {
    width: 160px;
    height: 160px;
    border: 1px solid #ececec;
    border-radius: 6px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: 43px;
      margin-bottom: 20px;
    }
  }
  .block_word {
    p:nth-child(1) {
      margin-top: 34px;
      margin-bottom: 18px;
      font-weight: 700;
      width: 494px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p:nth-child(2) {
      color: #666666;
      margin-bottom: 18px;
    }
    .block_gion {
      display: flex;
      margin-bottom: 14px;
      .block_gion_one {
        width: 69px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ff8201;
        border-radius: 4px;
        color: #ff8201;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
      }
    }
    .block_time {
      color: #999999;
      span {
        margin-right: 30px;
      }
    }
  }
  .rightbOx {
    width: 200px;
    text-align: center;
    padding-top: 25px;
  }
}
</style>
