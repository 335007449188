
<template>
  <div class="manually">
    <!-- // 手动组卷 -->
    <div class="manBox">
      <!-- 面包屑 -->
      <div class="man_nav">
        <p>
          个人中心：我的试卷库><span>手工组卷-选题</span>
        </p>
      </div>
      <!-- 步骤条 -->
      <div class="stepsBox">
        <el-steps :active="active" finish-status="success">
          <el-step title="选题"></el-step>
          <el-step title="组卷"></el-step>
          <el-step title="完善信息"></el-step>
        </el-steps>
      </div>
      <!-- 下边导航 -->
      <div class="ChangeNav">
        <!-- 左边 -->
        <div class="leftBox">
          <div :class="activeClass==index ? 'active' : '' " v-for="(item,index) in list" :key="index" @click="getItem(index)">{{item.name}}</div>
        </div>
        <!-- 右边下拉 -->
        <div class="rightBox">
          <el-dropdown>
            <el-button type="primary">
              请选择<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>当前 小学语文</el-dropdown-item>
              <el-dropdown-item>当前 小学数学</el-dropdown-item>
              <el-dropdown-item>当前 小学英文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //  控制章节样式变量
      activeClass: -1,
      //  步骤条变量
      active: 0,
      //选题
      list: [
        { "typeId": "1", "name": "按章节选题", "number": "50" },
        { "typeId": "2", "name": "按知识点选题", "number": "50" },
        { "typeId": "3", "name": "按试卷选题", "number": "50" }
      ]
    }
  },
  methods: {
    // 步方法
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    //学段点击按钮变色
    getItem(index) {
      this.activeClass = index;
    },
  }
}
</script>

<style lang="less" >
.manually {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  .manBox {
    width: 80%;
    .man_nav {
      //   面包屑
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #f0f0f0;
      p {
        font-size: 14px;
        color: #666666;
        span {
          color: #ff8201;
        }
      }
    }
    .stepsBox {
      width: 50%;
      margin: 0 auto;
      padding-top: 40px;
      margin-bottom: 40px;
    }
    //下边导航
    .ChangeNav {
      width: 100%;
      height: 52px;
      background: #eaf3ff;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      .leftBox {
        width: 30%;
        display: flex;
        height: 52px;
        div {
          margin-left: 20px;
          font-size: 14px;
          line-height: 52px;
          cursor: pointer;
        }
        .active {
          color: #3489fe;
          font-weight: bold;
          border-bottom: 2px solid #3489fe;
        }
      }
      .rightBox {
        margin: auto 0;
        margin-right: 50px;
      }
    }
  }
}
</style>