import Login from "../login/login.vue";
export default {
  components: {
    Login,
  },
  props: {

  },
  data() {
    return {
      dialogLoginVisible: false //登录弹窗是否显示
    };
  },
  created() {
    //判断是否加载时直接显示登录弹窗
    if (this.$route.query.dialogLogin == 1) {
      this.dialogLoginVisible = true
      this.$store.state.loginDisplay = true
    }
  },
  mounted() {

  },
  methods: {
    //登录弹窗关闭回调
    dialogLoginClose() {
      this.$store.state.loginDisplay = false
    }
  },
  watch: {
    //监听登录弹窗状态
    loginDisplay(val) {
      this.dialogLoginVisible = val
    }
  },
  computed: {
    //监听登录弹窗状态
    loginDisplay() {
      return this.$store.state.loginDisplay;
    },
  },
};