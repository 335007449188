<template>
  <!-- 创建话题页面 -->
  <div class="SendTopic public_main_bgc">
    <div class="main_box">

      <div class="bread_top_nav_div">
        <span class="bread_top_nav_text">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>我的话题</el-breadcrumb-item>
          <el-breadcrumb-item>
            <span class="bread_top_nav_item">发起话题</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="form_div">
        <CraeteItemTopic @onCreateData="createData" @onCancel="cancelButton" @onEditTopic="onEditTopic">
        </CraeteItemTopic>
      </div>

    </div>
  </div>
</template>

<script>
import CraeteItemTopic from "@/components/Community/CraeteItemTopic.vue";
export default {
  components: {
    CraeteItemTopic,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    // 修改话题
    onEditTopic() {
      this.$message.success("话题修改成功");
      this.$router.go(-1);
    },
    // 返回
    cancelButton() {
      this.$router.go(-1);
    },
    // 创建话题
    createData(res) {
      this.$message.success("话题创建成功");
      setTimeout(() => {
        this.$router.push("/myTopic");
      }, 1000);
    },
  },
};
</script>

<style lang="less">
.bread_top_nav_div {
  width: 100%;
  margin: 0px;
  padding: 0px;
  line-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;

  .bread_top_nav_text {
    font-size: 14px;
    color: #666;
  }

  .bread_top_nav_item {
    color: #ff8910 !important;
  }
}

.SendTopic {
  width: 100%;
  display: flex;
}

.main_box {
  width: 1400px;
  margin: 0 auto;
}

.form_div {
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
}
</style>