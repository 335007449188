import SubjectList from "../subjectList/subjectList.vue";
import SubjectAnswer from "../subjectAnswer/subjectAnswer.vue";
import SubjectStatistics from "../subjectStatistics/subjectStatistics.vue";
export default {
  components: {
    SubjectList,
    SubjectAnswer,
    SubjectStatistics
  },
  props: {
    //数据
    data: {
      type: Object,
      default: ()=>{},
    },
    //数据
    appName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      state: false, //是否开始状态
      tabs: [`${this.appName}内容`], //Tabs选项
      tabsIndex: 0, //Tabs索引
      isOpenResult: false, //是否公开结果
      isCreator: false, //是否创建者
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.state = this.data.status == 2 ? true : false;
      this.isOpenResult = this.data.open == 1 ? true : false;
      const userId = localStorage.getItem("id");
      this.isCreator = this.data.user_id == userId ? true : false;
      if (this.data.finish_status) {
        this.tabs.push(`${this.appName}结果`);
      }
    },
    //提交成功回调
    onSubmit(e) {
      this.$emit("onSubmit", e.data);
    },
    //Tabs选项变更
    tabsChange(index) {
      this.tabsIndex = index;
    }
  },
  watch: {
    data: {
      handler () {
        this.init();
      },
      deep: true
    }
  },
};
