<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="
            height: 49px;
            display: flex;
            align-items: center;
          ">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">四名工作室</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">优质原创内容</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="mainCen">
        <!-- 表格 -->
        <div class="tableList" v-if="total">
          <div class="ulBox" v-for="(it, ind) in dataList1" :key="ind" @click="goweChatDetails(it.group_id)">
            <div class="liBox cp" v-for="(em, dex) in it.article_list" :key="dex">
              <div class="top" v-if="dex == 0">
                <img class="imgBig" :src="em.thumb" alt="">
                <div class="aboutTxt">
                  <p class="ellipsis-1">{{ em.title }}</p>
                </div>
              </div>
              <div class="bom" v-else>
                <div class="fsbc">
                  <div class="txtBox">
                    <p class="ellipsis-2 fz14">{{ em.title }}</p>
                  </div>
                  <div class="imgBox">
                    <img class="imgBig" :src="em.thumb" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="bommitSty pl10 pr10 pb10">
              <div class="df dfb pl5 pr5">
                <div class="imgBox">
                  <img :src="it.photo_url" alt="">
                </div>
                <div class="txtBox">
                  <p class="fz14 ellipsis-1 c333">{{ it.teaching_studio_name }}</p>
                  <p class="fz14 ellipsis-1 c999 mt3 mb3">{{ it.create_time }}</p>
                  <p class="fz14 ellipsis-1 c333">推送至{{ it.account_name_str }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
        <el-pagination v-if="total" class="tc mt30 mb30" background @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="10" layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      type1List: [
        { id: 0, name: "全部" },
        { id: 1, name: "四名沙龙" },
        { id: 2, name: "四名论坛" },
        { id: 3, name: "竞赛活动" },
        { id: 4, name: "四名伴我行" },
      ],
      sectionIndex: 0,
      type2List: [
        { id: 0, name: "全部" },
        { id: 1, name: "进行中" },
        { id: 2, name: "未开始" },
        { id: 3, name: "已结束" },
      ],
      subjectIndex: 0,

      dataList1: [],
      total: 0,
      form: {
        page: 1,
        limit: 20,
      },
    };
  },

  created() {
    this.getList1()
  },
  methods: {
    getList1() {
      msAllApi.selectWeChatMaterialList(this.form).then(res => {
        this.dataList1 = res.data.data.data
        this.total = res.data.data.total
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getList1()
    },
    seletBtn() {
      this.form.page = 1
      this.getList1()
    },
    // 跳转直播详情
    goweChatDetails(id) {
      this.$router.push({
        name: "weChatDetails",
        query: { id: id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .select_box {
    border-radius: 6px;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;

    .select_left {
      flex: 1;
      overflow: hidden;

      .xueduan {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        p {
          color: #999999;
          margin-right: 20px;
        }

        .xueduan_block {
          height: 32px;
          line-height: 32px;
          display: inline-flex;
          align-items: center;
          margin-right: 20px;
          padding: 0 12px;
          font-size: 16px;
          color: #333;
        }

        .active_block {
          background: #3489fe;
          border-radius: 4px 3px 4px 4px;
          color: #ffffff;
        }
      }

      .sousuo-box {
        display: flex;
        align-items: center;

        p {
          color: #999999;
          flex-shrink: 0;
          margin-right: 20px;
        }

        .box-right {
          flex: 1;
          overflow: hidden;
          display: flex;
          justify-content: space-between;

          .sousuo {
            width: 320px;
            display: flex;

            /deep/ .el-input__inner {
              // border-radius: 0px;
              border-radius: 4px 0px 0px 4px;
            }

            /deep/ .el-button {
              // border-radius: 0px;
              border-radius: 0px 4px 4px 0px;
            }
          }
        }
      }
    }

    .select_right {
      .create {
        width: 140px;
        height: 38px;
        background: #ff8201;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }

  .mainCen {
    .tableList {
      column-count: 4;
      column-gap: 29px;

      .ulBox {
        height: auto;
        margin-bottom: 29px;
        border-radius: 8px;
        break-inside: avoid;
        background: #fff;

        &:hover {
          box-shadow: 0 0 10px 0 #46b0fe;
        }

        .liBox {
          &:not(:first-child) {
            border-left: 1px solid #e4e8eb;
            border-right: 1px solid #e4e8eb;
          }

          .top {
            position: relative;
            width: 100%;
            height: 184px;

            img {
              width: 100%;
              height: 184px;
              border-radius: 8px 8px 0 0;
            }

            .aboutTxt {
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(0deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .3));
              width: 100%;
              height: 32px;
              line-height: 32px;
              color: #fff;
              padding: 0 10px;
              box-sizing: border-box;
            }
          }

          .bom {
            width: 100%;
            height: 71px;
            padding: 0 10px;
            box-sizing: border-box;

            >div {
              border-bottom: 1px solid #ccc;
              padding: 5px 0;

              .txtBox {
                width: 230px;
              }

              .imgBox {
                width: 60px;
                height: 60px;

                img {
                  width: 100%;
                  height: 60px;
                }
              }
            }
          }
        }

        .bommitSty {
          border-left: 1px solid #e4e8eb;
          border-right: 1px solid #e4e8eb;
          border-bottom: 1px solid #e4e8eb;
          border-radius: 0 0 8px 8px;

          >div {
            background: rgba(238, 246, 255, 1);
            height: 80px;
            padding: 0 15px;
            align-items: center;

            .imgBox {
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
              }
            }

            .txtBox {
              width: calc(100% - 50px);
            }
          }
        }
      }
    }
  }
}
</style>