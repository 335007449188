<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth2 pb40">
      <div class="public-width">
        <div class="public-width">
          <div class="mainBox1 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aserviceImg/jiditit2.png" alt="">
            </div>

            <div class="hezuoBox">
              <h4>合作内容：</h4>
              <div class="box1 mt30">
                <h4>精准化教与学课题研究应用试点</h4>
                <p>以课题为导向、瓣学网为基础，选择试点班围绕<span>课前、课中、课后、课外</span>全过程开展精准化教与学课题研究试点应用。</p>
                <div class="boxUl">
                  <div>
                    <div>平台</div>
                    <div>提供所需的软件支持、备授课应用及精准作业解决方案。</div>
                  </div>
                  <div>
                    <div>资源</div>
                    <div>提供瓣学网题库资源、精准作业等服务。</div>
                  </div>
                  <div>
                    <div>课题</div>
                    <div>提供精准化教与学相关的课题申报与研究指导服务。</div>
                  </div>
                </div>
              </div>

              <div class="box2 mt24">
                <div>
                  <h4>校本研修</h4>
                  <p class="mb23">1.合作开展校本研训一体化项目，提供进校培训指导并推广至各学校。</p>
                  <p>2.以结果为导向的“<span>六个一</span>”校本研修</p>
                </div>
                <div>
                  <h4>成果推广</h4>
                  <p>包括：课堂教学软件、课堂评价工具
                    (lPAS分析）、精准作业、瓣学英语
                    (中高考)、随堂练(单元卷、期中期末、
                    模考卷、分析报告）、集团校服务等。</p>
                </div>
                <div>
                  <h4>其他内容</h4>
                  <p>双方均享有优先与对方合作的权利</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="bgWidth4 pt30">
      <div class="public-width">
        <div class="mainBox7">
          <div class="jidibox">
            <img src="@/assets/banner_img/aserviceImg/jidi1.png" alt="">
            <img src="@/assets/banner_img/aserviceImg/jidi2.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 620px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.mainBox7 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .jidibox {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    img {
      margin: 0 50px;
    }
  }
}

.mainBox1 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .hezuoBox {
    >h4 {
      font-size: 26px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .box1 {
      height: 340px;
      background: rgba(20, 91, 185, 0.1);
      padding: 55px 40px;
      box-sizing: border-box;

      >h4 {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 10px;
      }

      >p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 30px;

        span {
          color: #ea1e70;
          font-weight: bold;
        }
      }

      .boxUl {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >div {
          width: 596px;
          height: 68px;
          background: rgba(255, 255, 255, 1);
          padding: 2px;
          box-sizing: border-box;
          margin-bottom: 8px;
          display: flex;

          &:first-child {
            width: 100%;
          }

          >div {
            display: flex;
            align-items: center;

            &:first-child {
              width: 64px;
              height: 64px;
              background: rgba(20, 91, 185, 1);
              justify-content: center;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
            }

            &:last-child {
              padding-left: 17px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              color: rgba(51, 51, 51, 1);
            }
          }
        }
      }
    }

    .box2 {
      display: flex;
      justify-content: space-between;

      >div {
        width: 410px;
        height: 320px;
        background: rgba(231, 238, 248, 1);
        padding: 47px 36px;
        box-sizing: border-box;

        h4 {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }

        p {
          font-size: 20px;
          font-family: Microsoft YaHei;
          color: rgba(51, 51, 51, 1);
          margin-top: 15px;

          span {
            color: #ea1e70;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>