<template>
  <div class="groupdetaillive">
    <div class="content_one">
      <div class="one_left">教研活动</div>
      <div class="one_right cursor" v-if="(group_status != 3 && group_status != 4)">
        <!-- （<span>3</span>） -->
        <p class="btn2" v-if="openFlat  == 1 " @click="GroupAuditTearm()">审核组员创建的</p>
        <i class="el-icon-plus"></i>
        <p @click="createResearchActivity()">创建教研活动</p>
      </div>
    </div>

    <div class="content_three">
      <div class="three_one" v-for="(item,index) in stateArrList" :key="index" :class="{ 'active': ListData.search_status == item.id}"
        @click="handleStateChange(item)">{{item.name}}</div>
    </div>

    <div class="content_two">
      <CommonEmpty v-if="!ArryList.length" hVal="100%"></CommonEmpty>
      <template v-for="(item, index) in ArryList">
        <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="1" :currentIndex="index"
          :item="item" @researchActivityEditClick="researchActivityEditClick" @delClick_2="researchActivityDelClick" @activity_RefreshPage="getList()"
          @toPageEvent_5="activityDetails">
        </PublicTtemDiv>
      </template>
    </div>
    <div v-if="ListData.total" class="pagin">
      <el-pagination background :current-page="ListData.page" :page-size="ListData.limit" layout="prev, pager, next, jumper" :total="ListData.total"
        @current-change="currentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openFlat: 0,
      // 缓存组的状态
      group_status: 0,
      groupId: 0, //教研组id
      ListData: {
        total: 0,
        limit: 12,
        page: 1,
        search_status: 0,
      },
      ArryList: [],
      stateArrList: [
        { id: 0, name: '全部' },
        { id: 2, name: '未开始' },
        { id: 1, name: '进行中' },
        { id: 3, name: '已结束' },
      ]
    };
  },
  created() {
    // 缓存组的状态
    this.group_status = localStorage.getItem("group_status");
    this.openFlat = localStorage.getItem('AuditFlat');
    this.init();
  },
  methods: {
    //审核
    GroupAuditTearm() {
      this.$router.push({
        path: '/GroupTearmAudit',
        query: {
          groupId: this.$route.query.groupId,
          type: '2',
          navCurrentType: 'activity',
        }
      })
    },
    // 初始化数据
    init() {
      this.groupId = this.$route.query.groupId ? this.$route.query.groupId : 0;
      this.getList();
    },
    // 得到教研活动列表
    async getList() {
      let res = await this.$axios.get(`/group/${this.groupId}/Activities`, {
        params: this.ListData,
      });
      this.ArryList = res.data.data.data;
      this.ListData.total = res.data.data.total;
    },
    //删除活动
    async delActivity(id) {
      const res = await this.$axios.delete(`/activity/${id}`);
      if (res.data.code == 900) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList();
      }
    },
    handleStateChange(item) {
      switch (Number(item.id)) {
        case 0:
          this.$set(this.ListData, 'page', 1);
          this.getDataOne();
          break;
        case 2:
          this.$set(this.ListData, 'page', 1);
          this.getDataTwo();
          break;
        case 1:
          this.$set(this.ListData, 'page', 1);
          this.getDataThree();
          break;
        case 3:
          this.$set(this.ListData, 'page', 1);
          this.getDataThour();
          break;
        default:
          break;
      };
    },
    //全部
    getDataOne() {
      this.ListData.search_status = 0;
      this.getList();
    },
    //  未开始
    getDataTwo() {
      this.ListData.search_status = 2;
      this.getList();
    },
    // 进行中
    getDataThree() {
      this.ListData.search_status = 1;
      this.getList();
    },
    // 结束
    getDataThour() {
      this.ListData.search_status = 3;
      this.getList();
    },
    //创建教研活动按钮
    createResearchActivity() {
      this.$router.push({
        path: "/newActivity",
        query: {
          groupId: this.groupId
        },
      });
    },
    //修改教研活动按钮
    researchActivityEditClick(id) {
      this.$router.push({
        path: "/newActivity",
        query: { groupId: this.groupId, id: id },
      });
    },
    //删除教研活动按钮
    researchActivityDelClick(id) {
      this.$confirm("此操作将删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity(id);
      }).catch(() => { });
    },
    //教研活动详情
    activityDetails(item) {
      this.$router.push({
        path: "/basicinfo",
        query: { activityId: item.id },
      });
    },
    //当前页码改变
    currentChange(val) {
      this.ListData.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 90px;
  height: 62px;
  top: -20px;
  right: -36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  transform: rotate(45deg);
  background-color: #ff8201;
  color: #fff;
  &.tios_div_box_2_div {
    background-color: #0080ff;
  }
  &.tios_div_box_3_div {
    background-color: #ff8201;
  }
  &.tios_div_box_4_div {
    background-color: #ff0607;
  }
  &.tios_div_box_5_div {
    background-color: #0080ff;
  }
  &.tios_div_box_6_div {
    background-color: #ff0607;
  }
  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }
}
.groupdetaillive {
  background: #ffffff;
  border-radius: 6px;
  padding-bottom: 40px;
  margin-bottom: 60px;
  padding-left: 30px;
  overflow: hidden;
  .content_one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 10px;
    padding-right: 23px;
    .one_left {
      font-weight: 700;
    }
    .one_right {
      display: flex;
      align-items: center;
      color: #3489fe;
      .btn2 {
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #3489fe;
        margin-right: 30px;
        span {
          color: #ff0607;
          font-weight: bold;
        }
      }
      i {
        margin-right: 10px;
      }
    }
  }
}
.content_two {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  box-sizing: border-box;
}
.content_three {
  display: flex;
  .three_one {
    width: 90px;
    height: 32px;
    background: rgba(52, 137, 254, 0.1);
    border-radius: 16px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      background: #3489fe;
      color: #fffefe;
    }
  }
}
</style>