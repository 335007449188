<template>
  <div class="preparelessons">
    <StudioIndex v-if="is_show_head==1"></StudioIndex>
    <div class="w_d center_one">
      <div class="bread" style="
          height: 68px;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        ">
        <div class="bread_content" style="margin: 0 !important">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/researchcource' }">研修视频</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">视频详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="bread_hengxian" style="margin-top:27px !important;margin-bottom:33px !important"></div> -->
      </div>
      <div class="one_content">
        <div class="images">
          <!-- <img src="@/assets/images/live.png" /> -->
          <img :src="detailData.cover_url" />

          <div class="textbox">
            <comPlain :type="3" :id="detailData.id" :is_from="is_from" />
          </div>
          <!-- <div class="bg_code">
              <span>活动访问码:</span>
              <span>{{detailData.visit_code}}</span>
          </div> -->
        </div>
        <div class="word">
          <div class="word_one">
            <p>{{ detailData.name }}</p>
          </div>
          <div class="word_two">
            <span>课程分类：</span>
            <span>集中备课</span>
            <span>学段学科：</span>
            <span>{{ detailData.stage_name }}{{ detailData.subject_name }}</span>
            <span>年级：</span>
            <span>{{ detailData.stage_name }}{{ detailData.grade_name }}</span>
            <span>教材版本：</span>
            <span>{{ detailData.edition_name }}</span>
          </div>
          <div class="word_three">
            <span>适用章节：</span>
            <span :title="detailData.chapter_name">{{
              detailData.chapter_name
            }}</span>
            <span>作者：</span>
            <span>{{ detailData.author }}</span>
          </div>
          <!-- 新增访问码 -->
          <!-- <p>
            课程访问码：<span style="color: #ff8201; font-weight: bold">{{
              detailData.visit_code
            }}</span>
          </p> -->
          <div class="word_four">
            <div class="invitation_block">
              <new-invitation :access_code="detailData.visit_code" describe_string="课程" describe_two_string="集中备课"></new-invitation>
            </div>
            <div class="startup cursor" style="background-color: #1890ff" v-if="detailData.is_real == '0'" @click="subscribe">
              订阅课程
            </div>
            <div class="startup" style="background-color: #cccccc" v-else-if="detailData.is_real == '1'">
              已订阅
            </div>
            <div class="word_four_one">
              <!-- <p>
                <span>温馨提示：</span>启动课程后，无法再编辑课程，请确保课程信息和内容无误后再发布。
              </p> -->
              已有 <span>{{ detailData.user_count }}</span> 人订阅
            </div>
            <!-- <div
              class="invitation_block cursor"
              @click="invitationDilog = true"
            >
              邀请
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="two_content">
      <div class="center_main-content w_d">
        <!-- 筛选 -->
        <div class="title">
          <p :class="[activeContent == 'one' ? 'active_content' : '']" @click="changeAcyive('one')">
            视频介绍
          </p>
          <p :class="[activeContent == 'two' ? 'active_content' : '']" @click="changeAcyive('two')">
            视频内容
          </p>
          <p :class="[activeContent == 'three' ? 'active_content' : '']" @click="changeAcyive('three')">
            评论（{{ commentTotal }}）
          </p>
        </div>

        <div class="replace_one" v-if="activeContent == 'one'">
          <div class="one">
            <p class="one_one">课程概述</p>
          </div>
          <div class="two">
            <p class="rich_text_div_img" v-html="detailData.presentation"></p>
          </div>
          <div class="three">
            <p>作者简介</p>
          </div>
          <div class="four">
            <div class="four_block" v-for="(item, index) in authorList" :key="index">
              <div class="block_one">
                <div class="left">
                  <div class="images">
                    <img :src="item.photo_url" />
                  </div>
                  <div class="word">
                    <span style="font-size: 17px">
                      {{ item.realname }}&nbsp;&nbsp;
                    </span>
                    <span style="font-size: 15px">
                      {{ item.stage_text }} &nbsp;&nbsp;{{
                        item.subject_text
                      }}</span>
                    <p style="font-size: 15px">{{ item.org_name }}</p>
                  </div>
                </div>
                <!-- <div class="right">
                  <i class="el-icon-delete"></i>
                </div> -->
              </div>
              <div class="block_two">
                <p>
                  {{ item.synopsis }}
                </p>
              </div>
              <div class="block_three"></div>
            </div>
          </div>
        </div>
        <div class="replace_two" v-else-if="activeContent == 'two'">
          <div class="one">
            <div class="chapter" v-for="(item, index) in actionList" :key="index">
              <div class="chapter_title">
                <p>第{{ item.sort }}章</p>
                <p>
                  {{ item.title }}
                </p>
                <!-- <i class="el-icon-edit-outline"></i>
                <i class="el-icon-delete"></i>
                <div class="add_section">添加小节</div> -->
              </div>
              <div class="chapter_content">
                <div class="contnet_block">
                  <div v-for="(item2, index2) in item.child" :key="index2">
                    <div class="shuxian"></div>
                    <div class="detail cursor" @click="showDialog(item2.data)">
                      <div class="images">
                        <img src="@/assets/images/flower.png" />
                      </div>
                      <p class="word">
                        {{ item2.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="two">
            <div class="add_chapter">添加章节</div>
          </div> -->
        </div>
        <div class="replace_three" v-else-if="activeContent == 'three'">
          <div class="three_content">
            <div class="three_content_main w_d">
              <!-- <div class="comment_number">课程评论</div> -->
              <div class="loop">
                <div class="select_comment">
                  <div class="left_select">
                    <el-radio v-model="commentRadio" label="1">全部评论（{{ commentTotal }}）</el-radio>
                    <el-radio v-model="commentRadio" label="2">我的评论（{{ myCommentTotal }}）</el-radio>
                  </div>
                  <div class="right_select cursor" @click="srcoll">
                    发表评论
                  </div>
                </div>
                <!-- 评论列表 -->
                <div style="padding: 0 40px">
                  <new-comment :activity_id="id" :module_type="is_from == 1 ? 4 : 5" @onChangeTotal="onChangeTotal" @onGetComment="onGetComment"
                    ref="commentRef"></new-comment>
                </div>
              </div>
              <!-- 发布评论 -->
              <div class="my_comment" id="view">
                <div class="my_comment_left">
                  <div class="images">
                    <!-- <img src="@/assets/images/avator.png" /> -->
                    <img :src="photo_url" />
                  </div>
                  <p class="name">{{ username }}</p>
                </div>
                <div class="my_comment_right">
                  <el-input type="textarea" :rows="6" placeholder="写下你的评论..." v-model="myContent" resize="none"></el-input>
                  <div class="release cursor" @click="releaseComment">发布</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加小节对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="dialogVisible" width="704px" :show-close="false">
        <div class="dialogall_content">
          <div class="one">
            <p>添加小节</p>
          </div>
          <div class="hengxian"></div>
          <div class="two">
            <p>添加基础内容</p>
          </div>
          <div class="three">
            <div class="three_one">
              <div class="images">
                <img src="@/assets/images/add-wenben.png" />
              </div>
              <p>添加文本</p>
            </div>
            <div class="three_one">
              <div class="images">
                <img src="@/assets/images/add-word.png" />
              </div>
              <p>添加文档</p>
            </div>
            <div class="three_one">
              <div class="images">
                <img src="@/assets/images/add-video.png" />
              </div>
              <p>添加视频</p>
            </div>
          </div>
          <div class="two">
            <p>添加互动环节</p>
          </div>
          <div class="three">
            <div class="three_one">
              <div class="images">
                <img src="@/assets/images/add-comment.png" />
              </div>
              <p>添加讨论</p>
            </div>
            <div class="three_one">
              <div class="images">
                <img src="@/assets/images/add-paper.png" />
              </div>
              <p>添加试题试卷</p>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 邀请对话框 -->
    <el-dialog title="邀请成员" :visible.sync="invitationDilog" width="30%">
      <h4>分享至：</h4>
      <div class="df mt20 mb20">
        <PublicShare></PublicShare>
      </div>
      <div class="invitation">
        <template v-if="false">
          <p>访问码邀请</p>
          <p>
            将课程访问码分享给活动参与者，参与者可在网页中输入访问码访问课程
            课程访问码：{{ detailData.visit_code }}
            <el-button size="mini" class="tag-read" :data-clipboard-text="detailData.visit_code" @click="copy">copy</el-button>
          </p>
        </template>
        <p>分享链接</p>
        <p>
          复制链接并分享，听评课参与者打开链接查看活动详情<br />
          分享链接：<el-input :value="dizhi" placeholder="" class="three-input"></el-input>
          <el-button size="mini" class="tag-read" :data-clipboard-text="dizhi" @click="copy" type="primary">复制链接</el-button>
        </p>
      </div>
    </el-dialog>
    <!-- 文本对话框 -->
    <el-dialog :visible.sync="textDialogVisible" width="50%">
      <div class="text_content">
        <div class="content_one">{{ textData.title }}</div>
        <div class="content_two">
          <p class="rich_text_div_img" v-html="textData.presentation"></p>
        </div>
      </div>
    </el-dialog>
    <!-- 文档预览对话框 -->
    <el-dialog :title="textData.title" :visible.sync="docxDialogVisible" width="50%">
      <div style="width: 1000px; height: 1000px">
        <iframe :src="
            'https://view.officeapps.live.com/op/view.aspx?src=' +
            textData.resource_url
          " style="
            overflow: auto;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
          "></iframe>
      </div>
    </el-dialog>
    <!-- pdf文档预览对话框 -->
    <el-dialog :title="textData.title" :visible.sync="viewVisible" width="50%" center>
      <div style="margin-bottom: 15px; text-align: right">
        <el-button type="primary" size="small" @click.stop="previousPage">上一页</el-button>
        <el-button type="primary" size="small" @click.stop="nextPage">下一页</el-button>
        <span>当前第{{ pdfPage }}页 / 共{{ pageCount }}页</span>
      </div>

      <div>
        <pdf :src="src" :page="pdfPage" @num-pages="pageCount = $event" @page-loaded="pdfPage = $event" style="display: inline-block; width: 100%">
        </pdf>
      </div>
    </el-dialog>
    <!-- 视频浏览对话框 -->
    <el-dialog :title="textData.title" :visible.sync="shipinDialogVisible" width="50%">
      <video :src="textData.resource_url" type="video/mp4" poster="false.png" autoplay="autoplay" controls="controls" loop="-1"
        style="width: 100%; height: 100%">
        <p>你的浏览器不支持video标签.</p>
      </video>
    </el-dialog>
    <!-- 试题对话框 -->
    <el-dialog :visible.sync="questionDialog" width="50%" :title="textData.title" center>
      <!-- <div v-html="ques"></div> -->
      <div class="question_yangshi">
        <el-checkbox v-model="answerShow">显示全部答案和解析</el-checkbox>
        <div class="main_content">
          <div class="content_block" v-for="(item, index) in textData.questionArray" :key="index">
            <div class="content_one">
              <div>{{ index + 1 }}、【题目】</div>
              <div class="rich_text_div_img" v-html="item.title"></div>
            </div>

            <div class="content_two">
              <div>【选项】</div>
              <div class="content_two_one">
                <div>
                  <span>A:</span><span>{{ item.option_a }}</span>
                </div>
                <div>
                  <span>B:</span><span>{{ item.option_b }}</span>
                </div>
              </div>
              <div class="content_two_one">
                <div>
                  <span>C:</span><span>{{ item.option_c }}</span>
                </div>
                <div>
                  <span>D:</span><span>{{ item.option_d }}</span>
                </div>
              </div>
              <div class="content_two_one" v-if="item.option_e != ''">
                <div>
                  <span>E:</span><span>{{ item.option_e }}</span>
                </div>
              </div>
            </div>
            <div class="content_three"></div>

            <div v-if="answerShow">
              <div class="content_four">
                【答案】<span>{{ item.answer1 }}</span>
              </div>
              <div class="content_five">
                <div class="rich_text_div_img" v-html="item.parse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { isHasStudioIdFn } from "@/utils/utils.js";
export default {
  data() {
    return {
      dialogVisible: false,
      // 课程id
      id: "",
      // 详情总数据
      detailData: {},
      // 当前用户id
      user_id: "",
      // 邀请对话框
      invitationDilog: false,
      // 当前页面的地址
      dizhi: window.location.href,
      // 活动内容区域
      activeContent: "one",
      // 作者列表
      authorList: [],
      // 章节列表
      actionList: [],
      // 文本对话框显示与否
      textDialogVisible: false,
      // 文本对话框数据
      textData: {},
      //PDF预览
      viewVisible: false,
      src: "",
      pdfPage: 1,
      pageCount: 0,
      // 文档显示与否
      docxDialogVisible: false,
      //视频显示与否
      shipinDialogVisible: false,
      // 试题试卷对话框
      questionDialog: false,
      // 控制答案显示与否
      answerShow: false,
      // 当前用户评论内容
      myContent: "",
      // 评论总数目
      commentTotal: 0,
      // 评论总数据
      commentData: [],
      // 当前登录的用户名
      username: "",
      // 所选全部或我的评论数据
      commentRadio: "1",
      // 我的评论总数目
      myCommentTotal: "",
      // 我的评论总数据
      myCommentData: [],
      // 当前登录的用户id
      userId: "",
      // 当前用户头像url
      photo_url: "",
      // 1研修视频，2四名工作室的录播课程
      is_from: 1,
      is_show_head: null,
    };
  },

  computed: {
    // showData: function () {
    //   if (this.commentRadio == "1") {
    //     return this.commentData;
    //   } else if (this.commentRadio == "2") {
    //     return this.myCommentData;
    //   }
    // },
  },
  watch: {
    commentRadio(newVal) {
      this.onGetComment();
    },
  },
  created() {
    if (this.$route.query.is_show_head) {
      if (isHasStudioIdFn(this)) {
        this.is_show_head = this.$route.query.is_show_head
      };
    };
    this.init();
    // 初始化作者信息
    // this.getAutor();
  },
  methods: {
    fenxiang(val) {
      if (val == 1) {
        let urlData = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.dizhi}&sharesource=qzone&title=${this.detailData.name}&pics=${this.detailData.cover_url}`;
        window.open(urlData, "_blank");
      }
      if (val == 3) {
        let urlData = `https://connect.qq.com/widget/shareqq/index.html?url=${this.dizhi}&sharesource=qzone&title=${this.detailData.name}&pics=${this.detailData.cover_url}&summary=${this.liveData.label}`;
        window.open(urlData, "_blank");
      }
    },
    // 初始化函数
    init() {
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.user_id = window.localStorage.getItem("id");
      this.userId = window.localStorage.getItem("id");
      this.username = window.localStorage.getItem("user_name");

      this.getDetail();
      // 章节
      this.getActionList();
      // 全部评论
      //   this.getCommentInfo();
      // 个人评论
      //   this.getMyCommentInfo();
      this.getPeisonInfo();
    },
    // 重新请求评论
    onGetComment() {
      if (this.commentRadio == 1) {
        // 全部评论
        this.$refs.commentRef.getCommentList();
      } else {
        // 个人评论评论
        this.$refs.commentRef.getMyCommentInfo();
      }
    },
    // 修改评论总数
    onChangeTotal(total, type) {
      if (type == 1) {
        this.commentTotal = total;
      } else {
        this.myCommentTotal = total;
      }
    },
    //请求后台获取视频详情
    getDetail() {
      var obj = {
        params: {
          id: this.id,
        },
      };
      if (this.is_from == 1) {
        this.$axios.get(`course/get_info/${this.id}`).then((res) => {
          this.detailData = res.data.data;
          // 请求后台获取作者列表
          this.$axios.get("course/get_author", obj).then((res2) => {
            if (this.detailData.user_id == this.user_id) {
              // 个人的
              this.authorList.push(res2.data.data.data);
            } else {
              if (res2.data.data.total == 1) {
                this.authorList.push(res2.data.data.data);
              } else {
                this.authorList = res2.data.data.data;
              }
            }
          });
        });
      } else {
        this.$axios
          .get(`index/TeachingStudioCourse/infoTeachingStudioCourse`, obj)
          .then((res) => {
            this.detailData = res.data.data;
            // 请求后台获取作者列表
            this.$axios
              .get("index/TeachingStudioCourse/get_author", obj)
              .then((res2) => {
                if (this.detailData.user_id == this.user_id) {
                  // 个人的
                  this.authorList.push(res2.data.data.data);
                } else {
                  if (res2.data.data.total == 1) {
                    this.authorList.push(res2.data.data.data);
                  } else {
                    this.authorList = res2.data.data.data;
                  }
                }
              });
          });
      }
    },
    //请求后台订阅课程
    subscribe() {
      var obj = {
        course_id: this.id,
        user_id: this.user_id,
      };
      this.$axios.post("course/subscribe", obj).then((res) => {
        this.init();
      });
    },
    // 复制文字函数
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        // 释放内存
        clipboard.destroy();
      });
    },
    // 改变活动区域函数
    changeAcyive(str) {
      this.activeContent = str;
    },
    // 请求后台获取作者列表
    getAutor() {
      var obj = {
        params: {
          id: this.id,
        },
      };
      if (this.is_from == 1) {
        this.$axios.get("course/get_author", obj).then((res) => {
          var resdata = res.data.data;
          this.authorList = resdata.data;
        });
      } else {
        this.$axios
          .get("index/TeachingStudioCourse/get_author", obj)
          .then((res) => {
            var resdata = res.data.data;
            this.authorList = resdata.data;
          });
      }
    },
    // 请求后台获取章节列表信息
    getActionList() {
      var obj = {
        params: {
          course_id: this.id,
        },
      };
      this.$axios.get("courseLink/get_list", obj).then((res) => {
        var resdata = res.data;
        this.actionList = resdata.data;
        this.actionList.forEach((item) => {
          let flat = item.hasOwnProperty("child");
          if (flat) {
            item.child.forEach((item2) => {
              this.$axios
                .get(`courseLink/get_info/${item2.id}`)
                .then((res2) => {
                  item2.data = res2.data.data;
                  // var questionArray=[];
                  item2.data.questionArray = [];
                  if (item2.data.questions_ids) {
                    item2.data.questions_ids.forEach((item3) => {
                      this.$axios
                        .get("question/get_info", {
                          params: {
                            question_id: item3,
                          },
                        })
                        .then((res3) => {
                          item2.data.questionArray.push(res3.data.data);
                        });
                    });
                  }
                });
            });
          }
        });
      });
    },
    // 显示视频内容相应对话框内容函数
    showDialog(data) {
      if (this.detailData.is_real == "1") {
        if (data) {
          this.textData = data;

          if (data.type == "1" || data.type == "4") {
            // this.textData=data;
            this.textDialogVisible = true;
          } else if (data.type == "2") {
            //获取最后一个.的位置
            // var index = this.textData.resource_url.lastIndexOf(".");
            // //获取后缀
            // var ext = this.textData.resource_url.substr(index + 1);
            // if (this.isAssetTypeAnword(ext)) {
            //   this.docxDialogVisible = true;
            // }
            // if (this.isAssetTypehpdf(ext)) {
            //   this.src = this.textData.resource_url;
            //   this.viewVisible = true;
            // }
            window.open(data.resource_url);
          } else if (data.type == "3") {
            this.shipinDialogVisible = true;
          } else if (data.type == "5") {
            this.questionDialog = true;
          }
        } else {
          this.$message.info("内容为空");
        }
      } else {
        this.$message.info("请先订阅课程");
      }
    },
    // 判断是否是文档
    isAssetTypeAnword(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(ext.toLowerCase());
    },
    // 判断是否是pdf
    isAssetTypehpdf(ext) {
      return ["pdf"].includes(ext.toLowerCase());
    },
    //关闭窗口初始化PDF页码
    closeDialog() {
      this.pdfPage = 1;
    },

    //PDF改变页数
    previousPage() {
      var p = this.pdfPage;
      p = p > 1 ? p - 1 : this.pageCount;
      this.pdfPage = p;
    },
    nextPage() {
      var p = this.pdfPage;
      p = p < this.pageCount ? p + 1 : 1;
      this.pdfPage = p;
    },
    // 请求后台发布评论函数
    releaseComment() {
      var obj = {
        course_id: this.id,
        user_id: this.userId,
        content: this.myContent,
      };
      this.$axios.post("courseReply/insert_comment", obj).then((res) => {
        this.$message.success("发布成功");
        this.myContent = "";
        // 全部评论
        this.$refs.commentRef.getCommentList();
        // 个人评论评论
        this.$refs.commentRef.getMyCommentInfo();
      });
    },
    // 滚动指定元素
    srcoll() {
      document.getElementById("view").scrollIntoView();
    },
    // 请求后台获取当前用户个人信息
    getPeisonInfo() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$axios.get(`user/${this.userId}/edit`).then((res) => {
          this.photo_url = res.data.data.photo_url;
        });
      } else {
        this.photo_url = "@/assets/images/avator.png";
      }
    },
  },
};
</script>

<style scoped lang="less">
.preparelessons {
  display: flex;
  flex-direction: column;
  align-items: center;
  /deep/ .is-link {
    color: #606266;
    font-weight: 400;
  }
  .center_one {
    // border: 1px solid black;
    .one_content {
      display: flex;
      margin-bottom: 30px;
      //   justify-content: space-between;
      .images {
        //图片样式
        position: relative;
        z-index: 1;
        img {
          width: 520px;
          height: 293px;
          border-radius: 6px;
        }
        .textbox {
          position: absolute;
          bottom: 4px;
          right: 0px;
        }
        //访问码
        // .bg_code{
        //   position: absolute;
        //   z-index: 999;
        //    width: 100%;
        //    height: 35px;
        //    background: rgba(0, 0, 0, 0.6);
        //    display: flex;
        //    justify-content: center;
        //    align-items: center;
        //    font-size: 16px;
        //   //  top: 10px;
        //   bottom: 4px;
        //   span:nth-of-type(1){
        //     color: #ffffff;
        //     margin-right: 5px;
        //   }
        //   span:nth-of-type(2){
        //     color: rgba(255, 255, 0, 0.847058823529412);
        //   }
        // }
      }
      .word {
        margin-left: 58px;
        margin-top: 10px;
        .word_one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 31px;
          p {
            font-size: 22px;
            font-weight: 700;
          }
          i {
            font-size: 22px;
            margin-left: 15px;
            color: #009dff;
          }
        }
        .word_two {
          display: flex;
          margin-bottom: 29px;
          span:nth-child(odd) {
            color: #999999;
          }

          span:nth-child(2) {
            // width: 120px;
            margin-right: 30px;
          }
          span:nth-child(4) {
            // width: 105px;
            margin-right: 30px;
          }
          span:nth-child(6) {
            // width: 105px;
            margin-right: 30px;
          }
        }
        .word_three {
          display: flex;
          margin-bottom: 32px;
          span:nth-child(odd) {
            color: #999999;
          }

          span:nth-child(2) {
            width: 120px;
            margin-right: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span:nth-child(4) {
            width: 105px;
            margin-right: 30px;
          }
        }
        .word_four {
          display: flex;
          align-items: center;
          justify-content: left;
          margin-top: 32px;

          .startup {
            width: 169px;
            height: 46px;
            // background: #ff8201;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            margin-right: 30px;
          }
          .word_four_one {
            span {
              // font-weight: 700;
              color: #3489fe;
            }
            font-size: 16px;
            font-family: Microsoft YaHei Regular,
              Microsoft YaHei Regular-Regular;
            font-weight: 400;
            color: #333333;
            p:nth-child(1) {
              margin-bottom: 5px;
            }
          }
          .invitation_block {
            margin-right: 20px;
          }
        }
        .word_five {
          display: flex;
          margin-bottom: 35px;
          span:nth-child(odd) {
            color: #999999;
          }

          span:nth-child(2) {
            margin-right: 30px;
          }
        }
      }
    }
  }
  .two_content {
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    justify-content: center;

    .center_main-content {
      .title {
        margin-top: 26px;
        display: flex;
        font-size: 22px;
        font-weight: 700;
        color: #333333;
        background: #ffffff;
        padding: 40px 37px;
        padding-bottom: 0px;
        // margin-bottom: 31px;
        border-bottom: 1px solid #ececec;
        p {
          margin-right: 60px;
          cursor: pointer;
        }
        // p:nth-child(1) {
        //   color: #3489fe;
        //   padding-bottom: 7px;
        //   border-bottom: 2px solid #3489fe;
        // }
        .active_content {
          color: #3489fe;
          padding-bottom: 7px;
          border-bottom: 2px solid #3489fe;
        }
      }
      .replace_one {
        width: 1331px;
        background: #ffffff;
        border-radius: 6px;
        padding: 40px 36px 59px 33px;
        margin-bottom: 60px;
        .one {
          display: flex;
          align-items: center;
          margin-bottom: 33px;
          p {
            font-size: 18px;
            font-weight: 700;
            margin-right: 14px;
          }
          i {
            color: #3489fe;
            font-size: 22px;
          }
        }
        .two {
          margin-bottom: 59px;
          p {
            line-height: 32px;
            text-indent: 2em;
          }
        }
        .three {
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          p {
            font-size: 18px;
            font-weight: 700;
            margin-right: 14px;
          }
          i {
            color: #3489fe;
            font-size: 22px;
          }
        }
        .four {
          .four_block {
            .block_one {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 13px;
              .left {
                display: flex;
                align-items: center;
                .images {
                  margin-right: 19px;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
                .word {
                  p:nth-child(1) {
                    font-size: 14px;
                    color: #fa8c15;
                    margin-bottom: 5px;
                  }
                  p:nth-child(2) {
                    font-size: 14px;
                    color: #9a9ea5;
                  }
                }
              }
              .right {
                i {
                  color: #3489fe;
                  font-size: 22px;
                }
              }
            }
            .block_two {
              margin-left: 69px;
              margin-bottom: 39px;
              p {
                line-height: 30px;
              }
            }
            .block_three {
              margin-left: 69px;
              width: 1250px;
              border-top: 1px dashed #ececec;
              margin-bottom: 40px;
            }
          }
          .four_block:last-child {
            .block_three {
              margin: 0;
              border: 0px;
            }
          }
        }
      }
      .replace_two {
        width: 1332px;
        border-radius: 6px;
        background: #ffffff;
        padding: 48px 34px 30px 34px;
        margin-bottom: 60px;
        .one {
          .chapter {
            margin-bottom: 50px;
            .chapter_title {
              width: 1341px;
              height: 50px;
              background: #f7f7f7;
              display: flex;
              align-items: center;
              p:nth-child(1) {
                font-size: 16px;
                font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
                font-weight: 700;
                color: #333333;
                margin-left: 38px;
                margin-right: 38px;
              }
              p:nth-child(2) {
                font-size: 16px;
                font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
                font-weight: 700;
                color: #333333;
                width: 966px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              i {
                color: #3489fe;
                margin-right: 28px;
                font-size: 22px;
              }
              .add_section {
                width: 110px;
                height: 34px;
                background: #3489fe;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                color: #ffffff;
              }
            }
            .chapter_content {
              .contnet_block {
                .shuxian {
                  height: 28px;
                  // width:1px;
                  border-left: 2px dashed #e3e5e8;
                  margin-left: 60px;
                }
                .detail {
                  width: 1287px;
                  height: 38px;
                  // border: 1px solid blue;
                  display: flex;
                  align-items: center;
                  margin-left: 43px;
                  .images {
                    margin-right: 29px;
                    img {
                      width: 38px;
                      height: 38px;
                    }
                  }
                  .word {
                    width: 1130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
                .detail:hover {
                  width: 1287px;
                  height: 38px;
                  // border: 1px solid blue;
                  display: flex;
                  align-items: center;
                  background: #f7f7f7;
                  margin-left: 43px;
                  .images {
                    margin-right: 29px;
                    img {
                      width: 38px;
                      height: 38px;
                    }
                  }
                  .word {
                    width: 1130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  i {
                    font-size: 22px;
                    color: #009dff;
                  }
                  i:nth-child(3) {
                    margin-right: 28px;
                  }
                }
                // .detail_active {
                //   width: 1287px;
                //   height: 38px;
                //   // border: 1px solid blue;
                //   display: flex;
                //   align-items: center;
                //   background: #f7f7f7;
                //   margin-left: 43px;
                //   .images {
                //     margin-right: 29px;
                //     img {
                //       width: 37px;
                //       height: 37px;
                //     }
                //   }
                //   .word {
                //     width: 1130px;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                //     white-space: nowrap;
                //   }
                //   i {
                //     font-size: 22px;
                //     color: #009dff;
                //   }
                //   i:nth-child(3) {
                //     margin-right: 28px;
                //   }
                // }
              }
            }
          }
        }
        .two {
          display: flex;
          justify-content: center;
          margin-bottom: 193px;
          .add_chapter {
            width: 169px;
            height: 46px;
            background: #3489fe;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .replace_three {
        .three_content {
          width: 100%;
          //   border: 1px solid black;
          background-color: #f7f7f7;
          display: flex;
          justify-content: center;
          .three_content_main {
            //   border: 1px solid black;
            .comment_number {
              font-size: 22px;
              font-weight: 700;
              margin-bottom: 39px;
            }
            .loop {
              // width: 1045px;
              width: 1400px;
              background: #ffffff;
              // border-radius: 6px;s
              overflow: hidden;
              .select_comment {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 36px;
                margin-bottom: 33px;
                padding: 0 10px;
                font-size: 16px;
                font-family: Microsoft YaHei Regular,
                  Microsoft YaHei Regular-Regular;
                font-weight: 400;
                color: #1a1a1a;
                .left_select {
                  margin-left: 26px;
                }
                .right_select {
                  margin-right: 30px;
                  width: 75px;
                  height: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #3489fe;
                  color: #ffffff;
                  border-radius: 4px;
                  font-size: 14px;
                }
              }

              .comment_block {
                overflow: hidden;
                margin-right: 32px;
                margin-bottom: 40px;
                margin-top: 40px;
                .block_one {
                  display: flex;
                  align-items: center;
                  margin-bottom: 14px;
                  margin-left: 30px;
                  .images {
                    margin-right: 19px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                      width: 50px;
                      height: 50px;
                    }
                  }
                  .one_word {
                    font-size: 14px;
                    p:nth-child(1) {
                      color: #fa8c15;
                      margin-bottom: 5px;
                    }
                    p:nth-child(2) {
                      color: #9a9ea5;
                    }
                  }
                }
                .block_two {
                  // margin-left: 69px;
                  margin-left: 99px;
                  margin-bottom: 21px;
                  p {
                    line-height: 30px;
                  }
                }
                .block_three {
                  display: flex;
                  justify-content: flex-end;
                  font-size: 14px;
                  color: #9a9ea5;
                  margin-bottom: 26px;
                  .three_one {
                    margin-right: 26px;
                  }
                  i {
                    margin-right: 6px;
                  }
                }
                .block_four {
                  display: flex;
                  justify-content: flex-end;
                  .reply_block {
                    margin-left: 98px;
                    width: 100%;
                    // width: 858px;
                    // height: 542px;
                    background: #f7f7f7;
                    border-radius: 8px;
                    padding: 30px 27px 34px 30px;
                    .reply_block_one {
                      .reply_one {
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 19px;
                        .images {
                          margin-right: 14px;
                          width: 50px;
                          height: 50px;
                          border-radius: 50%;
                          overflow: hidden;
                          img {
                            width: 50px;
                            height: 50px;
                          }
                        }
                        .reply_one_word {
                          font-size: 14px;
                          p:nth-child(1) {
                            margin-bottom: 5px;
                            span:nth-child(odd) {
                              color: #fa8c15;
                            }
                            span:nth-child(2) {
                              margin: 0px 10px;
                            }
                          }
                          p:nth-child(2) {
                            color: #9a9ea5;
                          }
                        }
                      }
                      .reply_two {
                        margin-left: 64px;
                        margin-bottom: 17px;
                        p {
                          line-height: 30px;
                        }
                      }
                      .reply_three {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 14px;
                        color: #9a9ea5;
                        margin-bottom: 26px;
                        .three_one {
                          margin-right: 26px;
                        }
                        i {
                          margin-right: 6px;
                        }
                      }
                      .reply_four {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 30px;
                        .reply_four_hengxian {
                          width: 100%;
                          margin-left: 60px;
                          // width: 794px;
                          // height: 1px;
                          border-top: 1px dashed #ececec;
                        }
                      }
                      .reply_five {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 25px;
                        .reply_five_block {
                          // width: 792px;
                          margin-left: 60px;
                          width: 100%;
                          height: 100px;
                          background: #ffffff;
                          border-radius: 8px;
                          padding-top: 10px;
                          .release_block {
                            width: 92px;
                            height: 36px;
                            background: #fa8c15;
                            border-radius: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #ffffff;
                            float: right;
                            margin-right: 20px;
                          }
                          /deep/ .el-textarea__inner {
                            border: none;
                          }
                        }
                      }
                    }
                    .reply_block_one:last-child {
                      .reply_four {
                        margin-bottom: 0px;
                        .reply_four_hengxian {
                          border-top: 0px;
                        }
                      }
                    }
                  }
                }
                .block_five {
                  // width: 914px;
                  margin-left: 100px;
                  width: 100%;
                  height: 1px;
                  border-top: 1px dashed #ececec;
                  margin-top: 26px;
                  // float: right;
                }
                .comment_block_reply {
                  display: flex;
                  justify-content: flex-end;
                  margin-bottom: 25px;
                  .block {
                    // width: 914px;
                    margin-left: 100px;
                    width: 100%;
                    height: 100px;
                    background: #ffffff;
                    border: 1px solid #ececec;
                    border-radius: 8px;
                    padding-top: 10px;
                    .release {
                      width: 92px;
                      height: 36px;
                      background: #fa8c15;
                      border-radius: 4px;
                      color: #ffffff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      float: right;
                      margin-right: 20px;
                    }
                    /deep/ .el-textarea__inner {
                      border: none;
                    }
                  }
                }
              }
              .comment_block:last-child {
                .block_five {
                  border-top: 0px;
                  margin-top: 0px;
                }
              }
            }
            .my_comment {
              // width: 1045px;
              width: 1400px;
              height: 264px;
              background: #ffffff;
              // border-radius: 6px;
              // margin-top: 25px;
              margin-bottom: 60px;
              display: flex;
              .my_comment_left {
                display: flex;
                flex-direction: column;
                margin-left: 27px;
                margin-top: 40px;
                align-items: center;
                .images {
                  margin-bottom: 13px;
                  width: 50px;
                  height: 50px;
                  overflow: hidden;
                  border-radius: 50%;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
                .name {
                  font-size: 14px;
                }
              }
              .my_comment_right {
                // width: 914px;
                width: 1245px;
                height: 184px;
                border: 1px solid #ececec;
                border-radius: 4px;
                margin-top: 40px;
                margin-left: 17px;
                .release {
                  width: 92px;
                  height: 34px;
                  background: #fa8c15;
                  border-radius: 4px;
                  font-weight: 700;
                  color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  float: right;
                  margin-right: 13px;
                }
                /deep/ .el-textarea__inner {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .dialogall_content {
    overflow: hidden;
    .one {
      p {
        margin-left: 40px;
        margin-top: 36px;
        font-weight: 700;
      }
    }
    .hengxian {
      width: 100%;
      height: 1px;
      background: #dedede;
      margin-top: 36px;
      margin-bottom: 40px;
    }
    .two {
      p {
        margin-left: 40px;
        margin-bottom: 39px;
        color: #999999;
      }
    }
    .three {
      display: flex;
      margin-left: 98px;
      margin-bottom: 40px;
      .three_one {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 75px;
        .images {
          margin-bottom: 17px;
          img {
            width: 118px;
            height: 118px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
  .text_content {
    .content_one {
      display: flex;
      justify-content: center;
      // font-weight:600;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .content_two {
      p {
        line-height: 30px;
      }
    }
  }
  .question_yangshi {
    .main_content {
      margin-top: 20px;
      max-height: 658px;
      overflow: auto;
      .content_block {
        margin-top: 40px;
        .content_one {
          display: flex;
        }
        .content_two {
          margin-top: 20px;
          margin-bottom: 20px;
          margin-left: 22px;
          .content_two_one {
            margin-left: 5px;
            display: flex;
            margin-top: 10px;
            div {
              margin-right: 20px;
              span {
                margin-right: 10px;
              }
            }
          }
        }
        .content_three {
          width: 100%;
          border-top: 1px dashed #ebebeb;
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>