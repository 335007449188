import  request  from '@/api/request.js'

//直播审核列表
export function LiveList(params){
    return request({
        url: 'my_group_live_list',
        method: 'get',
        params
    })
}

// 直播审核
export function AuditLive(data){
    return request({
        url: 'my_group_live_review',
        method: 'put',
        data
    })
}


// ---------------------活动----------------



//活动审核列表
export function ActivityList(params){
    return request({
        url: 'my_group_activity',
        method: 'get',
        params
    })
}

//活动审核
export function ActivityAudit(data){
    return request({
        url: 'my_group_activity_review',
        method: 'put',
        data
    })
}




// ---------------------课程----------------

//课程审核列表
export function CourseList(params){
    return request({
        url: 'my_group_course_list',
        method: 'get',
        params
    })
}

//课程审核
export function CourseAudit(data){
    return request({
        url:'my_group_course_review',
        method: 'put',
        data
    })
}



// ---------------------话题----------------

//课程审核列表
export function TopicList(params){
    return request({
        url: 'my_group_gambit_list',
        method: 'get',
        params
    })
}

//课程审核
export function TopicAudit(data){
    return request({
        url:'my_group_gambit_review',
        method: 'put',
        data
    })
}

