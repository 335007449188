import Vue from "vue";
import "./utils/utils.js"; //全局自定义方法库
import store from "./utils/store.js";
import App from "./App.vue";
import './api/request'
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 导入新增级联打钩组件
import elCascaderMulti from "el-cascader-multi";
Vue.use(elCascaderMulti);

//新增表情包组件
import VEmojiPicker from 'v-emoji-picker';
Vue.use(VEmojiPicker);

//引入粘贴功能
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
// 全局样式表
import "./styles/global.css";
import "./styles/global2.css";
import "./styles/common.less";
import "./styles/public.scss";
import "./styles/public_css_module.scss";

// 导入富文本编辑器
import VueQuillEditor from "vue-quill-editor";
import VueUeditorWrap from "vue-ueditor-wrap";
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
//  导入富文本编辑器对应的样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Router from "vue-router";

//引入直播播放器的组件
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);


import VueCookies from 'vue-cookies' //引入VueCookies 
Vue.use(VueCookies) // 声明使用

// 【过滤器 文件类型显示图片】
import {
  filterImgTypeMini,
  filterImgTypeSmall,
  filterImgTypeResource
} from "@/utils/utils.js";
Vue.filter('fileTypeMini', function (value) {
  return filterImgTypeMini(value); //【小图】
});
Vue.filter('fileTypeSmall', function (value) {
  return filterImgTypeSmall(value); //【大图】
});
Vue.filter('fileTypeResource', function (value) {
  return filterImgTypeResource(value); //【资源用】
});

// 全局引入方法
import changeUrl from '@/utils/changeUrl.js';
Vue.mixin(changeUrl)

// 【过滤器 文件大小 】
import {
  fileSizeFilter
} from "@/utils/utils.js";
Vue.filter('fileSizeFilter', function (value) {
  return fileSizeFilter(value);
});

import animated from "animate.css"; // npm install animate.css --save安装，在引入
Vue.use(animated);

import componentsInstallation from "./utils/installation.js"; // 引入常用的自定义公共组件upload
// 将富文本编辑器，注册为全局可用的组件
Vue.use(VueQuillEditor);
// 引入常用的自定义公共组件
Vue.use(componentsInstallation);
//引入icon
import "./assets/icon/iconfont.css";
Vue.use(ElementUI);
Vue.config.productionTip = false;

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");