<template>
  <div :class="routeName == 'AddCertificate' ? 'w_layout' : ''">

    <div class="topText" v-if="routeName == 'AddCertificate'">
      <div class="title">
        <p>
          当前位置：四名工作室 > 成果展示 >
          <span>{{ form.id ? '编辑' : '新增' }}{{ achievement_type == 1 ? '研究成果' : '研究课题' }}</span>
        </p>
      </div>
    </div>

    <div class="form_box_div">

      <div class="top_title_box">{{ form.id ? '编辑' : '新增' }}{{ achievement_type == 1 ? '研究成果' : '研究课题' }}
        <span class="temp_class_span_div"> -t63</span>
      </div>

      <el-form ref="ruleForm" :model="form" :rules="rules" label-position="right" label-width="150px"
        style="width: 100%; margin-left: 0px;">

        <el-form-item :label="achievement_type == 1 ? '成果标题 :' : '课题标题 :'" prop="name">
          <el-input v-model="form.name" style="width: 600px"
            :placeholder="achievement_type == 1 ? '请输入成果标题' : '请输入课题标题'"></el-input>
        </el-form-item>

        <el-form-item :label="achievement_type == 1 ? '获奖人/贡献者 :' : '课题主持人 :'" prop="usernames">
          <!-- <el-select v-model="form.username_id" filterable multiple
              :placeholder="achievement_type == 1 ? '请选择获奖人/贡献者' : '请选课题择持人'" style="width: 600px">
              <el-option v-for="(item, index) in termData" :key="index" :label="item.realname" :value="item.user_id">
              </el-option>
            </el-select> -->
          <el-input v-model="form.usernames" style="width: 600px"
            :placeholder="achievement_type == 1 ? '请输入获奖人/贡献者' : '请输入主持人姓名'"></el-input>
        </el-form-item>

        <el-form-item v-if="achievement_type == 2" label="课题编号 :" prop="topic_num">
          <el-input v-model="form.topic_num" style="width: 600px" placeholder="请输入课题编号"></el-input>
        </el-form-item>

        <el-form-item v-if="achievement_type == 2" label="研究周期 :" :required="true">
          <el-date-picker @change="changeDate" v-model="dataList" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

        <el-form-item :label="achievement_type == 1 ? '成果级别 :' : '课题级别 :'" prop="certificat_level">
          <el-radio-group v-model="form.certificat_level">
            <el-radio v-for="(item, index) in certificatList" :key="index" :value="item.id" :label="item.id">
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="achievement_type == 1" label="成果类型 :" prop="certificat_type">
          <el-radio-group v-model="form.certificat_type">
            <el-radio v-for="(item, index) in radioList" :key="index" :value="item.id" :label="item.id">
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="achievement_type == 1 ? '成果图片 :' : '课题图片 :'" :required="true">
          <div class="active_selected_box">
            <div class="active_selected_img" v-for="(item, index) in form.resource_data" :key="index">
              <img style="width: 100%; height: 100%;" :src="item.url" alt="">
              <div class="about">
                <i @click="delBtn(index)" class="el-icon-close"></i>
              </div>
            </div>
            <div class="active_selected_uploading">
              <dc-upload ref="activityUpload" :dcAutoUpload="true" :show-file-list="false" dcdom="activityUpload"
                :dcConfig="dcConfig" @dc-success="dcSuccess" :limit="6"
                accept=".jpg,.png,.swf,.bmp,.jpeg,.JPG,.PNG,.SWF,.BMP,.JPEG" v-if="isUpOk">
                <div class="upload_img_box">
                  <i class="el-icon-plus upload_icon_div"></i>
                </div>
              </dc-upload>
            </div>
          </div>
          <p style="font-size: 14px; color: #999; margin: 0 0 0 0">
            支持.JPG,.PNG,.JPEG等常见图片格式，长宽比建议为4:3，可上传多图，最多支持6幅图。
          </p>
        </el-form-item>

        <el-form-item :label="achievement_type == 1 ? '成果简介 :' : '课题简介 :'" prop="synopsis">
          <el-input style="width: 600px" v-model="form.synopsis" type="textarea" :rows="8" resize="none"
            :placeholder="achievement_type == 1 ? '请输入成果简介，可以填写获奖内容等信息' : '请输入课题简介，可以填写获奖内容等信息'">
          </el-input>
        </el-form-item>

        <el-form-item style="text-align: left;margin-top: 50px;">
          <el-button class="width148" @click="resetForm('form')">取消</el-button>
          <el-button class="width148" type="primary" style="background: rgba(255, 130, 1, 1); border: none"
            @click="submitForm('form')" :loading="save_loading_btn">提交
            <span class="temp_class_span_div">-89a</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
import { CertificateType } from "@/api/teacherStudio/index.js";
import { deepClone } from "@/utils/utils.js";
import { debounce, throttle } from "@/utils/utils.js";
export default {
  watch: {
    "form.synopsis"() {
      this.$nextTick(() => {
        this.$refs['ruleForm'].validateField('synopsis'); //单独校验
      })
    }
  },
  data() {
    return {
      achievement_type: "",
      save_loading_btn: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      form: {
        name: "",
        usernames: "",
        certificat_type: "",
        synopsis: "",
        teaching_studio_id: localStorage.getItem("studioId"),
        resource_data: [],
        resource_id: [],
        type: 2,

        certificat_level: "",
        topic_num: "",
        cycle_start_date: "",
        cycle_end_date: "",
        achievement_type: "",
      },
      dataList: "",
      rules: {
        usernames: [{ required: true, message: '请输入', trigger: 'blur' }],
        synopsis: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        topic_num: [{ required: true, message: '请输入课题编号', trigger: 'blur' }],
        certificat_type: [{ required: true, message: "请选择成果类型", trigger: "change" }],
        certificat_level: [{ required: true, message: this.achievement_type == 1 ? "请选择课题级别" : '', trigger: "change" }],
        name: [{ required: true, message: this.achievement_type == 1 ? '请输入成果标题' : '请输入课题标题', trigger: 'blur' },],
      },
      termData: [],
      radioList: [],
      certificatList: [
        { id: 1, name: "国家级" },
        { id: 2, name: "省级" },
        { id: 3, name: "市级" },
        { id: 4, name: "区县级" },
        { id: 5, name: "校级" },
        { id: 6, name: "其他" },
      ],
      isUpOk: false,
      routeName: "",
    }
  },
  created() {
    this.routeName = this.$route.name
    this.achievement_type = this.$route.query.achievement_type
    this._getAddress();
    this.getTearm();
    this.getCertificatesType();
    if (this.$route.query.id) {
      this.getDetails(this.$route.query.id)
    }
  },

  methods: {
    setDate(val) {
      var today = val;     //创建日期对象
      var year = today.getFullYear();     //获取年份
      var month = today.getMonth() + 1;    //获取月份
      var day = today.getDate();          //获取日期
      return year + "-" + month + "-" + day;  //拼接成yyyymmdd形式字符串 
    },
    changeDate(val) {
      if (val) {
        this.form.cycle_start_date = val[0]//this.setDate(val[0])
        this.form.cycle_end_date = val[1]//this.setDate(val[1])
        console.log(this.form);
      }
    },
    delBtn(index) {
      this.form.resource_id.splice(index, 1)
      this.form.resource_data.splice(index, 1)
    },
    //取消
    resetForm() {
      this.$router.go(-1);
    },
    //发布成果证书
    submitForm: throttle(function () {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return false;
        };
        let reqObj = deepClone(this.form);
        reqObj['achievement_type'] = this.achievement_type
        reqObj['resource_id'] = reqObj.resource_id.toString();
        this.save_loading_btn = true;
        let api = null
        if (this.form.id) {
          api = this.$axios.put("/TeachingStudioCertificate/edit", reqObj)
        } else {
          api = this.$axios.post("/TeachingStudioCertificate/add", reqObj)
        }
        api.then(() => {
          this.save_loading_btn = false;
          this.$message.success("发布成功");
          this.$router.go(-1);
        }).catch((err) => {
          this.save_loading_btn = false;
        });
      });
    }),
    test(){
      // 9*9乘法表]

    },
    //获取成果证书类型
    getCertificatesType() {
      CertificateType({ teaching_studio_id: localStorage.getItem("studioId") }).then((res) => {
        this.radioList = res.data.data || [];
      });
    },
    //成员列表
    getTearm() {
      this.$axios.get("index/teachingStudioUser/search_realname", {
        params: {
          limit: 1000,
          page: 1,
          search_name: "",
          teaching_studio_id: localStorage.getItem("studioId"),
        }
      }).then((res) => {
        this.termData = res.data.data;
      });
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      let etag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", { params: { etag: etag } });
      this.form.resource_data.push(res.data.data)
      this.form.resource_id.push(res.data.data.id)
      this.$refs['ruleForm'].validateField('resource_data'); //单独校验
    },
    //获取编辑详情
    getDetails(id) {
      this.$axios.get("TeachingStudioCertificate/get_field", {
        params: {
          id,
          teaching_studio_id: localStorage.getItem("studioId"),
        }
      }).then((res) => {
        let obj = res.data.data;

        this.form.name = obj.name
        this.form.usernames = obj.usernames
        this.form.certificat_type = obj.certificat_type
        this.form.synopsis = obj.synopsis
        this.form.certificat_level = obj.certificat_level
        this.form.topic_num = obj.topic_num
        this.form.achievement_type = obj.achievement_type
        this.form.resource_data = obj.resource_data
        this.form.resource_id = obj.resource_id.split(",")

        this.form.cycle_start_date = obj.cycle_start_date
        this.form.cycle_end_date = obj.cycle_end_date

        this.dataList = [obj.cycle_start_date, obj.cycle_end_date]

        this.form.id = obj.id

      });
    },
  },
};
</script>

<style lang="less" scoped>
.form_box_div {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 30px 50px;
  box-sizing: border-box;
  border-radius: 6px;
}

.top_title_box {
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-left: 60px;
}

.topText {
  .title {
    height: 48px;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    p {
      span {
        color: #ff8201;
      }
    }
  }
}

.active_selected_box {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  .active_selected_img {
    width: 160px;
    height: 100px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    margin-right: 20px;
    position: relative;
    margin-top: 20px;

    &:hover {
      .about {
        display: block;
      }
    }

    .about {
      display: none;
      cursor: pointer;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: #c0bdbd;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 20px;
      font-weight: bold;
      border-radius: 0 0 0 4px;

      i {
        color: #f00;
      }
    }
  }

  .selection_img_list {
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    margin-right: 10px;

    .selection_img_item {
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      width: 160px;
      height: 100px;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .selection_img_img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }

    .selection_img_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .selection_img_icon {
      color: #fff;
      font-size: 40px;
    }
  }

  .active_selected_uploading {
    position: relative;
    height: 100px;
    width: 100px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;

    .upload_img_box {
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload_icon_div {
      font-size: 30px;
    }
  }
}
</style>