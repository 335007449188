import { render, staticRenderFns } from "./ResearchActivity.vue?vue&type=template&id=74176d50&scoped=true"
import script from "./ResearchActivity.vue?vue&type=script&lang=js"
export * from "./ResearchActivity.vue?vue&type=script&lang=js"
import style0 from "./ResearchActivity.vue?vue&type=style&index=0&id=74176d50&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74176d50",
  null
  
)

export default component.exports