<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">四名沙龙活动申报</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <main class="mainBox">
        <h1 class="mb24">{{ salonRegData.title }}</h1>
        <div class="mainDf">
          <div class="left">
            <h4>一、活动主题申报<span>【截止时间：{{ salonRegData.theme_submission_date }}】</span><span class="end" v-if="salonRegData.is_theme_end == 1">（已截止）</span></h4>
            <div class="dfBox">
              <img src="@/assets/tishi.png" alt="">
              申报说明：{{ salonRegData.abstract }}
            </div>
            <div class="tableBox mt20">
              <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="host_type_str" align="center" label="面向对象" width="170">
                </el-table-column>
                <el-table-column prop="title" align="center" label="活动主题">
                  <template scope="scope">
                    <h4 class="tabh4">{{ scope.$index + 1 + '、' + scope.row.title }}</h4>
                    <p class="tabp">{{ scope.row.abstract }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="max_count" align="center" label="限额" width="100">
                  <template scope="scope">
                    {{ scope.row.max_count == 0 ? '不限' : scope.row.max_count }}
                  </template>
                </el-table-column>
                <el-table-column prop="theme_submission_count" align="center" label="已申报" width="100">
                </el-table-column>
                <el-table-column prop="name" align="center" label="申报" width="140">
                  <template scope="scope">
                    <template v-if="salonRegData.is_theme_end == 1 && scope.row.submission && scope.row.submission.id">
                      已申报该类
                    </template>
                    <el-button type="text" v-if="salonRegData.is_theme_end == 1 && !scope.row.submission.id">/</el-button>
                    <template v-if="salonRegData.is_theme_end == 2">
                      <div v-if="scope.row.allow_submission == 1">
                        <el-button v-if="scope.row.submission && scope.row.submission.id" class="sbBtn sbBtn2"
                          @click="_unsubmission(scope.row.id)">取消申报</el-button>
                        <el-button v-else class="sbBtn" @click="_submission(scope.row.id)" v-if="salonRegData.my_submission_count == 0 && !scope.row.submission.id && (scope.row.max_count == 0 || scope.row.theme_submission_count < scope.row.max_count)">申报该类</el-button>
                        <el-button type="text" v-if="salonRegData.my_submission_count == 0 && !scope.row.submission.id && (scope.row.theme_submission_count == scope.row.max_count)">/</el-button>
                        <el-button type="text" v-if="salonRegData.my_submission_count != 0 && !scope.row.submission.id">/</el-button>
                      </div>
                      <el-button type="text" v-else>/</el-button>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
          <div class="right">
            <h4>二、相关材料提交</h4>
            <div class="dfBox">
              <img src="@/assets/tishi.png" alt="">
              注：相关材料提交截止时间
            </div>
            <div class="rightBox mt20" v-if="salonRegData.my_submission_count == 0">
              <div class="txtBox">
                <div>
                  <h4>1.提交申报表<span class="end" v-if="salonRegData.is_form_end == 1">（已截止）</span></h4>
                  <p>请先申报活动主题！</p>
                </div>
                <div class="mt30">
                  <h4>2.提交主题分享材料<span class="end" v-if="salonRegData.is_material_end == 1">（已截止）</span></h4>
                  <p>请先申报活动主题！</p>
                </div>
              </div>
            </div>

            <div class="rightBox mt20" v-if="salonRegData.my_submission_count != 0">
              <div class="txtBox" v-if="salonRegData.themes">
                <div class="pb30" style="border-bottom: 1px solid #D5DDF2;">
                  <div class="titleBox">
                    <h4>1.提交申报表<span class="end" v-if="salonRegData.is_form_end == 1">（已截止）</span></h4>
                    <el-button type="text" @click="upFile(salonRegData.reg_file_arr.url)">下载表模</el-button>
                  </div>
                  <div class="upload_div_box mt10">
                    <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                      dcdom="ceshi98" :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false"
                      v-if="dcConfig.key != '' && dcConfig.notify_url != '' && isform && !salonRegData.themes[subIndex].submission.form_submission_arr.id">
                      <el-button class="sbBtn" type="primary">选择文件</el-button>
                    </dc-upload>
                    <div class="fileLis"
                      v-if="salonRegData.themes[subIndex].submission.form_submission_arr && salonRegData.themes[subIndex].submission.form_submission_arr.id">
                      <div>
                        <i class="el-icon-document"></i>
                        {{ salonRegData.themes[subIndex].submission.form_submission_arr.name }}
                      </div>
                      <div>
                        <!-- <new-viewonline :viewSrc="salonRegData.themes[subIndex].submission.form_submission_arr.url"
                          :resourceId="salonRegData.themes[subIndex].submission.form_submission_arr.id" :fileExt="salonRegData.themes[subIndex].submission.form_submission_arr.mime ||
                            salonRegData.themes[subIndex].submission.form_submission_arr.ext"
                          :itemData="salonRegData.themes[subIndex].submission.form_submission_arr"
                          :fileTitle="salonRegData.themes[subIndex].submission.form_submission_arr.name"
                          :fileSize="salonRegData.themes[subIndex].submission.form_submission_arr.size"
                          :fileTime="salonRegData.themes[subIndex].submission.form_submission_arr.create_time"
                          fileNav="首页 > 四名沙龙活动申报 > 查看" :is_hidden_nav="1" :downOther="1">
                          <p class="cp">预览</p>
                        </new-viewonline> -->
                        <p @click="yulanFile([salonRegData.themes[subIndex].submission.form_submission_arr], 0)">预览</p>
                        <p @click="delFile(1)" v-if="salonRegData.is_form_end == 2">删除</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt30">
                  <h4>2.提交主题分享材料<span class="end" v-if="salonRegData.is_material_end == 1">（已截止）</span></h4>
                  <div class="upload_div_box mt10 pt10">
                    <template v-if="salonRegData.themes[subIndex].submission.material_submission_arr">
                      <div class="fileLis mb15"
                        v-for="(item, index) in salonRegData.themes[subIndex].submission.material_submission_arr" :key="item.id">
                        <div>
                          <i class="el-icon-document"></i>
                          {{ item.name }}
                        </div>
                        <div>
                          <!-- <new-viewonline :viewSrc="item.url" :resourceId="item.id" :fileExt="item.mime ||
                            item.ext" :itemData="item" :fileTitle="item.name" :fileSize="item.size"
                            :fileTime="item.create_time" fileNav="首页 > 四名沙龙活动申报 > 查看" :is_hidden_nav="1" :downOther="1">
                            <p class="cp">预览</p>
                          </new-viewonline> -->
                          <p @click="yulanFile(salonRegData.themes[subIndex].submission.material_submission_arr, index)">预览</p>
                          <p @click="delFile(2, item.id)" v-if="salonRegData.is_material_end == 2">删除</p>
                        </div>
                      </div>
                    </template>

                    <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                      dcdom="ceshi99" :dcConfig="dcConfig" @dc-success="dcSuccess2" @dc-progress="dcProgressTrue = false"
                      v-if="dcConfig.key != '' && dcConfig.notify_url != '' && ismaterial"
                      v-show="salonRegData.themes[subIndex].submission.material_submission_arr.length < 9">
                      <el-button class="sbBtn" type="primary">选择文件</el-button>
                    </dc-upload>
                  </div>
                </div>
              </div>

              <div class="conTxtBox">
                <p>申报表截止：{{ salonRegData.form_submission_date }}</p>
                <p>分享材料截止：{{ salonRegData.material_submission_date }}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    

    <el-dialog title="预览" :visible.sync="dialogVisible" width="1280px" top="2vh" :before-close="handleClose">
      <div class="diaBox" v-if="dialogVisible">
        <h4 class="fz18">{{ previewFileList[previewFileIndex].name }}</h4>
        <div class="DtopBox">
          <div>
            <p class="mr30">上传时间：{{ previewFileList[previewFileIndex].create_time }}</p>
            <p>大小：{{ chengeSize(previewFileList[previewFileIndex].size) }}</p>
          </div>
          <div>
            <el-button type="primary" class="mr80" @click="downFile(previewFileList[previewFileIndex].url)">下载</el-button>
            <el-button type="primary" :disabled="previewFileIndex == 0" @click="changFilePage(1)">上一页</el-button>
            <p class="ml10 mr10">{{ previewFileIndex + 1 + '/' + previewFileList.length }}</p>
            <el-button type="primary" :disabled="previewFileIndex == previewFileList.length - 1"
              @click="changFilePage(2)">下一页</el-button>
          </div>
        </div>
        <div class="page_box">

          <!-- 文档 -->
          <div v-if="showFileType == 1" class="outside_div" element-loading-text="资源加载中"
            element-loading-background="rgba(0, 0, 0, 0.5)">
            <iframe :src="previewFileList[previewFileIndex].view_url" width="100%" height="100%" seamless
              frameborder="0"></iframe>
          </div>

          <!-- 图片 -->
          <el-image v-if="showFileType == 2" class="outside_div" :src="previewFileList[previewFileIndex].url" fit="contain"
            element-loading-text="资源加载中" element-loading-background="rgba(0, 0, 0, 0.5)">>
          </el-image>

          <!-- 视频 -->
          <div v-if="showFileType == 3" class="outside_div">
            <Ckplayer :videoSrc="previewFileList[previewFileIndex].url" :videoLoop="true" :videoAutoplay="false"></Ckplayer>
          </div>

          <audio v-if="showFileType == 4" controls="controls" controlsList="nodownload" oncontextmenu="return false"
            class="outside_yinpin">
            <source :src="previewFileList[previewFileIndex].url" type="audio/mp3" />
          </audio>

          <div v-if="showFileType == 5" style="text-align: center;" class="pt50">
            <el-button type="text">暂不支持查看该类型文件，请下载后观看</el-button>
          </div>

        </div>
      </div>
    </el-dialog>

  </div>
</template>
  
<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
import { fileSizeFilter } from "@/utils/utils.js";
export default {
  data() {
    return {
      salonRegData: {},
      tableData: [],
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      subId: "",
      subIndex: 0,
      isform: true,
      ismaterial: true,
      dialogVisible: false,
      showFileType: 1,
      newFileList: [],
      newFileIndex: "",
      previewFileList: [],
      previewFileIndex: 0
    };
  },
  watch: {
    previewFileIndex() {
      this.handler_open()
    },
  },
  created() {
    this._getAddress()
    this._SalonRegshow()
  },
  methods: {
    downFile(url) {
      window.open(url)
    },
    changFilePage(val) {
      if (val == 1) {
        this.previewFileIndex -= 1
      } else {
        this.previewFileIndex += 1
      }
    },
    yulanFile(list, index) {
      this.previewFileList = list
      this.previewFileIndex = index
      this.handler_open()
      this.dialogVisible = true
    },
    chengeSize(file_Size) {
      let newfile_Size = fileSizeFilter(file_Size);
      return newfile_Size
    },
    // 展示
    handler_open() {
      //let ext = this.newFileList[this.newFileIndex].ext;
      let ext = this.previewFileList[this.previewFileIndex].ext;
      console.log(ext)
      if (this.isTypeImage(ext)) {
        this.showFileType = 2
      } else if (this.isTypeDoc(ext)) {
        this.showFileType = 1
      } else if (this.isTypeVideo(ext)) {
        this.showFileType = 3
      } else if (this.isTypeAudio(ext)) {
        this.showFileType = 4
      } else {
        this.showFileType = 5
        // this.$message({
        //   type: 'info',
        //   message: `暂不支持查看该类型文件，请下载后观看`
        // })
      }
    },
    //判断是否图片
    isTypeImage(ext) {
      return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
    },
    // 判断是否是文档
    isTypeDoc(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].includes(ext.toLowerCase());
    },
    // 判断是否是视频
    isTypeVideo(ext) {
      return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
    },
    // 是否 音频
    isTypeAudio(ext) {
      return ["mp3", "wav", "wma", "flac", "midi", "ra", "ape", "aac", "cda"].includes(ext.toLowerCase());
    },
    handleClose() {
      this.dialogVisible = false
    },
    getokTime() {
      let timeOld1 = this.salonRegData.form_submission_date + ' 23:59:59'
      let timeNew1 = Date.parse(timeOld1);
      let nowTime = Date.now();
      if (nowTime > timeNew1) {
        this.isform = false
      }

      let timeOld2 = this.salonRegData.material_submission_date + ' 23:59:59'
      let timeNew2 = Date.parse(timeOld2);
      if (nowTime > timeNew2) {
        this.ismaterial = false
      }
    },
    getFileList() {
      let submissionObj = this.tableData[this.subIndex].submission
      if (submissionObj.form_submission_id) {
        this.newFileList[0] = submissionObj.form_submission_arr
      }
      if (submissionObj.material_submission_ids) {
        submissionObj.material_submission_arr.forEach(item => {
          this.newFileList.push(item)
        })
      }
      this.newFileIndex = 0
      console.log(this.newFileList);
    },
    _SalonRegshow() {
      msAllApi.SalonRegshow({ id: this.$route.query.id })
        .then(res => {
          let obj = res.data.data
          obj.themes.forEach((item, index) => {
            if (item.submission && item.submission.id) {
              this.subId = item.id
              this.subIndex = index
            }
          })
          this.tableData = obj.themes
          this.salonRegData = obj
          this.getokTime()
          this.newFileList = []
          this.getFileList()
        })
    },
    _submission(id) {
      this.$confirm('是否确定申报该类主题活动？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        msAllApi.submission({ theme_use_id: id }).then(res => {
          this.$message.success("申报成功")
          this._SalonRegshow()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消申报'
        });
      });
    },
    _unsubmission(id) {
      let str = "取消后可以重新选择主题进行申报！"
      if (this.salonRegData.themes[this.subIndex].submission.form_submission_id) {
        str = "取消申报后，已提交的申报表将作废！"
      }
      this.$confirm(str, '是否确定取消申报？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        msAllApi.unsubmission({ theme_use_id: id }).then(res => {
          this.$message.success("取消申报成功")
          this._SalonRegshow()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '未取消申报'
        });
      });
    },
    upFile(url) {
      window.open(url)
    },
    delFile(val, id) {
      this.$confirm('删除后可以重新上传', '确定要删除该文件吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (val == 1) {
          msAllApi.delete_form({ theme_use_id: this.subId }).then(res => {
            this.$message.success("删除成功")
            this._SalonRegshow()
          })
        } else {
          msAllApi.delete_material({ theme_use_id: this.subId, material_submission_ids: id }).then(res => {
            this.$message.success("删除成功")
            this._SalonRegshow()
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });
      let param = {
        theme_use_id: this.subId,
        form_submission_id: res.data.data.id
      }
      msAllApi.set_form(param).then(ress => {
        this.$message.success("上传成功")
        this._SalonRegshow()
      })
    },
    // 上传成功后的回调
    async dcSuccess2(data) {
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });
      let param = {
        theme_use_id: this.subId,
        material_submission_ids: res.data.data.id
      }
      msAllApi.push_material(param).then(ress => {
        this.$message.success("上传成功")
        this._SalonRegshow()
      })
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isOk = true;
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1400px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .bread {
        height: 49px;
        display: flex;
        align-items: center;
      }

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainBox {
    width: 100%;
    min-height: 830px;
    background: #fff;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;

    >h1 {
      text-align: center;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(42, 48, 55, 1);
    }

    .mainDf {
      display: flex;
      justify-content: space-between;
      height: 100%;

      >div {
        height: 100%;

        >h4 {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(42, 48, 55, 1);
          margin-bottom: 10px;

          span {
            color: #3489ff;
          }
        }

        .dfBox {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          color: rgba(255, 122, 41, 1);

          img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }

      .left {
        width: 996px;

        .tableBox {
          min-height: 620px;

          /deep/.el-table thead tr>th {
            background-color: #3489ff;
            color: #fff;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            border-color: #3489ff;
          }

          .tabh4 {
            text-align: left;
            font-size: 18px;
            font-weight: bold;
            color: #333;
          }

          .tabp {
            text-align: left;
            font-size: 14px;
            color: #333;
          }

        }
      }

      .right {
        width: 290px;

        .rightBox {
          padding: 24px;
          box-sizing: border-box;
          background: rgba(235, 241, 255, 1);
          min-height: 620px;

          .txtBox {
            >div {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: rgba(42, 48, 55, 1);

              .titleBox {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }

              .upload_div_box {
                .avatar-uploader {
                  position: relative;
                }

                .fileLis {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  >div {
                    &:nth-child(1) {
                      width: calc(100% - 70px);
                      font-size: 14px;
                      font-family: Microsoft YaHei;
                      font-weight: normal;
                      color: rgba(51, 51, 51, 1);
                    }

                    &:nth-child(2) {
                      width: 65px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      p {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        cursor: pointer;
                        font-weight: normal;

                        &:nth-child(1) {
                          color: rgba(52, 137, 255, 1);
                        }

                        &:nth-child(2) {
                          color: #f53914;
                        }
                      }
                    }
                  }
                }

                /deep/ .el-progress-circle {
                  width: 40px !important;
                  height: 40px !important;
                }

                /deep/ .el-progress {
                  left: 120px;
                }
              }

              >p {
                font-size: 14px;
                font-weight: normal;
                color: rgba(153, 153, 153, 1);
                margin-top: 10px;
              }
            }
          }

          .conTxtBox {
            width: 100%;
            height: 77px;
            background: rgba(255, 255, 255, 1);
            margin-top: 50px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 15px 30px 15px 0;
            box-sizing: border-box;

            p {
              width: 100%;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: rgba(255, 122, 41, 1);
              text-align: right;
            }
          }
        }
      }

      .sbBtn {
        text-align: center;
        background: rgba(255, 122, 41, 1);
        border-radius: 4px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border: none;
      }

      .sbBtn2 {
        background: #fff;
        border: 1px solid rgba(255, 122, 41, 1);
        color: rgba(255, 122, 41, 1);
      }

      .end {
        color: rgba(255, 122, 41, 1);
      }
    }
  }
}

.diaBox {
  .DtopBox {
    display: flex;
    justify-content: space-between;

    >div {
      display: flex;
      align-items: center;
    }
  }

  .page_box {
    margin-top: 20px;
    height: 720px;

    >div {
      height: 720px;
    }
  }
}
</style>