<template>
  <div class="public_css_module per_div_Box">
    <!-- 个人信息 -->
    <div class="user_box_div">
      <div class="box_top_1">
        <div class="box1_left">
          <img v-if="getPerInfo.photo_url" :src="getPerInfo.photo_url" alt="" />
          <img v-else src="@/assets/images/user.png" alt="" />
        </div>
        <div class="box1_right">
          <div class="right_one">
            <span>{{ username || "-" }}</span>
            <div> {{ getPerInfo.stage_name || "-" }}-{{ getPerInfo.subject_name || "-" }}
            </div>
          </div>
          <div class="right_two">
            <span>{{ getPerInfo.org_name || "-" }}</span>
            <span>{{ getPerInfo.area_name || "-" }}</span>
            <i class="el-icon-edit" @click="toPersonDetail()">点击修改资料</i>
            <div class="bnBox">
              <faceBack></faceBack>
            </div>
          </div>
        </div>
      </div>
      <div class="box_top_2">
        <div class="item_1_box">
          <div class="item_2_name">教研直播</div>
          <div class="item_item_name">
            <div class="item_2_box item_3_box" @click="toDetail3(1)">
              <span>{{ getTotalNum.live_join_count || 0 }}</span>
              <p>我参与</p>
            </div>
            <div class="item_2_box item_3_box" @click="toDetail3(2)">
              <span>{{ getTotalNum.live_create_count || 0 }}</span>
              <p>我创建</p>
            </div>
            <div class="item_2_box" @click="toDetail3(3)">
              <span>{{ getTotalNum.audit_status_live_count || 0 }}</span>
              <p>待我审核</p>
            </div>
          </div>
        </div>
        <div class="item_1_box">
          <div class="item_2_name">教研活动</div>
          <div class="item_item_name">
            <div class="item_2_box item_3_box" @click="toDetail5(1)">
              <span>{{ getTotalNum.activity_join_count || 0 }}</span>
              <p>我参与</p>
            </div>
            <div class="item_2_box item_3_box" @click="toDetail5(2)">
              <span>{{ getTotalNum.activity_create_count || 0 }}</span>
              <p>我创建</p>
            </div>
            <div class="item_2_box" @click="toDetail5(3)">
              <span>{{ getTotalNum.audit_status_activity_count || 0 }}</span>
              <p>待我审核</p>
            </div>
          </div>
        </div>
        <div class="item_1_box">
          <div class="item_2_name">教研课程</div>
          <div class="item_item_name">
            <div class="item_2_box item_3_box" @click="toDetail7(1)">
              <span>{{ getTotalNum.course_subscription_count || 0 }}</span>
              <p>我参与</p>
            </div>
            <div class="item_2_box item_3_box" @click="toDetail7(2)">
              <span>{{ getTotalNum.course_create_count || 0 }}</span>
              <p>我创建</p>
            </div>
            <div class="item_2_box" @click="toDetail7(3)">
              <span>{{ getTotalNum.audit_status_course_count || 0 }}</span>
              <p>待我审核</p>
            </div>
          </div>
        </div>
        <!-- <div class="item_1_box">
          <div class="item_2_name">教研组</div>
          <div class="item_item_name">
            <div class="item_2_box item_3_box" @click="toDetail2(1)">
              <span>{{ getTotalNum.group_join_count || 0 }}</span>
              <p>我加入</p>
            </div>
            <div class="item_2_box item_3_box" @click="toDetail2(2)">
              <span>{{ getTotalNum.group_create_count || 0 }}</span>
              <p>我创建</p>
            </div>
            <div class="item_2_box" @click="toDetail2(3)">
              <span>{{ getTotalNum.audit_status_TeachingGroup_count || 0 }}</span>
              <p>待我审核</p>
            </div>
          </div>
        </div> -->
        <div class="item_1_box">
          <div class="item_2_name">四名工作室</div>
          <div class="item_item_name">
            <div class="item_2_box item_3_box" @click="toDetail1(1)">
              <span>{{ getTotalNum.TeachingStudio_join_count || 0 }}</span>
              <p>我加入</p>
            </div>
            <div class="item_2_box item_3_box" @click="toDetail1(2)">
              <span>{{ getTotalNum.TeachingStudio_create_count || 0 }}</span>
              <p>我创建</p>
            </div>
            <div class="item_2_box" @click="toDetail1(3)">
              <span>{{ getTotalNum.audit_status_TeachingStudio_count || 0 }}</span>
              <p>待我审核</p>
            </div>
          </div>
        </div>
        <!-- <div class="item_1_box active">
          <div class="item_2_name">研讨话题</div>
          <div class="item_item_name">
            <div class="item_2_box item_3_box" @click="toTopicList(1)">
              <span>{{ getTotalNum.gambit_create_count || 0 }}</span>
              <p>我发起</p>
            </div>
            <div class="item_2_box item_3_box" @click="toTopicList(2)">
              <span>{{ getTotalNum.gambit_join_count || 0 }}</span>
              <p>我加入</p>
            </div>
            <div class="item_2_box" @click="toTopicList(3)">
              <span>{{ getTotalNum.audit_status_Gambit_count || 0 }}</span>
              <p>待我审核</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 我的教研组 -->
    <div class="list_div_box" v-if="false">
      <div class="title_box">
        <div class="title_name">我的教研组</div>
        <div class="title_btn" @click="toPage1()" v-if="listItem.length">
          更多>
        </div>
      </div>
      <div class="item_list_1_box" v-if="listItem.length">
        <div class="item_1_item" v-for="(item, index) in listItem" :key="index" @click.stop="toGroupDetailPage1(item.id)">
          <div class="item_1_top fxaic curp">
            <div class="item_1_img">
              <img :src="item.cover_url" alt="" />
              <!-- <div v-if="item.audit_status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
              <div v-if="item.audit_status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
              <div v-if="item.audit_status == 1" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
              <div v-if="item.audit_status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
              <div v-if="item.audit_status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
              <div v-if="item.audit_status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div> -->

              <div v-if="item.audit_status == 1" class="tios_div_box_1_div tios_div_box_2_div">待审核</div>
              <div v-if="item.audit_status == 4" class="tios_div_box_1_div tios_div_box_3_div">已通过</div>
              <div v-if="item.audit_status == 5" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
            </div>
            <div class="item_1_msg fx1">
              <div class="title_1_div">
                <template v-if="false">
                  <span v-if="item.audit_status == 1" class="status_1_box zhuantai_1">审核中</span>
                  <span v-if="item.audit_status == 4" class="status_1_box zhuantai_1">已通过</span>
                  <span v-if="item.audit_status == 5" class="status_1_box zhuantai_2">未通过</span>
                  <span v-if="item.audit_status == 6" class="status_1_box zhuantai_2">已过期</span>
                </template>
                <span class="name_1_div ellipsis_1">{{item.name || "-"}}</span>
              </div>
              <div class="tag_1_box fxaic">
                <template v-if="item.user_activity">
                  <div v-if="item.user_activity == 1" class="tag_item tag_2_item">我创建的</div>
                  <div v-if="item.user_activity == 2" class="tag_item tag_3_item">我参与的</div>
                </template>
                <template v-else-if="item.my_type_text">
                  <div v-if="item.my_type_text=='我创建的'" class="tag_item tag_2_item">我创建的</div>
                  <div v-if="item.my_type_text=='我参与的'" class="tag_item tag_3_item">我参与的</div>
                </template>
                <div class="tag_item">{{ item.type_text || "-" }}</div>
                <div class="tag_item tag_1_item">{{ item.stage_name || "-" }}{{ item.subject_name || "-" }}</div>
              </div>
              <div class="name_tab_box">
                <div class="name_box fxaic">
                  <div class="name_name">组长: {{ item.username || "0" }}</div>
                  <div class="name_name">成员: {{ item.user_count || "0" }}</div>
                  <div class="name_name">资源: {{ item.result_count || "0" }}</div>
                </div>
                <div class="name_box fxaic">
                  <div class="name_name">活动: {{item.activity_count || "0" }} 【<span
                      class="name_1_name">{{item.activity_open || "0" }}</span>个已开展、<span
                      class="name_2_name">{{item.activity_develop || "0" }}</span>个待审核】
                  </div>
                  <el-dropdown v-if="(item.is_go_examine_role==1)  || (item.is_update_role==1) || (item.is_delete_role==1)" trigger="hover">
                    <span class="name_xiala_tag" @click.stop="">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="item.is_go_examine_role==1" divided @click.native="teaching_to_page(item, 1)">
                        <i class="el-icon-position"></i>去审核
                      </el-dropdown-item>
                      <el-dropdown-item v-if="item.is_update_role==1" divided @click.native="editClick_edit(item.id)">
                        <i class="el-icon-edit-outline"></i>编辑
                      </el-dropdown-item>
                      <el-dropdown-item v-if="item.is_delete_role==1" divided @click.native="delClick_del(item.id)">
                        <i class="el-icon-delete"></i>删除
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.activity_data && item.activity_data.length" class="item_1_list">
            <div class="item_1_1_item" v-for="(ite_2, inde_2) in item.activity_data" :key="inde_2">
              <div class="item_1_1_div"> <i class="icon_div el-icon-s-flag"></i> </div>
              <div class="item_1_2_div ellipsis_1">
                <span class="item_1_3_span">{{ite_2.username || "-"}}</span>
                <span class="item_1_5_span">发布了活动 :</span>
                <span class="item_1_4_span">{{ite_2.name || "-"}}</span>
              </div>
            </div>
          </div>

        </div>

      </div>
      <CommonEmpty v-else hVal="200px"></CommonEmpty>
    </div>

    <!-- 我的四名工作室 -->
    <div class="list_div_box">
      <div class="title_box">
        <div class="title_name">我的四名工作室</div>
        <div class="title_btn" @click="toPage5" v-if="studioList.length > 0">
          更多>
        </div>
      </div>
      <div class="item_list_2_box" v-if="studioList.length > 0">
        <div class="item_2_item" v-for="(item, index) in studioList" :key="index" @click="toDetailPage_1(item.id)">
          <div class="item_2_user_box">
            <div class="item_2_img">
              <img :src="item.photo_url" alt="" />
              <!-- <div v-if="item.my_type_text" class="tios_2_box_div"> {{ item.my_type_text || "" }} </div> -->
            </div>
            <div class="item_2_msg fx1">
              <div class="item_2_top">
                <div class="title_2_div ellipsis_1">
                  {{ item.teaching_studio_name || "-" }}
                </div>
              </div>
              <div class="studio-count">
                <div class="studio-count-item">
                  成员: {{ item.user_count || "0" }}
                </div>
                <div class="studio-count-item">
                  访问: {{ item.view_count || "0" }}
                </div>
              </div>
              <div class="name_2_tab_box">
                <div class="name_box fxaic">
                  <div v-if="item.my_type_text=='我创建的'" class="tios_div_box_1_1_div">我创建的 </div>
                  <div v-if="item.my_type_text=='我参与的'" class="tios_div_box_1_1_div active">我参与的 </div>
                  <div class="tag_2_box fxaic">
                    <div class="tag_item tag_1_item">
                      {{ item.stage_name || "-" }}{{ item.subject_name || "-" }}
                    </div>
                  </div>
                  <el-dropdown pdown trigger="hover" v-if="(item.is_go_examine_role==1) || (item.is_admin==1)">
                    <span class="name_xiala_1_tag" @click.stop="">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="item.is_go_examine_role==1" divided @click.native="studio_to_page(item, 1)">
                        <i class="el-icon-position"></i>去审核
                      </el-dropdown-item>
                      <el-dropdown-item v-if="item.is_admin==1" @click.native="onManager(item.id)">
                        <i class="el-icon-edit-outline"></i>管理
                      </el-dropdown-item>
                      <!-- <el-dropdown-item @click.native="delClick_del(item.id)">
                        <i class="el-icon-delete"></i>删除
                      </el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="item_2_resource_box fxaic">
            <div class="item_2_resource_item active">
              研修资源: {{ item.resource_count || "0" }}
            </div>
            <div class="item_2_resource_item">
              活动与讲堂: {{ item.activity_live_course_count || "0" }}
            </div>
          </div>
        </div>
      </div>
      <CommonEmpty v-else hVal="200px"></CommonEmpty>
    </div>

    <!-- 我的直播 -->
    <div class="list_div_box active">
      <div class="title_box active_2">
        <div class="title_name">我的直播</div>
        <div class="title_btn" @click="toPage6" v-if="getList1.length > 0">
          更多>
        </div>
      </div>
      <div class="item_list_3_box" v-if="getList1.length > 0">
        <div class="listBox_div">
          <template v-for="(item, index) in getList1">
            <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="2" :currentIndex="index" :item="item"
              @editClickLive="editClickLive" @delClick_3="delClick_3"></PublicTtemDiv>
          </template>
        </div>
      </div>
      <CommonEmpty v-else hVal="200px"></CommonEmpty>
    </div>

    <!-- 我的活动 -->
    <div class="list_div_box active">
      <div class="title_box active_2">
        <div class="title_name">我的活动</div>
        <div class="title_btn" @click="toPage3" v-if="getList2.length > 0">
          更多>
        </div>
      </div>
      <div class="item_list_3_box" v-if="getList2.length > 0">
        <div class="listBox_div">
          <template v-for="(item, index) in getList2">
            <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="1" :currentIndex="index" :item="item"
              @researchActivityEditClick="researchActivityEditClick" @delClick_2="delClick_2"></PublicTtemDiv>
          </template>
        </div>
      </div>
      <CommonEmpty v-else hVal="200px"></CommonEmpty>
    </div>

    <!-- 我的课程 -->
    <div class="list_div_box active">
      <div class="title_box active_2">
        <div class="title_name">我的课程</div>
        <div class="title_btn" @click="toPage4" v-if="ListCourse.length > 0">
          更多>
        </div>
      </div>
      <div class="item_list_3_box" v-if="ListCourse.length > 0">
        <div class="listBox_div">
          <template v-for="(item, index) in ListCourse">
            <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="3" :currentIndex="index" :item="item"
              @editClick_1="editClick_1" @delClick_1="delClick_1" @pushClick="pushClick" @toDetailPage="toDetailPage"></PublicTtemDiv>
          </template>
        </div>
      </div>
      <CommonEmpty v-else hVal="200px"></CommonEmpty>
    </div>

  </div>
</template>

<script>
import { SecretLogin } from "@/api/teacherStudio";
import faceBack from "@/components/Commons/faceback.vue";
import ListTwo from "@/components/CommonList/ListTwo.vue";
import { StudioList } from "@/api/teacherStudio";
import { localGet, localSet, localRemove } from "@/utils/utils.js";
export default {
  components: {
    faceBack,
    ListTwo,
  },
  computed: {
    // 计算统计信息
    getTotalNum() {
      return this.getPersonNumList;
    },
    //计算个人信息
    getPerInfo() {
      return this.userList;
    },
  },
  data() {
    return {
      user_id: localGet("unserInfoData")?.id,
      isArr: [
        "我的教研组",
        "我的四名工作室",
        "我的教研直播",
        "我的活动",
        "我的课程",
      ],

      //是否显示模块
      isPower: [],

      total: 0,
      getTopicList: "",
      userList: "",
      username: "",
      //统计信息数量列表
      getPersonNumList: {},
      ListCourse: [],
      my_type: 1,
      listItem: [],
      getList: {
        limit: 10,
        page: 1,
        my_type: "1",
      },
      getList1: [],
      form1: {
        limit: 10,
        page: 1,
        type: 0,
      },
      getList2: [],
      formList: {
        total: 0,
        limit: 10,
        page: 1,
        search_list_type: 0,
      },
      studioList: [],
      nowUrl: "",
    };
  },
  created() {
    let nowPath = window.location.host;
    if (nowPath.indexOf("gy.teachercamp.net") != -1) {
      this.nowUrl = 1;
    }
    if (this.$route.query.idcard) {
      SecretLogin({ key: this.$route.query.idcard }).then((res) => {
        const dataObj = res.data;
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj));
        //把瓣学网登录的权限存在缓存里
        localStorage.setItem("aes_code", dataObj.aes_code);

        //将用户所有信息存进缓存
        localStorage.setItem("user_name", dataObj.username);

        //把token存在缓存里
        localStorage.setItem("token", dataObj.token);

        //把用户id存进缓存里
        localStorage.setItem("id", dataObj.id);

        this.isShow = 1;
        //把登录显示放进缓存里
        localStorage.setItem("isShow", this.isShow);
        //把用户头像放进缓存
        localStorage.setItem("photo_url", dataObj.photo_url);
        setTimeout(() => {
          this.$router.push({
            path: `/perSonIndex`,
          });
          location.reload();
        }, 500);
      });
    } else {
      //从缓存中得到用户名
      this.username = localStorage.getItem("user_name");
      this.init();
      //初始化统计信息
      this.getPersonNum();
      //得到个人信息
      this.getInfo();
      //初始化话题数据
      this.getTotal();
      //获取权限
      this.getPower();
    }
  },
  methods: {
    studio_to_page(item, num) {
      this.onManager(item.id)
    },
    // 编辑页 四名工作室
    onManager(id) {
      localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/AdminHome",
      });
    },
    // 教研 操作 跳转
    teaching_to_page(item, num) {
      switch (Number(num)) {
        case 1:
          this.toGroupDetailPage1(item.id)
          break;
        default:
          break;
      }
    },
    //跳转到编辑页
    editClick_edit(id) {
      this.$router.push({
        path: "/createResearch",
        query: { groupId: id, type: "edit" },
      });
    },
    //删除教研组
    delClick_del(id) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivity_6(id);
        })
        .catch(() => { });
    },
    //删除教研组
    async delActivity_6(id) {
      let res = await this.$axios.delete("TeacherGroup/delGroup", {
        params: { id: id },
      });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.jiaoyanzhu();
    },
    //跳转到编辑页
    editClick_1(id) {
      this.$router.push(`/updataCourseInfo?id=${id}`);
    },

    //删除 课程
    delClick_1(id, item) {
      this.$confirm("此操作将删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_1(id, item);
      }).catch(() => { });
    },
    //删除课程
    async delActivity_1(id, item) {
      try {
        let res = await this.$axios.delete("courses/delCourse", {
          params: {
            id: id,
            is_personid: item.is_personid,
          },
        });
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getCourseList();
      } catch (error) {
      }
    },
    //删除 活动
    delClick_2(id, item) {
      this.$confirm("此操作将删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delActivity_2(id, item);
        })
        .catch(() => { });
    },
    //删除活动
    async delActivity_2(id, item) {
      const res = await this.$axios.delete(`/activity/${id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.getActivity();
    },

    //删除 直播
    delClick_3(id, item) {
      this.$confirm("此操作将删除该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_3(id, item);
      }).catch(() => { });
    },
    //删除直播
    async delActivity_3(id, item) {
      let user_id = localStorage.getItem("id");
      let res = await this.$axios.delete(`/live/delLive/${id}/${user_id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.getLive();
    },

    //修改教研活动按钮
    researchActivityEditClick(id) {
      this.$router.push({
        path: "/newActivity",
        query: { id: id },
      });
    },
    //跳转到详情
    toDetailPage_1(id) {
      localStorage.setItem("studioId", id);
      this.$router.push(`/TeachLectureIndex`);
    },
    //获取权限
    async getPower() {
      let res = await this.$axios.get("webConfig/getWebConfig");
      //权限数组
      let arr = res.data.data.personal_center.children;//res.data.data.auth[5].children;
      //返回权限数组
      this.isPower = arr.map((item) => {
        let obj = {
          id: item.is_permit,
          name: item.name,
        };
        return obj;
      });
    },

    //判断是否显示

    isShowData(type) {
      //返回下标
      let idx = this.isPower.findIndex((item) => {
        return item.name == type;
      });

      if (idx != -1) {
        let isShow = this.isPower[idx].id;
        if (isShow == 1) {
          //等1显示
          return true;
        } else {
          //等0不显示
          return false;
        }
      }
    },

    //获取我的工作室
    getMyWork() {
      let user_id = localStorage.getItem("id");
      // 我的工作室
      StudioList({
        limit: 3,
        page: 1,
        user_id,
      }).then((res) => {
        this.studioList = res.data.data;
      });
    },

    //跳转备课页面
    toFocusPage(id) {
      this.$router.push(`/nextFocusCourse?id=${id}`);
    },
    //发布课程
    pushClick(id) {
      this.$axios.put(`courses/${id}/publish`).then(() => {
        this.$message({
          message: "发布成功",
          type: "success",
        });
        //初始化课程列表
        this.getCourseList();
      });
    },
    //跳转到详情页
    toDetailPage(id, tid, fid, item, studioId) {
      let is_from = "";
      localStorage.setItem("studioId", studioId);
      // group_id，其他：教研组活动详情,0：四名活动详情
      if (item.is_personid == 1) {
        is_from = 1;
      } else {
        is_from = 2;
      }
      //  group_id == 0 ? 2 : 1;
      // 常规4块
      if (tid == 1 || tid == 2 || tid == 3 || tid == 0) {
        this.$router.push(`/goclassdetail?id=${id}&is_from=${is_from}`);
      } else if (tid == 4 && fid == 1) {
        // 线下听评课
        this.$router.push(
          `/listeningcommentdetail?id=${id}&is_from=${is_from}`
        );
      } else if (tid == 4 && fid == 2) {
        // 线上听评课
        this.$router.push(
          `/listeningcommentdetail?id=${id}&is_from=${is_from}`
        );
      } else if (tid == 5) {
        //集中备课
        this.$router.push(`/preparelessons?id=${id}&is_from=${is_from}`);
      }
    },
    toPage5() {
      this.$router.push("/teacherstudio");
    },
    toPage6() {
      this.$router.push("/live");
    },
    //初始化话题数据
    async getTotal() {
      //  从缓存里得到用户id
      let id = localStorage.getItem("id");
      //  将id赋值给user_id
      this.user_id = id;
      let res = await this.$axios("/gambits/my", {
        params: {
          user_id: this.user_id,
        },
      });
      //  把数据存进getList
      this.getTopicList = res.data.data.data;
      this.total = res.data.data.total;
    },
    //得到个人信息
    async getInfo() {
      //    从缓存中得到用户id
      let userId = localStorage.getItem("id");
      let res = await this.$axios.get(`user/${userId}/little`);
      this.userList = res.data.data;
    },
    //初始化统计信息
    async getPersonNum() {
      let user_id = localStorage.getItem("id");
      let res = await this.$axios.get(`/statistics/user/${user_id}`);
      this.getPersonNumList = res.data.data;
    },
    //跳到四名工作室
    toDetail1(num) {
      let tempObj = {};
      switch (Number(num)) {
        case 1:
          tempObj['pageType'] = 3;
          break;
        case 2:
          tempObj['pageType'] = 2;
          break;
        case 3:
          tempObj['pageType'] = 4;
          break;
        default:
          break;
      }
      this.$router.push({
        path: "/teacherstudio",
        query: tempObj
      });
    },
    //跳转我加入的教研组
    toDetail2(num) {
      let tempObj = {};
      switch (Number(num)) {
        case 1:
          tempObj['pageType'] = 3;
          break;
        case 2:
          tempObj['pageType'] = 2;
          tempObj['statusType'] = 0;
          break;
        case 3:
          tempObj['pageType'] = 4;
          break;
        default:
          break;
      }
      this.$router.push({
        path: "/research",
        query: tempObj
      });
    },
    //跳转我创建的直播
    toDetail3(num) {
      let tempObj = {};
      switch (Number(num)) {
        case 1:
          tempObj['pageType'] = 1;
          break;
        case 2:
          tempObj['pageType'] = 2;
          tempObj['statusType'] = 0;
          break;
        case 3:
          tempObj['pageType'] = 3;
          break;
        default:
          break;
      }
      this.$router.push({
        path: "/live",
        query: tempObj
      });
    },
    //跳转我创建的活动
    toDetail5(num) {
      let tempObj = {};
      switch (Number(num)) {
        case 1:
          tempObj['pageType'] = 1;
          break;
        case 2:
          tempObj['pageType'] = 2;
          tempObj['statusType'] = 0;
          break;
        case 3:
          tempObj['pageType'] = 3;
          tempObj['statusType'] = 0;
          break;
        default:
          break;
      }
      this.$router.push({
        path: "/activity",
        query: tempObj
      });
    },
    //跳转我创建的课程
    toDetail7(num) {
      let tempObj = {};
      switch (Number(num)) {
        case 1:
          tempObj['pageType'] = 2;
          break;
        case 2:
          tempObj['pageType'] = 3;
          tempObj['statusType'] = 0;
          break;
        case 3:
          tempObj['pageType'] = 5;
          break;
        default:
          break;
      }
      this.$router.push({
        path: "/course",
        query: tempObj
      });
    },

    //跳转到话题
    toTopicList(num) {
      let tempObj = {};
      switch (Number(num)) {
        case 1:
          tempObj['pageType'] = 1;
          break;
        case 2:
          tempObj['pageType'] = 2;
          break;
        case 3:
          tempObj['pageType'] = 3;
          break;
        default:
          break;
      }
      this.$router.push({
        path: "/myTopic",
        query: tempObj
      });
    },
    //跳转到编辑个人信息页
    toPersonDetail() {
      this.$router.push("/personInfo");
    },
    //  跳转到教研组更多
    toPage1() {
      this.$router.push("/research?id=1");
    },
    toPage2() {
      this.$router.push("/live?vid=0");
    },
    toPage3() {
      this.$router.push("/activity?id=0");
    },
    toPage4() {
      this.$router.push("/course?id=1");
    },
    //  请求教研组获取数据
    async jiaoyanzhu() {
      let res = await this.$axios.get("/TeacherGroup/get_my_list", {
        params: {
          limit: this.getList.limit,
          page: this.getList.page,
          my_type: this.getList.my_type,
          audit_status: "",
        },
      });
      //  赋值
      this.listItem = res.data.data.data;
      //  得到数组长度
      this.listItem = this.listItem.slice(0, 4);
    },
    async init() {
      this.jiaoyanzhu();
      //教研直播
      this.getLive();
      //教研活动
      this.getActivity();
      //初始化课程
      this.getCourseList();
      //获取工作室
      this.getMyWork();
    },
    //直播
    async getLive() {
      let res = await this.$axios.get("/live/get_my_list", {
        params: {
          limit: this.form1.limit,
          page: this.form1.page,
          type: this.form1.type,
          audit_status: "",
        },
      });
      this.getList1 = res.data.data.data;
      this.getList1 = this.getList1.slice(0, 6);
    },
    //活动
    async getActivity() {
      let res = await this.$axios.get("/myActivities", this.formList);
      this.getList2 = res.data.data.data;
      this.getList2 = this.getList2.slice(0, 6);
    },
    async getCourseList() {
      // 缓存里得到用户id
      let id = localStorage.getItem("id");
      let res = await this.$axios.get("/courses/get_my_list", {
        params: {
          user_id: id,
          my_type: this.my_type,
          audit_status: "",
        },
      });

      this.ListCourse = res.data.data.data;
      this.ListCourse = this.ListCourse.slice(0, 6);
    },
    //跳转到教研组详情
    toGroupDetailPage1(id) {
      this.$router.push(`/groupdetailshouye?groupId=${id}`);
    },
    //跳转直播详情页
    toLiveDetail(id, item) {
      if (item.is_personid == 1) {
        // 教研组直播详情
        this.$router.push(`/livedetail?liveid=${id}`);
      } else {
        // 四名直播详情
        localStorage.setItem("studioId", item.teaching_studio_id);
        this.$router.push(`/AdminLiveDetail?lid=${id}`);
      }
    },
    //跳转到编辑页
    editClickLive(id) {
      // 从路由原信息得到直播id传过去
      this.$router.push({
        path: "/newLive",
        query: {
          id: id,
        },
      });
    },
    //跳转到详情页
    toDetailPage1(id) {
      this.$router.push(`/goclassdetail?id=${id}`);
    },
    //教研活动详情
    activityDetails(id, is_personid) {
      this.$router.push({
        path: "/basicinfo",
        query: { activityId: id, is_from: is_personid != 1 ? 2 : 1 },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tios_div_box_1_1_div {
  width: 70px;
  height: 24px;
  background-color: #3489ff;
  font-size: 12px;
  line-height: 24px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  &.active {
    background-color: #52b876;
  }
}
.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 90px;
  height: 62px;
  top: -20px;
  right: -36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  transform: rotate(45deg);
  background-color: #ff8201;
  color: #fff;
  &.tios_div_box_2_div {
    background-color: #0080ff;
  }
  &.tios_div_box_3_div {
    background-color: #ff8201;
  }
  &.tios_div_box_4_div {
    background-color: #ff0607;
  }
  &.tios_div_box_5_div {
    background-color: #0080ff;
  }
  &.tios_div_box_6_div {
    background-color: #ff0607;
  }
  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }
}
.per_div_Box {
  width: 100%;

  .listBox_div {
    padding-top: 18px;
    padding-left: 34px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    .list_item {
      width: 356px;
    }
    /deep/ .list_item {
      width: 356px;
    }
  }
  > div {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 20px;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 30px;
  }

  .user_box_div {
    height: 370px;
    background-image: url("~@/assets/imgFIle_3/PersonalCenter_bg.png");
    background-repeat: no-repeat;
    background-size: 1168px 241px;
    background-position: 2px 2px;
    padding-top: 2px;
    padding-bottom: 2px;

    div,
    span,
    p {
      box-sizing: border-box;
    }

    .box_top_1 {
      height: 68px;
      margin-top: 22px;
      margin-bottom: 16px;
      display: flex;

      .box1_left {
        width: 68px;
        height: 68px;
        margin-right: 20px;
        border-radius: 4px;

        > img {
          box-sizing: border-box;
          border: 2px solid #fff;
          width: 68px;
          height: 68px;
          border-radius: 4px;
        }
      }

      .box1_right {
        flex: 1;
        height: 68px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .right_one {
          display: flex;
          align-items: center;

          > span {
            font-size: 16px;
            line-height: 16px;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
          }

          > div {
            margin-left: 10px;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            color: rgba(255, 255, 255, 1);
            background: rgba(52, 137, 254, 1);
            border-radius: 0 10px 10px 10px;
            padding: 0 6px;
          }
        }

        .right_two {
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #666;

          > span,
          i {
            font-size: 14px;
            line-height: 14px;
            margin-right: 26px;
            color: #666;
          }

          > I {
            cursor: pointer;
          }
        }
      }

      .bnBox {
        margin-left: auto;

        .ts {
          padding: 0;
          font-size: 14px;
          line-height: 14px;
          color: #666;
        }
      }
    }

    .box_top_2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item_1_box {
        width: 360px;
        height: 110px;
        margin-bottom: 12px;
        border-radius: 6px;
        border: 2px solid #fff;
        box-sizing: border-box;
        overflow: hidden;
        // margin-right: 16px;
        // &.active {
        //   margin-right: 0px;
        // }
      }

      .item_2_name {
        font-size: 14px;
        line-height: 36px;
        height: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-align: center;
      }

      .item_item_name {
        display: flex;
        width: 328px;
        height: 62px;
        background-color: #fff;
        border-radius: 8px;
        margin: 0 auto;
        padding-top: 15px;
        padding-bottom: 15px;

        .item_2_box {
          flex: 1;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          > span {
            font-size: 16px;
            line-height: 16px;
            font-weight: bold;
          }

          > p {
            font-size: 14px;
            line-height: 14px;
            font-weight: normal;
            margin-top: 8px;
            color: #333;
          }
        }
        .item_2_2_box {
          cursor: default !important;
        }

        .item_3_box {
          border-right: 1px dashed #ebebeb;
        }
      }

      .item_1_box:nth-child(1) {
        background-color: #baf0e4;

        .item_2_name,
        span {
          color: #258973;
        }
      }

      .item_1_box:nth-child(2) {
        background-color: #c5c6fa;

        .item_2_name,
        span {
          color: #6163d6;
        }
      }

      .item_1_box:nth-child(3) {
        background-color: #f8ceef;

        .item_2_name,
        span {
          color: #d46bbe;
        }
      }

      .item_1_box:nth-child(4) {
        background-color: #f8cece;

        .item_2_name,
        span {
          color: #cf6f6f;
        }
      }

      .item_1_box:nth-child(5) {
        background-color: #f8e5ce;

        .item_2_name,
        span {
          color: #ce9653;
        }
      }

      .item_1_box:nth-child(6) {
        background-color: #cee9f8;

        .item_2_name,
        span {
          color: #3489ff;
        }
      }
    }
  }

  .list_div_box {
    &.active {
      padding: 0;
    }

    .title_box {
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;

      &.active {
        padding-top: 20px;
      }

      &.active_2 {
        padding-left: 32px;
        padding-right: 32px;
      }

      .title_name {
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
        color: #333;
      }

      .title_btn {
        font-size: 15px;
        line-height: 15px;
        color: #7f889c;
        cursor: pointer;
      }
    }
  }

  .item_list_1_box {
    display: flex;
    flex-wrap: wrap;
    .item_1_item {
      width: 545px;
      min-height: 142px;
      // height: 214px;
      border-radius: 6px;
      padding: 15px 16px;
      box-sizing: border-box;
      margin-right: 18px;
      margin-top: 20px;
      background-color: #f1f6f9;
      border: 1px solid #f1f6f9;
      position: relative;
      &:hover {
        background: rgba(241, 246, 249, 1);
        border: 1px solid rgba(170, 201, 219, 1);
        box-shadow: 0 2px 20px 0 rgba(89, 142, 174, 0.31);
      }
      &:nth-child(even) {
        margin-right: 0;
      }
      .item_1_img {
        width: 168px;
        height: 112px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 16px;
        position: relative;
        > img {
          width: 168px;
          height: 112px;
          border-radius: 6px;
        }
        .tios_2_box_div {
          bottom: 0;
          position: absolute;
          text-align: center;
          height: 24px;
          width: 100%;
          background: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          line-height: 24px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }
      }
      .title_1_div {
        display: flex;
        .status_1_box {
          display: inline-block;
          width: 56px;
          height: 22px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          margin-right: 10px;
          background: #ff8201;
          color: #fff;
          &.zhuantai_1 {
            background-color: #3489ff;
          }
          &.zhuantai_2 {
            background-color: #ff0607;
          }
        }
        .name_1_div {
          flex: 1;
          font-size: 16px;
          line-height: 22px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }
      }
      .item_1_msg {
        height: 112px;
        display: flex;
        flex-direction: column;
      }
      .item_1_top {
        width: 100%;
      }
      .item_1_list {
        width: 100%;
        padding-top: 10px;
        .item_1_1_item {
          display: flex;
          align-items: center;
          height: 16px;
          margin-top: 10px;
          .item_1_1_div {
            width: 16px;
            height: 16px;
            background: rgba(52, 137, 255, 1);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon_div {
              color: #fff;
              font-size: 12px;
              transform: scale(0.8);
            }
          }
          .item_1_2_div {
            flex: 1;
            font-size: 14px;
            line-height: 16px;
            font-weight: normal;
            color: #3489ff;
          }
          .item_1_3_span {
            margin-left: 6px;
            color: #333333;
          }
          .item_1_5_span {
            margin-left: 6px;
            color: #999999;
          }
          .item_1_4_span {
            margin-left: 6px;
          }
        }
      }
      .tag_1_box {
        margin-top: 10px;
        .tag_item {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          height: 24px;
          line-height: 24px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(4, 147, 255, 1);
          padding: 0 8px;
          border: 1px solid #0493ff;
          color: #0493ff;
          margin-right: 10px;
        }
        .tag_1_item {
          border: 1px solid #51b875;
          color: #51b875;
        }
        .tag_2_item {
          background-color: #3489fe;
          color: #fff;
        }
        .tag_3_item {
          background-color: #52b876;
          border: 1px solid #51b875;
          color: #fff;
        }
      }
      .name_tab_box {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        .name_box {
          .name_name {
            margin-top: 8px;
            flex: 1;
            font-size: 14px;
            line-height: 14px;
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
            .name_1_name {
              font-weight: bold;
              color: #52b876;
            }
            .name_2_name {
              font-weight: bold;
              color: #fe7019;
            }
          }
          .el-dropdown {
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .name_xiala_tag {
            width: 50px;
            text-align: right;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .item_list_2_box {
    display: flex;
    flex-wrap: wrap;

    .item_2_item {
      width: 356px;
      height: 165px;
      border-radius: 8px;
      box-sizing: border-box;
      margin-right: 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      background-color: #f1f6f9;
      border: 1px solid #f1f6f9;

      &:hover {
        background: rgba(241, 246, 249, 1);
        border: 1px solid rgba(170, 201, 219, 1);
        box-shadow: 0 2px 20px 0 rgba(89, 142, 174, 0.31);
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      .item_2_user_box {
        display: flex;
        align-items: center;
        padding: 20px;
        padding-right: 10px;
        box-sizing: border-box;
      }

      .item_2_img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 16px;
        position: relative;
        overflow: hidden;

        > img {
          width: 80px;
          height: 80px;
          border-radius: 8px;
        }

        .tios_2_box_div {
          bottom: 0;
          position: absolute;
          text-align: center;
          height: 24px;
          width: 100%;
          background: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          line-height: 24px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }
      }

      .title_2_div {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        flex: 1;
      }

      .item_2_msg {
        height: 80px;
        display: flex;
        flex-direction: column;
      }

      .item_2_top {
        display: flex;
      }

      .tag_2_box {
        .tag_item {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          height: 24px;
          line-height: 24px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(4, 147, 255, 1);
          padding: 0 8px;
          border: 1px solid #0493ff;
          color: #0493ff;
        }

        .tag_1_item {
          border: 1px solid #51b875;
          color: #51b875;
          margin-left: 10px;
        }
      }

      .studio-count {
        display: flex;
        margin-top: 5px;
        .studio-count-item {
          font-size: 14px;
          color: rgba(102,102,102,1);
          margin-right: 16px;
        }
      }

      .name_2_tab_box {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        position: relative;

        .name_box {
          .name_name {
            margin-top: 8px;
            width: 50%;
            font-size: 14px;
            line-height: 14px;
            font-weight: normal;
            color: rgba(153, 153, 153, 1);
          }
        }

        .el-dropdown {
          position: absolute;
          bottom: -10px;
          right: -10px;
        }

        .name_xiala_1_tag {
          width: 50px;
          text-align: right;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .item_2_resource_box {
        width: 100%;
        height: 40px;
        background-color: #e2edf3;

        .item_2_resource_item {
          width: 50%;
          font-size: 16px;
          line-height: 40px;
          font-weight: normal;
          color: rgba(17, 26, 56, 1);
          text-align: center;

          &.active {
            border-right: 1px solid #fff;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .item_list_3_box {
    .item_box_3_div:nth-last-of-type(1) {
      .item_3_item {
        border: none !important;
      }
    }
  }
}
</style>