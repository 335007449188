<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth2 pb30">
      <div class="public-width">
        <div class="public-width">
          <div class="mainBox6 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aserviceImg/pingtai.png" alt="">
            </div>
            <div class="pingtai">
              <div class="pingtaiTop">
                <div>
                  <div v-for="(item, index) in pingtaiList" :key="index" @click="changePingtai(item.num)" :class="scrollHeight < 3444 && index == 0 ? 'activeS' :
                    scrollHeight >= 3444 && scrollHeight < 5089 && index == 1 ? 'activeS' :
                      scrollHeight >= 5089 && scrollHeight < 8342 && index == 2 ? 'activeS' :
                        scrollHeight >= 8342 && index == 3 ? 'activeS' : ''">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="pingtaiBom">
                <h4>一、四名网络工作室</h4>
                <div class="df dfb">
                  <div class="bomleft mt30">
                    <div class="pbox">
                      <p class="mb10">
                        提供四名网络工作室平台建设服务，该平台集教研、学习、资源、管理、考核于一体， 满足教师线上教研、学习研讨、活动管理等多重需求，促进教师专业发展。
                      </p>
                      <p>
                        平台部分主要包括四名工作室平台、四名绩效考核系统、四名公众号等的建设， 并与各大权威流量媒体合作实现四名原创及活动报道等优质内容的广泛传播。
                      </p>
                    </div>

                    <div class="dfbox">
                      <div>四名大课堂</div>
                      <div>资源共建共享</div>
                      <div>学员培养培训</div>
                      <div>线上教研活动</div>
                      <div>工作室管理</div>
                      <div>工作室考核</div>
                    </div>
                  </div>
                  <div class="bomright">
                    <img src="@/assets/banner_img/aserviceImg/pingtaibg.png" alt="">
                  </div>
                </div>
              </div>
            </div>

            <div class="fuwuBox">
              <h4 class="mb40">四名工作室服务内容：</h4>
              <div class="fuwuul2">
                <div class="fuwuli2">
                  <h5>四名大课堂直播</h5>
                  <p> 提供直播课、听课、讲座、听评课等，协助四名帮助培养对
                    象剖析教育教学、专业发展等方面存在的主要问题，指导开展课
                    题研究.
                  </p>
                </div>
                <div class="fuwuli2">
                  <h5>线上教研活动</h5>
                  <p> 组织开展观课议课、集体备课、同课异构、微课题研究、
                    主题研究、材料研读、案例分析、校本教研、成果展示等多
                    种形式的活动。
                  </p>
                </div>
                <div class="fuwuli2">
                  <h5>资源共建共享</h5>
                  <p> 通过名教师工作室网络空间发布生成性资源（包括课件、案
                    例、教学方法、教学总结、学习心得、教学改革探讨等文字或图
                    形资源），完成学科微课资源共享。
                  </p>
                </div>
                <div class="fuwuli2">
                  <h5>工作室管理及评估</h5>
                  <p> 展示工作室成员数、资源数、文章数、研修活动数、名
                    师课堂数等统计数据、实现管理员动态管理，为工作室建设
                    评估提供支持。
                  </p>
                </div>
              </div>
            </div>

            <div class="teseBox">
              <h4 class="mb40">平台特色：</h4>
              <div class="teses1">
                <div>
                  <h5>工作室管理员多角色权限设置功能</h5>
                  <p>灵活应对不同地区的工作室对角色及角色分
                    工的不同要求，配置相应的权限，共同打造
                    科研空间。
                  </p>
                </div>
                <div>
                  <h5>线上线下相结合活动的形式</h5>
                  <p>平台应能响应政策要求，实现线下活动或线
                    上线下相结合活动的开展，以便完整地记录
                    工作室成员的所有研修活动。
                    <br>
                    <span>活动涵盖： </span>
                    <br>
                    <span>四名课堂、专家讲座、主题研修、送教下乡等。</span>
                  </p>
                </div>
                <div>
                  <h5>资源审核和推荐功能</h5>
                  <p>保证工作室资源质量，设置资源审核功能，由
                    工作室管理员审核成员上传的资源，审核通过
                    的资源可以展示在四名工作室中；增加资源推
                    荐功能，由四名推荐工作室中特色、优质资源。
                  </p>
                </div>
                <div>
                  <h5>工作室数据统计分析模块</h5>
                  <p>实时汇总和统计工作室成员数、资源数、文
                    章数、教研活动数、四名课堂数、研究成果
                    数等相关数据，实现工作室动态管理。
                  </p>
                </div>
                <div>
                  <h5>多元化教研工具</h5>
                  <p>满足不同教研活动开展，提供资源浏览、资源上传、讨论、问卷、投票、协作文档、
                    在线直播、教研会议 8 种教研工具；针对不同的活动类型，提供相应的教研模板。
                  </p>
                </div>
                <div>
                  <h5>四名大讲堂</h5>
                  <p>可开展四名在线直播课，上传和展示四名录
                    播课，囊括上课、说课、讲课、听评课，集
                    中备课等多种形式。通过优秀课例的共享和
                    展示，发挥四名示范引领作用。
                  </p>
                </div>
              </div>
              <div class="teses1 teses2">
                <div>
                  <h5>工作室个性化设置功能模块</h5>
                  <p>为四名工作室提供多种个性化设置，满足教师对个性化工作室的建设需求。
                  </p>
                </div>
                <div>
                  <h5>优秀研究成果展示</h5>
                  <p>开设成果展示模块，可上传展示富有特色的研究成果（如正式刊物公开发表的论文、
                    教学成果奖励和公开出版著作等），提高工作室的吸引力和影响力。
                  </p>
                </div>
                <div>
                  <h5>过程性数据汇聚，助力工作室绩效考核</h5>
                  <p>为各地区四名工作室提供年度考核、周期考核的全流程信息化管理，通过日常活动及数据的积累汇聚，
                    将过程性考核与结果性数据相结合，使考核更加科学高效。
                  </p>
                </div>
                <div>
                  <h5>优质内容，推送至公众号及各大权威媒体</h5>
                  <p>为各四名工作室提供开放接口，工作室空间的原创内容经过简单汇编成专题素材，即可一键推送至平台微信公众号、
                    工作室自营公众号，以及各大权威媒体。
                  </p>
                </div>
              </div>
            </div>

            <div class="ruzhuBox">
              <h4 class="mb30">入驻四名示例：</h4>
              <div class="imgBox">
                <img src="@/assets/banner_img/aservice6/ruzhuImg.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgWidth1 pt30 pb40">
      <div class="public-width">
        <div class="mainBox2">
          <div class="pingjiaBox">
            <h4>二、课堂评价系统</h4>
            <p class="ptext mt20 mb30"><span>瓣学网IPAS（Instructional Process Analysis System）</span>
              课堂评价系统是适用于信息化教学环境中的教学过程分析与管理系统，是一款轻量级的微信小程序。
              目前，该系统集成了两种教学分析工具，一个是基于TBAS ( Teaching Behavior Analysis System )的分析工具，
              一个是基于S-T分析法的分析工具。这些分析工具都是基于既定的教学行为分类编码规则，
              通过特定频率的教学行为采样，自动生成课堂分析报告，助力教师反思教学实践，促进教师专业发展。
            </p>
            <div class="fenxiBox">
              <div>
                <div class="imgBox">
                  <img src="@/assets/banner_img/aservice6/fenxi1.png" alt="">
                </div>
                <div class="txtBox mt32">
                  <span class="span1">TBAS分析：</span>基于教学活动的课堂教学行为分析系统，可对<span class="span2">师生活动、 言语行为、媒体使用</span>等情况进行分析。
                </div>
              </div>
              <div>
                <div class="imgBox">
                  <img src="@/assets/banner_img/aservice6/fenxi2.png" alt="">
                </div>
                <div class="txtBox mt32">
                  <span class="span1">S-T分析：</span>一种能够直观表现<span class="span2">教学性格</span>的教学分析方法，可<span
                    class="span2">分析教师活动情况、对话与互动情况</span>，以及<span class="span2">课堂教学</span>模式。
                </div>
              </div>
            </div>
            <div class="keBox mt30">
              <div>现场课</div>
              <div>录制课</div>
              <div>单人评课</div>
              <div>多人评课</div>
            </div>

            <p class="ptext2 mt40 mb30">操作分如下几步走，简单易用：</p>

            <div class="keimgBox">
              <img src="@/assets/banner_img/aservice6/pingjiaimg.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth2 pt30 pb40">
      <div class="public-width">
        <div class="mainBox6 mainBox3">
          <div class="pingtai">
            <div class="pingtaiBom">
              <h4>三、教师专业发展云平台</h4>
              <div class="df dfb">
                <div class="bomleft mt30">
                  <div class="pbox">
                    <p>
                      <span>教师专业发展云平台</span>，以师资队伍管理、教师专业发展为切入点，综合运用云计算、大数据、移动互联网、
                      泛在网等技术，通过教师云服务平台基础设施及平台应用的建设，实现师资队伍建设管理业务、
                      教师专业发展各业务数据的动态汇聚和流转，通过“教、研、训、管、评”多维度数据的采集、智能分析和预测，
                      为师资队伍管理提供决策依据，为教师专业成长提供发展方向及指导性建议，推动区域优质师资队伍的建设，
                      达到促进教育整体发展的最终目标。
                    </p>
                  </div>
                  <div class="dfbox dfbox22">
                    <div>专业成长档案</div>
                    <div>在线学习平台</div>
                    <div>科研课题管理</div>
                    <div>教师职称评审</div>
                    <div>教师评优优先</div>
                    <div>校(园)长绩效考核</div>
                    <div>培训项目管理</div>
                  </div>
                </div>
                <div class="bomright">
                  <img src="@/assets/banner_img/aserviceImg/pingtaibg4.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="imgBox3 mt20">
            <img src="@/assets/banner_img/aservice6/chengzhang.png" alt="">
          </div>

          <div class="teseBox mt30">
            <h4 class="mb30">平台特色：</h4>
            <div class="teses3">
              <div>
                <h5>应用系统丰富</h5>
                <p>“教、研、训、管、评”各维度应用全覆盖，细化到教师成长和师资队伍的方方面面，能提供满足各用户需求的应用服务。
                </p>
              </div>
              <div>
                <h5>师资管理便捷</h5>
                <p>以“教师专业成长档案”为核心，汇聚了教师各方面的数据及各类应用，管理者只需登录一个平台即可实现数据的统一管理及应用的使用，有效提高了师资队伍管理的信息化。
                </p>
              </div>
              <div>
                <h5>数据应用灵活</h5>
                <p>用系统间的数据互联互通，有效发挥数据的价值，同时，简化了教师管理过程的业务流程。
                </p>
              </div>
              <div>
                <h5>数据采集全面</h5>
                <p>一方面通过各应用系统采集教师“教、研、训、评”等各方面的数据，另一方面通过“教师专业成长档案”采集各应用系统采集不到的教师数据，兼顾过程性数据和结果性数据，及异构数据的采集。
                </p>
              </div>
              <div>
                <h5>数据服务定制</h5>
                <p>在平台已提供的数据分析及报告基础上，可根据客户的需求提供多种分析服务，丰富数据服务内容。
                </p>
              </div>
            </div>
          </div>

          <div class="teseBox mt10">
            <h4 class="mb30">数据分析与报告服务：</h4>
            <div class="teses4">
              <div>
                <h5>应用系统丰富</h5>
                <div class="t4div1">
                  <p>研训应用</p>
                  <p>管理应用</p>
                  <p>教学应用</p>
                  <p>评价应用</p>
                </div>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice6/right1.png" alt="">
              </div>
              <div>
                <h5>数据应用灵活</h5>
                <div class="t4div2">
                  <div>
                    <h4>基础数据分析</h4>
                    <div>
                      <p>职称统计</p>
                      <p>待遇统计</p>
                      <p>工龄统计</p>
                      <p>学历统计</p>
                      <p>专业资格统计</p>
                      <p>......</p>
                    </div>
                  </div>
                  <div>
                    <h4>专业数据分析</h4>
                    <div>
                      <p>资源建设统计</p>
                      <p>教学工作统计</p>
                      <p>学习成果统计</p>
                      <p>科研成果统计</p>
                      <p>教研活动统计</p>
                      <p>......</p>
                    </div>
                  </div>
                  <div>
                    <h4>诚信数据分析</h4>
                    <div>
                      <p>教学奖励统计</p>
                      <p>职称认证统计</p>
                      <p>年度考核统计</p>
                      <p>绩效考核统计</p>
                      <p>师德考核统计</p>
                      <p>......</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice6/right2.png" alt="">
              </div>
              <div>
                <h5>应用系统丰富</h5>
                <div class="t4div1">
                  <p>师资队伍分析报告</p>
                  <p>教师专业发展分析报告</p>
                  <p>教师教学质量分析报告</p>
                  <p>教师职业能力分析报告</p>
                  <p>教学发展分析报告</p>
                  <p>......</p>
                </div>
              </div>
            </div>
          </div>

          <div class="yingyBox mt30">
            <h4 class="mb30">应用介绍：</h4>
            <div class="yingyUl">
              <div class="yingyLi">
                <h5>教师专业成长档案</h5>
                <p class="mt20 mb20">能动态、真实反应教师专业知识水平、业
                  务水平、教学科研能力及工作实践活动发
                  展过程和发展状况的专业信息管理系统。
                </p>
                <div class="boxUl">
                  <div class="boxLi">
                    <div></div>教师档案管理
                  </div>
                  <div class="boxLi">
                    <div></div>教师诚信管理
                  </div>
                  <div class="boxLi">
                    <div></div>成长报告管理
                  </div>
                  <div class="boxLi">
                    <div></div>应用中心
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/01.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>教师职称评审系统</h5>
                <p class="mt20 mb20">实现教师职称评审资料的上传和管理，
                  职称评审专家管理、职称评审管理与开
                  展等功能。
                </p>
                <div class="boxUl">
                  <div class="boxLi boxLi2">
                    <div></div>职称申报
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>单位审核
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>专家指派
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>评审管理
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>在线答辩
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>表决管理
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/02.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>四名网络工作室</h5>
                <p class="mt20 mb20">采用业务研讨、能力建设、继续教育、专
                  家讲座、导师指导、教学展示和学术论坛
                  等方式，实现教研教学的全面提升。
                </p>
                <div class="boxUl">
                  <div class="boxLi boxLi2">
                    <div></div>专业引领
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>研修资源
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>专题研修
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>四名课堂
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>案例赏析
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>四名导教
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/03.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>校(园)长绩效考核系统</h5>
                <p class="mt20 mb20">通过简单的“数字化”打分考评方式，科学、客观地对校(园)长进行综合评价的系统。
                  解决考核中的单方便述职报告、纸质文件统计不全、考核不透明等问题。
                </p>
                <div class="boxUl">
                  <div class="boxLi boxLi2">
                    <div></div>高中校长
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>小初、校长
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>园长
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>教职工
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>教育局部门
                  </div>
                  <div class="boxLi boxLi2">
                    <div></div>党组
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/04.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>评优评先系统</h5>
                <p class="mt20 mb20">优秀教师评比系统是将传统的评优流程迁移到电子设备及互联网上，
                  系统能根据评优条件和标准，自动处理完成一些传统手工评选工作，实现降本提效。
                </p>
                <div class="boxUl">
                  <div class="boxLi">
                    <div></div>个人信息管理
                  </div>
                  <div class="boxLi">
                    <div></div>评比管理
                  </div>
                  <div class="boxLi">
                    <div></div>报表管理
                  </div>
                  <div class="boxLi">
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/05.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>培训项目管理系统</h5>
                <p class="mt20 mb20">以信息化带动教师现代化，逐步构建开放灵活的教师终生学习体系，推进教师继续教育，
                  促进建成管理统一、结构合理、技术先进、功能完善的教师培训信息数据库。
                </p>
                <div class="boxUl">
                  <div class="boxLi boxLi3">
                    <div></div>需求调研
                  </div>
                  <div class="boxLi boxLi3">
                    <div></div>报名缴费
                  </div>
                  <div class="boxLi boxLi3">
                    <div></div>项目管理
                  </div>
                  <div class="boxLi boxLi3">
                    <div></div>学时证书管理
                  </div>
                  <div class="boxLi boxLi3">
                    <div></div>专家库
                  </div>
                  <div class="boxLi boxLi3">
                    <div></div>机构管理
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/06.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>在线学习平台</h5>
                <p class="mt15 mb15">集培训、资源和服务于—体的学习平台，提供丰富的课程资源，具备研修工作坊、
                  学校社区和区域社区等研修功能，适应教师、校长(园长）等学员的学习研修需要。
                </p>
                <div class="boxUl">
                  <div class="boxLi">
                    <div></div>培训课程系统
                  </div>
                  <div class="boxLi">
                    <div></div>在线培训系统
                  </div>
                  <div class="boxLi">
                    <div></div>网络研修系统
                  </div>
                  <div class="boxLi">
                    <div></div>教职工
                  </div>
                  <div class="boxLi">
                    <div></div>教育局部门
                  </div>
                  <div class="boxLi">
                    <div></div>党组
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/07.png" alt="">
                </div>
              </div>
              <div class="yingyLi">
                <h5>基础教育教科平台</h5>
                <p class="mt15 mb15">为用户构建集在线教研、教研成果分享、教研管理等功能一体的系统平台，
                  突破传统教研受时间、空间、经费等因素的限制，缩小城乡教育差距，促进教育均衡发展。
                </p>
                <div class="boxUl">
                  <div class="boxLi">
                    <div></div>教研管理
                  </div>
                  <div class="boxLi">
                    <div></div>教研活动开展
                  </div>
                  <div class="boxLi">
                    <div></div>教研科研指导
                  </div>
                  <div class="boxLi">
                    <div></div>科研管理
                  </div>
                  <div class="boxLi">
                    <div></div>活动工具
                  </div>
                  <div class="boxLi">
                    <div></div>科研活动开展
                  </div>
                </div>
                <div class="aboutyy">
                  <img src="@/assets/banner_img/aservice6/08.png" alt="">
                </div>
              </div>
              <div class="yingyLi2">
                <img src="@/assets/banner_img/aservice6/09.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth1 pt30 pb40">
      <div class="public-width">
        <div class="mainBox6 mainBox4">
          <div class="pingtai">
            <div class="pingtaiBom" style="background: none;">
              <h4>四、职称评审系统</h4>
              <div class="df dfb">
                <div class="bomleft mt30">
                  <div class="pbox">
                    <p>
                      <span>职称评审系统</span>主要实现教师职称专业资格评审的资料上传与审核、
                      评审专家入库与抽取遴选、在线评审、在线答辩、评审表决等功能，以及评审过程各环节的数据跟踪与监控，
                      解决传统职称评审工作量大、透明度差、过程繁杂、效率低等问题，
                      有效地提高评审工作效率、提升评审水准，实现专业资格职称评审工作的公开、公正、公平。
                    </p>
                  </div>
                  <div class="dfbox dfbox2">
                    <div>评审项目管理</div>
                    <div>教师材料申报</div>
                    <div>评委指派</div>
                    <div>在线答辩、评委表决</div>
                    <div>常态化监督审查</div>
                  </div>
                </div>
                <div class="bomright">
                  <img src="@/assets/banner_img/aserviceImg/pingtaibg4.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div class="canpinBox">
            <h4 class="mb30">功能介绍:</h4>
            <div class="chanpinUl">
              <div>
                <h1>评审前</h1>
                <div>
                  <p>评审项目管理</p>
                  <p>教师材料申报</p>
                  <p>学校、县市局审核</p>
                  <p>专家遴选</p>
                </div>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice6/rightBtn.png" alt="">
              </div>
              <div>
                <h1>评审中</h1>
                <div>
                  <p>评委指派</p>
                  <p>资料评审、视频评审</p>
                  <p>在线答辩</p>
                  <p>学科表决、大评委表决</p>
                  <p>结果审核</p>
                </div>
              </div>
              <div>
                <img src="@/assets/banner_img/aservice6/rightBtn.png" alt="">
              </div>
              <div>
                <h1>评审后</h1>
                <div>
                  <p>结果公示</p>
                  <p>数据统计</p>
                  <p>数据归档与封存</p>
                  <p>常态化监督审查</p>
                </div>
              </div>
            </div>
          </div>

          <div class="teSeBox mt20">
            <h4 class="mb30">产品特色:</h4>
            <div class="teSeUl">
              <div>
                <h4>业务一屏化</h4>
                <p>评审过程统一面板、数据实时跟踪。</p>
              </div>
              <div>
                <h4>系统人性化设计</h4>
                <p>佐证资料审阅舒适、评审交互直接高效。</p>
              </div>
              <div>
                <h4>智能指派</h4>
                <p>根据评审约束条件，自动分配评审工作量。</p>
              </div>
              <div>
                <h4>智能评审</h4>
                <p>根据评审标准自动评判，辅助专家评审参考。</p>
              </div>
              <div>
                <h4>管理可溯</h4>
                <p>评审资料完整保密，支撑过程性审查。</p>
              </div>

              <div>
                <h4>管理可溯</h4>
                <div class="guanliBox mt40">
                  <div>
                    <p>评审人数：<span>1154人</span></p>
                    <div class="about">2020</div>
                  </div>
                  <div>
                    <p>评审人数：<span>1187人</span></p>
                    <div class="about">2019</div>
                  </div>
                  <div>
                    <p>评审人数：<span>907人</span></p>
                    <div class="about">2018</div>
                  </div>
                  <div>
                    <p>评审人数：<span>755人</span></p>
                    <div class="about">2017</div>
                  </div>
                  <div>
                    <p>评审人数：<span>601人</span></p>
                    <div class="about">2016</div>
                  </div>
                  <div>
                    <p>评审人数：<span>2427人</span></p>
                    <div class="about">2015</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pingtaiList: [
        { name: "四名网络工作室", num: 0 },
        { name: "课堂评价系统", num: 3444 },
        { name: "教师专业发展云平台", num: 5089 },
        { name: "职称评审系统", num: 8342 },
      ],
      scrollHeight: 0, //当前滚轮高度
    };
  },
  created() {
  },
  methods: {
    changePingtai(val) {
      document.documentElement.scrollTop = val
      this.scrollHeight = val
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      this.scrollHeight = scrollTop;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.mainBox6 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .pingtai {
    margin-top: 30px;
    position: relative;

    .pingtaiTop {
      position: fixed;
      width: 1280px;
      top: 60vh;

      >div {
        display: inline-block;
        position: absolute;
        right: -180px;
        border-left: 1px solid #D9EAF5;

        >div {
          cursor: pointer;
          padding-left: 15px;
          margin: 15px 0;

          &:first-child {
            margin-top: 5px;
          }

          &:last-child {
            margin-bottom: 5px;
          }

          &:hover {
            color: #EA1E70;
            font-weight: bold;
            border-left: 2px solid #EA1E70;
            padding-left: 13px;
          }

          &.activeS {
            color: #EA1E70;
            font-weight: bold;
            border-left: 2px solid #EA1E70;
            padding-left: 13px;
          }
        }
      }
    }

    .pingtaiBom {
      background: #fff;

      h4 {
        font-size: 26px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }

      .bomleft {
        .pbox {
          width: 708px;
          height: 226px;
          padding: 20px;
          box-sizing: border-box;

          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: rgba(51, 51, 51, 1);
            text-indent: 2em;
            line-height: 30px;

            span {
              color: #145bb9;
              font-weight: bold;
            }
          }
        }

        .dfbox {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          div {
            width: 220px;
            height: 60px;
            background: rgba(20, 91, 185, 1);
            margin-bottom: 20px;
            font-size: 22px;
            color: rgba(255, 255, 255, 1);
            line-height: 60px;
            text-align: center;

            &:nth-child(2n) {
              background: #ea1e70;
            }
          }
        }

        .dfbox1 {
          div {
            width: 340px;

            &:nth-child(2n) {
              background: #ea1e70;
            }

            &:nth-child(3n) {
              background: #ea1e70;
            }

            &:nth-child(4n) {
              background: rgba(20, 91, 185, 1);
            }
          }
        }

        .dfbox2 {
          div {

            &:nth-child(2n) {
              background: #ea1e70;
            }

            &:nth-child(3n) {
              background: rgba(20, 91, 185, 1);
            }

            &:nth-child(4n) {
              width: 340px;
              background: #ea1e70;
            }

            &:nth-child(5n) {
              width: 340px;
            }
          }
        }

        .dfbox22 {
          div {
            &:nth-child(2n) {
              background: #ea1e70;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              width: 160px;
            }

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
              width: 220px;
            }
          }
        }
      }
    }

  }

  .fuwuBox {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .fuwuul2 {
      display: flex;
      flex-wrap: wrap;

      .fuwuli2 {
        width: 624px;
        height: 300px;
        background: rgba(20, 91, 185, 0.1);
        margin-right: 32px;
        font-size: 20px;
        color: rgba(51, 51, 51, 1);
        line-height: 56px;
        padding: 30px 60px 30px 60px;
        box-sizing: border-box;
        margin-bottom: 32px;

        h5 {
          font-size: 36px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }

        p {
          margin-top: 20px;
          width: 440px;
          height: 152px;
          font-size: 16px;
          color: #fff;
          line-height: 40px;
        }

        &:nth-child(1) {
          background: url('~@/assets/banner_img/aservice6/mings1.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(2) {
          background: url('~@/assets/banner_img/aservice6/mings2.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(3) {
          background: url('~@/assets/banner_img/aservice6/mings3.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(4) {
          background: #ea1e70;
        }


        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  .teseBox {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .teses1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      >div {
        width: 410px;
        height: 258px;
        background: rgba(235, 245, 252, 1);
        margin-bottom: 24px;

        h5 {
          width: 100%;
          height: 64px;
          background: rgba(94, 140, 200, 1);
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          line-height: 64px;
        }

        p {
          padding: 25px 26px;
          font-size: 17px;
          line-height: 30px;
          font-family: Microsoft YaHei;
          color: rgba(51, 51, 51, 1);
          box-sizing: border-box;

          span {
            font-weight: bold;
          }
        }
      }
    }

    .teses2 {
      >div {
        width: 628px;
        height: 200px;
      }
    }

    .teses3 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      >div {
        width: 362px;
        height: 200px;
        background: rgba(235, 245, 252, 1);
        margin-bottom: 24px;

        &:nth-child(2) {
          width: 508px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          width: 628px;
        }

        h5 {
          width: 100%;
          height: 64px;
          background: rgba(94, 140, 200, 1);
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          line-height: 64px;
        }

        p {
          padding: 25px 26px;
          font-size: 17px;
          line-height: 30px;
          font-family: Microsoft YaHei;
          color: rgba(51, 51, 51, 1);
          box-sizing: border-box;

          span {
            font-weight: bold;
          }
        }
      }
    }

    .teses4 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 360px;

      >div {
        width: 240px;
        height: 360px;
        background: #fde8f0;
        margin-bottom: 24px;

        h5 {
          width: 100%;
          height: 64px;
          background: #ea1e70;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          line-height: 64px;
        }

        .t4div1 {
          display: flex;
          flex-wrap: wrap;
          padding: 35px 0;
          box-sizing: border-box;
          align-items: center;
          height: calc(100% - 64px);

          >p {
            width: 100%;
            text-align: center;
            font-size: 17px;
            line-height: 30px;
            font-family: Microsoft YaHei;
            color: rgba(51, 51, 51, 1);
          }
        }

        .t4div2 {
          display: flex;
          justify-content: space-between;
          background: #fff;
          height: calc(100% - 64px);

          >div {
            h4 {
              width: 100%;
              height: 64px;
              background: rgba(202, 224, 239, 1);
              text-align: center;
              line-height: 64px;
              font-family: Microsoft YaHei;
              font-weight: normal;
            }

            >div {
              width: 210px;
              background: #ebf5fc;
              display: flex;
              flex-wrap: wrap;
              box-sizing: border-box;
              align-items: center;
              height: calc(100% - 64px);
              padding: 10px 0;

              >p {
                width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                font-family: Microsoft YaHei;
                color: rgba(51, 51, 51, 1);
              }
            }
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          width: 80px;
          background: #fff;
          padding-top: 17px;
          text-align: center;
        }

        &:nth-child(3) {
          width: 640px;

          h5 {
            background: #145bb9;
          }
        }
      }
    }
  }

  .ruzhuBox {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
  }
}

.mainBox2 {
  .pingjiaBox {
    h4 {
      font-size: 26px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .ptext {
      font-size: 18px;
      font-family: Microsoft YaHei;
      line-height: 30px;

      span {
        font-weight: bold;
        color: rgba(20, 91, 185, 1);
      }
    }

    .fenxiBox {
      display: flex;
      justify-content: space-between;

      >div {
        width: 624px;
        height: 410px;
        background: rgba(255, 255, 255, 1);
        padding: 40px;
        box-sizing: border-box;

        .imgBox {
          width: 544px;
          height: 236px;
        }

        .txtBox {
          font-size: 18px;
          font-family: Microsoft YaHei;
          line-height: 30px;

          .span1 {
            font-weight: bold;
            color: rgba(234, 30, 112, 1);
          }

          .span2 {
            font-weight: bold;
          }
        }
      }
    }

    .keBox {
      display: flex;
      justify-content: space-between;

      >div {
        width: 302px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        background: rgba(20, 91, 185, 1);
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);

        &:nth-child(2n) {
          background: rgba(234, 30, 112, 1);
        }
      }
    }

    .ptext2 {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
    }

    .keimgBox {
      width: 100%;
      height: 774px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.mainBox3 {
  .imgBox3 {
    width: 100%;
    height: 618px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .yingyBox {
    h4 {
      font-size: 26px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .yingyUl {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .yingyLi {
        width: 410px;
        height: 304px;
        position: relative;
        box-sizing: border-box;
        padding: 30px;
        margin-bottom: 24px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          background: #ebf5fc;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(8) {
          background: #fde8f0;
        }

        h5 {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }

        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: rgba(51, 51, 51, 1);
        }

        .boxUl {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          z-index: 66;

          .boxLi {
            width: 50%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 8px;

            >div {
              width: 6px;
              height: 6px;
              background: #333;
              margin-right: 10px;
              border-radius: 50%;
            }
          }

          .boxLi2 {
            width: 33%;
          }

          .boxLi3 {
            width: 30%;

            &:nth-child(1),
            &:nth-child(4) {
              width: 40%;
            }
          }
        }

        .aboutyy {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .yingyLi2 {
        width: 410px;
        height: 304px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.mainBox4 {
  .canpinBox {
    h4 {
      font-size: 26px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .chanpinUl {
      display: flex;

      >div {
        width: 354px;
        height: 415px;
        background: rgba(255, 255, 255, 1);

        &:nth-child(2),
        &:nth-child(4) {
          width: 109px;
          background: none;
          text-align: center;
          padding-top: 14px;
        }

        h1 {
          margin-left: 4px;
          margin-top: 4px;
          width: 346px;
          height: 64px;
          background: rgba(20, 91, 185, 1);
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          line-height: 64px;
        }

        >div {
          padding: 20px;
          box-sizing: border-box;

          p {
            width: 314px;
            height: 55px;
            background: rgba(235, 245, 252, 1);
            font-size: 20px;
            font-family: Microsoft YaHei;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 8px;
            text-align: center;
            line-height: 55px;
          }
        }
      }
    }
  }

  .teSeBox {
    h4 {
      font-size: 26px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .teSeUl {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      >div {
        width: 410px;
        height: 160px;
        background-size: 100% 100%;
        margin-bottom: 20px;
        padding: 38px 33px;
        box-sizing: border-box;

        h4 {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 15px;
        }

        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
        }

        &:nth-child(1) {
          background: url('~@/assets/banner_img/aservice6/tese1.png') no-repeat;
        }

        &:nth-child(2) {
          background: url('~@/assets/banner_img/aservice6/tese2.png') no-repeat;
        }

        &:nth-child(3) {
          background: url('~@/assets/banner_img/aservice6/tese3.png') no-repeat;
        }

        &:nth-child(4) {
          width: 628px;
          background: url('~@/assets/banner_img/aservice6/tese4.png') no-repeat;
        }

        &:nth-child(5) {
          width: 628px;
          background: url('~@/assets/banner_img/aservice6/tese5.png') no-repeat;
        }

        &:nth-child(6) {
          width: 1280px;
          height: 416px;
          background: url('~@/assets/banner_img/aservice6/tese6.png') no-repeat;

          h4 {
            text-align: center;
          }

          .guanliBox {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            >div {
              width: 384px;
              height: 100px;
              background: rgba(255, 255, 255, 1);
              margin-bottom: 20px;
              position: relative;

              .about {
                position: absolute;
                width: 81px;
                height: 32px;
                background: rgba(20, 91, 185, 1);
                border-radius: 0 0 16px 0;
                top: 0;
                left: 0;
                font-size: 24px;
                font-family: DIN;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
                line-height: 32px;
                text-align: center;
              }

              p {
                font-size: 30px;
                font-family: Microsoft YaHei;
                color: rgba(51, 51, 51, 1);
                line-height: 100px;
                text-align: center;

                span {
                  color: #ea1e70;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>