import { render, staticRenderFns } from "./NoticeDetail.vue?vue&type=template&id=5cff4dbe&scoped=true"
import script from "./NoticeDetail.vue?vue&type=script&lang=js"
export * from "./NoticeDetail.vue?vue&type=script&lang=js"
import style0 from "./NoticeDetail.vue?vue&type=style&index=0&id=5cff4dbe&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cff4dbe",
  null
  
)

export default component.exports