<template>
  <div class="create_research public_main_bgc">
    <!-- 创建教研组 -->
    <!-- 正文 -->
    <div class="ResearchBox">
      <!-- 面包屑 -->
      <div class="contents">
        <div class="navs">
          <p style="font-size: 14px">
            <span style="color: #666666"> 当前位置：个人中心>我的教研组> </span>
            <span style="color: #ff8201"> 创建教研组 </span>
          </p>
        </div>
        <div class="mainBox">
          <div class="mains">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm"
              style="margin-top: 30px; margin-bottom: 30px; margin-left: 2px">
              <el-form-item label="教研组封面：" prop="cover_id">
                <div class="toCenter">
                  <div :class="activeClass0 == index ? 'box_img1' : 'box_img2'" @click="selectOnePhoto(index, item.id)"
                    v-for="(item, index) in selectPhoto" :key="index">
                    <img :src="item.photo_url" alt="" />
                    <i class="el-icon-check dg"></i>
                  </div>

                  <div class="upImg">
                    <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                      dcdom="ceshi" :dcConfig="dcConfig" @dc-success="dcSuccess"
                      accept=".jpg,.png,.swf,.bmp,.jpeg,.JPG,.PNG,.SWF,.BMP,.JPEG" @dc-progress="dcProgressTrue = false"
                      v-if="isUpOk">
                      <!-- 上传后图片div -->
                      <div v-if="cover_url" class="avatar">
                        <img :src="cover_url" />
                      </div>
                      <!-- 没上传div -->
                      <div v-else class="jh">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <p>自定义封面</p>
                      </div>
                    </dc-upload>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="教研组名称：" prop="name">
                <el-input v-model="form.name" style="width: 400px" placeholder="请输入教研组名称"></el-input>
              </el-form-item>
              <el-form-item label="标签：" prop="label">
                <el-input v-model="form.label" style="width: 400px" placeholder="请点击输入教研组噱头、吸引人卖点等，最多50个字"></el-input>
              </el-form-item>
              <el-form-item label="教研组简介：" prop="intro">
                <el-input type="textarea" v-model="form.intro" rows="10"></el-input>
              </el-form-item>
              <el-form-item label="教研组类型：" prop="type">
                <!-- 学段 -->
                <el-select v-model="form.type" placeholder="请选择类型" class="sid">
                  <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

              <!-- 学科 -->
              <el-form-item label="学段学科：" prop="stageAndSubject">
                <!-- 学段 -->
                <el-select v-model="form.stage_id" placeholder="请选择学段" class="sid">
                  <el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.id"
                    @click.native="getXK(item.id)">
                  </el-option>
                </el-select>
                <!-- 学科 -->
                <el-select v-model="form.subject_id" placeholder="请选择学科" class="sid">
                  <el-option v-for="item in getDataList" :key="item.subjectId" :label="item.subjectName"
                    :value="item.subjectId">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="加入设置：" prop="is_need_approve">
                <el-radio-group v-model="form.is_need_approve">
                  <el-radio v-for="item in radioList" :key="item.id" :value="item.id" :label="item.id"
                    @click.native="selectGroup(item.id)">
                    {{ item.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item style="text-align: center">
                <el-button class="width148" @click="toBack1()">取消</el-button>
                <el-button class="width148" type="primary" @click="sendForm"
                  style="background: rgba(255, 130, 1, 1); border: none" :loading="save_loading_btn">保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce, throttle } from "@/utils/utils.js";
export default {
  data() {
    const stageAndSubject = (rule, value, callback) => {
      if (!this.form.stage_id) {
        callback(new Error("请选择学段"));
      } else if (!this.form.subject_id) {
        callback(new Error("请选择学科"));
      } else {
        callback();
      }
    };
    return {
      save_loading_btn: false,
      //控制三张图变量
      activeClass0: -1,
      // 三张图片数组
      selectPhoto: [
        {
          id: 5886244,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201113/6a8a1162-e241-4a03-ae52-7121cf9bd19f.png",
        },
        {
          id: 5886245,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201113/1f96534b-2aea-4198-919c-c1cdaef767aa.png",
        },
        {
          id: 5886246,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201113/e3408db4-4eb7-40d5-8d89-f3e2a90bd69c.png",
        },
      ],

      // 单选数据
      radioList: [
        {
          id: 1,
          name: "直接加入",
        },
        {
          id: 2,
          name: "组长审核",
        },
      ],
      //教研组下拉
      typeList: [
        {
          id: 1,
          name: "校本教研组",
        },
        {
          id: 2,
          name: "其他教研组",
        },
      ],
      //学段下拉框数组
      stageList: "",
      //学科下拉框数组
      SubjectList: "",
      //动态绑定imgUrl
      imgUrl: "/TeacherGroup/insertGroup",
      //动态样式变量
      isMax: false,
      //上传图片变量
      imgNum: 1,
      // 创建教研组信息
      //图片地址
      cover_url: "",
      form: {
        name: "",
        type: "",
        // 学段vluae
        stage_id: "",
        // 学科value
        subject_id: "",
        cover_id: "",
        is_need_approve: "",
        user_id: "",
        intro: "",
        label: "",
        id: "",
      },

      rules: {
        cover_id: [
          { required: true, message: "请上传教研组封面", trigger: "change" },
        ],
        name: [
          { required: true, message: '请输入教研活动名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '请输入标签', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        intro: [
          { required: true, message: "请输入教研组简介", trigger: "blur" },
          {
            min: 1,
            max: 200,
            message: "长度在 1 到 200 个字符",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择教研组类型", trigger: "change" },
        ],
        stageAndSubject: [
          { required: true, validator: stageAndSubject, trigger: "change" },
        ],
        is_need_approve: [
          { required: true, message: "请选择加入设置", trigger: "change" },
        ],
      },
      isUpOk: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
    };
  },
  created() {
    //初始化
    this.form.id = this.$route.query.groupId ? this.$route.query.groupId : "";
    //从缓存中得到userid
    let userId = localStorage.getItem("id");
    this.user_id = userId;
    this._getAddress();
    // 初始化学段
    this.getXD();
    if (this.form.id) {
      this.getGroupInfo(this.form.id);
    }
  },
  computed: {
    getDataList() {
      return this.SubjectList;
    },
  },
  methods: {
    selectOnePhoto(index, cid) {
      // index为数组下标
      //  赋值操作
      this.activeClass0 = index;
      //得到id
      //  console.log(cid,"课程id值");
      this.form.cover_id = cid;
    },
    //返回
    toBack1() {
      this.$router.go(-1);
    },
    //获取学科
    async getXK(sid) {
      let res = await this.$axios.get("subject/list", {
        params: {
          pharseid: sid,
        },
      });
      this.SubjectList = res.data.data;
      console.log(res.data.data, "信息data");
    },
    //获取学段
    async getXD() {
      let res = await this.$axios.get("stage/stage_list");
      this.stageList = res.data.data;
      //  console.log(res.data,"信息data");
    },
    //获取编辑教研组信息
    async getGroupInfo(id) {
      const res = await this.$axios.get("/TeacherGroup/editInfo", {
        params: { id: id },
      });
      const data = res.data.data;
      this.form.name = data.name;
      this.form.type = data.type;
      this.form.stage_id = data.stage_id;
      this.form.subject_id = data.subject_id;
      this.form.cover_id = data.cover_id;
      this.form.is_need_approve = data.is_need_approve;
      this.form.user_id = this.user_id;
      this.form.intro = data.intro;
      this.form.label = data.label;
      this.form.id = id;
      this.form.cover_url = data.cover_url;
      if (this.selectPhoto.indexOf(this.form.cover_url) == -1) {
        this.selectPhoto[3] = {
          id: 0,
          photo_url: this.form.cover_url,
        };
        this.activeClass0 = 3;
      }

      this.getXK(this.form.stage_id);
    },
    //  创建教研组
    sendForm: throttle(function (formName) {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //  从缓存得到用户id
          this.form.user_id = localStorage.getItem("id");
          if (!this.form.id) {
            this.save_loading_btn = true;
            this.$axios.post("/TeacherGroup/insertGroup", this.form).then((res) => {
              this.save_loading_btn = false;
              this.$message({
                message: "创建教研组成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.$router.push({
                    path: "/research",
                    query: {
                      id: 1,
                    },
                  });
                },
              });
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          } else {
            this.save_loading_btn = true;
            this.$axios.post("/TeacherGroup/editGroup", this.form).then((res) => {
              this.save_loading_btn = false;
              this.$message({
                message: "修改教研组成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.$router.push({
                    path: "/research",
                    query: {
                      id: 1,
                    },
                  });
                },
              });
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          }
        }
      });
    }),
    //上传失败的时候会出现Bug，故在上传失败的时候也进行一下判断
    error: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      this.isUpOk = false;
      //erg
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });
      this.form.cover_id = res.data.data.id;
      this.selectPhoto[3] = {
        id: res.data.data.id,
        photo_url: resData.Location,
      };
      this.isUpOk = true;
      this.activeClass0 = 3;
    },
  },
};
</script>

<style lang="less">
.create_research {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

  .ResearchBox {
    padding: 0;
    margin: 0 auto;

    .contents {
      width: 1400px;
      margin: 0 auto;
      border-radius: 6px;

      .navs {
        height: 48px;
        line-height: 48px;
      }

      .mainBox {
        background: rgba(255, 255, 255, 1);
        border-radius: 6px;
        min-height: 1227px;

        .mains {
          width: 893px;
          margin: 0 auto;
          padding: 55px 0;

          .toCenter {
            display: flex;

            .box_img1 {
              .dg {
                z-index: 1;
                position: absolute;
                margin-left: -100px;
                margin-top: 30px;
                font-size: 38px;
                color: #f7f7f7;
              }

              background: #000;
              width: 164px;
              height: 92px;
              margin-right: 5px;

              img {
                position: relative;
                z-index: 2;
                width: 164px;
                height: 92px;
                opacity: 0.6;
              }
            }

            .box_img2 {
              .dg {
                display: none;
              }

              width: 164px;
              height: 92px;
              margin-right: 5px;

              img {
                position: relative;
                z-index: 2;
                width: 164px;
                height: 92px !important;
              }
            }

            .upImg {
              margin-left: 10px;

              .avatar-uploader {
                .avatar {
                  width: 164px;
                  height: 92px;
                  box-sizing: border-box;

                  img {
                    width: 100%;
                    height: 92px;
                  }
                }

                .jh {
                  width: 164px;
                  height: 92px;
                  box-sizing: border-box;
                  border: 1px dashed#EBEBEB;
                  position: relative;

                  .avatar-uploader-icon {
                    font-size: 20px;
                    margin-top: 20px;
                  }

                  p {
                    position: absolute;
                    font-size: 14px;
                    color: rgba(51, 51, 51, 1);
                    width: 164px;
                    text-align: center;
                    left: 0;
                    bottom: 10px;
                  }
                }
              }

              .disabled .el-upload--picture-card {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
