const changeUrl = {
    methods: {
        setUcUrl(url) {
            if (localStorage.getItem("ucAppList")) {
                let ucAppList = JSON.parse(localStorage.getItem("ucAppList"))
                let domainarr = url.split('/');
                let domain = domainarr[0] + '//' + domainarr[2]
                let domid = ""
                ucAppList.forEach(item => {
                    if (item.domain == domain) {
                        domid = item.id
                    }
                });
                let tex = 'ud' + domid + ','
                let newUrldom = url.replace(domain, tex)
                return newUrldom
            }
        },
        getUcUrl(url) {
            if (localStorage.getItem("ucAppList")) {
                let ucAppList = JSON.parse(localStorage.getItem("ucAppList"))
                let domainarr = url.split(',');
                let olddomid = domainarr[0]
                let domid = olddomid.replace('ud', '')
                let domain = ""
                ucAppList.forEach(item => {
                    if (item.id == domid) {
                        domain = item.domain
                    }
                });
                let newUrldom = url.replace(olddomid + ',', domain)
                return newUrldom
            }
        },
        setHost(url) {
            let ucAppList = JSON.parse(localStorage.getItem("ucAppList"))
            let domain = url
            let domid = ""
            ucAppList.forEach(item => {
                if (item.domain == domain) {
                    domid = item.id
                }
            });
            let tex = 'ud' + domid + ','
            return tex
        }
    }
}

export default changeUrl