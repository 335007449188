<template>
  <!-- 正文 -->
  <div class="personal_center_box" :class="is_hide == 1 ? '' : 'public_main_bgc'">
    <!-- :style="{
          width: is_hide == 1 ? '1330px' : '',
        }" -->
    <div class="personal_center_main"
      :style="{ 'padding-top': is_hide == 1 ? '0px' : '', width: is_hide == 1 ? '1330px' : '' }">
      <!-- 左边导航 -->
      <div class="personal_center_left" v-if="is_hide != 1">
        <el-menu :default-active="$route.path" router>
          <!-- 个人中心首页 -->
          <el-menu-item :index="'/' + item.identity" v-for="(item, index) in listArry" :key="index">
            <i class="iconfont icongerenzhongxinshouye"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>

          <!-- 个人中心子页 :class="index1 != activeClass ? '' : 'active'" @click="toChangeColor(index1)"  -->
          <el-menu-item :index="'/' + item1.identity" v-for="(item1, index1) in childArry" :key="index1 + 1">
            <i class="iconfont iconwodejiaoyanzu" v-if="index1 == 0"></i>
            <i class="iconfont iconjiaoyanzhibo" v-if="index1 == 1"></i>
            <i class="iconfont iconjiaoyanhuodong" v-if="index1 == 2"></i>
            <i class="iconfont iconjiaoyankecheng" v-if="index1 == 3"></i>
            <i class="iconfont iconwodeziyuan" v-if="index1 == 4"></i>
            <i class="iconfont iconwodeshiti02-copy" v-if="index1 == 5"></i>
            <i class="iconfont iconwodeshijuan" v-if="index1 == 6"></i>
            <i class="iconfont iconhuati" v-if="index1 == 7"></i>
            <i class="iconfont iconwodeshijuan" v-if="index1 == 8"></i>
            <i class="el-icon-files" v-if="index1 == 9"></i>
            <i class="iconfont iconhuati" v-if="index1 == 10"></i>
            <!-- <span slot="title">{{item1.name}}</span> -->
            <span slot="title">{{ filtername(item1.name) }}</span>
          </el-menu-item>

          <!-- <el-menu-item index="/message">
                                 <i class="iconfont iconxiaoxi"></i>

                <span slot="title">我的消息</span>
              </el-menu-item>  -->
          <!-- <el-menu-item index="/live?vid=0">
                                <i class="iconfont icon02"></i>

                <span slot="title">我的教研直播</span>
              </el-menu-item>

              <el-menu-item index="/activity?id=0">
                               <i class="iconfont iconhuodongyingxiao"></i>

                <span slot="title">我的活动</span>
              </el-menu-item>

              <el-menu-item index="/course?id=1">
                               <i class="iconfont iconshu"></i>

                <span slot="title">我的课程</span>
              </el-menu-item>

              <el-menu-item index="/resource">
                <i class="iconfont iconwenjianjia"></i>
                <span slot="title">我的资源</span>
              </el-menu-item>

              <el-menu-item index="/test">
                <i class="iconfont iconwenjian"></i>
                <span slot="title">我的试题库</span>
              </el-menu-item>

              <el-menu-item index="/testPaper">
                 <i class="iconfont iconshijuan"></i>
                <span slot="title">我的试卷库</span>
              </el-menu-item> -->

          <!-- <el-menu-item index="/conference">
                <img
                  src="../../assets/images/personal/8.png"
                  alt=""
                  class="el-icon-menu"
                />
                <span slot="title">我的会议</span>
              </el-menu-item> -->

          <!-- <el-menu-item index="/myTopic">
                <i class="iconfont iconhuatiyantao"></i>

                <span slot="title">我的话题</span>
              </el-menu-item>

              <el-menu-item index="/message">
                <i class="iconfont iconwodexiaoxi"></i>
                <span slot="title">我的消息</span>
              </el-menu-item> -->
        </el-menu>
      </div>

      <!-- 内容 -->
      <div class="personal_center_view" :style="{
        width: is_hide == 1 ? '1330px' : '',
      }">
        <!-- 子路由渲染 -->
        <router-view> </router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "personalindex",
  data() {
    return {
      activeIndex: "perSonIndex",
      //定一个变量控制导航颜色样式
      activeClass: -1,
      currentPage4: 0,
      //存放主页
      listArry: [],
      //存放主页的子页
      childArry: [],
      is_hide: "",
    };
  },
  created() {
    this.is_hide = sessionStorage.getItem("is_hide");
    //初始化数据
    if (sessionStorage.getItem("webAll")) {
      let res = JSON.parse(sessionStorage.getItem("webAll"))
      this.listArry = [res.personal_center]//res.auth.slice(5, 6);
      let childArry = [];
      this.listArry.forEach((item) => {
        //赋值
        childArry = item.children;
      });
      this.childArry = childArry.filter((item) => {
        return item.is_permit == "1";
      });
    } else {
      this.getPower();
    }
  },
  computed: {
    // 获取个人信息
    getUserInfo() {
      return localStorage.getItem("user_name");
    },
  },
  methods: {
    //点击变化背景颜色
    // toChangeColor(index1){
    //     this.activeClass=index1;
    // },
    //获取个人中心模板
    //获取权限
    async getPower() {
      let res = await this.$axios.get("webConfig/getWebConfig");
      console.log(res.data.data)
      this.listArry = [res.data.data.personal_center]//res.data.data.auth.slice(5, 6);
      let childArry = [];
      this.listArry.forEach((item) => {
        //赋值
        childArry = item.children;
      });
      this.childArry = childArry.filter((item) => {
        return item.is_permit == "1";
      });
      // this.childArry.splice(1, 0, {
      //   identity: "teacherstudio",
      //   is_permit: 1,
      //   name: "我的四名工作室",
      // });
    },
    // 编辑个人信息
    editor_info() {
      this.$router.push("/personInfo");
    },
    // 菜单名过滤
    filtername(name) {
      if (name == "我的教研直播") {
        return "我的直播";
      } else if (name == "我的试题库") {
        return "我的试题";
      } else if (name == "我的试卷库") {
        return "我的试卷";
      } else {
        return name;
      }
    },
  },
};
</script>

<style lang="less" >
.personal_center_box {

  // 底部样式
  .personal_center_main {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-height: 953px;
    padding-top: 20px;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    // 左边导航样式
    .personal_center_left {
      width: 208px;
      flex: 0 0 208px;

      .el-menu {
        box-sizing: border-box;
        background-color: #ffffff;
        width: 100%;
        padding: 20px 12px;
        border-radius: 8px;
        border: none !important;

        .el-menu-item {
          font-size: 16px;
          color: #333;
          line-height: 48px;
          height: 48px;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 !important;
          padding: 0 !important;
          display: flex;
          align-items: center;

          &:hover {
            // background-color: #3489ff !important;
            // color: #fff !important;
          }

          i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            font-size: 22px;
            color: #333;
            margin-right: 8px;
            margin-left: 16px;
          }

          span {
            color: #333;
          }
        }

        .el-menu-item.is-active {
          background-color: #3489ff !important;
          color: #fff !important;

          i {
            color: #fff;
          }

          span {
            color: #fff;
          }
        }
      }
    }

    // 底部右边内容
    .personal_center_view {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      width: 1172px;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 30px;
      box-sizing: border-box;
    }
  }
}
</style>