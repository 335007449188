<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div
            class="bread"
            style="height: 49px; display: flex; align-items: center"
          >
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">活动直播</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="radioBox">
        <div :class="active == 1 ? 'activeSty' : ''" @click="changeActive(1)">
          <img src="@/assets/images/banner/zhibo.png" alt="" />
          全部直播
        </div>
        <div :class="active == 2 ? 'activeSty' : ''" @click="changeActive(2)">
          <img src="@/assets/images/banner/zhuanti.png" alt="" />
          全部专题
        </div>
      </div>

      <!-- 筛选 -->
      <div class="select_box">
        <div class="select_left">
          <div class="xueduan">
            <p>类型：</p>
            <div
              class="xueduan_block cursor"
              :class="form.search_type == item.id ? 'active_block' : ''"
              v-for="(item, index) in type1List"
              :key="index"
              @click="changeType(item.id, 'search_type')"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan" v-if="active == 1">
            <p>状态：</p>
            <div
              class="xueduan_block cursor"
              :class="form.search_status == item.id ? 'active_block' : ''"
              v-for="(item, index) in type2List"
              :key="index"
              @click="changeType(item.id, 'search_status')"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="df dfb">
            <div class="sousuo-box">
              <p>查找：</p>
              <div class="box-right">
                <div class="sousuo">
                  <el-input
                    placeholder="请输入搜索关键词"
                    prefix-icon="el-icon-search"
                    v-model="form.keyword"
                    clearable
                  ></el-input>
                  <el-button type="primary" @click="seletBtn">搜索</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mainCen" v-if="active == 1">
        <div class="ulBox df dfw" v-if="total">
          <div
            class="liBox cp mb16"
            v-for="(item, index) in dataList1"
            :key="index"
            @click="goMsLiveDetails(item.id)"
          >
            <div class="imgbox">
              <img
                class="imgbig"
                v-if="item.cover_url"
                :src="item.cover_url"
                alt=""
              />
              <img
                v-else
                class="imgbig"
                src="@/assets/images/banner/home_news_pic.png"
                alt=""
              />
              <div
                class="imgAbout1 tc line-h20"
                :class="
                  item.status == 1
                    ? 'bg1'
                    : item.status == 2
                    ? 'bg2'
                    : item.playback_resource_url
                    ? 'bg3'
                    : 'bg4'
                "
              >
                {{
                  item.status == 1
                    ? "预告"
                    : item.status == 2
                    ? "直播中"
                    : item.playback_resource_url
                    ? "回放"
                    : "已结束"
                }}
              </div>
              <div class="imgAbout2 df line-h24">
                <div class="flex mt3 mr5">
                  <i class="el-icon-caret-right"></i>
                </div>
                <p>
                  {{ item.start_date }} <i class="ml3 mr3"></i>
                  {{ item.start_time.substring(0, 5) }}~{{
                    item.end_time.substring(0, 5)
                  }}
                </p>
              </div>
            </div>
            <div class="txtBox mt10">
              <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
              <div class="lableBox df mb10">
                <p v-for="(item, index) in item.label.split(',')" :key="index">
                  {{ item }}
                </p>
              </div>
              <div class="df dfb">
                <p>
                  {{
                    item.type == 1
                      ? "四名沙龙"
                      : item.type == 2
                      ? "四名论坛"
                      : item.type == 4
                      ? "竞赛活动"
                      : "四名伴我行"
                  }}
                </p>
                <p>浏览人次：{{ item.view_count }}</p>
              </div>
            </div>
          </div>
        </div>
        <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
        <el-pagination
          v-if="total"
          class="tc mt30 mb30"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="form.page"
          :page-size="15"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>

      <div class="mainZhuan" v-if="active == 2">
        <div class="ulBox df dfw" v-if="total">
          <div
            class="liBox mb16"
            v-for="(item, index) in dataList1"
            :key="index"
          >
            <div
              class="topBox"
              :style="{ backgroundImage: 'url(' + item.cover_url + ')' }"
            >
              <h4 class="ellipsis-2 mb15">{{ item.title }}</h4>
              <div class="lableBox df">
                <p v-for="(item, index) in item.label.split(',')" :key="index">
                  {{ item }}
                </p>
              </div>
            </div>
            <div class="bomm df dfb">
              <h4>{{ item.type_str }}</h4>
              <p class="cp" @click="gomsLiveTopic(item)">
                {{ item.live_count }}场直播 >
              </p>
            </div>
          </div>
        </div>
        <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
        <el-pagination
          v-if="total"
          class="tc mt30 mb30"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="form.page"
          :page-size="15"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as msAllApi from "@/api/teacherStudio/msAllApi";
export default {
  data() {
    return {
      type1List: [
        { id: 0, name: "全部" },
        { id: 1, name: "四名沙龙" },
        { id: 2, name: "四名论坛" },
        { id: 4, name: "竞赛活动" },
        { id: 3, name: "四名公益行" },
      ],
      type2List: [
        { id: 0, name: "全部" },
        { id: 1, name: "预告" },
        { id: 2, name: "直播中" },
        { id: 3, name: "已结束" },
      ],
      dataList1: [],
      total: 0,
      form: {
        keyword: "",
        search_type: 0,
        search_status: 0,
        page: 1,
        limit: 15,
      },
      active: 1,
    };
  },

  created() {
    if (this.$route.query.active) {
      this.active = this.$route.query.active;
    }
    this.getList1();
  },
  methods: {
    changeActive(val) {
      this.active = val;
      this.dataList1 = [];
      this.form = {
        keyword: "",
        search_type: 0,
        search_status: 0,
        page: 1,
        limit: 15,
      };
      this.getList1();
    },
    changeType(val, name) {
      this.form[name] = val;
      this.getList1();
      // if (name == 'search_type') {
      //   this.sectionIndex = val
      // } else {
      //   this.subjectIndex = val
      // }
    },
    getList1() {
      let param = this.form;
      param.search_status = param.search_status == 0 ? "" : param.search_status;
      param.search_type = param.search_type == 0 ? "" : param.search_type;
      if (this.active == 1) {
        msAllApi.LiveList(this.form).then((res) => {
          this.dataList1 = res.data.data.data;
          this.total = res.data.data.total;
        });
      } else {
        msAllApi.TopicList(this.form).then((res) => {
          this.dataList1 = res.data.data.data;
          this.total = res.data.data.total;
        });
      }
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getList1();
    },
    seletBtn() {
      this.form.page = 1;
      this.getList1();
    },
    // 跳转直播详情
    goMsLiveDetails(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id },
      });
    },
    gomsLiveTopic(item) {
      this.$router.push({
        name: "msLiveTopic",
        query: { id: item.id },
      });
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .radioBox {
    display: flex;
    border: 1px solid #fff;
    border-bottom: none;
    background: #ebf3fc;
    align-items: center;
    border-radius: 8px 8px 0 0;

    > div {
      width: 50%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #666666;
      cursor: pointer;

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }

      img {
        width: 26px;
        height: 26px;
        margin-right: 8px;
      }
    }

    .activeSty {
      color: #fff;
      background: linear-gradient(
        -39deg,
        rgba(76, 164, 241, 1) 0%,
        rgba(95, 213, 251, 1) 100%
      );
    }
  }

  .select_box {
    border-radius: 0 0 8px 8px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    background: #fff;
    margin-bottom: 14px;

    .select_left {
      flex: 1;
      overflow: hidden;

      .xueduan {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        p {
          margin-right: 20px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }

        .xueduan_block {
          height: 32px;
          line-height: 32px;
          display: inline-flex;
          align-items: center;
          margin-right: 20px;
          padding: 0 12px;
          font-size: 16px;
          color: #333;
        }

        .active_block {
          background: #3489fe;
          border-radius: 4px 3px 4px 4px;
          color: #ffffff;
        }
      }

      .sousuo-box {
        display: flex;
        align-items: center;

        p {
          flex-shrink: 0;
          margin-right: 20px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }

        .box-right {
          flex: 1;
          overflow: hidden;
          display: flex;
          justify-content: space-between;

          .sousuo {
            width: 320px;
            display: flex;

            /deep/ .el-input__inner {
              // border-radius: 0px;
              border-radius: 4px 0px 0px 4px;
            }

            /deep/ .el-button {
              // border-radius: 0px;
              border-radius: 0px 4px 4px 0px;
            }
          }
        }
      }
    }

    .select_right {
      .create {
        width: 140px;
        height: 38px;
        background: #ff8201;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }

  .mainCen {
    .ulBox {
      .liBox {
        width: 243px;
        height: 248px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        padding: 12px;
        box-sizing: border-box;
        margin-right: 16px;

        &:nth-child(5n) {
          margin-right: 0;
        }

        .imgbox {
          width: 219px;
          height: 133px;
          border-radius: 8px;
          position: relative;

          img {
            border-radius: 8px;
          }

          .imgAbout1 {
            position: absolute;
            top: 0;
            left: 0;
            width: 52px;
            height: 20px;
            border-radius: 8px 0 8px 0;
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;

            &.bg1 {
              background: #409eff;
            }

            &.bg2 {
              background: #51b875;
            }

            &.bg3 {
              background: #ff7a29;
            }

            &.bg4 {
              background: #999;
            }
          }

          .imgAbout2 {
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 24px;
            color: #fff;
            font-size: 14px;
            justify-content: center;

            > div {
              width: 14px;
              height: 14px;
              border: 1px solid #fff;
              border-radius: 50%;
            }
          }
        }

        .txtBox {
          h4 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }

          .lableBox {
            height: 23px;
            flex-wrap: wrap;
            overflow: hidden;

            p {
              padding: 2px 6px;
              background: #e5f4ff;
              border-radius: 4px;
              margin-right: 10px;
              text-align: center;
              color: #0493ff;
              font-size: 14px;
            }
          }

          div {
            p {
              font-size: 14px;
              color: rgba(153, 153, 153, 1);
            }
          }
        }
      }
    }
  }

  .mainZhuan {
    .ulBox {
      .liBox {
        width: 410px;
        height: 200px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        margin-right: 25px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .topBox {
          width: 100%;
          height: 150px;
          padding: 30px 20px 10px 20px;
          box-sizing: border-box;
          border-radius: 8px 8px 0 0;
          background-size: 100% 100%;

          h4 {
            width: 300px;
            height: 60px;
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            line-height: 30px;
          }

          .lableBox {
            height: 23px;
            flex-wrap: wrap;
            overflow: hidden;

            p {
              padding: 2px 6px;
              background: rgba(255, 255, 255, 0.3);
              border-radius: 4px;
              margin-right: 10px;
              text-align: center;
              color: #fff;
              font-size: 14px;
            }
          }
        }

        .bomm {
          align-items: center;
          height: 50px;
          padding: 0 30px;

          h4 {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }

          p {
            color: #fff;
            background: #3489ff;
            padding: 4px 6px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>