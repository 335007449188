<template>
  <div class="changePwd personal_right_box">

    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">修改密码</div>
      </div>
    </div>

    <div class="ChangeBox">
      <!-- 主体 -->
      <div class="item">
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm"
          style="margin-top:20px">
          <el-form-item label="原密码" prop="oldPassword">
            <el-input type="password" v-model="ruleForm2.oldPassword" show-password auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="ruleForm2.newPassword" show-password auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="verifyPassword">
            <el-input type="password" v-model="ruleForm2.verifyPassword" show-password auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="margin-left:20px;width:100px" @click="ReplacePwd">保存</el-button>
          </el-form-item>
        </el-form>

      </div>
      <!-- end -->

    </div>

  </div>
</template>

<script>
import CryptoJS from "./crypto";
const key = CryptoJS.enc.Utf8.parse("Dcit400887638100");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('Dcit400887638100');   //十六位十六进制数作为密钥偏移量
const cryptoEncryption = str => {
  let encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  return encodeURIComponent(encrypted);
};
const qs = require("qs");
export default {
  data() {
    // 校验修改密码
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码（" + this.password_name + "）"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm2.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    //   验证end
    return {
      ruleForm2: {
        oldPassword: '',
        newPassword: '',
        verifyPassword: ''
      },
      rules2: {
        newPassword: [
          { validator: validatePass, trigger: 'blur' }
        ],
        verifyPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      password_level: "",
      password_name: "",
    };
  },
  methods: {

    async ReplacePwd() {
      //  判断输入的三个输入框不为空才发送请求
      if (this.oldPassword != "" && this.newPassword != "" && this.verifyPassword != "") {
        //    从缓存中获取用户id
        let user_id = localStorage.getItem("id")
        let loginObj = JSON.parse(JSON.stringify(this.ruleForm2));
        loginObj.oldPassword = cryptoEncryption(loginObj.oldPassword);
        loginObj.newPassword = cryptoEncryption(loginObj.newPassword);
        loginObj.verifyPassword = cryptoEncryption(loginObj.verifyPassword);
        const from_data = qs.stringify(loginObj, {
          allowDots: true,
        });

        let res = await this.$axios.put(`/user/${user_id}/password`, from_data);
        //判断是否修改密码成功
        if (res.status == 200) {
          //    修改密码成功
          //    清除缓存
          this.$message({
            showClose: true,
            message: '密码修改成功,跳转登录页面',
            type: 'success'
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "./";
          }, 500);
        }
      }
    },
    getPwdLevel() {
      this.$axios
        .get("/Password/getPwdLevel")
        .then((res) => {
          this.password_level = res.data.data.password_level
          if (this.password_level == 1) {
            this.password_name = '6-18位字符'
          }
          if (this.password_level == 2) {
            this.password_name = '6-18位字符，含数字+英文'
          }
          if (this.password_level == 3) {
            this.password_name = '8-18位字符，含数字+大小写英文+特殊符号'
          }
          //【1级】6-18位字符。【2级】6-18位字符，含数字+英文。【3级】8-18位字符，含数字+大小写英文+特殊符号。
        });
    },
  },
  created() {
    this.getPwdLevel()
  },
}
</script>

<style lang="less" scoped>
.changePwd {
  background-color: #fff;
}

.ChangeBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #fff;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;

  .item {
    .el-form {
      width: 445px;
    }
  }
}
</style>