<template>
  <div class="subject-box">
    <div class="box-top">
      <h2 class="top-title">
        {{ subject.title }}
      </h2>
      <div class="top-describe rich_text_div_img" v-html="subject.description"></div>
      <el-button type="primary" icon="el-icon-arrow-left" class="top-return" @click="returnButton()">返回</el-button>
    </div>
    <div class="box-bottom">
      <SubjectList :subject="subject" v-if="subject.topics.length > 0">
      </SubjectList>
      <p class="list-tips" v-else>暂无题目</p>
    </div>
  </div>
</template>

<script>
import Preview from "./preview.js";
export default Preview;
</script>

<style lang="less" scoped>
@import "./preview.less";
</style>