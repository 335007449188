<template>
  <!-- 创建话题 -->
  <el-form ref="ruleForm" :model="form" :rules="rules_1" label-position="right" label-width="100px" style="width: auto; margin-left: 30px;">

    <el-form-item label="标题 :" prop="title">
      <el-input v-model="form.title" style="width: 400px"></el-input>
    </el-form-item>
    <el-form-item label="内容 :" prop="content">
      <div class="pr80">
        <Editor v-model="form.content"></Editor>
      </div>
    </el-form-item>

    <el-form-item label="学段学科 :" required>
      <el-col :span="7">
        <el-form-item label="" prop="stage_id">
          <el-select v-model="form.stage_id" placeholder="请选择学段" @change="handleStage">
            <el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="17">
        <el-form-item label="" prop="subject_id">
          <el-select v-model="form.subject_id" placeholder="请选择学科" no-data-text="请选择学段">
            <el-option v-for="item in SubjectList" :key="item.subjectId" :label="item.subjectName" :value="item.subjectId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-form-item>

    <el-form-item label="发起人 :" prop="type">
      <el-radio-group disabled v-model="form.type">
        <el-radio v-for="item in radioList" :key="item.id" :label="item.id">
          {{ item.name }}
        </el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item v-if="form.type == 2" label="教研组 :" prop="group_id">
      <el-select :disabled="form.is_disabled_group" style="width: 260px;" v-model="form.group_id" filterable placeholder="请选择教研组">
        <el-option v-for="item in myGroundList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button class="form_btn" @click="cancelButton()">取消</el-button>
      <el-button v-if="!form.id" class="form_btn form_cancel_btn" style="background: #ff8201; border: none" type="primary" @click="createData()">保存
      </el-button>
      <el-button v-if="form.id" class="form_btn form_cancel_btn" style="background: #ff8201; border: none" type="primary" @click="EditTopic()">保存
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { deepClone } from "@/utils/utils.js";
import { isHasStudioIdFn } from "@/utils/utils.js";
export default {
  data() {
    return {
      radioList: [
        {
          id: 1,
          name: "个人",
        },
        {
          id: 2,
          name: "教研组",
        },
      ],
      // 学段
      stageList: [],
      // 学科
      SubjectList: [],
      // 教研组列表
      myGroundList: [],
      form: {
        id: "",
        title: "",
        content: "",
        type: 2,
        stage_id: "",
        subject_id: "",
        user_id: "",
        group_id: "",
        is_disabled_group: false,
      },
      rules_1: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 1, max: 60, message: "长度在 1 到 50 个字符", trigger: "blur" },
        ],
        stage_id: [{ required: true, message: "请选择学段", trigger: "change" }],
        subject_id: [{ required: true, message: "请选择学科", trigger: "change" }],
        content: [{ required: true, message: "请输入内容", trigger: "change" }],
        type: [{ required: true, message: "请选择学科", trigger: "change" }],
        group_id: [{ required: true, message: "请选择教研组", trigger: "change" }],
      },
    };
  },
  created() {
    let query = this.$route.query || {};
    if (query.groupId) {
      this.$set(this.form, "group_id", Number(query.groupId));
      this.$set(this.form, "is_disabled_group", true);
    };
    if (query.id) {
      this.$set(this.form, "id", Number(query.id));
      this.getMyTopicDetail();
    };
    this.getGroundList();
    this.getStateList();
  },
  methods: {
    //获取话题内容
    getMyTopicDetail() {
      this.$axios.get(`/gambit/${this.form.id}`).then((res) => {
        this.form = Object.assign(deepClone(this.form), res.data.data);
        this.getSubjectList();
      });
    },
    // 创建话题
    createData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (!valid) {
          return false;
        };
        let reqObj = deepClone(this.form)
        let uid = localStorage.getItem("id");
        reqObj['user_id'] = uid;
        if (reqObj['type'] == 1) {
          delete reqObj['group_id'];
        };
        this.$axios.post("/CreateGambit", reqObj).then((res) => {
          this.$emit('onCreateData', res)
        });
      });
    },
    // 修改话题
    EditTopic() {
      this.$refs["ruleForm"].validate((valid) => {
        if (!valid) {
          return false;
        };
        let reqObj = deepClone(this.form)
        let uid = localStorage.getItem("id");
        reqObj['user_id'] = uid;
        if (reqObj['type'] == 1) {
          delete reqObj['group_id'];
        };
        this.$confirm("将为您撤回已提交的审核申请，进入修改。是否确定撤回？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios.put("/editGambit", reqObj).then((res) => {
            this.$emit('onEditTopic', res);
          });
        })
      });
    },
    // 取消按钮
    cancelButton() {
      this.$emit('onCancel');
    },
    // 请求后台获取我的所有教研组列表数据
    getGroundList() {
      this.$axios.get("/my_ground_list").then((res) => {
        let resdata = res.data;
        this.myGroundList = resdata.data;
      });
    },
    // 请求后台获取学段信息
    getStateList() {
      this.$axios.get("/stage/stage_list").then((res) => {
        let resdata = res.data.data;
        this.stageList = resdata;
      });
    },
    // 请求后台获取科目列表
    getSubjectList() {
      let obj = {
        params: {
          pharseid: this.form.stage_id,
        },
      };
      this.$axios.get("/subject/list", obj).then((res) => {
        let resdata = res.data;
        this.SubjectList = resdata.data;
      });
    },
    // 切换 学段
    handleStage() {
      this.$set(this.form, "subject_id", "");
      this.getSubjectList();
    },
  },
  watch: {
    "form.content"() {
      this.$refs["ruleForm"].validateField("content");
    },
  },
};
</script>

<style lang="less">
.bread_top_nav_div {
  width: 100%;
  margin: 0px;
  padding: 0px;
  line-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  .bread_top_nav_text {
    font-size: 14px;
    color: #666;
  }
  .bread_top_nav_item {
    color: #ff8910 !important;
  }
}
.SendTopic {
  width: 100%;
  display: flex;
}
.main_box {
  width: 1400px;
  margin: 0 auto;
}
.form_btn {
  font-weight: bold !important;
  width: 150px !important;
  height: 42px !important;
  padding: 0 !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  &.form_cancel_btn {
    margin-left: 60px !important;
  }
}
</style>