import { Message } from 'element-ui';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import * as researchApi from "@/api/researchApi.js";
import { localGet, localSet, localRemove } from "@/utils/utils.js";
export default new Vuex.Store({
  state: {
    userInfo: {}, //用户信息
    researchGroupInfo: {}, //教研组信息
    loginDisplay: null, //登录弹窗状态
    activity_isJoin: localStorage.getItem("activity_isJoin") == 1 ? true : false, //是否加入研修活动
    activity_isEnable: localStorage.getItem("activity_isEnable") == 1 ? true : false, //是否启用研修活动
    isLogin: false, //是否已经登录
    msgObj: { // 消息未读数量
      count: 0,
      personal_msg_count: 0,
      system_msg_count: 0
    },
  },
  mutations: {
    //设置用户信息
    setUserInfo(state, data) {
      state.userInfo = data
    },
    //设置教研组信息
    setResearchGroupInfo(state, data) {
      state.researchGroupInfo = data
    },
    //设置登录弹窗
    setLoginDisplay(state, data) {
      state.loginDisplay = data
    },
    //设置 消息数量
    setMsgObj(state, data) {
      state.msgObj = data
    },
    //设置是否加入研修活动状态
    setActivity_isJoin(state, data) {
      state.activity_isJoin = data
    },
    //设置是否启用研修活动状态
    setActivity_isEnable(state, data) {
      state.activity_isEnable = data
    },
    //设置是否已经登录
    setIsLogin(state, data) {
      state.isLogin = data
    },
  },
  actions: {
    //设置登录状态
    setLoginState(context) {
      context.commit('setIsLogin', localStorage.getItem("token") ? true : false);
    },
    //获取用户信息
    async getUserInfo(context) {
      const userId = localStorage.getItem("id") ? localStorage.getItem("id") : 0;
      const res = await Vue.prototype.$axios.get(`/user/${userId}/little`);
      context.commit('setUserInfo', res.data.data)
    },
    //检查用户是否登录
    async checkLogin(context) {
      let display = false
      if (!localStorage.getItem("token")) {
        display = true
      }
      context.commit('setLoginDisplay', display)
      return !display
    },
    // 获取未读tiaoshu
    getMessage(context) {
      let unserInfoData = localGet("unserInfoData");
      if (unserInfoData && unserInfoData.id && unserInfoData.token) {
        researchApi.get_message_get_unread_message().then((res) => {
          let resdata = res.data.data;
          for (let key_1 in resdata) {
            resdata[key_1] = resdata[key_1] > 99 ? "99+" : resdata[key_1];
          };
          context.commit('setMsgObj', resdata)
        });
      }
    },
  }
});