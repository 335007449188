<template>
  <!-- 直播间页面 -->
  <div class="myLive">
    <!-- 左边视频 -->
    <div class="topBox">
      <div class="topTitle">
        <div class="oneBox">
          <span>{{ liveTitle }}</span>
          <span style="color: #ff8201" ref="startTimer"></span>
        </div>
        <div class="twoBox" v-if="is_live">
          <img src="../../assets/images/close.png" alt="" />
          <!-- //教研组 -->
          <span @click="endLive()" v-if="is_form == 1">结束直播</span>
          <!-- //四名工作室 -->
          <span @click="TeachingStudioEndLive()" v-if="is_form == 2">结束直播</span>
        </div>
      </div>

      <!-- 下面 -->
      <div class="botmBox">
        <!-- 左边直播盒子 -->
        <div class="leftBox">
          <!-- //直播盒子 -->
          <div id="dplayer"></div>
        </div>

        <!-- 右边聊天 -->
        <!-- //右边聊天和人员显示 -->
        <div class="rightBox">
          <!-- //筛选盒子 -->
          <div class="selectBox">
            <!-- //聊天 -->
            <div :class="isShow == 1 ? 'activeBox' : 'chartBox'" @click="changType(1)">
              <span>聊天</span>
            </div>
            <!-- //成员 -->
            <div :class="isShow == 2 ? 'activeBox' : 'chartBox'" @click="changType(2)">
              <span>成员({{ onlineNum }})</span>
            </div>
            <!-- //图标 -->
            <div class="imgBox">
              <img src="../../assets/images/list.png" alt="" />
            </div>
          </div>

          <!-- //内容盒子 -->
          <div v-if="isShow == 1">
            <div class="contentList">
              <!-- //循环  -->
              <div class="chartList" v-for="(item, index) in onlineComment" :key="index">
                <div class="tBox">
                  <span>{{ item.fromUserName }}</span>
                  <div v-if="now_userName == item.fromUserName">主持人，我</div>
                </div>
                <!-- //内容 -->
                <div class="cBox">
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </div>
            </div>
            <!-- //表情包和禁言盒子 -->
            <div class="middleBox" v-if="isShow == 1">
              <!-- 左边表情包 -->
              <div class="faceBox">
                <el-button type="text" size="mini" @click="showDialog = !showDialog" style="font-size: 18px">😃</el-button>
                <div class="Boxs">
                  <VEmojiPicker v-show="showDialog" @select="selectEmoji" />
                </div>
              </div>
              <!-- 右边禁用 -->
              <div class="diableBox">
                <el-select v-model="value" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>

            <!-- ---输入框盒子 -->
            <div class="inputBox">
              <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="textarea" resize="none" id="input">
              </el-input>
            </div>
            <!-- 发送信息按钮 -->
            <div class="btn">
              <el-button type="primary" @click="sentUserInfo()">发送</el-button>
            </div>
          </div>

          <!-- 成员盒子 -->
          <div class="contentList" v-if="isShow == 2">
            <div class="oneBox">
              <el-input placeholder="搜索成员" prefix-icon="el-icon-search" v-model="input1" class="input1">
              </el-input>
            </div>
            <div class="peopleList">
              <div v-for="(item, index) in onlineUser" :key="index" class="bigBox">
                <div class="one" v-if="item.avatar != null">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="two" v-if="item.username != null">
                  <span>{{ item.username }}</span>
                  <div v-if="now_userName == item.username" class="three">
                    主持人，我
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//播放器组件
let Hls = require("hls.js");
import DPlayer from "dplayer";

//导入表情包组件
import { VEmojiPicker } from "v-emoji-picker";
export default {
  components: {
    VEmojiPicker,
  },
  data() {
    return {
      is_form: 1,
      //直播id
      live_id: "",
      //观看直播在线人数
      visitor: 0,
      //当前用户名
      now_userName: "",
      //websock变量
      websock: null,
      //直播在线人员信息
      onlineUser: [],
      //在线人员评论
      onlineComment: [],

      //计时变量
      timer: "",
      content: "",
      hour: 0,
      minutes: 0,
      seconds: 0,

      //直播标题
      liveTitle: "",
      //判断是否主播标识
      is_live: "",

      //视频url
      videoUrl: "",
      //禁言值
      value: 1,
      options: [
        {
          id: 1,
          name: "自由聊天",
        },
        {
          id: 2,
          name: "全体禁言",
        },
      ],

      //成员搜索变量
      input1: "",
      //控制显示成员还是聊天
      isShow: 1,
      //控制表情包显示
      showDialog: false,
      textarea: "",
    };
  },
  created() {
    this.init();
    //初始化执行计时
    this.timer = setInterval(this.startTimer, 1000);
    //初始化webscoke
    this.initWebSocket();
  },
  computed: {
    //聊天总数
    chartTotal() {
      return this.onlineComment.length;
    },
    //在线人数
    onlineNum() {
      return this.onlineUser.length;
    },
  },
  destroyed() {
    //销毁直播计时
    clearInterval(this.timer);
    //离开路由之后断开websocket连接
    this.websock.close();
  },
  watch: {
    videoUrl() {
      this.live();
    },
  },

  methods: {
    //初始化直播间获取url
    init() {
      //获取当前登录用户id
      this.now_userName = localStorage.getItem("user_name");
      //从路由获取直播id
      this.live_id = this.$route.query.id;
      //判断是教研组和个人中心的直播还是四名工作室的直播   is_from:1  是教研组的    2是四名工作室的
      this.is_form = this.$route.query.is_form;

      if (this.is_form == 1) {
        //初始化教研组和个人直播信息
        this.getLiveInfo();
        //教研组直播间接口
        this.GroupAndPersonLive();
      } else {
        //四名工作室的直播间
        this.TeachingStudioLive();
        this.getTeachingStudioLiveInfo();
      }
    },

    //教研组直播间接口

    GroupAndPersonLive() {
      //教研组直播
      this.$axios.get(`live/room/${this.live_id}`).then((res) => {
        this.videoUrl = res.data.data.url.url;
      });
    },

    //四名工作室的直播间
    TeachingStudioLive() {
      this.$axios.get(`TeachingStudiolive/room/${this.live_id}`).then((res) => {
        this.videoUrl = res.data.data.url.url;
      });
    },

    //获取教研组和个人直播信息
    getLiveInfo() {
      //从路由获取直播id
      this.$axios.get(`live/${this.live_id}`).then((res) => {
        //直播标题
        this.liveTitle = res.data.data.title;
        //主播id
        let lid = res.data.data.anchor_user_id;
        //当前用户id
        let user_id = localStorage.getItem("id");
        if (lid == user_id) {
          //判断是不是主播，用户id和直播信息返回的id相等则为主播，否则不是
          this.is_live = true;
        } else {
          this.is_live = false;
        }
      });
    },

    //四名工作室直播信息

    getTeachingStudioLiveInfo() {
      //从路由获取直播id
      this.$axios
        .get("index/TeachingStudioLive/detailsTeachingStudioLive", {
          params: { id: this.live_id },
        })
        .then((res) => {
          //直播标题
          this.liveTitle = res.data.data.title;
          //主播id
          let lid = res.data.data.anchor_user_id;
          //当前用户id
          let user_id = localStorage.getItem("id");
          if (lid == user_id) {
            //判断是不是主播，用户id和直播信息返回的id相等则为主播，否则不是
            this.is_live = true;
          } else {
            this.is_live = false;
          }
        });
    },

    //教研组结束直播
    endLive() {
      this.$confirm("确定要结束直播吗?", "提示", {
        confirmButtonText: "结束直播",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //从路由获取直播id
          let live_id = this.$route.query.id;
          //结束直播接口
          this.$axios.put(`live/room/${live_id}/end`).then(() => {
            this.$router.push("/researchCenter?navActive=2");
          });
        })
        .catch(() => {
          this.$message.warning("取消结束直播");
        });
    },

    //四名工作室结束直播
    TeachingStudioEndLive() {
      this.$confirm("确定要结束直播吗?", "提示", {
        confirmButtonText: "结束直播",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //结束直播接口
          this.$axios
            .put(`TeachingStudiolive/room/${this.live_id}/end`)
            .then(() => {
              this.$router.push("/researchCenter?navActive=2");
            });
        })
        .catch(() => {
          this.$message.warning("取消结束直播");
        });
    },

    //1.初始化weosocket
    initWebSocket() {
      //从路由获取直播id
      let id = this.$route.query.id;
      // 用户id
      let user_name = localStorage.getItem("user_name");
      //获取用户头像
      let photo = localStorage.getItem("photo_url");
      //域名：wss://im-ws.dccloud.com.cn 当前用户  username用户名  avatar   room:房间号
      const wsuri =
        "wss://im-ws.dccloud.com.cn?username=" +
        user_name +
        "&avatar=" +
        photo +
        "&room=hlwjy" +
        id;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //2.连接建立之后执行send方法发送数据
    websocketonopen() {
      setInterval(() => {
        //转为字符串发送 PING
        let obj = "PING";
        this.websocketsend(JSON.stringify(obj));
      }, 5000);
      //初始化获取在线人数
      this.getOnlineUser();
    },

    //获取用户列表   格式{"controller":"chat","action":"online"}
    getOnlineUser() {
      let userInfo = { controller: "chat", action: "online" };
      //转为字符串发送
      this.websocketsend(JSON.stringify(userInfo));
    },

    //3.连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //4.数据接收
    websocketonmessage(e) {
      if (e.data != "PONG") {
        let obj = JSON.parse(e.data);
        if (obj.content != "" && obj.action == 103) {
          //103类型是聊天
          this.onlineComment.push(obj);
          this.textarea = ""; //清空输入框
        } else if (obj.content != "" && obj.action == 202) {
          //202是人员
          this.onlineUser = obj.list;
        }
      }
    },

    //5.数据发送  注释重复调用，用户列表
    websocketsend(Data) {
      this.websock.send(Data);
    },

    //6.关闭
    websocketclose(e) {
      let pathName = this.$route.path;
      if (e.type == "close" && pathName == "/MyLive") {
        this.initWebSocket();
        console.log("离开页面再次启动");
      } else {
        this.websock.close();
      }
    },

    //发送用户信息
    sentUserInfo() {
      let userComment = {
        controller: "chat",
        action: "send",
        params: {
          type: "text",
          content: this.textarea,
        },
      };
      //转为字符串发送
      this.websocketsend(JSON.stringify(userComment));
    },

    //点击切换
    changType(val) {
      this.isShow = val;
      if (val == 2) {
        //用户列表调用方法
        this.getOnlineUser();
      }
    },

    //   -----------------以上为websocket

    //进入直播间计时
    startTimer() {
      this.seconds += 1;
      if (this.seconds >= 60) {
        this.seconds = 0;
        this.minutes = this.minutes + 1;
      }

      if (this.minutes >= 60) {
        this.minutes = 0;
        this.hour = this.hour + 1;
      }
      this.$refs.startTimer.innerHTML =
        "已直播：" +
        (this.minutes < 10 ? "0" + this.minutes : this.minutes) +
        ":" +
        (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },

    //表情包组件方法
    selectEmoji(emoji) {
      //    console.log(emoji)

      //获取文本框节点
      let input = document.getElementById("input");
      let startPos = input.selectionStart; //输入框光标初始位置
      let endPos = input.selectionEnd; //输入框光标初始位置
      let resultText =
        input.value.substring(0, startPos) +
        emoji.data +
        input.value.substring(endPos);
      input.value = resultText;
      input.focus();
      input.selectionStart = startPos + emoji.data.length;
      input.selectionEnd = startPos + emoji.data.length;
      this.textarea = resultText;
    },

    //视频内置方法
    live() {
      const dp = new DPlayer({
        live: true,
        autoplay: true,
        container: document.getElementById("dplayer"), //获取直播页面dom
        video: {
          // url: 'https://live.carvedu.com/a/b.m3u8?auth_key=1589281526-0-0-02d0913b6a725efdb53f0deed823e418', // 示例地址
          url: this.videoUrl, // 示例地址
          type: "customHls",

          customType: {
            customHls: function (video, player) {
              const hls = new Hls();
              hls.loadSource(video.src);
              hls.attachMedia(video);
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myLive {
  //    padding-left: 20px;
  // 左边视频
  //  width: 100%;
  //  display: flex;
  //  justify-content: center;
  .topBox {
    width: 100%;
    //播放器标题
    .topTitle {
      display: flex;
      background: #1d1d1d;
      height: 60px;

      justify-content: space-between;
      align-items: center;
      //左边标题
      .oneBox {
        span {
          color: #fff;
          margin-left: 20px;
          font-size: 16px;
        }
      }
      //右边时间和结束按钮
      .twoBox {
        display: flex;
        align-items: center;
        margin-right: 60px;
        img {
          width: 23px;
          height: 23px;
        }
        span {
          margin-left: 12px;
          color: #06a7ff;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }

    //底部播放器和人员列表
    .botmBox {
      display: flex;
      //  左边播放器
      .leftBox {
        width: 100%;
        //播放器样式
        #dplayer {
          width: 100%;
          height: 915px;
        }
      }

      //右边人员和聊天
      .rightBox {
        width: 300px;
        height: 915px;
        background: #333333;
        //筛选盒子
        .selectBox {
          width: 300px;
          height: 48px;
          background: #3f3f3f;
          display: flex;
          justify-content: space-around;
          align-items: center;
          //聊天盒子
          .chartBox {
            color: #8590a6;
            font-size: 16px;
            cursor: pointer;
            font-weight: bold;
            height: 48px;
            line-height: 48px;
          }
          //激活聊天盒子
          .activeBox {
            color: #06a7ff;
            height: 48px;
            font-size: 16px;
            cursor: pointer;
            font-weight: bold;
            border-bottom: 2px solid #06a7ff;
            line-height: 48px;
          }

          //图标盒子
          .imgBox {
            width: 20px;
            height: 20px;
            margin-top: 5px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
        }

        //内容盒子
        .contentList {
          height: 597px;
          overflow-y: scroll;
          //滚动条样式
          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
          }
          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 2px;
            background-color: #cccccc;
            background-image: -webkit-linear-gradient(
              45deg,
              rgba(255, 255, 255, 0.2) 25%,
              transparent 25%,
              transparent 50%,
              rgba(255, 255, 255, 0.2) 50%,
              rgba(255, 255, 255, 0.2) 75%,
              transparent 75%,
              transparent
            );
          }
          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #ededed;
            border-radius: 2px;
          }

          //头部搜索
          .oneBox {
            .input1 {
              width: 250px;
              margin-top: 10px;
              margin-left: 30px;
            }
            /deep/ .el-input__inner {
              background-color: #333333;
              border: 1px solid #666666;
            }
          }
          //下面列表
          .peopleList {
            .bigBox {
              margin-top: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 34px;
              .one {
                width: 35px;
                img {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  margin-top: 3px;
                }
              }
              .two {
                width: 200px;
                display: flex;
                justify-content: left;
                align-items: center;
                span {
                  color: #f3f3f3;
                  font-size: 14px;
                  margin: 0 10px;
                }
                .three {
                  color: #f3f3f3;
                  font-size: 14px;
                  background: #ff8201;
                  font-weight: bold;
                  padding: 1px 1px;
                  border-radius: 2px;
                }
              }
            }
          }

          // ---------------下面这部分是聊天样式
          .chartList {
            //  名字
            .tBox {
              display: flex;
              justify-content: left;
              align-items: center;
              font-weight: bold;
              height: 20px;
              font-size: 15px;
              padding: 10px 15px;
              span {
                color: #06a7ff;
                margin-right: 10px;
              }
              div {
                background: #ff8201;
                border-radius: 2px;
                color: #ffffff;
              }
            }
            //内容盒子
            .cBox {
              p {
                color: #fff;
                padding: 0 15px;
              }
            }
          }
        }

        //表情包和禁言盒子
        .middleBox {
          border: 1px solid #3f3f3f;
          display: flex;
          justify-content: space-between;
          //左边表情
          .faceBox {
            position: relative;
            z-index: 1;
            .Boxs {
              position: absolute;
              z-index: 2;
              left: -330px;
              top: -4px;
            }
          }
          //右边禁言
          .diableBox {
            width: 104px;
            /deep/ .el-input__inner {
              color: #606266;
              width: 100%;
              background-color: #333333;
              border: 1px solid #333333;
            }
          }
        }

        //输入框盒子
        .inputBox {
          /deep/ .el-textarea__inner {
            color: #cccccc;
            background-color: #333333;
            border: 1px solid #333333;
            border-radius: 4px;
          }
        }
        //按钮盒子
        .btn {
          margin-top: 10px;
          width: 60px;
          margin-left: 220px;
        }
      }
    }
  }
}
</style>