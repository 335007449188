<template>
  <div class="upload_resource public_main_bgc">
    <!-- 正文 -->
    <!-- 面包屑 -->
    <div class="ResourceBox">
      <div class="ResourceNav">
        <!-- 面包屑 -->
        <p style="font-size: 14px">
          <span style="color: #666666">当前位置：个人中心>我的资源></span>
          <span style="color: #ff8201">发布资源</span>
        </p>
      </div>
      <div class="main_block_two">
        <UploadResource :is_who="2"> </UploadResource>
      </div>
    </div>
  </div>
</template>

<script>
import UploadResource from "@/components/uploadResource";
export default {
  components: {
    UploadResource,
  },
  data() {
    return {
    }
  },
};
</script>

<style lang="less" >
.upload_resource {
  width: 100%;
  display: flex;
  justify-content: center;
  // background: #f7f7f7;
  .ResourceBox {
    width: 1400px;
    margin: 0 auto;
    .ResourceNav {
      line-height: 48px;
      height: 48px;
      // background: #f7f7f7;
    }
    .main_block_two {
      background: #fff;
      display: flex;
      justify-content: center;
      padding-top: 30px;
      margin-bottom: 50px;
    }
  }
}
</style>