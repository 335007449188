<template>
  <div>

    <div v-if="is_show_head==1" class="show_box_div">
      <StudioIndex></StudioIndex>
    </div>

    <div class="activityDetail public_main_bgc">
      <div class="w_d center pb20">
        <div class="new_bread">
          <div class="bread_content">
            <span class="span_one">当前位置：</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/researchCenter?navActive=1' }">教研活动</el-breadcrumb-item>
              <el-breadcrumb-item>
                <span class="font_orange">活动详情</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>

        <div class="activity-frame">
          <div class="activity-frame-head">
            <div class="activity-cover">
              <img :src="detailData.cover_url" />
            </div>
            <div class="activity-info">
              <h2 class="activity-title">{{ detailData.name }}</h2>
              <!-- <p class="activity-synopsis">
              简介
            </p> -->
              <div class="activity-field">
                <div class="activity-field-item">
                  <span class="field-label">活动类型：</span>{{ detailData.type == null ? "" : detailData.type }}
                </div>
                <div class="activity-field-item">
                  <span class="field-label">开展方式：</span>{{ detailData.implement_type }}
                </div>
                <div class="activity-field-item">
                  <span class="field-label">学段学科：</span>{{ detailData.stage_name == null ? "" : detailData.stage_name
                }}{{ detailData.subject_name == null ? "" : detailData.subject_name }}
                </div>
              </div>
              <p class="activity-date">
                活动时间：{{ detailData.start_date }} {{ detailData.start_time }} 至
                {{ detailData.end_date }} {{ detailData.end_time }}
              </p>
              <div class="activity-block mb20">
                <div class="activity-author">
                  <div class="author-cover">
                    <img :src="detailData.photo_url" />
                  </div>
                  <p class="author-name">{{ detailData.initiator_name || "" }}</p>
                </div>
                <a :href="detailData.group_go_url" target="_blank" class="activity-group">{{ detailData.initiator_type }}</a>
                <!-- <p class="activity-group">{{ detailData.initiator_type }}</p> -->
              </div>
              <div class="activity-block">
                <span class="activity-button-1 mr25" @click="onJoin()" v-if="detailData.checkUserJoin == 3 || detailData.checkUserJoin == 5">
                  我要参与
                </span>
                <span class="activity-button-1 mr25" v-if="detailData.checkUserJoin == 2">
                  报名审核中
                </span>
                <span class="activity-button-1 mr25" v-if="detailData.checkUserJoin == 6">
                  不允许报名
                </span>
                <!-- //参与活动成员待审核列表 -->
                <span class="activity-button-1 mr25" @click="screenWay(2)" v-if="detailData.checkUserJoin == 1">
                  待审核报名成员 ({{ checkList.length }})
                </span>
                <new-invitation :access_code="detailData.access_code" class="mr50"></new-invitation>
                <div class="activity-partake" @click="joinTeacher()">
                  <div class="partake-cover">
                    <img :src="item.photo_url" v-for="(item, index) in memberList" :key="index" v-if="index <= 10" />
                  </div>
                  <p class="partake-text">参与教师（{{ detailData.user_count }}）</p>
                </div>
              </div>

              <!-- 投屏 -->
              <!-- <div class="listOne" @click="screenWay(1)" v-if="!(detailData.user_id == user_id && detailData.audit_status != 4)">
                <img src="@/assets/images/Projection_icon.png" alt="" />
              </div> -->
            </div>
          </div>
          <div class="activity-frame-content">
            <div class="activity-frame-content-left">
              <linkNav ref="linkNav"></linkNav>
            </div>
            <div class="activity-frame-content-right">
              <router-view :key="$route.query.t" :activityInfoData="detailData"></router-view>
            </div>
          </div>
        </div>
        <!-- <div class="center_left">
        <div>
        <div class="introduce">
          <!-- <p class="introduce_one">活动介绍</p> --
          <div class="introduceSty df dfb" :class="link_name == 'basicinfo' ? 'active' : 'introduce_two'" @click="intoBasic">
            <p>活动基本信息</p>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <!-- <div class="heng_one"></div> --
        <p class="link_title">活动环节</p>
        <template v-if="false">
          <div class="link_block cursor" @click="getintoOther(item, index)" v-for="(item, index) in linkData" :key="index">
            <!--活动环节 样式 内嵌样式--
            <div :class="link_index === index ? 'activeOne' : 'block_one'">
              <i class="el-icon-circle-check ifin" v-if="item.finish_status == '2'"></i>
              <i class="el-icon-remove-outline inot" v-if="item.finish_status == '1'"></i>
              <p>{{ item.name }}</p>
            </div>
            <!-- 样式end --
            <div class="block_two finish" v-if="item.status == '3'">已结束</div>
            <div class="block_two notstart" v-else-if="item.status == '1'">
              未开始
            </div>
            <div class="block_two going" v-else-if="item.status == '2'">
              进行中
            </div>
          </div>
        </template>

        <div class="linkBox">
          <template v-for="(item, index) in linkData">
            <div :key="index" class="link_item" @click="getintoOther(item, index)">
              <div class="icon_box">
                <img v-if="item.type==1" src="@/assets/huodon_img/资源浏览@2x.png" alt="" />
                <img v-if="item.type==2" src="@/assets/huodon_img/资源上传@2x.png" alt="" />
                <img v-if="item.type==3" src="@/assets/huodon_img/讨论@2x.png" alt="" />
                <img v-if="item.type==4" src="@/assets/huodon_img/问卷@2x.png" alt="" />
                <img v-if="item.type==5" src="@/assets/huodon_img/投票@2x.png" alt="" />
                <img v-if="item.type==6" src="@/assets/huodon_img/在线文档@2x.png" alt="" />
                <img v-if="item.type==7" src="@/assets/huodon_img/在线直播@2x.png" alt="" />
                <img v-if="item.type==8" src="@/assets/huodon_img/教研会议@2x.png" alt="" />
              </div>
              <div class="name_box" :class="link_index === index ? 'active' : '' ">{{ item.name }}</div>
              <div v-if="item.status==1" class="name_state_box name_state_3">未开始</div>
              <div v-if="item.status==2" class="name_state_box name_state_1">进行中</div>
              <div v-if="item.status==3" class="name_state_box name_state_2">已结束</div>
            </div>
          </template>
        </div>
      </div>
      </div>
      <div class="center_right">
        <router-view @cancelLink="toInit" :key="$route.query.t"></router-view>
      </div> -->
      </div>

      <!-- 投屏/审核报名成员弹窗 -->
      <MyDialog :open="open" :title="title" @onClose="onClose" :width="width">
        <div>
          <ListOne :typeVal="is_who" :activityId="+activityId" :list="checkList" @onCallBack="onWaitCheckList" />
        </div>
      </MyDialog>

      <MyDialog :open="joinTeacherOpen" title="参与教师" @onClose="joinTeacherOpen = false" width="766px">
        <ListOne :typeVal="4" :list="memberList" />
      </MyDialog>
    </div>
  </div>
</template>

<script>
import {
  ApplyJoinActivity,
  WaitCheckList,
  StudipApplyJoinActivity,
  SecretLogin,
} from "@/api/teacherStudio";
import linkNav from "./components/linkNav/linkNav.vue";
import ListOne from "@/components/CommonList/ListOne.vue";
import { isHasStudioIdFn } from "@/utils/utils.js";
export default {
  components: {
    linkNav,
    ListOne,
  },
  data() {
    return {
      // //定义一个控制样式的变量
      // activeClass: -1,
      // activeClass0: -1,
      // // 活动id
      // activityId: "",
      // // 环节列表
      // linkData: [],
      // // 环节名字
      // link_name: "",
      // // 活动环节活动项
      // link_index: "",
      // // 1教研活动详情，2四名工作室活动详情
      // is_from: 1,

      // 1教研活动模块，2四名工作室活动模块
      is_who: 1,
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
      //活动详情数据
      detailData: {},
      open: false,
      title: "请选择要投屏的电脑或教师",
      width: "38%",
      user_id: localStorage.getItem("id"),
      checkList: [],
      memberForm: {
        is_personid: this.$route.query.is_from ? this.$route.query.is_from : "",
        activity_id: 0,
        limit: 100000,
        page: 1,
      },
      memberList: [],
      joinTeacherOpen: false,
      is_show_head: null,
    };
  },
  created() {
    if (this.$route.query.is_show_head) {
      if (isHasStudioIdFn(this)) {
        this.is_show_head = this.$route.query.is_show_head
      }
    };
    if (this.$route.query.key) {
      SecretLogin({ key: this.$route.query.key }).then((res) => {
        const dataObj = res.data;
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj));
        //把瓣学网登录的权限存在缓存里
        localStorage.setItem("aes_code", dataObj.aes_code);

        //将用户所有信息存进缓存
        localStorage.setItem("user_name", dataObj.username);

        //把token存在缓存里
        localStorage.setItem("token", dataObj.token);

        //把用户id存进缓存里
        localStorage.setItem("id", dataObj.id);

        this.isShow = 1;
        //把登录显示放进缓存里
        localStorage.setItem("isShow", this.isShow);
        //把用户头像放进缓存
        localStorage.setItem("photo_url", dataObj.photo_url);
        setTimeout(() => {
          this.$router.push({
            path: `/basicinfo?module=${this.$route.query.module}&activityId=${this.$route.query.activityId}&is_from=${this.$route.query.is_from}`,
          });
          location.reload();
        }, 500);
      });
    } else {
      this.init();
    }
  },
  methods: {
    init() {
      this.activityId = this.$route.query.activityId;
      this.is_from = this.$route.query.is_from || 1;
      this.getDetail();
      this.getMember();
      if (this.is_from == 1) {
        // 待审核列表
        this.onWaitCheckList();
      }
    },
    // 请求后台获取活动详情
    getDetail() {
      //教研组活动、个人活动
      if (this.is_from == 1) {
        this.$axios.get(`activity/${this.activityId}`).then((res) => {
          this.detailData = res.data.data;
          console.log(this.detailData, "活动详情");
          // is_who: 1个人，2教研组
          // this.detailData.is_who = 1;
          // permit:1所有人，2自定义
          // this.detailData.permit = 1;
          // sign_up:1开启，2关闭
          // this.detailData.sign_up = 1;
          //this.onGetAchievement();
          this.setActivityIsJoin();
        });
      } else {
        this.$axios
          .get(`index/teachingStudioActivity/info`, {
            params: { id: this.activityId },
          })
          .then((res) => {
            this.detailData = res.data.data;
            this.setActivityIsJoin();
          });
      }
    },
    //设置加入权限
    setActivityIsJoin() {
      let state = ""
      if (this.is_from == 1) {
        state = this.detailData.checkUserJoin == 1 || this.detailData.checkUserJoin == 4 ? 1 : 0;
      }
      if (this.is_from == 2) {
        state = this.detailData.join_permission.hasOwnProperty("state") && this.detailData.join_permission.state == 1 ? 1 : 0;
      }
      localStorage.setItem(
        "activity_isJoin",
        state
      );
      this.$store.commit("setActivity_isJoin", state);
    },
    //活动投屏
    screenWay(type) {
      // 从缓存中得到用户名
      //   let userName = localStorage.getItem("user_name");
      //将用户名使用unicode编码
      //let b= setUnicode(userName);
      //   let b = escape(userName);
      //   window.open("DCYJTP://Type:1_User:" + b);

      if (type === 1) {
        if (!this.detailData.join_TeachingStudio) {
          return this.$message.warning("请先报名参与");
        }
        // 投屏
        this.title = "请选择要投屏的电脑或教师";
        this.is_who = 1;
        this.width = "38%";
      } else {
        // 审核报名成员
        this.title = "审核报名成员";
        this.is_who = 2;
        this.width = "621px";
      }
      this.open = true;
    },
    // 关闭弹窗后的操作
    onClose() {
      this.open = false;
    },
    // 待审核列表
    onWaitCheckList() {
      let obj = {
        activity_id: this.activityId,
        limit: 5000,
        page: 1,
      };
      WaitCheckList(obj).then((res) => {
        if (res.data.data.length) {
          this.checkList = JSON.parse(
            JSON.stringify(res.data.data).replace(/realname/g, "username")
          );
        } else {
          this.checkList = [];
        }
      });
    },
    // 我要参与
    onJoin() {
      // state: null未加入工作室,0待审核,1审核通过,2审核不通过
      if (this.detailData.checkUserJoin == 3) {
        // 有参与权限
        let msg = "已发起参与报名，请耐心等候审核结果";
        if (this.is_from == 1) {
          this.onJoinGroupApi(msg);
        } else {
          this.onJoinApi(msg);
        }
      } else {
        // 无参与权限
        this.$refs.joinStudioRef.open = true;
      }
    },
    // 教研组参与接口
    onJoinGroupApi(msg) {
      ApplyJoinActivity({
        activity_id: this.activityId,
        user_id: this.user_id,
      }).then((res) => {
        msg && this.$message.success(msg);
        this.getDetail();
      });
    },
    onCallBack() {
      //   let msg = "参与成功，请等待审核";
      let msg = "";
      if (this.is_from == 1) {
        this.onJoinGroupApi(msg);
      } else {
        this.onJoinApi(msg);
      }
    },
    // 四名参与接口
    onJoinApi(msg) {
      StudipApplyJoinActivity({
        activity_id: this.activityId,
        user_id: this.user_id,
      }).then((res) => {
        msg && this.$message.success(msg);
        this.getDetail();
      });
    },
    //获取活动成员
    getMember() {
      this.memberForm.activity_id = this.activityId;
      this.$axios
        .get(`index/UserActivity/UserActivityLists`, { params: this.memberForm })
        .then((res) => {
          let data = res.data;
          if (data.code == 900) {
            this.memberList = data.data.data;
          }
        });
    },
    joinTeacher() {
      this.joinTeacherOpen = true;
    },
    // //初始化函数
    // init() {
    //   this.activityId = this.$route.query.activityId;
    //   this.is_from = this.$route.query.is_from || 1;
    //   this.getActivityLink();
    //   this.link_name = this.$route.name;
    //   if (this.link_name != "basicinfo") {
    //     this.link_index = Number(window.localStorage.getItem("link_index"));
    //   }
    // },
    // //子向父刷新
    // toInit(val) {
    //   if (val) {
    //     this.init();
    //   }
    // },
    // // 跳转到活动详情页面
    // intoBasic() {
    //   this.activeClass = 1;
    //   this.$router.push({
    //     name: "basicinfo",
    //     query: {
    //       activityId: this.activityId,
    //       is_from: this.is_from,
    //     },
    //   });
    // },
    // // 请求后台获取环节列表
    // getActivityLink() {
    //   if (this.is_from == 1) {
    //     this.$axios.get(`activity/${this.activityId}/activityLinks`).then((res) => {
    //       this.linkData = res.data.data;
    //     });
    //   } else {
    //     this.$axios.get(`TeachingStudioactivity/${this.activityId}/activityLinks`).then((res) => {
    //       this.linkData = res.data.data;
    //     });
    //   }
    // },
    // one(str) {
    //   this.$router.push(str);
    // },
    // // 跳转到其他页面
    // getintoOther(item, index) {
    //   if (!localStorage.getItem("activity_isJoin")) {
    //     // 没有浏览权限
    //     return this.$message.warning("请先报名参与");
    //   }
    //   //index是下标,
    //   this.activeClass0 = index;
    //   window.localStorage.setItem("link_index", index);
    //   switch (Number(item.type)) {
    //     //资源浏览环节1
    //     case 1:
    //       this.$router.push({
    //         name: "resourcebrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     //资源上传环节1
    //     case 2:
    //       this.$router.push({
    //         name: "resourceuploadbrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     //讨论环节1
    //     case 3:
    //       this.$router.push({
    //         name: "discussbrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     // 问卷环节1
    //     case 4:
    //       this.$router.push({
    //         name: "questionbrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     // 投票环节1
    //     case 5:
    //       this.$router.push({
    //         name: "votebrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     // 协作文档环节1
    //     case 6:
    //       this.$router.push({
    //         name: "collaborationbrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     // 在线直播环节1
    //     case 7:
    //       this.$router.push({
    //         name: "livebrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     // 教研会议环节1
    //     case 8:
    //       this.$router.push({
    //         name: "meetbrowsing",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //     // 在线文档环节
    //     case 9:
    //       this.$router.push({
    //         name: "OnLineWord",
    //         query: {
    //           activityId: this.activityId,
    //           is_from: this.is_from,
    //           id: item.id,
    //           t: Date.now(),
    //         },
    //       });
    //       break;
    //   }
    // },
  },
  computed: {

    // //判断是否显示待审批
    // isAuditData() {
    //   //有参与权限   已报名    并未审核
    //   if (
    //     this.detailData.join_permission.state == 1 &&
    //     this.detailData.join_permission.audit_status == 1 &&
    //     this.detailData.join_TeachingStudio
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
};
</script>

<style scoped lang="less">
.show_box_div {
  /deep/.mainBox {
    height: 0 !important;
    display: none !important;
  }
}
.activity-frame {
  .activity-frame-head {
    width: 100%;
    height: 274px;
    padding: 30px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    display: flex;
    position: relative;
    .activity-cover {
      width: 380px;
      height: 214px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .activity-info {
      flex: 1;
      overflow: hidden;
      .activity-title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        //margin-bottom: 12px;
        margin-bottom: 20px;
      }
      .activity-synopsis {
        font-size: 16px;
        color: #999999;
        margin-bottom: 4px;
      }
      .activity-field {
        display: flex;
        .activity-field-item {
          font-size: 16px;
          color: #000000;
          margin-right: 80px;
          .field-label {
            color: #999999;
          }
        }
      }
      .activity-date {
        font-size: 16px;
        font-weight: bold;
        color: #ff7e24;
        //padding: 12px 0;
        padding: 17px 0;
      }
      .activity-block {
        display: flex;
        align-items: center;
        .activity-author {
          align-items: center;
          display: flex;
          margin-right: 40px;
          .author-cover {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 6px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .author-name {
            font-size: 16px;
            color: #000000;
          }
        }
        .activity-group {
          font-size: 16px;
          color: #000000;
          &:hover {
            text-decoration: underline;
          }
        }

        .activity-button-1 {
          width: 169px;
          height: 46px;
          background: #3489fe;
          border-radius: 8px;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .activity-button-2 {
          width: 98px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #3489fe;
          border-radius: 8px;
          font-size: 16px;
          color: #3489fe;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          cursor: pointer;
        }

        .activity-partake {
          display: flex;
          align-items: center;
          cursor: pointer;
          .partake-cover {
            margin-right: 26px;
            img {
              width: 24px;
              height: 24px;
              border-radius: 100%;
              overflow: hidden;
              margin-right: -12px;
            }
          }
          .partake-text {
            font-size: 16px;
            color: #666666;
            line-height: 16px;
          }
        }
      }
      .listOne {
        width: 0;
        height: 0;
        border-top: 54px solid #3489ff;
        border-left: 54px solid transparent;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        > img {
          transform: translate(-26px, -46px);
        }
      }
    }
  }
  .activity-frame-content {
    padding-top: 20px;
    display: flex;
    .activity-frame-content-left {
      margin-right: 20px;
    }
    .activity-frame-content-right {
      flex: 1;
      overflow: hidden;
    }
  }
}

.public_main_bgc {
  background: #eef2fb;
  min-height: calc(100vh - 180px) !important;
}
.activityDetail {
  display: flex;
  justify-content: center;
  /deep/ .is-link {
    color: #606266;
    font-weight: 400;
  }
  .center {
    // border: 1px solid black;
    // display: flex;
    // justify-content: space-between;
    .center_left {
      width: 264px;
      background: #ffffff;
      border-radius: 6px;
      padding-bottom: 30px;
      //   height:626px;
      //   display: inline-block;
      float: left;
      .introduce {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 16px 14px;
        .introduceSty {
          width: 100%;
          height: 100%;
          background: rgba(233, 243, 255, 1);
          border-radius: 8px;
          align-items: center;
          padding: 0 16px;
          background-color: #e9f3ff;
          color: #3489fe;
        }

        .introduce_one {
          margin: 30px 0px 24px 30px;
          font-weight: 700;
        }
        .introduce_two {
          cursor: pointer;
          display: flex;
        }
        .active {
          cursor: pointer;
          color: #3489fe;
          display: flex;
        }
      }
      .heng_one {
        width: 100%;
        height: 1px;
        background: #ebebeb;
        margin-bottom: 30px;
      }
      .link_title {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        padding-left: 28px;
        padding-top: 14px;
        padding-bottom: 20px;
      }
      .link_block {
        // height: 83px;
        padding: 0 27px;
        // line-height: 83px;
        // margin: 0px 15px 0px 30px;
        // border-bottom: 1px dashed #ececec;
        margin-top: 20px;
        .block_one {
          display: flex;
          align-items: center;
          // margin-top: 24px;
          margin-bottom: 12px;
          // i:nth-child(1) {
          //   color: #ffa84f;
          //   margin-right: 14px;
          // }
          .ifin {
            color: #ffa84f;
            margin-right: 14px;
          }
          .inot {
            color: #999999;
            margin-right: 14px;
          }
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 36px;
          }
        }
        //点击样式
        .activeOne {
          display: flex;
          align-items: center;
          // margin-top: 24px;
          margin-bottom: 12px;
          color: #3489fe;
          .ifin {
            color: #ffa84f;
            margin-right: 14px;
          }
          .inot {
            color: #999999;
            margin-right: 14px;
          }
          p {
            width: 203px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 36px;
          }
        }
        .block_two {
          margin-left: 32px;
          width: 51px;
          height: 18px;
          border-radius: 9px;
          margin-bottom: 17px;
          font-size: 12px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .finish {
          background: #cecece;
        }
        .notstart {
          background: #3489fe;
        }
        .going {
          background: #ff8201;
        }
      }
      .link_block:last-child {
        border-bottom: 0px;
      }
      .linkBox {
        width: 100%;
        padding-left: 28px;
        padding-right: 28px;
        box-sizing: border-box;
        .link_item {
          display: flex;
          align-items: center;
          height: 70px;
          cursor: pointer;
        }
        .icon_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name_box {
          flex: 1;
          margin-left: 8px;
          font-size: 16px;
          line-height: 20px;
          font-weight: normal;
          color: rgba(51, 51, 51, 1);
          margin-right: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          &.active {
            color: #3489fe;
          }
        }
        .name_state_box {
          width: 51px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          border-radius: 4px;
          font-size: 12px;
          font-weight: normal;
        }
        .name_state_1 {
          border: 1px solid rgba(255, 136, 14, 1);
          color: rgba(255, 136, 14, 1);
        }
        .name_state_2 {
          border: 1px solid #cecece;
          color: #cecece;
        }
        .name_state_3 {
          border: 1px solid #3489fe;
          color: #3489fe;
        }
      }
    }
    .center_right {
      width: 1116px;
      float: right;
      // height:500px;
      // border: 1px solid black;
      //   overflow: hidden;
    }
  }
  // 顶部面包屑样式
  .new_bread {
    height: 48px;
    line-height: 48px;
    .bread_content {
      display: flex;
      align-items: center;
      margin-top: 0px;

      .span_one {
        font-size: 14px;
        color: #666666;
      }

      .font_orange {
        color: #ff8910;
      }
    }

    .bread_hengxian {
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
      margin-top: 32px;
      margin-bottom: 40px;
    }
  }
}
</style>
