<template>
  <div class="collaborationbrowsing study-active-introduce">
    <div class="title">{{ resourceData.name }}</div>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="one_box">
        <span class="link-date">环节时间：{{ resourceData.start_date }} 至 {{ resourceData.end_date }}</span>

        <el-popover placement="top" width="" trigger="click" v-if="isEdit">
          <!-- <el-date-picker v-model="activityInfoTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeTImeEvent">
          </el-date-picker> -->
          <div>
            <el-date-picker v-model="liveDate.start_date" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 200px; margin-right: 10px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="选择开始时间" v-model="liveDate.start_time" value-format="HH:mm" format="HH:mm"
              style="width: 100px; margin-right: 5px" @change="change_start"></el-time-picker>
            <span>- </span>
            <el-time-picker type="fixed-time" placeholder="选择结束时间" v-model="liveDate.end_time" value-format="HH:mm" format="HH:mm"
              style="width: 100px; margin-right: 5px"></el-time-picker>
            <el-button type="primary" @click="modifyDate()">修改</el-button>
          </div>
          <div class="change_time_div_btn" slot="reference" @click="openTImeSelectBox">
            更改时间
          </div>
        </el-popover>
        <el-dialog title="" :visible.sync="time_visible" width="500px" top="20vh">
          <div style="text-align: center;">
            <el-button type="primary" @click="handleSync(2)" v-if="activityInfoData.audit_status == 1">确定</el-button>
            <template v-else>
              <el-button type="primary" @click="handleSync(1)">确认并同步活动状态</el-button>
              <el-button type="primary" @click="handleSync(2)">不同步活动状态</el-button>
            </template>
            <el-button @click="time_visible = false">取消</el-button>
          </div>
        </el-dialog>

        <div v-if="resourceData.status ==1" class="conduct nostart"> 未开始 </div>
        <div v-if="resourceData.status ==2" class="conduct going"> 进行中 </div>
        <div v-if="resourceData.status ==3" class="conduct end"> 已结束 </div>
        <EndLink :id="id" :is_from="is_from" :status="resourceData.status" @success="endLinkSuccess" v-if="isEdit"></EndLink>
      </div>
      <div class="two">
        <span>环节类型：在线直播</span>
        <span>开展方式：{{
            resourceData.implement_type == "1" ? "线上" : "线下"
          }}</span>
        <span>参与人数:{{ resourceData.user_count }}人</span>
      </div>
      <div class="public-line mb30"></div>
      <div class="three">环节描述</div>
      <div class="four">
        <div v-bind:class="{ expansion: textActive }" class="show_text_all">
          <p class="rich_text_div_img" v-html="resourceData.description"></p>
        </div>
      </div>
      <div class="public-line mb30"></div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->
      <div v-if="resourceData.implement_type == '1'">
        <div class="nine">
          <div class="images">
            <img :src="resourceData.live_data.cover_url" />
          </div>
          <div class="nine_word">
            <p>
              {{ resourceData.live_data.title }}

            </p>
            <p>
              <span style="color:#999;">主播：</span>
              <span>{{ resourceData.live_data.anchor_user }}</span>

              <!-- <span v-if="isLive">
                <span style="color:#999;margin-left:30px">服务器：</span>
                <span style="color:#3489fe">{{resourceData.live_data.rtmp_url}} <i class="el-icon-document"
                    v-clipboard:copy="resourceData.live_data.rtmp_url" v-clipboard:success="onCopy" v-clipboard:error="onError"></i> </span>

              </span> -->

            </p>

            <p>
              <span style="color:#999;">开播时间：</span>
              {{ resourceData.live_data.time }}

              <!-- <span v-if="isLive">
                <span style="color:#999;margin-left:20px">密钥：</span>{{resourceData.live_data.rtmp_key }}
                <i class="el-icon-document" v-clipboard:copy="resourceData.live_data.rtmp_key" v-clipboard:success="onCopy"
                  v-clipboard:error="onError"></i>
              </span> -->
            </p>

            <!-- <p v-if="isLive" style="color:#999;margin-top:5px"> 注：主播必须复制服务器密钥信息开启OBS直播工具,才能直播</p> -->

          </div>

          <!-- 未开始  并 是主播   显示开始直播按钮 -->
          <div class="enter_live" @click="startLive()" v-if="isLive && (resourceData.live_data.status == 1 || resourceData.live_data.status == 2)">
            开始直播
          </div>
          <div v-if="!isLive && resourceData.live_data.status == 2 && $store.state.activity_isJoin" class="enter_live" @click="enterLive()">
            进入直播
          </div>
          <div v-if="resourceData.live_data.status == 3 && $store.state.activity_isJoin && resourceData.live_data.review_url" class="enter_live" @click="enterLive()">
            直播回放
          </div>
          <div v-else-if="resourceData.live_data.status == 3 && $store.state.activity_isJoin" class="end_live">
            直播已结束
          </div>


<!-- 原本版直播
          <!-- 未开始  并 不是主播 --
          <div v-if="resourceData.live_data.status == 1 && !isLive">
            <el-button type="info">未开始</el-button>
          </div>

          <!-- 未开始  并 是主播   显示开始直播按钮 --
          <div v-if="resourceData.live_data.status == 1 && isLive">
            <div class="enter_live" @click="statLive(resourceData.live_data.live_id)">
              开始直播
            </div>
          </div>

          <!--进行中 是主播 --
          <div v-if="resourceData.live_data.status == 2" class="enter_live" @click="getintoRoom()">
            进入直播间
          </div>

          <!-- 已结束 --
          <div v-if="resourceData.live_data.status == 3">
            <!-- 活动创建人 --
            <div v-if="isLive">
              <!-- 已上传 --
              <div v-if="resourceData.live_data.playback_resource_url">
                <new-viewonline :viewSrc="resourceData.live_data.playback_resource_url" :fileExt="resourceData.live_data.ext">
                  <div class="cursor enter_live look">查看回放</div>
                </new-viewonline>
                <MyUpload @onGetId="onGetId" up_class="fcc">
                  <el-button type="text">更换视频</el-button>
                </MyUpload>
              </div>
              <!-- 未上传 --
              <MyUpload @onGetId="onGetId" v-else>
                <div class="cursor enter_live look">上传回放</div>
              </MyUpload>
            </div>
            <!-- 参与人 --
            <div v-else>
              <!-- 未上传 --
              <!-- <p v-if="0">无显示内容</p> --
              <!-- 已上传 --
              <new-viewonline :viewSrc="resourceData.live_data.playback_resource_url" :fileExt="resourceData.live_data.ext"
                v-if="resourceData.live_data.playback_resource_url">
                <div class="cursor enter_live look">
                  查看回放
                </div>
              </new-viewonline>
            </div>
          </div>
-->


        </div>
      </div>
      <!-- 线下区域 -->
      <UnderLineDetail :is_from="+is_from" :resourceData="resourceData" v-else-if="resourceData.implement_type == '2'"
        @getResourceData="getResourceData" :activityId="+activityId" :linkId="+id" />
    </div>

    <div class="live-plug-in" v-if="isLive">
      <span class="plug-in-button" @click="downloadLiveSoftware()">下载直播软件</span>
    </div>
  </div>
</template>

<script>
import { localGet, localSet, localRemove } from "@/utils/utils.js";
import UnderLineDetail from "./components/underlineDetail/index.vue";
import {
  ActiveHjDetail,
  StudioSaveVideo,
  JySaveVideo,
} from "@/api/teacherStudio";
import EndLink from "./components/endLink/endLink.vue";
import * as researchApi from '@/api/researchApi.js';
export default {
  components: {
    UnderLineDetail,
    EndLink
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      time_visible: false,
      time_reqObj: {},
      activityInfoTime: [],
      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 资源浏览数据
      resourceData: {},
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",
      // 文本是否隐藏
      textActive: true,

      //线下表单
      xianxiaform: {
        name: "",
        accessoryId: "",
        url: "",
      },
      //obs配置信息
      obsData: {},
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
      unserInfoData: {},
      liveDate: {
        start_date: "",
        start_time: "",
        end_time: ""
      }
    };
  },
  created() {
    this.init();
  },
  computed: {
    //判断是不是主播
    isLive() {
      let uid = localStorage.getItem('id');
      if (uid == this.resourceData.live_data.anchor_user_id) {
        return true
      } else {
        return false
      }
    },
    //判断是否能编辑
    isEdit() {
      let uid = localStorage.getItem('id');
      if (uid == this.resourceData.user_id) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    // 点击更改时间
    openTImeSelectBox() {
      this.activityInfoTime = [`${this.resourceData.start_date}`, `${this.resourceData.end_date}`]
    },
    // 同步迟环节
    async handleSync(num) {
      try {
        let time_reqObj = this.time_reqObj;
        time_reqObj['is_sync_status'] = num;
        time_reqObj['is_personid'] = this.is_from;
        let res_1 = await researchApi.get_activity_activitylink_live_update_start_end_date(time_reqObj);
        this.time_visible = false;
        if (res_1.data.data.status) {
          this.$set(this.resourceData, "status", res_1.data.data.status);
          this.$set(this.resourceData, "start_date", res_1.data.data.start_date);
          this.$set(this.resourceData, "end_date", res_1.data.data.end_date);
        };
        this.$message.success("操作成功");
        this.$parent.$refs.linkNav.init();
      } catch (error) {
        this.time_visible = false;
      }
    },
    // 确定更改l环节时间
    changeTImeEvent(e) {
      if (e) {
        let time_reqObj = {
          id: this.id,
          start_date: e[0],
          end_date: e[1],
        };
        this.time_visible = true;
        this.time_reqObj = time_reqObj;
      }
    },
    // 初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("use_rname");
      this.userId = window.localStorage.getItem("id");
      this.unserInfoData = localGet("unserInfoData");
      this.getResourceData();
    },
    //开始直播
    statLive(live_id) {
      //教研组和个人中心活动直播
      // if (this.is_from == 1) {
      this.$axios.put(`TeachingStudioActivityLive/room/${live_id}/start/${this.is_from}`).then(() => {
        this.$message.success('启动直播成功,请进入直播间');
        this.init()
      })
      // }else{
      //   this.$axios.put(`TeachingStudiolive/room/${live_id}/start`).then(()=>{
      //         this.$message.success('启动直播成功,请进入直播间');
      //         this.init() 
      //    })

      // }
    },
    //请求后台获取资源浏览数据
    getResourceData() {
      if (this.is_from == 1) {
        //获取教研组活动直播
        this.$axios.get(`activity/${this.activityId}/activityLink/${this.id}`).then((res) => {
          this.resourceData = res.data.data;
          const start_date = this.resourceData.start_date.split(" ");
          const end_date = this.resourceData.end_date.split(" ");
          this.liveDate.start_date = `${start_date[0]} 00:00:00`;
          this.liveDate.end_time = `${end_date[1].split(":")[0]}:${end_date[1].split(":")[1]}`;
          this.liveDate.start_time = `${start_date[1].split(":")[0]}:${start_date[1].split(":")[1]}`;
        });
      } else {
        //获取四名工作室活动直播
        ActiveHjDetail(this.activityId, this.id).then((res) => {
          this.resourceData = res.data;
        });
      }
    },
    //粘贴和复制
    onCopy() {
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    onError() {
      this.$message.error('复制失败');
    },
    //跳转进入直播间(通用直播)
    enterLive() {
      if (this.resourceData.live_data.live_url) {
        window.open(this.resourceData.live_data.live_url);
      } else {
        this.$message.error("直播室还没准备就绪，请刷新页面再试");
      }
    },
    // 跳转进入直播间
    getintoRoom() {
      //直播中状态才能进入直播
      if (this.resourceData.status == "2") {
        //转换要传递的对象
        let obj = JSON.stringify(this.resourceData.live_data)
        this.$router.push({
          path: "/ActivityLive",
          query: {
            id: this.resourceData.live_data.live_id,
            is_from: this.is_from,
            LiveInfo: encodeURIComponent(obj)
          },
        });

      } else if (this.resourceData.status == "1") {
        this.$message.info("活动环节未开始");
      } else if (this.resourceData.status == "3") {
        this.$message.info("活动环节已结束");
      }
    },
    // 获取上传回放id，并请求接口上传直播视频
    onGetId({ id }) {
      let data = {
        live_id: this.resourceData.live_data.live_id,
        resource_id: id,
      };
      // let p = null;
      // // 保存直播视频回放
      // if (this.is_from == 1) {
      //   p = JySaveVideo(data);
      // } else {
      //   p = StudioSaveVideo(data);
      // }
      this.$axios.put(`TeachingStudioActivityLive/uploadPlayback/${this.is_from}`, data).then(() => {
        this.$message.success("上传成功");
        this.getResourceData();
      });
    },
    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    //直播创建者展示直播信息
    showLiveInfo() {
      this.$axios
        .get(`ActivityLive/${this.resourceData.live_data.live_id}/config`)
        .then((res) => {
          this.obsData = res.data.data;
          this.$alert(
            `<p><span style="margin-right:20px">"rtmp_url":</span>${this.obsData.rtmp_url}</p><p><span style="margin-right:20px">"trmp_key":</span>${this.obsData.rtmp_key}</p>`,
            "obs直播配置",
            {
              confirmButtonText: "确定",
              dangerouslyUseHTMLString: true,
            }
          );
        });
    },
    startLive() {
      //window.open(`liveav://${this.unserInfoData.sid},2,${this.resourceData.live_data.ykt_id}`);
      if (this.resourceData.live_data.live_url) {
        window.open(this.resourceData.live_data.live_url);
      } else {
        this.$message.error("直播数据还没生成，请刷新页面再试");
      }
    },
    //结束环节
    endLinkSuccess(status) {
      this.$set(this.resourceData, "status", status);
      this.$parent.$refs.linkNav.init();
    },
    // 自动增加 3小时
    change_start(e) {
      if (!e) {
        return false;
      };
      let hour = e.split(":")[0];
      let minute = e.split(":")[1];
      hour = Number(hour) + 3;
      let end_time = hour > 23 ? "23:59" : `${hour < 10 ? "0" : ""}${hour}:${minute}`;
      this.$set(this.liveDate, "end_time", end_time);
    },
    modifyDate() {
      this.time_visible = true;
      this.time_reqObj = {
        id: this.id,
        is_personid: this.is_from,
        start_date: this.liveDate.start_date,
        end_time: this.liveDate.end_time,
        start_time: this.liveDate.start_time,
      };
    },
    downloadLiveSoftware() {
      window.open(this.resourceData.live_data.download_url);
    }
  },
};
</script>

<style scoped lang="less">
.collaborationbrowsing {
  width: 1116px;
  //   height: 2157px;
  background: #ffffff;
  border: NaNpx solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 0px 4px 7px 0px #f5f5f5;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    font-size: 20px;
    font-weight: 700;
    margin: 49px 0px 45px 0px;
    display: flex;
    justify-content: center;
  }
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    margin-left: 30px;
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
    }
    .one_box {
      display: flex;
      margin: 41px 0px 23px;
      align-items: center;
      padding-right: 30px;
      .conduct {
        width: 51px;
        height: 18px;
        background: #ff8201;
        border-radius: 9px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 18px;
      }
      .going {
        background: #ff8201;
      }
      .nostart {
        background: #3489fe;
      }
      .end {
        background: #cecece;
      }
    }
    .two {
      color: #000000;
      padding-bottom: 39px;
      span {
        margin-right: 56px;
      }
    }
    .three {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 23px;
    }
    .four {
      margin-bottom: 25px;
      // p {
      //   position: relative;
      //   line-height: 28px;
      //   // max-height: 40px;
      //   overflow: hidden;
      //   width: 986px;
      //   height: 81px;
      // }
      // p::after {
      //   content: "...";
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      //   padding-left: 40px;
      //   background: -webkit-linear-gradient(left, transparent, #fff 55%);
      //   background: -o-linear-gradient(right, transparent, #fff 55%);
      //   background: -moz-linear-gradient(right, transparent, #fff 55%);
      //   background: linear-gradient(to right, transparent, #fff 55%);
      // }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 24px;
    }
    .seven {
      margin-bottom: 50px;
      .seven_one {
        border: 1px dashed #d9d9d9;
        width: 178px;
        .avatar-uploader .el-upload {
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
      }
      .seven_one:hover {
        border-color: #409eff;
      }
      .seven_two {
        width: 400px;
      }
      .seven_three {
        width: 804px;
      }
      .seven_four {
        display: flex;
        align-items: center;
        .seven_four_w {
          width: 180px;
        }
        .seven_four_margin {
          margin-right: 14px;
        }
        .seven_four_hengxian {
          width: 5px;
          height: 1px;
          background-color: #9a9ea5;
          margin: 0 26px;
        }
      }
    }
    .eight {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 102px;
    }
    .nine {
      width: 984px;
      height: 130px;
      border: 1px solid #e9e9e9;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 70px;
      .images {
        margin-left: 14px;
        margin-right: 20px;
        img {
          width: 182px;
          height: 102px;
          border-radius: 6px;
        }
      }
      .nine_word {
        margin-right: 40px;
        p:nth-child(1) {
          width: 531px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 700;
          color: #1a1a1a;
          margin-bottom: 12px;
        }
        p:nth-child(2) {
          margin-bottom: 14px;
          span:nth-child(1) {
            color: #999999;
          }
        }
        p:nth-child(3) {
          span:nth-child(1) {
            color: #999999;
          }
          span:nth-child(2) {
            color: #3489fe;
          }
        }
      }
      .nine_button {
        width: 169px;
        height: 46px;
        background-color: #3489fe;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
      }
      .enter_live,.end_live {
        width: 140px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        color: #fff;
        font-weight: 600;
        background: #ff8201;
        border-radius: 4px;
        cursor: pointer;
        &.look {
          background: #3489fe;
        }
      }
      .end_live {
        cursor: default;
      }
    }
  }
  .except {
    width: 30px;
    height: 30px;
    // border: 1px solid black;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
</style>