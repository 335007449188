<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="
            height: 49px;
            display: flex;
            align-items: center;
          ">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item to="/MsActivity">
                  <span class="c-b">四名活动</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">活动详情</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="mainCenter df dfb">
        <div class="mainLeft">
          <div class="topRightBox">
            <div @click="_likeLive" :style="{ color: dataObj.is_like == 1 ? '#409eff' : '' }">
              <i class="el-icon-thumb"></i>
              点赞({{ dataObj.like_count }})
            </div>
            <div @click="_collectionLive" :style="{ color: dataObj.is_collect == 1 ? '#409eff' : '' }">
              <i class="el-icon-star-off"></i>
              收藏({{ dataObj.collect_count }})
            </div>
            <div @click="openshareDilog">
              <i class="el-icon-share"></i>
              分享
            </div>
          </div>
          <!-- 内容 -->
          <div class="mainBox">
            <div class="ulBox">
              <h4 class="tc fz22 mb14 ellipsis-2" style="color: #2A3037;">{{ dataObj.title }}</h4>
              <div class="flex">
                <p class="c666 mr30 fz14">来源：{{ dataObj.source }}</p>
                <p class="c666 fz14">浏览：{{ dataObj.view_num }}</p>
              </div>
              <!-- 富文本 -->
              <div class="ueBox mt30 mb30">
                <p class="width876 overa" v-html="dataObj.content"></p>
              </div>
              <!-- 工作室 -->
              <h4 v-if="dataObj.array_relation_gzs.length" style="color: #2A3037;" class="fz16 mb20">参与活动的工作室:</h4>
              <div class="gzBox df dfw">
                <div class="gzLi flex_align_center cp" v-for="(item, index) in dataObj.array_relation_gzs" :key="index"
                  @click="goTeachLectureIndex(item)">
                  <div class="mr10">
                    <img :src="item.photo_url" alt="">
                  </div>
                  <div>
                    <p>{{ item.teaching_studio_name }}</p>
                  </div>
                </div>
              </div>
              <!-- 上下页 -->
              <div class="fsbc mt20">
                <p style="color: #677C9B;" class="width380 ellipsis-1">上一篇：
                  <span v-if="dataObj.next" class="cp" @click="goMsDetails(dataObj.next.id)">{{ dataObj.next.title
                  }}</span>
                  <span v-else>无</span>
                </p>
                <p style="color: #677C9B;" class="width380 ellipsis-1">下一篇：
                  <span v-if="dataObj.prev" class="cp" @click="goMsDetails(dataObj.prev.id)">{{ dataObj.prev.title
                  }}</span>
                  <span v-else>无</span>
                </p>
              </div>
            </div>
            <div class="pinglun mt30">
              <h4>评论（{{ commentTotal }}）</h4>
              <div class="pTop df mt30">
                <div class="leftBox pt5">
                  <div class="imgBox">
                    <img class="imgbig" v-if="unserInfoData && unserInfoData.photo_url" :src="unserInfoData.photo_url"
                      alt="">
                    <img class="imgbig" v-else src="@/assets/images/avator.png" alt="">
                  </div>
                  <p class="ellipsis-1 mt10 tc">{{ unserInfoData && unserInfoData.username ? unserInfoData.username : ''
                  }}</p>
                </div>
                <div class="rightBox df dfb">
                  <el-input type="textarea" :rows="3" placeholder="请输入回复内容" v-model="textarea">
                  </el-input>
                  <el-button type="warning" class="width100 ml10" @click="_postComment">发布</el-button>
                </div>
              </div>
              <div class="pBom mt70">
                <div class="pLiBox" v-for="(item, index) in commentLists" :key="index">
                  <div class="bom1 df dfb">
                    <div class="imgBox">
                      <img v-if="item.photo_url" class="imgbig" :src="item.photo_url" alt="">
                      <img v-else class="imgbig" src="@/assets/images/avator.png" alt="">
                    </div>
                    <div class="txtBox" :style="{ border: item.child.total ? 'none' : '' }">
                      <div class="txt1 df dfb mb10">
                        <p>{{ item.realname }}</p>
                        <p>{{ item.comment_time }}</p>
                      </div>
                      <div class="txt2 mb10">
                        <p> {{ item.cont }} </p>
                      </div>
                      <div class="txt3 df dfr">
                        <div class="flex mr20 cp" @click="delList(item.id)"
                          v-if="unserInfoData && item.user_id == unserInfoData.id">
                          <i class="el-icon-delete-solid"></i>
                          删除
                        </div>
                        <div class="flex mr20 cp" @click="_postNumLike(1, item)">
                          <template v-if="item.is_zan == 1">
                            <img class="mr5" src="@/assets/banner_img/zan2.png" alt="">
                            <span style="color:#3489ff">{{ item.num_zan }}</span>
                          </template>

                          <template v-else>
                            <img class="mr5" src="@/assets/banner_img/zan1.png" alt="">
                            <span>赞{{ item.num_zan ? item.num_zan : '' }}</span>
                          </template>
                        </div>
                        <div class="flex cp" @click="openInput(item)">
                          <img class="mr5" src="@/assets/banner_img/hui1.png" alt="">
                          回复
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inbox df dfb mt10 mb10" v-if="item.inputOk">
                    <el-input type="textarea" :rows="3" placeholder="请输入回复内容" v-model="item.textarea">
                    </el-input>
                    <el-button type="warning" class="width100 ml10" @click="huifuBtn(1, item)">发布</el-button>
                  </div>
                  <div class="bom2" v-if="item.child.total">
                    <div class="pLiBox" v-for="(common, dex) in item.child.data" :key="dex">
                      <div class="bom1 df dfb">
                        <div class="imgBox">
                          <img v-if="common.photo_url" class="imgbig" :src="common.photo_url" alt="">
                          <img v-else class="imgbig" src="@/assets/images/avator.png" alt="">
                        </div>
                        <div class="txtBox">
                          <div class="txt1 df dfb mb10">
                            <p>{{ common.realname }}</p>
                            <p>{{ common.comment_time }}</p>
                          </div>
                          <div class="txt2 mb10">
                            <p> {{ common.cont }} </p>
                          </div>
                          <div class="txt3 df dfr cp">
                            <div class="flex mr20" @click="delList(common.id)"
                              v-if="unserInfoData && common.user_id == unserInfoData.id">
                              <i class="el-icon-delete-solid"></i>
                              删除
                            </div>
                            <div class="flex mr20 cp" @click="_postNumLike(2, item, common)">
                              <template v-if="common.is_zan == 1">
                                <img class="mr5" src="@/assets/banner_img/zan2.png" alt="">
                                <span style="color:#3489ff">{{ common.num_zan }}</span>
                              </template>

                              <template v-else>
                                <img class="mr5" src="@/assets/banner_img/zan1.png" alt="">
                                <span>赞{{ common.num_zan ? common.num_zan : '' }}</span>
                              </template>
                            </div>
                            <div class="flex" @click="openInput2(item, common)">
                              <img class="mr5" src="@/assets/banner_img/hui1.png" alt="">
                              回复
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="inbox df dfb mt10 mb10" v-if="common.inputOk">
                        <el-input type="textarea" :rows="3" placeholder="请输入回复内容" v-model="common.textarea">
                        </el-input>
                        <el-button type="warning" class="width100 ml10" @click="huifuBtn(2, item, common)">发布</el-button>
                      </div>
                    </div>
                    <div class="addLength tc pb20">
                      <el-button type="text" v-loading="!flag" @click="addList2(item)">查看更多>>></el-button>
                    </div>
                  </div>
                </div>
                <div class="addLength tc" v-if="showAdd1">
                  <el-button type="primary" v-loading="!flag" @click="addList1">查看更多>>></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainRight">
          <!-- 名称 -->
          <div class="titleBox df dfb">
            <div class="titleLeft">
              <p>热点活动关注</p>
            </div>
          </div>
          <div class="mainBox">
            <div class="ulBox">
              <template v-if="dataList2.length">
                <div class="liBox cp" v-for="(item, index) in dataList2" :key="index" @click="goMsDetails(item.id)">
                  <h4 class="ellipsis-1 mb12">
                    <img class="width16 h-22" src="@/assets/banner_img/hot_icon.png" alt="">
                    {{ item.title }}
                  </h4>
                  <div class="rightTop df dfb">
                    <div class="imgBox">
                      <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    </div>
                    <div class="txtbox">
                      <p class="ellipsis-3">{{ setText(item.content) }}</p>
                    </div>
                  </div>
                </div>
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyDialog :open="shareDilog" title="信息分享" @onClose="shareDilog = false" width="600px">
      <div class="share-box">
        <div class="share-url">
          <p class="share-label">直播链接：</p>
          <p class="share-value">{{ currentUrl }}</p>
          <el-button type="primary" :data-clipboard-text="currentUrl" @click="copyUrl" class="tag-read">
            复制链接
          </el-button>
        </div>
        <div class="share-code">
          <img :src="h5LiveQrcode" class="share-code-img">
        </div>
      </div>
    </MyDialog>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
import Clipboard from "clipboard";
export default {
  data() {
    return {
      currentUrl: window.location.href,
      h5LiveQrcode: "",
      shareDilog: false,
      unserInfoData: {},
      dataObj: {
        array_relation_gzs: [],
      },
      dataList2: [],
      textarea: "",
      commObj: {
        activity_report_id: "",
        page: 1,
        limit: 10
      },
      commObj2: {
        page: 1,
        limit: 10
      },
      commentLists: [],
      commentTotal: 0,
      showAdd1: true,
      flag: true,
    };
  },
  watch: { // 监听，当路由发生变化的时候执行
    $route(to, from) {
      this.currentUrl = window.location.href
      this.getList1()
      this.getList2()
      this.commObj = {
        activity_report_id: "",
        page: 1,
        limit: 10
      }
      this.textarea = ""
      this.getList3()
    },
  },
  created() {
    this.unserInfoData = JSON.parse(localStorage.getItem("unserInfoData"))
    this.getList1()
    this.getList2()
    this.getList3()
  },
  methods: {
    openshareDilog() {
      msAllApi.getactivityQr({ id: this.$route.query.id }).then(res => {
        this.h5LiveQrcode = res.data.data.url
        this.shareDilog = true
      })
    },
    _likeLive() {
      if (!localStorage.getItem("unserInfoData")) {
        this.$message.warning("请先进行登录~");
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href));
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return;
      }
      let param = {
        reply_id: this.$route.query.id,
      };
      msAllApi.postActiviinsertLike(param).then((res) => {
        if (this.dataObj.is_like == 2) {
          this.dataObj.is_like = 1;
          this.dataObj.like_count = this.dataObj.like_count + 1;
        } else {
          this.dataObj.is_like = 2;
          this.dataObj.like_count = this.dataObj.like_count - 1;
        }
      });
    },
    _collectionLive() {
      if (!localStorage.getItem("unserInfoData")) {
        this.$message.warning("请先进行登录~");
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href));
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return;
      }
      let param = {
        id: this.$route.query.id,
      };
      msAllApi.putActivicollect(param).then((res) => {
        if (this.dataObj.is_collect == 2) {
          this.dataObj.is_collect = 1;
          this.dataObj.collect_count = this.dataObj.collect_count + 1;
        } else {
          this.dataObj.is_collect = 2;
          this.dataObj.collect_count = this.dataObj.collect_count - 1;
        }
      });
    },
    copyUrl() {
      const clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        this.$message.success("已复制到剪贴板");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    openInput(item) {
      if (!localStorage.getItem('unserInfoData')) {
        this.$message.warning("请先进行登录~")
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href))
          this.$router.push({
            path: '/logo',
          })
        }, 1000);
        return
      }
      this.commentLists.forEach(it => {
        this.$set(it, 'textarea', "")
        if (it.id == item.id) {
          this.$set(it, 'inputOk', 1)
        } else {
          this.$set(it, 'inputOk', 0)
        }
      })
    },
    huifuBtn(val, item, noon) {
      if (val == 1 && !item.textarea) {
        this.$message.warning("输入回复内容")
        return
      }
      if (val == 2 && !noon.textarea) {
        this.$message.warning("输入回复内容")
        return
      }
      let param = {
        pid: item.id,
        cont: val == 1 ? item.textarea : noon.textarea
      }
      msAllApi.postChildComment(param).then(res => {
        this.commObj.page = 1
        this.getList3()
      })
    },
    openInput2(item, noon) {
      if (!localStorage.getItem('unserInfoData')) {
        this.$message.warning("请先进行登录~")
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href))
          this.$router.push({
            path: '/logo',
          })
        }, 1000);
        return
      }
      this.commentLists.forEach(it => {
        if (it.id == item.id) {
          item.child.data.forEach(em => {
            this.$set(em, 'textarea', "")
            if (em.id == noon.id) {
              this.$set(em, 'inputOk', 1)
            } else {
              this.$set(em, 'inputOk', 0)
            }
          })
        }
      })
    },
    // 评论
    _postComment() {
      if (!localStorage.getItem('unserInfoData')) {
        this.$message.warning("请先进行登录~")
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href))
          this.$router.push({
            path: '/logo',
          })
        }, 1000);
        return
      }
      if (!this.textarea) {
        this.$message.warning("输入回复内容")
        return
      }
      let param = {
        activity_report_id: this.$route.query.id,
        cont: this.textarea
      }
      msAllApi.postComment(param).then(res => {
        this.textarea = ""
        this.commObj.page = 1
        this.getList3()
      })
    },
    getList1() {
      msAllApi.activityDetail({ id: this.$route.query.id }).then(res => {
        this.dataObj = res.data.data
      })
    },
    getList2() {
      msAllApi.activityList({ sort: 'hot', limit: 8 }).then(res => {
        this.dataList2 = res.data.data.data
      })
    },
    getList3() {
      this.commObj.activity_report_id = this.$route.query.id
      msAllApi.commentList(this.commObj).then(res => {
        let arr = res.data.data.data
        if (arr.length) {
          arr.forEach(item => {
            this.$set(item.child, 'page', 1)
            this.$set(item, 'inputOk', 0)
            this.$set(item, 'textarea', "")
            if (item.child.total) {
              item.child.data.forEach(noon => {
                this.$set(noon, 'inputOk', 0)
                this.$set(noon, 'textarea', "")
              })
            }
          })
        }
        if (arr.length < 9) {
          this.showAdd1 = false
        } else {
          this.showAdd1 = true
        }
        this.$nextTick(() => {
          this.commentLists = arr
          console.log(this.commentLists);
          this.commentTotal = res.data.data.total
        })
      })
    },
    addList1() {
      if (this.flag) {
        this.flag = false
        setTimeout(() => {
          this.flag = true
        }, 3000);
      } else {
        return
      }
      let param = {
        activity_report_id: this.commObj.activity_report_id,
        page: this.commObj.page + 1,
        limit: 10
      }
      msAllApi.commentList(param).then(res => {
        let arr = res.data.data.data
        if (arr.length) {
          this.commObj.page = this.commObj.page + 1
          arr.forEach(item => {
            this.$set(item.child, 'page', 1)
            this.commentLists.push(item)
          })
        } else {
          this.$message.warning("没有更多评论了~")
          this.showAdd1 = false
        }
      })
    },
    addList2(item) {
      if (this.flag) {
        this.flag = false
        setTimeout(() => {
          this.flag = true
        }, 3000);
      } else {
        return
      }
      console.log(item);
      let param = {
        activity_report_id: this.commObj.activity_report_id,
        pid: item.id,
        page: item.child.page + 1,
        limit: 10
      }
      msAllApi.commentChildList(param).then(res => {
        let arr = res.data.data.data
        if (arr.length) {
          this.commObj2.page = this.commObj2.page + 1
          this.commentLists.forEach(it => {
            if (it.id == item.id) {
              arr.forEach(em => {
                it.child.page = it.child.page + 1
                it.child.data.push(em)
              })
            }
          })

        } else {
          this.$message.warning("没有更多评论了~")
        }
      })
    },
    _postNumLike(val, data, common) {
      if (!localStorage.getItem('unserInfoData')) {
        this.$message.warning("请先进行登录~")
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href))
          this.$router.push({
            path: '/logo',
          })
        }, 1000);
        return
      }
      if (val == 1) {
        let param = {
          comment_id: data.id,
          like_type: data.is_zan == 1 ? 2 : 1
        }
        msAllApi.postNumLike(param).then(res => {
          this.commentLists.forEach(item => {
            if (item.id == data.id) {
              if (param.like_type == 1) {
                this.$set(item, 'num_zan', data.num_zan + 1)
              } else {
                this.$set(item, 'num_zan', data.num_zan - 1)
              }
              this.$set(item, 'is_zan', data.is_zan == 1 ? 2 : 1)
            }
          })
        })
      } else {
        let param = {
          comment_id: common.id,
          like_type: common.is_zan == 1 ? 2 : 1
        }
        msAllApi.postNumLike(param).then(res => {
          this.commentLists.forEach(item => {
            if (item.id == data.id) {
              item.child.data.forEach(noon => {
                if (noon.id == common.id) {
                  if (param.like_type == 1) {
                    this.$set(common, 'num_zan', common.num_zan + 1)
                  } else {
                    this.$set(common, 'num_zan', common.num_zan - 1)
                  }
                  this.$set(common, 'is_zan', common.is_zan == 1 ? 2 : 1)
                }
              })
            }
          })
        })
      }
    },
    delList(id) {
      msAllApi.delComment({ del_id: id }).then(res => {
        this.$message.success("删除成功")
        this.commObj.page = 1
        this.getList3()
      })
    },
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },
    goTeachLectureIndex(item) {
      this.$router.push({
        name: "TeachLectureIndex",
        query: { id: item.id, module: 'TeachingStudio' }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainCenter {
    .mainLeft {
      width: 956px;
      min-height: 500px;
      background: #ffffff;
      border-radius: 8px;
      padding: 40px;
      box-sizing: border-box;
      position: relative;

      .mainBox {
        width: 100%;

        .ulBox {


          .gzBox {
            .gzLi {
              margin-right: 16px;
              height: 48px;
              background: rgba(238, 246, 255, 1);
              border-radius: 8px;
              padding: 0 18px;
              margin-bottom: 16px;

              >div {

                &:first-child {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;

                  img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                  }
                }

                &:last-child {
                  font-size: 16px;
                  color: #2a3037;
                }
              }
            }
          }
        }
      }
    }

    .mainRight {
      width: 308px;
      min-height: 500px;

      .titleBox {
        border-radius: 8px 8px 0 0;
        height: 72px;
        align-items: center;
        padding: 0 25px;
        background: #ffffff;

        .titleLeft {
          p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }
        }
      }

      .mainBox {

        .ulBox {
          background: #ffffff;
          padding: 0 8px 8px 8px;
          border-radius: 0 0 8px 8px;

          .liBox {
            width: 292px;
            height: 134px;
            background: rgba(238, 246, 255, 1);
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 16px;
            box-sizing: border-box;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            .rightTop {
              .imgBox {
                width: 111px;
                height: 62px;

                img {
                  border-radius: 4px;
                }
              }

              .txtbox {
                width: 140px;

                h4 {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #2a3037;
                }

                p {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                  color: #666666;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.pinglun {
  >h4 {
    font-size: 18px;
    font-weight: bold;
    color: rgba(26, 26, 26, 1);
  }

  .pTop {
    justify-content: space-between;

    .leftBox {
      width: 60px;

      .imgBox {
        width: 50px;
        height: 50px;

        img {
          border-radius: 50%;
        }
      }

      p {
        font-size: 14px;
        font-weight: normal;
        color: #333333;
      }
    }

    .rightBox {

      background: rgba(255, 248, 241, 1);
      border: 1px solid rgba(250, 140, 21, 1);
      border-radius: 4px;
      width: calc(100% - 80px);
      padding: 6px;
      box-sizing: border-box;

      ::v-deep .el-textarea__inner {
        background: #fff8f1;
        border: none;
        resize: none;
      }
    }
  }

  .pBom {
    .pLiBox {
      margin-bottom: 20px;

      .bom1 {
        .imgBox {
          width: 50px;
          height: 50px;

          img {
            border-radius: 50%;
          }
        }

        .txtBox {
          width: calc(100% - 70px);
          border-bottom: 1px dashed #ccc;
          padding-bottom: 20px;

          .txt1 {
            p {
              &:first-child {
                font-size: 16px;
                font-weight: normal;
                color: #2766be;
              }

              &:last-child {
                font-size: 14px;
                font-weight: normal;
                color: #9a9ea5;
              }
            }
          }

          .txt2 {
            p {
              font-size: 16px;
              font-weight: normal;
              color: #333333;
              line-height: 28px;
            }
          }

          .txt3 {
            >div {
              font-size: 14px;
              font-weight: normal;
              color: #9a9ea5;
            }
          }
        }
      }

      .inbox {

        background: rgba(255, 248, 241, 1);
        border: 1px solid rgba(250, 140, 21, 1);
        border-radius: 4px;
        width: 100%;
        padding: 6px;
        box-sizing: border-box;

        ::v-deep .el-textarea__inner {
          background: #fff8f1;
          border: none;
          resize: none;
        }
      }

      .bom2 {
        padding: 0 20px;
        box-sizing: border-box;
        background: #eef6ff;
        margin-left: 70px;
        border-radius: 8px;

        .pLiBox {
          padding-top: 16px;
        }

        .imgBox {
          width: 50px;
          height: 50px;

          img {
            border-radius: 50%;
          }
        }

        .txtBox {
          width: calc(100% - 70px);
          border-bottom: 2px dashed #fff;
          padding-bottom: 20px;

          .txt1 {
            p {
              &:first-child {
                font-size: 16px;
                font-weight: normal;
                color: #2766be;
              }

              &:last-child {
                font-size: 14px;
                font-weight: normal;
                color: #9a9ea5;
              }
            }
          }

          .txt2 {
            p {
              font-size: 16px;
              font-weight: normal;
              color: #333333;
              line-height: 28px;
            }
          }

          .txt3 {
            >div {
              font-size: 14px;
              font-weight: normal;
              color: #9a9ea5;
            }
          }
        }
      }
    }
  }
}

.topRightBox {
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  background: #fdf0e2;
  border-radius: 0 8px 0 8px;
  padding: 8px 15px;

  >div {
    font-size: 12px;
    margin: 0 10px;
    cursor: pointer;

    &:hover {
      color: #1e82ff;
    }
  }
}

.share-box {
  display: flex;

  .share-url {
    flex: 1;
    overflow: hidden;

    .share-label {
      font-size: 18px;
      color: #333;
      line-height: 18px;
      margin-bottom: 12px;
    }

    .share-value {
      font-size: 16px;
      color: #999;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }

  .share-code {
    width: 90px;
    margin-left: 20px;

    .share-code-text {
      font-size: 14px;
      color: #333;
      line-height: 16px;
      margin-bottom: 6px;
    }

    .share-code-img {
      width: 90px;
      height: 90px;
    }
  }
}
</style>