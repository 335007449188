import BasicInfo from "./components/basicInfo/basicInfo.vue";
import Subject from "./components/subject/subject.vue";
import Preview from "./components/preview/preview.vue";
import Finish from "./components/finish/finish.vue";
import History from "./components/history/history.vue";
export default {
  components: {
    BasicInfo,
    Subject,
    Preview,
    Finish,
    History,
  },
  data() {
    return {
      moduleType: 1, //模块索引（1.基本信息 2.题目 3.题目预览 4.创建完成 5.历史题目）
      problemType: 1, //问卷/投票（1.问卷 2.投票）
    };
  },
  created() {},
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  methods: {
    //基本信息提交回调
    basicInfoSubmitData(type) {
      if (type == 1) {
        //type=1创建，type=2使用历史题目创建
        this.moduleType = 2;
      } else {
        this.problemType = JSON.parse(
          sessionStorage.getItem("problem_basic_info_data")
        ).type;
        this.moduleType = 5;
      }
    },
    //基本信息类型回调
    basicInfoProblemType(val) {
      this.problemType = val;
    },
    //题目返回回调
    subjectCallback() {
      this.moduleType = 1;
    },
    //题目预览回调
    subjectPreview() {
      this.moduleType = 3;
    },
    //题目预览返回回调
    previewCallback() {
      this.moduleType = 2;
    },
    //题目提交回调
    subjectSubmitData() {
      this.moduleType = 4;
    },
    //创建完成再创建回调
    finishCreate() {
      this.moduleType = 1;
    },
    //历史题目返回回调
    historyCallback() {
      this.moduleType = 1;
    },
    //历史题目提交回调
    historySubmitData() {
      this.moduleType = 2;
    },
    //卸载处理程序之前
    beforeunloadHandler() {
      sessionStorage.removeItem("problem_basic_info_data");
    },
  },
  watch: {},
  beforeDestroy() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
};
