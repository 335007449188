<template>
  <div class="public_css_module resource_div personal_right_box">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的资源</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'" @click="showData(item.id)">
          <span>{{ item.name }}</span>
          <el-dropdown v-if="index == 1 && false" trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">审核中</el-dropdown-item>
              <el-dropdown-item command="4">通过</el-dropdown-item>
              <el-dropdown-item command="5">未通过</el-dropdown-item>
              <el-dropdown-item command="6">已过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div v-if="currentNum == 1" class="filter_search_div">
        <el-select v-model="reqObj.sources_id" :clearable="true" placeholder="资源类型" style="width: 112px">
          <el-option v-for="(item, index) in resourcetypesOption" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="filter_search_box">
          <el-input style="width: 240px" :clearable="true" placeholder="输入资源名称查询" v-model="reqObj.title" @keyup.enter.native="handleFilter()">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="handleFilter()"></el-button>
          </el-input>
        </div>
      </div>

      <router-link to="/uploadResource" class="rightLink">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>发布资源</span>
      </router-link>
    </div>

    <div class="resource_list" v-loading="listLoading">
      <div class="resource_item_div" v-for="(item, index) in tableData" :key="index">
        <div class="resource_item_box">
          <div class="left_div">
            <img :src="item.mime | fileTypeResource" />
            <div class="left_name">{{ item.sources_name || "-" }}</div>
          </div>
          <div class="center_div">
            <div class="title_div ellipsis_1">{{ item.title || "-" }}</div>
            <div class="tag_3_box ellipsis_1 fxaic">
              <span class="span_2">{{ item.pharse_name || "-" }}
                {{ item.subject_name || "-" }}</span>
              <!-- <span class="span_1"></span> -->
              <!-- <span class="span_2">{{item.edtion || "-"}}</span> -->
              <span class="span_1"></span>
              <span class="span_2">{{ item.grade_name || "-" }}</span>
              <!-- <span class="span_1"></span> -->
              <!-- <span class="span_2">{{item.chater || "-"}}</span> -->
            </div>
            <!-- <div v-if="currentNum == 2" class="tag_3_box fxaic">
              <div class="tag_3_name_1 ellipsis_1">
                {{ item.chapter_all }}666
              </div>
            </div> -->
            <div class="info_div fxaic">
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/shijian_icon.png" />
                </div>
                上传时间: {{ item.create_time || "-" }}
              </div>
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/shoucang_icon.png" />
                </div>
                <template v-if="currentNum == 1">
                  收藏次数: {{ item.collect_count || "0" }}
                  <!-- {{ item.watch_number || "0"}} -->
                </template>
                <template v-if="currentNum == 2">
                  收藏次数: {{ item.collect_count || "0" }}
                </template>
              </div>
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/wenjain_icon.png" />
                </div>
                文件大小: {{ item.size | fileSizeFilter }}
              </div>
            </div>
            <!-- <div class="tag_1_box fxaic">
              <div class="tag_item tag_1_item">{{ item.year || "-" }}</div>
            </div> -->
          </div>
          <div v-if="currentNum == 1" class="right_div">
            <div class="btn_1" @click="getEditData(item.id)">修改</div>
            <div class="btn_1" @click="deleteResource(item.id)">删除</div>
            <div class="btn_2" @click="downRource(item)">下载</div>
            <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
              :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item" :fileTitle="item.title"
              :fileSize="item.size" :fileTime="item.create_time" fileNav="个人中心 > 我的资源 > 查看">
              <div class="btn_2 btn_3">查看</div>
            </new-viewonline>
          </div>
          <div v-if="currentNum == 2" class="right_div">
            <div class="btn_1" @click="ClearSource(item)">取消收藏</div>
            <div class="btn_2" @click="toLoad(item)">下载</div>
            <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
              :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item" :fileTitle="item.title"
              :fileSize="item.size" :fileTime="item.create_time" fileNav="个人中心 > 我的资源 > 查看">
              <div class="btn_2 btn_3">查看</div>
            </new-viewonline>
            <!-- <div class="btn_2 btn_3" @click="getResourceDetail_2(item)">查看222</div> -->
          </div>
        </div>
      </div>

      <CommonEmpty v-if="!listLoading && !tableData.length" hVal="200px"></CommonEmpty>

      <div v-show="ListTotal" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="reqObj.page"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="reqObj.limit" layout="total, sizes, prev, pager, next, jumper" :total="ListTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as researchApi from "@/api/researchApi.js";
import { deepClone } from "@/utils/utils.js";
export default {
  data() {
    return {
      //收藏的资源查看地址
      lookUrl: "",
      // 收藏资源的下载地址
      url_source: "",
      resourcetypesOption: [],
      currentNum: 1,
      selectData: [
        {
          id: 1,
          name: "我上传的",
        },
        {
          id: 2,
          name: "我收藏的",
        },
      ],
      reqObj: {
        page: 1,
        limit: 10,
        sources_id: "",
        title: "",
      },
      listLoading: true, //表格Loading
      tableData: [], //表格数据
      ListTotal: 0, //总条数
    };
  },
  computed: {},

  components: {},

  methods: {
    // 资源类型
    getresourceTypes() {
      this.$axios.get("/resourceTypes").then((res) => {
        this.resourcetypesOption = res.data.data;
      });
    },
    // 我收藏的下载
    toLoad(item) {
      window.open(item.url);
    },
    // 取消收藏
    ClearSource(item) {
      if (!item.hasOwnProperty("is_person")) {
        //教研组
        this.$axios
          .post("resource/collect_resource", { id: item.id + "" })
          .then(() => {
            this.$message.success("取消收藏成功");
            this.freshListData();
          });
      } else {
        //四名工作室的
        let {
          teaching_studio_id,
          teaching_studio_user_resource_id,
          user_id,
          id,
        } = item;
        this.$axios
          .post("resource/collect_count", {
            id: item.id + "",
            teaching_studio_id: teaching_studio_id,
            user_id: user_id,
            resource_id: id,
            teaching_studio_user_resource_id: teaching_studio_user_resource_id,
          })
          .then(() => {
            this.$message.success("取消收藏成功");
            this.freshListData();
          });
      }
    },
    // 获取编辑的编辑资源信息
    getEditData(eid) {
      this.$router.push({
        path: "/updataResource",
        query: {
          id: eid,
        },
      });
    },
    //我上传的 查看收藏资源
    async getResourceDetail_1(item) {
      let id = item.id ? item.id : item.id;
      let res = await this.$axios.get("resource/get_resource_info", {
        params: {
          id: id,
        },
      });
      this.lookUrl = res.data.data.newroot;
    },
    getResourceDetail_2(item) {
    },
    // 我上传的 下载收藏的资源
    async downRource(item) {
      let id = item.id ? item.id : item.id;
      let res = await this.$axios.get("resource/down_resource", {
        params: {
          id: id,
        },
      });
      this.url_source = res.data.data;
      window.open(this.url_source);
    },
    //删除资源
    deleteResource(id) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$axios.delete("resources/del_resource", {
          params: { delId: [id] },
        });
        this.freshListData();
        this.$message.success("删除成功");
      });
    },
    //截取后面
    // toGetS(a) {
    //   let b = a.trim().split(" ");
    //   if (b[1]) {
    //     return b[0] + b[1];
    //   } else {
    //     return b[0];
    //   }
    // },
    // 列表数据
    async freshListData() {
      this.listLoading = true;
      let requestData = null;
      try {
        switch (Number(this.currentNum)) {
          case 1:
            requestData = await researchApi.get_resource_get_get_my_found_list(
              this.reqObj
            );
            break;
          case 2:
            requestData =
              await researchApi.get_resource_get_get_my_collect_list(
                this.reqObj
              );
            break;
          default:
            break;
        }
        let resdata = requestData.data.data;
        this.tableData = resdata.data;
        this.ListTotal = resdata.total;
        if (!this.tableData.length && this.reqObj.page != 1) {
          this.$set(this.reqObj, "page", this.reqObj.page - 1);
          this.freshListData();
        } else {
          this.$nextTick(() => {
            this.listLoading = false;
          });
        };
      } catch (err) {
        this.tableData = [];
        this.ListTotal = 0;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
    // 切换
    showData(id) {
      this.tableData = [];
      this.ListTotal = 0;
      this.currentNum = id;
      let query = this.$route.query;
      this.$router.replace({
        query: {
          ...query,
          currentNum: this.currentNum,
        },
      });
      this.handleFilter();
    },
    //下拉
    handleCommand(type) { },
    // 列表
    handleFilter() {
      this.$set(this.reqObj, "page", 1);
      this.freshListData();
    },
    // 改变 条数
    handleSizeChange(val) {
      this.$set(this.reqObj, "page", 1);
      this.$set(this.reqObj, "limit", val);
      this.freshListData();
    },
    // 改变 页数
    handleCurrentChange(val) {
      this.$set(this.reqObj, "page", val);
      this.freshListData();
    },
  },

  destroyed() { },

  mounted() {
    let query = this.$route.query;
    if (query && query.currentNum) {
      this.currentNum = query.currentNum;
    }
    this.handleFilter();
    this.getresourceTypes();
  },

  created() { },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.filter_search_div {
  height: 42px;
  display: flex;
  margin-left: auto;
  margin-right: 24px;
  /deep/.el-input__inner {
    background-color: #f4fbff;
  }
  .filter_search_box {
    margin-left: 24px;
  }
}
.resource_div {
  width: 100%;
  background-color: #fff;
}
.resource_list {
  min-height: 100px;
  .resource_item_div {
    width: 100%;
    height: 128px;
    padding: 0 32px;
    &:hover {
      background-color: #f3faff;
      .title_div {
        color: #3489fe;
      }
    }
  }
  .resource_item_box {
    width: 100%;
    height: 100%;
    border-bottom: 1px dashed #dae7ed;
    display: flex;
    padding-top: 22px;
    position: relative;
  }
  .left_div {
    width: 68px;
    height: 80px;
    margin-right: 24px;
    position: relative;
    > img {
      width: 68px;
      height: 80px;
    }
    .left_name {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 12px;
      line-height: 26px;
      height: 26px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }
  }
  .center_div {
    flex: 1;
    .title_div {
      font-size: 16px;
      height: 18px;
      line-height: 18px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
    .tag_3_box {
      margin-top: 8px;
      width: 650px;
      .tag_3_name_1 {
        width: 650px;
      }
      > .span_2 {
        font-size: 14px;
        line-height: 14px;
        color: #666;
      }
      > .span_1 {
        display: inline-block;
        width: 0.5px;
        height: 12px;
        margin: 0 14px;
        background-color: #ccc;
      }
    }
    .tag_1_box {
      margin-top: 16px;
      .tag_item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
        color: rgba(4, 147, 255, 1);
        padding: 0 8px;
        border: 1px solid #0493ff;
        color: #0493ff;
      }
      .tag_1_item {
        border: 1px solid #51b875;
        color: #51b875;
        // margin-left: 10px;
      }
    }
    .info_div {
      margin-top: 14px;
      .info_item {
        font-size: 14px;
        line-height: 16px;
        color: rgba(102, 102, 102, 1);
        margin-right: 32px;
      }
      .info_img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .right_div {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    > div {
      height: 32px;
      font-size: 15px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }
    .btn_1 {
      color: #3489ff;
      margin-right: 40px;
      font-size: 15px;
      line-height: 32px;
      text-align: center;
    }
    .btn_2 {
      font-size: 15px;
      line-height: 32px;
      border-radius: 8px;
      width: 92px;
      color: #fff;
      background-color: #51b875;
      font-weight: bold;
      text-align: center;
    }
    .btn_3 {
      margin-left: 24px;
      background-color: #3489ff;
    }
  }
}
</style>