// 排序题
export default {
  props: {
    //弹窗显示
    value: {
      type: Boolean,
      default: false,
    },
    //题目数据
    subjectData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogShow: false, //是否显示弹窗
      //排序题参数
      subject: {
        title: "",
        type: 3,
        is_required: 1,
        selected_type: 1,
        options: [
          { content: "", allow_fill_in: 1, fill_in_required: 1 },
          { content: "", allow_fill_in: 1, fill_in_required: 1 },
        ],
      },
      //选择题验证
      subjectRules: {
        title: [{ required: true, message: "请输入题干", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    //题目必填回调
    // subjectRequiredChange(val) {
    //   if (val == 1) {
    //     this.subject.options.forEach((item) => {
    //       item.allow_fill_in = 1;
    //       item.fill_in_required = 1;
    //     });
    //   }
    // },


    //允许填空回调
    writeAllowChange(val, index) {
      this.subject.options[index].allow_fill_in = val;
      // if (val == 1) {
        
      // }
      // if (val == 2) {
      //   this.subject.is_required = 2;
      // }
    },
    //填空必填回调
    writeRequiredChange(val, index) {
      // if (val == 2) {
        this.subject.options[index].fill_in_required = val;
        // this.subject.options[index].allow_fill_in = 1;
      // }
    },
    //上移选项
    upOptionButton(item, index) {
      this.subject.options.splice(index - 1, 0, item);
      this.subject.options.splice(index + 1, 1);
    },
    //下移选项
    downOptionButton(item, index) {
      this.subject.options.splice(index + 2, 0, item);
      this.subject.options.splice(index, 1);
    },
    //新增选项
    addOptionButton() {
      //新增不填空，不必填
      this.subject.options.push({ content: "", allow_fill_in: 1 ,fill_in_required: 1});
    },
    //删除选项
    delOptionButton(index) {
      const options = JSON.parse(JSON.stringify(this.subject.options));
      if (options.length > 2) {
        this.subject.options.splice(index, 1);
      }
    },
    //弹窗关闭的回调
    dialogHandleClose() {
      this.$emit("input", false);
    },
    //取消按钮
    cancelButton() {
      this.$emit("input", false);
    },
    //确认按钮
    defineButton(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let isComplete = true;
          for (let index = 0; index < this.subject.options.length; index++) {
            if (!this.subject.options[index].content) {
              isComplete = false;
              this.$message({
                message: `请输入选项 ${index + 1} 的内容`,
                type: "warning",
              });
              break;
            }
          }
          if (isComplete) {
            this.$emit("onSubject", this.subject);
            this.$emit("input", false);
          }
        }
      });
    },
  },
  watch: {
    //监听是否显示弹窗
    value(val) {
      this.dialogShow = val;
      if (!this.dialogShow) {
        this.subject = {
          title: "",
          type: 3,
          is_required: 1,
          selected_type: 1,
          options: [
            { content: "", allow_fill_in: 1, fill_in_required: 1 },
            { content: "", allow_fill_in: 1, fill_in_required: 1 },
          ],
        };
        setTimeout(() => {
          this.$refs["subject"].clearValidate();
        }, 1000);
      }
    },
    //监听题目数据
    subjectData(e) {
      if (this.dialogShow) {
        this.subject = e;
      }
    },
    //监听问卷说明
    "subject.title"() {
      this.$nextTick(() => {
        this.$refs["subject"].validateField("title");
      });
    },
  },
};
