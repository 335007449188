import axios from 'axios';
import Vue from 'vue';
import {
  MessageBox,
  Message,
  Loading
} from 'element-ui';
import {
  localGet,
  localSet,
  localRemove
} from '@/utils/utils.js';

let flag = true;
// axios封装 请求封装 http封装请求
const service = axios.create({
  baseURL: "/api", // url = base url + request url
  // withCredentials: true, // 当跨域请求时发送cookie
  timeout: 60 * 1000 // 请求超时时间
});

// 开启全屏Loading
function startLoading() {
  service.fullscreen_loading = Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)',
  })
};

// 关闭全屏Loading
function endLoading() {
  if (!service.fullscreen_loading) return false;
  Vue.nextTick(() => {
    let fullscreen_imeout = setTimeout(() => {
      service.fullscreen_loading.close();
      service.fullscreen_loading = null;
      clearTimeout(fullscreen_imeout);
    }, 100);
  })
};

// 请求拦截器 req 拦截器
service.interceptors.request.use(config => {
  let unserInfoData = localGet("unserInfoData");
  if (unserInfoData && unserInfoData.token) {
    config.headers['token'] = unserInfoData.token;
  };
  if (unserInfoData && unserInfoData.id) {
    config.headers['id'] = unserInfoData.id;
  } else {
    config.headers['id'] = "";
  };

  //请求参数随机数防止CDN缓存
  if (config.method == "get") {
    config.params = {
      ...config.params,
      random: Math.random()
    }
  }

  if (config.isLoading) {
    startLoading();
  };
  return config;
}, error => {
  return Promise.reject(error)
});


const successDataCode = [900, 200]; //后台约定好【数据成功 code 标识】
const loginExpireCode = [21000]; //后台约定好【登录过期 code 标识】
const noSiteCode = [404001]; //后台约定好【无站点 code 标识】

service.interceptors.response.use(response => {
  if (response?.config?.isLoading) {
    endLoading();
  };
  const res = response.data;
  if (res.error_code === 31001) {
    window.location.href = "/#/changePwd";
  }
  if ((res.hasOwnProperty("code") && successDataCode.includes(Number(res.code))) || (res.hasOwnProperty("error_code") && successDataCode.includes(Number(res.error_code)))) {
    return Promise.resolve(response);

  } else if ((res.hasOwnProperty("code") && loginExpireCode.includes(Number(res.code))) || (res.hasOwnProperty("error_code") && loginExpireCode.includes(Number(res.error_code)))) {
    if (sessionStorage.getItem("is_hide") != 1) {
      if (flag) {
        flag = false
        setTimeout(() => {
          flag = true
        }, 3000);
        Message({
          type: 'error',
          message: "登录过期，请重新登录。",
          duration: 2 * 1000
        });
      }
    }
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "./";
    }, 500);
    return Promise.reject(response)
  } else if ((res.hasOwnProperty("code") && noSiteCode.includes(Number(res.code))) || (res.hasOwnProperty("error_code") && noSiteCode.includes(Number(res.error_code)))) {
    window.location.href = "./#/noSite";
  } else {
    let resmsg = '';
    if (res.msg) {
      resmsg = res.msg;
    } else if (res.message) {
      resmsg = res.message;
    } else {
      resmsg = `接口内部错误`;
    };
    if (sessionStorage.getItem("is_hide") != 1) {
      if (flag) {
        flag = false
        setTimeout(() => {
          flag = true
        }, 3000);
        Message({
          message: resmsg,
          type: 'error',
          duration: 2 * 1000
        })
      }
    }

    return Promise.reject(response);
  };
}, error => {
  if (error?.config?.isLoading) {
    endLoading();
  };

  let {
    message
  } = error;
  console.log(message);
  if (message == "Network Error") {
    message = "网络异常，请检查网络连接状态";
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时";
  } else if (message.includes("Request failed with status code")) {
    // message = "系统接口" + message.substr(message.length - 3) + "异常";
    message = "用户无权访问"
  } else {
    message = "接口内部错误";
  };
  if (flag) {
    flag = false
    setTimeout(() => {
      flag = true
    }, 3000);
    Message({
      message: message,
      type: 'error',
      duration: 2 * 1000
    });
  }

  return Promise.reject(error);
});

Vue.prototype.$axios = service;
export default service;