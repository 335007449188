<template>
  <div class="groupdetaillive">
    <div class="content_one">
      <div class="one_left">
        <span @click="GroupAuditTearm(1)" class="span_div" :class="{ 'active':pageType==1 }">教研直播</span>
        <span v-if="openFlat==1" @click="GroupAuditTearm(2)" class="span_div" :class="{ 'active':pageType==2 }">审核组员创建的</span>
        <span class="temp_class_span_div">-888</span>
      </div>
      <div class="one_right" v-if="(group_status != 3 && group_status != 4)">
        <!-- <p class="btn2" v-if="openFlat==1 " @click="GroupAuditTearm()">审核组员创建的</p> -->
        <i class="el-icon-plus"></i>
        <p @click="toCreateGroupLive">创建教研直播</p>
      </div>
    </div>

    <template v-if="pageType==1">
      <div class="content_three">
        <div class="three_one" v-for="(item,index) in stateArrList" :key="index" :class="{ 'active': live.search_status == item.id}"
          @click="handleStateChange(item)">{{item.name}}</div>
      </div>
      <div class="content_two">
        <CommonEmpty v-if="!liveList.length" hVal="100%"></CommonEmpty>
        <template v-for="(item, index) in liveList">
          <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="2" :isGroupModule="1"
            :currentIndex="index" :item="item" @editClickLive="editClickLive" @delClick_3="delClick_3" @live_RefreshPage="getLiveList()"
            @toPageEvent_live="toDetailPage">
          </PublicTtemDiv>
        </template>
      </div>
      <div v-if="live.total" class="pagin">
        <el-pagination background :current-page="live.page" :page-size="live.limit" layout="prev, pager, next, jumper" :total="live.total"
          @current-change="currentChange"></el-pagination>
      </div>
    </template>

    <div class="live_check_div" v-if="pageType==2">
      <GroupTearmAudit :fa_flat="1"></GroupTearmAudit>
    </div>

  </div>
</template>

<script>
import GroupTearmAudit from '@/views/teachingGroup/AuditTearm.vue'
export default {
  components: {
    GroupTearmAudit,
  },
  data() {
    return {
      stateArrList: [
        { id: "", name: '全部' },
        { id: 1, name: '未开始' },
        { id: 2, name: '进行中' },
        { id: 3, name: '已结束' },
      ],
      pageType: 1,
      openFlat: 0,
      //组长id
      G_user_id: -1,
      // 缓存组的状态
      group_status: 0,
      userId: 0,//用户id
      liveList: [],//直播列表
      live: {//教研直播参数
        limit: 12,
        page: 1,
        total: 0,
        current_group_id: "",
        search_status: "",
      }
    }
  },
  created() {
    // 缓存组的状态
    this.group_status = localStorage.getItem("group_status")
    this.live.current_group_id = this.$route.query.groupId;
    this.userId = localStorage.getItem("id");
    this.openFlat = localStorage.getItem('AuditFlat');
    this.getLiveList()
    //初始化教研组详情
    this.getGroupDetails()
  },

  methods: {
    //跳转到编辑页
    editClickLive(id) {
      // 从路由原信息得到直播id传过去
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/newLive?id=${id}`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/createGroupNewLive",
          query: {
            id: id,
            groupId: this.$route.query.groupId
          },
        });
      }
    },
    //删除 直播
    delClick_3(id, item) {
      this.$confirm("此操作将删除该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_3(id, item);
      }).catch(() => { });
    },
    //删除直播
    async delActivity_3(id, item) {
      let user_id = localStorage.getItem("id");
      let res = await this.$axios.delete(`/live/delLive/${id}/${user_id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.getLiveList();
    },
    // 筛选状态
    handleStateChange(item) {
      this.$set(this.live, 'search_status', item.id);
      this.$set(this.live, 'page', 1);
      this.getLiveList()
    },
    //审核
    GroupAuditTearm(num) {
      this.pageType = num;
      return
      this.$router.push({
        path: '/GroupTearmAudit',
        query: {
          groupId: this.$route.query.groupId,
          type: '1',
          navCurrentType: 'live',
        }
      })
    },
    //获取教研组详情
    async getGroupDetails() {
      // 从路由得到id
      let gid = this.$route.query.groupId
      let res = await this.$axios.get(`group/${gid}`)
      this.G_user_id = res.data.data.user_id

    },
    //跳转到编辑页
    editClick(id) {
      this.$router.push({
        path: '/newLive',
        query: {
          id: id,
          groupId: this.$route.query.groupId
        }
      });
    },
    //  跳转到直播详情页
    toDetailPage(item) {
      this.$router.push({
        path: '/livedetail',
        query: {
          liveid: item.id,
          groupId: this.$route.query.groupId
        }
      })
    },
    //创建直播
    toCreateGroupLive() {
      this.$router.push({
        path: "/createGroupNewLive",
        query: {
          groupId: this.$route.query.groupId
        }
      })
    },
    //获取直播列表
    async getLiveList() {
      const res = await this.$axios.get("live/get_group_list", { params: this.live })
      this.liveList = res.data.data.data
      this.live.total = res.data.data.total
    },
    //当前页码改变
    currentChange(val) {
      this.live.page = val;
    },
  },
  watch: {
  }
};
</script>

<style lang="less" scoped>
.live_check_div {
  padding-right: 30px;
  box-sizing: border-box;
  /deep/.mBox {
    padding: 0 !important;
    margin: 0 !important;
    .title_h4 {
      display: none !important;
      padding: 0 !important;
      margin: 0 !important;
    }
    .ForBox {
      width: 100%;
    }
    .iBox {
      width: 100% !important;
    }
  }
}
.groupdetaillive {
  //   border: 1px solid pink;
  background: #ffffff;
  border-radius: 6px;
  padding-bottom: 40px;
  margin-bottom: 60px;
  padding-left: 30px;
  overflow: hidden;
  //   padding-right: 30px;
  .content_one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-right: 23px;
    .one_left {
      .span_div {
        font-size: 16px;
        font-weight: normal;
        color: rgba(102, 102, 102, 1);
        margin-right: 30px;
        cursor: pointer;
        &.active {
          font-weight: bold;
          color: #3489ff;
        }
      }
    }
    .one_right {
      display: flex;
      cursor: pointer;
      align-items: center;
      color: #3489fe;
      i {
        margin-right: 10px;
      }
      //审核行
      .btn2 {
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #3489fe;
        margin-right: 30px;
        span {
          color: #ff0607;
          font-weight: bold;
        }
      }
    }
  }
}
.content_two {
  display: flex;
  flex-wrap: wrap;
  /deep/ .details_div {
    box-sizing: border-box;
    padding-right: 6px !important;
  }
}
.content_three {
  display: flex;
  margin-bottom: 20px;
  .three_one {
    width: 90px;
    height: 32px;
    background: rgba(52, 137, 254, 0.1);
    border-radius: 16px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      background: #3489fe;
      color: #fffefe;
    }
  }
}
</style>