<template>
  <!-- 会议详情 -->
  <div class="ConferenceDetail">
    <!-- 正文 -->
    <div class="content">
      <!-- 会议详情 -->
      <div class="main">
        <div class="title">
          <h4>会议详情</h4>
          <router-link to="/conference">
            <el-button type="primary">返回列表</el-button>
          </router-link>
        </div>
        <p class="zt">会议主题</p>
        <!-- 时间内容 -->
        <div class="detail">
          <p>会议时间：</p>
          <p>发起人：</p>
          <p>参与人：</p>
        </div>
        <!-- 会议文件 -->
        <div class="wj">
          <h4>会议文件:</h4>
          <div class="items">
            <img src="" alt="">
            <span>.doc名字</span>
            <span>在线查看</span>
            <span>下载</span>
          </div>

        </div>
        <!-- 会议回放视频 -->
        <div class="videos">
          <h4>会议回放视频</h4>
          <div>
            视频位置
          </div>
        </div>
        <!-- 会议聊天记录 -->
        <div class="records">
          <h4>会议聊天记录:</h4>
          <h4>2020.04 张三</h4>
          <p>
            fsdodghsodhgoisdjgioghooahoggadggaga
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage4: 0,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入教研组名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],

        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写教研组简介', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="less" >
.ConferenceDetail {
  width: 100%;
  display: flex;
  justify-content: center;
  .content {
    width: 1045px;
    height: 1124px;

    .main {
      width: 100%;
      margin-top: 20px;
      .title {
        display: flex;
        justify-content: space-between;
      }
      .zt {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
      .detail {
        margin-left: 20px;
        margin-top: 50px;
        p {
          margin-bottom: 10px;
        }
      }
      .wj {
        margin-top: 20px;
        h4 {
          margin-left: 20px;
          margin-bottom: 20px;
        }
        .items {
          display: flex;
          align-items: center;
          margin-left: 20px;
          img {
            width: 50px;
            height: 50px;
          }
          span {
            margin-left: 50px;
          }
        }
      }
      .videos {
        margin-left: 20px;
        margin-top: 20px;
      }
      .records {
        margin-left: 20px;
        margin-top: 20px;
        h5 {
          margin-top: 20px;
        }
        p {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>