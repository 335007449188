<template>
  <!-- 备课 -->
  <div class="nextCourseDetail">
    <!-- 头部 -->
    <div class="nextTopCourse">
      <!-- 左边图片 -->
      <div class="TopLeft">
        <img :src="form.cover_id" alt="" />
      </div>
      <!-- 右边 -->
      <div class="TopRight">
        <!-- 标题 -->
        <div class="titleTop">
          <h5>
            {{form.name}}
          </h5>
        </div>
        <!-- 便签 -->
        <div class="bq">
          <div><span>课程分类：{{form1.gradeName}}</span></div>
          <div><span>学段学科：{{form1.stageName}} &nbsp;&nbsp; {{form1.subjectName}}</span></div>
          <div><span>年级：{{form1.gradeName}}</span></div>
          <div><span>教材版本：{{form1.versionName}}</span></div>
        </div>
        <!-- 最后一行 -->
        <div class="bq_last">
          <p>
            <span>适用章节：{{form1.chapterName}}</span>
            <span v-if="form.sign_type=='1'">作者：{{username}}</span>
            <span v-if="form.sign_type=='2'">作者：</span>
            <span v-if="form.sign_type=='3'">作者：{{orgName}}</span>
            <span v-if="form.sign_type=='4'">作者：{{form.sign_text}}</span>
            <span>备课时间：</span>
          </p>
        </div>
        <el-button type="primary">发布课程</el-button>
        <el-button type="success" @click="toBack()">修改课程</el-button>
        <el-button type="info" @click="deleteInforMation">删除课程</el-button>
      </div>
    </div>
    <!-- 内容 -->
    <div class="dbBox">
      <div class="boxContent">
        <div class="Box_top">
          <div :class="num == 0 ? 'active' : ''" @click="getOne">视频介绍</div>
          <div :class="num == 1 ? 'active' : ''" @click="getTwo">视频内容</div>
        </div>
        <!-- 视频介绍 -->
        <div v-if="num ==0">
          <div class="Box_middle">
            <h4>课程概述</h4>
            <div>
              <p>
                {{form.presentation}}
              </p>
            </div>
          </div>
          <div class="Box_bottom">
            <h4>作者简介</h4>
            <div class="itemBox" v-if="form.sign_type == 1">
              <div class="list_left">
                <img src="../../../../assets/images/avator.png" alt="">
              </div>
              <div class="list_right">
                <span>{{username}}</span><br>
                <span>{{orgName}}</span>
                <p>我是{{username}},居住于{{infoList.area_name}}</p>
              </div>
            </div>
            <div class="itemBox" v-if="form.sign_type == 2">
              <div class="list_left">
                <img src="../../../../assets/images/avator.png" alt="">
              </div>
              <div class="list_right">
                <span>{{username}}</span><br>
                <span>{{orgName}}</span>
                <p>我是{{username}},居住于{{infoList.area_name}}</p>
              </div>
            </div>
            <div v-if="form.sign_type == 3">
              <div class="itemBox" v-for="(item,index) in  arryList" :key="index">
                <div class="list_left">
                  <img src="../../../../assets/images/avator.png" alt="">
                </div>
                <div class="list_right">
                  <span>{{item.realname}}</span><br>
                  <span>{{item.org_name}}</span>
                  <p>我是{{item.realname}},是{{item.subject_name}}老师</p>
                </div>
              </div>
            </div>
            <div class="itemBox" v-if="form.sign_type == 4">
              <div class="list_left">
                <img src="../../../../assets/images/avator.png" alt="">
              </div>
              <div class="list_right">
                <span>{{form.sign_text}}</span><br>
                <span>{{orgName}}</span>
                <p>我是{{form.sign_text}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 视频内容 -->
        <div v-if="num ==1">
          <!-- 循环部分 -->
          <div class="forBox" v-for="(item,index) in chapterArry" :key="index">
            <div class="titleItem">
              <div class="one">
                <span>{{item.chapter}}</span>
                <span>{{item.title}}</span>
              </div>

              <div class="two">
                <!-- 修改章节内容 -->
                <i class="el-icon-edit" @click="editZJ(index)"></i>
                <!-- 删除 -->
                <i class="el-icon-delete" @click="deleteBox(index)"></i>
                <span @click="add_dialog_XJ=true">添加小节</span>
              </div>
            </div>
            <!-- 添加小节节 -->
            <div class="add_dialog_XJ">
              <el-dialog title="添加小节" :visible.sync="add_dialog_XJ" style="width:1200px;">
                <h4>添加基础内容</h4>
                <div class="one">
                  <div class="one_one">
                    <div>
                      <img src="../../../../assets/images/personal/001.png" alt="">
                    </div>
                    <div>
                      <img src="../../../../assets/images/personal/002.png" alt="">
                    </div>
                    <div>
                      <img src="../../../../assets/images/personal/003.png" alt="">
                    </div>
                  </div>
                  <p>
                    <span @click="b1=true">添加文本</span>
                    <span @click="b2=true">添加文档</span>
                    <span @click="b3=true">添加视频</span>
                  </p>
                </div>
                <h4>添加互动环节</h4>
                <div class="two">
                  <div class="two_one">
                    <div>
                      <img src="../../../../assets/images/personal/004.png" alt="">
                    </div>
                    <div>
                      <img src="../../../../assets/images/personal/005.png" alt="">
                    </div>

                  </div>
                  <p>
                    <span @click="b4=true">添加讨论</span>
                    <span @click="b5=true">添加试题试卷</span>
                  </p>
                </div>

              </el-dialog>
            </div>
            <!-- 添加小节内嵌套五个 -->
            <div class="add_dialog_XJ_one">
              <div class="itemBox1">
                <el-dialog title="添加文本" :visible.sync="b1" style="width:1200px;">

                  <span>小节标题</span>
                  <el-input v-model="a.titles" placeholder="请输入章节" style="width:400px;margin-bottom:20px"></el-input><br>
                  <span>小节内容</span>
                  <el-input v-model="a.content" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                  <el-button type="primary" @click="addBox(index)">确定</el-button>
                  <el-button type="success" @click="b1=false;">取消</el-button>

                </el-dialog>
              </div>
              <div class="itemBox2"></div>
              <div class="itemBox3"></div>
              <div class="itemBox4"></div>
              <div class="itemBox5"></div>
            </div>
            <!-- end -->
            <!-- 修改章节 -->
            <div>
              <el-dialog title="修改" :visible.sync="dialogZJ" style="width:1200px;">

                <span>章节</span>
                <el-input v-model="item.chapter" placeholder="请输入章节" style="width:400px;margin-bottom:20px"></el-input><br>
                <span>标题</span>
                <el-input v-model="item.title" placeholder="请输入标题" style="width:400px;margin-bottom:20px"></el-input><br>
                <el-button type="primary" @click="noRepalce">取消</el-button>
                <el-button type="success" @click="updataInfos">修改</el-button>

              </el-dialog>
            </div>
            <!-- 内循环 -->
            <div class="forOneBox" v-for="(item1,index1) in item.knobChild" :key="index1">
              <div class="one">
                <span>{{item1.titles}}</span>
                <span>{{item1.content}}</span>
              </div>

              <div class="two">
                <i class="el-icon-edit"></i>
                <!-- 删除小节 -->
                <i class="el-icon-delete" @click="deleteKnob(index,index1)"></i>

              </div>

            </div>

          </div>
          <!-- 添加张 -->
          <div class="btn1">
            <el-button type="primary" @click="addChapter">添加章节</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      a: {
        titles: "",
        content: "",
      },
      // 五个框
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b5: false,
      //小节内容
      // 添加小节
      add_dialog_XJ: false,
      //章弹出框
      dialogZJ: false,
      //修改小节弹出框
      dialogXJ: false,
      //章节数组
      chapterArry: [{
        chapter: "章节",
        title: "标题",
        knobChild: [

        ]
      }],

      // 署名为3单位
      arryList: "",
      groupId: "",
      orgName: "",
      username: "",
      infoList: "",
      num: 0,
      // 要提交的数据
      form: "",
      //学段
      form1: "",
      //单位成员
      form2: {
        limit: 10,
        page: 1,
        stage_id: "",
        subject_id: "",
        province_id: "",
        city_id: "",
        county_id: "",
        realname: "",
        org_name: "",
        group_name: "",
      }
    }
  },
  created() {
    //通过缓存得到用户名
    this.username = localStorage.getItem("user_name")
    //通过路由得到表单信息
    this.form = this.$route.query.Infor;
    //上传信息
    this.form1 = this.$route.query.learnName;
    //组id
    this.groupId = this.$route.query.groupId;
    this.init();
    this.getTitle();
    this.getWorkPoeple();
  }, methods: {
    updataInfos() {
      // 关闭修改章节框
      this.dialogZJ = false
    },
    // 关闭修改章节框
    noRepalce() {
      this.dialogZJ = false
    },
    // 弹出章节修改框
    editZJ(index) {
      this.dialogZJ = true
    },
    //删除节
    deleteKnob(index, index1) {
      this.chapterArry[index].knobChild.splice(index1, 1)
    },
    // 添加章
    addChapter() {
      this.chapterArry.push(
        {
          chapter: "章节",
          title: "标题",
          knobChild: [

          ]
        }
      )

    },
    //删除章
    deleteBox(index) {
      this.chapterArry.splice(index, 1)
    },
    // 添加节
    addBox(index) {

      let b = this.a
      // let b=this.content
      this.chapterArry[index].knobChild.push(b)
      // this.chapterArry[index].knobChild.push(b)

      // this.$set(this.chapterArry[index],'knobChild',arr)
    },
    //得到指定成员
    async getWorkPoeple() {
      let res = await this.$axios.get("get_appoint_user", {
        params: {
          limit: 10,
          page: 1,
          stage_id: this.form2.stage_id,
          subject_id: this.form2.subject_id,
          province_id: this.form2.province_id,
          city_id: this.form2.city_id,
          county_id: this.form2.county_id,
          realname: this.form2.realname,
          org_name: this.form2.org_name,
          group_name: this.form2.group_name
        }
      });
      this.arryList = res.data.data.data;
    },
    //删除
    deleteInforMation() {
      this.$confirm('删除该课程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        setTimeout(() => {
          this.$router.go(-2)
        }, 1000)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //返回修改
    toBack() {
      this.$router.push({
        path: `/groupCreteCourse?groupId=${this.groupId}`
      })
    },
    //  初始化数据
    async init() {
      // 从缓存里得到用户信息
      let userId = localStorage.getItem("id")
      let res = await this.$axios.get(`/user/${userId}/edit`)
      this.orgName = res.data.data.org_name;
    },
    //得到个人简介
    async getTitle() {
      // 从缓存里得到用户信息
      let userId = localStorage.getItem("id")
      let res = await this.$axios.get(`user/${userId}/little`)
      this.infoList = res.data.data
      this.form2.org_name = res.data.data.org_name
    },
    //    视频介绍
    getOne() {
      this.num = 0;
    },
    //    视频内容
    getTwo() {
      this.num = 1;
    }
  }
}
</script>

<style lang="less">
//num=2
.nextCourseDetail {
  width: 100%;
  //头部
  .nextTopCourse {
    width: 80%;
    margin-left: 10%;
    display: flex;
    margin-bottom: 10px;
    // 左边图片
    .TopLeft {
      width: 40%;
      height: 293px;
      border: 1px solid #cccc;
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    //头部右边
    .TopRight {
      width: 60%;
      margin: auto 0;
      .titleTop {
        height: 30px;
        display: flex;
        margin-bottom: 10px;
        h5 {
          line-height: 28px;
          margin-right: 15px;
        }
        div {
          margin-right: 5px;
        }
      }
      //  便签
      .bq {
        display: flex;
        height: 30px;
        margin-bottom: 10px;
        div {
          margin-right: 10px;
          font-size: 15px;
          span {
            color: #999999;
          }
        }
      }
      //最后一行
      .bq_last {
        font-size: 15px;
        height: 100px;
        span {
          color: #999999;
        }
      }
    }
    //  按钮
    #wzt {
      font-size: 14px;
      color: red;
    }
  }
  .dbBox {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #cccc;

    .boxContent {
      width: 80%;
      margin-left: 10%;
      background: #ffffff;
      padding-bottom: 50px;
      //循环box
      .forBox {
        width: 900px;
        margin-top: 20px;

        margin-left: 40px;
        line-height: 40px;
        padding: 5px;
        //    章样式
        .titleItem {
          display: flex;
          justify-content: space-between;
          background: rgba(248, 248, 248, 1);

          border: 1px solid #cccc;
          .one {
            span {
              margin-right: 20px;
            }
          }
          .two {
            cursor: pointer;
            i {
              margin-right: 10px;
            }
            font-size: 17px;
            span {
              margin-right: 10px;
            }
          }
        }
        ///添加小节弹出框
        .add_dialog_XJ {
          .one {
            .one_one {
              display: flex;
              div {
                margin-right: 80px;
                width: 56px;
                height: 50px;
              }
            }
            p {
              span {
                margin-right: 80px;
              }
            }
          }
          .two {
            .two_one {
              display: flex;
              div {
                margin-right: 100px;
                width: 56px;
                height: 50px;
              }
            }
            p {
              span {
                margin-right: 80px;
              }
            }
          }
        }
        //添加小节内嵌套五个
        .add_dialog_XJ_one {
          .itemBox1 {
          }
          .itemBox2 {
          }
          .itemBox3 {
          }
          .itemBox4 {
          }
          .itemBox5 {
          }
        }
        //    节样式
        .forOneBox {
          display: flex;
          width: 80%;
          margin-left: 10%;
          margin-top: 20px;
          border: 1px solid #1890ff;
          background: #409eff;
          justify-content: space-between;
          .one {
            span {
              margin-right: 20px;
            }
          }
          .two {
            cursor: pointer;
            i {
              margin-right: 10px;
            }
            font-size: 17px;
            span {
              margin-right: 10px;
            }
          }
        }
      }
      .btn1 {
        width: 900px;
        margin-top: 80px;
        text-align: center;
      }
      .Box_top {
        display: flex;
        div {
          margin-top: 20px;
          margin-left: 20px;
          line-height: 50px;
          text-align: center;
          width: 100px;
          height: 50px;
          font-weight: bold;
        }
        .active {
          color: #409eff;
          border-bottom: 2px solid #409eff;
        }
      }
      .Box_middle {
        margin-top: 20px;
        height: 400px;
        h4 {
          margin-left: 35px;
        }
        div {
          p {
          }
        }
      }
      .Box_bottom {
        margin-top: 20px;

        h4 {
          margin-left: 35px;
        }
        .itemBox {
          margin-left: 35px;
          margin-top: 20px;
          display: flex;
          .list_left {
            margin-right: 20px;
            img {
              width: 50px;
              height: 50px;
            }
          }
          .list_right {
            span:nth-of-type(1) {
              font-size: 15px;
              font-weight: bold;
            }
            span:nth-of-type(2) {
              font-size: 15px;
              color: #cccc;
            }
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
</style>