<template>
  <div class="TrainRes">
    <div class="w_layout ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>研修资源</span>
        </p>
      </div>
      <div class="bottomBox">
        <div class="Art_left">
          <h4>资源分类</h4>
          <ul v-if="ResTypeData.length > 0">
            <li :class="index == active ? 'active' : ''" v-for="(item, index) in ResTypeData" :key="index"
              @click="onClickFile(item.id, index)">
              <img src="../../../assets/images/teacherStudio/floder.png" alt="" />
              <span class="ml5">{{ item.name }}</span>
            </li>
          </ul>
          <CommonEmpty v-else />
        </div>
        <div class="Art_right">
          <div class="df dfb mb10">
            <p class="topT">资源列表</p>
            <div class="df" v-if="detailData.role != 0">
              <div class="btnBox btnBox2 mr20" @click="pushRes(1)" v-if="workData.is_addUser == 1 || workData.role">
                上传微课
              </div>
              <div class="btnBox" @click="pushRes(2)" v-if="workData.is_addUser == 1 || workData.role">
                上传文档
              </div>
            </div>
          </div>

          <div class="selectBox pl10">
            <div class="selectNavBox active">
              <div class="sTitle">资源类别</div><span class="ml20 mr16">|</span>
              <div class="listBox" :class="form.search_resource_type == item.id ? 'activeBox' : ''"
                v-for="(item, index) in typeList" :key="index" @click="typeChange(item)">
                {{ item.name }}
              </div>
            </div>
            <div class="selectNavBox mt10" v-if="form.search_resource_type != 0">
              <div class="sTitle">资源类型</div><span class="ml20 mr16">|</span>
              <div class="listBox" :class="form.search_sources_id == item.id ? 'activeBox' : ''"
                v-for="(item, index) in typeList2" :key="index" @click="typeChange2(item)">
                {{ item.name }}
              </div>
            </div>
          </div>

          <div v-if="total > 0">
            <div class="demoBox">
              <div v-for="(item, index) in ResData" :key="index" class="forBox">
                <div class="aboutBox" :class="item.audit_status == 5 ? 'aboutFas' : ''"
                  v-if="item.audit_status == 1 || item.audit_status == 5">
                  {{ item.audit_status == 1 ? '待审核' : item.audit_status == 5 ? '未通过' : '' }}
                </div>
                <div class="leftBox cp" @click="onGoDetail(item.id, item.resource_id)">
                  <div class="oneBox">
                    <!-- //上层图片 -->
                    <div class="topImg">
                      <img :src="item.ext | showImg" alt="" />
                      <div class="bottomTxt">{{ item.ext }}</div>
                    </div>
                    <!-- 下层文字 -->
                  </div>
                  <div class="twoBox">
                    <p>{{ item.title }}</p>
                    <div class="df oneList">
                      <p class="ellipsis-1">
                        <img src="@/assets/fileIcon/see_icon.png" alt="">
                        浏览量：{{ item.view_count }}
                      </p>

                      <p class="ellipsis-1">
                        <img src="@/assets/fileIcon/shoucang_icon.png" alt="">
                        收藏次数：{{ item.collect_count }}
                      </p>

                      <p class="ellipsis-1">
                        <img src="@/assets/fileIcon/size_icon.png" alt="">
                        文件大小：{{ item.size | fileSizeFilter }}
                      </p>

                      <p class="ellipsis-1">
                        <img src="@/assets/fileIcon/shijian_icon.png" alt="">
                        上传时间：{{ item.create_time.substring(0, 10) }}
                      </p>

                      <p class="ellipsis-1">
                        <img src="@/assets/fileIcon/men_icon.png" alt="">
                        贡献者：{{ item.username }}
                      </p>
                    </div>
                    <div class="twolist">
                      <div class="mr15 divc1 ellipsis-1" v-if="item.sources_name">{{ item.sources_name }}</div>
                      <div class="mr15 divc2 ellipsis-1" v-if="item.stage_name || item.subject_name">{{ item.stage_name
                      }}{{ item.subject_name }}</div>
                      <div class="mr15 divc3 ellipsis-1" v-if="item.grade_name">{{ item.grade_name }}</div>
                      <div class="mr15 divc4 ellipsis-1" v-if="item.type_name">{{ item.type_name }}</div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="btnBoxs" v-if="item.user_id == user_id">
                  <div class="cursor mb15" @click="pushText(item)">编辑 ></div>
                  <div class="cursor" @click="delList(item.id)">删除 ></div>
                </div>
                <div v-if="item.is_recommend == 1" class="threeBox">
                  四名推荐
                </div>
              </div>
            </div>

            <div v-show="total" class="pagina_div" style="height:50px">
              <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="form.page" :page-sizes="[8, 16, 24, 32]" :page-size="form.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>

          </div>
          <CommonEmpty v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onShowImages5 } from "@/utils/utils";

export default {
  props: ["workData"],
  data() {
    return {
      active: 0, //类型激活
      ResTypeData: [],
      ResData: [],
      total: 0,
      form: {
        limit: 8,
        page: 1,
        search_title: "",
        search_is_recommend: "",
        search_type: "",
        teaching_studio_id: localStorage.getItem("studioId"),
        search_resource_type: 0,
        search_sources_id: 0,
      },
      form1: {
        search_name: "",
        limit: 999,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
      typeList: [
        { id: 0, name: "全部", },
        { id: 1, name: "微课", },
        { id: 2, name: "文档", },
      ],
      typeList2: [],
      user_id: localStorage.getItem("id") || 0,
    };
  },
  created() {
    this.init();
    this.getAdminData();
  },
  filters: {
    showImg(ext) {
      return onShowImages5(ext);
    },
  },
  methods: {
    //删除
    delList(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.delApi,
        id,
      });
    },
    //删除接口
    delApi(id) {
      let params = {
        delId: [id]
      };
      this.$axios.delete("TeachingStudio/deleteTeachingStudioUserResource", {
        params
      }).then(() => {
        this.$message.success("删除成功");
        this.handleCurrentChange(1)
      });
    },
    //发布文章
    pushText(item) {
      this.$router.push({
        path: "/uploadRes",
        query: {
          id: item.id,
          type: item.resource_type
        }
      })
    },
    typeChange(item) {
      this.typeList2 = []
      let params = {
        resource_type: item.id
      }
      this.$axios.get("/resourceTypes", { params }).then((res) => {
        let arr = res.data.data
        arr.unshift({ id: 0, name: "全部" })
        this.typeList2 = arr;
      });
      this.$set(this.form, "search_sources_id", 0);
      this.$set(this.form, "search_resource_type", item.id);
      this.$set(this.form, "page", 1);
      this.getResList();
    },
    typeChange2(item) {
      this.$set(this.form, "search_sources_id", item.id);
      this.$set(this.form, "page", 1);
      this.getResList();
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    init() {
      //获取资源分类
      this.getResType();
    },
    //获取资源列表
    getResList() {
      this.$axios.get("TeachingStudioResource/listResource", {
        params: this.form,
      }).then((res) => {
        this.ResData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    //获取资源分类
    getResType() {
      this.$axios
        .get("TeachingStudioUserResourceType/list", {
          params: this.form1,
        })
        .then((res) => {
          this.ResTypeData = res.data.data.data;
          this.ResTypeData.unshift({
            id: "",
            name: "全部资源",
          }, {
            id: 1,
            name: "默认文件夹",
          });
          this.form.search_type = this.ResTypeData[0].id;
          this.getResList();
        });
    },

    // 详情
    onGoDetail(id, resource_id) {
      let routeData = this.$router.resolve({
        path: "/resourcedetail",
        query: {
          id,
          resource_id,
          is_show_head: 1,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //筛选
    onClickFile(id, index) {
      this.active = index;
      this.form.search_type = id;
      this.getResList();
    },

    //跳转上传资源页
    pushRes(val) {
      this.$router.push({
        path: "/uploadRes",
        query: {
          type: val
        }
      });
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    //跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.TrainRes {
  // background: #f7f7f7;
  padding-bottom: 50px;

  .ArrBox {
    .title {
      height: 48px;
      font-size: 14px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    .bottomBox {
      display: flex;

      //  左边导航
      .Art_left {
        width: 274px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 20px;
        padding: 6px 0px;
        box-sizing: border-box;

        h4 {
          font-size: 18px;
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-weight: 700;
          color: #333333;
          box-sizing: border-box;
          padding-left: 20px;
        }

        ul {
          box-sizing: border-box;
          width: 234px;
          margin-left: 20px;
          height: 100%;

          &::-webkit-scrollbar {
            width: 5px;
            height: 1px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: skyblue;
            background-image: -webkit-linear-gradient(45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent);
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #ededed;
            border-radius: 10px;
          }

          li {
            border-radius: 4px;
            position: relative;
            width: 100%;
            height: 48px;
            line-height: 48px;
            padding-left: 10px;
            box-sizing: border-box;
            background: rgba(238, 246, 255, 1);
            margin-bottom: 4px;
            cursor: pointer;

            img {
              margin-bottom: -4px;
            }

            &:hover {
              color: #fff;
              background: #3489FF;
            }

            &.active {
              color: #fff;
              background: #3489FF;
            }
          }
        }
      }

      // 右边列表
      .Art_right {
        flex: 1;
        background: #ffffff;
        border-radius: 4px;
        padding: 20px 16px;
        box-sizing: border-box;

        .topT {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 10px;
          padding-left: 10px;
        }

        .selectNavBox {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        //筛选
        .selectBox {
          background: #fff;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          cursor: pointer;
          margin-bottom: 25px;
          border-radius: 6px;
          position: relative;

          .creBtn_div {
            position: absolute;
            top: 20px;
            right: 20px;
          }

          .sTitle {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            color: #333;
          }

          .listBox {
            display: flex;
            align-items: center;
            height: 30px;
            margin-right: 10px;
            border-radius: 4px 3px 4px 4px;
            padding: 0 8px;
          }

          .activeBox {
            display: flex;
            align-items: center;
            height: 25px;
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            padding: 1px 8px;
            color: #fff;
          }
        }



        .demoBox {
          .forBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ebebeb;
            padding: 20px 12px;
            position: relative;
            border-radius: 6px;
            position: relative;

            &:hover {
              background: #eef6ff;
            }

            &:first-child {
              border-top: 1px solid #ebebeb;
            }

            .aboutBox {
              position: absolute;
              top: 0;
              left: 0;
              background: #ff8201;
              border-radius: 6px 0;
              padding: 1px 8px;
              color: #fff;
              font-size: 14px;

              &.aboutFas {
                background: #ff0607;
              }
            }

            .leftBox {
              display: flex;

              .oneBox {
                cursor: pointer;
                width: 68px;
                height: 80px;

                .topImg {
                  height: 82px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  img {
                    width: 68px;
                    height: 80px;
                  }

                  .bottomTxt {
                    position: absolute;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    bottom: 5px;
                    color: #fff;
                    font-size: 14px;
                  }
                }
              }

              .twoBox {
                width: 840px;
                //第一行标题
                margin-left: 20px;

                >p {
                  font-size: 16px;
                  font-weight: 400;
                  text-align: left;
                  color: #333333;
                  margin-bottom: 10px;
                  white-space: pre-line;
                  overflow: hidden;
                  -webkit-line-clamp: 1;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }

                .oneList {
                  margin-bottom: 10px;

                  p {
                    margin-right: 20px;
                    font-size: 14px;

                    img {
                      width: 16px;
                      height: 16px;
                      margin-bottom: -3px;
                    }
                  }
                }

                //标签
                .twolist {
                  display: flex;

                  div {
                    box-sizing: border-box;
                    min-width: 120px;
                    padding: 1px 10px;
                    opacity: 1;
                    background: #ecf5ff;
                    border: 1px solid #fff;
                    border-radius: 6px;
                    text-align: center;
                    color: #409eff;

                  }

                  .divc1 {
                    color: #fff;
                    background: #52B876;
                  }

                  .divc2 {
                    color: #0493FF;
                    background: #fff;
                    border: 1px solid #0493FF;
                  }

                  .divc3 {
                    color: #52B876;
                    background: #fff;
                    border: 1px solid #52B876;
                  }

                  .divc4 {
                    color: #fff;
                    background: #0493FF;
                  }
                }

                span {
                  margin-right: 10px;
                }
              }
            }

            .btnBoxs {
              width: 100px;

              >div {
                width: 92px;
                height: 32px;
                background: #fef0f0;
                border: 1px solid #f78383;
                border-radius: 4px;
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f78383;
                text-align: center;
                line-height: 32px;

                &:first-child {
                  border: 1px solid #67c23a;
                  color: #67c23a;
                  background: #f0f9eb;
                }

                &:hover {
                  background: #f78383;
                  color: #fff;

                  &:first-child {
                    background: #67c23a;
                    color: #fff;
                  }
                }
              }
            }

            //标签盒子
            .threeBox {
              position: absolute;
              right: 0;
              top: 0;
              padding: 0 10px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background: #ff8201;
              color: #fff;
              font-size: 14px;
              border-radius: 0 5px 0 5px;
            }
          }
        }

        .block {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    }
  }
}

.btnBox {
  width: 100px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  background: #3489fe;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
}

.btnBox2 {
  background: #52B876;
}
</style>