<template>
  <div class="addpaper">
    <div class="w_d center_one">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>研修视频</el-breadcrumb-item>
            <el-breadcrumb-item>创建课程</el-breadcrumb-item>
            <el-breadcrumb-item>视频详情</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">添加小节</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="content_one">
        <div class="one">
          <p>
            <span>*</span>小节标题：
          </p>
          <el-input max="50" placeholder="最多50个字..." class="one_one"></el-input>
        </div>
        <div class="two">
          <p>
            <span>*</span>小节类型：
          </p>
          <el-radio v-model="radio" label="1">文本</el-radio>
          <el-radio v-model="radio" label="2">文档</el-radio>
          <el-radio v-model="radio" label="3">视频</el-radio>
          <el-radio v-model="radio" label="4">讨论</el-radio>
          <el-radio v-model="radio" label="5">试题试卷</el-radio>
        </div>
        <div class="three">
          <p>
            <span>*</span>小节类型：
          </p>
          <div class="three_one">从试题库选题</div>
          <div class="three_one">从试卷库选题</div>
        </div>
        <div class="four"></div>
        <div class="five">
          <div class="five_block" v-for="(item,index) in 3" :key="index">
            <div class="images"></div>
            <div class="five_block_one">
              <div class="fun">详情</div>
              <div class="fun">移除</div>
              <div class="fun">纠错</div>
              <div class="fun">上移</div>
              <div class="fun">下移</div>
            </div>
            <div class="five_block_two"></div>
          </div>
        </div>
        <div class="six">
          <div class="submit cursor">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.addpaper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .content_one {
    .one {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      p:nth-child(1) {
        span {
          color: #ff0607;
        }
      }
      .one_one {
        width: 400px;
      }
    }
    .two {
      display: flex;
      align-items: center;
      margin-bottom: 31px;
      p:nth-child(1) {
        span {
          color: #ff0607;
        }
      }
    }
    .three {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .three_one {
        width: 110px;
        height: 34px;
        background: #3489fe;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 18px;
      }
    }
    .four {
      width: 100%;
      border-top: 1px solid #f0f0f0;
      margin-bottom: 30px;
    }
    .five {
      .five_block {
        margin-bottom: 30px;
        .images {
          width: 100%;
          height: 231px;
          border: 1px solid pink;
          margin-bottom: 27px;
        }
        .five_block_one {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 30px;
          .fun {
            width: 80px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #3489fe;
            border-radius: 2px;
            color: #3489fe;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 14px;
          }
        }
        .five_block_two {
          width: 100%;
          border-top: 1px dashed #f0f0f0;
        }
      }
      .five_block:last-child {
        .five_block_two {
          width: 100%;
          border-top: 0;
        }
      }
    }
    .six {
      display: flex;
      justify-content: center;
      margin-bottom: 244px;
      margin-top: 60px;
      .submit {
        width: 148px;
        height: 42px;
        background: #ff8201;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>