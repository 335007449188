<template>
  <div class="createcource">
    <div class="w_d center">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/researchcource'}">研修视频</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">创建课程</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="main">
        <el-form ref="form" label-position="right" label-width="120px">
          <el-form-item label="课程名称：">
            <el-input class="one" placeholder="最多50个字..."></el-input>
          </el-form-item>
          <el-form-item label="直播封面：">
            <div class="two">
              <div class="two_images">
                <img src="@/assets/images/live.png" />
              </div>
              <div class="two_images">
                <img src="@/assets/images/live.png" />
              </div>
              <div class="two_images">
                <img src="@/assets/images/live.png" />
              </div>
              <div class="two_upload">
                <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="课程分类：">
            <div class="three">
              <div class="three_one">
                <div class="three_one_block cursor active" @click="two">
                  上课
                </div>
                <div class="three_one_block">说课</div>
                <div class="three_one_block">讲座</div>
              </div>
            </div>
          </el-form-item>
          <div class="inline">
            <el-form-item label="所属学段：">
              <el-select v-model="value" placeholder="请选择" class="four">
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学段学科：">
              <el-select v-model="value" placeholder="请选择" class="four">
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属年级：">
              <el-select v-model="value" placeholder="请选择" class="four">
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item label="教材版本：">
              <el-select v-model="value" placeholder="请选择" class="four">
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="章节：">
              <el-select v-model="value" placeholder="请选择" class="four">
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="sort == 'goclass'">
            <el-form-item label="视频介绍：">
              <el-input type="textarea" :rows="7" placeholder="请简单介绍本视频内容" v-model="textarea"></el-input>
            </el-form-item>
            <el-form-item label="授课视频：">
              <div class="five cursor">点击添加</div>
            </el-form-item>
            <el-form-item label="上课资源：">
              <div class="five cursor">点击添加</div>
            </el-form-item>
          </div>
          <div v-if="sort == 'listening'" class="listening">
            <el-form-item label="授课老师：">
              <el-input class="listening_one"></el-input>
            </el-form-item>
            <el-form-item label="授课说明：">
              <el-input type="textarea" :rows="7" placeholder="请简单介绍本视频内容" v-model="textarea"></el-input>
            </el-form-item>
            <el-form-item label="授课形式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="授课时间：">
              <div class="listening_two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期" class="listening_two_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="开始时间"></el-time-picker>
                <div class="listening_two_two">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期" class="listening_two_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="结束时间"></el-time-picker>
              </div>
            </el-form-item>
            <el-form-item label="授课地点：">
              <el-input></el-input>
            </el-form-item>
            <el-form-item label="评课时间：">
              <div class="listening_two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期" class="listening_two_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="开始时间"></el-time-picker>
                <div class="listening_two_two">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期" class="listening_two_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="结束时间"></el-time-picker>
              </div>
            </el-form-item>
            <el-form-item label="上课资源：">
              <div class="listening_three cursor">点击添加</div>
            </el-form-item>
            <el-form-item label="允许谁评课：">
              <div class="listening_four">
                <div>
                  <el-radio v-model="radio" label="1">所有人</el-radio>
                  <el-radio v-model="radio" label="2">本校成员</el-radio>
                  <el-radio v-model="radio" label="3">指定教研组</el-radio>
                  <el-radio v-model="radio" label="4">指定成员</el-radio>
                </div>
                <div class="listening_four_one">
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                </div>
                <div class="listening_four_two">
                  <div class="nine_left">
                    <div class="nine_left_one">
                      <div>选择指定成员</div>
                      <i class="el-icon-d-arrow-right"></i>
                    </div>
                    <div class="nine_left_two">清空所有</div>
                  </div>
                  <div class="nine_right">
                    <el-tag closable effect="dark" v-for="(item, index) in 30" :key="index" class="nine_tag">标签一</el-tag>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-if="sort == 'prepare'" class="prepare">
            <el-form-item label="课程概述：">
              <el-input type="textarea" :rows="7" placeholder="请简单介绍本视频内容" v-model="textarea"></el-input>
            </el-form-item>
            <el-form-item label="备课时间：">
              <div class="prepare_one">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期" class="prepare_one_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="开始时间"></el-time-picker>
                <div class="prepare_one_two">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期" class="prepare_one_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="结束时间"></el-time-picker>
              </div>
            </el-form-item>
            <el-form-item label="作者署名：">
              <div class="prepare_two">
                <el-radio v-model="radio" label="1"> 个人署名 </el-radio>

                <el-tooltip class="item" effect="dark" content="选择后，课程的作者为个人" placement="bottom-start">
                  <div class="prepare_two_one">?</div>
                </el-tooltip>
                <el-radio v-model="radio" label="1"> 教研组署名 </el-radio>

                <el-tooltip class="item" effect="dark" content="选择后，课程的作者为指定教研组" placement="bottom-start">
                  <div class="prepare_two_one">?</div>
                </el-tooltip>
                <el-radio v-model="radio" label="1"> 单位署名 </el-radio>

                <el-tooltip class="item" effect="dark" content="选择后，课程的作者为本单位" placement="bottom-start">
                  <div class="prepare_two_one">?</div>
                </el-tooltip>
                <el-radio v-model="radio" label="1"> 自定义署名 </el-radio>

                <el-tooltip class="item" effect="dark" content="选择后，课程的作者为自定义名称" placement="bottom-start">
                  <div class="prepare_two_one">?</div>
                </el-tooltip>

              </div>
            </el-form-item>
            <el-form-item label="允许谁备课：">
              <div class="prepare_three">
                <div>
                  <el-radio v-model="radio" label="1">所有人</el-radio>
                  <el-radio v-model="radio" label="2">本校成员</el-radio>
                  <el-radio v-model="radio" label="3">指定教研组</el-radio>
                  <el-radio v-model="radio" label="4">指定成员</el-radio>
                </div>
                <div class="prepare_three_one">
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                  <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                </div>
                <div class="prepare_three_two">
                  <div class="nine_left">
                    <div class="nine_left_one">
                      <div>选择指定成员</div>
                      <i class="el-icon-d-arrow-right"></i>
                    </div>
                    <div class="nine_left_two">清空所有</div>
                  </div>
                  <div class="nine_right">
                    <el-tag closable effect="dark" v-for="(item, index) in 30" :key="index" class="nine_tag">标签一</el-tag>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="next">
        <el-button type="warning" class="commond_button">保存并下一步</el-button>
      </div>
    </div>

    <!-- 添加文档视频对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="dialogVisible" width="1342px" :show-close="false">
        <div class="dialog_content">
          <div class="title">
            <div class="title_word">添加文档/视频</div>
            <div class="point_one avtive cursor" @click="one('one')">
              我的资源
            </div>
            <div class="point_one cursor" @click="one('two')">上传资源</div>
          </div>
          <div class="hengxian"></div>
          <div class="main" v-if="data1 == 'one'">
            <div class="main_one">
              <p>学段：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>学科：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>年级：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>类型：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
            </div>
            <div class="main_two">
              <p>地区：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>教材：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
            </div>
            <div class="main_three">
              <p>知识点：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>文档类型：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <div class="reset_button">重置</div>
              <div class="sousuo_button">搜索</div>
            </div>
            <div class="main_block">
              <div class="images">
                <img src="@/assets/images/word.png" />
                <span>资源类型</span>
              </div>
              <div class="block_word">
                <p>
                  标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题
                </p>
                <p>学段学科 / 教材 / 年级 / 章 / 节/</p>
                <div class="block_gion">
                  <div class="block_gion_one">地区</div>
                  <div class="block_gion_one">年份</div>
                </div>
                <div class="block_time">
                  <span>上传时间：2020-8-4</span>
                  <span>收藏次数：2452</span>
                  <span>文件大小：204KB</span>
                </div>
              </div>
              <div class="block_sign"></div>
            </div>
            <div class="main_block">
              <div class="images">
                <img src="@/assets/images/word.png" />
                <span>资源类型</span>
              </div>
              <div class="block_word">
                <p>
                  标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题
                </p>
                <p>学段学科 / 教材 / 年级 / 章 / 节/</p>
                <div class="block_gion">
                  <div class="block_gion_one">地区</div>
                  <div class="block_gion_one">年份</div>
                </div>
                <div class="block_time">
                  <span>上传时间：2020-8-4</span>
                  <span>收藏次数：2452</span>
                  <span>文件大小：204KB</span>
                </div>
              </div>
              <div class="block_sign active">
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div>
          <div class="main_block_two" v-if="data1 == 'two'">
            <div class="main_center">
              <el-form ref="form" :model="form" label-width="120px">
                <el-form-item label="上传文件：">
                  <div class="one cursor">选择文件</div>
                </el-form-item>
                <el-form-item label="资源名称：">
                  <el-input placeholder="默认为文件名" class="two"></el-input>
                </el-form-item>
                <el-form-item label="资源类型：">
                  <el-select v-model="value" placeholder="请选择资源类型" class="two">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学段学科：">
                  <el-select v-model="value" placeholder="请选择学段" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择学科" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择年级" class="three">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="适用教材：">
                  <el-select v-model="value" placeholder="请选择版本" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择章" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择节" class="three">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="地区年份：">
                  <el-select v-model="value" placeholder="请选择地区" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择年份" class="three">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="共享范围：">
                  <el-radio v-model="radio" label="1">所有人</el-radio>
                  <el-radio v-model="radio" label="3">本校成员</el-radio>
                  <el-radio v-model="radio" label="2">教研组成员</el-radio>
                </el-form-item>
                <!-- <div class="four">
                  <el-checkbox v-model="checked">允许下载资源文件</el-checkbox>
                </div>
                <div class="five"></div> -->
              </el-form>
              <div class="four">
                <el-checkbox v-model="checked">允许下载资源文件</el-checkbox>
              </div>
              <div class="five"></div>
              <div class="six">
                <p>注意事项：</p>
                <p>
                  1.文件支持格式：
                  doc、docx、xls、xlsx、ppt、pptx、pdf、txt、flv、mp4、mpg、gif、jpg、jpeg、png
                </p>
                <p>2.单个文件大小：≤ 200M</p>
              </div>
            </div>
          </div>
          <div class="save">
            <div class="save_one cursor">取消</div>
            <div class="save_two cursor">保存</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      data1: "one",
      // goclass listening prepare
      sort: "prepare",
    };
  },
  methods: {
    one(str) {
      this.data1 = str;
    },
    two() {
      this.$router.push("/goclasssteptwo");
    },
  },
};
</script>

<style scoped lang="less">
.createcource {
  display: flex;
  justify-content: center;
  /deep/ .is-link {
    color: #606266;
    font-weight: 400;
  }
  .center {
    .main {
      margin-top: 71px;
      display: flex;
      justify-content: center;
      .one {
        width: 600px;
      }
      .two {
        display: flex;
        .two_images {
          margin-right: 26px;
          img {
            width: 130px;
            height: 130px;
            border-radius: 4px;
          }
        }
        .two_upload {
          /deep/ .el-upload--picture-card {
            width: 130px;
            height: 130px;
            border-radius: 4px;
          }
        }
      }
      .three {
        .three_one {
          display: flex;
          .three_one_block {
            width: 100px;
            height: 34px;
            background: #eaf3ff;
            border-radius: 4px;
            color: #666666;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px;
          }
          .active {
            background: #3489fe;
            color: #ffffff;
          }
        }
      }
      .inline {
        display: flex;
      }
      .four {
        width: 220px;
      }
      .five {
        width: 134px;
        height: 34px;
        background: #3489fe;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .listening {
        .listening_one {
          width: 180px;
        }
        .listening_two {
          display: flex;
          align-items: center;
          .listening_two_one {
            margin-right: 14px;
            width: 180px;
          }
          .listening_two_two {
            margin: 0px 26px;
          }
        }
        .listening_three {
          width: 134px;
          height: 34px;
          background: #3489fe;
          border-radius: 4px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .listening_four {
          .listening_four_one {
            width: 550px;
            height: 100px;
            border: 1px solid #ececec;
            border-radius: 0px 0px 4px 4px;
            overflow: auto;
            padding-left: 20px;
          }
          .listening_four_two {
            width: 1020px;
            height: 100px;
            border: 1px solid #ececec;
            border-radius: 0px 0px 4px 4px;
            display: flex;

            .nine_left {
              width: 130px;
              height: 100%;
              border-right: 1px solid #ececec;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .nine_left_one {
                width: 100px;
                height: 40px;
                background: #409eff;
                border-radius: 4px;
                line-height: 24px;
                color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                margin-top: 20px;
              }
              .nine_left_two {
                color: #9a9ea5;
                cursor: pointer;
              }
            }
            .nine_right {
              padding-top: 10px;
              padding-left: 20px;
              overflow: auto;
              .nine_tag {
                margin-right: 10px;
              }
            }
          }
        }
      }
      .prepare {
        .prepare_one {
          display: flex;
          align-items: center;
          .prepare_one_one {
            margin-right: 14px;
            width: 180px;
          }
          .prepare_one_two {
            margin: 0px 26px;
          }
        }
        .prepare_two {
          display: flex;
          align-items: center;
          margin-top: 12px;

          .prepare_two_one {
            width: 16px;
            height: 16px;
            background-color: #ff8201;
            border-radius: 50%;
            font-size: 12px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            // float: left;
            position: relative;
            top: -2px;
            left: -20px;
          }
          .prepare_two_two {
            display: flex;
          }
        }
        .prepare_three {
          .prepare_three_one {
            width: 550px;
            height: 100px;
            border: 1px solid #ececec;
            border-radius: 0px 0px 4px 4px;
            overflow: auto;
            padding-left: 20px;
          }
          .prepare_three_two {
            width: 1020px;
            height: 100px;
            border: 1px solid #ececec;
            border-radius: 0px 0px 4px 4px;
            display: flex;

            .nine_left {
              width: 130px;
              height: 100%;
              border-right: 1px solid #ececec;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              .nine_left_one {
                width: 100px;
                height: 40px;
                background: #409eff;
                border-radius: 4px;
                line-height: 24px;
                color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                margin-top: 20px;
              }
              .nine_left_two {
                color: #9a9ea5;
                cursor: pointer;
              }
            }
            .nine_right {
              padding-top: 10px;
              padding-left: 20px;
              overflow: auto;
              .nine_tag {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .next {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 60px 0px;
    }
  }
  .dialog_content {
    overflow: hidden;
    .title {
      display: flex;
      margin: 35px 0px 23px 40px;
      align-items: center;
      .title_word {
        font-weight: 700;
        color: #333333;
        // margin-right: 26px;
      }
      .point_one {
        width: 96px;
        height: 30px;
        background: rgba(52, 137, 254, 0.1);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 26px;
      }
      .avtive {
        color: #fffefe;
        background: #3489fe;
      }
    }
    .hengxian {
      width: 100%;
      height: 1px;
      background: #dedede;
    }
    .main {
      margin-top: 38px;
      margin-left: 40px;
      .main_one {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        p:nth-child(1) {
          margin-left: 15px;
        }
        p:nth-child(3) {
          margin-left: 81px;
        }
        p:nth-child(5) {
          margin-left: 49px;
        }
        p:nth-child(7) {
          margin-left: 39px;
        }
      }
      .main_two {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        p:nth-child(1) {
          margin-left: 15px;
        }
        p:nth-child(3) {
          margin-left: 81px;
        }
      }
      .main_three {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        p:nth-child(3) {
          margin-left: 50px;
        }
        .reset_button {
          margin-left: 444px;
          margin-right: 20px;
          width: 82px;
          height: 34px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 6px;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .sousuo_button {
          width: 82px;
          height: 34px;
          background: #ff8201;
          border-radius: 6px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .main_block {
        width: 1264px;
        height: 200px;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        margin-bottom: 20px;
        .images {
          width: 160px;
          height: 160px;
          border: 1px solid #ececec;
          border-radius: 6px;
          margin-top: 20px;
          margin-left: 20px;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-top: 43px;
            margin-bottom: 20px;
          }
        }
        .block_word {
          margin-right: 490px;
          p:nth-child(1) {
            margin-top: 34px;
            margin-bottom: 18px;
            font-weight: 700;
            width: 494px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p:nth-child(2) {
            color: #666666;
            margin-bottom: 18px;
          }
          .block_gion {
            display: flex;
            margin-bottom: 14px;
            .block_gion_one {
              width: 69px;
              height: 24px;
              background: #ffffff;
              border: 1px solid #ff8201;
              border-radius: 4px;
              color: #ff8201;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
            }
          }
          .block_time {
            color: #999999;
            span {
              margin-right: 30px;
            }
          }
        }
        .block_sign {
          margin-top: 80px;
          width: 34px;
          height: 34px;
          background: #ffffff;
          border: 1px solid #ececec;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .active {
          background: #3489fe;
          color: #ffffff;
        }
      }
    }
    .main_block_two {
      margin-top: 88px;
      display: flex;
      justify-content: center;
      .main_center {
        .one {
          width: 134px;
          height: 34px;
          background: #3489fe;
          border-radius: 4px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .two {
          width: 439px;
        }
        .three {
          width: 220px;
        }
        .four {
          margin-left: 120px;
          margin-bottom: 37px;
        }
        .five {
          width: 770px;
          //  width: 775px;
          border-top: 1px dashed #dedede;
          margin-bottom: 30px;
          margin-left: 35px;
        }
        .six {
          margin-left: 35px;
          // margin-bottom:50px;
          p:nth-child(2) {
            color: #999999;
            margin-bottom: 20px;
            margin-top: 20px;
          }
          p:nth-child(3) {
            color: #999999;
          }
        }
      }
    }
    .save {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
      .save_one {
        width: 148px;
        height: 42px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        margin-right: 22px;
      }
      .save_two {
        width: 148px;
        height: 42px;
        background: #ff8201;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>