<template>
  <div class='AuditTearm'>
    <div class="mBox">
      <h4 class="title_h4">
        审核组员创建的<span v-if="flat == 1">直播</span>
        <span v-if="flat == 2">活动</span>
        <span v-if="flat == 3">课程</span>
        <span v-if="flat == 4">话题</span>
        (<span style="color:#FF8201">{{total || 0}}</span>)
      </h4>
      <div class="ForBox">
        <CommonEmpty v-if="!forList.length" hVal="100%"></CommonEmpty>
        <div class="iBox" v-for="(item,index) in forList" :key="index">
          <div class='t' v-if="flat != 4">
            <img :src="item.cover_url" alt="">
          </div>

          <!-- 直播 -->
          <div class="cBox" v-if="flat == 1">
            <h4>
              {{item.title }}
            </h4>
            <p>
              <span>主播：</span>
              {{item.username}}
              <span style="margin-left: 48px;">创建时间：</span>
              {{item.create_time}}
            </p>
            <p>
              <span>开播时间：</span>
              {{item.start_date}}
              &nbsp;
              &nbsp;
              {{item.start_time}}
            </p>
          </div>

          <!-- 活动 -->
          <div class="cBox" v-if="flat == 2">
            <h4>
              {{item.name}}
            </h4>
            <p>
              <span>创建的组员：</span>
              {{item.realname}}
              <span style="margin-left: 48px;">创建时间：</span>
              {{item.create_time}}
            </p>
            <p>
              <span>开始时间：</span>
              {{item.start_date}}
              &nbsp;
              &nbsp;
              {{item.start_time}}
            </p>
          </div>

          <!-- 课程 -->
          <div class="cBox" v-if="flat == 3">
            <h4>
              {{item.name}}
            </h4>
            <p>
              <span>创建的组员：</span>
              {{item.username}}
            </p>
            <p>
              <span>创建时间：</span>
              {{item.create_time}}
            </p>
          </div>

          <!-- 话题 -->
          <div class="cBox" v-if="flat == 4" style="margin-right:250px">
            <h4>
              {{item.title}}
            </h4>
            <p>
              <span>发起组员：</span>
              {{item.username}}
            </p>
            <p>
              <span>发起时间：</span>
              {{item.create_time}}
            </p>
          </div>

          <div class="btBox">
            <el-button type="primary" @click="ToAudit(item.id)">通过</el-button>
            <el-button type="info" plain @click="toShow(item.id)">不通过</el-button>
          </div>
        </div>
        <div class="block" v-if="total">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="form1.page"
            :page-size="form1.limit" layout="prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="填写不通过的理由" :visible.sync="Visible" style="width:900px;margin:0 auto;">
      <el-input type="textarea" :rows="6" resize='none' placeholder="请输入内容" v-model="form2.reason">
      </el-input>
      <div style="margin:10px 0">
        <el-button @click="toClear()">取消</el-button>
        <el-button type="primary" @click="noPass()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { LiveList, AuditLive, ActivityList, ActivityAudit, CourseList, CourseAudit, TopicList, TopicAudit } from '@/views/teachingGroup/api/auditTearm.js'
export default {
  props: {
    fa_flat: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      //判断是直播还是活动、课程标识
      flat: this.$route.query.type,
      Visible: false,
      total: 0,
      forList: [],
      form1: {
        limit: 10,
        page: 1,
        groupId: this.$route.query.groupId
      },

      form2: {
        id: "",
        audit_status: "",
        reason: "",
      }
    }
  },
  created() {
    if (this.fa_flat) {
      this.flat = this.fa_flat;
    }
    this.init();
  },
  computed: {
  },
  methods: {
    //初始化
    init() {
      this.getLiveData();//直播列表
    },
    //获取直播列表数据
    getLiveData() {
      let p = '';
      if (this.flat == 1) {
        p = LiveList(this.form1)
      } else if (this.flat == 2) {
        p = ActivityList(this.form1)
      } else if (this.flat == 3) {
        p = CourseList(this.form1)
      } else {
        p = TopicList(this.form1)
      }
      p.then((res) => {
        this.forList = res.data.data.data || [];
        this.total = res.data.data.total;
      })
    },
    //审核通过
    ToAudit(id) {
      let p = '';
      this.form2.reason = '';
      this.form2.id = id;
      this.form2.audit_status = 4;
      if (this.flat == 1) {
        p = AuditLive(this.form2)
      } else if (this.flat == 2) {
        p = ActivityAudit(this.form2)
      } else if (this.flat == 3) {
        p = CourseAudit(this.form2)
      } else {
        p = TopicAudit(this.form2)
      }
      p.then(() => {
        this.$message.success("审核通过");
        this.getLiveData();//直播列表
      })
    },
    //显示
    toShow(id) {
      this.Visible = !this.Visible;
      this.form2.id = id;
    },
    //审核不通过
    noPass() {
      let p = '';
      this.form2.audit_status = 5;
      if (this.flat == 1) {
        p = AuditLive(this.form2)
      } else if (this.flat == 2) {
        p = ActivityAudit(this.form2)
      } else if (this.flat == 3) {
        p = CourseAudit(this.form2)
      } else {
        p = TopicAudit(this.form2)
      }
      p.then(() => {
        this.$message.success("审核不通过");
        this.Visible = !this.Visible;
        this.getLiveData();//直播列表
      })
    },
    //取消审核
    toClear() {
      this.Visible = !this.Visible;
    },

    //分页
    handleSizeChange(val) {
      this.form1.limit = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.form1.page = val;
      this.init();
    }

  },
}
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.AuditTearm {
  .mBox {
    background: #ffffff;
    border-radius: 6px;
    padding: 40px 0;
    margin-bottom: 60px;
    padding-left: 30px;
    overflow: hidden;
    h4 {
      margin-bottom: 29px;
    }
  }
  .ForBox {
    .block {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
    .iBox {
      width: 1052px;
      height: 130px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 6px;
      display: flex;
      padding: 14px 14px;
      margin-bottom: 20px;
      .t {
        margin-right: 20px;
        img {
          width: 182px;
          height: 102px;
          border-radius: 6px;
        }
      }
      .cBox {
        margin-right: 115px;
        h4 {
          width: 531px;
          font-size: 16px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          text-align: left;
          color: #1a1a1a;
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: 22px;
        }
        p {
          margin-bottom: 14px;
          span {
            font-size: 16px;
            font-family: Microsoft YaHei Regular,
              Microsoft YaHei Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
          }
        }
      }

      .btBox {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
