  <template>
  <div class="public_css_module  personal_right_box testPaper">

    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的试卷</div>
        <div v-for="(item, index) in selectData" :key="index" :class="num == item.id ? 'active' : 'noClass'" @click="showData(item.id)">
          <span>{{ item.name }}</span>
        </div>
      </div>
      <el-dropdown>
        <span class="rightLink">
          <img src="@/assets/imgFIle_4/add.png" />
          <span>我要组卷</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="intobanxue(1)">手工组卷</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="intobanxue(2)">智能组卷</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </span>
      </el-dropdown>
    </div>

    <!-- 我收藏的区域 -->
    <div v-show="num == 1" class="resource_list" v-loading="listLoading_1">
      <div class="resource_item_div" v-for="(item, index) in collection_list" :key="index">
        <div class="resource_item_box">
          <div class="left_div">
            <img src="@/assets/imgFIle_4/shijuan.png" />
          </div>
          <div class="center_div">
            <div class="title_div ellipsis_1">{{ item.paperName || "-" }}</div>
            <div class="info_div fxaic">
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/shijian_icon.png" />
                </div>
                上传时间: {{ item.create_time || "-"}}
              </div>
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/shoucang_11icon.png" />
                </div>
                题目数: {{ item.number || "0"}}
              </div>
            </div>
            <div class="tag_1_box fxaic">
              <div class="tag_item">{{ item.area || "-" }}</div>
              <div class="tag_item tag_1_item">{{ item.year || "-" }}</div>
              <div class="tag_item tag_1_item">{{ item.parseName || "-" }}{{ item.subjectName || "-" }}</div>
            </div>
          </div>
          <div class="right_div">
            <div class="btn_1" @click="handleCollection(item)">取消收藏</div>
            <div class="btn_2" @click="upload(item)">下载</div>
            <div class="btn_2 btn_3" @click="intoview(item, 'two')">查看</div>
          </div>
        </div>
      </div>

      <CommonEmpty v-if="!listLoading_1 && !collection_list.length" hVal="200px"></CommonEmpty>

      <div v-if="collection_list && collection_list.length != 0" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="fromcollection.page"
          :page-sizes="[10,20,30,40,50]" :page-size="fromcollection.limit" layout="total, sizes, prev, pager, next, jumper" :total="collectiontotal">
        </el-pagination>
      </div>

    </div>

    <!-- 我组的卷区域 -->
    <div v-show="num == 2" class="resource_list" v-loading="listLoading_2">
      <div class="resource_item_div" v-for="(item, index) in get_my_list" :key="index">
        <div class="resource_item_box">
          <div class="left_div">
            <img src="@/assets/imgFIle_4/shijuan.png" />
          </div>
          <div class="center_div">
            <div class="title_div ellipsis_1">{{ item.paperName || "-" }}</div>
            <div class="info_div fxaic">
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/shijian_icon.png" />
                </div>
                上传时间: {{ item.create_time || "-"}}
              </div>
              <div class="info_item fxaic">
                <div class="info_img">
                  <img src="@/assets/imgFIle_4/shoucang_11icon.png" />
                </div>
                题目数: {{ item.number || "0"}}
              </div>
            </div>
            <div class="tag_1_box fxaic">
              <div class="tag_item tag_1_item tag_2_item">
                {{ item.subjectName || "-" }}
              </div>
            </div>
          </div>
          <div class="right_div">
            <div class="btn_1" @click="deletePaper_1(item)">删除</div>
            <!-- <div class="btn_1" @click="intoedit()">编辑</div> -->
            <div class="btn_2" @click="upload(item)">下载</div>
            <div class="btn_2 btn_3" @click="intoview(item, 'one')">查看</div>
          </div>
        </div>
      </div>

      <CommonEmpty v-if="!listLoading_2 && !get_my_list.length" hVal="200px"></CommonEmpty>

      <div v-if="get_my_list && get_my_list.length != 0" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="fromcreate.page"
          :page-sizes="[10,20,30,40,50]" :page-size="fromcreate.limit" layout="total, sizes, prev, pager, next, jumper" :total="my_listtotal">
        </el-pagination>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "testpaper",
  data() {
    return {
      listLoading_1: true, //表格Loading
      listLoading_2: true, //表格Loading
      selectData: [
        {
          id: 2,
          name: "我组卷的",
        },
        {
          id: 1,
          name: "我收藏的",
        },
      ],
      num: 2,
      // 我组的卷参数
      fromcreate: {
        page: 1,
        limit: 10,
      },
      // 我组的卷数据
      get_my_list: [],
      // 我组的卷数据总数
      my_listtotal: 0,
      // 我收藏的参数
      fromcollection: {
        page: 1,
        limit: 10,
      },
      // 收藏的卷数据
      collection_list: [],
      // 收藏的卷数据总数
      collectiontotal: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMylist();
      this.getCollection();
    },
    handleSizeChange1(val) {
      this.$set(this.fromcreate, "page", 1);
      this.$set(this.fromcreate, "limit", val);
      this.getMylist();
    },
    handleCurrentChange1(val) {
      this.$set(this.fromcreate, "page", val);
      this.getMylist();
    },
    handleSizeChange2(val) {
      this.$set(this.fromcollection, "page", 1);
      this.$set(this.fromcollection, "limit", val);
      this.getCollection();
    },
    handleCurrentChange2(val) {
      this.$set(this.fromcollection, "page", val);
      this.getCollection();
    },
    // 组卷跳转瓣学网函数
    intobanxue(str) {
      if (str == 1) {
        // let url = `http://www.xkyun.net/resource/#/paperGeneration?aescode=${localStorage.getItem(
        //   "aes_code"
        // )}`;
        let url = `https://bx20.dc-it.cn/home/#/questionBank/questionChapter?aescode=${localStorage.getItem("aes_code")}`;
        window.open(url);
      };
      if (str == 2) {
        let url = `https://bx20.dc-it.cn/home/#/questionBank/smartChoice?aescode=${localStorage.getItem("aes_code")}`;
        window.open(url);
      };
    },
    // 进入瓣学网编辑界面
    intoedit() {
      let url = `http://www.xkyun.net/resource/#/paperManage/mySetvolume?aescode=${localStorage.getItem(
        "aes_code"
      )}&type=personinfo`;
      window.open(url);
    },
    // 进入瓣学网查看界面
    intoview(item, str) {
      if (str == "one") {
        this.$router.push({
          path: "/paperdetail",
          query: {
            paper_id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/paperdetail",
          query: {
            paper_id: item.paper_id,
          },
        });
      }
    },
    // 我组的卷获取接口
    getMylist() {
      this.listLoading_2 = true;
      try {
        let obj = {
          params: this.fromcreate,
        };
        this.$axios.get("papers/get_my_list", obj).then((res) => {
          this.get_my_list = res.data.data.data;
          this.my_listtotal = res.data.data.total;
          this.$nextTick(() => {
            this.listLoading_2 = false;
          });
        });
      } catch (error) {
        this.get_my_list = [];
        this.my_listtotal = 0;
        this.$nextTick(() => {
          this.listLoading_2 = false;
        });
      }
    },
    // 改变展示区域函数
    showData(id) {
      this.$set(this.fromcreate, "page", 1);
      this.$set(this.fromcollection, "page", 1);
      this.num = id;
      this.init();
    },
    // 收藏的卷获取接口
    getCollection() {
      this.listLoading_1 = true;
      try {
        let obj = {
          params: this.fromcollection,
        };
        this.$axios.get("papers/get_my_collect_list", obj).then((res) => {
          this.collection_list = res.data.data.data;
          this.collectiontotal = res.data.data.total;
          this.$nextTick(() => {
            this.listLoading_1 = false;
          });
        });
      } catch (error) {
        this.collection_list = [];
        this.collectiontotal = 0;
        this.$nextTick(() => {
          this.listLoading_1 = false;
        });
      }
    },
    // 下载函数
    upload(item) {
      let obj = {
        params: {
          kejian_id: item.id,
        },
      };
      this.$axios.get("papers/down_paper", obj).then((res) => {
        window.open(res.data.data);
      });
    },
    // 删除试卷
    deletePaper_1(item) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deletePaper_2(item);
      }).catch(() => { });
    },
    deletePaper_2(item) {
      let params = {
        kejian_id: item.id,
      };
      this.$axios({
        url: "papers/del_paper",
        method: "delete",
        params,
      }).then((res) => {
        this.$message.success("删除成功");
        this.getMylist();
      });
    },
    // 处理收藏函数
    handleCollection(item) {
      let params = {
        kejian_id: item.paper_id,
      };
      this.$axios({ url: "papers/collect_paper", method: "post", params, }).then((res) => {
        this.$message.success(res.data.data.data);
        this.getCollection();
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.testPaper {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}
.resource_list {
  min-height: 100px;
  .resource_item_div {
    width: 100%;
    height: 148px;
    padding: 0 32px;
    &:hover {
      background-color: #f3faff;
      .title_div {
        color: #3489fe;
      }
    }
  }
  .resource_item_box {
    width: 100%;
    height: 100%;
    border-bottom: 1px dashed #dae7ed;
    display: flex;
    padding-top: 34px;
    position: relative;
  }
  .left_div {
    width: 68px;
    height: 80px;
    margin-right: 24px;
    position: relative;
    > img {
      width: 68px;
      height: 80px;
    }
    .left_name {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 12px;
      line-height: 26px;
      height: 26px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }
  }
  .center_div {
    flex: 1;
    .title_div {
      font-size: 16px;
      height: 18px;
      line-height: 18px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
    .tag_3_box {
      margin-top: 8px;
      width: 650px;
      .tag_3_name_1 {
        width: 650px;
      }
      > .span_2 {
        font-size: 14px;
        line-height: 14px;
        color: #666;
      }
      > .span_1 {
        display: inline-block;
        width: 0.5px;
        height: 12px;
        margin: 0 14px;
        background-color: #ccc;
      }
    }
    .tag_1_box {
      margin-top: 16px;
      .tag_item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
        color: rgba(4, 147, 255, 1);
        padding: 0 8px;
        border: 1px solid #0493ff;
        color: #0493ff;
      }
      .tag_1_item {
        border: 1px solid #51b875;
        color: #51b875;
        margin-left: 10px;
      }
      .tag_2_item {
        margin-left: 0px;
      }
    }
    .info_div {
      margin-top: 10px;
      .info_item {
        font-size: 14px;
        line-height: 16px;
        color: rgba(102, 102, 102, 1);
        margin-right: 32px;
      }
      .info_img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .right_div {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    > div {
      height: 32px;
      font-size: 15px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }
    .btn_1 {
      color: #3489ff;
      margin-right: 40px;
      font-size: 15px;
      line-height: 32px;
      text-align: center;
    }
    .btn_2 {
      font-size: 15px;
      line-height: 32px;
      border-radius: 8px;
      width: 92px;
      color: #fff;
      background-color: #51b875;
      font-weight: bold;
      text-align: center;
    }
    .btn_3 {
      margin-left: 24px;
      background-color: #3489ff;
    }
  }
}
</style>