<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth1">
      <div class="public-width">

        <div class="mainBox1">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/luntan.png" alt="">
          </div>
          <p class="mt40 mb40">以县、市两级为单位组织开展主题式四名沙龙活动，服务一线，形式灵活多样</p>
          <div class="txtBox">
            <div>
              <div>活动目的</div>
              <div>发展高质量教育，培养高素质人才。</div>
            </div>
            <div>
              <div>活动主题</div>
              <div>教学改革、课程改革、教育治理、学生发展、教师发展等。</div>
            </div>
            <div>
              <div>活动形式</div>
              <div>主题分享、互动交流、专家微讲座等。</div>
            </div>
            <div>
              <div>活动内容</div>
              <div>经验分享、成果展示、学术研讨、热点探讨等。</div>
            </div>
            <div>
              <div>宣传方式</div>
              <div>一个渠道投稿，八个渠道传播。通过媒体报道、录播直播、视频回放等形式，带动区域教师参与研讨，共享成果。</div>
            </div>
          </div>
          <div class="btnBox mt40">
            <div>
              <p>高峰论坛活动</p>
              <img src="@/assets/banner_img/aserviceImg/rightIcon.png" alt="">
            </div>
            <div>
              <p>四名伴我行</p>
              <img src="@/assets/banner_img/aserviceImg/rightIcon.png" alt="">
            </div>
            <div>
              <p>竞赛活动</p>
              <img src="@/assets/banner_img/aserviceImg/rightIcon.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth2 pt20">
      <div class="public-width">
        <div class="mainBox2">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/chuji.png" alt="">
          </div>
          <div class="fuwuBox mt20">
            <h4 class="mb20">服务内容：</h4>
            <div class="fuwuul">
              <div class="fuwuli">
                作品内容形式审核服务
              </div>
              <div class="fuwuli">
                作品内容编辑校对服务
              </div>
              <div class="fuwuli">
                作品内容排版服务
              </div>
              <div class="fuwuli">
                作品封面设计服务
              </div>
              <div class="fuwuli">
                出版全流程管理服务
              </div>
              <div class="fuwuli">
                100套图书塑封精装样书服务
              </div>
              <div class="fuwuli">
                按需发行宣传服务
              </div>
            </div>
          </div>
          <div class="fuwuBox mt20">
            <h4 class="mb20">服务优势：</h4>
            <div class="fuwuul2">
              <div class="fuwuli2">
                <h5>专业性高</h5>
                <p>已与国内知名出版社建立长期合作关系。拥有一支包括编审等高级人才在内的稳定的编辑队伍，
                  建立了高效的内容组织开发体系、规范的编辑加工体系和有效的版权保护体系，
                  对内容特别是专业性较强的教育内容进行严格规范。
                </p>
              </div>
              <div class="fuwuli2">
                <h5>周期保障</h5>
                <p>建构畅通的沟通渠道，安排编辑团队提前介入，充分沟通后，
                  提前进行书稿的编辑加工工作，以求“书稿”顺利达到国家
                  出版要求。
                </p>
              </div>
              <div class="fuwuli2">
                <h5>服务周到</h5>
                <p>提供从编辑、排版、印刷、发行到宣传等全方位的出版服务，为作者提供一站式的出版解决方案。
                </p>
              </div>
              <div class="fuwuli2">
                <h5>成果推广</h5>
                <p>拥有专业的市场营销团队，能够为作者提供针对性的宣传和推广策略，
                  提高书籍的知名度和销售量。可结合书籍实际开展主题培训宣传，课程开发等。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth1 pt20 pb20">
      <div class="public-width">
        <div class="mainBox3">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/keti.png" alt="">
          </div>
          <div class="ketiBox mt20">
            <h4 class="mb20">服务内容：</h4>
            <div class="ketiUl">
              <div class="ketili">
                <p>课题基础指导</p>
                <p>课题申报</p>
                <p>课题开题</p>
                <p>中期检查评审</p>
                <p>课题论文发表支持</p>
                <p>课题结题评审</p>
              </div>
              <div class="ketili">
                <p>课题进阶指导</p>
                <p>课题调研考察</p>
                <p>课题研究培训</p>
                <p>课题研究技术支持</p>
                <p>参赛指导</p>
                <p>课题成果展示交流</p>
                <p>教学成果奖申报指导</p>
              </div>
              <div class="ketili">
                <p>预期成果</p>
                <p>一批骨干四名</p>
                <p>优质示范课例</p>
                <p>优质教学资源</p>
                <p>论文研究报告</p>
                <p>课题活动合集</p>
              </div>
            </div>
          </div>
          <div class="ketiBox mt20">
            <h4 class="mb20">特色亮点：</h4>
            <div class="ketiUl2">
              <div class="ketili2">
                <div>面向教师</div>
                <div>提供课题基础指导与课题研究进阶服务，涵盖课题研究全流程各个阶段的指导服务，
                  能够满足不同的教师的需求。</div>
              </div>
              <div class="ketili2">
                <div>面向学校</div>
                <div>制定了“强校-优校-名校”的分级指导计划，为学校提供差异化服务，
                  满足不同学校发展及师资队伍建设需求，通过课题帮助学校凝练特色、打造品牌。</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth3">
      <div class="public-width">
        <div class="mainBox4 mt20 pt50">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/peixun.png" alt="">
          </div>
          <div class="peixunBox mt70">
            <div>
              <img src="@/assets/banner_img/aserviceImg/picon1.png" alt="">
              <h4>四名名家讲座</h4>
              <p>教学成果获奖者/评委主讲</p>
            </div>
            <div>
              <img src="@/assets/banner_img/aserviceImg/picon2.png" alt="">
              <h4>教育科研能力提升工程</h4>
              <p>全员班、骨干班、高研班等</p>
            </div>
            <div>
              <img src="@/assets/banner_img/aserviceImg/picon3.png" alt="">
              <h4>数据素养能力提升培训</h4>
              <p>全面提升教师数据素养</p>
            </div>
            <div>
              <img src="@/assets/banner_img/aserviceImg/picon4.png" alt="">
              <h4>命题能力提升培训</h4>
              <p>专家指导、技术培训、命题大赛等</p>
            </div>
            <div>
              <img src="@/assets/banner_img/aserviceImg/picon5.png" alt="">
              <h4>教育惩戒培训</h4>
              <p>集中培训+工作坊研修</p>
            </div>
            <div>
              <img src="@/assets/banner_img/aserviceImg/picon6.png" alt="">
              <h4>教师国际化发展培训</h4>
              <p>提供国际化学习和专业发展机会</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth2 pt20 pb40">
      <div class="public-width">
        <div class="mainBox5">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/pinggu.png" alt="">
          </div>
          <div class="pingguBox mt20">
            <p>评估服务依托四名工作室绩效考核系统进行，该系统可实现各地区工作室年度考核、
              周期考核的全流程信息化管理，将过程性考核与结果性评价相结合，使考核的过程更加科学，
              考核的结果更加公平、公正。系统支持考核环节自主配置、考核指标体系本地导入、考评方案灵活设置、
              评审专家自动指派、考评材料一键导出等功能，同时提供考核过程管理和数据统计汇总功能。
            </p>
            <div class="pingguUl mt40">
              <div class="pingguLi">
                服务内容
              </div>
              <div class="pingguLi">
                佐证材料汇集
              </div>
              <div class="pingguLi">
                评估报告生成
              </div>
              <div class="pingguLi">
                组织专家评审
              </div>
              <div class="pingguLi">
                优秀成果共享
              </div>
            </div>
            <div class="pingguUl pingguUl2 mt20">
              <div class="pingguLi">
                服务特色
              </div>
              <div class="pingguLi">
                过程性考核材料可追溯
              </div>
              <div class="pingguLi">
                评估流程管理信息化
              </div>
              <div class="pingguLi">
                评估报告自动生成
              </div>
              <div class="pingguLi">
                个性化改进建议提供
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth1 pt20">
      <div class="public-width">
        <div class="mainBox6">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/pingtai.png" alt="">
          </div>
          <div class="pingtai">
            <div class="pingtaiTop">
              <div v-for="(item, index) in pingtaiList" :key="index" @click="changePingtai(index)"
                :class="pingtaiActive == index ? 'acSty' : ''">{{ item.name }}</div>
            </div>
            <div class="pingtaiBom">
              <h4>{{ pingtaiList[pingtaiActive].name }}</h4>
              <div class="df dfb">
                <div class="bomleft mt30">
                  <div class="pbox" v-if="pingtaiActive == 0">
                    <p class="mb10"> 提供四名网络工作室平台建设服务，该平台集教研、学习、资源、管理、考核于一体，
                      满足教师线上教研、学习研讨、活动管理等多重需求，促进教师专业发展。
                    </p>
                    <p>
                      平台部分主要包括四名工作室平台、四名绩效考核系统、四名公众号等的建设，
                      并与各大权威流量媒体合作实现四名原创及活动报道等优质内容的广泛传播。
                    </p>
                  </div>
                  <div class="pbox" v-if="pingtaiActive == 1">
                    <p class="mb10">
                      瓣学网IPAS（Instructional Process Analysis System）课堂评价系统是适用于信息化教学环境中的教学过程分析与管理系统，是一款轻量级的微信小程序。
                    </p>
                    <p>
                      目前，该系统集成了两种教学分析工具，一个是基于TBAS ( Teaching Behavior Analysis System
                      )的分析工具，一个是基于S-T分析法的分析工具。这些分析工具都是基于既定的教学行为分类编码规则，通过特定频率的教学行为采样，自动生成课堂分析报告，助力教师反思教学实践，促进教师专业发展。
                    </p>
                  </div>
                  <div class="pbox" v-if="pingtaiActive == 2">
                    <p>
                      教师专业发展云平台，以师资队伍管理、教师专业发展为切入点，综合运用云计算、大数据、移动互联网、泛在网等技术，
                      通过教师云服务平台基础设施及平台应用的建设，实现师资队伍建设管理业务、教师专业发展各业务数据的动态汇聚和流转，
                      通过“教、研、训、管、评”多维度数据的采集、智能分析和预测，为师资队伍管理提供决策依据，为教师专业成长提供发展方向及指导性建议，推动区域优质师资队伍的建设，达到促进教育整体发展的最终目标。
                    </p>
                  </div>
                  <div class="pbox" v-if="pingtaiActive == 3">
                    <p>
                      职称评审系统主要实现教师职称专业资格评审的资料上传与审核、评审专家入库与抽取遴选、在线评审、
                      在线答辩、评审表决等功能，以及评审过程各环节的数据跟踪与监控，解决传统职称评审工作量大、
                      透明度差、过程繁杂、效率低等问题，有效地提高评审工作效率、提升评审水准，
                      实现专业资格职称评审工作的公开、公正、公平。
                    </p>
                  </div>
                  <div class="dfbox" v-if="pingtaiActive == 0">
                    <div>四名大课堂</div>
                    <div>资源共建共享</div>
                    <div>学员培养培训</div>
                    <div>线上教研活动</div>
                    <div>工作室管理</div>
                    <div>工作室考核</div>
                  </div>
                  <div class="dfbox dfbox1" v-if="pingtaiActive == 1">
                    <div>现场课</div>
                    <div>录制课</div>
                    <div>单人评课</div>
                    <div>多人评课</div>
                  </div>
                  <div class="dfbox dfbox2" v-if="pingtaiActive == 2">
                    <div>专业成长档案</div>
                    <div>资格证定期注册</div>
                    <div>评优评先</div>
                    <div>校(园)长绩效考核</div>
                    <div>培训项目管理</div>
                  </div>
                  <div class="dfbox dfbox2" v-if="pingtaiActive == 3">
                    <div>评审项目管理</div>
                    <div>教师材料申报</div>
                    <div>评委指派</div>
                    <div>在线答辩、评委表决</div>
                    <div>常态化监督审查</div>
                  </div>
                </div>
                <div class="bomright">
                  <img v-if="pingtaiActive == 0" src="@/assets/banner_img/aserviceImg/pingtaibg.png" alt="">
                  <img v-if="pingtaiActive == 1" src="@/assets/banner_img/aserviceImg/pingtaibg2.png" alt="">
                  <img v-if="pingtaiActive == 2" src="@/assets/banner_img/aserviceImg/pingtaibg4.png" alt="">
                  <img v-if="pingtaiActive == 3" src="@/assets/banner_img/aserviceImg/pingtaibg3.png" alt="">
                </div>
              </div>
              <div class="dian">
                <div v-for="(item, index) in pingtaiList" :key="index" @click="changePingtai(index)"
                  :class="pingtaiActive == index ? 'dianSty' : ''"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth4 mt30 pt30">
      <div class="public-width">
        <div class="mainBox7">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/jiditit.png" alt="">
          </div>
          <div class="jidibox">
            <img src="@/assets/banner_img/aserviceImg/jidi1.png" alt="">
            <img src="@/assets/banner_img/aserviceImg/jidi2.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth2 pt30 pb80">
      <div class="public-width">
        <div class="mainBox7">
          <div class="titleImg">
            <img src="@/assets/banner_img/aserviceImg/meiti.png" alt="">
          </div>
          <div class="jidibox">
            <img src="@/assets/banner_img/aserviceImg/logo.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pingtaiList: [
        { name: "四名网络工作室" },
        { name: "课堂评价系统" },
        { name: "教师专业发展云平台" },
        { name: "职称评审系统" },
      ],
      pingtaiActive: 0,
    };
  },
  created() {
    // this.addPingtai()
  },
  methods: {
    changePingtai(val) {
      this.pingtaiActive = val
    },
    addPingtai() {
      setInterval(() => {
        this.pingtaiActive += 1
        if (this.pingtaiActive == 4) {
          this.pingtaiActive = 0
        }
      }, 5000);
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.mainBox1 {
  margin-top: 20px;

  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .txtBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    >div {
      display: flex;
      width: 49%;
      margin-bottom: 20px;
      box-sizing: border-box;

      &:last-child {
        width: 100%;
        margin-bottom: 0;
      }

      >div {
        &:first-child {
          width: 110px;
          background: #145bb9;
          color: #fff;
          padding: 8px 10px;
          text-align: center;
        }

        &:last-child {
          background: #fff;
          padding: 8px 10px;
          width: calc(100% - 110px);
        }
      }
    }
  }

  .btnBox {
    display: flex;

    >div {
      flex: 1;
      width: calc(100% / 3 - 2px);
      height: 127px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      p {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 36px;
      }

      img {
        margin-top: -40px;
        width: 34px;
        height: 10px;
      }

      &:nth-child(1n) {
        background: #145bb9;
      }

      &:nth-child(2n) {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
      }

      &:hover {
        background: #ea1e70;
      }
    }
  }
}

.mainBox2 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .fuwuBox {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .fuwuul {
      display: flex;
      flex-wrap: wrap;

      .fuwuli {
        width: 305px;
        height: 56px;
        background: rgba(20, 91, 185, 0.1);
        margin-right: 20px;
        font-size: 20px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        line-height: 56px;

        &:nth-child(2) {
          background: #fde8f0;
        }

        &:nth-child(5) {
          background: #fde8f0;
        }

        &:nth-child(7) {
          background: #fde8f0;
        }

        &:nth-child(4) {
          background: #fde8f0;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    .fuwuul2 {
      display: flex;
      flex-wrap: wrap;

      .fuwuli2 {
        width: 624px;
        height: 300px;
        background: rgba(20, 91, 185, 0.1);
        margin-right: 32px;
        font-size: 20px;
        color: rgba(51, 51, 51, 1);
        line-height: 56px;
        padding: 30px 100px 30px 40px;
        box-sizing: border-box;
        margin-bottom: 32px;

        h5 {
          font-size: 36px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }

        p {
          margin-top: 20px;
          width: 433px;
          height: 152px;
          font-size: 16px;
          color: #fff;
          line-height: 40px;
        }

        &:nth-child(1) {
          background: url('~@/assets/banner_img/aserviceImg/fuwu1.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(2) {
          background: url('~@/assets/banner_img/aserviceImg/fuwu3.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(3) {
          background: url('~@/assets/banner_img/aserviceImg/fuwu2.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(4) {
          background: #ea1e70;
        }


        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

.mainBox3 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .ketiBox {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .ketiUl {
      display: flex;
      justify-content: space-between;

      .ketili {
        width: 405px;
        height: 523px;
        background: rgba(255, 255, 255, 1);
        padding: 10px;
        box-sizing: border-box;

        &:nth-child(2) {
          p {
            background: #fde8f0;

            &:first-child {
              background: #ea1e70;
            }
          }
        }

        p {
          text-align: center;
          width: 381px;
          height: 56px;
          line-height: 56px;
          background: rgba(20, 91, 185, 0.1);
          margin-bottom: 10px;
          font-size: 18px;
          color: #333333;

          &:first-child {
            width: 381px;
            height: 100px;
            line-height: 100px;
            background: rgba(20, 91, 185, 1);
            font-size: 30px;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .ketiUl2 {
      display: flex;
      justify-content: space-between;

      .ketili2 {
        width: 624px;
        height: 112px;
        display: flex;

        &:last-child {
          >div {
            &:first-child {
              background: #ea1e70;
            }
          }
        }

        >div {
          &:first-child {
            width: 112px;
            height: 112px;
            background: rgba(20, 91, 185, 1);
            font-size: 26px;
            color: rgba(255, 255, 255, 1);
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          &:last-child {
            width: calc(100% - 112px);
            height: 112px;
            background: rgba(255, 255, 255, 1);
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
}

.mainBox4 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .peixunBox {
    width: 1278px;
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >div {
      width: 33.33%;
      height: 220px;
      background: #145bb9;
      text-align: center;
      padding: 30px;
      box-sizing: border-box;

      img {
        width: 68px;
        height: 68px;
      }

      h4 {
        font-size: 30px;
        color: rgba(255, 255, 255, 1);
      }

      p {
        margin-top: 10px;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
      }

      &:nth-child(2n) {
        background: #ea1e70;
      }
    }
  }
}

.mainBox5 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .pingguBox {
    >p {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      text-indent: 2em
    }

    .pingguUl {
      display: flex;
      justify-content: space-between;
      background: #145bb9;
      padding: 10px;

      .pingguLi {
        width: 242px;
        height: 68px;
        background: rgba(239, 246, 255, 1);
        line-height: 68px;
        text-align: center;
        font-size: 20px;
        color: rgba(51, 51, 51, 1);

        &:first-child {
          background: #145bb9;
          color: #fff;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }

    .pingguUl2 {
      background: #ea1e70;

      .pingguLi {
        &:first-child {
          background: #ea1e70;
        }
      }

    }
  }
}

.mainBox6 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .pingtai {
    margin-top: 30px;

    .pingtaiTop {
      display: flex;
      background: #fff;
      border-bottom: 2px solid #145bb9;

      >div {
        width: 25%;
        height: 56px;
        line-height: 56px;
        font-size: 18px;
        color: rgba(51, 51, 51, 1);
        border-right: 1px solid #eee;
        text-align: center;
        cursor: pointer;

        &.acSty {
          background: #145bb9;
          color: #fff;
          font-weight: bold;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .pingtaiBom {
      background: #fff;
      padding: 30px 50px;

      h4 {
        font-size: 26px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }

      .bomleft {
        .pbox {
          background: #ebf1ff;
          width: 632px;
          height: 226px;
          padding: 40px 40px;
          box-sizing: border-box;

          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: rgba(51, 51, 51, 1);
            text-indent: 2em;
          }
        }

        .dfbox {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 10px;

          div {
            width: 204px;
            height: 60px;
            background: rgba(20, 91, 185, 1);
            margin-bottom: 10px;
            font-size: 22px;
            color: rgba(255, 255, 255, 1);
            line-height: 60px;
            text-align: center;

            &:nth-child(2n) {
              background: #ea1e70;
            }
          }
        }

        .dfbox1 {
          div {
            width: 310px;

            &:nth-child(2n) {
              background: #ea1e70;
            }

            &:nth-child(3n) {
              background: #ea1e70;
            }

            &:nth-child(4n) {
              background: rgba(20, 91, 185, 1);
            }
          }
        }

        .dfbox2 {
          div {

            &:nth-child(2n) {
              background: #ea1e70;
            }

            &:nth-child(3n) {
              background: rgba(20, 91, 185, 1);
            }

            &:nth-child(4n) {
              width: 310px;
              background: #ea1e70;
            }

            &:nth-child(5n) {
              width: 310px;
            }
          }
        }
      }

      .dian {
        display: flex;
        justify-content: center;

        div {
          width: 8px;
          height: 8px;
          background: rgba(211, 220, 242, 1);
          border-radius: 4px;
          margin: 0 3px;

          &.dianSty {
            background: #145bb9;
          }
        }
      }
    }

  }
}

.mainBox7 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .jidibox {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    img {
      margin: 0 50px;
    }
  }
}
</style>