<template>
  <div class="public_css_module personal_right_box myTopic_page">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的话题</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'" @click="showData(item.id)">
          <span v-if="item.id != 1">{{ item.name }}</span>
          <el-dropdown trigger="click" v-if="item.id == 1" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">审核中</el-dropdown-item>
              <el-dropdown-item command="4">通过</el-dropdown-item>
              <el-dropdown-item command="5">未通过</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <router-link to="/createMyTopic" class="rightLink">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>发起话题</span>
      </router-link>
    </div>

    <!--全部 内容-->
    <div class="main" v-if="currentNum == 0 ||  currentNum == 3" v-loading="listLoading_3">
      <div class="item_div" v-for="(item, index) in getList3" :key="index">
        <div class="content_div">
          <div class="content_img">
            <img src="@/assets/imgFIle_4/shijuan123.png" />
          </div>
          <div class="content_left">
            <div class="titles" @click="toDetailPage(item)">
              <span class="sBox"> {{ item.audit_status_name }} </span>
              <p>#{{ item.title }}</p>
            </div>
            <span style="margin-right: 30px">
              {{
                item.teacher_group_name != ""
                  ? item.teacher_group_name
                  : item.username
              }}
              {{ item.username != "" ? item.username : "" }}
              {{ item.create_time }}
              发起
            </span>
            <span>最新回复时间 : {{ item.create_time }}</span>
          </div>
          <div class="content_right">
            <div v-if="item.is_update_role==1" class="item_op" @click="toEdit(item)">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/edit_btn.png" />
              </div>
              编辑
            </div>
            <div v-if="item.is_delete_role==1" class="item_op" @click="deleteData(item)">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/del_btn.png" />
              </div>
              删除
            </div>
            <div class="item_op active">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/liulan_btn.png" />
              </div>
              浏览{{ item.visit_count }}
            </div>
            <div class="item_op active">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/huifu_btn.png" />
              </div>
              回复{{ item.reply_count }}
            </div>

            <el-dropdown v-if="item.is_examine_role==1" pdown trigger="hover">
              <span class="name_xiala_tag" @click.stop="">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item divided @click.native="topic_to_page(item, 2)">
                  <i class="el-icon-circle-check"></i>审核通过
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="topic_to_page(item, 3)">
                  <i class="el-icon-circle-close"></i>审核不通过
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>
      </div>
      <CommonEmpty v-if="!listLoading_3 && !getList3.length" hVal="200px"></CommonEmpty>
      <div v-show="total3" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange3" @current-change="handleCurrentChange3" :current-page="currentPage3"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize3" layout="total, sizes, prev, pager, next, jumper" :total="total3">
        </el-pagination>
      </div>
    </div>

    <!-- 我创建的内容-->
    <div class="main" v-if="currentNum == 1" v-loading="listLoading_2">
      <div class="item_div" v-for="(item, index) in getList2" :key="index">
        <div class="content_div">
          <div class="content_img">
            <img src="@/assets/imgFIle_4/shijuan123.png" />
          </div>
          <div class="content_left">
            <div class="titles">
              <span class="sBox"> {{ item.audit_status_name }} </span>
              <p @click="toDetailPage(item)">#{{ item.title }}</p>
            </div>
            <span>{{
                item.teacher_group_name != ""
                  ? item.teacher_group_name
                  : item.username
              }}
              {{ item.username != "" ? item.username : "" }}
              {{ item.create_time }}
              发起
            </span>
            <span style="margin-left: 20px">最新回复时间 : {{ item.reply_time }}</span>
          </div>
          <div class="content_right">
            <div v-if="item.is_update_role==1" class="item_op" @click="toEdit(item)">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/edit_btn.png" />
              </div>
              编辑
            </div>
            <div v-if="item.is_delete_role==1" class="item_op" @click="deleteData(item)">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/del_btn.png" />
              </div>
              删除
            </div>
            <div class="item_op active">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/liulan_btn.png" />
              </div>
              浏览{{ item.visit_count }}
            </div>
            <div class="item_op active">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/huifu_btn.png" />
              </div>
              回复{{ item.reply_count }}
            </div>

            <el-dropdown v-if="item.is_examine_role==1" pdown trigger="hover">
              <span class="name_xiala_tag" @click.stop="">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item divided @click.native="topic_to_page(item, 2)">
                  <i class="el-icon-circle-check"></i>审核通过
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="topic_to_page(item, 3)">
                  <i class="el-icon-circle-close"></i>审核不通过
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>
      </div>
      <CommonEmpty v-if="!listLoading_2 && !getList2.length" hVal="200px"></CommonEmpty>
      <div v-show="total2" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="currentPage2"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize2" layout="total, sizes, prev, pager, next, jumper" :total="total2">
        </el-pagination>
      </div>
    </div>

    <!--我参与的 内容-->
    <div class="main" v-if="currentNum == 2" v-loading="listLoading_1">
      <div class="item_div" v-for="(item, index) in getList1" :key="index">
        <div class="content_div">
          <div class="content_img">
            <img src="@/assets/imgFIle_4/shijuan123.png" />
          </div>
          <div class="content_left">
            <div class="titles" @click="toDetailPage(item)">
              <span class="sBox"> {{ item.audit_status_name }} </span>
              <p>#{{ item.title }}</p>
            </div>
            <span>{{
                item.teacher_group_name != ""
                  ? item.teacher_group_name
                  : item.username
              }}
              {{ item.username != "" ? item.username : ""
              }}{{ item.create_time }}
              发起
            </span>
            <span style="margin-left: 20px">最新回复时间 : {{ item.reply_time }}</span>
          </div>
          <div class="content_right">
            <div v-if="item.is_update_role==1" class="item_op" @click="toEdit(item)">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/edit_btn.png" />
              </div>
              编辑
            </div>
            <div v-if="item.is_delete_role==1" class="item_op" @click="deleteData(item)">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/del_btn.png" />
              </div>
              删除
            </div>
            <div class="item_op active">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/liulan_btn.png" />
              </div>
              浏览{{ item.visit_count }}
            </div>
            <div class="item_op active">
              <div class="item_img_div">
                <img src="@/assets/imgFIle_3/huifu_btn.png" />
              </div>
              回复{{ item.reply_count }}
            </div>

            <el-dropdown v-if="item.is_examine_role==1" pdown trigger="hover">
              <span class="name_xiala_tag" @click.stop="">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item divided @click.native="topic_to_page(item, 2)">
                  <i class="el-icon-circle-check"></i>审核通过
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="topic_to_page(item, 3)">
                  <i class="el-icon-circle-close"></i>审核不通过
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>
      </div>
      <CommonEmpty v-if="!listLoading_1 && !getList1.length" hVal="200px"></CommonEmpty>
      <div v-show="total1" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import * as researchApi from '@/api/researchApi.js';
export default {
  data() {
    return {
      selectData: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "我发起的",
        },
        {
          id: 2,
          name: "我加入的",
        },
        {
          id: 3,
          name: "待我审核",
        },
      ],

      currentNum: 0,

      listLoading_1: true, //表格Loading
      listLoading_2: true, //表格Loading
      listLoading_3: true, //表格Loading
      currentPage1: 1,
      pageSize1: 10,
      total1: 0,

      currentPage2: 1,
      pageSize2: 10,
      total2: 0,

      currentPage3: 1,
      pageSize3: 10,
      total3: 0,

      user_id: localStorage.getItem("id") || "",
      //审核
      audit_status: "",
      //全部话题
      getList3: [],
      getList1: [],
      getList2: [],
    };
  },
  created() {
    this.initPageData();
  },
  methods: {
    initPageData() {
      let query = this.$route.query;
      if (query.pageType) {
        this.currentNum = Number(query.pageType);
      };
      if (this.currentNum == 1) {
        this.handleCommand(0)
      };
      if ([0, 2, 3].includes(Number(this.currentNum))) {
        this.showData(this.currentNum);
      };
    },
    // 直播 输入 理由
    topic_open_AffirmFn(item, num) {
      this.$prompt('请输入您的理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入您的理由，不能为空!',
        inputValidator: (valtext) => {
          if (valtext && valtext.length >= 2) {
            return true;
          };
          return false;
        }
      }).then(({ value }) => {
        switch (Number(num)) {
          case 2:
            this.topic_to_request(item, 2, value);
            break;
          case 3:
            this.topic_to_request(item, 3, value);
            break;
          default:
            break;
        }
      }).catch(() => {

      });
    },
    // 直播 确认理由
    topic_to_request(item, num, value) {
      if (item.is_personid == 1) {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
              teaching_studio_id: item.teaching_studio_id,
            };
            researchApi.get_course_my_3_my_group_gambit_review(params).then(res => {
              this.$message.success("操作成功");
              this.initPageData();
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
              teaching_studio_id: item.teaching_studio_id,
            };
            researchApi.get_course_my_3_my_group_gambit_review(params).then(res => {
              this.$message.success("操作成功");
              this.initPageData();
            })
            break;
          default:
            break;
        }
      } else {
        let params = {};
        switch (Number(num)) {
          case 2:
            params = {
              id: item.id,
              audit_status: 4,
              reason: value,
              is_personid: item.is_personid,
              teaching_studio_id: item.teaching_studio_id,
            };
            researchApi.get_course_my_4_my_group_gambit_review(params).then(res => {
              this.$message.success("操作成功");
              this.initPageData();
            })
            break;
          case 3:
            params = {
              id: item.id,
              audit_status: 5,
              reason: value,
              is_personid: item.is_personid,
              teaching_studio_id: item.teaching_studio_id,
            };
            researchApi.get_course_my_4_my_group_gambit_review(params).then(res => {
              this.$message.success("操作成功");
              this.initPageData();
            })
            break;
          default:
            break;
        }
      }
    },
    // 直播 操作 跳转
    topic_to_page(item, num) {
      switch (Number(num)) {
        case 2:
          this.topic_open_AffirmFn(item, 2);
          break;
        case 3:
          this.topic_open_AffirmFn(item, 3);
          break;
        default:
          break;
      }
    },
    //修改话题
    toEdit(item) {
      if (item.is_personid == 1) {
        this.$router.push({
          path: "/createMyTopic",
          query: {
            id: item.id,
          },
        });
      } else {
        localStorage.setItem("studioId", item.teaching_studio_id);
        this.$router.push({
          path: "/AddTopic",
          query: {
            id: item.id,
            type: 2,
          },
        });
      }
    },
    //下拉
    handleCommand(type) {
      this.currentNum = 1;
      this.currentPage2 = 1;
      this.audit_status = type;
      this.myCreate_2();
    },
    routeLink() {
      let query = this.$route.query;
      this.$router.replace({
        query: {
          ...query,
          pageType: this.currentNum,
        },
      });
    },
    //筛选
    showData(id) {
      if (id == 0) {
        this.currentNum = id;
        this.currentPage3 = 1;
        this.init_1();
      };
      if (id == 1) {
        return false;
      };
      if (id == 2) {
        this.currentNum = id;
        this.currentPage1 = 1;
        this.myJoin_3();
      };
      if (id == 3) {
        this.currentNum = 3;
        this.currentPage3 = 1;
        this.init_1(3);
      };
    },
    //跳转到话题详情页
    toDetailPage(item) {
      // 如果 teaching_studio_id ==0  则跳转教研组话题详情
      if (item.is_personid != 1) {
        localStorage.setItem("studioId", item.teaching_studio_id);
        this.$router.push(`/TopicDetail?tid=${item.id}`);
      } else {
        this.$router.push(`/topicDetails?id=${item.id}`);
      }
    },
    //初始化数据 全部话题
    async init_1(num) {
      this.listLoading_3 = true;
      try {
        let reqObj = {
          page: this.currentPage3,
          limit: this.pageSize3,
          user_id: this.user_id,
        };
        if (num == 3) {
          reqObj['my_type'] = 1;
        }
        let res = await this.$axios("/gambits/my", { params: reqObj, });
        this.getList3 = res.data.data.data;
        this.total3 = res.data.data.total;
        this.$nextTick(() => {
          this.listLoading_3 = false;
        });
      } catch (error) {
        this.getList3 = [];
        this.total3 = 0;
        this.$nextTick(() => {
          this.listLoading_3 = false;
        });
      }
    },
    //  我参与的
    async myJoin_3() {
      this.listLoading_1 = true;
      try {
        let res = await this.$axios("/gambits/my_partake", {
          params: {
            page: this.currentPage1,
            limit: this.pageSize1,
            user_id: this.user_id,
          },
        });
        this.getList1 = res.data.data.data;
        this.total1 = res.data.data.total;
        this.$nextTick(() => {
          this.listLoading_1 = false;
        });
      } catch (error) {
        this.getList1 = [];
        this.total1 = 0;
        this.$nextTick(() => {
          this.listLoading_1 = false;
        });
      }
    },
    // 我发起的
    async myCreate_2() {
      this.listLoading_2 = true;
      try {
        let res = await this.$axios("/gambit/my_found", {
          params: {
            page: this.currentPage2,
            limit: this.pageSize2,
            user_id: this.user_id,
            audit_status: this.audit_status,
          },
        });
        this.getList2 = res.data.data.data;
        this.total2 = res.data.data.total;
        this.$nextTick(() => {
          this.listLoading_2 = false;
        });
      } catch (error) {
        this.getList2 = [];
        this.total2 = 0;
        this.$nextTick(() => {
          this.listLoading_2 = false;
        });
      }
    },
    //删除一条信息
    deleteData(item) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.delete(`/gambit/${item.id}/${this.user_id}/${item.is_personid}`).then(() => {
          // 重新获取数据
          if (this.currentNum == 0) {
            this.init_1();
          } else if (this.currentNum == 1) {
            this.myCreate_2();
          } else {
            this.myJoin_3();
          }
          this.$message.success("删除成功");
        });
      }).catch(() => { });
    },
    handleSizeChange3(val) {
      this.currentPage3 = 1;
      this.pageSize3 = val;
      this.init_1();
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val;
      this.init_1();
    },
    handleSizeChange1(val) {
      this.currentPage1 = 1;
      this.pageSize1 = val;
      this.myJoin_3();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.myJoin_3();
    },
    handleSizeChange2(val) {
      this.currentPage2 = 1;
      this.pageSize2 = val;
      this.myCreate_2();
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.myCreate_2();
    },
  },
};
</script>

<style lang="less" scoped>
.myTopic_page {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}
.main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  .item_div {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 32px;
    height: 104px;
    &:hover {
      background-color: #f3faff;
      .titles {
        cursor: pointer;
        p {
          color: #3489ff !important;
        }
      }
    }
  }
  .content_div {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    border-bottom: 1px dashed #dae7ed;
    .content_img {
      width: 56px;
      height: 56px;
      margin-right: 16px;
      > img {
        width: 56px;
        height: 56px;
      }
    }
    .content_left {
      flex: 1;
      .titles {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        p {
          max-width: 500px;
          font-size: 16px;
          line-height: 22px;
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-weight: bold;
          text-align: left;
          color: #111a38;
          margin-right: 19px;
        }
        .sBox {
          height: 22px;
          line-height: 22px;
          background: #3489ff;
          border-radius: 4px;
          padding: 0 7px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          margin-right: 10px;
        }
      }
      > span {
        font-size: 16px;
        color: #666;
      }
    }
    .name_xiala_tag {
      width: 50px;
      text-align: right;
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content_right {
      display: flex;
      align-items: center;
      .item_op {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 16px;
        color: #3489ff;
        cursor: pointer;
        margin-left: 32px;
        &.active {
          cursor: default;
        }
        .item_img_div {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          > img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>