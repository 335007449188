export default {
  props: {
    // 教研组选中
    group_id: {
      type: [String, Number],
      default: ''
    },
    //指定成员表格弹窗
    value: {
      type: Boolean,
      default: false
    },
    //指定成员选中值
    tearmCheckedList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogShow: false, //是否显示弹窗
      provinceOption: [], //省选项
      cityOption: [], //市选项
      areaOption: [], //区选项
      sectionOption: [], //学段选项
      courseOption: [], //学科选项
      teachingGroupOption: [], //教研组选项
      tearmTableChecked: [], //指定成员选中值
      //成员参数
      tearm: {
        page: 1,
        limit: 10,
        total: 0,

        stage_id: "",
        subject_id: "",
        province_id: "",
        city_id: "",
        county_id: "",
        realname: "",
        group_id: "",
        is_org: "",
        user_ids: ""
      },
      isSelect: false, //查看已选成员是否选中
      tearmList: [] //成员列表
    };
  },
  created() {
    this.tearmTableChecked = this.tearmCheckedList.length > 0 ? this.tearmCheckedList.length : []
    if (this.group_id) {
      this.$set(this.tearm, "group_id", this.group_id);
    }
    this.getTearm(this.tearm) //成员接口
    this.getSection() //获取学段
    this.getProvince() //获取省
    this.getTeachingGroup() //获取教研组
  },
  mounted() { },
  methods: {
    //初始化获取指定成员
    async getTearm(params) {
      const res = await this.$axios.get("/get_appoint_user", {
        params: params
      })
      let list = res.data.data.data
      list.forEach((item) => {
        let isSelect = false
        this.tearmTableChecked.forEach((id) => {
          if (item.id == id) {
            isSelect = true
          }
        });
        item.selectState = isSelect
      });
      this.tearmList = list
      this.tearm.total = res.data.data.total

      this.allSelectTable()
    },
    //获取学段
    async getSection() {
      const res = await this.$axios.get("/stage/stage_list");
      this.sectionOption = res.data.data;
    },
    //获取学科
    async getCourse(pid) {
      const res = await this.$axios.get("/subject/list", {
        params: {
          pharseid: pid
        }
      });
      let data = res.data.data
      data.forEach((item) => {
        item.id = item.subjectId
        item.name = item.subjectName
      });
      this.courseOption = data;
    },
    //获取省列表
    async getProvince() {
      const res = await this.$axios.get("/province")
      this.provinceOption = res.data.data
    },
    //获取市列表
    async getCity(id) {
      const res = await this.$axios.get("/city", {
        params: {
          pid: id
        }
      })
      this.cityOption = res.data.data
    },
    //获取区列表
    async getArea(id) {
      const res = await this.$axios.get("/county", {
        params: {
          pid: id
        }
      })
      this.areaOption = res.data.data
    },
    //获取教研组
    async getTeachingGroup() {
      const res = await this.$axios.get("/TeacherGroup/get_my_list", {
        params: {
          my_type: 1,
          audit_status: ''
        }
      })
      this.teachingGroupOption = res.data.data.data
    },
    //省更改的回调
    provinceChange(id) {
      this.getCity(id)
    },
    //市更改的回调
    cityChange(id) {
      this.getArea(id)
    },
    //学段更改的回调
    sectionChange(id) {
      this.getCourse(id)
    },
    //表格当前页码改变
    currentChange(val) {
      this.tearm.page = val;
      this.getTearm(this.tearm) //成员接口
    },
    //操作表格全选
    allSelectTable() {
      let allSelectState = true
      this.tearmList.forEach((item) => {
        if (!item.selectState) {
          allSelectState = false
        }
      });
      // if (allSelectState) {
      //   this.$refs.tearmTable.toggleAllSelection();
      // }
    },
    //指定人员全部选择回调
    tearmChoiceAll(list) {
      if (list.length > 0) {
        this.tearmList.forEach((item) => {
          item.selectState = true
          this.tearmChoice(item, false)
        });
      } else {
        this.tearmList.forEach((item) => {
          item.selectState = false
          this.tearmChoice(item, false)
        });
      }
    },
    //指定人员选择回调
    tearmChoice(e, type = true) {
      let isPush = true
      this.tearmTableChecked.forEach((id) => {
        if (id == e.id) {
          isPush = false
        }
      });
      if (isPush) {
        if (e.selectState) {
          this.tearmTableChecked.push(e.id)

          /**操作表格全选按钮 开始*/
          if (type) {
            this.allSelectTable()
          }
          /**操作表格全选按钮 结束*/
        }
      } else {
        if (!e.selectState) {
          this.tearmTableChecked.splice(this.tearmTableChecked.indexOf(e.id), 1)
          /**操作表格全选按钮 开始*/
          if (type) {
            this.$refs.tearmTable.clearSelection();
          }
          /**操作表格全选按钮 结束*/
        }
      }
    },
    //指定人员筛选重置按钮
    resetButton() {
      this.tearm.stage_id = ""
      this.tearm.subject_id = ""
      this.tearm.province_id = ""
      this.tearm.city_id = ""
      this.tearm.county_id = ""
      this.tearm.realname = ""
      this.tearm.group_id = ""
      this.tearm.page = 1
      this.getTearm(this.tearm) //成员接口
    },
    //指定人员筛选查询按钮
    queryButton() {
      this.tearm.page = 1
      this.getTearm(this.tearm) //成员接口
    },
    //指定人员筛选取消按钮
    cancelButton() {
      this.$emit('input', false)
    },
    //弹窗关闭的回调
    dialogHandleClose() {
      this.$emit('input', false)
    },
    //指定人员筛选确定按钮
    defineButton() {
      this.$emit('input', false)
      this.$emit('tearmTableChecked', this.tearmTableChecked)
    }
  },
  watch: {
    //监听是否显示弹窗
    value(val) {
      this.dialogShow = val
    },
    //监听本校成员
    "tearm.is_org"() {
      this.tearm.page = 1
      this.getTearm(this.tearm) //成员接口
    },
    //监听查看已选成员
    isSelect(val) {
      if (val) {
        this.tearm.user_ids = this.tearmTableChecked.length > 0 ? this.tearmTableChecked.join(",") : ""
      } else {
        this.tearm.user_ids = ""
      }
      this.tearm.page = 1
      this.getTearm(this.tearm) //成员接口
    },
    //监听传入指定成员选中值
    tearmCheckedList(list) {
      this.tearmTableChecked = list
      this.tearmList.forEach((item) => {
        let isSelect = false
        this.tearmTableChecked.forEach((id) => {
          if (item.id == id) {
            isSelect = true
          }
        });
        item.selectState = isSelect
      });
    }
  }
};