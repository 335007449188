<template>
  <div class="conference">
    <!-- 教研 -->
    <div class="wz">
      <h4>我的会议</h4>
      <router-link to="/sendConference"><span>+发起会议</span></router-link>
    </div>
    <div class="btn">
      <el-button round @click="getAll">全部</el-button>
      <el-button type="primary" @click="getMyJoin" round>我参加的</el-button>
      <el-button type="primary" @click="getMyCreate" round>我发起的</el-button>
    </div>
    <!-- 内容-->
    <div class="main">
      <!-- 主体 -->
      <div class="content" v-for="(item, index) in List" :key=index>
        <!-- 左边 -->
        <div class="content_left">
          <h4>#{{ item.title }}</h4>
          <p>
            <span>
              {{ item.start_date }} {{ item.start_time }}
            </span>
            -
            <span>
              {{ item.end_date }} {{ item.end_time }}
            </span>
          </p>
          <p>发起人:&nbsp;&nbsp;{{ item.user_name }}</p>
          <p>参与人:&nbsp;&nbsp;{{ item.join_user }}</p>
        </div>
        <!-- 右边 -->
        <div class="content_right">

          <router-link to="/detailConference">
            <el-button type="primary" style="margin-bottom:15px">详情</el-button>
          </router-link> <br>
          <el-button type="info" plain @click="deleteData(item.id)">删除</el-button>

        </div>
      </div>
      <!-- end -->
    </div>

    <!-- 分页 -->
    <div class="block">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      List: "",
      form: {
        limit: "",
        page: "",
        type: 0,
        user_num: "",
      },
      currentPage4: 0
    }
  },
  created() {
    this.init()
  },

  methods: {
    // 初始化数据
    async init() {
      let res = await this.$axios.get("/meetings", this.form)
      console.log(res.data.data);
      this.List = res.data.data
    },
    // 删除
    deleteData(id) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let res = this.$axios.delete(`meeting/${id}`);
        this.$message.success("删除成功")
        // 重新遍历
        this.init()
      }).catch(() => {
        alert("删除失败")
      });
    },
    // 全部
    getAll() {
      this.form.type = 0
      this.init();
    },
    // 我参加
    getMyJoin() {
      this.form.type = 1
      this.init();
    },
    // 我发起
    getMyCreate() {
      this.form.type = 2
      this.init();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  },
}
</script>

<style lang="less" scoped>
.conference {
  .wz {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: bold;
      color: #3489FE;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .btn {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  //主要内容部分
  .main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .content {
      margin-right: 25px;
      border-bottom: 1px solid #EBEBEB;
      width: 100%;
      height: 141px;
      margin-bottom: 20px;
      border-radius: 6px;
      padding: 20px 20px;
      display: flex;
      justify-content: space-between;

      // 左边
      .content_left {
        h4 {
          margin-bottom: 10px;
        }

        p {
          margin-top: 15px;
          font-size: 16px;
          color: #999999;
        }
      }

      //右边
      .content_right {
        padding: 20px 30px
      }
    }
  }
}
</style>