<template>
   <!-- //直播推流详情 -->
  <div class="GuideDetail">
        <div class="OneBox">   
                <!-- 面包屑 -->
                <div class="bread_content">
                    <span class="span_one">当前位置：</span>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>研修中心</el-breadcrumb-item>
                        <el-breadcrumb-item>教研直播</el-breadcrumb-item>
                        <el-breadcrumb-item>直播详情</el-breadcrumb-item>
                        <el-breadcrumb-item>
                        <span style="color:#ff8201">直播指引</span>
                    </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <!-- 提示内容 -->
                <div class="tipBox">
                    <!-- //按钮 -->
                    <div class="BtnTop">
                          <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="下载指引" name="1">
                                  <!-- //下载指引组件 -->
                                    <downGuide
                                        @is_Use="is_Usee"
                                    />
                            </el-tab-pane>

                            <el-tab-pane label="使用指引" name="2">
                                 <!-- 使用指引 -->
                                    <useGuide/>
                            </el-tab-pane>
                         
                        </el-tabs>
                      
                    </div>
                 
                </div>
                


        </div>
      
  </div>
</template>

<script>
export default {
    created(){
          //从路由获取类型
          this.activeName=this.$route.query.id;
    },
   data(){
        return{
            //控制显示下载还是使用组件
            activeName:"1"
        }
   },
   methods:{
       is_Usee(val){
           this.activeName=val;
       },
        handleClick(tab, event) {
      }
   }
}
</script>

<style lang="less" scoped>
  .GuideDetail{
      width: 100%;
      background: #F7F7F7;
      .OneBox{
            width: 80%;
            margin: 0 auto;
            .bread_content{
                display: flex;
                align-items: center;
                height: 70px;
            }
            //提示盒子
            .tipBox{
                background: #ffffff;
                // height: 800px;
                padding: 20px 20px;
                //按钮
                .BtnTop{
                   /deep/ .el-tabs__item {
                       font-size: 18px;
                       font-weight: bold;
                   }
                }
               
            }
      }
  }
</style>