<template>
  <div class="problem-subject">
    <div class="subject-left">
      <div class="public-box">
        <h3 class="box-title">添加题目</h3>
        <ul class="box-list">
          <li v-for="(item, index) in subjectTypeOption" :key="index" @click="addSubject(item)">
            + {{ item.name }}
          </li>
        </ul>
      </div>
      <!--选择题弹窗 开始-->
      <ChoiceSubject v-model="choiceSubjectShow" :subjectData="subjectData" @onSubject="onSubject"></ChoiceSubject>
      <!--选择题弹窗 结束-->
      <!--填空题弹窗 开始-->
      <WriteSubject v-model="writeSubjectShow" :subjectData="subjectData" @onSubject="onSubject"></WriteSubject>
      <!--填空题弹窗 结束-->
      <!--排序题弹窗 开始-->
      <SortSubject v-model="sortSubjectShow" :subjectData="subjectData" @onSubject="onSubject"></SortSubject>
      <!--排序题弹窗 结束-->
      <!--量表题弹窗 开始-->
      <GaugeSubject v-model="gaugeSubjectShow" :subjectData="subjectData" @onSubject="onSubject"></GaugeSubject>
      <!--量表题弹窗 结束-->
    </div>
    <div class="subject-right">
      <div class="subject-box">
        <div class="box-top">
          <h2 class="top-title">
            {{ subject.title }}
          </h2>
          <div class="top-describe rich_text_div_img" v-html="subject.details"></div>
          <el-button type="primary" size="mini" class="top-edit" @click="editInfoButton()">
            编辑
          </el-button>
          <!--编辑基本信息弹窗 开始-->
          <EditInfo v-model="editInfoShow"></EditInfo>
          <!--编辑基本信息弹窗 结束-->
        </div>
        <div class="box-bottom">
          <SubjectList :subject="subject" v-if="subject.topics.length > 0">
            <template v-slot:operate="data">
              <div class="list-operate">
                <el-button type="primary" size="mini" @click="editOptionButton(data.data.item, data.data.index)">编辑</el-button>
                <el-button type="primary" size="mini" @click="copyOptionButton(data.data.item)">
                  复制
                </el-button>
                <el-button type="primary" size="mini" @click="delOptionButton(data.data.index)">
                  删除
                </el-button>
                <el-button type="primary" size="mini" @click="upOptionButton(data.data.item, data.data.index)">
                  上移
                </el-button>
                <el-button type="primary" size="mini" @click="downOptionButton(data.data.item, data.data.index)">
                  下移
                </el-button>
              </div>
            </template>
          </SubjectList>
          <p class="list-tips" v-else>暂无题目，请添加。</p>
          <el-collapse class="collapse-box">
            <el-collapse-item title="提交问卷后处理方式">
              <p class="collapse-tips">提交答卷后，显示一段您自定义的文案。</p>
              <Editor v-model="subject.submit_doc"></Editor>
            </el-collapse-item>
          </el-collapse>
          <div class="operate-button">
            <el-button type="success" @click="previewButton()">预览</el-button>
            <el-button type="primary" @click="submitButton()">提交</el-button>
            <el-button type="info" @click="cancelButton()">取消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subject from "./subject.js";
export default Subject;
</script>

<style lang="less" scoped>
@import "./subject.less";
</style>