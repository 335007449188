<template>
  <div class="TeachIndex">
    <div class="mainBox">
      <!-- //左边导航 -->
      <div class="LeftNav" v-if="istrue">
        <Nav v-if="istrue"></Nav>
      </div>
      <!-- 右边内容模块 -->
      <div class="rightContent">
        <template v-if="istrue">
          <NoticeCom></NoticeCom>
          <Course></Course>
          <Activity></Activity>
          <ResAndTxt></ResAndTxt>
          <HonorIndex></HonorIndex>
          <HonorRotor></HonorRotor>
        </template>

        <div v-if="unserInfoData" class="videoLink" @click="handleVideo">
          <div class="img_div">
            <img src="@/assets/imgFIle_3/shipin_icon.png" alt="" />
          </div>
          <span>操作手册</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SecretLogin } from "@/api/teacherStudio";
import { localGet, localSet, localRemove } from "@/utils/utils.js";
import Nav from "@/views/MasterStudio/TeachIndex/components/navMenu.vue";
import NoticeCom from "@/views/MasterStudio/TeachIndex/components/noticeAndInfo.vue";
import Course from "@/views/MasterStudio/TeachIndex/components/Lecture.vue";
import Activity from "@/views/MasterStudio/TeachIndex/components/Activity.vue";
//资源和文章
import ResAndTxt from "@/views/MasterStudio/TeachIndex/components/ResAndTxt.vue";
//荣誉证书
import HonorIndex from "@/views/MasterStudio/TeachIndex/components/HonorIndex.vue";
import HonorRotor from "@/views/MasterStudio/TeachIndex/components/HonorRotor.vue";

export default {
  data() {
    return {
      istrue: false,
      unserInfoData: false,
    };
  },
  components: {
    Nav,
    NoticeCom,
    Course,
    Activity,
    ResAndTxt,
    HonorIndex,
    HonorRotor
  },
  created() {
    if (this.$route.query.key) {
      this.istrue = false;
      SecretLogin({ key: this.$route.query.key }).then((res) => {
        const dataObj = res.data;
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj));
        //把瓣学网登录的权限存在缓存里
        localStorage.setItem("aes_code", dataObj.aes_code);

        //将用户所有信息存进缓存
        localStorage.setItem("user_name", dataObj.username);

        //把token存在缓存里
        localStorage.setItem("token", dataObj.token);

        //把用户id存进缓存里
        localStorage.setItem("id", dataObj.id);

        //把用户id存进缓存里
        localStorage.setItem("studioId", this.$route.query.id);

        this.isShow = 1;
        //把登录显示放进缓存里
        localStorage.setItem("isShow", this.isShow);
        //把用户头像放进缓存
        localStorage.setItem("photo_url", dataObj.photo_url);
        setTimeout(() => {
          this.$router.push({
            path: `/TeachLectureIndex?module=${this.$route.query.module}&id=${this.$route.query.id}`,
          });
          location.reload();
        }, 500);
      });
    } else {
      this.istrue = true;
    }

    let unserInfoData = localGet("unserInfoData");
    if (unserInfoData && unserInfoData.id && unserInfoData.token) {
      this.unserInfoData = true;
    } else {
      this.unserInfoData = false;
    }
  },
  methods: {
    // 去视频播放页
    handleVideo() {
      let routeData = this.$router.resolve({
        path: "/videoPlayPage",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.TeachIndex {
  width: 100%;
  // background: #f7f7f7;
  // background-color: red;
  padding: 20px 0;
  padding-bottom: 30px;
  .mainBox {
    width: 1400px;
    display: flex;
    margin: 0 auto;
    .LeftNav {
    }
    .rightContent {
      margin-left: 20px;
      position: relative;
    }
    .videoLink {
      position: fixed;
      right: 172px;
      bottom: 24px;
      width: 72px;
      height: 64px;
      background: rgba(52, 137, 254, 1);
      border-radius: 4px;
      box-shadow: 0 4px 12px 0 rgba(52, 152, 254, 0.32);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      .img_div {
        width: 20px;
        height: 20px;
      }
      > span {
        margin-top: 5px;
      }
    }
  }
}
</style>