<template>
  <div class="ResAndTxt">
    <div class="rTop">
      <!-- 左边研修资源 -->
      <div class="Res">
        <div class="titleClass">
          <span>研修资源</span>
          <span class="cursor" @click="toMoreRes">更多>></span>
        </div>
        <ul class="uu1" v-if="listData.length > 0">
          <li v-for="(item, index) in listData" :key="index" class="df" @click="onGoDetail(item.id, item.resource_id)">
            <img :src="onShowImages2(item.ext)" alt="" />
            <div>
              <!-- 上 -->
              <div class="fc pb3">
                <p class="recommander" v-if="item.is_recommend == 2">四名推荐</p>
                <!-- <new-viewonline :viewSrc="item.view_url"
                  :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
                  :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item"
                  :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time" fileNav="工作室 > 研修资源 > 查看"
                  :is_show_head="1" :is_hidden_nav="2" :teaching_studio_id="form.teaching_studio_id">
                </new-viewonline> -->
                  <p class="ellipsis cursor" :style="{ width: item.is_recommend == 2 ? '386px' : '420px' }">
                    {{ item.title }}
                  </p>
              </div>
              <!-- 下 -->
              <div class="fc down">
                <!-- class="df l"  -->
                <div class='gBox' :title="item.chapter_name">
                  {{ item.stage_name }} &nbsp; {{ item.subject_name }}&nbsp;
                  {{ item.edition_name }} &nbsp;
                  {{ item.grade_name }} &nbsp;
                  &nbsp; {{ item.chapter_name }}
                  <!-- class="lsl " -->
                  <!-- <span  :title="item.chapter_name">
                  </span> -->
                </div>
                <div class="fc r">
                  <img src="@/assets/images/preview.png" alt="" class="mr5" />
                  <span class="" style="width:50px">{{ item.view_count }}</span>
                  <img src="@/assets/images/shoucang.png" alt="" class="mr5" />
                  <span>{{ item.collect_count }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <CommonEmpty v-else />
      </div>

      <!-- 教师文章 -->
      <div class="TeachTxt">
        <div class="titleClass">
          <span>教师文章</span>
          <span class="cursor" @click="toMoreArticle">更多>></span>
        </div>
        <ul class="uu2" v-if="articleList.length > 0">
          <li v-for="(item, index) in articleList" :key="index" class="cursor" @click="toArticleDetail(item.id)">
            <div class="fc pb10 tBox">
              <div class="One">{{ item.type_name }}</div>
              <div class="recommander" v-if="item.status_name == '已推荐'">四名推荐</div>
              <p class="ellipsis" :style="{ width: item.status_name == '已推荐' ? '310px' : '390px', lineHeight: 1 }">
                {{ item.name }}
              </p>
            </div>
            <div class="fc photoInfo">
              <div class="onerow">
                <span class="mr27 ellipsis">{{ item.username }}</span>
                <span class="mr10">{{ item.start_date }}</span>
              </div>

              <!-- 浏览 -->
              <img src="@/assets/images/preview.png" alt="" class="mr5" />
              <span style="width:60px;" class="ellipsis">{{ item.view_count }}</span>
              <!-- 评论数 -->
                <img src="@/assets/images/pinglun.png" alt="" class="mr5" />
                <span style="width:60px;" class="ellipsis">{{ item.reply_count }}</span>
            </div>
          </li>
        </ul>
        <CommonEmpty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { onShowImages2 } from "@/utils/utils";
export default {
  data() {
    return {
      listData: [],
      articleList: [],
      form: {
        search_title: "",
        search_is_recommend: "",
        search_type: "",
        teaching_studio_id: localStorage.getItem("studioId"),
        limit: 4,
        page: 1
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getResList();
      this.getArtiCleList();
    },
    // 详情
    onGoDetail(id, resource_id) {
      // return
      this.$router.push({
        path: "/resourcedetail",
        query: { id, resource_id },
      });
    },
    //跳转文章详情
    toArticleDetail(id) {
      this.$router.push({
        path: "/ArticleDetail",
        query: {
          tid: id,
        },
      });
    },
    onShowImages2,
    //资源列表
    getResList() {
      this.$axios
        .get("TeachingStudioResource/listResource", {
          params: this.form,
        })
        .then((res) => {
          this.listData = res.data.data.data;
        });
    },
    //教师文章
    getArtiCleList() {
      this.$axios
        .get("TeachingStudioArticleType/IndexlistTeachingStudioArtic", {
          params: { teaching_studio_id: localStorage.getItem("studioId"), limit: 4, page: 1 },
        })
        .then((res) => {
          this.articleList = res.data.data.data;
          this.articleList.map(e => {
            e.start_date = e.start_date.split(' ')[0]
          })
        });
    },

    //跳转更多资源
    toMoreRes() {
      this.$router.push("/TrainResIndex");
    },
    toMoreArticle() {
      this.$router.push("/ArticleIndex");
    }
  },
};
</script>

<style lang="less" scoped>
.ResAndTxt {
  width: 1106px;
  box-sizing: border-box;
  padding-top: 20px;
  min-height: 243px;

  .rTop {
    display: flex;
    justify-content: space-between;

    //左边研修资源
    .Res {
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      padding-right: 24px;
      width: 540px;
      border-radius: 8px;

      .titleClass {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:nth-of-type(1) {
          font-size: 16px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }

        span:nth-of-type(2) {
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
      }

      .uu1 {
        li {
          margin-top: 12px;
          padding: 13px 26px 13px 10px;
          box-sizing: border-box;
          background: #EEF6FF;
          border-radius: 8px;

          .gBox {
            width: 300px;
            display: flex;
            align-items: center;
            display: -webkit-box;
            display: -moz-box;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            /*显示行数*/
          }

          &:hover {
            background: #c8dffa;
          }

          >img {
            width: 36px;
            height: 40px;
            margin-right: 13px;
          }

          .down {
            color: #999;
            font-size: 14px;

            .l {

              //   margin-right: 60px;
              // width: 334px;
              // span {
              //   // margin-right: 15px;
              //   min-width: 40px;
              // }
              .lsl {
                max-width: 160px;
                margin-left: 4px;
              }
            }

            .r {
              margin-left: 15px;

              img {
                vertical-align: text-top;
              }
            }
          }
        }
      }
    }

    //教师文章
    .TeachTxt {
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      padding-right: 24px;
      width: 540px;
      border-radius: 8px;

      .titleClass {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:nth-of-type(1) {
          font-size: 16px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }

        span:nth-of-type(2) {
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
      }

      .uu2 {
        li {
          margin-top: 12px;
          padding: 10px 26px 10px 10px;
          box-sizing: border-box;
          background: #EEF6FF;
          border-radius: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          //顶头样式
          .tBox {
            p {
              width: 315px;
              height: 16px;
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }

            .One {
              width: 68px;
              height: 20px;
              overflow: hidden;
              background: #3489fe;
              border-radius: 2px;
              font-size: 14px;
              color: #fff;
              text-align: center;
              line-height: 20px;
              margin-right: 7px;
            }
          }

          //底部样式
          .photoInfo {
            font-size: 14px;
            color: #999;

            .onerow {
              flex: 1;
              overflow: hidden;
              display: flex;
              span {
                &:nth-of-type(2) {
                  white-space: nowrap;
                }
              }
            }

            img {
              vertical-align: text-top;
            }
          }
        }
      }
    }

    .recommander {
      width: 66px;
      height: 20px;
      line-height: 18px;
      text-align: center;
      border: 1px solid #ff8201;
      border-radius: 2px;
      color: #ff8201;
      font-size: 14px;
      margin-right: 11px;
      box-sizing: border-box;
    }
  }
}
</style>