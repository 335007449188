<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth2">
      <div class="public-width">
        <div class="public-width">
          <div class="mainBox2 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aserviceImg/chuji.png" alt="">
            </div>
            <div class="fuwuBox mt50">
              <div class="fuLeft">
                <h4 class="mb20">服务内容：</h4>
                <div class="fuwuul">
                  <div class="fuwuli">
                    作品内容形式审核服务
                  </div>
                  <div class="fuwuli">
                    作品内容编辑校对服务
                  </div>
                  <div class="fuwuli">
                    作品内容排版服务
                  </div>
                  <div class="fuwuli">
                    作品封面设计服务
                  </div>
                  <div class="fuwuli">
                    出版全流程管理服务
                  </div>
                  <div class="fuwuli">
                    100套图书塑封精装样书服务
                  </div>
                  <div class="fuwuli">
                    按需发行宣传服务
                  </div>
                </div>
                <div class="bomTxt">
                  服务宗旨：向四名学习、为四名服务、与四名共成长
                </div>
              </div>
              <div class="rightImg">
                <img src="@/assets/banner_img/aservice1/img4.png" alt="">
              </div>
            </div>
            <div class="fuwuBox2 mt30">
              <h4 class="mb30">服务优势：</h4>
              <div class="fuwuul2">
                <div class="fuwuli2">
                  <h5>专业性高</h5>
                  <p>已与国内知名出版社建立长期合作关系。拥有一支包括编审等高级人才在内的稳定的编辑队伍，
                    建立了高效的内容组织开发体系、规范的编辑加工体系和有效的版权保护体系，
                    对内容特别是专业性较强的教育内容进行严格规范。
                  </p>
                </div>
                <div class="fuwuli2">
                  <h5>周期保障</h5>
                  <p>建构畅通的沟通渠道，安排编辑团队提前介入，充分沟通后，
                    提前进行书稿的编辑加工工作，以求“书稿”顺利达到国家
                    出版要求。
                  </p>
                </div>
                <div class="fuwuli2">
                  <h5>服务周到</h5>
                  <p>提供从编辑、排版、印刷、发行到宣传等全方位的出版服务，为作者提供一站式的出版解决方案。
                  </p>
                </div>
                <div class="fuwuli2">
                  <h5>成果推广</h5>
                  <p>拥有专业的市场营销团队，能够为作者提供针对性的宣传和推广策略，
                    提高书籍的知名度和销售量。可结合书籍实际开展主题培训宣传，课程开发等。
                  </p>
                </div>
              </div>
            </div>
            <div class="fuwuBox2 mt30 pb30">
              <h4 class="mb30">出版流程：</h4>
              <p class="fupsty"> 图书出版周期一般为3-4个月（书稿资料齐全、自向出版社提交选题申报表之日起开始计算），
                为提高出版社审稿通过率，缩短出版周期，编写团队需在稿件的质量上下功夫，确保图书内容质量过关。
                出版流程如下：
              </p>
              <div class="fuimgsty mt10">
                <img src="@/assets/banner_img/aservice1/img5.png" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}


.mainBox2 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .fuwuBox {
    display: flex;

    .fuLeft {
      h4 {
        font-size: 24px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        font-family: Microsoft YaHei;
      }

      .fuwuul {
        display: flex;
        flex-wrap: wrap;

        .fuwuli {
          width: 305px;
          height: 56px;
          background: rgba(20, 91, 185, 0.1);
          margin-right: 20px;
          font-size: 20px;
          color: rgba(51, 51, 51, 1);
          text-align: center;
          line-height: 56px;
          margin-bottom: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:nth-child(2) {
            background: #fde8f0;
          }

          &:nth-child(5) {
            background: #fde8f0;
          }

          &:nth-child(7) {
            background: #fde8f0;
          }

          &:nth-child(4) {
            background: #fde8f0;
          }
        }
      }

      .bomTxt {
        width: 701px;
        height: 154px;
        background: rgba(234, 30, 112, 1);
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 154px;
        text-align: center;
      }
    }

    .rightImg {
      img {
        width: 580px;
        height: 509px;
      }
    }


  }

  .fuwuBox2 {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      font-family: Microsoft YaHei;
    }

    .fuwuul2 {
      display: flex;
      flex-wrap: wrap;

      .fuwuli2 {
        width: 624px;
        height: 300px;
        background: rgba(20, 91, 185, 0.1);
        margin-right: 32px;
        font-size: 20px;
        color: rgba(51, 51, 51, 1);
        line-height: 56px;
        padding: 30px 100px 30px 40px;
        box-sizing: border-box;
        margin-bottom: 32px;

        h5 {
          font-size: 36px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }

        p {
          margin-top: 20px;
          width: 433px;
          height: 152px;
          font-size: 16px;
          color: #fff;
          line-height: 40px;
        }

        &:nth-child(1) {
          background: url('~@/assets/banner_img/aserviceImg/fuwu1.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(2) {
          background: url('~@/assets/banner_img/aserviceImg/fuwu3.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(3) {
          background: url('~@/assets/banner_img/aserviceImg/fuwu2.png') no-repeat;
          background-size: 100% 100%;
        }

        &:nth-child(4) {
          background: #ea1e70;
        }


        &:nth-child(2n) {
          margin-right: 0;
        }
      }

    }

    .fupsty {
      font-size: 20px;
      font-family: Microsoft YaHei;
      color: rgba(51, 51, 51, 1);
      text-indent: 2em;
    }

    .fuimgsty {
      display: flex;
      justify-content: center;
    }
  }
}
</style>