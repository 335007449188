<template>
  <div class="researchactivity">
    <div class="w_d center" style="margin: 0 auto">
      <div class="bread" style="height:49px;border-bottom:1px solid #f0f0f0;display:flex;align-items:center;margin-bottom:30px">
        <div class="bread_content" style="margin:0 !important;">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">教研活动</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="bread_hengxian"></div> -->
      </div>
      <div class="type_xueduan2">
        <p>类型：</p>
        <div class="xueduan2_one">
          <div :class="[
              selectedType == '0' ? 'active_block' : '',
              'xueduan_block',
              'cursor',
            ]" @click="handleType('0')">
            全部
          </div>
          <div :class="[
              selectedType == item.id ? 'active_block' : '',
              'xueduan_block',
              'cursor',
            ]" v-for="(item, index) in typeData" :key="index" @click="handleType(item.id)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="select">
        <div class="select_left">
          <div class="xueduan">
            <p>学段：</p>
            <div :class="[
                pharseid == '0' ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" @click="handleStage('0')">
              全部
            </div>
            <div :class="[
                pharseid == item.id ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" v-for="(item, index) in stage" :key="index" @click="handleStage(item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan2">
            <p>学科：</p>
            <div class="xueduan2_one">
              <div :class="[
                  search_subject_id == '0' ? 'active_block' : '',
                  'xueduan_block',
                  'cursor',
                ]" @click="handleSubject('0')">
                全部
              </div>
              <div :class="[
                  search_subject_id == item.subjectId ? 'active_block' : '',
                  'xueduan_block',
                  'cursor',
                ]" v-for="(item, index) in subjectData" :key="index" @click="handleSubject(item.subjectId)">
                {{ item.subjectName }}
              </div>
            </div>
          </div>
          <div class="xueduan">
            <p>状态：</p>
            <div :class="[
                search_status == '0' ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" @click="handlestatus('0')">
              全部
            </div>
            <div :class="[
                search_status == item.id ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" v-for="(item, index) in statusData" :key="index" @click="handlestatus(item.id)">
              {{ item.statusName }}
            </div>
          </div>
          <div class="sousuo">
            <p>查找：</p>
            <el-input placeholder="请输入搜索关键字" prefix-icon="el-icon-search" class="sousuo_one" v-model="search_title"></el-input>
            <el-button type="primary" @click="screensousuo">搜索</el-button>
          </div>
        </div>
        <div class="select_right">
          <!-- <div class="create cursor" @click="go">+ 创建活动</div> -->
          <!-- <div class="create cursor" >+ 创建直播</div> -->
          <div class="create"></div>
          <div class="sousuo_one">
            <el-input placeholder="通过访问码查找" prefix-icon="el-icon-search" v-model="search_visit_code"></el-input>
            <el-button type="primary" @click="rightsousuo">确定</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 循环内容部分 -->
    <!-- shadow="hover" -->

    <div class="CenterPage">
      <div v-if="activityData && activityData.length != 0">
        <div class="tBoxs">
          <!-- :body-style="{ padding: '0px' }" -->
          <div class="activity_block3" v-for="(item, index) in activityData" :key="index">
            <div @click="intoBasic(item.id)">
              <div class="img" :style="{ backgroundImage: 'url(' + item.cover_url + ')' }">
                <!-- 三角形  1进行中 2未开始 3已结束-->
                <div :class="
                    item.status == 1
                      ? 'triangle'
                      : item.status == 2
                      ? 'triangle1'
                      : 'triangle2'
                  "></div>
                <div class="wzBox">
                  <span v-if="item.status == 1">进行中</span>
                  <span v-if="item.status == 2">未开始</span>
                  <span v-if="item.status == 3" style="color: #999999">已结束</span>
                </div>
              </div>
              <div class="mBoxs">
                <p class="word1">
                  {{ item.name }}
                </p>
                <div class="word_other1">
                  <span v-if="item.type_name" class="sp1">{{
                    item.type_name
                  }}</span>
                  <span v-if="item.implement_type" class="sp2">{{
                    item.implement_type
                  }}</span>
                  <span class="sp3">{{ item.user_count }}+参与</span>
                </div>
                <!--时间 -->
                <div class="lastContent">
                  <span class="oneC">时间</span>
                  <span class="twoC">{{ item.start_date }}</span>
                  <span class="threeC">{{ item.start_time }}</span>
                </div>
                <div class="hx"></div>
                <!-- 最后一行 -->
                <div class="fc word2">
                  <p class="fc">
                    <span class="mr4">由</span>
                    <span v-if="item.initiator_type == '教师'" class="ellipsis studio_name">{{ item.initiator_name }} 老师发起</span>
                    <span v-else class="ellipsis studio_name">{{ item.initiator_name }} 教研组发起</span>
                  </p>
                  <!-- 1不可参与 2可参与 3已参与 -->
                  <p class="join" v-if="item.permiss_status == 2 && item.status != 3">可参与</p>
                  <p class="join unjoin" v-if="item.permiss_status == 3 && item.status != 2">
                    已参与
                  </p>
                  <!-- <div
                        class="hand hand2"
                        v-if="item.status == '3' && item.permiss_status == '2'"
                        style="margin-right:5px;"
                      >
                        已结束
                      </div>
                      <div
                        class="have_flex"
                        v-else-if="item.status == '3' && item.permiss_status == '3'"
                        style="margin-right:5px;"
                      >
                        <div class="hand hand2">已参与</div><div class="hand hand2">已结束</div>
                      </div>
                      <div
                        class="hand"
                        v-else-if="item.status == '1' && item.permiss_status == '2'"
                        style="margin-right:5px;"
                      >
                        可参与
                      </div>
                      <div
                        class="hand hand2"
                        v-else-if="item.status == '1' && item.permiss_status == '3'"
                        style="margin-right:5px;"
                      >
                        已参与
                      </div>
                      <div
                        class="have_flex"
                        v-else-if="item.status == '2' && item.permiss_status == '2'"
                        style="margin-right:5px;"
                      >
                        <div class="hand">可参与</div> <div class="hand hand2">未开始</div>
                      </div>
                      <div v-else></div>
                      <div class="hand" v-if="item.permiss_status == '2'">可参与</div>
                      <div class="hand hand3" v-else-if="item.permiss_status == '1'">
                        已参与
                      </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="pick"></div>
        </div>
        <div class="pagin">
          <el-pagination background :current-page="page" :page-size="limit" layout="prev, pager, next, jumper" :total="total"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
      <div v-else class="nodata">暂无数据.....</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //学段数据
      stage: [],
      //科目数据
      subjectData: [],
      // 选中的学段id
      pharseid: "0",
      // 选中的学科id
      search_subject_id: "0",
      // 状态数据
      statusData: [
        {
          id: "1",
          statusName: "进行中",
        },
        {
          id: "2",
          statusName: "未开始",
        },
        {
          id: "3",
          statusName: "已结束",
        },
      ],
      // 选中的状态
      search_status: "0",
      // 搜索标题数据
      search_title: "",
      // 搜索访问码
      search_visit_code: "",
      // 每页条数
      limit: 20,
      // 页数
      page: 1,
      // 总数据
      total: 0,
      // 直播总数据
      activityData: [],
      // 选中搜索活动类型
      // search_type: "0",
      // 活动类型数据
      typeData: [],
      // 选中的活动类型数据
      selectedType: "0",
    };
  },
  created() {
    this.init();
  },
  methods: {
    go() {
      this.$router.push("/createactivity");
    },
    // 初始化函数
    init() {
      this.getStateList();
      this.leftsousuo();
      this.getType();
    },
    // 请求后台获取学段信息
    getStateList() {
      this.$axios.get("stage/stage_list").then((res) => {
        var resdata = res.data.data;
        // console.log(resdata)
        this.stage = resdata;
      });
    },
    // 请求后台获取科目列表
    getSubjectList() {
      var obj = {
        // pharseid:'1'
        params: {
          pharseid: this.pharseid,
        },
      };
      this.$axios.get("subject/list", obj).then((res) => {
        // console.log(res.data)
        var resdata = res.data;
        if (resdata.code == "900") {
          this.subjectData = resdata.data;
        } else {
          this.$message({
            message: resdata.msg,
          });
        }
      });
    },
    // 点击切换所选学段函数
    handleStage(id) {
      this.pharseid = id;
      // this.getSubjectList();
      if (id != "0") {
        this.getSubjectList();
      }
      // this.leftsousuo();
      this.screensousuo();

      // this.search_subject_id = "0";
    },
    //点击切换所选类型
    handleType(id) {
      this.selectedType = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 点击切换所选学科函数
    handleSubject(id) {
      this.search_subject_id = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 点击切换所选状态函数
    handlestatus(id) {
      this.search_status = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 左边搜索函数
    leftsousuo() {
      var obj = {
        limit: this.limit,
        page: this.page,
        search_type: this.selectedType,
        search_stage: this.pharseid,
        search_subject: this.search_subject_id,
        search_status: this.search_status,
        search_name: this.search_title,
      };
      this.getActivity(obj);
    },
    // 右边访问码搜索函数
    rightsousuo() {
      var obj = {
        limit: "24",
        page: "1",
        search_type: "0",
        search_stage: "0",
        search_subject: "0",
        search_status: "0",
        search_name: this.search_visit_code,
      };
      this.getActivity(obj);
    },
    //筛选搜索函数
    screensousuo() {
      this.page = 1;
      var obj = {
        limit: this.limit,
        page: 1,
        search_type: this.selectedType,
        search_stage: this.pharseid,
        search_subject: this.search_subject_id,
        search_status: this.search_status,
        search_name: this.search_title,
      };
      this.getActivity(obj);
    },

    // 请求后台获取活动数据
    getActivity(obj) {
      this.$axios.get("activities", { params: obj }).then((res) => {
        var resdata = res.data;
        this.activityData = resdata.data.data;
        this.total = resdata.data.total;
      });
    },
    //   切换当前页码时触发
    handleCurrentChange(val) {
      this.page = val;
      this.leftsousuo();
    },
    // 跳转到活动详情页
    intoBasic(id) {
      this.$router.push({
        name: "basicinfo",
        query: {
          activityId: id,
          is_from: 1,
        },
      });
    },
    // 请求后台获取活动类型
    getType() {
      this.$axios.get("activity_type").then((res) => {
        this.typeData = res.data.data;
      });
    },
  },
};
</script>

<style scoped lang="less">
.researchactivity {
  // display: flex;
  // justify-content: center;
  .center {
    margin-bottom: 60px;
    // border: 1px solid black;
    .select {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      .select_left {
        .xueduan {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          p {
            color: #999999;
            margin-right: 20px;
          }
          .xueduan_block {
            // width: 72px;
            padding: 0 24px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-right: 20px;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }
        .xueduan2 {
          display: flex;
          // align-items: center;
          // margin-bottom: 39px;
          width: 780px;
          // width: 720px;

          p {
            color: #999999;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .xueduan2_one {
            display: flex;
            align-items: center;
            width: 780px;
            flex-wrap: wrap;

            // mar
          }

          .xueduan_block {
            // width: 72px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 26px;
            padding: 0 27px;
            // padding: 0 20px;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }

        .sousuo {
          display: flex;
          align-items: center;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            border-radius: 4px 4px 4px 4px;
          }
          /deep/ .el-button {
            // border-radius: 0px;
            border-radius: 4px 4px 4px 4px;
          }

          p {
            color: #999999;
            flex-shrink: 0;
            margin-right: 20px;
          }
          .sousuo_one {
            width: 388px;
            margin-right: 20px;
          }
        }
      }
      .select_right {
        // .create {
        //   width: 140px;
        //   height: 38px;
        //   background: #ff8201;
        //   border-radius: 6px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   color: #ffffff;
        //   font-weight: 700;
        // }
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .create {
          width: 140px;
          height: 38px;
          // background: #ff8201;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: 700;
          // display: none;
        }
        .sousuo_one {
          display: flex;
          align-items: center;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            border-radius: 4px 0px 0px 4px;
          }
          /deep/ .el-button {
            // border-radius: 0px;
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }
  }
  .type_xueduan2 {
    display: flex;
    // align-items: center;
    // margin-bottom: 39px;
    width: 1400px;
    // width: 720px;

    p {
      color: #999999;
      margin-right: 20px;
      flex-shrink: 0;
    }
    .xueduan2_one {
      display: flex;
      align-items: center;
      width: 1400px;
      flex-wrap: wrap;
    }

    .xueduan_block {
      // width: 72px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      padding: 0 27px;
      // padding: 0 20px;
    }
    .active_block {
      background: #3489fe;
      border-radius: 4px 3px 4px 4px;
      color: #ffffff;
    }
  }
  .CenterPage {
    width: 100%;
    background: #f7f7f7;
    padding: 24px 0;
    .tBoxs {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .activity_block3 {
        margin-right: 13px;
        width: 264px;
        // height: 332px;
        // background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        // box-shadow: 0px 0px 6px 2px rgba(113, 113, 113, 0.12);
        margin-bottom: 26px;
        &:hover {
          box-shadow: 0 2px 12px 0 #ddd;
          cursor: pointer;
        }

        .img {
          width: 264px;
          height: 159px;
          border-radius: 2px 2px 0px 0px;
          // background-image: url(../../../assets/images/live.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column-reverse;
          position: relative;

          //三角形样式
          .triangle {
            position: absolute;
            width: 0;
            height: 0;
            /* px越大三角形就越高 ，显示这个三角要设置颜色，其他方向的三角不能设置颜色并设置属性transparent*/
            border-bottom: 50px solid #ff8201;
            /*left和right是对立面，px要一样。px越小，三角形就越窄。 */
            border-top: 50px solid transparent;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            transform: rotateZ(45deg);
            left: 214px;
            top: -51px;
          }
          .triangle1 {
            position: absolute;
            width: 0;
            height: 0;
            /* px越大三角形就越高 ，显示这个三角要设置颜色，其他方向的三角不能设置颜色并设置属性transparent*/
            border-bottom: 50px solid #3489fe;
            /*left和right是对立面，px要一样。px越小，三角形就越窄。 */
            border-top: 50px solid transparent;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            transform: rotateZ(45deg);
            left: 214px;
            top: -51px;
          }
          .triangle2 {
            position: absolute;
            width: 0;
            height: 0;
            /* px越大三角形就越高 ，显示这个三角要设置颜色，其他方向的三角不能设置颜色并设置属性transparent*/
            border-bottom: 50px solid #ddd;
            /*left和right是对立面，px要一样。px越小，三角形就越窄。 */
            border-top: 50px solid transparent;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            transform: rotateZ(45deg);
            left: 214px;
            top: -51px;
          }
          // 文字样式
          .wzBox {
            position: absolute;
            right: 3px;
            top: 13px;
            transform: rotateZ(45deg);
            color: #fff;
            font-size: 14px;
          }
        }
        .mBoxs {
          padding: 20px;
          .word1 {
            width: 220px !important;
            font-weight: 700;
            width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 12px;
          }

          .word_other1 {
            margin-bottom: 15px;
            font-size: 14px;
            .sp1 {
              color: #3489fe;
              padding-right: 12px;
              margin-right: 12px;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 4px;
                width: 1px;
                height: 12px;
                background-color: #ebebeb;
              }
            }
            .sp2 {
              font-size: 14px;
              padding-right: 12px;
              margin-right: 12px;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 4px;
                width: 1px;
                height: 12px;
                background-color: #ebebeb;
              }
            }
            .sp3 {
              color: #a9a9a9;
            }
          }
          //时间样式
          .lastContent {
            .oneC {
              width: 36px;
              height: 20px;
              opacity: 1;
              background: #e8eaed;
              border-radius: 2px;
              color: #999999;
              padding: 2px;
              font-size: 14px;
              margin-right: 10px;
            }
            .twoC {
              color: #999999;
              margin-left: 3px;
              margin-right: 6px;
            }
            .threeC {
              color: #999999;
              margin-left: 6px;
            }
          }
          //横线样式
          .hx {
            width: 224px;
            height: 1px;
            opacity: 1;
            border-bottom: 1px dashed #ebebeb;
            margin-top: 10px;
          }
          //最后一行样式
          .word2 {
            padding: 20px 0 0 0;
            //   margin: 0 20px;
            color: #666666;
            font-size: 14px;
            border-top: 1px dashed #ebebeb;
            .studio_name {
              width: 130px;
              color: #3489fe;
            }
            .join {
              width: 50px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background: #ff8201;
              border-radius: 10px;
              font-size: 12px;
              font-weight: 600;
              color: #fff;
              margin-left: 18px;
              &.unjoin {
                background-color: #3489fe;
              }
            }
          }
        }
        .word_other2 {
          margin-left: 30px;
          margin-bottom: 15px;
          display: flex;
          .member {
            font-size: 14px;
          }
          .hand {
            width: 50px;
            // padding:5px 5px;
            height: 20px;
            background: #ff8201;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
          }
          .hand2 {
            background: #cccccc;
          }
        }
      }
    }
    .main:after {
      content: "";
      width: 335px;
    }
    .pick {
      width: 336px;
      border: 1px solid transparent;
      overflow: hidden;
    }
    .nodata {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #888;
      margin-top: 100px;
    }
  }
}
</style>