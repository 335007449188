import { MessageBox } from 'element-ui';

//全局注册自定义组件   全部在这里统一注册 全局组件
export default {
  install: Vue => {
    //引入修改后的选择成员组件
    Vue.component('AppointUser', () =>
      import('../components/MyCom/AppointUser.vue')
    )
    // 金山云上传
    Vue.component('DcUpload', () =>
      import('../components/dcUpload/src/index.vue'))
    // 数据表格
    Vue.component('DataTable', () =>
      import('../components/table/dataTable/dataTable.vue'))
    Vue.component('DataTableColumn', () =>
      import('../components/table/dataTableColumn/dataTableColumn.vue'))
    // 邀请对话框组件
    Vue.component('new-invitation', () =>
      import('../components/newInvitation/newInvitation.vue'))
    //添加资源组件
    Vue.component('new-addresource', () =>
      import('../components/newAddResource/newAddResource.vue'))
    //在线查看组件
    Vue.component('new-viewonline', () =>
      import('../components/newViewonline/newViewonline.vue'))
    //评论回复组件
    Vue.component('new-comment', () =>
      import('../components/newViewonline/newComment.vue'))
    //无数据提示
    Vue.component('dataTips', () =>
      import('../components/dataTips/dataTips.vue'))


    //直播指引组件
    Vue.component('liveGuide', () =>
      import('../components/MyCom/LiveGuide.vue'))

    //下载指引组件
    Vue.component('downGuide', () =>
      import('../components/MyCom/downGuide.vue'))

    //使用指引
    Vue.component('useGuide', () =>
      import('../components/MyCom/useGuide.vue'))

    //教研组投诉
    Vue.component('comPlain', () =>
      import('../components/Commons/complain.vue'))

    // 通用上传组件
    Vue.component('MyUpload', () =>
      import('../components/MyUpload/index.vue'))

    // 通用dialog
    Vue.component('MyDialog', () =>
      import('../components/MyDialog/index.vue'))

    // 空值组件
    Vue.component('CommonEmpty', () =>
      import('../components/commonEmpty/index.vue'))

    // 课程 活动 直播 单个卡片
    Vue.component('PublicTtemDiv', () => import('@/components/PublicTtemDiv/PublicTtemDiv.vue'));

    // 分享组件
    Vue.component('PublicShare', () => import('@/components/PublicShare/PublicShare.vue'));

    // 播放器
    Vue.component('Ckplayer', () => import('@/components/Ckplayer/Ckplayer.vue'));

    // 工作室头部
    Vue.component('StudioIndex', () => import('@/views/MasterStudio/StudioIndex.vue'));

    // 弹框组件
    Vue.component('PublicBoxMaskDialog', () => import('@/components/PublicBoxMaskDialog/PublicBoxMaskDialog.vue'));

    // 编辑器组件
    Vue.component('Editor', () => import('@/components/editor/index.vue'));

    //工作室管理 通用头部tabs
    Vue.component('StudioAdminTabs', () => import('@/components/studioAdmin/tabs.vue'));

    // 确认框
    Vue.prototype.myConfirm = (data) => {
      MessageBox.confirm(data.content, data.tip || "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: data.type || "warning"
      }).then(() => {
        data.fn && data.fn(data.id)
      }).catch(() => {
        console.log('取消');
      });
    }
    // 确认框
    Vue.prototype.downloadFile = (url) => {
      window.location.href = url;
    }

  }
}