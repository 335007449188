<template>
  <div class="noticedetail public_main_bgc">
    <!-- 面包屑 -->
    <div class="navList">
      <p>
        当前位置:
        <span @click="toList()">首页</span>
        >
        <span v-if="type == 'news'">资讯详情</span>
        <span v-if="type == 'notice'">公告详情</span>
      </p>
    </div>
    <div class="w_d center" style="margin: 0 auto">
      <div class="left">
        <div class="one">
          <span class="boxaa">资讯中心</span>
        </div>
        <div class="one">
          <div class="word cursor">
            <i class="el-icon-bell"></i>
            <span @click="toNoticeList()">通知公告</span>
          </div>
        </div>
        <div class="one">
          <div class="word cursor">
            <i class="el-icon-document"></i>
            <span @click="toNewsList()">教研资讯</span>
          </div>
        </div>
      </div>
      <div class="right">
        <p class="title">{{ this.getDetail.title }}</p>
        <p class="time">
          <span>发布时间：{{ this.getDetail.create_time }}</span>
          <span>浏览：{{ this.getDetail.view_count }}</span>
        </p>
        <div class="hengxian"></div>
        <div class="word rich_text_div_img" v-html="getDetail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0, //公告/资讯id
      type: "", //公告/资讯类型
      getDetail: {}, //公告/资讯详情
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.id = this.$route.query.id;
      this.type = this.$route.query.type;
      let apiUrl = "";
      if (this.type == "news") {
        apiUrl = `/information/${this.id}`;
      }
      if (this.type == "notice") {
        apiUrl = `/notice/${this.id}`;
      }
      const res = await this.$axios.get(apiUrl);
      this.getDetail = res.data.data;
      console.log(this.getDetail, "54545454545");
    },
    //跳转通知列表
    toNoticeList() {
      this.$router.push({
        path: "/noticelist",
        query: { type: "notice" },
      });
    },
    //跳转资讯列表
    toNewsList() {
      this.$router.push({
        path: "/noticelist",
        query: { type: "news" },
      });
    },

    //路由
    toList() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="less">
.noticedetail {
  width: 100%;
  // background-color: #f7f7f7;
  // display: flex;
  // justify-content: center;
  padding-bottom: 20px;
  box-sizing: border-box;
  .navList {
    font-size: 14px;
    height: 48px;
    width: 1400px;
    margin: 0 auto;
    line-height: 48px;
    p {
      color: rgba(153, 153, 153, 1);
      span:last-child {
        color: #ff8201;
      }

      span:first-of-type {
        cursor: pointer;
        &:hover {
          color: #3489fe;
        }
      }
    }
  }
  .center {
    // border: 1px solid black;
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    // justify-content: space-between;

    .left {
      width: 268px;
      height: 180px;
      background: #ffffff;
      margin-right: 20px;
      border-radius: 6px;
      .one {
        .boxaa {
          font-size: 16px;
          color: #ffffff;
        }
        height: 50px;
        // border-bottom: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        .word {
          margin-left: 29px;
          span {
            margin-left: 13px;
          }
        }
        .word:hover {
          color: #3489fe;
        }
      }
      .one:nth-child(1) {
        border-bottom: 1px solid #ebebeb;
        background: #3489fe;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        justify-content: center;
        //  margin-top: 10px;
        .boxaa {
          font-size: 16px;
          color: #ffffff;
        }
      }
      .one:nth-child(2) {
        margin-top: 10px;
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 10px;
      }
      .one:nth-of-type(3) {
        height: 50px;
      }
    }
    .right {
      * {
        img {
          width: 100px !important;
        }
      }
      width: 1112px;
      //   height: 1046px;
      background: #ffffff;
      border-radius: 6px;
      padding: 0px 30px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 28px;
        color: #2b2b2b;
        margin-top: 56px;
        margin-bottom: 24px;
        text-align: center;
      }
      .time {
        font-weight: 500;
        color: #8590a6;
        margin-bottom: 32px;
        span:nth-child(1) {
          margin-right: 30px;
        }
      }
      .hengxian {
        width: 100%;
        border-top: 1px dashed #ececec;
        margin-bottom: 57px;
      }
      /deep/ .word {
        width: 883px;
        font-weight: 500;
        color: #666666;
        line-height: 32px;
        text-indent: 2em;
        img {
          max-width: 883px;
        }
      }
    }
  }
}
</style>