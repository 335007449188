<template>
  <div class="createlive">
    <div class="w_d center">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>教研直播</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">创建教研直播</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="main">
        <el-form ref="form" label-position="right" label-width="120px">
          <el-form-item label="直播封面：">
            <div class="four">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="直播主题：">
            <el-input class="one" placeholder="最多50个字..." :maxlength="50" v-model="formData.title"></el-input>
          </el-form-item>

          <el-form-item label="直播时间：">
            <div class="two">
              <el-date-picker v-model="formData.start_date" type="date" placeholder="开始日期" class="two_one" value-format="yyyy-MM-dd"></el-date-picker>
              <el-time-picker v-model="formData.start_time" placeholder="开始时间" value-format="HH:mm" @change="change_start"></el-time-picker>
              <div class="two_two">-</div>

              <el-date-picker v-model="formData.end_date" type="date" placeholder="结束日期" class="two_one" value-format="yyyy-MM-dd"></el-date-picker>
              <el-time-picker v-model="formData.end_time" placeholder="结束时间" value-format="HH:mm"></el-time-picker>
            </div>
          </el-form-item>

          <el-form-item label="直播介绍：">
            <Editor v-model="formData.presentation"></Editor>
          </el-form-item>
          <el-form-item label="学段学科：">
            <el-select v-model="formData.stage_id" placeholder="请选择学段" @change="changeStage">
              <el-option v-for="item in stageData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="formData.subject_id" placeholder="请选择学科">
              <el-option v-for="item in subjectData" :key="item.subjectId" :label="item.subjectName" :value="item.subjectId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教研资源：">
            <div class="three">
              <div class="three_one">
                <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview"
                  :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                  <el-button size="small" type="primary">点击选择</el-button>
                </el-upload>
                <div class="three_one_one">
                  添加资源后，在直播过程中可调用添加的资源文件。
                </div>
              </div>
              <div class="three_two">
                <el-checkbox v-model="formData.allowed_download" class="three_two_one" true-label="2" false-label="1">允许下载资源文件</el-checkbox>
                <el-tooltip class="item" effect="dark" content="勾选后，参与教研的用户将可以下载教研资源文件。每个文件还可以单独设置是否允许下载" placement="right">
                  <div class="circular">?</div>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="直播发起人：">
            <div class="six">
              <el-radio v-model="formData.initiator_type" label="1">个人</el-radio>
              <el-radio v-model="formData.initiator_type" label="2">教研组</el-radio>
            </div>
            <div class="eight" v-if="formData.initiator_type=='2'">
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
            </div>
          </el-form-item>

          <el-form-item label="允许谁参与：">
            <div class="five">
              <el-radio v-model="radio" label="1">所有人</el-radio>
              <el-radio v-model="radio" label="2">本校成员</el-radio>
              <el-radio v-model="radio" label="3">指定教研组</el-radio>
              <el-radio v-model="radio" label="4">指定成员</el-radio>
            </div>
            <div class="eight">
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
            </div>
            <div class="nine">
              <div class="nine_left">
                <div class="nine_left_one">
                  <div>选择指定成员</div>
                  <i class="el-icon-d-arrow-right"></i>
                </div>
                <div class="nine_left_two">清空所有</div>
              </div>
              <div class="nine_right">
                <el-tag closable effect="dark" v-for="(item, index) in 30" :key="index" class="nine_tag">标签一</el-tag>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="save">
        <el-button class="commond_button save_one">取消</el-button>
        <el-button class="commond_button" type="warning" @click="go">保存</el-button>
      </div>
    </div>
    <!-- 添加文档视频对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="dialogVisible" width="1342px" :show-close="false">
        <div class="dialog_content">
          <div class="title">
            <div class="title_word">添加文档/视频</div>
            <div class="point_one avtive cursor" @click="one('one')">
              我的资源
            </div>
            <div class="point_one cursor" @click="one('two')">上传资源</div>
          </div>
          <div class="hengxian"></div>
          <div class="main" v-if="data1 == 'one'">
            <div class="main_one">
              <p>学段：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>学科：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>年级：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>类型：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
            </div>
            <div class="main_two">
              <p>地区：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>教材：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
            </div>
            <div class="main_three">
              <p>知识点：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <p>文档类型：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <div class="reset_button">重置</div>
              <div class="sousuo_button">搜索</div>
            </div>
            <div class="main_block">
              <div class="images">
                <img src="@/assets/images/word.png" />
                <span>资源类型</span>
              </div>
              <div class="block_word">
                <p>
                  标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题
                </p>
                <p>学段学科 / 教材 / 年级 / 章 / 节/</p>
                <div class="block_gion">
                  <div class="block_gion_one">地区</div>
                  <div class="block_gion_one">年份</div>
                </div>
                <div class="block_time">
                  <span>上传时间：2020-8-4</span>
                  <span>收藏次数：2452</span>
                  <span>文件大小：204KB</span>
                </div>
              </div>
              <div class="block_sign"></div>
            </div>
            <div class="main_block">
              <div class="images">
                <img src="@/assets/images/word.png" />
                <span>资源类型</span>
              </div>
              <div class="block_word">
                <p>
                  标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题
                </p>
                <p>学段学科 / 教材 / 年级 / 章 / 节/</p>
                <div class="block_gion">
                  <div class="block_gion_one">地区</div>
                  <div class="block_gion_one">年份</div>
                </div>
                <div class="block_time">
                  <span>上传时间：2020-8-4</span>
                  <span>收藏次数：2452</span>
                  <span>文件大小：204KB</span>
                </div>
              </div>
              <div class="block_sign active">
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div>
          <div class="main_block_two" v-if="data1 == 'two'">
            <div class="main_center">
              <el-form ref="form" :model="form" label-width="120px">
                <el-form-item label="上传文件：">
                  <div class="one cursor">选择文件</div>
                </el-form-item>
                <el-form-item label="资源名称：">
                  <el-input placeholder="默认为文件名" class="two"></el-input>
                </el-form-item>
                <el-form-item label="资源类型：">
                  <el-select v-model="value" placeholder="请选择资源类型" class="two">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学段学科：">
                  <el-select v-model="value" placeholder="请选择学段" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择学科" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择年级" class="three">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="适用教材：">
                  <el-select v-model="value" placeholder="请选择版本" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择章" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择节" class="three">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="地区年份：">
                  <el-select v-model="value" placeholder="请选择地区" class="three">
                    <el-option></el-option>
                  </el-select>
                  <el-select v-model="value" placeholder="请选择年份" class="three">
                    <el-option></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="共享范围：">
                  <el-radio v-model="radio" label="1">所有人</el-radio>
                  <el-radio v-model="radio" label="3">本校成员</el-radio>
                  <el-radio v-model="radio" label="2">教研组成员</el-radio>
                </el-form-item>
                <!-- <div class="four">
                  <el-checkbox v-model="checked">允许下载资源文件</el-checkbox>
                </div>
                <div class="five"></div> -->
              </el-form>
              <div class="four">
                <el-checkbox v-model="checked">允许下载资源文件</el-checkbox>
              </div>
              <div class="five"></div>
              <div class="six">
                <p>注意事项：</p>
                <p>
                  1.文件支持格式：
                  doc、docx、xls、xlsx、ppt、pptx、pdf、txt、flv、mp4、mpg、gif、jpg、jpeg、png
                </p>
                <p>2.单个文件大小：≤ 200M</p>
              </div>
            </div>
          </div>
          <div class="save">
            <div class="save_one cursor">取消</div>
            <div class="save_two cursor">保存</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 选择用户对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="userDialog" width="1342px" :show-close="false">
        <div class="user_content">
          <div class="title">
            <div class="title_word">选择用户</div>
          </div>
          <div class="hengxian"></div>
          <div class="main">
            <div class="main_one">
              <p>地区：</p>
              <el-select v-model="value" placeholder="选择省市">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="选择省市">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="选择省市">
                <el-option></el-option>
              </el-select>
              <p>学段学科：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
            </div>
            <div class="main_two">
              <p>姓名：</p>
              <el-select v-model="value" placeholder="请输入姓名">
                <el-option></el-option>
              </el-select>
              <p>单位：</p>
              <el-select v-model="value" placeholder="请输入所属单位">
                <el-option></el-option>
              </el-select>
              <p>教研组：</p>
              <el-select v-model="value" placeholder="请输入所属教研组">
                <el-option></el-option>
              </el-select>
              <div class="reset_button">重置</div>
              <div class="sousuo_button">查询</div>
            </div>
            <div class="main_three">
              <el-radio v-model="radio" label="1">全部</el-radio>
              <el-radio v-model="radio" label="2">已选</el-radio>
            </div>
            <div class="main_four">
              <el-table height="220" :data="tableData">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="姓名"> </el-table-column>
                <el-table-column label="学段"> </el-table-column>
                <el-table-column label="学科"> </el-table-column>
                <el-table-column label="所属教研组"> </el-table-column>
                <el-table-column label="所属单位"> </el-table-column>
                <el-table-column label="所属地区"> </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="pagin">
            <el-pagination background :current-page="1" :page-size="100" layout="prev, pager, next, jumper" :total="1000"></el-pagination>
          </div>
          <div class="footer_save">
            <el-button class="commond_button cancel">取消</el-button>
            <el-button class="commond_button save" type="warning" @click="go">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      data1: "two",
      userDialog: false,
      tableData: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      // 表单总数据
      formData: {
        //标题
        title: "",
        //开始日期
        start_date: "",
        // 开始时间
        start_time: "",
        //结束日期
        end_date: "",
        //结束时间
        end_time: "",
        // 直播介绍、描述
        presentation: "vsdfvsd",
        // 学段id
        stage_id: "",
        // 学科id
        subject_id: "",
        // 是否允许下载
        allowed_download: "",
        // 直播类型
        initiator_type: "1",
        //
      },
      //学段总数据
      stageData: [],
      // 学科总数据
      subjectData: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 自动增加 3小时
    change_start(e) {
      if (!e) {
        return false;
      };
      let hour = e.split(":")[0];
      let minute = e.split(":")[1];
      hour = Number(hour) + 3;
      let end_time = `${hour}:${minute}`;
      this.$set(this.formData, "end_time", end_time);
    },
    //初始化函数
    init() {
      this.getStage();
    },
    go() {
      this.$router.push("/livedetail");
    },
    //请求后台获取学段信息
    getStage() {
      this.$axios.get("stage/stage_list").then((res) => {
        this.stageData = res.data.data;
      });
    },
    // 请求后台获取学科信息
    getSubject() {
      var obj = {
        params: {
          pharseid: this.formData.stage_id,
        },
      };
      this.$axios.get("api/subject/list", obj).then((res) => {
        var resdata = res.data;
        if (resdata.code == "900") {
          this.subjectData = resdata.data;
        } else {
          this.$message({
            message: resdata.msg,
          });
        }
      });
    },
    // 改变学段时触发的函数
    changeStage() {
      this.getSubject();
    },
  },
};
</script>

<style scoped lang="less">
.createlive {
  display: flex;
  justify-content: center;
  .center {
    // border: 1px solid black;
    .main {
      display: flex;
      justify-content: center;
      margin-top: 70px;
      .one {
        width: 600px;
      }
      .two {
        display: flex;
        .two_one {
          margin-right: 14px;
        }
        .two_two {
          margin: 0px 26px;
        }
      }
      .three {
        .three_one {
          display: flex;
          .three_one_one {
            color: #9a9ea5;
            margin-left: 30px;
          }
        }
        .three_two {
          display: flex;
          align-items: center;
          .three_two_one {
            color: #9a9ea5;
          }
          .circular {
            width: 16px;
            height: 16px;
            background-color: #ff8201;
            border-radius: 50%;
            font-size: 12px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 9px;
          }
        }
      }

      .four {
        border: 1px dashed #d9d9d9;
        width: 130px;
        .avatar-uploader .el-upload {
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 130px;
          height: 130px;
          line-height: 130px;
          text-align: center;
        }
        .avatar {
          width: 130px;
          height: 130px;
          display: block;
        }
      }
      .four:hover {
        border-color: #409eff;
      }
      .eight {
        width: 550px;
        height: 100px;
        border: 1px solid #ececec;
        border-radius: 0px 0px 4px 4px;
        overflow: auto;
        padding-left: 20px;
      }
      .nine {
        width: 1020px;
        height: 100px;
        border: 1px solid #ececec;
        border-radius: 0px 0px 4px 4px;
        display: flex;

        .nine_left {
          width: 130px;
          height: 100%;
          border-right: 1px solid #ececec;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          .nine_left_one {
            width: 100px;
            height: 40px;
            background: #409eff;
            border-radius: 4px;
            line-height: 24px;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            margin-top: 20px;
          }
          .nine_left_two {
            color: #9a9ea5;
            cursor: pointer;
          }
        }
        .nine_right {
          padding-top: 10px;
          padding-left: 20px;
          overflow: auto;
          .nine_tag {
            margin-right: 10px;
          }
        }
      }

      /deep/ .el-form-item__label {
        font-size: 16px;
        font-weight: 400;
        color: #2b2b2b;
      }
      /deep/ .el-form-item {
        margin-bottom: 30px;
      }
    }
    .save {
      margin-top: 51px;
      margin-bottom: 60px;
      display: flex;
      justify-content: center;
      .save_one {
        margin-right: 22px;

        background-color: #ececec;
        // color: #333333;
      }
    }
  }
  // 文档视频对话框样式
  .dialog_content {
    overflow: hidden;
    .title {
      display: flex;
      margin: 35px 0px 23px 40px;
      align-items: center;
      .title_word {
        font-weight: 700;
        color: #333333;
        // margin-right: 26px;
      }
      .point_one {
        width: 96px;
        height: 30px;
        background: rgba(52, 137, 254, 0.1);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 26px;
      }
      .avtive {
        color: #fffefe;
        background: #3489fe;
      }
    }
    .hengxian {
      width: 100%;
      height: 1px;
      background: #dedede;
    }
    .main {
      margin-top: 38px;
      margin-left: 40px;
      .main_one {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        p:nth-child(1) {
          margin-left: 15px;
        }
        p:nth-child(3) {
          margin-left: 81px;
        }
        p:nth-child(5) {
          margin-left: 49px;
        }
        p:nth-child(7) {
          margin-left: 39px;
        }
      }
      .main_two {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        p:nth-child(1) {
          margin-left: 15px;
        }
        p:nth-child(3) {
          margin-left: 81px;
        }
      }
      .main_three {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        p:nth-child(3) {
          margin-left: 50px;
        }
        .reset_button {
          margin-left: 444px;
          margin-right: 20px;
          width: 82px;
          height: 34px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 6px;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .sousuo_button {
          width: 82px;
          height: 34px;
          background: #ff8201;
          border-radius: 6px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .main_block {
        width: 1264px;
        height: 200px;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        margin-bottom: 20px;
        .images {
          width: 160px;
          height: 160px;
          border: 1px solid #ececec;
          border-radius: 6px;
          margin-top: 20px;
          margin-left: 20px;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-top: 43px;
            margin-bottom: 20px;
          }
        }
        .block_word {
          margin-right: 490px;
          p:nth-child(1) {
            margin-top: 34px;
            margin-bottom: 18px;
            font-weight: 700;
            width: 494px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p:nth-child(2) {
            color: #666666;
            margin-bottom: 18px;
          }
          .block_gion {
            display: flex;
            margin-bottom: 14px;
            .block_gion_one {
              width: 69px;
              height: 24px;
              background: #ffffff;
              border: 1px solid #ff8201;
              border-radius: 4px;
              color: #ff8201;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
            }
          }
          .block_time {
            color: #999999;
            span {
              margin-right: 30px;
            }
          }
        }
        .block_sign {
          margin-top: 80px;
          width: 34px;
          height: 34px;
          background: #ffffff;
          border: 1px solid #ececec;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .active {
          background: #3489fe;
          color: #ffffff;
        }
      }
    }
    .main_block_two {
      margin-top: 88px;
      display: flex;
      justify-content: center;
      .main_center {
        .one {
          width: 134px;
          height: 34px;
          background: #3489fe;
          border-radius: 4px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .two {
          width: 439px;
        }
        .three {
          width: 220px;
        }
        .four {
          margin-left: 120px;
          margin-bottom: 37px;
        }
        .five {
          width: 770px;
          //  width: 775px;
          border-top: 1px dashed #dedede;
          margin-bottom: 30px;
          margin-left: 35px;
        }
        .six {
          margin-left: 35px;
          // margin-bottom:50px;
          p:nth-child(2) {
            color: #999999;
            margin-bottom: 20px;
            margin-top: 20px;
          }
          p:nth-child(3) {
            color: #999999;
          }
        }
      }
    }
    .save {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
      .save_one {
        width: 148px;
        height: 42px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        margin-right: 22px;
      }
      .save_two {
        width: 148px;
        height: 42px;
        background: #ff8201;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>