<template>
  <div class="TrainActivity">
    <div class="w_layout">
      <!-- 导航 -->
      <div class="topText">
        <div class="title">
          <p>
            当前位置：四名工作室 >
            <span>教研活动</span>
          </p>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="ItemBox">
        <div class="item1">
          <div class="title">活动类型:</div>
          <div :class="index == active ? 'activeClass' : 'forList'" v-for="(item, index) in optiones" :key="index"
            @click="changType(index, item.id)">
            {{ item.name }}
          </div>
        </div>
        <div class="item1">
          <div class="title">开展方式:</div>
          <div :class="index == active1 ? 'activeClass' : 'forList'" v-for="(item, index) in optiones1" :key="index"
            @click="changType1(index, item.id)">
            {{ item.name }}
          </div>
        </div>
        <div class="item1">
          <div class="title">活动状态:</div>
          <div :class="index == active2 ? 'activeClass' : 'forList'" v-for="(item, index) in optiones2" :key="index"
            @click="changType2(index, item.id)">
            {{ item.name }}
          </div>
        </div>

        <div v-if="detailData.role != 0" class="creBtn_div">
          <router-link :to="`/TrainActivityIndexSet?studioId=${this.form.teaching_studio_id}`">
            <el-button type="primary" style="background:#3489fe">创建活动</el-button>
          </router-link>
        </div>

      </div>
    </div>
    <!-- 列表 -->
    <div class="ArrList">
      <div class="w_layout">
        <div v-if="total > 0">
          <div class="ListItem">
            <template v-for="(item, index) in listData">
              <PublicTtemDiv :key="index" :activeBor="true" :handleBtn="true" :navActive="1" :isStudioModule="1"
                :currentIndex="index" :item="item" @researchActivityEditClick="researchActivityEditClick"
                @delClick_2="researchActivityDelClick" @activity_RefreshPage="init()" @toPageEvent_4="activityDetails">
              </PublicTtemDiv>
            </template>
          </div>

          <div v-show="total" class="pagina_div" style="height:50px">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
        <CommonEmpty v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      //  1 2 3 4 5 6 7 8 9 10 11
      optiones: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "观课议课",
        },
        {
          id: 2,
          name: "集体备课",
        },
        {
          id: 3,
          name: "同课异构",
        },
        {
          id: 4,
          name: "微课题研究",
        },
        {
          id: 5,
          name: "研课磨课",
        },
        {
          id: 6,
          name: "主题研讨",
        },
        {
          id: 7,
          name: "材料研读",
        },
        {
          id: 8,
          name: "案例分析",
        },
        {
          id: 9,
          name: "校本教研",
        },
        {
          id: 10,
          name: "成果展示",
        },
        {
          id: 11,
          name: "自定义",
        },
      ],
      // 开展方式
      optiones1: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "线上",
        },
        {
          id: 2,
          name: "线下",
        },
        {
          id: 3,
          name: "混合",
        },
      ],
      //  活动状态
      optiones2: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 2,
          name: "进行中",
        },
        {
          id: 1,
          name: "未开始",
        },
        {
          id: 3,
          name: "已结束",
        },
      ],
      active: 0,
      active1: 0,
      active2: 0,
      total: 0,
      form: {
        teaching_studio_id: localStorage.getItem("studioId"),
        search_type: "",
        search_implement_type: "",
        search_status: "", //活动状态 1进行中 2未开始 3已结束
        limit: 12,
        page: 1,
      },
      bgc: {
        1: "#ff8201",
        2: "#3489fe",
        3: "#ddd",
      },
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
    };
  },
  created() {
    this.init();
    this.getAdminData();
  },
  methods: {
    //删除活动
    async delActivity(id, item) {
      const res = await this.$axios.delete(`/activity/${id}`, { params: { is_personid: item.is_personid } });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.init();
    },
    //修改教研活动按钮
    researchActivityEditClick(id) {
      this.$router.push({
        path: "/newActivity",
        query: { id: id },
      });
    },
    //删除教研活动按钮
    researchActivityDelClick(id, item) {
      this.$confirm("此操作将删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity(id, item);
      }).catch(() => { });
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    init() {
      this.getActivity();
    },
    //四名讲堂列表
    getActivity() {
      this.$axios.get("teachingStudioActivity/list", { params: this.form, }).then((res) => {
        this.listData = res.data.data.data;
        this.total = res.data.data.total;
        // this.listData.forEach((item) => {
        //   let a = item.start_date;
        //   let b = item.end_date;
        //   item.start_date = a.substring(a.indexOf("-") + 1);
        //   item.end_date = b.substring(b.indexOf("-") + 1);
        //   // item.start_date=item.start_date.substring(item.start_date.IndexOf("-")+1);
        // });
      });
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    //   跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
    activityDetails(item) {
      let routeData = this.$router.resolve({
        name: "basicinfo",
        query: {
          activityId: item.id,
          is_from: 2,
          is_show_head: 1,
        },
      });
      window.open(routeData.href, '_blank');
    },
    changType(index, id) {
      this.active = index;
      this.form.search_type = id;
      this.$set(this.form, "page", 1);
      this.init();
    },
    changType1(index, id) {
      this.active1 = index;
      this.form.search_implement_type = id;
      this.$set(this.form, "page", 1);
      this.init();
    },
    changType2(index, id) {
      this.active2 = index;
      this.form.search_status = id;
      this.$set(this.form, "page", 1);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}

.TrainActivity {

  // background: #fff;
  .topText {
    .title {
      height: 48px;
      font-size: 14px;
      color: #999999;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      p {
        span {
          color: #ff8201;
        }
      }
    }
  }

  //选项
  .ItemBox {
    padding: 10px 25px;
    border-radius: 6px;
    background: #fff;
    position: relative;

    .creBtn_div {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }

    .item1 {
      display: flex;
      align-items: center;
      margin: 15px 0;

      .title {
        height: 15px;
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        margin-right: 13px;
        line-height: 15px;
      }

      //循环
      .forList {
        // width: 67px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 4px 3px 4px 4px;
        padding: 0 10px;
        margin-right: 10px;
        cursor: pointer;
      }

      .activeClass {
        // width: 67px;
        height: 30px;
        background: #3489fe;
        line-height: 30px;
        text-align: center;
        border-radius: 4px 3px 4px 4px;
        padding: 0 10px;
        margin-right: 10px;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .ArrList {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 30px;
  }
}

.ListItem {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
</style>