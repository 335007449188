<template>
  <div class="problem-create">
    <div class="problem-top">
      <h3 class="create-title" v-if="moduleType == 5">
        {{ problemType == 1 ? "引用已有问卷" : "引用已有投票" }}
      </h3>
      <h3 class="create-title" v-else>创建{{ problemType == 1 ? "问卷" : "投票" }}</h3>
    </div>
    <div class="problem-bottom">
      <!-- 基本信息 开始 -->
      <BasicInfo @submitData="basicInfoSubmitData" @problemType="basicInfoProblemType" v-if="moduleType == 1">
      </BasicInfo>
      <!-- 基本信息 结束 -->

      <!-- 题目 开始 -->
      <Subject @goBack="subjectCallback" @preview="subjectPreview" @submitData="subjectSubmitData"
        v-if="moduleType == 2"></Subject>
      <!-- 题目 结束 -->

      <!-- 题目预览 开始 -->
      <Preview @goBack="previewCallback" v-if="moduleType == 3"></Preview>
      <!-- 题目预览 结束 -->

      <!-- 创建完成 开始 -->
      <Finish @create="finishCreate" v-if="moduleType == 4"></Finish>
      <!-- 创建完成 结束 -->

      <!-- 历史题目 开始 -->
      <History @goBack="historyCallback" @submitData="historySubmitData" v-if="moduleType == 5"></History>
      <!-- 历史题目 结束 -->
    </div>
  </div>
</template>

<script>
import CreateProblem from "./createProblem.js";
export default CreateProblem;
</script>

<style lang="less" scoped>
@import "./createProblem.less";
</style>