<template>
  <div class="groupdetail public_main_bgc">
    <div class="w_d center_one">
      <div class="bread" style="
          height: 48px;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
        ">
        <div class="bread_content" style="margin: 0 !important">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>教研组</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/groupIndex' }">教研组列表</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">教研组详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="bread_hengxian"></div> -->
      </div>
      <div class="one_content">
        <div class="images">
          <img :src="groupInfo.cover_url" />
          <!-- 投诉 -->
          <div class="textbox" v-if="groupInfo.is_show == 2">
            <comPlain :type="4" :id="groupInfo.id" :is_from="1" />
          </div>
        </div>

        <div class="word_div">
          <div class="word_one">
            <p class="ellipsis">{{ groupInfo.name }}</p>
            <el-tag type="info" v-if="groupInfo.audit_status == 1 && isOrigin" style="
                margin-left: 10px;
                height: 30px;
                display: flex;
                align-items: center;
              ">
              未审核</el-tag>
            <el-tag type="success" v-if="groupInfo.audit_status == 4 && isOrigin" style="
                margin-left: 10px;
                height: 30px;
                display: flex;
                align-items: center;
              ">通过</el-tag>
            <el-tag type="danger" v-if="groupInfo.audit_status == 5 && isOrigin" style="
                margin-left: 10px;
                height: 30px;
                display: flex;
                align-items: center;
              ">
              拒绝</el-tag>

            <span v-if="groupInfo"></span>
          </div>
          <p class="lableSty">{{ groupInfo.label }}</p>
          <div class="word_two">
            <span>学段学科：</span>
            <span>{{ groupInfo.stage_name }}{{ groupInfo.subject_name }}</span>
            <span>教研组类型：</span>
            <span>{{ groupInfo.type_text }}</span>
          </div>
          <div class="word_three">
            <span>简介：</span>
            <p :title="groupInfo.intro"> {{ groupInfo.intro }} </p>
          </div>
          <div class="word_four">
            <!-- <el-popover
              width="230"
              trigger="click"
              v-model="applyPopoverShow"
              v-if="groupInfo.is_show == 3"
            >
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入..."
                v-model="applyJoin.reason"
              >
              </el-input>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="send()"
                  >发送</el-button
                >
              </div>
              <div slot="reference" class="startup">申请加入</div>
            </el-popover> -->
            <div class="startup" v-if="groupInfo.is_show == 3" @click="joinGroup()">
              申请加入
            </div>
            <div class="startup-examine" v-if="groupInfo.is_show == 4">
              审核中
            </div>
            <div class="startupDisable" v-if="groupInfo.is_show == 2">
              已加入
            </div>

            <new-invitation v-if="groupInfo.is_show == 1" :access_code="groupInfo.visit_code" button_name="邀请成员"
              code_describe="将教研组访问码分享给其他人，可在教研组频道输入访问码访问教研组详情。" share_describe="复制链接并分享，其他人可打开链接查看活动教研组详情。" style_string="width: 169px;
                            height: 46px;
                            background: #fff;
                            border-radius: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 18px;
                            font-weight: 700;
                            color: #3489fe;
                            border: 1px solid #3489fe;
                            margin-right: 20px;
                            cursor: pointer;"></new-invitation>

            <el-dropdown placement="bottom" v-if="groupInfo.is_show == 1">
              <span class="operate">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="editGroupButton()">编辑教研组</el-dropdown-item>
                <el-dropdown-item @click.native="delGroupButton()">解散教研组</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="two_content" v-if="groupInfo.audit_status == 4">
      <div class="w_d two_content_center">
        <!-- //教研组左边导航 -->
        <div>
          <div class="left">
            <div class="left_one cursor" :class="item.type == navCurrentType ? 'active' : ''" v-for="(item, index) in navLeft" :key="index"
              @click="enterModule(item)">
              <i :class="item.icon"></i>
              <p>{{ item.name }}</p>
              <i class="ipsd el-icon-arrow-right" :style="
                  item.type == navCurrentType
                    ? { display: 'block' }
                    : { display: 'none' }
                "></i>
            </div>
          </div>
        </div>

        <!-- 右边显示内容 -->
        <div class="right">
          <router-view @changeNavCurrent="changeNavCurrent"></router-view>
        </div>
      </div>
    </div>
    <!-- 申请加入教研组弹窗 -->
    <JoinStudio ref="joinStudioRef" @onCallBack="getGroupInfo" title="申请加入教研" :is_who="3" :group_id="+groupInfo.id" />
  </div>
</template>

<script>
import JoinStudio from "@/components/joinStudio/index.vue";
export default {
  components: { JoinStudio },
  data() {
    return {
      isOrigin: false,
      groupId: 0, //教研组id
      userId: 0, //用户id
      groupInfo: {}, //教研组信息
      applyPopoverShow: false, //是否申请加入弹窗
      navLeft: [
        {
          name: "教研组首页",
          icon: "iconfont iconjiaoyanzushouye",
          type: "index",
        },
        {
          name: "教研直播",
          icon: "iconfont iconjiaoyanzhibo",
          type: "live",
        },
        {
          name: "教研活动",
          icon: "iconfont iconjiaoyanhuodong",
          type: "activity",
        },
        {
          name: "教学视频",
          icon: "iconfont iconjiaoyankecheng",
          type: "course",
        },
        {
          name: "研修成果",
          icon: "iconfont iconyanxiuchengguo",
          type: "achievement",
        },
        // {
        //   name: "话题研讨",
        //   icon: "iconfont iconhuatiyantao",
        //   type: "talk",
        // },
        {
          name: "教研组成员",
          icon: "iconfont iconjiaoyanzuchengyuan",
          type: "member",
        },
      ],
      navCurrentType: "index", //当前所在模块（默认首页）
      applyJoin: {
        id: "",
        user_id: "",
        reason: "",
      },
    };
  },
  created() {
    this.getNavCurrent();
    this.groupId = this.$route.query.groupId ? this.$route.query.groupId : 0;
    this.userId = localStorage.getItem("id") ? localStorage.getItem("id") : 0;
    this.applyJoin.id = this.groupId;
    this.applyJoin.user_id = this.userId;
    this.getGroupInfo();
  },
  methods: {
    changeNavCurrent(e) {
      if (e) {
        this.getNavCurrent();
      }
    },
    getNavCurrent() {
      if (this.$route.query.navCurrentType) {
        this.navCurrentType = this.$route.query.navCurrentType;
      };
    },
    //获取教研组信息
    async getGroupInfo() {
      const res = await this.$axios.get(`/group/${this.groupId}`);
      this.groupInfo = res.data.data;
      this.isOrigin =
        this.groupInfo.user_id == localStorage.getItem("id") ? true : false;
      // this.user_id
      this.$store.commit("setResearchGroupInfo", this.groupInfo);
      let is_show = res.data.data.is_show;
      localStorage.setItem("group_status", is_show);
      let AuditFlat =
        res.data.data.user_id == localStorage.getItem("id") ? 1 : 0;
      localStorage.setItem("AuditFlat", AuditFlat);
    },
    //删除教研组
    async delGroup() {
      const res = await this.$axios.delete(
        `/TeacherGroup/delGroup?id=${this.groupId}`
      );
      const data = res.data;
      if (data.code == 900) {
        this.$message({
          message: "删除成功",
          type: "success",
          onClose: () => {
            this.$router.push({
              path: "/groupIndex",
            });
          },
        });
      }
    },

    //审核加入教研组
    joinGroup() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$refs.joinStudioRef.open = true;
      } else {
        this.$message.warning("请先登录，再进行操作。");
      }
    },
    //进入对应模块
    enterModule(e) {
      this.navCurrentType = e.type;
      if (this.navCurrentType == "index") {
        this.getGroupInfo();
        //组首页
        this.$router.push({
          path: "/groupdetailshouye",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
      if (this.navCurrentType == "live") {
        //教研直播
        this.$router.push({
          path: "/groupDetailLive",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
      if (this.navCurrentType == "activity") {
        //教研活动
        this.$router.push({
          path: "/groupDetailActivity",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
      if (this.navCurrentType == "course") {
        //教学视频
        this.$router.push({
          path: "/groupDetailCourse",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
      if (this.navCurrentType == "achievement") {
        //研修成果
        this.$router.push({
          path: "/groupDetailResult",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
      if (this.navCurrentType == "talk") {
        //话题研讨
        this.$router.push({
          path: "/groupDetailTalk",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
      if (this.navCurrentType == "member") {
        //成员
        this.$router.push({
          path: "/groupdetailmember",
          query: { groupId: this.groupId, navCurrentType: e.type },
        });
      }
    },
    //编辑教研组
    editGroupButton() {
      this.$router.push({
        path: "/createResearch",
        query: { groupId: this.groupId, type: "edit" },
      });
    },
    //删除教研组
    delGroupButton() {
      this.$confirm("此操作将删除该教研组, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delGroup();
        })
        .catch(() => { });
    },
    //申请加入
    // send() {
    //   if (this.applyJoin.reason) {
    //     this.$axios.post("/group/applyJoin", this.applyJoin).then((res) => {
    //       this.$message.success("发送成功");
    //       this.applyJoin.reason = "";
    //       this.applyPopoverShow = false;
    //       this.groupInfo.is_show = 4;
    //     });
    //   } else {
    //     this.$message({
    //       message: "请输入申请理由",
    //       type: "warning",
    //     });
    //   }
    // },
  },
  watch: {
    $route: {
      handler(newVal, oldval) {
        this.getNavCurrent();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.groupdetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  /deep/ .is-link {
    color: #606266;
    font-weight: 400;
  }
  .center_one {
    //border: 1px solid black;
    .one_content {
      padding: 30px;
      box-sizing: border-box;
      display: flex;
      background: #fff;
      border-radius: 6px;
      .images {
        width: 380px;
        height: 220px;
        border-radius: 6px;
        position: relative;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }

        .textbox {
          position: absolute;
          bottom: 0;
          right: 0px;
        }
      }

      .word_div {
        flex: 1;
        height: 220px;
        margin-left: 30px;
        overflow: hidden;
        position: relative;
        .word_one {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          p {
            font-size: 22px;
            font-weight: 700;
          }
          i {
            font-size: 22px;
            margin-left: 15px;
            color: #009dff;
          }
        }
        .lableSty {
          font-size: 16px;
          color: rgba(153, 153, 153, 1);
          margin-bottom: 15px;
        }
        .word_two {
          display: flex;
          margin-bottom: 20px;
          span:nth-child(odd) {
            color: #999999;
          }
          span:nth-child(even) {
            margin-right: 53px;
          }
        }
        .word_three {
          display: flex;
          span:nth-child(1) {
            color: #999999;
          }
          p:nth-child(2) {
            flex: 1;
            word-break: break-all;
            padding-right: 10px;
            overflow-y: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            &::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
            }

            &::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 4px;
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: #535353;
            }

            &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              border-radius: 4px;
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: #ededed;
            }
          }
        }
        .word_four {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0px;
          bottom: 0px;
          .startup,
          .startup-examine,
          .startupDisable {
            width: 169px;
            height: 46px;
            background: #ff8201;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            margin-right: 70px;
            cursor: pointer;
          }
          .startup-examine {
            cursor: initial;
          }
          .startupDisable {
            background: #cecece;
            color: #fff;
            cursor: initial;
          }
          .invitation {
            width: 169px;
            height: 46px;
            background: #fff;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #3489fe;
            border: 1px solid #3489fe;
            margin-right: 20px;
            cursor: pointer;
          }
          .operate {
            width: 60px;
            height: 46px;
            background: #fff;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 28px;
            font-weight: 700;
            color: #999;
            border: 1px solid #999;
            cursor: pointer;
          }
          .word_four_one {
            span {
              font-weight: 700;
            }
            font-size: 14px;
            font-weight: 400;
            color: #ff0607;
            p:nth-child(1) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .two_content {
    width: 100%;
    display: flex;
    justify-content: center;
    .two_content_center {
      margin-bottom: 60px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 268px;
        //height: 482px;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        background: #ffffff;
        border-radius: 6px;
        margin-top: 30px;
        padding: 23px 14px 10px 14px;
        .left_one {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333333;
          margin-bottom: 15px;

          .ipsd {
            position: absolute;
            right: 0;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .active {
          color: #3489fe;
          position: relative;
          background: #e9f3ff;
          margin-bottom: 15px;
          border-radius: 8px;
          font-weight: 700;
        }
        i {
          margin-left: 27px;
          margin-right: 13px;
          font-size: 22px;
        }
        .left_one:last-child {
          border-bottom: 0px;
        }
      }
      .right {
        width: 1112px;
        margin-top: 30px;
      }
    }
  }
}
</style>