<template>
  <div class="topicDetails">
    <div class="mBox">
      <h4>{{ topicDetail.title }}</h4>
      <div style="border-bottom: 1px dashed #ebebeb; padding: 26px 0 30px">
        <div class="cBox">
          <div class="common">
            <!-- //头像 -->
            <div class="photo">
              <img src="" alt="" />
              {{ topicDetail.username }}
            </div>

            <!-- 话题发起人信息 -->
            <div class="info">
              <p>
                <span>发起于{{ topicDetail.create_time }}</span>
                <span v-if="topicDetail.audit_status == 4">{{ topicDetail.visit_count }}浏览</span>
                <span v-if="topicDetail.audit_status == 4">{{ topicDetail.reply_count }}评论</span>
              </p>
            </div>
          </div>
          <!-- //操作 -->
          <div class="btn" v-if="topicDetail.audit_status == 1">
            <el-button type="primary" size="small" @click="statusGambit(4)">通过</el-button>
            <el-button type="danger" size="small" style="margin-left: 10px" @click="statusGambit(5)">不通过</el-button>
          </div>
        </div>
      </div>
      <!-- //内容 -->
      <div class="content rich_text_div_img" v-html="topicDetail.content">
      </div>
      <div v-if="topicDetail.audit_status == 4">
        <!-- //表格 -->
        <div class="tBox">
          <img src="@/assets/images/workAdmin/pl.png" alt="" />
          <span>全部评论</span>
          <span>（{{ total }}）</span>
        </div>
        <!-- //删除操作 -->
        <div class="btnBox">
          <el-button size="medium" type="primary" @click="delMore()">批量删除</el-button>
        </div>
        <div class="Tab">
          <el-table :data="tableData" tooltip-effect="dark" :header-cell-style="{ background: '#f5f5f5' }" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column label="ID" align="center" prop="id" width="80" />
            <el-table-column label="评论者" align="center" prop="username" width="120" />
            <el-table-column label="回复" align="center" prop="reply_count" width="100" />
            <el-table-column label="内容" align="center" prop="content" width="210">
              <template slot-scope="scope">
                <span>{{ scope.row.content }}</span>
                <span style="color: #66b1ff; margin-left: 10px; cursor: pointer" @click="toShow(scope.row)">详情</span>
              </template>
            </el-table-column>
            <el-table-column label="点赞数" align="center" prop="like_count" width="100" />
            <el-table-column label="时间" align="center" prop="create_time" width="180" />
            <el-table-column label="操作">
              <template slot-scope="scope">
                <span @click="toDel(scope.row.id)" style="color: #66b1ff">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- margin:15px 0; -->
          <div v-if="total" class="block" style="width: 400px; margin-top: 10px">
            <el-pagination style="margin-left: 400px" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>

        <!-- //详情弹框 -->
        <el-dialog title="话题内容详情" :visible.sync="dialog" style="width: 1200px; margin: 0 auto">
          <p v-html="commitDetail.content" class="rich_text_div_img"></p>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      //审核状态
      status: 0,
      total: 0,
      //话题详情  prev-text='上一页' next-text='下一页'
      topicDetail: "",
      tableData: [],
      ids: [],

      //评论详情
      commitDetail: "",

      //评论列表
      form: {
        id: "",
        limit: 10,
        page: 1,
      },
      //  commentList:
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //初始化
    this.init();
  },

  computed: {},
  methods: {
    //初始化
    init() {
      this.getTopicDetail();
      this.getTopicComment();
    },

    //显示弹框
    toShow(row) {
      this.dialog = true;
      let obj = {
        id: row.id,
        gambit_id: this.$route.query.id,
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios
        .get("TeachingStudioGambit/detail_reply", {
          params: obj,
        })
        .then((res) => {
          //  commitDetail
          this.commitDetail = res.data.data;
          console.log(res, "下牛扣开破开票");
        });
    },

    //批量删除
    delMore() {
      if (this.ids.length != 0) {
        let obj = {
          ids: this.ids,
          teaching_studio_id: localStorage.getItem("studioId"),
        };
        this.$axios
          .delete("TeachingStudioGambit/reply_del", { params: obj })
          .then(() => {
            this.$message.success("删除成功");
            //刷新回复的评论列表
            this.getTopicComment();
          });
      } else {
        this.$message.warning("请勾选要删除的数据。");
      }
    },

    //审核
    statusGambit(type) {
      let obj = {
        audit_status: type,
        teaching_studio_id: localStorage.getItem("studioId"),
        id: this.$route.query.id,
      };
      this.$axios.post("TeachingStudio/statusGambit", obj).then(() => {
        if (type == 4) {
          this.$message.success("审核通过");
        } else {
          this.$message.success("审核不通过");
        }
        this.$router.push("/TopicManage");
      });
    },

    //删除
    toDel(id) {
      let obj = {
        ids: this.ids.push(id),
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios
        .delete("TeachingStudioGambit/reply_del", { params: obj })
        .then(() => {
          this.$message.success("删除成功");
          //刷新回复的评论列表
          this.getTopicComment();
        });
    },
    //勾选赋值
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id;
      });
      // console.log(this.ids,"勾线");
    },

    //获取话题详情
    getTopicDetail() {
      let id = this.$route.query.id;
      this.$axios.get(`TeachingStudio/gambit/${id}`).then((res) => {
        this.topicDetail = res.data.data;
        this.status = res.data.data.audit_status;
        // console.log(res,"详情");
      });
    },

    //获取话题评论列表
    getTopicComment() {
      this.form.id = this.$route.query.id;
      this.$axios
        .get("TeachingStudio/gambitComment/list", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          console.log(res, "评论列表");
        });
    },
    //  ---------分页----------
    handleSizeChange(val) {
      this.form.limit = val;
      this.getTopicComment();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTopicComment();
    },
  },
};
</script>
<style lang="less" scoped>
.topicDetails {
  .mBox {
    width: 1045px;
    // height: 2149px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background: #ffffff;
    // border: 1px solid #ebebeb;
    border-radius: 6px;
    padding: 40px 30px 39px 30px;
    h4 {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      line-height: 44px;
    }
    .cBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .common {
        display: flex;
        align-items: center;
        p {
          margin-left: 10px;
          span {
            margin-right: 15px;
          }
        }
      }

      //按钮
      .btn {
      }
    }

    .content {
      line-height: 32px;
      padding-top: 30px;
      padding-bottom: 42px;
    }

    //标题盒子
    .tBox {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    //删除操作
    .btnBox {
      margin: 20px 0;
    }
  }
}
</style>
