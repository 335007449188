<template>
  <div>
    <el-form ref="problem" :rules="problemRules" :model="problem" label-width="100px" class="problem-form">
      <el-form-item label="类型：" prop="type">
        <el-radio-group v-model="problem.type" @change="problemTypeChange">
          <el-radio :label="item.value" v-for="(item, index) in problemTypeOption" :key="index"
            v-if="item.value == problem.type">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题：" prop="title">
        <el-input placeholder="请输入标题" v-model="problem.title"></el-input>
      </el-form-item>
      <el-form-item label="说明：" prop="description">
        <Editor v-model="problem.description"></Editor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createButton(1, 'problem')">
          立即创建
        </el-button>
        <el-button type="info" @click="cancelButton()"> 取消 </el-button>
      </el-form-item>
    </el-form>
    <div class="problem-mode">
      <h3 class="mode-title">或选择以下方式创建</h3>
      <el-button type="primary" size="mini" @click="createButton(2, 'problem')">
        引入已有题目
      </el-button>
    </div>
  </div>
</template>

<script>
import BasicInfo from "./basicInfo.js";
export default BasicInfo;
</script>

<style lang="less" scoped>
@import "./basicInfo.less";
</style>