<template>
  <!-- //投票和问卷提交之后返回答案内容文件 -->
  <div>
    <div v-for="(item,index) in statistics" :key="index">

      <div class="statistics-title">
        <span class="statistics-serial"><span class="serial-must" v-if="item.is_required==2">*</span>{{ index + 1 }}、</span>
        <div class="statistics-content rich_text_div_img" v-html="item.title"></div>
        <span class="statistics-type">
          [{{ item.type|subjectType(item.selected_type?item.selected_type:"") }}]
        </span>
      </div>

      <!--选择题和排序题 开始-->
      <DataTable :list="item.options" ref="listTable" :noPage="false" v-if="item.type==1 || item.type==3">
        <el-table-column align="center" label="选项" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="display:flex">
              <!-- //选中 -->
              <el-radio v-if="scope.row.selected" style="margin-right:-22px" v-model="scope.row.selected" :label="true"><span></span></el-radio>
              <span style="margin-right:30px"></span>
              <p v-if="scope.row.allow_fill_in==2">
                {{scope.row.content}}
                <span class="statistics-details" @click="details(scope.row.id,item.id,item.title)">[详情]</span>
              </p>
              <p v-else>{{scope.row.content}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="selected_count" align="center" label="小计" show-overflow-tooltip v-if="item.type==1"></el-table-column>
        <el-table-column prop="ranks_count" align="center" label="平均综合得分" show-overflow-tooltip v-if="item.type==3"></el-table-column>
        <el-table-column align="center" label="比例" v-if="item.type==1">
          <el-progress :text-inside="true" :stroke-width="20" :percentage="Number(scope.row.selected_rate)" slot-scope="scope"></el-progress>
        </el-table-column>
        <el-table-column align="center" label="比例" v-if="item.type==3">
          <el-progress :text-inside="true" :stroke-width="20" :percentage="Number(scope.row.ranks_rate)" slot-scope="scope"></el-progress>
        </el-table-column>
      </DataTable>
      <!--选择题和排序题 结束-->

      <!--填空题 开始-->
      <DataTable :list="item.options" ref="listTable" :noPage="false" v-if="item.type==2">
        <el-table-column align="center" label="选项" show-overflow-tooltip>
          <template slot-scope="scope">第 {{scope.$index+1}} 填空题</template>
        </el-table-column>
        <el-table-column align="center" label="详情" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="statistics-details" @click="details(scope.row.id,item.id,item.title)">[详情]</span>
          </template>
        </el-table-column>
      </DataTable>
      <!--填空题 结束-->

    </div>

    <!--弹出层详情 开始-->
    <el-dialog :visible.sync="dialogFormVisible">
      <div class="statistics-dialog">
        <div v-html="dialogFormTitle" class="statistics-dialogTitle rich_text_div_img"></div>
        <el-input placeholder="搜索文本内容" v-model="filter.search_content">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <DataTable :fetch="getSubjectWritingStatistics" :filter="filter" ref="listTable" v-if="dialogFormVisible">
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">{{ scope.$index+1 }}</template>
          </el-table-column>
          <el-table-column prop="option_content" align="center" label="文本内容" show-overflow-tooltip></el-table-column>
        </DataTable>
      </div>
    </el-dialog>
    <!--弹出层详情 结束-->

  </div>
</template>

<script>
import SubjectStatistics from "./subjectStatistics.js";
export default SubjectStatistics;
</script>

<style lang="less" scoped>
@import "./subjectStatistics.less";
</style>