<template>
  <div class="public_css_module researchBox personal_right_box">

    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的教研组</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'" @click="showData(item.id)">
          <span v-if="item.id != 2">{{ item.name }}</span>
          <el-dropdown trigger="click" v-if="index == 1" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">审核中</el-dropdown-item>
              <el-dropdown-item command="4">已通过</el-dropdown-item>
              <el-dropdown-item command="5">未通过</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <router-link to="/createResearch" class="rightLink">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>创建教研组</span>
      </router-link>
    </div>

    <div class="item_list_1_box" v-loading="listLoading">
      <div class="item_1_item" v-for="(item, index) in listItem" :key="index" @click.stop="toDetailPage1(item.id)">
        <div class="item_1_top fxaic curp">
          <div class="item_1_img">
            <img :src="item.cover_url" alt="" />
            <!-- <div v-if="item.audit_status == 1" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
            <div v-if="item.audit_status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
            <div v-if="item.audit_status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
            <div v-if="item.audit_status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
            <div v-if="item.audit_status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
            <div v-if="item.audit_status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div> -->

            <div v-if="item.audit_status == 1" class="tios_div_box_1_div tios_div_box_2_div">待审核</div>
            <div v-if="item.audit_status == 4" class="tios_div_box_1_div tios_div_box_3_div">已通过</div>
            <div v-if="item.audit_status == 5" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
          </div>
          <div class="item_1_msg fx1">
            <div class="title_1_div">
              <template v-if="false">
                <span v-if="item.audit_status == 1" class="status_1_box zhuantai_1">审核中</span>
                <span v-if="item.audit_status == 4" class="status_1_box zhuantai_1">已通过</span>
                <span v-if="item.audit_status == 5" class="status_1_box zhuantai_2">未通过</span>
                <span v-if="item.audit_status == 6" class="status_1_box zhuantai_2">已过期</span>
              </template>
              <span class="name_1_div ellipsis_1">{{item.name || "-"}}</span>
            </div>
            <div class="tag_1_box fxaic">
              <template v-if="item.user_activity">
                <div v-if="item.user_activity == 1" class="tag_item tag_2_item">我创建的</div>
                <div v-if="item.user_activity == 2" class="tag_item tag_3_item">我参与的</div>
              </template>
              <template v-else-if="item.my_type_text">
                <div v-if="item.my_type_text=='我创建的'" class="tag_item tag_2_item">我创建的</div>
                <div v-if="item.my_type_text=='我参与的'" class="tag_item tag_3_item">我参与的</div>
              </template>
              <div class="tag_item">{{ item.type_text || "-" }}</div>
              <div class="tag_item tag_1_item">{{ item.stage_name || "-" }}{{ item.subject_name || "-" }}</div>
            </div>
            <div class="name_tab_box">
              <div class="name_box fxaic">
                <div class="name_name">组长: {{ item.username || "0" }}</div>
                <div class="name_name">成员: {{ item.user_count || "0" }}</div>
                <div class="name_name">资源: {{ item.result_count || "0" }}</div>
              </div>
              <div class="name_box fxaic">
                <div class="name_name">活动: {{item.activity_count || "0" }} 【<span class="name_1_name">{{item.activity_open || "0" }}</span>个已开展、<span
                    class="name_2_name">{{item.activity_develop || "0" }}</span>个待审核】
                </div>
                <el-dropdown v-if="(item.is_go_examine_role==1)  || (item.is_update_role==1) || (item.is_delete_role==1)" trigger="hover">
                  <span class="name_xiala_tag" @click.stop="">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="item.is_go_examine_role==1" divided @click.native="teaching_to_page(item, 1)">
                      <i class="el-icon-position"></i>去审核
                    </el-dropdown-item>
                    <el-dropdown-item v-if="item.is_update_role==1" divided @click.native="editClick_edit(item.id)">
                      <i class="el-icon-edit-outline"></i>编辑
                    </el-dropdown-item>
                    <el-dropdown-item v-if="item.is_delete_role==1" divided @click.native="delClick_del(item.id)">
                      <i class="el-icon-delete"></i>删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.activity_data && item.activity_data.length" class="item_1_list">
          <div class="item_1_1_item" v-for="(ite_2, inde_2) in item.activity_data" :key="inde_2">
            <div class="item_1_1_div"> <i class="icon_div el-icon-s-flag"></i> </div>
            <div class="item_1_2_div ellipsis_1">
              <span class="item_1_3_span">{{ite_2.username || "-"}}</span>
              <span class="item_1_5_span">发布了活动 :</span>
              <span class="item_1_4_span">{{ite_2.name || "-"}}</span>
            </div>
          </div>
        </div>

      </div>

      <CommonEmpty v-if="!listLoading && !listItem.length" hVal="200px"></CommonEmpty>

      <div v-show="total" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="getList.page"
          :page-sizes="[10,20,30,40,50]" :page-size="getList.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import { localGet, localSet, localRemove } from '@/utils/utils.js';
export default {
  data() {
    return {
      listLoading: true, //表格Loading
      user_id: localGet("unserInfoData")?.id,
      currentNum: 1,
      total: 0,
      uid: 0,
      getList: {
        limit: 10,
        page: 1,
        //  1：全部 2：我创建 3：我加入
        my_type: 1,
        audit_status: "", // 0全部、1审核中 、4通过、5未通过
      },
      listItem: [],
      selectData: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "我创建的",
        },
        {
          id: 3,
          name: "我加入的",
        },
        {
          id: 4,
          name: "待我审核",
        },
      ],
    };
  },
  created() {
    let query = this.$route.query;
    if (query.pageType) {
      this.$set(this.getList, "my_type", Number(query.pageType));
      this.currentNum = Number(query.pageType);
    };
    if (query.statusType) {
      this.$set(this.getList, "audit_status", Number(query.statusType));
    };
    // 从缓存得到id
    this.uid = localStorage.getItem("id");
    this.init();

    if (this.$route.query.id == 2) {
      this.myCreate();
    } else if (this.$route.query.id == 3) {
      this.myJoin();
    }
  },
  methods: {
    showData(id) {
      if (id != 2) {
        this.currentNum = id;
        this.$set(this.getList, "my_type", id);
        this.$set(this.getList, "page", 1);
        this.$set(this.getList, "audit_status", "");
        this.init();
      }
    },
    //下拉
    handleCommand(type) {
      this.currentNum = 2;
      this.$set(this.getList, "my_type", 2);
      this.$set(this.getList, "page", 1);
      this.$set(this.getList, "audit_status", type);
      this.init();
    },
    async init() {
      this.listLoading = true;
      try {
        let res = await this.$axios.get("/TeacherGroup/get_my_list", {
          params: this.getList,
        });
        this.listItem = res.data.data.data;
        this.total = res.data.data.total;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      } catch (error) {
        this.listItem = [];
        this.total = 0;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
    // 教研 操作 跳转
    teaching_to_page(item, num) {
      switch (Number(num)) {
        case 1:
          this.toDetailPage1(item.id)
          break;
        default:
          break;
      }
    },
    //跳转到编辑页
    editClick_edit(id) {
      this.$router.push({
        path: "/createResearch",
        query: { groupId: id, type: "edit" },
      });
    },
    //删除教研组
    delClick_del(id) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_6(id);
      }).catch(() => { });
    },
    //删除教研组
    async delActivity_6(id) {
      let res = await this.$axios.delete("TeacherGroup/delGroup", { params: { id: id, }, });
      this.$message({
        message: "删除成功",
        type: "success",
      });
      this.init();
    },
    //跳转到详情
    toDetailPage1(id) {
      this.$router.push({
        path: "/groupdetailshouye",
        query: {
          groupId: id,
        },
      });
    },
    //全部
    getAll() {
      this.currentNum = 0;
      this.getList.my_type = "1";
      this.init();
    },
    // 我创建的
    myCreate() {
      this.currentNum = 1;
      this.getList.my_type = "2";
      this.init();
    },
    myJoin() {
      this.currentNum = 2;
      this.getList.my_type = "3";
      this.init();
    },
    handleSizeChange(val) {
      this.$set(this.getList, "page", 1);
      this.$set(this.getList, "limit", val);
      this.init();
    },
    handleCurrentChange(val) {
      this.$set(this.getList, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 90px;
  height: 62px;
  top: -20px;
  right: -36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  transform: rotate(45deg);
  background-color: #ff8201;
  color: #fff;
  &.tios_div_box_2_div {
    background-color: #0080ff;
  }
  &.tios_div_box_3_div {
    background-color: #ff8201;
  }
  &.tios_div_box_4_div {
    background-color: #ff0607;
  }
  &.tios_div_box_5_div {
    background-color: #0080ff;
  }
  &.tios_div_box_6_div {
    background-color: #ff0607;
  }
  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }
}
.researchBox {
  width: 100%;
  background-color: #fff;
}
.item_list_1_box {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  min-height: 100px;
  .item_1_item {
    width: 545px;
    min-height: 142px;
    // height: 214px;
    border-radius: 6px;
    padding: 15px 16px;
    box-sizing: border-box;
    margin-right: 18px;
    margin-top: 20px;
    background-color: #f1f6f9;
    border: 1px solid #f1f6f9;
    position: relative;
    &:hover {
      background: rgba(241, 246, 249, 1);
      border: 1px solid rgba(170, 201, 219, 1);
      box-shadow: 0 2px 20px 0 rgba(89, 142, 174, 0.31);
    }
    &:nth-child(even) {
      margin-right: 0;
    }
    &:nth-child(1) {
      margin-top: 10px;
    }
    &:nth-child(2) {
      margin-top: 10px;
    }
    .item_1_img {
      width: 168px;
      height: 112px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 16px;
      position: relative;
      > img {
        width: 168px;
        height: 112px;
        border-radius: 6px;
      }
      .tios_2_box_div {
        bottom: 0;
        position: absolute;
        text-align: center;
        height: 24px;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 24px;
        font-weight: normal;
        color: rgba(255, 255, 255, 1);
      }
    }
    .title_1_div {
      display: flex;
      .status_1_box {
        display: inline-block;
        width: 56px;
        height: 22px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin-right: 10px;
        background: #ff8201;
        color: #fff;
        &.zhuantai_1 {
          background-color: #3489ff;
        }
        &.zhuantai_2 {
          background-color: #ff0607;
        }
      }
      .name_1_div {
        flex: 1;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
    }
    .item_1_msg {
      height: 112px;
      display: flex;
      flex-direction: column;
    }
    .item_1_top {
      width: 100%;
    }
    .item_1_list {
      width: 100%;
      padding-top: 10px;
      .item_1_1_item {
        display: flex;
        align-items: center;
        height: 16px;
        margin-top: 10px;
        .item_1_1_div {
          width: 16px;
          height: 16px;
          background: rgba(52, 137, 255, 1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon_div {
            color: #fff;
            font-size: 12px;
            transform: scale(0.8);
          }
        }
        .item_1_2_div {
          flex: 1;
          font-size: 14px;
          line-height: 16px;
          font-weight: normal;
          color: #3489ff;
        }
        .item_1_3_span {
          margin-left: 6px;
          color: #333333;
        }
        .item_1_5_span {
          margin-left: 6px;
          color: #999999;
        }
        .item_1_4_span {
          margin-left: 6px;
        }
      }
    }
    .tag_1_box {
      margin-top: 10px;
      .tag_item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
        color: rgba(4, 147, 255, 1);
        padding: 0 8px;
        border: 1px solid #0493ff;
        color: #0493ff;
        margin-right: 10px;
      }
      .tag_1_item {
        border: 1px solid #51b875;
        color: #51b875;
      }
      .tag_2_item {
        background-color: #3489fe;
        color: #fff;
      }
      .tag_3_item {
        background-color: #52b876;
        border: 1px solid #51b875;
        color: #fff;
      }
    }
    .name_tab_box {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      .name_box {
        .name_name {
          margin-top: 8px;
          flex: 1;
          font-size: 14px;
          line-height: 14px;
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
          .name_1_name {
            font-weight: bold;
            color: #52b876;
          }
          .name_2_name {
            font-weight: bold;
            color: #fe7019;
          }
        }
        .el-dropdown {
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .name_xiala_tag {
          width: 50px;
          text-align: right;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>