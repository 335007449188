<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth2">
      <div class="public-width">
        <div class="public-width pb40">
          <div class="mainBox7 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aserviceImg/meiti.png" alt="">
            </div>
            <div class="jidibox">
              <img src="@/assets/banner_img/aserviceImg/logo.png" alt="">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}

.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.mainBox7 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .jidibox {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    img {
      margin: 0 50px;
    }
  }
}
</style>