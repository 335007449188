<template>
  <div class="basicinfo">
    <!-- <div class="one_content">
      <div class="images">
        <img :src="detailData.cover_url" />
        <!-- <div class="ts">
            <img src="@/assets/images/teacherStudio/ts.png" alt="">
            <span>投诉</span>
        </div> -->
    <!-- 投诉 --
        <div class="textbox" v-if="detailData.user_id != user_id">
          <comPlain :type="2" :id="detailData.id" :is_from="is_from" />
        </div>
      </div>
      <div class="one_word">
        <div class="titleList">
          <p>{{ detailData.name }}</p>
          <div class="status" :class="
              detailData.audit_status_name == '已通过'
                ? 'lv'
                : detailData.audit_status_name == '未通过'
                ? 'un'
                : detailData.audit_status_name == '审核中'
                ? 'zhong'
                : 'mei'
            " v-if="is_from == 1 && detailData.user_id == user_id">
            {{ detailData.audit_status_name }}
          </div>
        </div>
        <div class="mb10">
          <p class="ellipsis-1" style="font-size: 16px; color: rgba(153, 153, 153, 1)">
            {{ detailData.lable }}
          </p>
        </div>
        <p>
          活动时间：{{ detailData.start_date }} {{ detailData.start_time }} 至
          {{ detailData.end_date }} {{ detailData.end_time }}
        </p>
        <div class="have_flex">
          <p>
            <span>活动类型：</span>{{ detailData.type == null ? "" : detailData.type }}
          </p>
          <p><span>开展方式：</span>{{ detailData.implement_type }}</p>
          <p>
            <span>学段学科：</span>
            {{ detailData.stage_name == null ? "" : detailData.stage_name
            }}{{
              detailData.subject_name == null ? "" : detailData.subject_name
            }}
          </p>
        </div>
        <div class="have_flex">
          <p><span>活动发起人：</span>{{ detailData.initiator_name || "" }}</p>
          <p><span>参与人数：</span>{{ detailData.user_count }}</p>
        </div>
        <!--<p style="line-height: 28px" >
           <span
            >参与权限：

            <span
              style="color: #ff8201"
              v-if="detailData.join_permission.state"
              >{{
                detailData.join_permission.state == 1 ? "可参与" : "不可参与"
              }}</span
            >
          </span> 
        </p>--
        <div class="fc">
          <!--  --
          <div class="cursor my_join" @click="onJoin()" v-if="showJoin">
            我要参与
          </div>

          <div class="Row3 wait" v-if="isAuditData">待审批加入</div>

          <!-- <div class="Row3 wait" v-if="detailData.join_permission.state == 0">
            待审批加入{{ is_from == 1 ? "教研组" : "工作室" }}
          </div> --
          <new-invitation :access_code="detailData.access_code" :is_height="showJoin"></new-invitation>

          <!-- //参与活动成员待审核列表 --
          <div v-if="showCheck">
            <el-button type="primary" size="medium " style="margin-left: 20px" @click="screenWay(2)" plain>待审核报名成员>>
              <span style="color: red">({{ checkList.length }})</span>
            </el-button>
          </div>
          <!-- <el-badge :value="checkList.length" v-if="showCheck">
            <p class="cursor wait_check" >
            </p>
          </el-badge> --
        </div>
      </div>
      <!-- 投屏 --
      <div class="listOne" @click="screenWay(1)" v-if="!(detailData.user_id == user_id && detailData.audit_status != 4)">
        <img src="@/assets/images/Projection_icon.png" alt="" />
      </div>
    </div> -->
    <div class="two_content">
      <ul class="uu1">
        <li @click="onClickNav(0)" :class="{ active: current == 0 }" class="cursor">
          活动描述
        </li>
        <li @click="onClickNav(1)" :class="{ active: current == 1 }" class="cursor">
          活动成果
        </li>
        <li @click="onClickNav(2)" :class="{ active: current == 2 }" class="cursor">
          活动成员
        </li>
      </ul>
      <!-- 活动描述 -->
      <div v-html="detailData.description" v-if="current == 0" class="desc" style="overflow: auto"></div>

      <!-- 活动成果 -->
      <div class="bottomBox" v-if="current == 1">
        <ul>
          <template v-for="(item, index) in cgList">
            <li v-if="detailData.join_TeachingStudio || index == 0" :key="index" class="" @click="onSearchCg(item.id)">
              <img :src="item.id == active ? item.icon1 : item.icon2" alt="" />
              <span>{{ item.name }}</span>
            </li>
          </template>
        </ul>
        <div class="Art_right">
          <div class="demoBox">
            <!-- //循环 -->
            <!-- @click="onGoDetail(item.id, item.resource_id)" -->
            <div v-for="(item, index) in ResData" :key="index" class="forBox cursor">
              <div class="oneBox">
                <div class="topImg">
                  <img :src="onShowImages(item.mime)" alt="" />
                </div>
                <div class="bottomTxt">{{ item.sources_name }}</div>
              </div>
              <div class="twoBox">
                <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext"
                  :itemData="item" :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time" fileNav="-">
                  <el-link targer="_blank" :underline="false">
                    <p>{{ item.title }}</p>
                  </el-link>
                </new-viewonline>
                <p>
                  {{ item.pharse_name || "-" }}
                  {{ item.subject_name || "-" }} /
                  {{ item.grade_name  || "-" }}
                </p>
                <p>
                  <span>上传时间：{{ format(item.create_time) }}</span>
                  <span>收藏次数：{{ item.is_collect }}</span>
                  <span> 文件大小：{{ item.size | fileSizeFilter  }}</span>
                </p>
              </div>
            </div>
            <CommonEmpty v-if="ResData.length == 0" hVal="200px"></CommonEmpty>
          </div>
          <!-- 分页 -->
          <div v-if="total" class="block">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
              :page-sizes="[5, 10, 20, 30]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 活动成员 -->
      <ul class="member-list" v-if="current == 2">
        <li class="member-list-item" v-for="(item,index) in memberList" :key="index">
          <div class="member-cover">
            <img :src="item.photo_url" />
          </div>
          <div class="member-content">
            <div class="fsbc">
              <h3 class="member-name">{{item.realname}}</h3>
              <span class="fs14">{{item.stage_text}} - {{item.subject_text}}</span>
            </div>
            <p class="member-unit">{{item.organization_name}}</p>
            <!-- <span class="member-chat">私信TA</span> -->
          </div>
        </li>
      </ul>
    </div>
    <!-- 投屏/审核报名成员弹窗 -->
    <MyDialog :open="open" :title="title" @onClose="onClose" :width="width">
      <div>
        <ListOne :typeVal="is_who" :activityId="+activityId" :list="checkList" @onCallBack="onWaitCheckList" />
      </div>
    </MyDialog>
    <!-- 申请加入工作室弹窗 -->
    <JoinStudio ref="joinStudioRef" @onCallBack="getDetail" title="温馨提示" :is_who="is_from == 1 ? 4 : 2" :group_id="+detailData.group_id" />
  </div>
</template>

<script>
import {
  WaitCheckList,
  ApplyJoinActivity,
  StudipApplyJoinActivity,
  SecretLogin,
} from "@/api/teacherStudio";
import JoinStudio from "@/components/joinStudio/index.vue";
import Clipboard from "clipboard";
import { onShowImages } from "@/utils/utils";
import ListOne from "@/components/CommonList/ListOne.vue";
//导入js,使用解构
export default {
  components: { ListOne, JoinStudio },
  props: {
    // is_from: {
    //   type: Number,
    //   default: 1,
    // },
  },
  data() {
    return {
      //活动id
      activityId: "",
      //活动详情数据
      detailData: {},
      // 邀请对话框
      invitationDilog: false,
      // 当前页面的地址
      dizhi: window.location.href,
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
      current: 0,
      ResData: [],
      cgList: [
        {
          name: "全部成果",
          id: 0,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
        {
          name: "我的成果",
          id: 1,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
      ],
      active: 0,
      open: false,
      title: "请选择要投屏的电脑或教师",
      width: "38%",
      // 1教研活动模块，2四名工作室活动模块
      is_who: 1,
      form: {
        page: 1,
        limit: 5,
        teaching_studio_id: localStorage.getItem("studioId"),
        type: 2, //来源：0：全部 1：教研直播 2：教研活动 3：研修视频
        all_my: 0, //0 全部成果 1 我的成果
        id: "",
        source_id: 0, //	资源类型id
      },
      total: 0,
      user_id: localStorage.getItem("id"),
      checkList: [],
      memberForm: {
        is_personid: this.$route.query.is_from ? this.$route.query.is_from : "",
        activity_id: 0,
        limit: 100000,
        page: 1
      },
      memberList: []
    };
  },
  created() {
    if (this.$route.query.key) {
      SecretLogin({ key: this.$route.query.key }).then((res) => {
        console.log(res.data);
        const dataObj = res.data;
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj));
        //把瓣学网登录的权限存在缓存里
        localStorage.setItem("aes_code", dataObj.aes_code);

        //将用户所有信息存进缓存
        localStorage.setItem("user_name", dataObj.username);

        //把token存在缓存里
        localStorage.setItem("token", dataObj.token);

        //把用户id存进缓存里
        localStorage.setItem("id", dataObj.id);

        this.isShow = 1;
        //把登录显示放进缓存里
        localStorage.setItem("isShow", this.isShow);
        //把用户头像放进缓存
        localStorage.setItem("photo_url", dataObj.photo_url);
        setTimeout(() => {
          this.$router.push({
            path: `/basicinfo?module=${this.$route.query.module}&activityId=${this.$route.query.activityId}&is_from=${this.$route.query.is_from}`,
          });
          location.reload();
        }, 500);
      });
    } else {
      this.init();
    }
  },
  filters: {
    showImg(ext) {
      return onShowImages(ext);
    },
  },
  computed: {
    //判断是否是组长显示，参与活动成员审核
    showCheck() {
      //is_from==1代表是教研组活动    当前用户是组长  且活动审核通过
      if (
        this.is_from == 1 &&
        this.detailData.user_id == this.user_id &&
        this.detailData.audit_status == 4 &&
        this.detailData.sign_up == 1
      ) {
        return true;
      } else {
        return false;
      }
    },

    //判断是否显示待审批
    isAuditData() {
      //有参与权限   已报名    并未审核
      if (
        this.detailData.checkUserJoin == 1 &&
        this.detailData.join_TeachingStudio
      ) {
        return true;
      } else {
        return false;
      }
    },

    // showJoin() {
    //   let token = localStorage.getItem("token");
    //   //不是创建者   未参与    有权限参与
    //   if (
    //     this.detailData.user_id != this.user_id &&
    //     !this.detailData.join_TeachingStudio &&
    //     this.detailData.join_permission.state == 1 &&
    //     token
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },

    //判断是否能编辑
    isEdit() {
      let uid = localStorage.getItem('id');
      if (uid == this.resourceData.user_id) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    //时间戳转化
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },

    //活动投屏
    screenWay(type) {
      // 从缓存中得到用户名
      //   let userName = localStorage.getItem("user_name");
      //将用户名使用unicode编码
      //let b= setUnicode(userName);
      //   let b = escape(userName);
      //   window.open("DCYJTP://Type:1_User:" + b);

      if (type === 1) {
        if (!this.detailData.join_TeachingStudio) {
          return this.$message.warning("请先报名参与");
        }
        // 投屏
        this.title = "请选择要投屏的电脑或教师";
        this.is_who = 1;
        this.width = "38%";
      } else {
        // 审核报名成员
        this.title = "审核报名成员";
        this.is_who = 2;
        this.width = "621px";
      }
      this.open = true;
    },
    // 待审核列表
    onWaitCheckList() {
      let obj = {
        activity_id: this.activityId,
        limit: 5000,
        page: 1,
      };
      WaitCheckList(obj).then((res) => {
        if (res.data.length) {
          this.checkList = JSON.parse(
            JSON.stringify(res.data).replace(/realname/g, "username")
          );
        }
      });
    },
    // 我要参与
    onJoin() {
      // state: null未加入工作室,0待审核,1审核通过,2审核不通过
      if (this.detailData.checkUserJoin == 3) {
        // 有参与权限
        let msg = "已发起参与报名，请耐心等候审核结果";
        if (this.is_from == 1) {
          this.onJoinGroupApi(msg);
        } else {
          this.onJoinApi(msg);
        }
      } else {
        // 无参与权限
        this.$refs.joinStudioRef.open = true;
      }
    },
    // 四名参与接口
    onJoinApi(msg) {
      StudipApplyJoinActivity({
        activity_id: this.activityId,
        user_id: this.user_id,
      }).then((res) => {
        msg && this.$message.success(msg);
        this.getDetail();
      });
    },
    // 教研组参与接口
    onJoinGroupApi(msg) {
      ApplyJoinActivity({
        activity_id: this.activityId,
        user_id: this.user_id,
      }).then((res) => {
        msg && this.$message.success(msg);
        this.getDetail();
      });
    },
    onCallBack() {
      //   let msg = "参与成功，请等待审核";
      let msg = "";
      if (this.is_from == 1) {
        this.onJoinGroupApi(msg);
      } else {
        this.onJoinApi(msg);
      }
    },
    // 关闭弹窗后的操作
    onClose() {
      this.open = false;
    },
    onShowImages,
    // 全部成果/活动成果
    onSearchCg(id) {
      this.active = id;
      this.form.page = 1;
      this.form.all_my = id;
      this.onGetAchievement();
    },
    // 详情
    onGoDetail(id, resource_id) {
      this.$router.push({
        path: "/resourcedetail",
        query: { id, resource_id },
      });
    },
    // 活动描述和成果切换
    onClickNav(i) {
      // let flat= localStorage.getItem("id") == this.detailData.user_id? true : false;
      // if (!this.detailData.join_TeachingStudio) {
      //   return this.$message.warning("您无查看权限");
      // }
      this.current = i;
    },
    //初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.form.id = this.$route.query.activityId;
      this.is_from = this.$route.query.is_from || 1;
      this.getDetail();
      this.getMember();
      if (this.is_from == 1) {
        // 待审核列表
        this.onWaitCheckList();
      } else {
        this.onGetAchievement();
      }
    },
    // 请求后台获取活动详情
    getDetail() {
      //教研组活动、个人活动
      if (this.is_from == 1) {
        this.$axios.get(`activity/${this.activityId}`).then((res) => {
          this.detailData = res.data.data;
          console.log(this.detailData, "活动详情");
          // is_who: 1个人，2教研组
          // this.detailData.is_who = 1;
          // permit:1所有人，2自定义
          // this.detailData.permit = 1;
          // sign_up:1开启，2关闭
          // this.detailData.sign_up = 1;
          this.onGetAchievement();
        });
      } else {
        this.$axios
          .get(`index/teachingStudioActivity/info`, {
            params: { id: this.activityId },
          })
          .then((res) => {
            this.detailData = res.data.data;
          });
      }
    },
    // 活动成果
    onGetAchievement() {
      let p = null;
      if (this.is_from == 1) {
        // console.log(this.detailData,"数据11111111111111111111111111111");
        p = this.$axios.get("TeacherGroup/get_resource_list", {
          params: {
            limit: this.form.limit,
            page: this.form.page,
            group_id: this.detailData.group_id ? this.detailData.group_id : "",
            // 来源：0：全部 1：教研直播 2：教研活动 3：研修视频
            type: 2,
            all_my: this.form.all_my,
            id: this.detailData.id,
            // source_id: this.form.source_id,
          },
        });
      } else {
        p = this.$axios.get(`TeachingStudio/get_resource_list`, {
          params: this.form,
        });
      }
      p.then((res) => {
        this.ResData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },

    //获取活动成员
    getMember() {
      this.memberForm.activity_id = this.activityId;
      this.$axios.get(`index/UserActivity/UserActivityLists`, { params: this.memberForm }).then((res) => {
        let data = res.data;
        if (data.code == 900) {
          this.memberList = data.data.data;
        }
      });
    },

    //分页
    handleSizeChange(val) {
      this.form.limit = val;
      this.onGetAchievement();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.onGetAchievement();
    },
    // 复制文字函数
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        console.log("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped lang="less">
.basicinfo {
  .one_content {
    width: 1045px;
    height: 274px;
    background: #ffffff;
    border: NaNpx solid #f0f0f0;
    border-radius: 6px;
    box-shadow: 0px 4px 7px 0px #f5f5f5;
    display: flex;
    // align-items: start;
    position: relative;
    .listOne {
      width: 0;
      height: 0;
      border-top: 54px solid #3489ff;
      border-left: 54px solid transparent;
      position: absolute;
      right: 0;
      top: 0;
      > img {
        transform: translate(-26px, -46px);
      }
    }
    .images {
      margin: 30px;
      border-radius: 6px;
      position: relative;
      > img {
        width: 380px;
        height: 214px;
        border-radius: 6px;
      }
      .textbox {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
    .one_word {
      margin-top: 38px;
      .titleList {
        line-height: 1;
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        p:nth-child(1) {
          font-size: 20px;
          font-weight: 700;
          max-width: 431px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .status {
          padding: 0 5px;
          height: 22px;
          background: #3489ff;
          border-radius: 4px;
          text-align: center;
          line-height: 22px;
          color: #fffefe;
          font-size: 14px;
          margin-left: 12px;
          &.lv {
            background-color: #ff8201;
          }
          &.un {
            background-color: #ff0607;
          }
          &.zhong {
            background-color: #3489ff;
          }
          &.mei {
            background-color: #ff0607;
          }
        }

        .listOne1 {
          box-sizing: border-box;
          width: 70px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          background: #ffffff;
          border: 1px solid #3489fe;
          border-radius: 4px;
          cursor: pointer;
          color: #3489fe;
        }
      }
      p:nth-child(2) {
        color: #ff7e24;
        margin-bottom: 24px;
      }
      p:nth-child(3) {
        font-size: 14px;
        color: #000000;
        margin-bottom: 10px;
        span {
          // margin-right: 40px;
          // display: inline-block;
          width: 190px;
        }
      }
      p:nth-child(4) {
        font-size: 14px;
        color: #000000;
        margin-bottom: 20px;
        span {
          // margin-right: 40px;
          // display: inline-block;
          width: 190px;
        }
      }
      .invitation_block {
        width: 100px;
        height: 34px;
        border: 1px solid #3489fe;
        border-radius: 4px;
        font-weight: 700;
        color: #3489fe;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .two_content {
    //width: 1045px;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    //margin-top: 20px;
    .uu1 {
      display: flex;
      padding-left: 30px;
      border-bottom: 1px solid #ececec;
      li {
        padding: 25px 0;
        margin-right: 58px;
        font-size: 20px;
        font-weight: 700;
        color: #666666;
        &.active {
          color: #3489fe;
          border-bottom: 3px solid #3489fe;
        }
      }
    }
    .desc {
      padding: 40px 0 30px 30px;
    }
    .bottomBox {
      padding: 30px;
      ul {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        li {
          margin-right: 10%;
          > img {
            vertical-align: text-bottom;
            margin-right: 16px;
          }
        }
      }
      .Art_right {
        // width: 1106px;
        // height: 999px;
        // padding: 0;
        // margin: 0;
        // box-sizing: border-box;
        // background: #ffffff;
        // border-radius: 4px;
        // margin-bottom: 60px;
        // padding: 32px 26px;
        .demoBox {
          .forBox {
            display: flex;
            align-items: center;
            border: 1px solid #ebebeb;
            margin-bottom: 20px;
            padding: 21px 21px;
            position: relative;
            border-radius: 6px;
            &:last-child {
              margin-bottom: 0;
            }
            .oneBox {
              cursor: pointer;
              width: 120px;
              height: 120px;
              background: #ffffff;
              border: 1px solid #ececec;
              border-radius: 7px;
              .topImg {
                height: 82px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 68px;
                  height: 48px;
                }
              }
              .bottomTxt {
                width: 100%;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ececec;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
            .twoBox {
              //第一行标题
              margin-left: 20px;
              p:nth-of-type(1) {
                font-size: 16px;
                text-align: left;
                color: #333333;
                margin-bottom: 14px;
              }
              //第二行
              p:nth-of-type(2) {
                // width: 235px;
                // height: 16px;
                font-size: 16px;
                text-align: left;
                color: #999999;
                margin-bottom: 16px;
              }

              p:nth-of-type(3) {
                // width: 423px;
                height: 12px;
                font-size: 14px;
                color: #999999;
                margin-top: 12px;
              }
              //标签
              .twolist {
                display: flex;
                div {
                  width: 69px;
                  height: 24px;
                  opacity: 1;
                  background: #ecf5ff;
                  border: 1px solid #d9ecff;
                  border-radius: 3px;
                  text-align: center;
                  color: #409eff;
                }
                div:nth-of-type(1) {
                  margin-right: 8px;
                }
              }

              span {
                margin-right: 10px;
              }
            }
          }
        }
        .block {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    }
  }
  .invitation {
    p:nth-child(1) {
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 30px;
    }
    p:nth-child(2) {
      margin-bottom: 30px;
      line-height: 30px;
    }
    p:nth-child(3) {
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 30px;
    }
    .three-input {
      width: 300px;
      height: 25px;
      line-height: 25px;
      margin-right: 10px;
      // padding:0px 12px;
    }
  }
  .my_join {
    width: 169px;
    height: 46px;
    line-height: 46px;
    background: #3489fe;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    margin-right: 20px;
    &:hover {
      background: #5da1fe;
    }
  }
  .Row3 {
    width: 150px;
    height: 34px;
    text-align: center;
    line-height: 36px;
    background: #ff8201;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-right: 20px;
    &.wait {
      background-color: #d4b08c;
    }
  }
  .wait_check {
    width: 166px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border: 1px solid #f56c6c;
    border-radius: 4px;
    color: #f56c6c !important;
    margin-bottom: 0 !important;
    margin-left: 18px;
    box-sizing: border-box;
    &:hover {
      border: 1px solid #e96767;
      color: #e96767;
    }
  }
}
.have_flex {
  display: inline-flex;
  p {
    min-width: 180px;
    span {
      color: #999;
    }
  }
}

.member-list {
  padding: 26px;
  display: flex;
  flex-wrap: wrap;
  .member-list-item {
    width: 339px;
    height: 78px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    padding: 10px 14px;
    margin: 0 20px 20px 0;
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    &:hover {
      .member-chat {
        display: flex !important;
      }
    }
    .member-cover {
      width: 58px;
      height: 58px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .member-content {
      flex: 1;
      overflow: hidden;
      position: relative;
      .member-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        padding: 6px 0;
      }
      .member-unit {
        font-size: 14px;
        color: #999999;
      }
      .member-chat {
        width: 72px;
        height: 32px;
        background: #3489fe;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 12px;
        display: none;
      }
    }
  }
}
</style>
