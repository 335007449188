<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth1">
      <div class="public-width">
        <div class="public-width pb40">
          <div class="mainBox3 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aserviceImg/keti.png" alt="">
            </div>
            <div class="ketiBox mt20">
              <h4 class="mb20">服务内容：</h4>
              <div class="ketiUl">
                <div class="ketili">
                  <p>课题基础指导</p>
                  <p>课题申报</p>
                  <p>课题开题</p>
                  <p>中期检查评审</p>
                  <p>课题论文发表支持</p>
                  <p>课题结题评审</p>
                </div>
                <div class="ketili">
                  <p>课题进阶指导</p>
                  <p>课题调研考察</p>
                  <p>课题研究培训</p>
                  <p>课题研究技术支持</p>
                  <p>参赛指导</p>
                  <p>课题成果展示交流</p>
                  <p>教学成果奖申报指导</p>
                </div>
                <div class="ketili">
                  <p>预期成果</p>
                  <p>一批骨干四名</p>
                  <p>优质示范课例</p>
                  <p>优质教学资源</p>
                  <p>论文研究报告</p>
                  <p>课题活动合集</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgWidth2">
      <div class="public-width">
        <div class="mainBox3">
          <div class="ketiBox pt20 pb30">
            <h4 class="mb20">特色亮点：</h4>
            <div class="ketiUl2">
              <div class="ketili2">
                <div>面向教师</div>
                <div>提供课题基础指导与课题研究进阶服务，涵盖课题研究全流程各个阶段的指导服务，
                  能够满足不同的教师的需求。</div>
              </div>
              <div class="ketili2">
                <div>面向学校</div>
                <div>制定了“强校-优校-名校”的分级指导计划，为学校提供差异化服务，
                  满足不同学校发展及师资队伍建设需求，通过课题帮助学校凝练特色、打造品牌。</div>
              </div>
            </div>
            <h4 class="mt30 mb20">分级指导计划：</h4>
            <p class="mt20 mb20">根据不同学校发展及教师科研队伍建设的需求，提供差异化服务，帮助学校打造品牌。分级计划内容如下：</p>
            <img src="@/assets/banner_img/aservice1/img6.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}


.mainBox3 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .ketiBox {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .ketiUl {
      display: flex;
      justify-content: space-between;

      .ketili {
        width: 405px;
        height: 523px;
        background: rgba(255, 255, 255, 1);
        padding: 10px;
        box-sizing: border-box;

        &:nth-child(2) {
          p {
            background: #fde8f0;

            &:first-child {
              background: #ea1e70;
            }
          }
        }

        p {
          text-align: center;
          width: 381px;
          height: 56px;
          line-height: 56px;
          background: rgba(20, 91, 185, 0.1);
          margin-bottom: 10px;
          font-size: 18px;
          color: #333333;

          &:first-child {
            width: 381px;
            height: 100px;
            line-height: 100px;
            background: rgba(20, 91, 185, 1);
            font-size: 30px;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .ketiUl2 {
      display: flex;
      justify-content: space-between;

      .ketili2 {
        width: 624px;
        height: 112px;
        display: flex;

        &:last-child {
          >div {
            &:first-child {
              background: #ea1e70;
            }

            &:last-child {
              background: #fde8f0;
            }
          }
        }

        >div {
          &:first-child {
            width: 112px;
            height: 112px;
            background: rgba(20, 91, 185, 1);
            font-size: 26px;
            color: rgba(255, 255, 255, 1);
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          &:last-child {
            width: calc(100% - 112px);
            height: 112px;
            background: #ebf1ff;
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
}
</style>