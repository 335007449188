<template>
  <div class="Certificate">
    <div class="w_layout ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>成果展示</span>
        </p>
      </div>

      <!-- 筛选 -->
      <div class="ItemBox">
        <div class="df dfb mb10">
          <p class="topT">成果展示</p>
          <template v-if="detailData.role != 0">
            <div class="df">
              <div class="pushTxt mr10" @click="pushBook(1)" v-if="workData.is_addUser == 1 || workData.role">
                新增研究成果
              </div>
              <div class="pushTxt" @click="pushBook(2)" v-if="workData.is_addUser == 1 || workData.role">
                新增研究课题
              </div>
            </div>
          </template>
        </div>
        <div class="item1">
          <div class="title">类别:</div>
          <div :class="index == active ? 'activeClass' : 'forList'" v-for="(item, index) in optiones" :key="index"
            @click="changType(index, item.id)">
            {{ item.name }}
          </div>
        </div>
        <div class="item1">
          <div class="title">级别:</div>
          <div :class="index == active1 ? 'activeClass' : 'forList'" v-for="(item, index) in optiones1" :key="index"
            @click="changType1(index, item.id)">
            {{ item.name }}
          </div>
        </div>
        <div class="item1" v-if="form.search_achievement_type == 1">
          <div class="title">类型:</div>
          <div :class="index == active2 ? 'activeClass' : 'forList'" v-for="(item, index) in optiones2" :key="index"
            @click="changType2(index, item.id)">
            {{ item.name }}
          </div>
        </div>
      </div>

      <!-- //下面循环列表 -->
      <div class="ListBox">
        <div class="forBox" v-for="(item, index) in  listData " :key="index">
          <div class="topBox cp" @click="toDetail(item)">
            <img :src="item.resource_url" alt="" />
            <div class="about" :class="item.status == 3 ? 'aboutFas' : ''" v-if="item.status == 1 || item.status == 3">
              {{ item.status == 1 ? '待审核' : '未通过' }}
            </div>
            <div class="about aboutLeft" v-if="item.top == 1 && item.status != 1 && item.status != 3">
              置顶
            </div>
          </div>
          <div class="BtnBox">
            <h4 class="ellipsis cp" @click="toDetail(item)">{{ item.name }}</h4>
            <div class="typeSty cp" @click="toDetail(item)">
              <p>{{ item.certificat_level_str }}</p>
              <p v-if="item.achievement_type == 1">{{ item.certificat_type_name }}</p>
              <p v-else :class="item.status2.status == 1 ? 's1' : item.status2.status == 2 ? 's2' : 's3'">{{
                item.status2.status_str }}</p>
            </div>
            <p class="timeSty" v-if="item.achievement_type == 1">发布时间：{{ item.update_time }}</p>
            <p class="timeSty" v-else>研究周期：{{ item.cycle_start_date }}~{{ item.cycle_end_date }}</p>
            <p class="timeSty" v-if="item.achievement_type == 1">浏览人次：{{ item.view_count }}</p>
            <p class="timeSty mt10 ellipsis-2" v-else>{{ item.synopsis }}</p>
          </div>

          <el-dropdown @command="handleCommand" trigger="click" placement="bottom" class="more"
            v-if="item.user_id == user_id">
            <i class="el-icon-more more-icon cp"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="beforeCommand(item.id, 'a')">编辑</el-dropdown-item>
              <el-dropdown-item :command="beforeCommand(item.id, 'b')">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 1待审核2审核通过3审核不通过 -->
        <CommonEmpty v-if="!listData.length" hVal="100%"></CommonEmpty>

        <div v-show="total" class="block">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="form.page" :page-sizes="[10, 20, 30, 40, 50]" :page-size="form.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { CertificateType } from "@/api/teacherStudio/index.js";
export default {
  props: ["workData"],
  data() {
    return {
      optiones: [
        {
          id: 1,
          name: "研究成果",
        },
        {
          id: 2,
          name: "研究课题",
        },
      ],
      // 开展方式
      optiones1: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "国家级",
        },
        {
          id: 2,
          name: "省级",
        },
        {
          id: 3,
          name: "市级",
        },
        {
          id: 4,
          name: "区县级",
        },
        {
          id: 5,
          name: "校级",
        },
        {
          id: 6,
          name: "其他",
        },
      ],
      //  活动状态
      optiones2: [
        {
          id: "",
          name: "全部",
        },
      ],
      active: 0,
      active1: 0,
      active2: 0,

      total: 0,
      form: {
        limit: 10,
        page: 1,
        search_achievement_type: 1,
        search_certificat_level: "",
        search_certificat_type: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      listData: [], //列表数据
      is_role_1: "",
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
      user_id: localStorage.getItem("id") || 0
    };
  },
  created() {
    this.getCertificatesType()
    this.init();
    this.getAdminData();
  },
  methods: {
    beforeCommand(id, name) {
      return {
        id: id,
        name: name
      }
    },
    handleCommand(command) {
      console.log(command);
      if (command.name == 'a') {
        this.$router.push({
          path: "/AddCertificate",
          query: {
            achievement_type: this.form.search_achievement_type,
            id: command.id
          }
        })
      }
      if (command.name == 'b') {
        this.$confirm('确认是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.delete("TeachingStudioCertificate/del", {
            params: {
              teaching_studio_id: localStorage.getItem("studioId"),
              id: command.id,
            },
          }).then((res) => {
            this.form.page = 1
            this.CertificateData()
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    //获取成果证书类型
    getCertificatesType() {
      CertificateType({ teaching_studio_id: localStorage.getItem("studioId") }).then((res) => {
        let arr = res.data.data || [];
        console.log(arr);
        arr.forEach(item => {
          this.optiones2.push(item)
        })
      });
    },
    changType(index, id) {
      this.active = index;
      this.form.search_certificat_type = "";
      this.form.search_achievement_type = id;
      this.$set(this.form, "page", 1);
      this.init();
    },
    changType1(index, id) {
      this.active1 = index;
      this.form.search_certificat_level = id;
      this.$set(this.form, "page", 1);
      this.init();
    },
    changType2(index, id) {
      this.active2 = index;
      this.form.search_certificat_type = id;
      this.$set(this.form, "page", 1);
      this.init();
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    //初始
    init() {
      this.CertificateData();
    },
    CertificateData() {
      this.$axios
        .get("indexTeachingStudioCertificate/lists", {
          params: this.form,
        })
        .then((res) => {
          this.listData = res.data.data.data;
          this.total = res.data.data.total;
          this.is_role_1 = res.data.data.is_role_1
        });
    },
    //跳转详情页
    toDetail(item) {
      let routeData = this.$router.resolve({
        path: "/CertificateDetail",
        query: {
          id: item.id,
          is_from: 1,
        },
      });
      window.open(routeData.href, '_blank');
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    //   跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
    //发布荣誉证书
    pushBook(val) {
      this.$router.push("/AddCertificate?achievement_type=" + val);
    },
  },
};
</script>

<style lang="less" scoped>
.Certificate {
  // background: #f7f7f7;
  padding-bottom: 30px;

  .ArrBox {

    //标题
    .title {
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        span {
          color: #ff8201;
        }
      }


    }

    .ItemBox {
      padding: 20px;
      border-radius: 6px 6px 0 0;
      background: #fff;
      position: relative;

      .topT {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        padding-bottom: 10px;
        padding-left: 10px;

      }

      .pushTxt {
        width: 123px;
        height: 34px;
        background: #3489fe;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
      }

      .creBtn_div {
        position: absolute;
        right: 20px;
        bottom: 40px;
      }

      .item1 {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 10px;
        flex-wrap: wrap;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          margin-right: 13px;
          line-height: 15px;
        }

        //循环
        .forList {
          // width: 67px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 4px 3px 4px 4px;
          padding: 0 10px;
          margin-right: 10px;
          cursor: pointer;
        }

        .activeClass {
          // width: 67px;
          height: 30px;
          background: #3489fe;
          line-height: 30px;
          text-align: center;
          border-radius: 4px 3px 4px 4px;
          padding: 0 10px;
          margin-right: 10px;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    //下面循环列表
    .ListBox {
      display: flex;
      flex-wrap: wrap;
      min-height: 200px;
      background: #fff;
      border-radius: 0 0 6px 6px;
      padding: 10px;

      .forBox {
        width: 267px;
        min-height: 279px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 2px rgba(113, 113, 113, 0.12);
        margin-right: 11px;
        margin-bottom: 20px;
        padding: 12px;
        box-sizing: border-box;
        position: relative;

        &:nth-child(5n) {
          margin-right: 0;
        }

        .topBox {
          width: 243px;
          height: 137px;
          background: #f6f2e6;
          border-radius: 4px 4px 0px 0px;
          position: relative;

          .about {
            position: absolute;
            top: 0;
            right: 0;
            background: #ff8201;
            color: #fff;
            font-size: 14px;
            padding: 2px 5px;
            border-radius: 0 5px 0 5px;

            &.aboutFas {
              background: #ff0607;
            }
          }

          .aboutLeft {
            left: 0;
            width: 40px;
            text-align: center;
            border-radius: 5px 0 5px 0;
            background: #ff0607;
          }

          img {
            width: 243px;
            height: 137px;
            border-radius: 5px;
          }
        }

        .BtnBox {
          padding: 20px 0 0 0;

          h4 {
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 5px;
          }

          .typeSty {
            margin-bottom: 10px;
            display: flex;

            p {
              font-size: 14px;
              padding: 0 10px;

              &:first-child {
                color: #fff;
                background: #57a8ef;
                border-radius: 3px 0 0 3px;
              }

              &:last-child {
                border: 1px solid #57a8ef;
                color: #57a8ef;
                border-radius: 0 3px 3px 0;
              }

              &.s1 {
                color: #f00;
              }

              &.s2 {
                color: #0dac9e;
              }

              &.s3 {
                color: #666;
              }


            }
          }

          .timeSty {
            font-size: 14px;
            color: #999;
          }
        }

        .more {
          position: absolute;
          bottom: 10px;
          right: 10px;

          .more-icon {
            font-size: 18px;
          }
        }
      }

      //分页
      .block {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
</style>