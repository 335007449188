<template>
  <div class="public_main_bgc">
    <div class="bannerSty">
      <el-carousel trigger="click" height="320px">
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/banner_sanming.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/shouye_banner.mingshi.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/mingshi_banner.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="public-width pb15">
      <!-- 四名大讲堂 -->
      <div class="public2box">
        <div class="publicLeft">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>四名大讲堂</p>
            </div>
            <div class="titleRight">
              <p class="cp" @click="goMsAdvanceList">更多></p>
            </div>
          </div>
          <div class="mainCenter df" v-if="dataList1.length">
            <div class="cp" v-for="(item, index) in dataList1" :key="index" @click="goMsDetails(item.id)">
              <div class="imgBox">
                <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
              </div>
              <div class="txtBox">
                <h4 class="ellipsis-2 h-42">{{ item.title }}</h4>
                <p class="ellipsis-2 mt10">{{ setText(item.content) }}</p>
              </div>
            </div>
          </div>
          <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
        </div>
        <div class="publicRight">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>精彩直播</p>
            </div>
            <div class="titleRight">
              <p class="cp" @click="goMsLiveList">更多></p>
            </div>
          </div>
          <div class="rightMain1">
            <div v-if="dataList2.length">
              <div v-for="(item, index) in dataList2" :key="index">
                <div class="rightTop df dfb">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox">
                    <h4 class="ellipsis-1 mb5">{{ item.title }}</h4>
                    <p class="ellipsis-2">{{ setText(item.abstract) }}</p>
                  </div>
                </div>
                <div class="rightBom mt14 df dfb">
                  <div class="bom1">
                    <p class="mb5">{{ item.start_date }} {{ item.start_time.substring(0, 5) }}~{{
                  item.end_time.substring(0, 5) }}</p>
                    <p>观看人次：{{ item.view_count }}</p>
                  </div>
                  <div class="bom2">
                    <div class="btn cp" @click="goMsLiveDetails(item.id)">马上看</div>
                  </div>
                </div>
              </div>
            </div>
            <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </div>

      <!-- 四名公开课 -->
      <div class="public2box mt16 mb20">
        <div class="publicLeft">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>四名公开课</p>
            </div>
            <div class="titleRight">
              <p class="cp" @click="goMsAdvanceList">更多></p>
            </div>
          </div>
          <div class="mainCenter df" v-if="dataList3.length">
            <div class="cp" v-for="(item, index) in dataList3" :key="index" @click="goMsDetails(item.id)">
              <div class="imgBox">
                <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
              </div>
              <div class="txtBox">
                <h4 class="ellipsis-2 h-42">{{ item.title }}</h4>
                <p class="ellipsis-2 mt10">{{ setText(item.content) }}</p>
              </div>
            </div>
          </div>
          <el-empty class="h-648" :image-size="50" v-else description="暂无数据"></el-empty>

          <!-- 送教下乡 -->
          <div class="leftMain2 mt12">
            <div>
              <div class="mtitle">
                <p>送教下乡</p>
              </div>
              <div class="main2Center h-275">
                <div class="slrightTop df cp" v-if="dataList4.length">
                  <div class="imgBox" @click="goMsDetails(dataList4[0].id)">
                    <img class="imgbig" v-if="dataList4[0].thumb" :src="dataList4[0].thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox" @click="goMsDetails(dataList4[0].id)">
                    <h4 class="ellipsis-1 mb11">{{ dataList4[0].title }}</h4>
                    <p class="ellipsis-3">{{ setText(dataList4[0].content) }}</p>
                  </div>
                </div>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                <div class="mt20">
                  <template v-for="(its, ind) in dataList4">
                    <p class="ellipsis-1 cp line-h32 hoverBlue" :key="ind" v-if="ind >= 1" @click="goMsDetails(its.id)">
                      <span class="fwb"><i class="el-icon-caret-right fz12 lanse"></i></span>
                      {{ its.title }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <div>
              <div class="mtitle">
                <p>教育帮扶</p>
              </div>
              <div class="main2Center h-275">
                <div class="slrightTop df cp" v-if="dataList5.length">
                  <div class="imgBox" @click="goMsDetails(dataList5[0].id)">
                    <img class="imgbig" v-if="dataList5[0].thumb" :src="dataList5[0].thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox" @click="goMsDetails(dataList5[0].id)">
                    <h4 class="ellipsis-1 mb11">{{ dataList5[0].title }}</h4>
                    <p class="ellipsis-3">{{ setText(dataList5[0].content) }}</p>
                  </div>
                </div>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                <div class="mt20">
                  <template v-for="(its, ind) in dataList5">
                    <p class="ellipsis-1 cp line-h32 hoverBlue" :key="ind" v-if="ind >= 1" @click="goMsDetails(its.id)">
                      <span class="fwb"><i class="el-icon-caret-right fz12 lanse"></i></span>
                      {{ its.title }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 热点活动关注 -->
        <div class="publicRight">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>热点活动关注</p>
            </div>
            <div class="titleRight">
              <!-- <p class="cp">更多></p> -->
            </div>
          </div>
          <div class="rightMain2">
            <template v-if="dataList9.length">
              <div class="cp" v-for="(item, index) in dataList9" :key="index" @click="goMsDetails(item.id)">
                <div class="title-box">
                  <span class="title-icon"></span>
                  <h4 class="ellipsis-1 mb12">{{ item.title }}</h4>
                </div>
                <div class="rightTop df dfb">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox">
                    <p class="ellipsis-2">{{ setText(item.content) }}</p>
                    <p class="txtbox-click">点击查看></p>
                  </div>
                </div>
              </div>
            </template>
            <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      dataList1: [],
      dataList2: [],
      dataList3: [],
      dataList4: [],
      dataList5: [],
      dataList9: [],
    };
  },
  created() {
    // 1四名大讲堂
    this.getList1()
    // 2活动直播
    this.getList2()
    // 3四名公开课
    this.getList3()
    // 4送教下乡
    this.getList4()
    // 5教育帮扶
    this.getList5()
    this.getList9()
  },
  methods: {
    // 1四名大讲堂
    getList1() {
      let param = {
        show_type: 2,
        type1: 4,
        type2: 1,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList1 = res.data.data.data
      })
    },

    // 2活动直播
    getList2() {
      let param = {
        limit: 4,
        sort: 'new',
      }
      msAllApi.LiveList(param).then(res => {
        this.dataList2 = res.data.data.data
      })
    },

    // 3四名公开课
    getList3() {
      let param = {
        show_type: 2,
        type1: 4,
        type2: 2,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList3 = res.data.data.data
      })
    },

    // 4四名公开课
    getList4() {
      let param = {
        show_type: 2,
        type1: 4,
        type2: 3,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList4 = res.data.data.data
      })
    },

    // 5四名公开课
    getList5() {
      let param = {
        show_type: 2,
        type1: 4,
        type2: 4,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList5 = res.data.data.data
      })
    },

    // 9热点活动关注
    getList9() {
      let param = {
        show_type: 1,
        sort: 'hot',
        limit: 7,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList9 = res.data.data.data
      })
    },

    goMsAdvanceList() {
      this.$router.push({
        name: "MsAdvanceList",
      })
    },
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },

    goMsLiveList() {
      this.$router.push({
        name: "MsLiveList",
      })
    },
    // 跳转直播详情
    goMsLiveDetails(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: left;
}

.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

// 内容样式开始
.public2box {
  display: flex;
  justify-content: space-between;

  .publicLeft {
    width: 956px;

    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .titleLeft {
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .titleRight {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #667280;
          line-height: 29px;
        }
      }
    }


    .mainCenter {
      width: 956px;
      // height: 358px;
      border-radius: 8px;
      box-sizing: border-box;
      width: 100%;
      flex-wrap: wrap;

      >div {
        width: 308px;
        height: 308px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        margin-right: 16px;
        margin-bottom: 16px;

        &:hover {
          box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        .imgBox {
          width: 100%;
          padding: 12px;
          box-sizing: border-box;

          img {
            height: 155px;
            border-radius: 8px;
          }
        }

        .txtBox {
          padding: 5px 16px;
          box-sizing: border-box;
          width: 100%;

          h4 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: #666666;
          }
        }
      }
    }

    .leftMain2 {
      display: flex;
      justify-content: space-between;

      >div {
        width: 470px;
        height: 342px;
        border-radius: 8px;
        box-sizing: border-box;

        .mtitle {
          margin-bottom: 14px;

          p {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #222427;
          }
        }

        .main2Center {
          background: rgba(255, 255, 255, 1);
          padding: 22px;
          border-radius: 8px;


          .slrightTop {
            justify-content: space-between;

            &:hover {
              .txtbox {
                h4 {
                  color: #3489FF;
                }
              }
            }

            .imgBox {
              width: 168px;
              height: 95px;

              img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
              }
            }

            .txtbox {
              width: 240px;

              h4 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2a3037;
              }

              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
                color: #666666;
              }
            }
          }
        }
      }
    }

  }

  .publicRight {
    width: 308px;
    // height: 632px;

    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .titleLeft {
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .titleRight {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #667280;
          line-height: 29px;
        }
      }
    }

    .rightMain1 {
      width: 308px;
      max-height: 632px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;

      >div {
        >div {
          width: 292px;
          height: 148px;
          background: rgba(238, 246, 255, 1);
          border-radius: 8px;
          margin-bottom: 8px;
          padding: 16px 7px;
          box-sizing: border-box;

          .rightTop {
            .imgBox {
              width: 111px;
              height: 62px;

              img {
                border-radius: 4px;
              }
            }

            .txtbox {
              width: 155px;

              h4 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2a3037;
              }

              p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
                color: #666666;
              }
            }
          }

          .rightBom {
            .bom1 {
              p {
                &:first-child {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #ff7a29;
                }

                &:last-child {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                  color: #666666;
                }

              }
            }

            .bom2 {

              div {
                margin-top: 7px;
                width: 70px;
                height: 28px;
                line-height: 28px;
                background: rgba(52, 137, 255, 1);
                border-radius: 14px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: normal;
                color: #ffffff;
                text-align: center;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .rightMain2 {
      width: 308px;
      max-height: 1016px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;

      >div {
        width: 292px;
        height: 134px;
        background: rgba(238, 246, 255, 1);
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 16px;
        box-sizing: border-box;

        .title-box {
          display: flex;
          .title-icon {
            width: 16px;
            height: 22px;
            background: url(../../../assets/images/myImg/huo_icon.png);
            display: block;
            margin-right: 4px;
          }
          h4 {
            flex: 1;
            overflow: hidden;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }
        }

        .rightTop {
          .imgBox {
            width: 111px;
            height: 62px;

            img {
              border-radius: 4px;
            }
          }

          .txtbox {
            width: 140px;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
            }

            .txtbox-click {
              font-size: 14px;
              color: rgba(52,137,255,1);
              margin-top: 2px;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>