<template>
  <div class="personInfo_div personal_right_box">

    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">个人资料</div>
      </div>
    </div>

    <div class="PerSonInfoBox">
      <!-- 主体 -->
      <div class="item">
        <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="头像:">
            <div class="upImg">
              <!-- 金山云组件上传图片 -->
              <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi"
                :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="isUpOk">
                <!-- 上传后图片div -->
                <div v-if="form.photo_url" class="avatar">
                  <img :src="form.photo_url" />
                </div>
                <!-- 没上传div -->
                <div v-else class="jh">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </dc-upload>
            </div>
            <!-- end -->
            <div style="border: 1px"></div>
          </el-form-item>

          <el-form-item label="真实姓名">
            <el-input v-model="form.realname" style="width: 200px"></el-input>
          </el-form-item>

          <el-form-item label="手机" style="margin-left: -28px">
            <span style="color: #666666">{{ form.phone }}</span>
            <span style="color: #3489fe; font-size: 12px; margin-left: 20px" @click="ReplaceMobile">更换手机</span>
          </el-form-item>

          <!-- <el-form-item label="身份证" style="margin-left: -15px">
            <span style="color: #666666">{{ form.idnum }}</span>
            <span style="color: #ff0607; margin-left: 15px">*</span>
            <span style="font-size: 12px; color: #666666">如需修改身份证，请联系客服</span>
          </el-form-item> -->

          <el-form-item label="性别" style="margin-left: -28px">
            <el-radio-group v-model="form.sex">
              <el-radio v-for="item in radioList" :key="item.id" :value="item.id" :label="item.id">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <!-- 学段 -->
            <span style="margin-left: -70px; margin-right: 20px; color: #606266">学段学科:</span>
            <el-select v-model="form.stage_id" placeholder="请选择学段" class="sid" style="margin-right: 20px; width: 130px">
              <el-option v-for="item in stageList" :key="item.id" :label="item.name" :value="item.id"
                @click.native="getXK(item.id)">
              </el-option>
            </el-select>
            <!-- 学科 -->
            <el-select v-model="form.subject_id" placeholder="请选择学科" class="sid" style="width: 130px">
              <el-option v-for="item in getDataList" :key="item.subjectId" :label="item.subjectName"
                :value="item.subjectId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历" style="margin-left: -28px">
            <el-select v-model="form.education_id" placeholder="请选择学历" style="margin-left: 28px">
              <el-option v-for="item in Education" :key="item.id" :value="item.id" :label="item.name" />
            </el-select>
          </el-form-item>

          <el-form-item label="所在地区">
            <!-- 地-->
            <el-select v-model="form.province_id" placeholder="请选择省份" style="margin-right: 20px; width: 120px">
              <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"
                @click.native="getCity(item.id)">
              </el-option>
            </el-select>
            <!-- 市 -->
            <el-select v-model="form.city_id" placeholder="请选择地市" style="margin-right: 20px; width: 120px">
              <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"
                @click.native="getAddress(item.id)">
              </el-option>
            </el-select>
            <!-- 市 -->
            <el-select v-model="form.county_id" placeholder="请选择县" class="sid" style="width: 130px">
              <el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属单位">
            <span>{{ form.org_name }}</span>
            <span class="cp" style="color: #3489fe; font-size: 14px; margin-left: 20px" @click="_getRepalce">修改</span>
          </el-form-item>

          <el-form-item style="text-align: center">
            <el-button type="primary" @click="saveInfo()">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- end -->
      <!-- 对话框 -->

      <el-dialog title="修改单位" :visible.sync="tableVisible">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="">
            <el-input v-model="formInline.search_name" placeholder="请输入单位名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="_getRepalce">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="List" border>
          <el-table-column label="选择" width="50">
            <template slot-scope="scope">
              <el-radio-group v-model="form.org_id">
                <el-radio :label="scope.row.id" @click.native="selectOne(scope.$index)"><span></span></el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column property="name" label="单位名称" width="250"></el-table-column>
          <el-table-column property="type_name" label="单位类别"></el-table-column>
          <el-table-column property="area" label="所属地区"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block" style="text-align: center; margin-top: 20px">
          <el-pagination @current-change="handleCurrentChange" :current-page="page"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="btn" style="text-align: center; padding-top: 20px">
          <el-button type="primary" @click="addPlace()">确定</el-button>
          <el-button @click="tableVisible = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      formInline: {
        search_name: ""
      },
      total: 0,
      //  分页数据
      limit: 5,
      page: 1,
      //  存放id
      radio2: 1,
      radio: 1,
      List: "",
      a: "",
      cid: "",
      aid: "",
      cover_url: "",

      isUpOk: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      // 地区
      provinceList: "",
      // 市
      cityList: "",
      //addressList
      addressList: "",
      //学段下拉框数组
      stageList: "",
      //学科下拉框数组
      SubjectList: "",
      radioList: [
        {
          id: 1,
          name: "男",
        },
        {
          id: 2,
          name: "女",
        },
      ],

      form: {
        city_id: 0,
        county_id: 0,
        education_id: 0,
        idnum: "",
        org_id: 0,
        org_name: "",
        phone: "",
        photo_id: "",
        photo_url: "",
        province_id: 0,
        realname: "",
        sex: "",
        stage_id: 0,
        subject_id: 0,
      },
      form: {},
      // 学历
      Education: [
        { id: 0, name: "小学" },
        { id: 1, name: "初中" },
        { id: 2, name: "高中" },
        { id: 3, name: "大专" },
        { id: 4, name: "本科" },
        { id: 5, name: "硕士研究生" },
        { id: 6, name: "博士研究生" },
      ],

      // 分页数据
      currentPage4: 1,

      radio: "1",

      tableVisible: false,
    };
  },
  created() {
    this.init();
    // 初始化学段
    this.getXD();
    this.geteducation();
    //初始化金山云
    this._getAddress();

  },
  //    计算属性
  computed: {
    getDataList() {
      return this.SubjectList;
    },
  },
  //监听属性
  methods: {
    onSubmit() {
      this.page = 1
      this.limit = 10
      this.getRepalce();
    },
    _getRepalce() {
      this.page = 1
      this.limit = 10
      this.formInline.search_name = ""
      this.getRepalce();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRepalce();
    },
    //  初始化数据
    async init() {
      // 从缓存里得到用户信息
      let userId = localStorage.getItem("id");
      let res = await this.$axios.get(`/user/${userId}/edit`);
      this.form = JSON.parse(JSON.stringify(res.data.data));
      console.log(this.form);
      if (this.form.stage_id == 0) this.form.stage_id = "";
      if (this.form.subject_id == 0) this.form.subject_id = "";
      if (this.form.education_id == 0) this.form.education_id = "";
      if (this.form.province_id == 0) this.form.province_id = "";
      if (this.form.city_id == 0) this.form.city_id = "";
      if (this.form.county_id == 0) this.form.county_id = "";
      let aa = res.data.data.stage_id;
      if (aa != "") {
        this.getXK(aa);
      }
      //  初始化省
      this.getProvice();
    },
    // 单选事件
    selectOne(id) {
      //  选中的id
      this.radio2 = id;
    },
    //添加地址
    addPlace() {
      let idx = this.radio2;
      this.form.org_name = this.List[idx].name;
      this.tableVisible = false;
    },
    //获取单位
    async getRepalce() {
      let res = await this.$axios.get("/organization", {
        params: {
          page: this.page,
          limit: this.limit,
          search_name: this.formInline.search_name,
        },
      });
      this.List = res.data.data.data;
      this.total = res.data.data.total;
      //提取id
      let arr = res.data.data.data;
      this.radioList1 = arr.map((item) => {
        return item.id;
      });
      this.tableVisible = true
    },
    async saveInfo() {
      let res = await this.$axios.put("user", this.form);
      if (res.status == 200) {
        this.$message.success("修改个人信息成功");
        this.getUserInfo();
        this.init();
      }
    },
    //获取学历
    async geteducation() {
      let res = await this.$axios.get("education");
      this.Education = res.data.data;
      console.log(this.Education.data);
    },
    //获取学段
    async getXD() {
      let res = await this.$axios.get("stage/stage_list");
      this.stageList = res.data.data;
    },
    //获取学科
    async getXK(id) {
      let res = await this.$axios.get("subject/list", {
        params: {
          pharseid: id,
        },
      });
      this.SubjectList = res.data.data;
    },

    //  获取市
    async getProvice() {
      let res = await this.$axios.get("province");
      this.provinceList = res.data.data;
      //调用区
      let cid = this.form.province_id;
      if (cid != "") {
        this.getCity(cid);
      }
    },
    //获取区
    async getCity(id) {
      let res = await this.$axios.get("city", {
        params: {
          pid: id,
        },
      });
      this.cityList = res.data.data;
      //调用县
      let gid = this.form.city_id;
      if (gid != "") {
        this.getAddress(gid);
      }
    },
    //获取县
    async getAddress(id) {
      let res = await this.$axios.get("county", {
        params: {
          pid: id,
        },
      });
      this.addressList = res.data.data;
    },

    //上传失败的时候会出现Bug，故在上传失败的时候也进行一下判断
    error: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      // 上传成功就关掉
      this.isUpOk = false;
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      // 把本地文件地址给cover_url变量
      this.form.photo_url = resData.Location;
      this.form.photo_id = res.data.data.id;
      this.isUpOk = true;
    },

    // 跳转更换手机页面
    ReplaceMobile() {
      this.$router.push({
        path: "/replaceMobile",
      });
    },
    ...mapActions(["getUserInfo"]),
  },
};
</script>

<style lang="less" scoped>
.personInfo_div {
  background-color: #fff;
}

.PerSonInfoBox {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #fff;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;

  .item {
    .el-form {
      width: 945px;

      //图片上传样式
      .upImg {
        margin-left: 10px;

        // 上传图片样式
        .avatar-uploader {

          //  上传图片样式
          .avatar {
            width: 100px;
            height: 100px;

            img {
              width: 100%;
            }
          }

          //没上传图片样式
          .jh {
            width: 100px;
            height: 100px;
            border: 1px dashed #ebebeb;

            .avatar-uploader-icon {
              font-size: 30px;
              margin-top: 35px;
            }
          }
        }

        //添加一张图片之后隐藏后续框样式
        .disabled .el-upload--picture-card {
          display: none;
        }
      }
    }

    // 图片
    .item_info {
      display: flex;
      justify-content: left;
      margin-top: 20px;
      margin-left: -19px;

      span {
        font-size: 14px;
        margin-left: 58px;
        margin-right: 5px;
        color: #666666;
      }

      .item_img {
        width: 108px;
        height: 108px;
        border: 1px solid bisque;

        margin-bottom: 20px;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>