<template>
  <div class="public_main_bgc" v-if="dataObj != null">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="height: 49px; display: flex; align-items: center">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">直播详情</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="video-box">
        <template v-if="playerShow">
          <Ckplayer :videoSrc="dataObj.rtc_url" :live="true" :liveAutoplay="true" className="videoLivePlay"
            v-if="dataObj.status == 2">
          </Ckplayer>
          <Ckplayer :videoSrc="dataObj.playback_resource_url" v-if="dataObj.status == 6">
          </Ckplayer>
        </template>
        <template v-if="dataObj.id == 62">
          <div class="live-cover"
            :style="`background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${dataObj.cover_url}');`"
            v-if="dataObj.status == 1 || dataObj.status == 3 || dataObj.status == 4 || dataObj.status == 5"
          >
            <div class="cover-box">
              <p class="cover-box-text-1">2024年1月30日播报</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="live-cover"
            :style="`background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${dataObj.cover_url}');`"
            v-if="dataObj.status == 1 || dataObj.status == 3 || dataObj.status == 4 || dataObj.status == 5"
          >
            <div class="cover-box" v-if="dataObj.status == 1">
              <template v-if="countdown.seconds >= 0">
                <p class="cover-box-text-1 mb30">
                  直播倒计时：
                  <span class="number">{{ countdown.days }}</span> 天
                  <span class="number">{{ countdown.hours }}</span> 小时
                  <span class="number">{{ countdown.minutes }}</span> 分钟
                  <span class="number">{{ countdown.seconds }}</span> 秒
                </p>
                <p class="cover-box-text-2">直播时间：{{ dataObj.start_date }} {{ dataObj.start_time }}</p>
              </template>
              <p class="cover-box-text-1" v-else>直播暂未开始，请耐心等待！</p>
            </div>
            <p class="cover-title" v-if="dataObj.status == 3 || dataObj.status == 4">直播已结束，暂无回放</p>
            <p class="cover-title" v-if="dataObj.status == 5">抱歉，该直播活动已被取消！</p>
          </div>
        </template>
      </div>
      <div class="live-info-module">
        <div class="live-info-left">
          <h1 class="live-title ellipsis">{{ dataObj.title }}</h1>
          <div class="live-tag">
            <span class="tag-item" v-for="(item, index) in dataObj.label.split(',')" :key="index">{{ item }}</span>
          </div>
        </div>
        <div class="live-info-right">
          <span class="live-info-opetation">
            <i class="operation-icon el-icon-view"></i>
            <p class="operation-text">{{ dataObj.view_count }}人阅览</p>
          </span>
          <span class="live-info-opetation" @click="_likeLive" :class="dataObj.is_like == 1 ? 'opetation-active' : ''">
            <i class="operation-icon el-icon-thumb"></i>
            <p class="operation-text">{{ dataObj.is_like == 1 ? '已点赞' : '点赞' }}({{ dataObj.like_count }})</p>
          </span>
          <span class="live-info-opetation" @click="_collectionLive" :class="dataObj.is_collect == 1 ? 'opetation-active' : ''">
            <i class="operation-icon el-icon-star-off"></i>
            <p class="operation-text">{{ dataObj.is_collect == 1 ? '已收藏' : '收藏' }}({{ dataObj.collect_count }})</p>
          </span>
          <span class="live-info-opetation" @click="shareDilog = true">
            <i class="operation-icon el-icon-share"></i>
            <p class="operation-text">分享</p>
          </span>
        </div>
      </div>

      <div class="mainCenter df dfb">
        <div class="mainLeft">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick" class="tabsBox">
            <el-tab-pane label="直播简介" name="first">
              <div class="live-info-box">
                <div class="info-top">
                  <div class="info-block">
                    <span class="block-left">
                      <img src="@/assets/live/live_icon_label1.png" class="block-icon">
                      <p class="block-label">活动类型：</p>
                    </span>
                    <p class="block-text">{{ dataObj.topic_data.type_str }}</p>
                  </div>
                  <div class="info-block">
                    <span class="block-left">
                      <img src="@/assets/live/live_icon_label2.png" class="block-icon">
                      <p class="block-label">所属专题：</p>
                    </span>
                    <p class="block-text block-text-color-1">{{ dataObj.topic_data.title }}</p>
                  </div>
                </div>
                <div class="info-bottom">{{ dataObj.abstract }}</div>
              </div>
              <div class="live-content" v-if="dataObj.content" v-html="format(dataObj.content)"></div>
            </el-tab-pane>
            <el-tab-pane :label="`评论 (${commenttotal})`" name="second">
              <div class="pTop df mt30">
                <div class="leftBox pt5">
                  <div class="imgBox">
                    <img class="imgbig" v-if="unserInfoData && unserInfoData.photo_url" :src="unserInfoData.photo_url"
                      alt="" />
                    <img class="imgbig" v-else src="@/assets/images/avator.png" alt="" />
                  </div>
                  <p class="ellipsis-1 mt10 tc">
                    {{
                      unserInfoData && unserInfoData.username
                      ? unserInfoData.username
                      : ""
                    }}
                  </p>
                </div>
                <div class="rightBox df dfb">
                  <el-input type="textarea" :rows="3" placeholder="请输入评论内容" v-model="textarea">
                  </el-input>
                  <el-button type="warning" class="width100 ml10" @click="_postComment">发布</el-button>
                </div>
              </div>

              <div class="pBom" v-if="commenttotal">
                <div class="pLiBox" v-for="(item, index) in commentList" :key="index">
                  <div class="bom1 df dfb">
                    <div class="imgBox">
                      <img v-if="item.photo_url" class="imgbig" :src="item.photo_url" alt="" />
                    </div>
                    <div class="txtBox" :style="{ border: item.child.total ? 'none' : '' }">
                      <div class="txt1 df mb10">
                        <p class="mr20">{{ item.username }}</p>
                        <p>{{ item.create_time }}</p>
                      </div>
                      <div class="txt2 mb10">
                        <p>{{ item.content }}</p>
                      </div>
                      <div class="txt3 df dfr mr20">
                        <div class="flex mr20 cp" @click="zanBtn(item.id)" :class="item.is_like == 1 ? 'lanseDiv' : ''">
                          <i class="el-icon-thumb mr5"></i>
                          点赞({{ item.like_count }})
                        </div>
                        <div class="flex mr20 cp" @click="delList(item.id)" v-if="item.user_id == unserInfoData.id">
                          <i class="el-icon-delete mr5"></i>
                          删除
                        </div>
                        <div class="flex mr20 cp" @click="addList2(item)">
                          <i class="el-icon-view mr5"></i>
                          回复({{ item.reply_count }})
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="inbox df dfb mt10 mb10" v-if="item.inputOk">
                    <el-input type="textarea" :rows="3" placeholder="请输入回复内容" v-model="item.textarea">
                    </el-input>
                    <el-button type="warning" class="width100 ml10" @click="huifuBtn(1, item)">发布</el-button>
                  </div>

                  <div class="bom2" v-if="item.child.length">
                    <div class="pLiBox" v-for="(common, dex) in item.child" :key="dex">
                      <div class="bom1 df dfb">
                        <div class="imgBox">
                          <img v-if="common.photo_url" class="imgbig" :src="common.photo_url" alt="" />
                        </div>
                        <div class="txtBox">
                          <div class="txt1 df mb10">
                            <p class="mr20">{{ common.username }}</p>
                            <p>{{ common.create_time }}</p>
                          </div>
                          <div class="txt2 mb10">
                            <p>{{ common.content }}</p>
                          </div>
                          <div class="txt3 df dfr mr20">
                            <div class="flex mr20 cp" @click="zanBtn(common.id)"
                              :class="common.is_like == 1 ? 'lanseDiv' : ''">
                              <i class="el-icon-thumb mr5"></i>
                              点赞({{ common.like_count }})
                            </div>
                            <div class="flex mr20 cp" @click="delList(common.id)"
                              v-if="common.user_id == unserInfoData.id">
                              <i class="el-icon-delete mr3"></i>
                              删除
                            </div>
                            <div class="flex mr20 cp" @click="openInput2(item, common)">
                              <i class="el-icon-view mr3"></i>
                              回复
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="inbox df dfb mt10 mb10" v-if="common.inputOk">
                        <el-input type="textarea" :rows="3" placeholder="请输入回复内容" v-model="common.textarea">
                        </el-input>
                        <el-button type="warning" class="width100 ml10"
                          @click="huifuBtn(2, item, common)">发布</el-button>
                      </div>
                    </div>
                    <div class="addLength tc pb20">
                      <el-button type="text" :disabled="!flag" @click="addList2(item)">查看更多>>></el-button>
                    </div>
                  </div>
                </div>
                <div class="addLength tc" v-if="showAdd1">
                  <el-button type="primary" :disabled="!flag" @click="addList1">查看更多>>></el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="mainRight">
          <!-- 名称 -->
          <div class="titleBox df dfb">
            <div class="titleLeft">
              <p>相关直播推荐</p>
            </div>
          </div>
          <div class="mainBox">
            <div class="ulBox">
              <template v-if="dataList2.length">
                <div class="live-list-item" v-for="(item, index) in dataList2" :key="index">
                  <div class="item-top">
                    <div class="imgBox">
                      <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="" />
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="" />
                      <span class="live-status" :class="item.status == 1 ? 'live-status-color1' : item.status == 2 ? 'live-status-color2' : 'live-status-color3'">
                        {{ item.status_str }}
                      </span>
                    </div>
                    <div class="txtbox">
                      <h3 class="ellipsis">{{ item.title }}</h3>
                      <p class="ellipsis-2">{{ item.abstract }}</p>
                    </div>
                  </div>
                  <div class="item-bottom">
                    <div class="item-bottom-left">
                      <p class="live-date">{{ item.start_date }} {{ item.start_time }}</p>
                      <p class="live-num">观看人次：{{ item.view_count }}</p>
                    </div>
                    <div class="item-bottom-right">
                      <span class="live-button" @click="goMsDetails(item.id)">马上看</span>
                    </div>
                  </div>
                </div>
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>



    <MyDialog :open="shareDilog" title="直播分享" @onClose="shareDilog = false" width="600px">
      <div class="share-box">
        <div class="share-url">
          <p class="share-label">直播链接：</p>
          <p class="share-value">{{ currentUrl }}</p>
          <el-button type="primary" :data-clipboard-text="currentUrl" @click="copyUrl" class="tag-read">
            复制链接
          </el-button>
        </div>
        <div class="share-code">
          <p class="share-code-text">手机扫码观看</p>
          <img :src="h5LiveQrcode" class="share-code-img">
        </div>
      </div>
    </MyDialog>

  </div>
  <div v-else class="live-page-404">直播被删除(404页面)</div>
</template>

<script>
import * as msAllApi from "@/api/teacherStudio/msAllApi";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      unserInfoData: {},
      dataObj: {
        id: "",
        label: "",
        topic_data: {
          type_str: "",
          title: "",
        },
      },
      dataList2: [],
      activeName: "first",
      commentList: [],
      commenttotal: 0,
      cpmmentObj: {
        topic_live_id: "",
        page: 1,
        limit: 10,
      },
      cpmmentObj2: {
        page: 1,
        limit: 10,
      },
      showAdd1: true,
      flag: true,
      textarea: "",
      countdown: {},
      playerShow: true,
      shareDilog: false,
      currentUrl: window.location.href,
      h5LiveQrcode: '',
      liveStatusSetTimeout: null,
      h5LiveQrcodeSetTimeout: null
    };
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route(to, from) {
      this.playerShow = false
      this.getList1()
      this.getList2()
      this._get_comment_list()
      this.getH5LiveQrcode();
      this.currentUrl = window.location.href
    },
  },
  created() {
    this.unserInfoData = JSON.parse(localStorage.getItem("unserInfoData")) || { id: '' };
    this.getList1();
    this.getList2();
    this._get_comment_list();
    this.getH5LiveQrcode();
  },
  methods: {
    format(html) {
      var GRT = [
        // img 样式
        ['img', "max-width:100%;height:auto;"],
      ];
      for (let i = 0; i < GRT.length; i++) {
        html = html.replace(new RegExp('<' + GRT[i][0] + '>|<' + GRT[i][0] + ' (.*?)>', 'gi'), function (word) {
          // 分析 dom 上是否带有 style=""
          if (word.indexOf('style=') != -1) {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)style="(.*?)"(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1style="$2 ' + GRT[i][1] + '"$3$4>');
          } else {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1 style="' + GRT[i][1] + '$2">');
          }
        });
      }
      return html;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    huifuBtn(val, item, noon) {
      if (val == 1 && !item.textarea) {
        this.$message.warning("输入回复内容");
        return;
      }
      if (val == 2 && !noon.textarea) {
        this.$message.warning("输入回复内容");
        return;
      }
      let param = {
        topic_live_id: this.$route.query.id,
        user_id: this.unserInfoData.id,
        reply_user_id: item.user_id,
        top_reply_id: item.id,
        content: val == 1 ? item.textarea : noon.textarea,
      };
      msAllApi.postinsert_reply(param).then((res) => {
        this.cpmmentObj.page = 1;
        this._get_comment_list();
      });
    },
    // 评论
    _postComment() {
      if (!localStorage.getItem("unserInfoData")) {
        this.$message.warning("请先进行登录~");
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href));
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return;
      }
      if (!this.textarea) {
        this.$message.warning("输入回复内容");
        return;
      }
      let param = {
        topic_live_id: this.$route.query.id,
        user_id: this.unserInfoData.id,
        content: this.textarea,
      };
      msAllApi.postinsert(param).then((res) => {
        this.textarea = "";
        this.cpmmentObj.page = 1;
        this._get_comment_list();
      });
    },
    _get_comment_list() {
      this.cpmmentObj.topic_live_id = this.$route.query.id;
      msAllApi.get_comment_list(this.cpmmentObj).then((res) => {
        let arr = res.data.data.data;
        if (arr.length) {
          arr.forEach((item) => {
            this.$set(item, "childPage", 0);
            this.$set(item, "child", []);
            this.$set(item, "inputOk", 0);
            this.$set(item, "textarea", "");
          });
        }
        if (arr.length < 9) {
          this.showAdd1 = false;
        } else {
          this.showAdd1 = true;
        }
        this.$nextTick(() => {
          this.commentList = arr;
          this.commenttotal = res.data.data.total;
        });
      });
    },
    addList1() {
      if (this.flag) {
        this.flag = false;
        setTimeout(() => {
          this.flag = true;
        }, 3000);
      } else {
        return;
      }
      let param = {
        topic_live_id: this.$route.query.id,
        page: this.cpmmentObj.page + 1,
        limit: 10,
      };
      msAllApi.get_comment_list(param).then((res) => {
        let arr = res.data.data.data;
        if (arr.length) {
          this.cpmmentObj.page = this.cpmmentObj.page + 1;
          arr.forEach((item) => {
            this.$set(item, "childPage", 0);
            this.$set(item, "child", []);
            this.commentList.push(item);
          });
        } else {
          this.$message.warning("没有更多评论了~");
          this.showAdd1 = false;
        }
      });
    },
    addList2(item) {
      if (this.flag) {
        this.flag = false;
        setTimeout(() => {
          this.flag = true;
        }, 3000);
      } else {
        return;
      }
      this.$set(item, "inputOk", 1);
      let param = {
        topic_live_id: this.$route.query.id,
        top_reply_id: item.id,
        page: item.childPage + 1,
        limit: 10,
      };
      msAllApi.get_reply_list(param).then((res) => {
        let arr = res.data.data.data;
        if (arr.length) {
          this.cpmmentObj2.page = this.cpmmentObj2.page + 1;
          this.commentList.forEach((it) => {
            if (it.id == item.id) {
              it.childPage = it.childPage + 1;
              arr.forEach((em) => {
                it.child.push(em);
              });
            }
          });
        }
      });
    },
    openInput2(item, noon) {
      if (!localStorage.getItem("unserInfoData")) {
        this.$message.warning("请先进行登录~");
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href));
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return;
      }
      this.commentList.forEach((it) => {
        if (it.id == item.id) {
          item.child.forEach((em) => {
            this.$set(em, "textarea", "");
            if (em.id == noon.id) {
              this.$set(em, "inputOk", 1);
            } else {
              this.$set(em, "inputOk", 0);
            }
          });
        }
      });
    },
    delList(id) {
      this.$confirm("是否确定删除该数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          msAllApi.del_reply({ id: id }).then((res) => {
            this.$message.success("删除成功");
            this.cpmmentObj.page = 1;
            this._get_comment_list();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    zanBtn(id) {
      msAllApi.postinsertLike({ reply_id: id, form: 2 }).then((res) => {
        this.cpmmentObj.page = 1;
        this._get_comment_list();
      });
    },
    getList1() {
      msAllApi.activityLiveDetail({ id: this.$route.query.id }).then((res) => {
        //直播状态：status 1未开始2进行中3已结束4已结束5未发布6录像回放
        this.dataObj = res.data.data;
        this.playerShow = true
        this.updateCountdown()
        this.getLiveStatus()
        if (this.dataObj.status == 2 && !this.dataObj.rtc_url) {
          this.$message.warning("没有推流地址！");
        }
      });
    },
    getList2() {
      msAllApi.LiveList({ sort: "new", limit: 5 }).then((res) => {
        this.dataList2 = res.data.data.data;
      });
    },
    getH5LiveQrcode() {
      msAllApi.getH5LiveQrcode({ id: this.$route.query.id }).then((res) => {
        if (res.data.code == 900) {
          this.h5LiveQrcode = res.data.data.url
        }
      });
    },
    getLiveStatus() {
      msAllApi.getLiveStatus({ id: this.cpmmentObj.topic_live_id }).then((res) => {
        if (res.data.code == 900) {
          const status = res.data.data.status
          if (status != this.dataObj.status) {
            this.getList1()
            return
          }
          if (status == 1 || status == 2) {
            this.liveStatusSetTimeout = setTimeout(this.getLiveStatus, 5000)
          }
        }
      });
    },
    _collectionLive() {
      if (!localStorage.getItem("unserInfoData")) {
        this.$message.warning("请先进行登录~");
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href));
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return;
      }
      let param = {
        id: this.$route.query.id,
      };
      msAllApi.collectionLive(param).then((res) => {
        if (this.dataObj.is_collect == 2) {
          this.dataObj.is_collect = 1;
          this.dataObj.collect_count = this.dataObj.collect_count + 1;
        } else {
          this.dataObj.is_collect = 2;
          this.dataObj.collect_count = this.dataObj.collect_count - 1;
        }
      });
    },
    _likeLive() {
      if (!localStorage.getItem("unserInfoData")) {
        this.$message.warning("请先进行登录~");
        setTimeout(() => {
          localStorage.setItem("oldUrl", JSON.stringify(location.href));
          this.$router.push({
            path: "/logo",
          });
        }, 1000);
        return;
      }
      let param = {
        id: this.$route.query.id,
      };
      msAllApi.likeLive(param).then((res) => {
        if (this.dataObj.is_like == 2) {
          this.dataObj.is_like = 1;
          this.dataObj.like_count = this.dataObj.like_count + 1;
        } else {
          this.dataObj.is_like = 2;
          this.dataObj.like_count = this.dataObj.like_count - 1;
        }
      });
    },
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id },
      });
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    updateCountdown() {
      // 设置目标日期和时间（以毫秒为单位）
      const targetDate = new Date(`${this.dataObj.start_date} ${this.dataObj.start_time}`).getTime();

      // 获取当前日期和时间（以毫秒为单位）
      const currentDate = new Date().getTime();

      // 计算剩余时间（以毫秒为单位）
      const remainingTime = targetDate - currentDate;

      // 将剩余时间转换为天、小时、分钟和秒
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      this.countdown = {
        days, hours, minutes, seconds
      }
      // 如果剩余时间大于0，每秒钟调用一次updateCountdown函数
      if (remainingTime > 0) {
        this.h5LiveQrcodeSetTimeout = setTimeout(this.updateCountdown, 1000);
      }
    },
    // 复制文字函数
    copyUrl() {
      const clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        this.$message.success("已复制到剪贴板");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },

  mounted() { },

  beforeDestroy() {
    clearTimeout(this.liveStatusSetTimeout)
    clearTimeout(this.h5LiveQrcodeSetTimeout)
  }
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainCenter {
    .mainLeft {
      width: 956px;
      min-height: 500px;
      background: #ffffff;
      border-radius: 8px;
      padding: 0 16px 16px 16px;
      box-sizing: border-box;

      /deep/.tabsBox {
        .el-tabs__header {
          height: 64px;
          display: flex;
          align-items: center;
          border: 0;
          padding-left: 15px;
          margin: 0;
        }
        .el-tabs__item {
          height: initial;
          line-height: initial;
          font-size: 18px;
          color: rgba(34,36,39,1);
          border: 0;
          padding: 0 40px 0 0;
        }
        .el-tabs__nav {
          border: 0;
        }
        .is-active {
          font-size: 20px;
          font-weight: bold;
        }
      }

      .live-info-box {
        padding: 0 15px;
        background: rgba(238,246,255,1);
        border-radius: 8px;
        .info-top {
          padding: 20px 0 10px 0;
          border-bottom: 1px solid rgb(217,228,241);
          display: flex;
          flex-wrap: wrap;
          .info-block {
            display: inline-flex;
            align-items:flex-start;
            margin: 0 38px 10px 0;
            &:last-of-type {
              margin-right: 0;
            }
            .block-left {
              display: flex;
              align-items: center;
              .block-icon {
                margin-right: 4px;
              }
              .block-label {
                font-weight: bold;
              }
            }
            .block-text {
              flex: 1;
              font-size: 16px;
              color: rgb(42,48,55);
            }
            .block-text-color-1 {
              color: rgb(52,137,255);
            }
          }
        }
        .info-bottom {
          padding: 20px 0;
          font-size: 16px;
          color: rgba(102,102,102,1);
        }
      }

      .live-content {
        padding: 15px;
      }

      .mainBox {
        width: 100%;

        .topBox {
          .imgBox {
            width: 400px;
            height: 270px;

            img {
              border-radius: 8px;
            }
          }

          .txtBox {
            width: 450px;

            h4 {
              font-size: 20px;
              color: #333;
              height: 52px;
            }

            .color1 {
              padding: 1px 5px;
              border-radius: 4px;
              color: #51b875;
              border: 1px solid #51b875;
            }

            .color2 {
              padding: 1px 5px;
              border-radius: 4px;
              color: #ff7a29;
              border: 1px solid #ff7a29;
            }

            .color3 {
              padding: 1px 5px;
              border-radius: 4px;
              color: #999;
              border: 1px solid #999;
            }

            .lableBox {
              height: 23px;
              flex-wrap: wrap;
              overflow: hidden;

              p {
                padding: 2px 6px;
                background: #e5f4ff;
                border-radius: 4px;
                margin-right: 10px;
                text-align: center;
                color: #0493ff;
                font-size: 14px;
              }
            }
          }
        }

        .ulBox {
          .gzBox {
            .gzLi {
              margin-right: 16px;
              height: 48px;
              background: rgba(238, 246, 255, 1);
              border-radius: 8px;
              padding: 0 18px;
              margin-bottom: 16px;

              >div {
                &:first-child {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;

                  img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                  }
                }

                &:last-child {
                  font-size: 16px;
                  color: #2a3037;
                }
              }
            }
          }
        }
      }
    }

    .mainRight {
      width: 308px;
      min-height: 500px;

      .titleBox {
        border-radius: 8px 8px 0 0;
        height: 64px;
        align-items: center;
        padding: 0 22px;
        background: #ffffff;

        .titleLeft {
          p {
            font-size: 20px;
            font-weight: bold;
            color: #222427;
          }
        }
      }

      .mainBox {
        .ulBox {
          background: #ffffff;
          padding: 0 9px 9px 9px;
          border-radius: 0 0 8px 8px;

          .live-list-item {
            width: 292px;
            height: 148px;
            background: rgba(238, 246, 255, 1);
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 16px 8px 0 8px;
            box-sizing: border-box;
            .item-top {
              display: flex;
              .imgBox {
                width: 111px;
                height: 62px;
                border-radius: 8px;
                overflow: hidden;
                position: relative;
                img {
                  width: 100%;
                  height: 100%;
                }
                .live-status {
                  width: 52px;
                  height: 20px;
                  border-radius: 0 0 8px 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(255,255,255,1);
                  line-height: 20px;
                  text-align: center;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
                .live-status-color1 {
                  background: rgba(64,158,255,1);
                }
                .live-status-color2 {
                  background: rgba(255,122,41,1);
                }
                .live-status-color3 {
                  background: rgb(153,153,153);
                }
              }
              .txtbox {
                flex: 1;
                overflow: hidden;
                margin-left: 8px;
                  h3 {
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba(42,48,55,1);
                    margin-bottom: 4px;
                  }
                  p {
                    font-size: 14px;
                    color: rgba(102,102,102,1);
                  }
              }
            }
            .item-bottom {
              padding-top: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .item-bottom-left {
                .live-date {
                  font-size: 14px;
                  font-weight: bold;
                  color: rgba(255,122,41,1);
                }
                .live-num {
                  font-size: 14px;
                  color: rgba(102,102,102,1);
                }
              }
              .item-bottom-right {
                .live-button {
                  width: 70px;
                  height: 28px;
                  background: rgba(52,137,255,1);
                  border-radius: 28px;
                  font-size: 14px;
                  color: rgba(255,255,255,1);
                  text-align: center;
                  line-height: 28px;
                  display: block;
                  cursor: pointer;
                  &:hover {
                    background: rgb(84, 152, 248);
                  }
                }
              }
            }
          }

          .liBox {
            width: 292px;
            height: 148px;
            background: rgba(238, 246, 255, 1);
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 16px 8px 0 8px;
            box-sizing: border-box;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            .rightTop {
              .imgBox {
                width: 111px;
                height: 62px;

                img {
                  border-radius: 4px;
                }
              }

              .txtbox {
                width: 140px;

                h4 {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #2a3037;
                }

                p {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                  color: #666666;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.pTop {
  justify-content: space-between;

  .leftBox {
    width: 60px;

    .imgBox {
      width: 50px;
      height: 50px;

      img {
        border-radius: 50%;
      }
    }

    p {
      font-size: 14px;
      font-weight: normal;
      color: #333333;
    }
  }

  .rightBox {
    background: rgba(255, 248, 241, 1);
    border: 1px solid rgba(250, 140, 21, 1);
    border-radius: 4px;
    width: calc(100% - 80px);
    padding: 6px;
    box-sizing: border-box;

    ::v-deep .el-textarea__inner {
      background: #fff8f1;
      border: none;
      resize: none;
    }
  }
}

.pBom {
  margin-top: 20px;

  .pLiBox {
    margin-bottom: 20px;

    .bom1 {
      .imgBox {
        width: 50px;
        height: 50px;
        border: 1px solid #eee;
        border-radius: 50%;

        img {
          border-radius: 50%;
        }
      }

      .txtBox {
        width: calc(100% - 70px);
        border-bottom: 1px dashed #ccc;
        padding-bottom: 20px;

        .txt1 {
          align-items: center;

          p {
            &:first-child {
              font-size: 16px;
              font-weight: normal;
              color: #2766be;
            }

            &:last-child {
              font-size: 16px;
              font-weight: normal;
              color: #9a9ea5;
            }
          }
        }

        .txt2 {
          p {
            font-size: 16px;
            font-weight: normal;
            color: #333333;
            line-height: 28px;
          }
        }

        .txt3 {
          >div {
            font-size: 14px;
            font-weight: normal;
            color: #9a9ea5;
          }

          .lanseDiv {
            color: #0493ff;
          }
        }
      }
    }

    .inbox {
      background: rgba(255, 248, 241, 1);
      border: 1px solid rgba(250, 140, 21, 1);
      border-radius: 4px;
      width: 100%;
      padding: 6px;
      box-sizing: border-box;

      ::v-deep .el-textarea__inner {
        background: #fff8f1;
        border: none;
        resize: none;
      }
    }

    .bom2 {
      padding: 0 20px;
      box-sizing: border-box;
      background: #eef6ff;
      margin-left: 70px;
      border-radius: 8px;

      .pLiBox {
        padding-top: 16px;
      }

      .imgBox {
        width: 50px;
        height: 50px;
        border: 1px solid #eee;
        border-radius: 50%;

        img {
          border-radius: 50%;
        }
      }

      .txtBox {
        width: calc(100% - 70px);
        border-bottom: 2px dashed #fff;
        padding-bottom: 20px;

        .txt1 {
          align-items: center;

          p {
            &:first-child {
              font-size: 16px;
              font-weight: normal;
              color: #2766be;
            }

            &:last-child {
              font-size: 16px;
              font-weight: normal;
              color: #9a9ea5;
            }
          }
        }

        .txt2 {
          p {
            font-size: 16px;
            font-weight: normal;
            color: #333333;
            line-height: 28px;
          }
        }

        .txt3 {
          >div {
            font-size: 14px;
            font-weight: normal;
            color: #9a9ea5;
          }

          .lanseDiv {
            color: #0493ff;
          }

        }
      }
    }
  }
}

.video-box {
  width: 100%;
  height: 600px;

  .live-cover {
    width: 100%;
    height: 100%;
    background: #333;
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cover-title {
      font-size: 38px;
      color: #fff;
    }

    .cover-box {
      padding: 40px 30px;
      border-radius: 8px;
      background: #666;

      .cover-box-text-1 {
        font-size: 30px;
        color: #fff;
        text-align: center;
        line-height: 30px;

        .number {
          color: #4caf50;
        }
      }

      .cover-box-text-2 {
        font-size: 26px;
        color: #fff;
        text-align: center;
        line-height: 26px;
      }
    }
  }
}

.live-info-module {
  width: 100%;
  height: 83px;
  background: rgba(27,33,40,1);
  display: flex;
  margin-bottom: 20px;
  .live-info-left {
    flex: 1;
    overflow: hidden;
    padding-left: 24px;
    .live-title {
      font-size: 18px;
      font-weight: bold;
      color: rgba(234,234,234,1);
      margin-top: 15px;
    }
    .live-tag {
      margin-top: 6px;
      .tag-item {
        height: 22px;
        border: 1px solid rgba(130,146,158,1);
        border-radius: 4px;
        font-size: 14px;
        color: rgba(130,146,158,1);
        line-height: 22px;
        padding: 0 6px;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  .live-info-right {
    padding: 0 24px;
    display: flex;
    align-items: center;
    .live-info-opetation {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      margin-right: 30px;
      &:first-of-type {
        cursor: default;
        .operation-icon,
        .operation-text {
          color: rgba(130,146,158,1);
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
      .operation-icon {
        font-size: 16px;
        color: rgb(52,137,255);
        margin-right: 4px;
      }
      .operation-text {
        font-size: 16px;
        color: rgb(52,137,255);
      }
    }
    .opetation-active {
      .operation-icon,
      .operation-text {
        color: rgba(130,146,158,1);
      }
    }
  }
}

.share-box {
  display: flex;

  .share-url {
    flex: 1;
    overflow: hidden;

    .share-label {
      font-size: 18px;
      color: #333;
      line-height: 18px;
      margin-bottom: 12px;
    }

    .share-value {
      font-size: 16px;
      color: #999;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }

  .share-code {
    width: 90px;
    margin-left: 20px;

    .share-code-text {
      font-size: 14px;
      color: #333;
      line-height: 16px;
      margin-bottom: 6px;
    }

    .share-code-img {
      width: 90px;
      height: 90px;
    }
  }
}

.live-page-404 {
  width: 100%;
  font-size: 30px;
  color: #333;
  text-align: center;
  padding-top: 300px;
}
</style>
