var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goclasssteptwo"},[_c('div',{staticClass:"w_d center_one"},[_c('div',{staticClass:"bread"},[_c('div',{staticClass:"bread_content"},[_c('span',{staticClass:"span_one"},[_vm._v("当前位置：")]),_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("研修中心")]),_c('el-breadcrumb-item',[_vm._v("研修视频")]),_c('el-breadcrumb-item',[_c('span',{staticClass:"font_orange"},[_vm._v("创建课程")])])],1)],1),_c('div',{staticClass:"bread_hengxian"})]),_c('div',{staticClass:"one_content"},[_vm._m(0),_c('div',{staticClass:"word"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"word_four"},[_c('div',{staticClass:"startup",on:{"click":_vm.one}},[_vm._v("发布课程")]),_vm._m(4)])])])]),_c('div',{staticClass:"two_content"},[_c('div',{staticClass:"center_main-content w_d"},[_vm._m(5),_c('div',{staticClass:"right"},[_vm._m(6),_c('div',{staticClass:"right_two"},_vm._l((6),function(item,index){return _c('div',{key:index,staticClass:"two_block"},[_c('p',{staticClass:"goclass"},[_vm._v("万字干货！帮你深度掌握设计中的「光影」知识点")]),_vm._m(7,true),_c('div',{staticClass:"hengxian"})])}),0)])])]),_c('div',{staticClass:"mydialog"},[_c('el-dialog',{attrs:{"visible":_vm.deleteDialogVisible,"width":"400px","show-close":false,"top":"35vh"},on:{"update:visible":function($event){_vm.deleteDialogVisible=$event}}},[_c('div',{staticClass:"delete_dialog"},[_c('div',{staticClass:"delete_one"},[_c('div',{staticClass:"one_one"},[_c('i',{staticClass:"el-icon-close"})]),_c('p',[_vm._v("确定删除此课程吗？")])]),_c('div',{staticClass:"delete_two"},[_c('div',{staticClass:"two_cancel"},[_vm._v("取消")]),_c('div',{staticClass:"two_comfirm"},[_vm._v("确定")])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/images/live.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word_one"},[_c('p',[_vm._v("Adobe xd ui设计/交互动效教程入门到精通全集【51RGB在线教育】")]),_c('i',{staticClass:"el-icon-edit-outline"}),_c('i',{staticClass:"el-icon-delete"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word_two"},[_c('span',[_vm._v("学科学科：")]),_c('span',[_vm._v("小学语文")]),_c('span',[_vm._v("年级：")]),_c('span',[_vm._v("小学一年级")]),_c('span',[_vm._v("教材版本：")]),_c('span',[_vm._v("xxxx")]),_c('span',[_vm._v("适用章节：")]),_c('span',[_vm._v("章/节")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word_three"},[_c('span',[_vm._v("视频介绍：")]),_c('p',[_vm._v("构图、色彩和光影属于设计中非常重要的3块体系，但构图和色彩大多是偏感性的主观理解的凤凰山房符多是偏感性的主观理解的凤凰山房符")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word_four_one"},[_c('p',[_c('span',[_vm._v("温馨提示：")]),_vm._v("启动直播后，无法再编辑课程，请确保课程信息和内容无误后再发布。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left_one"},[_c('p',[_vm._v("视频内容")])]),_c('div',{staticClass:"left_two"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_one"},[_c('p',[_vm._v("上课资源")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"check"},[_c('p',[_vm._v("上传于 2020-04-21")]),_c('div',{staticClass:"check_right"},[_c('span',[_vm._v("查看")]),_c('span',[_vm._v("下载")])])])
}]

export { render, staticRenderFns }