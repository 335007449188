
<template>
  <!-- // 提交问卷 投票之前的列表 -->
  <div v-if="loading">
    <ul class="subject-list" v-if="subjectList.length > 0">
      <li v-for="(item, index) in subjectList" :key="index">
        <div>
          <div class="subject-title">
            <span class="subject-serial"><span class="serial-must" v-if="item.is_required==2">*</span>{{ index + 1 }}、</span>
            <div class="subject-content rich_text_div_img " v-html="item.title"></div>
            <span class="subject-type">
              [{{ item.type|subjectType(item.selected_type?item.selected_type:"") }}]
            </span>
          </div>
          <div class="subject-option option">

            <template v-if="item.type == 1">
              <div class="option-box" v-for="(item1, index1) in item.options" :key="index1" v-if="item.selected_type == 1">
                <el-radio :label="item1.id" v-model="item.option_id">
                  {{ item1.content }}
                </el-radio>
                <div class="input-box">
                  <el-input class="option-input" v-model="item1.option_content" v-if="item1.allow_fill_in==2"></el-input>
                  <span class="input-must" v-if="item1.fill_in_required==2">*</span>
                </div>
              </div>

              <div class="option-box" v-for="(item1, index1) in item.options" :key="index1" v-if="item.selected_type == 2">
                <el-checkbox v-model="item1.select">
                  {{ item1.content }}
                </el-checkbox>
                <div class="input-box">
                  <el-input class="option-input" v-model="item1.option_content" v-if="item1.allow_fill_in==2"></el-input>
                  <span class="input-must" v-if="item1.fill_in_required==2">*</span>
                </div>
              </div>
            </template>

            <!-- //填空 -->
            <template v-if="item.type == 2">
              <div v-for="(item1,index1) in item.options" :key="index1">
                <div class="input-box">
                  <el-input class="option-input option-input-m10" :placeholder="'填空'+(index1+1)" v-model="item1.option_content"></el-input>
                  <span class="input-must" v-if="item.is_required ==2">*</span>
                </div>
              </div>
            </template>

            <!-- //排序题 -->
            <template v-if="item.type == 3">
              <div class="option-box" v-for="(item1, index1) in item.options" :key="index1">
                <div class="option-sort">
                  <div class="sort-left">
                    <p class="option-text">{{ item1.content }}</p>
                    <div class="input-box">
                      <el-input class="option-input" v-model="item1.option_content" v-if="item1.allow_fill_in==2"></el-input>
                      <span class="input-must" v-if="item1.fill_in_required==2">*</span>
                    </div>
                  </div>
                  <div class="sort-right">
                    <span @click="onUpItem(index,item1, index1)">上移</span>
                    <span @click="onDownItem(index,item1, index1)">下移</span>
                  </div>
                </div>
              </div>
            </template>

            <!-- //量表题 -->

            <template v-if="item.type == 4">

              <!-- //除了多选矩阵显示表格 -->
              <table class="gauge-table" v-if="item.selected_type!=2">

                <tr>
                  <td></td>
                  <td v-for="(item1, index1) in item.options" :key="index1">
                    {{item1.content}}
                  </td>
                </tr>

                <tr v-if="item.selected_type==3" class="table-tr-score">
                  <td>分值</td>
                  <td v-for="(item1, index1) in item.options" :key="index1">{{item1.score}}</td>
                </tr>

                <tr v-for="(item1, index1) in item.lines" :key="index1">
                  <!-- //循环标题 -->
                  <td>
                    {{item1.title}}
                  </td>
                  <td v-for="(item1, index2) in item.options" :key="index2">
                    <div class="table-td-flex">

                      <!-- //多选 -->
                      <el-checkbox v-model="item1.select" v-if="item.selected_type==2" @change="getsid(item1)"></el-checkbox>

                      <!-- 单选 -->
                      <el-radio :label="item1.id" v-model="item.lines[index1].option_id" v-if="item.selected_type==1||item.selected_type==3">{{""}}
                      </el-radio>

                      <!-- 输入框 -->
                      <div class="input-box">
                        <el-input class="option-input" v-model="item.lines[index1].option_content" v-if="item1.allow_fill_in==2"></el-input>
                        <span class="input-must" v-if="item1.fill_in_required==2">*</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <!-- //多选矩阵 -->
              <div class="moreSelect" v-if="item.selected_type ==2">
                <!-- 左边标题加打钩 -->
                <div class="cBox" style="margin-left:50px">
                  <div v-for="(item2, index2) in item.lines" :key="index2" style="min-height:50px;margin-bottom:10px">
                    <!-- 行标题 -->
                    <p style="margin-right:20px">
                      {{item2.title}}:
                    </p>
                    <!-- 多选 -->
                    <el-checkbox-group style="display: inline-block;" v-model="item2.select">
                      <el-checkbox style="width:100%;margin-bottom:20px" v-for="(e) in item2.selectOption" :label="e.id" :key="e.id">

                        <span style="margin-right:10px;">{{e.content}}</span>
                        <span class="input-must" v-if="e.fill_in_required==2" style="color:red">*</span>
                        <el-input class="option-input" v-model="e.option_content" v-if="e.allow_fill_in==2"></el-input>

                      </el-checkbox>
                    </el-checkbox-group>

                    <!-- //必填填空 -->
                    <!-- <div style="display:flex;margin-left:20px">
                                  
                                  <el-input class="option-input" v-model="item.lines[index1].option_content" v-if="item1.allow_fill_in==2"></el-input>
                                </div> -->
                  </div>
                </div>
              </div>

            </template>
          </div>
        </div>
        <slot name="operate"></slot>
      </li>
    </ul>
    <div class="subject-operation" v-if="$store.state.activity_isJoin && state">
      <el-button type="primary" @click="submit()">提交</el-button>
    </div>
  </div>
</template>

<script>
import SubjectList from "./subjectList.js";
export default SubjectList;
</script>

<style lang="less" scoped>
@import "./subjectList.less";
</style>