import SubjectList from "../subjectList/subjectList.vue";
export default {
  components: {
    SubjectList,
  },
  props: {},
  data() {
    return {
      //问卷/投票列表参数
      subjectParam: {
        limit: 1000,
        page: 1,
        type: 0,
      },
      subjectList: [], //问卷/投票列表
      //题目参数
      subject: {
        title: "",
        details: "",
        type: "",
        topics: [],
        submit_doc: "",
      },
    };
  },
  created() {
    this.getBasicInfoData();
  },
  mounted() {},
  methods: {
    //获取基本信息
    getBasicInfoData() {
      const e = JSON.parse(sessionStorage.getItem("problem_basic_info_data"));
      this.subject.title = e.title;
      this.subject.details = e.details;
      this.subject.type = e.type;
      this.getSubjectList();
    },
    //复制按钮
    copyButton() {
      if (this.subject.topics.length > 0) {
        sessionStorage.setItem(
          "problem_subject_data",
          JSON.stringify(this.subject.topics)
        );
        this.$emit("submitData", true);
      } else {
        this.$message({
          message: `暂无题目，请选择左侧的
          ${this.subject.type == 1 ? "问卷" : "投票"}
          列表。`,
          type: "warning",
        });
      }
    },
    //取消按钮
    cancelButton() {
      this.$emit("goBack", true);
    },
    //获取问卷/投票列表
    async getSubjectList() {
      const res = await this.$axios.get("/questionnaire/get_my_list", {
        params: this.subjectParam,
      });
      this.subjectList = res.data.data.data;
    },
    //选择题目
    async choiceSubject(e) {
      const res = await this.$axios.get("/questionnaire/get_info", {
        params: { id: e.id },
      });
      const data = res.data.data;

      this.subject.topics = data.topics[1];
      // console.log(data.topics)
      // if (data.topics[1].length > 0) {
      //   let newList = [];
      //   data.topics[1].forEach((item) => {
      //     let newOptionList = [];
      //     if (item.options.length > 0) {
      //       item.options.forEach((item1) => {
      //         newOptionList.push({
      //           content: item1.content,
      //           is_write: 1,
      //         });
      //       });
      //       newList.push({
      //         title: item.title,
      //         type: item.type,
      //         option: newOptionList,
      //       });
      //     }
      //   });
      //   this.subject.topics = newList;
      // }
    },
  },
  watch: {},
};
