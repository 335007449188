<template>
  <!-- 话题详情页 -->
  <div class="Details_div public_main_bgc">
    <!-- 面包屑 -->
    <div class="bread" style="
        width: 1400px !important;
        height: 49px;
        display: flex;
        align-items: center;
      ">
      <div class="bread_content" style="margin: 0 !important">
        <span class="span_one">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/community' }">
            <span>研讨社区</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <span class="font_orange">话题详情</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- <div class="bread_hengxian"></div> -->
    </div>

    <!-- 正文 -->
    <div class="main_box">
      <!-- 布局 -->
      <div class="box_content">
        <!-- 主体内容 -->
        <div class="bottom">
          <div class="b_left">
            <!-- 头部标题 -->
            <div class="top_title">
              <div class="textbox">
                <h4 style="font-size: 24px; font-weight: 700">
                  {{ getDetail.title }}
                </h4>
                <!-- <div  v-if="getDetail.user_id != user_id">
                    <comPlain />
                 </div> -->
              </div>

              <div class="wz">
                <div class="wz_item">
                  <span>{{ getDetail.stage_name }}
                    {{ getDetail.subject_name }}</span>
                  <span>发起人：{{ getDetail.username }}</span>
                  <span>发起时间：{{ getDetail.create_time }}</span>
                  <span style="color: #333333">{{ getDetail.reply_count }}评论</span>
                  <span style="color: #333333">{{ getDetail.visit_count }}浏览</span>
                </div>
                <div class="btn" v-if="isToken">
                  <el-button type="primary" size="medium " @click="scrollele">发表评论</el-button>
                </div>
              </div>
            </div>
            <!-- 内容 -->
            <div class="contentItemOne">
              <p v-html="getDetail.content" class="rich_text_div_img" style="line-height: 32px"></p>
            </div>
            <div class="new_comment">
              <new-comment :activity_id="talk_id" :module_type="2" :random="newdata"></new-comment>
            </div>

            <!-- end -->
            <!-- 回复文本框 -->
            <!-- <div class="hf">
              <el-input
                type="textarea"
                rows="9"
                placeholder="请输入回复内容"
                v-model="CommentList.content"
              ></el-input
              ><br />
              <el-button
                type="primary"
                style="margin-top: 20px"
                @click="setComment"
                >发表评论</el-button
              >
            </div> -->
            <div class="new_releaseword" v-if="isToken">发表评论</div>
            <div class="new_release" v-if="isToken">
              <!-- <div class="seven_left">
                <div class="images">
                  <img src="@/assets/images/avator.png" />
                </div>
                <div class="left_word">{{ username }}</div>
              </div> -->
              <div class="seven_right" id="box2">
                <el-input type="textarea" :rows="6" placeholder="请输入回复内容" v-model="CommentList.content" resize="none"></el-input>
                <div class="release cursor" @click="setComment">发表评论</div>
              </div>
            </div>
          </div>
          <!-- 右边排行 -->
          <div class="b_right">
            <!-- 标题 -->
            <div class="title">
              <span>热门话题</span>
            </div>
            <!-- 内容循环部分 -->
            <div class="main_item" v-for="(item, index) in getHotList" :key="index">
              <div class="m_content">
                <div class="content_left">
                  <!-- <span>{{ item.id }}</span> -->
                  <div :class="
                      index + 1 == 1
                        ? 'span1'
                        : index + 1 == 2
                        ? 'span2'
                        : index + 1 == 3
                        ? 'span3'
                        : 'others'
                    ">
                    {{ index + 1 }}
                  </div>

                  <!-- <span>0{{ index + 1 }}</span> -->
                </div>
                <div class="content_right">
                  <h5 class="cursor" @click="toDetails(item.id)">
                    #{{ item.title }}
                  </h5>
                  <p>
                    <span>浏览：{{ item.visit_count }}</span>
                    <span>评论：{{ item.reply_count }}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- end循环 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //是否有token
      isToken: false,

      user_id: localStorage.getItem("id"),
      //回复list
      replayListData: "",
      //内嵌显示框
      isShow: -1,
      // 评论框变量
      num: false,
      // 评论列表
      gambitComment: "",
      currentPage4: 0,
      getHotList: "",
      //
      TopicList: "",
      // 评论话题
      form: {
        id: "",
        limit: "",
        page: "",
      },
      //发表评论
      CommentList: {
        gambit_id: "",
        user_id: "",
        content: "",
      },
      //点赞
      LikeData: {
        reply_id: "",
        user_id: "",
      },
      //回复话题list
      gambitReplyList: {
        id: "",
        reply_id: "1",
        page: "",
        limit: "",
      },
      //内嵌回复
      sendTopicList: {
        gambit_id: "",
        user_id: "",
        content: "",
        reply_user_id: "",
        top_reply_id: "1",
      },
      // 当前话题id
      talk_id: "",
      // 刷新评论组件
      newdata: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    getDetail() {
      return this.TopicList;
    },
  },
  methods: {
    async init() {
      this.talk_id = this.$route.query.id;
      this.newdata = 12;
      //初始化评论列表
      this.getInfor();
      this.initHot();
      // 初始化话题详情
      this.TopicDetail();

      this.isToken = localStorage.getItem("token") == null ? false : true;
    },

    //  内嵌框回复话题
    async sendTopicData(userid, rid) {
      // 从路由信息得到话题id
      let tid = this.$route.query.id;
      this.sendTopicList.gambit_id = tid;
      // 用户id
      this.sendTopicList.user_id = userid;
      // 被回复id
      this.sendTopicList.reply_user_id = rid;
      let res = await this.$axios.post(
        "gambitReply/set_reply",
        this.sendTopicList
      );
      console.log(res, "回复内容");
      // 重新渲染
      this.getReplyData();
      // 清空输入框
      this.sendTopicList.content = "";
    },
    // 初始化回复话题列表
    async getReplyData(rid, index) {
      // index控制显示隐藏
      this.isShow = index;

      this.gambitReplyList.id = rid;
      let res = await this.$axios.get("gambitReply/list", {
        params: {
          id: this.gambitReplyList.id,
          reply_id: this.gambitReplyList.reply_id,
          page: this.gambitReplyList.page,
          limit: this.gambitReplyList.limit,
        },
      });
      //回复列表
      this.replayListData = res.data.data.data;
      this.init();
    },
    // 点赞
    async setLike(rid, uid) {
      this.LikeData.reply_id = rid;
      this.LikeData.user_id = uid;
      let res = await this.$axios.post("gambitReply/set_like", this.LikeData);
      //重新遍历
      this.init();
    },
    // 评论话题
    async setComment() {
      function javaTrim(str) {
        for (var i = 0; str.charAt(i) == " " && i < str.length; i++);
        if (i == str.length) return ""; //whole string is space
        var newstr = str.substr(i);
        for (var i = newstr.length - 1; newstr.charAt(i) == " " && i >= 0; i--);
        newstr = newstr.substr(0, i + 1);
        return newstr;
      }
      var strValue = this.CommentList.content.replace(/\n/g, "");
      if (javaTrim(strValue) == "") {
        this.$message.info("输入不能为回车、空格、空");
      } else {
        // 当前话题id
        let tid = this.$route.query.id;
        // 从缓存中得到userid
        let uid = localStorage.getItem("id");
        // 赋值用户id
        this.CommentList.user_id = uid;
        //赋值话题id
        this.CommentList.gambit_id = tid;
        let res = await this.$axios.post(
          "gambitReply/set_comment",
          this.CommentList
        );
        this.$message.success("评论成功");
        this.newdata = this.newdata + 1;

        this.getInfor();
        this.CommentList.content = "";
      }
    },
    //初始化评论列表
    async getInfor() {
      // 当前话题id
      let tid = this.$route.query.id;
      this.form.id = tid;
      let res = await this.$axios.get("gambitComment/list", {
        params: {
          id: this.form.id,
          limit: this.form.limit,
          page: this.form.page,
        },
      });
      this.gambitComment = res.data.data.data;
    },
    //初始化热门话题数据
    async initHot() {
      let res = await this.$axios.get("/gambits/hot");
      this.getHotList = res.data.data;
    },
    //初始化话题详情
    async TopicDetail() {
      let id = this.$route.query.id;
      let res = await this.$axios.get(`gambit/${id}`);
      this.TopicList = res.data.data;
      console.log(res, "话题详情");
    },

    // 编辑个人信息
    editor_info() {
      this.$router.push("/personInfo");
    },
    //学段点击按钮变色
    getItem(index) {
      this.activeClass = index;
    },
    //学科点击按钮变色
    getItemOne(index) {
      this.activeClass_One = index;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 跳转话题详情页
    toDetails(uid) {
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: uid,
          t: Date.now(),
        },
      });
    },
    //点击滚动到相应元素
    scrollele() {
      document.getElementById("box2").scrollIntoView();
    },
  },
};
</script>

<style lang="less">
.Details_div {
  .el-textarea__inner {
    border: 1px solid #fff !important;
  }
  .bread {
    width: 80%;
    margin: 0 auto;
  }

  .el-breadcrumb__inner.is-link {
    color: #606266 !important;
    font-weight: 400;
    &:hover {
      color: #009dff;
    }
  }

  // /deep/ .is-link{
  //       color: #606266 !important;
  //       font-weight: 400;
  //     }
  // 中间
  .main_box {
    // background: #f7f7f7;
    // width: 1920px;

    .box_content {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      margin: 0 auto;
      width: 1400px;
      // 顶部
      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 14px;
      }

      // // 底部
      .bottom {
        width: 1400px;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        // margin-bottom: 60px;

        .b_left {
          width: 1045px;
          padding: 40px 30px;
          background: #fff;
          border: 1px solid #ebebeb;
          border-radius: 6px;
          margin-right: 20px;
          margin-bottom: 20px;
          //  左边内容
          .contentItemOne {
            padding-bottom: 20px;
            p {
              text-indent: 2em;
            }
          }
          //  头部标题
          .top_title {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            width: 985px;
            border-bottom: 1px dashed #ececec;
            padding-bottom: 20px;
            margin-bottom: 20px;
            .textbox {
              display: flex;
              align-items: center;
            }
            .wz {
              display: flex;
              justify-content: space-between;
              .wz_item {
                span {
                  margin-right: 20px;
                  font-size: 14px;
                  color: #999999;
                }
              }
            }
          }
          h4 {
            margin-bottom: 20px;
          }
          .b_contents {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            width: 985px;
            border-bottom: 1px solid #ececec;
            display: flex;
            justify-content: space-between;
            padding: 20px 20px;
            //  主体内容
            .item_main {
              //  头部
              .head_img {
                // 图片div
                display: flex;
                align-items: center;
                .imgs {
                  width: 50px;
                  height: 50px;
                  border: 1px solid aqua;
                  border-radius: 50%;
                  margin-right: 10px;
                  img {
                    width: 100%;
                  }
                }
                //文字
                .wz {
                  span {
                    font-size: 14px;
                  }
                  span:nth-of-type(1) {
                    color: #fa8c15;
                  }
                  span:nth-of-type(2) {
                    color: #9a9ea5;
                  }
                }
              }
              //  内容
              .main_detail {
                // 内嵌回复
                .hf_son {
                  border: 1px solid #9a9ea5;
                  background-color: #f7f7f7;
                  font-size: 16px;
                  margin-left: 70px;
                  border-radius: 4px;
                  margin-top: 10px;
                  padding: 10px 10px;
                  .xhBox {
                    display: flex;
                    margin-bottom: 20px;
                    border-bottom: 1px dashed #9a9ea5;
                    .son_left {
                      width: 10%;
                      div {
                        width: 50px;
                        height: 50px;
                        border: 1px solid aqua;
                        border-radius: 50%;
                        margin-right: 10px;
                        img {
                          width: 100%;
                        }
                      }
                    }
                    .son_right {
                      width: 90%;
                      div {
                        margin-bottom: 10px;
                      }
                      .box1 {
                        span {
                          color: #3489fe;
                        }
                      }
                      .box2 {
                      }
                      .box3 {
                        width: 100%;
                        height: 41px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .Item_left {
                          display: flex;
                          height: 31px;
                          line-height: 31px;
                        }
                        .Item_right {
                          color: #9a9ea5;
                          margin-right: 17px;
                        }
                      }
                    }
                  }
                }
                p {
                  font-size: 16px;
                  color: #333333;
                  margin-top: 10px;
                }
                .main_icon {
                  display: flex;
                  align-items: center;
                  margin-left: 700px;
                  margin-top: 10px;
                  div {
                    margin-right: 10px;
                  }
                  span {
                    margin-right: 20px;
                  }
                }
              }
            }
          }
        }
        .b_right {
          width: 335px;
          height: auto;
          border: 1px solid #ebebeb;
          background: #fff;
          border-radius: 6px;
          padding: 24px 24px;
          .title {
            margin-bottom: 20px;
            span {
              font-size: 16px;
              font-weight: bold;
            }
          }
          //  循环部分
          .main_item {
            margin-bottom: 36px;
            .m_content {
              display: flex;
              // justify-content: center;
              //  内容左边序号
              .content_left {
                // width: 50px;
                margin-right: 12px;
                .span1 {
                  width: 24px;
                  height: 24px;
                  border-radius: 2px;
                  text-align: center;
                  line-height: 24px;
                  font-size: 14px;
                  font-family: DIN Black Regular, DIN Black Regular-Regular;
                  font-weight: 400;
                  color: #fff;
                  background: #fa8c15;
                }
                .span2 {
                  width: 24px;
                  height: 24px;
                  border-radius: 2px;
                  text-align: center;
                  line-height: 24px;
                  font-size: 14px;
                  font-family: DIN Black Regular, DIN Black Regular-Regular;
                  font-weight: 400;
                  color: #fff;
                  background: #3489ff;
                }
                .span3 {
                  width: 24px;
                  height: 24px;
                  border-radius: 2px;
                  text-align: center;
                  line-height: 24px;
                  font-size: 14px;
                  font-family: DIN Black Regular, DIN Black Regular-Regular;
                  font-weight: 400;
                  color: #fff;
                  background: #00c1ae;
                }
                .others {
                  width: 24px;
                  height: 24px;
                  border-radius: 2px;
                  text-align: center;
                  line-height: 24px;
                  font-size: 14px;
                  font-family: DIN Black Regular, DIN Black Regular-Regular;
                  font-weight: 400;
                  color: #fff;
                  background: #c0c4cc;
                }
                // span {
                //   // font-size: 25px;
                //   // font-weight: bold;
                //   font-size: 36px;
                //   font-family: DIN Black Regular, DIN Black Regular-Regular;
                //   font-weight: 400;
                //   text-align: left;
                //   color: #666666;
                // }
              }
              //  内容右边
              .content_right {
                border-bottom: 1px dashed #ebebeb;
                width: 217px;
                padding-bottom: 35px;
                h5 {
                  font-size: 16px;
                  font-weight: 600;
                  color: #333333;
                  line-height: 26px;
                }
                h5:hover {
                  color: #3489fe;
                }
                p {
                  margin-top: 10px;
                  span {
                    font-size: 14px;
                    color: #999999;
                    margin-right: 20px;
                  }
                }
              }
            }
          }
          .main_item:last-child {
            margin-bottom: 0px;
          }
        }
      }
      //分页
      .block {
        margin-top: 50px;
        margin-left: 220px;
        margin-bottom: 30px;
      }
      //回复
      .hf {
        width: 975px;
        margin-top: 130px;
        margin-bottom: 50px;
      }
    }
  }
  .new_comment {
    margin-top: 50px;
  }
  .new_releaseword {
    font-size: 18px;
    font-weight: 700;
    color: #1a1a1a;
    margin-top: 50px;
    margin-bottom: 26px;
  }
  .new_release {
    margin-bottom: 37px;

    .seven_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .images {
        margin-bottom: 13px;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
    .seven_right {
      // width: 890px;
      width: 100%;
      height: 184px;
      border: 1px solid #ececec;
      border-radius: 4px;
      margin-right: 30px;
      padding-top: 20px;
      .release {
        width: 92px;
        height: 34px;
        background: #fa8c15;
        border-radius: 4px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        margin-right: 13px;
      }
      /deep/ .el-textarea__inner {
        border: none;
      }
    }
  }
}
</style>