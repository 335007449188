<template>
  <div class="TopicsIndex public_css_module">
    <div class="w_layout">
      <!-- 导航 -->
      <div class="topText">
        <div class="title">
          <p>
            当前位置：四名工作室 >
            <span>话题研讨</span>
          </p>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="bottom_div">
        <div class="leftBox_div">
          <div class="title_div">
            <div class="title_span">话题研讨</div>
            <template v-if="detailData.role != 0">
              <div class="btn_div" @click="sendTopic()" v-if="workData.is_addUser == 1 || workData.role">
                发起话题
              </div>
            </template>
          </div>

          <div class="list_div">
            <div class="list_item_1" v-for="(item, index) in TopicData" :key="index" @click="toDetails(item.id)">
              <div class="list_item_2">
                <div class="list_title ellipsis_1">#{{ item.title }} </div>
                <div class="list_msg_div ellipsis_2 rich_text_div_img" v-html="item.content"> </div>
                <div class="list_info">
                  <div class="info_left">
                    <div class="touxiang">
                      <img :src="item.photo_url" style="margin-right: 8px" alt="" />
                    </div>
                    <span>{{item.username || "-"}}于{{item.create_time || "-"}}发起</span>
                    <span>{{item.org_name || "-"}}</span>
                  </div>
                  <div class="info_right">
                    <div>
                      <img src="@/assets/images/personal/ll.png" style="margin-right: 8px" alt="" />
                      <span>浏览{{item.visit_count || 0}}</span>
                    </div>
                    <div style="margin-left: 40px">
                      <img src="@/assets/images/personal/hf.png" style="margin-right: 8px" alt="" />
                      <span>回复{{item.reply_count || 0}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="total" class="pagina_div">
              <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
                :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
          <CommonEmpty v-if="!TopicData.length" hVal="100%"></CommonEmpty>
        </div>

        <!-- 右边排行 -->
        <div class="b_right">
          <div class="title_right">
            <span>热门话题</span>
          </div>
          <div v-if="hotData.length > 0">
            <div class="main_item" v-for="(item, index) in hotData" :key="index">
              <div class="m_content">
                <div class="content_left">
                  <div :class="
                      index + 1 == 1
                        ? 'span1'
                        : index + 1 == 2
                        ? 'span2'
                        : index + 1 == 3
                        ? 'span3'
                        : 'others'
                    ">
                    {{ index + 1 }}
                  </div>
                </div>
                <div class="content_right">
                  <h5 class="title ellipsis_2" @click="toDetails(item.id)" :title="item.title">
                    #{{ item.title }}
                  </h5>
                  <p>
                    <span>浏览：{{ item.visit_count }}</span>
                    <span>评论：{{ item.reply_count }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <CommonEmpty v-if="!hotData.length" hVal="100%"></CommonEmpty>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["workData"],
  data() {
    return {
      total: 0,
      form: {
        limit: 12,
        page: 1,
        select_teaching_studio_id: localStorage.getItem("studioId"),
      },
      //热门话题
      hotData: [],
      //话题列表
      TopicData: [],
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
    };
  },
  created() {
    this.init();
    this.getAdminData();
  },
  methods: {
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    //初始化
    init() {
      this.HotTopict();
      this.TopicList();
    },
    //话题列表
    TopicList() {
      this.$axios.get("TeachingStudiogambits/listAll", { params: this.form, }).then((res) => {
        this.TopicData = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    //热门话题
    HotTopict() {
      this.$axios.get("TeachingStudio/gambits/hot", { params: { teaching_studio_id: localStorage.getItem("studioId") }, }).then((res) => {
        this.hotData = res.data.data;
      });
    },
    //发起话题
    sendTopic() {
      this.$router.push("/AddTopic");
    },
    toDetails(id) {
      this.$router.push({
        path: "/TopicDetail",
        query: {
          tid: id,
        },
      });
    },
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.TopicsIndex {
  padding-bottom: 30px;
  .topText {
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      height: 48px;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        span {
          color: #ff8201;
        }
      }
    }
  }

  .bottom_div {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    .leftBox_div {
      width: 1045px;
      box-sizing: border-box;
      padding-bottom: 30px;
      border: 1px solid #fff;
      background-color: #fff;
      border-radius: 6px;
      margin-right: 20px;
      margin-bottom: 60px;
    }
  }
}
.title_div {
  padding-top: 26px;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title_span {
    font-size: 16px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
  .btn_div {
    width: 90px;
    height: 34px;
    line-height: 34px;
    background: #3489fe;
    background: rgba(52, 137, 254, 1);
    text-align: center;
    border-radius: 4px;
    color: #fff;
  }
}
.list_div {
  .list_item_1 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 32px;
    cursor: pointer;
    &:hover {
      background-color: #f3faff;
      .list_title {
        color: #3489fe;
      }
    }
  }
  .list_item_2 {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 14px;
    border-bottom: 1px dashed #ececec;
    box-sizing: border-box;
    .list_title {
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: #333333;
    }
    .list_msg_div {
      margin-top: 12px;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      color: rgba(51, 51, 51, 1);
      max-height: 44px;
      overflow: hidden;
    }
    .list_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;
      > div {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: rgba(133, 144, 166, 1);
      }
      .touxiang {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info_left {
        display: flex;
        align-items: center;
        span {
          margin-right: 40px;
        }
      }
      .info_right {
        display: flex;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.b_right {
  width: 335px;
  min-height: 307px;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px 24px 0px 24px;
  .title_right {
    margin-bottom: 20px;
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .main_item {
    margin-bottom: 19px;
    .m_content {
      display: flex;
      .content_left {
        margin-right: 27px;
        .span1 {
          width: 24px;
          height: 24px;
          border-radius: 2px;
          text-align: center;
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          background: #fa8c15;
        }
        .span2 {
          width: 24px;
          height: 24px;
          border-radius: 2px;
          text-align: center;
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          background: #3489ff;
        }
        .span3 {
          width: 24px;
          height: 24px;
          border-radius: 2px;
          text-align: center;
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          background: #00c1ae;
        }
        .others {
          width: 24px;
          height: 24px;
          border-radius: 2px;
          text-align: center;
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          background: #c0c4cc;
        }
      }
      //  内容右边
      .content_right {
        border-bottom: 1px dashed #ebebeb;
        width: 217px;
        margin-top: -5px;
        padding-bottom: 19px;
        cursor: pointer;
        h5 {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 26px;
        }
        h5:hover {
          color: #3489fe;
        }
        .title {
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        p {
          margin-top: 10px;
          span {
            font-size: 14px;
            color: #999999;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .main_item:last-child {
    margin-bottom: 0px;
    .content_right {
      border-bottom: 0px dashed #ebebeb;
    }
  }
}
</style>