<template>
  <div class="navMenu">
    <!-- 数据统计 -->
    <div class="dataSum">
      <h4 class="pb15">数据统计</h4>
      <div class="numSty">
        <div class="cp" :class="index != 6 ? 'numone' : 'numtwo'" :style="{ background: item.bgc }"
          v-for="(item, index) in dataTotal" :key="index" @click="toPage(item)">
          <template v-if="index != 6">
            <div>
              <p :style="{ color: item.txc }">{{ item.count }}</p>
              <p>{{ item.name }}</p>
            </div>
          </template>
          <template v-if="index == 6">
            <div class="df">
              <p>{{ item.name }}：</p>
              <p :style="{ color: item.txc }">{{ item.count }}</p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 成员排行 -->
    <div class="Tearm">
      <div class="h4Box df dfb">
        <h4>成员排行</h4>
        <p class="cp" @click="goMemberList">更多></p>
      </div>
      <div v-if="termList.length > 0">
        <template v-for="(item, index) in termList">
          <div v-if="index < 6" class="dataFor" :key="index">
            <!-- 左边成员和icon -->
            <div class="icon">
              <div class="ims mr15">
                <img src="../../../../assets/navIcon/jin.png" alt="" v-if="index == 0" />
                <img src="../../../../assets/navIcon/yin.png" alt="" v-if="index == 1" />
                <img src="../../../../assets/navIcon/tong.png" alt="" v-if="index == 2" />
                <div class="circle" v-if="index > 2">
                  {{ index + 1 }}
                </div>
              </div>
              <img :src="item.photo_url" alt="" class="mr12" />
              <div class="ellipsis-1" style="width:120px" :title="item.username">
                {{ item.username }}
              </div>
            </div>
            <!-- //右边 -->
            <div class="txtClass">
              {{ item.sun }}
            </div>
          </div>
        </template>
      </div>
      <CommonEmpty v-else />
    </div>
    <!-- 最近访客 -->
    <div class="bgc_f visitorBox" style="border-radius: 4px">
      <div class="visitor">
        <h4>最近访客</h4>
        <div class="vBox" v-if="visitData.data && visitData.data.length > 0">
          <div class="vFor" v-for="(item, index) in visitData.data" :key="index">
            <div class="icon">
              <img :src="item.photo_url" alt="" />
            </div>
            <p class="ellipsis fz14" :title="item.username">{{ item.username }}</p>
            <!-- <p>{{item.login_time}}</p> -->
            <p>{{ item.login_time }}</p>
          </div>
        </div>
        <CommonEmpty hVal="306px" v-else />
      </div>
      <div class="visit">
        <span>今日访客：</span>
        <span class="mr24">{{ visitData.today }}</span>
        <span>总访客：</span>
        <span>{{ visitData.total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataTotal: [
        {
          bgc: "#cff1ee",
          txc: "#0DAC9E",
          name: "成员",
          count: 0,
          id: 1,
        },
        {
          bgc: "#caf0e5",
          txc: "#46C5A0",
          name: "四名讲堂",
          count: 0,
          id: 2,
        },
        {
          bgc: "#d2f0fc",
          txc: "#1EB8F7",
          name: "教研活动",
          count: 0,
          id: 3,
        },
        {
          bgc: "#f8e7c8",
          txc: "#E5A83D",
          name: "研修资源",
          count: 0,
          id: 4,
        },
        {
          bgc: "#dae2fd",
          txc: "#486DEB",
          name: "教师文章",
          count: 0,
          id: 5,
        },
        {
          bgc: "#e9dcf9",
          txc: "#9F6ADB",
          name: "活动报道",
          count: 0,
          id: 6,
        },
        {
          bgc: "#f4e2f9",
          txc: "#C765DF",
          name: "成果展示",
          count: 0,
          id: 7,
        },

      ],

      form: {
        teaching_studio_id: localStorage.getItem("studioId"),
        page: 1,
        limit: 9,
      },
      //   visitList: [],
      visitData: {}, //访客数据
      termList: [], //成员数据
      newInfoList: [], //动态数据
      //工作室数据
      workInfo: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 跳转
    toPage(item) {
      switch (Number(item.id)) {
        case 1:
          this.$router.push({
            path: "/memberList",
          });
          break;
        case 2:
          this.$router.push({
            path: "/TeachIndex",
          });
          break;
        case 3:
          this.$router.push({
            path: "/TrainActivityIndex",
          });
          break;
        case 4:
          this.$router.push({
            path: "/TrainResIndex",
          });
          break;
        case 5:
          this.$router.push({
            path: "/ArticleIndex",
          });
          break;
        case 6:
          this.$router.push({
            path: "/ReportIndex",
          });
          break;
        case 7:
          this.$router.push({
            path: "/Certificate",
          });
          break;
        default:
          break;
      }
    },
    //初始化
    init() {
      this.getVisiter(); //访客
      this.termSort(); //成员排行
      this.newInfo(); //最新动态
      this.WorkDetail();
    },
    //获取工作室详情
    WorkDetail() {
      this.$axios.get("index/TeachingStudio/TeachingStudioInfo", {
        params: {
          id: localStorage.getItem("studioId"),
          user_id: localStorage.getItem("id"),
        },
      }).then((res) => {
        this.workInfo = res.data.data;
        let {
          user_count,
          report_count,
          live_course_count,
          activity_count,
          resource_count,
          article_count,
          certificate_count,
        } = this.workInfo;
        this.dataTotal[0].count = user_count;
        this.dataTotal[1].count = live_course_count;
        this.dataTotal[2].count = activity_count;
        this.dataTotal[3].count = resource_count;
        this.dataTotal[4].count = article_count;
        this.dataTotal[5].count = report_count;
        this.dataTotal[6].count = certificate_count;
        // this.detailData=res.data.data;
      });
    },

    //访客
    getVisiter() {
      this.$axios.get("index/TeachingStudio/TeachingStudioVisitTime", {
        params: this.form,
      }).then((res) => {
        let newDate = new Date().getTime();
        this.visitData = res.data.data;
        this.visitData.data.map((e) => {
          let time = new Date(e.login_time).getTime();
          //   10分钟之内算刚刚（此处使用毫秒计算）
          if (newDate - time <= 600000) {
            e.login_time = "刚刚";
          } else {
            let o = e.login_time.split(" ")[0];
            let arr = o.split("-").slice(1);
            e.login_time = arr.join("-");
          }
        });
      });
    },

    //成员排行
    termSort() {
      this.$axios
        .get("index/TeachingStudio/RankingListTeachingStudioUserCount", {
          params: {
            teaching_studio_id: localStorage.getItem("studioId"),
          },
        })
        .then((res) => {
          this.termList = res.data.data;
        });
    },
    //最新动态
    newInfo() {
      this.$axios
        .get("index/TeachingStudio/ListTeachingStudioUserLog", {
          params: this.form,
        })
        .then((res) => {
          this.newInfoList = res.data.data.data;
        });
    },
    goMemberList() {
      this.$router.push({
        path: "/memberList"
      })
    },
  },
};
</script>

<style lang="less" scoped>
.navMenu {

  //数据统计
  .dataSum {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 274px;
    height: 310px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 16px;

    h4 {
      text-align: left;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .numSty {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .numone {
        width: 116px;
        height: 52px;
        margin-bottom: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 14px;
          color: rgb(51,51,51);

          &:first-child {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }

      .numtwo {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        padding-left: 30px;

        >div {
          justify-content: center;
          align-items: center;

          p {
            &:last-child {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }
    }

    .data_box {
      cursor: pointer;
    }

    .dataFor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 39px;
      border-bottom: 1px dashed #f0f0f0;

      .icon {
        display: flex;
        align-items: center;

        .im {
          width: 16px;
          height: 20px;
          margin-right: 14px;

          >img {
            width: 100%;
          }
        }
      }

      .txtClass {
        // width: 26px;
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: right;
        color: #333333;
      }
    }
  }

  //成员排行
  .Tearm {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 274px;
    height: 384px;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #fff;

    >h4 {
      text-align: left;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      height: 70px;
      line-height: 70px;
      padding: 0 16px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 235, 214, 1) 100%);
      background-position: 1px 1px;
      margin-left: 1px;
      margin-right: 1px;
      border-radius: 8px 8px 0 0;
    }

    .h4Box {
      height: 70px;
      line-height: 70px;
      padding: 0 16px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 235, 214, 1) 100%);
      background-position: 1px 1px;
      margin-left: 1px;
      margin-right: 1px;
      border-radius: 8px 8px 0 0;

      h4 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
    }

    >div {
      padding: 0 16px 16px 16px;

      .dataFor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;

        .icon {
          display: flex;
          align-items: center;

          .ims {
            width: 21px;
            height: 21px;
            margin-right: 13px;

            img {
              width: 21px;
              height: 21px;
            }

            .circle {
              width: 21px;
              height: 21px;
              line-height: 21px;
              background: #ebe7e7;
              border-radius: 50%;
              text-align: center;
              font-size: 9px;
              font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
              font-weight: 700;
              color: #8590a6;
            }
          }

          >img {
            width: 38px;
            height: 38px;
            border-radius: 4px;
          }
        }

        .txtClass {
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: bold;
          text-align: right;
          color: #333333;
        }
      }
    }
  }

  .visitorBox {
    padding-bottom: 16px;

    //访客
    .visitor {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      width: 274px;
      border-radius: 8px;
      background: #fff;

      h4 {
        text-align: left;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        height: 70px;
        line-height: 70px;
        padding: 0 16px;
        background: linear-gradient(0deg, rgba(148, 219, 245, 0) 0%, rgba(226, 245, 252, 1) 100%);
        background-position: 1px 1px;
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 8px 8px 0 0;
      }

      .vBox {
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px 16px 16px;

        .vFor {
          //图片
          margin-right: 37px;
          line-height: 22px;
          text-align: center;
          margin-bottom: 25px;

          &:nth-child(3n) {
            margin-right: 0;
          }

          .icon {
            margin: 0 auto;
            width: 38px;

            img {
              width: 38px;
              height: 38px;
              border-radius: 4px;
            }
          }

          //名字
          p:nth-of-type(1) {
            width: 56px;
            font-size: 14px;
            font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
            font-weight: 400;
            text-align: center;
            color: #333333;
          }

          p:nth-of-type(2) {
            // width:56px;
            font-size: 14px;
            font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
            font-weight: 400;
            text-align: center;
            color: #999999;
          }
        }
      }
    }

    .visit {
      text-align: center;
      margin: 0 16px;
      box-sizing: border-box;
      padding: 6px 0;
      font-size: 14px;
      background: #EEF6FF;
      border-radius: 8px;
    }
  }
}
</style>