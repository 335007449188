<template>
  <div class="public_css_module personal_right_box testPage">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的试题</div>
        <div v-for="(item, index) in list" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'" @click="showData(item.id)">
          <span>{{ item.name }}</span>
        </div>
      </div>
      <a :href="newhref" target="_blank" class="rightLink">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>创建试题</span>
      </a>
    </div>

    <!-- 我创建的内容区域 -->
    <div v-if="currentNum == 0" class="main" v-loading="listLoading_1">
      <div class="item_div" v-for="(item, index) in createList.list" :key="index">
        <div class="contents">
          <div class="item1">
            <span>【题目】</span>
            <div v-html="item.title" class="rich_text_div_img"></div>
          </div>
          <!-- <div class="item2">
            <img src="" alt="" />
          </div> -->
          <div class="item3" v-if="item.type == '选择题'">
            <!-- 第一行 -->
            <span style="color: #00b5a3" class="one_span">【选项】</span>
            <p>
              <span class="have_span_space">A、<span v-html="item.option_a" class="rich_text_div_img"></span></span>
              <span>B、<span v-html="item.option_b"></span></span><br />
              <span class="have_span_space">C、<span v-html="item.option_c" class="rich_text_div_img"></span></span>
              <span v-if="item.option_d">D、<span v-html="item.option_d" class="rich_text_div_img"></span></span>
              <br />
              <span v-if="item.option_e">E、<span v-html="item.option_e" class="rich_text_div_img"></span></span>
            </p>
          </div>
          <div class="info_div">
            <div class="item4">
              <p>
                <span>更新时间:
                  {{
                    item.update_time ? item.update_time : item.create_time
                  }}</span>
                <span>年级: {{ item.grade }}</span>
                <span>题型: {{ item.qtpye }}</span>
                <span>难度: {{ item.diffcn }}</span>
                <span>组卷次数: {{ item.use_number }}</span>
              </p>
            </div>
            <div class="item5">
              <el-button class="btn_1" type="text" @click="getintoedit()">编辑</el-button>
              <el-button class="btn_1" type="text" @click="deleteQuestion(item)">删除</el-button>
              <el-button class="btn_2" type="primary" @click="intoview(item)">查看</el-button>
            </div>
          </div>
        </div>
      </div>

      <CommonEmpty v-if="!listLoading_1 && createList.list && !createList.list.length" hVal="200px"></CommonEmpty>

      <div v-if="createList.list && createList.list.length != 0" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="fromcreate.page"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="fromcreate.limit" layout="total, sizes, prev, pager, next, jumper" :total="createList.count">
        </el-pagination>
      </div>
    </div>

    <!-- 我收藏的内容区域 -->
    <div v-if="currentNum == 1" class="main" v-loading="listLoading_2">
      <div class="item_div" v-for="(item, index) in collectionList.data" :key="index">
        <div class="contents">
          <div class="item1">
            <span>【题目】</span>
            <div v-html="item.title" class="rich_text_div_img"></div>
          </div>
          <!-- <div class="item2">
            <img src="" alt="" />
          </div> -->
          <div class="item3" v-if="item.type == '选择题' && item.option_a != ''">
            <!-- 第一行 -->
            <span style="color: #00b5a3" class="one_span">【选项】</span>
            <p>
              <span>A、</span><span v-html="item.option_a" class="have_span_space rich_text_div_img"></span>
              <span>B、</span><span v-html="item.option_b" class="rich_text_div_img"></span>
              <br />
              <span>C、</span><span v-html="item.option_c" class="have_span_space rich_text_div_img"></span>
              <span>D、</span><span v-html="item.option_d" class="have_span_space rich_text_div_img"></span>
              <br />
              <span v-if="item.option_e && item.option_e != ''">E、</span><span v-html="item.option_e" v-if="item.option_e && item.option_e != ''"
                class="rich_text_div_img"></span>
            </p>
          </div>
          <div class="info_div">
            <div class="item4">
              <p>
                <span>更新时间：{{
                    item.update_time ? item.update_time : item.create_time
                  }}</span>
                <span>年级：{{ item.grade }}</span>
                <span>题型：{{ item.qtpye }}</span>
                <span>难度：{{ item.diffcn }}</span>
                <span>组卷次数：{{ item.use_time }}</span>
              </p>
            </div>
            <div class="item5">
              <el-popover width="230" trigger="click" style="margin: 0 10px" @after-leave="correctionData = ''" v-model="item.correctionShow">
                <el-input type="textarea" :rows="3" placeholder="纠错原因..." v-model="correctionData">
                </el-input>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="correctionErr(item)">提交</el-button>
                </div>
                <el-button class="btn_1" type="text" slot="reference">纠错</el-button>
              </el-popover>

              <el-button class="btn_1" type="text" @click="cancelCollection(item)">取消收藏</el-button>
              <!-- <el-button class="btn_1" type="text" @click="collectionDeleteQuestion(item)">删除</el-button> -->
              <el-button class="btn_2" type="primary" @click="intoview(item)">查看</el-button>
            </div>
          </div>
        </div>
      </div>

      <CommonEmpty v-if="!listLoading_2 && !collectionList.data.length" hVal="200px"></CommonEmpty>

      <div v-if="collectionList.data && collectionList.data.length != 0" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="fromcollection.page"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="fromcollection.limit" layout="total, sizes, prev, pager, next, jumper"
          :total="collectionList.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      list: [
        {
          id: 0,
          name: "我创建的",
        },
        {
          id: 1,
          name: "我收藏的",
        },
      ],
      listLoading_1: true, //表格Loading
      listLoading_2: true, //表格Loading

      currentNum: 0,
      newhref: "",
      // 创建数据列表
      createList: {
        list: [],
        count: 0,
      },
      // 创建数据参数
      fromcreate: {
        page: 1,
        limit: 10,
      },
      // 收藏数据列表
      collectionList: {
        data: [],
        total: 0,
      },
      // 收藏数据参数
      fromcollection: {
        page: 1,
        limit: 10,
      },
      // 纠错数据
      correctionData: "",
      // 控制纠错框显示与隐藏
      // correctionShow:false
    };
  },
  created() {
    this.myCreate();
    this.myCollection();
    //得到瓣学网
    let aes_code = localStorage.getItem("aes_code");
    // this.newhref =
    //   "https://bxw.dc-it.cn/resource/#/ExaminationPaper" +
    //   "?aescode=" +
    //   aes_code;
    this.newhref =
      "http://www.xkyun.net/resource/#/testManage/createTest?type=personinfo" +
      "&aescode=" +
      aes_code;
  },
  methods: {
    showData(index) {
      this.$set(this.fromcreate, "page", 1);
      this.$set(this.fromcollection, "page", 1);
      this.currentNum = index;
      this.myCreate();
      this.myCollection();
    },
    // 我创建的
    async myCreate() {
      this.listLoading_1 = true;
      try {
        let obj = {
          params: {
            limit: this.fromcreate.limit,
            page: this.fromcreate.page,
          },
        };
        let { data } = await this.$axios.get(
          "/question/get_my_found_list",
          obj
        );
        this.createList = data.data;
        this.$nextTick(() => {
          this.listLoading_1 = false;
        });
      } catch (error) {
        this.$set(this.createList, "list", []);
        this.$set(this.createList, "count", 0);
        this.$nextTick(() => {
          this.listLoading_1 = false;
        });
      }
    },
    // 我收藏的
    async myCollection() {
      this.listLoading_2 = true;
      try {
        let obj = {
          params: {
            limit: this.fromcollection.limit,
            page: this.fromcollection.page,
          },
        };
        let { data } = await this.$axios.get(
          "/question/get_question_collect",
          obj
        );
        this.collectionList = data.data;
        this.$nextTick(() => {
          this.listLoading_2 = false;
        });
      } catch (error) {
        this.$set(this.collectionList, "data", []);
        this.$set(this.collectionList, "total", 0);
        this.$nextTick(() => {
          this.listLoading_2 = false;
        });
      }
    },
    handleSizeChange1(val) {
      this.$set(this.fromcreate, "page", 1);
      this.$set(this.fromcreate, "limit", val);
      this.myCreate();
    },
    handleCurrentChange1(val) {
      this.$set(this.fromcreate, "page", val);
      this.myCreate();
    },
    handleSizeChange2(val) {
      this.$set(this.fromcollection, "page", 1);
      this.$set(this.fromcollection, "limit", val);
      this.myCollection();
    },
    handleCurrentChange2(val) {
      this.$set(this.fromcollection, "page", val);
      this.myCollection();
    },
    // 跳转进入查看页面
    intoview(item) {
      this.$router.push({
        name: "testdetail",
        query: {
          question_id: item.question_id,
        },
      });
    },
    // 跳转到瓣学网编辑界面
    getintoedit() {
      let url = `http://www.xkyun.net/resource/#/testManage/mytest?aescode=${localStorage.getItem(
        "aes_code"
      )}`;
      window.open(url);
    },
    // 删除试题接口
    deleteQuestion(item) {
      this.$axios
        .delete(`question/del_question/${item.question_id}`)
        .then((res) => {
          this.$message.success("删除成功");
          this.myCreate();
        });
    },
    // 提交纠错原因
    correctionErr(item) {
      let obj = {
        id: item.question_id,
        msg: this.correctionData,
      };
      this.$axios.post("question/do_error", obj).then((res) => {
        this.$message.success("提交成功");
        // this.correctionData="";
        item.correctionShow = false;
      });
    },
    // 取消收藏函数
    cancelCollection(item) {
      let obj = {
        question_id: item.question_id,
      };
      this.$axios.post("question/collection_cancel", obj).then((res) => {
        this.$message.success("取消收藏成功");
        this.myCollection();
      });
    },
    // 我的收藏区域删除
    collectionDeleteQuestion(item) {
      this.$axios
        .delete(`question/del_question/${item.question_id}`)
        .then((res) => {
          this.$message.success(res.data.data.data);
          this.myCollection();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.testPage {
  width: 100%;
  background-color: #fff;
  min-height: 200px;
}
//主要内容部分
.main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .item_div {
    width: 100%;
    padding: 0px 32px;
    box-sizing: border-box;
    &:hover {
      background-color: #f3faff;
    }
  }
  .contents {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px dashed #dae7ed;
    box-sizing: border-box;
    .item1 {
      span {
        color: #3489fe;
        flex-shrink: 0;
      }
      font-size: 16px;

      display: flex;
      margin-bottom: 60px;
    }
    .item2 {
      width: 213px;
      height: 112px;
      border: 1px solid skyblue;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .item3 {
      display: flex;
      margin-bottom: 20px;
      .one_span {
        margin-right: 10px;
        flex-shrink: 0;
      }
      p {
        span {
          line-height: 30px;
        }
        .have_span_space {
          margin-right: 40px;
        }
      }
    }
    .info_div {
      width: 100%;
      height: 42px;
      display: flex;
      justify-content: space-between;
      background-color: #e0eeff;
    }
    .item4 {
      height: 100%;
      padding-left: 16px;
      box-sizing: border-box;
      p {
        line-height: 42px;
        text-align: center;
        font-size: 14px;
        color: #333;
        span {
          margin-right: 36px;
        }
      }
    }
    .item5 {
      display: inline-flex;
      align-items: center;
      padding-right: 10px;
      .btn_1 {
        padding: 0;
        margin: 0;
        margin-right: 40px;
        font-size: 15px;
        line-height: 32px;
        height: 32px;
        box-sizing: border-box;
      }
      .btn_2 {
        width: 92px;
        height: 32px;
        padding: 0;
        margin: 0;
        background: rgba(52, 137, 255, 1);
        border-radius: 8px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        font-size: 15px;
        line-height: 32px;
        box-sizing: border-box;
      }
    }
  }
}
.have_flex {
  display: flex;
}
</style>