// 量标题
export default {
  props: {
    //弹窗显示
    value: {
      type: Boolean,
      default: false,
    },
    //题目数据
    subjectData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogShow: false, //是否显示弹窗
      //选择题参数
      subject: {
        title: "",
        type: 4,
        is_required: 1,
        selected_type: 1,
        options: [
          {
            content: "",
            allow_fill_in: 1,
            fill_in_required: 1,
            score: 0,
            is_score: 1,
          },
          {
            content: "",
            allow_fill_in: 1,
            fill_in_required: 1,
            score: 0,
            is_score: 1,
          },
        ],
        lines: [],
      },
      //选择题验证
      subjectRules: {
        title: [{ required: true, message: "请输入题干", trigger: "blur" }],
        type: [
          { required: true, message: "请选择量表题类型", trigger: "change" },
        ],
      },
      //量表题类型选项
      subjectTypeOption: [
        { name: "矩阵单选", value: 1 },
        { name: "矩阵多选", value: 2 },
        { name: "矩阵量表", value: 3 },
      ],
      titleRow: "",
    };
  },
  created() {},
  mounted() {},
  methods: {

    //记不计分回调
    getTao(val,idx){
       console.log(val,idx,"计分");
    },


    //题目必填回调
    subjectRequiredChange(val) {
      if (val == 1) {
        this.subject.options.forEach((item) => {
          item.allow_fill_in = 1;
          item.fill_in_required = 1;
        });
      }
    },
    //允许填空回调
    writeAllowChange(val, index) {
      // if (val == 1) {
        this.subject.options[index].allow_fill_in=val;
      // }
      // if (val == 2) {
      //   this.subject.is_required = 2;
      // }
    },
    //填空必填回调
    writeRequiredChange(val, index) {
      // if (val == 2) {
        this.subject.options[index].fill_in_required = val;
        // this.subject.is_required = 2;
      // }
    },
    //上移选项
    upOptionButton(item, index) {
      this.subject.options.splice(index - 1, 0, item);
      this.subject.options.splice(index + 1, 1);
    },
    //下移选项
    downOptionButton(item, index) {
      this.subject.options.splice(index + 2, 0, item);
      this.subject.options.splice(index, 1);
    },
    //新增选项
    addOptionButton() {
     
      this.subject.options.push({
        content: "",
        allow_fill_in: 1,
        fill_in_required: 1,
        score: 0,
        //选择类型为3则是量标题  默认计分 则为2，不计分为1  this.subject.selected_type == 3 ? 2 :
        is_score:1,  
      });
    },
    //删除选项
    delOptionButton(index) {
      const options = JSON.parse(JSON.stringify(this.subject.options));
      if (options.length > 2) {
        this.subject.options.splice(index, 1);
      }
    },
    //弹窗关闭的回调
    dialogHandleClose() {
      this.$emit("input", false);
    },
    //取消按钮
    cancelButton() {
      this.$emit("input", false);
    },
    //确认按钮
    defineButton(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let titleRowList = [];
          if (this.titleRow.split(/[(\r\n)\r\n]+/).length > 0) {
            let titleRow = this.titleRow.split(/[(\r\n)\r\n]+/);
            titleRow.forEach((item) => {
              titleRowList.push({ title: item });
            });
          }
          this.subject.lines = titleRowList;

          let isComplete = true;
          for (let index = 0; index < this.subject.options.length; index++) {
            if (!this.subject.options[index].content) {
              isComplete = false;
              this.$message({
                message: `请输入选项 ${index + 1} 的内容`,
                type: "warning",
              });
              break;
            }
          }
          if (isComplete) {
            this.$emit("onSubject", this.subject);
            this.$emit("input", false);
          }
        }
      });
    },
  },
  watch: {
    //监听是否显示弹窗
    value(val) {
      this.dialogShow = val;
      console.log(this.subject,"数据2就2接口加I2加i");
      if (!this.dialogShow) {
        this.titleRow = "";
        this.subject = {
          title: "",
          type: 4,
          is_required: 1,
          selected_type: 1,
          options: [
            {
              content: "",
              allow_fill_in: 1,
              fill_in_required: 1,
              score: 0,
              is_score: 1,
            },
            {
              content: "",
              allow_fill_in: 1,
              fill_in_required: 1,
              score: 0,
              is_score: 1,
            },
          ],
          lines: [],
        };
        setTimeout(() => {
          this.$refs["subject"].clearValidate();
        }, 1000);
      }
    },
    //监听题目数据
    subjectData(e) {
      if (this.dialogShow) {
        this.subject = e;
        let lines = "";
        if (this.subject.lines.length > 0) {
          this.subject.lines.forEach((item, index) => {
            if (index == this.subject.lines.length - 1) {
              lines += item.title;
            } else {
              lines += item.title + "\n";
            }
          });
        }
        this.titleRow = lines;
      }
    },
    //监听问卷说明
    "subject.title"() {
      this.$nextTick(() => {
        this.$refs["subject"].validateField("title");
      });
    },
  },
};
