<template>
  <div class="listone">
    <ul :class="[typeVal == 1 ? 'tpuu' : typeVal == 2 ? 'checkuu' : 'visituu']" v-if="dataList.length > 0">
      <li :class="{ active: 0, selected: current == i }" class="fsbc" v-for="(e, i) in dataList" :key="i" @click="onTrigger(i)">

        <div class="df" style="flex:1;overflow:hidden;">
          <img :src="e.photo_url" />
          <div style="flex:1;overflow:hidden;">
            <p class="fw600 pb10 ellipsis" v-if="typeVal == 4">
              {{e.realname}}
              <span class="CompanyName_itext">{{e.organization_name || "-"}}</span>
            </p>
            <p class="fw600 pb10" v-else>{{e.username}}</p>
            <div class="fs14 c9" v-if="typeVal === 1">
              <span>计算机名：</span>
              <span>DESKTOP-S0L9P68</span>
            </div>

            <!-- //活动成员审核 -->
            <div v-else-if="typeVal === 2" class="fsbc fs14 c9">
              <span class="c3">{{e.stage_text}}{{e.subject_text}}</span>
              <div class="line"></div>
              <span>{{e.organization_name}}</span>
            </div>

            <p v-else-if="typeVal === 4" class="fs14 c9 ellipsis">
              <span class="studying_1 studying_2">学段：{{e.stage_text || "-"}}</span>
              <span class="studying_1">学科：{{e.subject_text || "-"}}</span>
            </p>

            <span v-else class="fs14 c9">{{e.org_name}}</span>

            <!-- <span class="member-chat" v-if="typeVal === 4">私信TA</span> -->
          </div>
        </div>

        <!-- //活动成员审核 -->
        <div class="is_pass fc" v-if="typeVal === 2">
          <p class="mr16 cursor" @click="onCheck(e,5)">不通过</p>
          <p @click="onCheck(e,4)" class="cursor">通过</p>
        </div>

        <!-- 投屏 -->
        <!-- 正在投屏（定位） -->
        <div class="toup" v-if="typeVal === 1">正在投屏</div>
        <!-- 投屏正方形（定位） -->
        <div class="carre" v-if="typeVal === 1 && current == i">
          <!-- 勾 -->
          <div class="gou"></div>
        </div>

      </li>
    </ul>
    <CommonEmpty v-else />
  </div>
</template>

<script>
import { CheckMembre } from "@/api/teacherStudio";
export default {
  props: {
    // 1投屏,2审核报名成员,3浏览记录,4研修活动参与教师
    typeVal: {
      type: Number,
      default: 1,
    },
    activityId: {
      type: Number,
      default: 1,
    },
    list: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      current: -1,
      dataList: []
    };
  },
  created() {
    this.dataList = this.list;
  },
  watch: {
    typeVal(val) {
      console.log(val, "val");
    },
    list(val) {
      this.dataList = val;
    }
  },
  methods: {
    onTrigger(i) {
      this.current = i;
    },
    //活动成员审核
    onCheck(item, type) {
      CheckMembre({
        id: item.id,
        // 4通过 5拒绝
        audit_status: type,
        activity_id: this.activityId,
      }).then(res => {
        this.$message.success("审核成功")
        this.$emit('onCallBack')
      })
    }
  },
};
</script>
 
<style lang="less" scoped>
.listone {
  ul {
    li {
      width: 320px;
      border: 1px solid #ebebeb;
      border-radius: 4px;
      padding: 10px 0 10px 14px;
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      position: relative;
      box-sizing: border-box;
      img {
        width: 58px;
        height: 58px;
        border-radius: 100%;
        margin-right: 17px;
      }
      &:hover {
        .member-chat {
          display: flex !important;
        }
      }
    }
  }
  /* 投屏类 */
  .tpuu {
    max-height: 380px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    li {
      &:nth-child(2n) {
        margin-right: 0;
      }
      .toup {
        position: absolute;
        right: 0;
        top: 0;
        padding: 2px 5px;
        background-color: #3489fe;
        color: #fff;
        font-size: 12px;
        border-radius: 0 0px 0 4px;
      }
      .carre {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-color: #ff8201;
        border-radius: 0 2px 0 2px;
        .gou {
          width: 6px;
          height: 8px;
          border-color: #fff;
          border-style: solid;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg) translate(7px, -3px);
        }
      }
      &.active {
        border: 1px solid #3489fe;
      }
      &.selected {
        border: 1px solid #ff8201;
      }
      &:hover {
        border: 1px solid #ff8201;
        box-shadow: 0px 4px 7px 0px rgba(255, 130, 1, 0.1);
      }
    }
  }
  /* 审核报名成员类 */
  .checkuu {
    max-height: 400px;
    overflow-y: auto;
    li {
      width: 556px;
      padding: 10px 30px 10px 14px;
      margin-right: 0;
      .line {
        width: 1px;
        height: 14px;
        background: #ebebeb;
        margin: 0 12px;
      }
      .is_pass {
        > p {
          width: 72px;
          height: 36px;
          line-height: 36px;
          border: 1px solid #e9e9e9;
          border-radius: 4px;
          color: #999;
          font-weight: 600;
          text-align: center;
          &:nth-child(2) {
            background: #3489fe;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }

  .member-chat {
    width: 66px;
    height: 32px;
    background: #3489fe;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 22px;
    display: none;
  }

  /* 浏览记录 */
  .visituu {
    max-height: 366px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 338px;
      height: 77px;
      background-color: #eff6ff;
      border-radius: 8px;
      border: none;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .CompanyName_itext {
      margin-left: 6px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }
    .studying_1 {
      font-size: 14px;
      font-weight: normal;
      color: rgba(102, 102, 102, 1);
    }
    .studying_2 {
      margin-right: 30px;
    }
  }
}
</style>