<template>
  <div class="createactivity">
    <div class="w_d center">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>教研活动</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">创建活动</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="stepBar">
        <div class="step_center">
          <div class="one">
            <div class="circular">
              <div class="circular_center active">1</div>
            </div>
            <div class="hengxian"></div>
            <div class="circular">
              <div class="circular_center">2</div>
            </div>
            <div class="hengxian"></div>
            <div class="circular">
              <div class="circular_center">3</div>
            </div>
          </div>
          <div class="two">
            <p class="word_one active">活动信息设置</p>
            <p class="word_two">活动环节设置</p>
            <p class="word_three">创建活动</p>
          </div>
        </div>
      </div>
      <!-- 活动信息设置区域 -->
      <div class="step_one" v-if="data1 == 'one'">
        <div class="main">
          <el-form ref="form" label-position="right" label-width="120px">
            <el-form-item label="直播封面：">
              <div class="one">
                <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="活动名称：">
              <el-input placeholder="请输入教研活动名称" class="two"></el-input>
            </el-form-item>
            <el-form-item label="活动描述：">
              <!-- <el-input type="textarea" :rows="6" class="three"></el-input> -->
              <div class="three">
                <Editor></Editor>
              </div>
            </el-form-item>
            <el-form-item label="适合学段：">
              <el-select placeholder="请选择学段" class="two">
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="适合学科：">
              <el-select placeholder="请选择学科" class="two">
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-select placeholder="请选择活动开展方式" class="two">
                <el-option></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="four">
                <el-radio v-model="radio" label="1">不限</el-radio>
                <el-radio v-model="radio" label="2">指定时间：</el-radio>
                <el-date-picker v-model="value1" type="date" placeholder="开始日期" class="four_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="开始时间"></el-time-picker>
                <div class="four_two">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期" class="four_one"></el-date-picker>
                <el-time-picker v-model="value1" placeholder="结束时间"></el-time-picker>
              </div>
            </el-form-item>

            <el-form-item label="活动发起人：">
              <div>
                <el-radio v-model="radio" label="1">个人</el-radio>
                <el-radio v-model="radio" label="2">教研组</el-radio>
              </div>
              <div class="five">
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              </div>
            </el-form-item>

            <el-form-item label="允许谁参与：">
              <div>
                <el-radio v-model="radio" label="1">所有人</el-radio>
                <el-radio v-model="radio" label="2">本校成员</el-radio>
                <el-radio v-model="radio" label="3">指定教研组</el-radio>
                <el-radio v-model="radio" label="4">指定成员</el-radio>
              </div>
              <div class="five">
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
                <el-radio v-model="radio" label="1">xxx教研组1</el-radio>
              </div>
              <div class="six">
                <div class="nine_left">
                  <div class="nine_left_one">
                    <div>选择指定成员</div>
                    <i class="el-icon-d-arrow-right"></i>
                  </div>
                  <div class="nine_left_two">清空所有</div>
                </div>
                <div class="nine_right">
                  <el-tag closable effect="dark" v-for="(item, index) in 30" :key="index" class="nine_tag">标签一</el-tag>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="save">
          <!-- <el-button class="commond_button save_one">取消</el-button> -->
          <el-button class="commond_button" type="warning" @click="one('two')">下一步</el-button>
        </div>
      </div>
      <!-- 活动环节设置区域 -->
      <div class="step_two" v-if="data1 == 'two'">
        <div class="main">
          <div class="one">
            <el-select v-model="value" placeholder="请选择活动类型" class="one_one">
              <el-option></el-option>
            </el-select>
          </div>
          <div class="two">
            <div class="two_left">
              <div class="left_block cursor active">
                <p>模板模板模板模板模板模板模板模板</p>
              </div>
              <div class="left_block cursor" v-for="(item, index) in 6" :key="index">
                <p>模板模板模板模板模板模板模板模板</p>
              </div>
            </div>
            <div class="two_right cursor">+ 自定义</div>
          </div>
          <div class="three"></div>
          <div class="four">
            <div class="four_block" v-for="(item, index) in 6" :key="index">
              <div class="block_one">1</div>
              <div class="block_two">
                <p>
                  环节名称环节名称环节名称环节名称环节名称环节名称名称环节名称名称环节名称
                </p>
              </div>
              <div class="block_three">
                <p>资源浏览</p>
              </div>
              <div class="block_four">
                <p>2019-9-20 - 2019-9-25</p>
              </div>
              <div class="block_five">
                <p class="cursor">修改</p>
              </div>
              <div class="block_six">
                <p class="cursor">删除</p>
              </div>
            </div>
          </div>
          <div class="five">
            <el-select v-model="value" placeholder="+ 增加活动环节" class="five_one">
              <el-option @click="two">asd</el-option>
            </el-select>
            <p>
              <span>*</span>
              <span @click="two">点击创建各种活动环节，包括：资源浏览、讨论、问卷、投票、资源上传、协作文档、在线直播</span>
            </p>
          </div>
          <div class="save">
            <el-button class="commond_button save_one">上一步</el-button>
            <el-button class="commond_button" type="warning" @click="one('three')">下一步</el-button>
          </div>
        </div>
      </div>
      <!-- 创建活动区域 -->
      <div class="step_three" v-if="data1 == 'three'">
        <div class="main">
          <div class="images">
            <img src="@/assets/images/gain.png" />
          </div>
          <p class="main_word">创建成功！</p>
          <div class="other_word">
            <span>继续创建>></span>
            <span>或</span>
            <span class="cursor" @click="three">查看活动>></span>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出资源浏览环节对话框 -->
    <el-dialog title="添加资源浏览环节" :visible.sync="resourceViewDialog" width="60%">
      <div class="resource_dialog">
        <div class="hengxian"></div>
        <div class="resource_main">
          <el-form ref="form" label-position="right" label-width="100px">
            <el-form-item label="环节名称：">
              <el-input placeholder="请输入环节名称" class="one"></el-input>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期"></el-date-picker>
                <div class="two_one">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="添加资源：">
              <div class="three">
                <el-button type="primary" class="commond_button">上传资源</el-button>
                <span>或</span>
                <el-button type="primary" class="commond_button">上传资源</el-button>
              </div>
            </el-form-item>
            <el-form-item label="环节描述：">
              <!-- 富文本编辑器组件 -->
              <div class="four">
                <Editor></Editor>
              </div>
            </el-form-item>
          </el-form>
          <div class="last">
            <div class="save">
              <el-button class="commond_button save_one">取消</el-button>

              <el-button class="commond_button" type="warning">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹出添加问卷环节对话框 -->
    <el-dialog title="添加问卷环节" :visible.sync="questionNaireDialog" width="60%">
      <div class="resource_dialog">
        <div class="hengxian"></div>
        <div class="questionNaire_main">
          <el-form ref="form" label-position="right" label-width="100px">
            <el-form-item label="环节名称：">
              <el-input placeholder="请输入环节名称" class="one"></el-input>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期"></el-date-picker>
                <div class="two_one">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="添加问卷：">
              <div class="three">
                <el-select class="three_one">
                  <el-option></el-option>
                </el-select>
                <span>没有问卷?</span>
                <span>创建问卷>></span>
              </div>
            </el-form-item>
            <el-form-item label="环节描述：">
              <!-- 富文本编辑器组件 -->
              <div class="four">
                <Editor></Editor>
              </div>
            </el-form-item>
          </el-form>
          <div class="last">
            <div class="save">
              <el-button class="commond_button save_one">取消</el-button>

              <el-button class="commond_button" type="warning">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹出添加投票环节对话框 -->
    <el-dialog title="添加投票环节" :visible.sync="voteDialog" width="60%">
      <div class="resource_dialog">
        <div class="hengxian"></div>
        <div class="questionNaire_main">
          <el-form ref="form" label-position="right" label-width="100px">
            <el-form-item label="环节名称：">
              <el-input placeholder="请输入环节名称" class="one"></el-input>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期"></el-date-picker>
                <div class="two_one">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="添加投票：">
              <div class="three">
                <el-select class="three_one">
                  <el-option></el-option>
                </el-select>
                <span>没有投票?</span>
                <span>创建投票>></span>
              </div>
            </el-form-item>
            <el-form-item label="环节描述：">
              <!-- 富文本编辑器组件 -->
              <div class="four">
                <Editor></Editor>
              </div>
            </el-form-item>
          </el-form>
          <div class="last">
            <div class="save">
              <el-button class="commond_button save_one">取消</el-button>

              <el-button class="commond_button" type="warning">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹出添加讨论/资源上传环节对话框 -->
    <el-dialog title="添加讨论/资源上传环节" :visible.sync="commentDialog" width="60%">
      <div class="resource_dialog">
        <div class="hengxian"></div>
        <div class="questionNaire_main">
          <el-form ref="form" label-position="right" label-width="100px">
            <el-form-item label="环节名称：">
              <el-input placeholder="请输入环节名称" class="one"></el-input>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期"></el-date-picker>
                <div class="two_one">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="环节描述：">
              <!-- 富文本编辑器组件 -->
              <div class="four">
                <Editor></Editor>
              </div>
            </el-form-item>
          </el-form>
          <div class="last">
            <div class="save">
              <el-button class="commond_button save_one">取消</el-button>

              <el-button class="commond_button" type="warning">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹出添加协作文档环节对话框 -->
    <el-dialog title="添加协作文档环节" :visible.sync="cooperationDialog" width="60%">
      <div class="resource_dialog">
        <div class="hengxian"></div>
        <div class="cooperation_main">
          <el-form ref="form" label-position="right" label-width="100px">
            <el-form-item label="环节名称：">
              <el-input placeholder="请输入环节名称" class="one"></el-input>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期"></el-date-picker>
                <div class="two_one">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="环节描述：">
              <!-- 富文本编辑器组件 -->
              <div class="four">
                <Editor></Editor>
              </div>
            </el-form-item>
            <el-form-item label="文档类型：">
              <div class="five">
                <div class="five_one">
                  <img src="@/assets/images/word.png" />
                </div>
                <div class="five_one">
                  <img src="@/assets/images/excel.png" />
                </div>
                <div class="five_one">
                  <img src="@/assets/images/ppt.png" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="文档标题：">
              <el-select>
                <el-option></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="last">
            <div class="save">
              <el-button class="commond_button save_one">取消</el-button>

              <el-button class="commond_button" type="warning">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 弹出添加在线直播对话框 -->
    <el-dialog title="添加在线直播环节" :visible.sync="liveDialog" width="60%">
      <div class="resource_dialog">
        <div class="hengxian"></div>
        <div class="live_main">
          <el-form ref="form" label-position="right" label-width="100px">
            <el-form-item label="环节名称：">
              <el-input placeholder="请输入环节名称" class="one"></el-input>
            </el-form-item>
            <el-form-item label="开展方式：">
              <el-radio v-model="radio" label="1">线上</el-radio>
              <el-radio v-model="radio" label="2">线下</el-radio>
            </el-form-item>
            <el-form-item label="起止时间：">
              <div class="two">
                <el-date-picker v-model="value1" type="date" placeholder="开始日期"></el-date-picker>
                <div class="two_one">-</div>
                <el-date-picker v-model="value1" type="date" placeholder="结束日期"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="环节描述：">
              <!-- 富文本编辑器组件 -->
              <div class="four">
                <Editor></Editor>
              </div>
            </el-form-item>
            <el-form-item label="直播封面：">
              <div class="five">
                <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="直播主题：">
              <el-input class="six"></el-input>
            </el-form-item>
          </el-form>
          <div class="last">
            <div class="save">
              <el-button class="commond_button save_one">取消</el-button>

              <el-button class="commond_button" type="warning">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 选择用户对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="userDialog" width="1342px" :show-close="false">
        <div class="user_content">
          <div class="title">
            <div class="title_word">选择用户</div>
          </div>
          <div class="hengxian"></div>
          <div class="main">
            <div class="main_one">
              <p>地区：</p>
              <el-select v-model="value" placeholder="选择省市">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="选择省市">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="选择省市">
                <el-option></el-option>
              </el-select>
              <p>学段学科：</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择">
                <el-option></el-option>
              </el-select>
            </div>
            <div class="main_two">
              <p>姓名：</p>
              <el-select v-model="value" placeholder="请输入姓名">
                <el-option></el-option>
              </el-select>
              <p>单位：</p>
              <el-select v-model="value" placeholder="请输入所属单位">
                <el-option></el-option>
              </el-select>
              <p>教研组：</p>
              <el-select v-model="value" placeholder="请输入所属教研组">
                <el-option></el-option>
              </el-select>
              <div class="reset_button">重置</div>
              <div class="sousuo_button">查询</div>
            </div>
            <div class="main_three">
              <el-radio v-model="radio" label="1">全部</el-radio>
              <el-radio v-model="radio" label="2">已选</el-radio>
            </div>
            <div class="main_four">
              <el-table height="220" :data="tableData">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="姓名"> </el-table-column>
                <el-table-column label="学段"> </el-table-column>
                <el-table-column label="学科"> </el-table-column>
                <el-table-column label="所属教研组"> </el-table-column>
                <el-table-column label="所属单位"> </el-table-column>
                <el-table-column label="所属地区"> </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="pagin">
            <el-pagination background :current-page="1" :page-size="100" layout="prev, pager, next, jumper" :total="1000"></el-pagination>
          </div>
          <div class="footer_save">
            <el-button class="commond_button cancel">取消</el-button>
            <el-button class="commond_button save" type="warning" @click="go">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resourceViewDialog: true,
      questionNaireDialog: true,
      voteDialog: false,
      commentDialog: false,
      cooperationDialog: false,
      liveDialog: true,
      data1: "one",
      userDialog: true,
    };
  },
  methods: {
    one(str) {
      this.data1 = str;
    },
    two() {
      this.dialog = true;
    },
    three() {
      this.$router.push({ name: "basicinfo" });
    },
  },
};
</script>

<style scoped lang="less">
.createactivity {
  display: flex;
  justify-content: center;
  .center {
    // border: 1px solid black;
    .save {
      margin-top: 51px;
      margin-bottom: 60px;
      display: flex;
      justify-content: center;
      .save_one {
        margin-right: 22px;

        background-color: #ececec;
        // color: #333333;
      }
    }
    .stepBar {
      margin-top: 91px;
      margin-bottom: 70px;
      display: flex;
      justify-content: center;
      .step_center {
        .one {
          display: flex;
          align-items: center;
          margin-left: 20px;
          .circular {
            width: 46px;
            height: 46px;
            border: 1px solid #cecece;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            .circular_center {
              width: 36px;
              height: 36px;
              background-color: #cecece;
              border-radius: 50%;
              color: #ffffff;
              font-weight: 700;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .active {
              background-color: #3489fe;
            }
          }
          .hengxian {
            width: 150px;
            height: 3px;
            background-color: #cecece;
          }
        }
        .two {
          display: flex;
          margin-top: 20px;
          color: #cecece;
          .word_one {
            margin-right: 100px;
          }
          .active {
            color: #3489fe;
          }
          .word_two {
            margin-right: 120px;
          }
        }
      }
    }
    .step_one {
      .main {
        display: flex;
        justify-content: center;
        // margin-top: 70px;
        .one {
          border: 1px dashed #d9d9d9;
          width: 178px;
          .avatar-uploader .el-upload {
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
          }
          .avatar {
            width: 178px;
            height: 178px;
            display: block;
          }
        }
        .two {
          width: 400px;
        }
        .three {
          width: 1031px;
          /deep/ .ql-editor {
            height: 160px;
            // width: 1031px;
          }
        }
        .four {
          display: flex;
          align-items: center;
          .four_one {
            margin-right: 14px;
            width: 180px;
          }
          .four_two {
            margin: 0px 26px;
          }
        }
        .five {
          width: 550px;
          height: 100px;
          border: 1px solid #ececec;
          border-radius: 0px 0px 4px 4px;
          overflow: auto;
          padding-left: 20px;
        }
        .six {
          width: 1020px;
          height: 100px;
          border: 1px solid #ececec;
          border-radius: 0px 0px 4px 4px;
          display: flex;

          .nine_left {
            width: 130px;
            height: 100%;
            border-right: 1px solid #ececec;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            .nine_left_one {
              width: 100px;
              height: 40px;
              background: #409eff;
              border-radius: 4px;
              line-height: 24px;
              color: #ffffff;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              margin-top: 20px;
            }
            .nine_left_two {
              color: #9a9ea5;
              cursor: pointer;
            }
          }
          .nine_right {
            padding-top: 10px;
            padding-left: 20px;
            overflow: auto;
            .nine_tag {
              margin-right: 10px;
            }
          }
        }
        /deep/ .el-form-item__label {
          font-size: 16px;
          font-weight: 400;
          color: #2b2b2b;
        }
      }
      //   .save {
      //     margin-top: 51px;
      //     margin-bottom: 60px;
      //     display: flex;
      //     justify-content: center;
      //     .save_one {
      //       margin-right: 22px;

      //       background-color: #ececec;
      //       // color: #333333;
      //     }
      //   }
    }
    .step_two {
      display: flex;
      justify-content: center;
      .main {
        .one {
          .one_one {
            width: 400px;
          }
          margin-bottom: 26px;
        }
        .two {
          display: flex;
          .two_left {
            width: 952px;
            display: flex;
            //   justify-content: space-between;
            flex-wrap: wrap;
            .left_block {
              width: 176px;
              height: 34px;
              background-color: #cecece;
              border-radius: 4px;
              margin-bottom: 18px;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              p {
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
              }
            }
            .active {
              background: #3489fe;
            }
          }
          .two_right {
            width: 149px;
            height: 34px;
            background: #ffffff;
            border: 1px solid #ff8201;
            border-radius: 4px;
            font-size: 14px;
            color: #ff8201;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .three {
          width: 1200px;
          height: 1px;
          background: #f0f0f0;
          margin-top: 25px;
          margin-bottom: 20px;
        }
        .four {
          .four_block {
            width: 1200px;
            height: 80px;
            border-bottom: 1px dashed #f0f0f0;
            display: flex;
            justify-content: center;

            align-items: center;
            .block_one {
              width: 27px;
              height: 27px;
              background: #3489fe;
              border-radius: 50%;
              font-size: 12px;
              font-weight: 700;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 50px;
            }
            .block_two {
              margin-right: 104px;
              p {
                width: 345px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .block_three {
              margin-right: 100px;
              p {
                width: 73px;
              }
            }
            .block_four {
              margin-right: 110px;
              p {
                width: 180px;
              }
            }
            .block_five {
              margin-right: 67px;
              p {
                color: #368afe;
              }
            }
            .block_six {
              p {
                color: #368afe;
              }
            }
          }
        }
        .five {
          margin-top: 30px;
          display: flex;
          align-items: center;
          .five_one {
            width: 400px;
            margin-right: 23px;
          }
          p {
            span:nth-child(1) {
              color: #ff0607;
            }
            color: #9ca0a7;
          }
        }
      }
    }
    .step_three {
      margin-top: 109px;
      display: flex;
      justify-content: center;
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        .images {
          width: 90px;
          height: 90px;
        }
        .main_word {
          font-weight: 700;
          margin: 19px 0px 50px 10px;
        }
        .other_word {
          margin-bottom: 160px;
          span:nth-child(odd) {
            color: #3489fe;
            cursor: pointer;
          }
          span:nth-child(2) {
            margin: 0px 15px;
          }
        }
      }
    }
  }
  .resource_dialog {
    .hengxian {
      width: 100%;
      height: 1px;
      background: #dedede;
    }
    .resource_main {
      margin-top: 44px;
      .one {
        width: 400px;
      }
      .two {
        display: flex;
        .two_one {
          margin: 0 20px;
        }
      }
      .three {
        span {
          margin: 0 20px;
          font-size: 16px;
        }
      }
      .four {
        //   height:500px;
        /deep/ .ql-editor {
          height: 200px;
        }
      }
      .last {
        display: flex;
        justify-content: center;
        margin: 59px 0px;
      }
      /deep/ .el-form-item__label {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
      }
    }
    .questionNaire_main {
      margin-top: 44px;
      .one {
        width: 400px;
      }
      .two {
        display: flex;
        .two_one {
          margin: 0 20px;
        }
      }
      .three {
        .three_one {
          width: 280px;
        }
        span {
          font-size: 16px;
        }
        span:nth-child(2) {
          margin-left: 20px;
        }
        span:nth-child(3) {
          margin-left: 10px;
          cursor: pointer;
          color: #0000ff;
        }
      }
      .four {
        //   height:500px;
        /deep/ .ql-editor {
          height: 200px;
        }
      }
      .last {
        display: flex;
        justify-content: center;
        margin: 59px 0px;
      }
      /deep/ .el-form-item__label {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
      }
    }
    .cooperation_main {
      margin-top: 44px;
      .one {
        width: 400px;
      }
      .two {
        display: flex;
        .two_one {
          margin: 0 20px;
        }
      }
      .three {
        .three_one {
          width: 280px;
        }
        span {
          font-size: 16px;
        }
        span:nth-child(2) {
          margin-left: 20px;
        }
        span:nth-child(3) {
          margin-left: 10px;
          cursor: pointer;
          color: #0000ff;
        }
      }
      .four {
        //   height:500px;
        /deep/ .ql-editor {
          height: 200px;
        }
      }
      .five {
        display: flex;
        .five_one {
          width: 120px;
          height: 116px;
          border: 1px solid #e9e9e9;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 30px;
        }
      }
      .last {
        display: flex;
        justify-content: center;
        margin: 59px 0px;
      }
      /deep/ .el-form-item__label {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
      }
    }
    .live_main {
      margin-top: 44px;
      .one {
        width: 400px;
      }
      .two {
        display: flex;
        .two_one {
          margin: 0 20px;
        }
      }
      .three {
        .three_one {
          width: 280px;
        }
        span {
          font-size: 16px;
        }
        span:nth-child(2) {
          margin-left: 20px;
        }
        span:nth-child(3) {
          margin-left: 10px;
          cursor: pointer;
          color: #0000ff;
        }
      }
      .four {
        //   height:500px;
        /deep/ .ql-editor {
          height: 200px;
        }
      }
      .five {
        border: 1px dashed #d9d9d9;
        width: 178px;
        .avatar-uploader .el-upload {
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
      }
      .six {
        width: 370px;
      }
      .last {
        display: flex;
        justify-content: center;
        margin: 59px 0px;
      }
      /deep/ .el-form-item__label {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
      }
    }
  }

  /deep/ .el-dialog__title {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }
  /deep/ .el-dialog {
    border-radius: 6px;
  }
}
.upload-box {
  display: flex;

  .upload-img-box {
    width: 134px;
    height: 75px;
    background: #f7f7f7;
    border: 1px solid #ececec;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    .upload-img {
      width: 134px;
      height: 75px;
    }

    .upload-noData {
      font-size: 30px;
    }
  }

  .upload-img-activity {
    border: 4px solid #409eff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "选中";
      display: block;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      position: absolute;
    }
  }

  //三张图片样式
  .box_img1 {
    .dg {
      z-index: 1;
      position: absolute;
      margin-left: -83px;
      margin-top: 18px;
      font-size: 38px;
      color: #f7f7f7;
    }
    background: #000;
    width: 134px;
    height: 75px;
    margin-right: 5px;
    //

    img {
      position: relative;
      z-index: 2;
      width: 134px;
      height: 75px;
      opacity: 0.6;
    }
  }

  // 没有打钩的样式
  .box_img2 {
    .dg {
      display: none;
    }
    width: 134px;
    height: 75px;
    margin-right: 5px;
    img {
      position: relative;
      z-index: 2;
      width: 134px;
      height: 75px;
    }
  }
}
</style>