<template>
  <div class="liveBroadCast">
    <!-- 正文 -->
    <div class="items">
      <!-- 面包屑 -->
      <div class="items_top">
        <div class="nav">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            <el-breadcrumb-item>我的教研直播</el-breadcrumb-item>
            <el-breadcrumb-item>直播详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="banner">
          <!-- 图片 -->
          <div class="b_img">
            <img src="" alt="">
          </div>
          <!-- 右边文字 -->
          <div class="b_wz" style="margin-top:50px">
            <div style="display:flex;align-items:center;justify-content: space-between;">
              <span style="font-weight:bold;font-size:20px">Adobe xd ui设计/交互</span>
              <div style="">
                <img src="../../assets/images/personal/bj.png" alt="">
                <img src="../../assets/images/personal/ljt.png" alt="">
              </div>
            </div>
            <div class="box" style="display:flex;">
              <span style="color:#999999">直播时间：</span>

              <span style="margin-right:20px;color:">2020年02月10日9:00~11:00</span>

              <div class="box_wz" style="border:1px solid #999999;border-radius:4px;margin-bottom:20px;color:#999999">
                未开始
              </div>
            </div>
            <p>
              <span style="color:#999999;">学科学段：</span>
              <span style="margin-right:20px">小学语文</span>

              <span style="color:#999999">主持人：</span>
              <span style="margin-right:20px">XXX老师</span>
            </p>
            <div class="btn" style="display:flex;margin-top:50px">
              <el-button type="primary" style="margin-right:20px">启动直播</el-button>
              <p style="color:red;font-size:14px">
                <span style="font-weight:bold">
                  温馨提示：
                </span>
                启动直播后，无法再编辑直播，请确保直播信息和内容无误后再启动。<br>
                若您未手动启动直播，当达到直播时间时，系统将会自动启动直播。
              </p>
            </div>
          </div>

          <!-- end -->
        </div>
      </div>
      <!-- 直播介绍标题 -->
      <div style="display:flex;align-items:center">
        <span style="font-weight:bold;margin-left:150px;margin-right:10px;">
          直播介绍
        </span>
        <img src="../../assets/images/personal/bj_b.png" alt="" style="width:25px;height:25px">
      </div>
      <!-- 底部 -->
      <div class="items_bottom">
        <p style="letter-spacing: 5px;word-wrap: break-word;text-indent: 30px;line-height:22px;font-size:14px">

        </p>

      </div>
      <span style="font-weight:bold;margin-left:150px;">
        直播资源
      </span>
      <div class="items_last">
        <!-- 循环中 -->
        <div class="boxs">
          <div class="b_left">
            <img src="../../assets/images/word.png" alt="">
          </div>
          <div class="b_right">
            <div class="r_box">
              <div>
                <span style="font-weight:bold;font-size:13px;margin-right:10px">标题标题</span>
              </div>

              <div>
                <span style="color:#3489FE;font-weight:bold;font-size:12px;margin-right:10px">在线查看</span>
                <span style="color:#3489FE;font-weight:bold;font-size:12px">下载</span>
              </div>
            </div>
            <p>
              <span style="font-size:12px;color:#666666">XXXX老师上传于2020-02-21</span>
            </p>
          </div>
        </div>
        <!-- end -->

        <div class="boxs">
          <div class="b_left">
            <img src="../../assets/images/word.png" alt="">
          </div>
          <div class="b_right">
            <div class="r_box">
              <div>
                <span style="font-weight:bold;font-size:13px;margin-right:10px">标题标题</span>
              </div>

              <div>
                <span style="color:#3489FE;font-weight:bold;font-size:12px;margin-right:10px">在线查看</span>
                <span style="color:#3489FE;font-weight:bold;font-size:12px">下载</span>
              </div>
            </div>
            <p>
              <span style="font-size:12px;color:#666666">XXXX老师上传于2020-02-21</span>
            </p>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      // 活动下拉框
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }],
      //表格数组
      tableData: [{
        index: 1,
        date: '2016-05-03',
        name: '王小虎',
        ways: '上海',

      }],
      // 活动下一步变量
      active: 1,
      // form表单数据
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入教研组名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],

        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写教研组简介', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    // 表格查看
    handleClick(row) {
      console.log(row);
    },
    // 保存下一步方法
    next() {
      if (this.active++ > 2) this.active = 0;
    }
  }
}
</script>

<style lang="less" >
.liveBroadCast {
  width: 100%;
  display: flex;
  justify-content: center;
  .items {
    padding-top: 20px;
    width: 100%;
    background-color: #f7f7f7;

    .items_top {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .nav {
        width: 80%;
        height: 30px;
        padding-top: 20px;
        border-bottom: 1px solid #f0f0f0;
      }
      .banner {
        width: 80%;
        height: 293px;
        display: flex;
        padding-top: 50px;
        padding-bottom: 50px;
        .b_img {
          width: 520px;
          height: 293px;
          border: 1px solid aqua;
          margin-right: 50px;
          img {
            width: 100%;
          }
        }
      }
    }
    // 底部
    .items_bottom {
      width: 80%;
      height: 500px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 50px;
      padding: 40px 40px;
      background-color: #ffffff;
    }
    // 最后一个模块
    .items_last {
      width: 80%;
      height: 500px;
      display: flex;
      margin: 0 auto;
      margin-top: 30px;
      padding: 40px 40px;
      margin-bottom: 50px;
      .boxs {
        width: 690px;
        height: 137px;
        background-color: #ffffff;
        border-radius: 6px;
        margin-left: 50px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        .b_left {
          margin-left: 50px;
          p {
            margin-bottom: 10px;
          }
        }
        .b_right {
          margin-left: 50px;
          .r_box {
            width: 500px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>