<template>
  <div class="ThBox_div public_css_module public_main_bgc">
    <div class="ThBox_box">
      <div class="center_one">
        <div class="bread" style="
          height: 48px;
          display: flex;
          align-items: center;
        ">
          <div class="bread_content" style="margin: 0 !important">
            <span class="span_one">当前位置：</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>研修视频</el-breadcrumb-item>
              <el-breadcrumb-item>
                <span class="font_orange">视频详情</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </div>

      <div class="nextTopCourse">
        <div class="TopLeft">
          <img :src="getList.cover_url" alt="" />
        </div>
        <div class="TopRight">
          <div class="word_one ellipsis-1"> {{ getList.name }} </div>
          <div class="word_two">
            <span>学段学科：</span>
            <span>{{ getList.stage_name || "-" }}{{ getList.subject_name || "-"  }}</span>
            <span>年级：</span>
            <span>{{ getList.grade_name || "-"  }}</span>
            <span>教材版本：</span>
            <span>{{ getList.edition_name || "-"  }}</span>
          </div>
          <div class="word_three">
            <p><span>适用章节：</span>{{ getList.chapter_name || "-"  }}</p>
          </div>
          <el-button type="primary" @click="pushCourse(getList.id)">发布课程</el-button>
          <el-button v-if="is_personid != 2" type="success" @click="toUpdataCourse()">修改课程</el-button>
          <el-button type="info" @click="deleteOne(getList.id)">删除课程</el-button>
        </div>
      </div>

      <div class="b_Box">
        <div class="nextContentBox">
          <div class="n_Left">
            <div class="btn_list">
              <div :class="index == activeClass ? 'active' : ''" @click="showItem(index)" v-for="(item, index) in courseList" :key="index">
                {{ item.name }}
              </div>
            </div>
            <div v-if="activeClass == 0" class="videoList">
              <video width="100%" height="100%" :src="mp4Url" controls> </video>
            </div>
            <div v-if="activeClass == 1" class="teacher_detail">
              <p class="rich_text_div_img" v-html="getList.presentation"></p>
            </div>
          </div>

          <div class="n_right_div">
            <div class="title_bottom">视频资源</div>
            <div class="right_box">
              <CommonEmpty v-if="!saveList.length" hVal="200px"></CommonEmpty>
              <div class="right_nav" v-for="(item, index) in saveList" :key="index">
                <div class="r_img">
                  <img :src="item.resource_url | showicon" alt="" />
                </div>
                <div class="r_wenzi">
                  <div class="list_zhiyuan ellipsis_1">
                    <span class="title ellipsis_1">{{ item.resource_name }}</span>
                    <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext"
                      :itemData="item" :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time" fileNav="研修视频 > 视频详情 > 查看">
                      <span style="color: #409eff;">查看</span>
                    </new-viewonline>
                    <a v-if="item.is_download==2" style="color: #409eff;" :href="item.resource_url">下载</a>
                  </div>
                  <span class="time_span ellipsis_1">上传于：{{ item.create_time }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LuBoDetail, SendCourse } from "@/api/teacherStudio";
import { onShowImages } from "@/utils/utils";
export default {
  data() {
    return {
      activeClass: 0,
      id: 0,
      mp4Url: "",
      form: "",
      saveList: [],
      courseList: [
        {
          id: 1,
          name: "视频内容",
        },
        {
          id: 2,
          name: "授课说明",
        },
      ],
      // 1教研组模块，2四名工作室模块
      is_personid: "",
    };
  },
  computed: {
    getList() {
      return this.form;
    },
  },
  filters: {
    showicon(url) {
      let index = url.lastIndexOf(".");
      let ext = url.substring(index + 1);
      return onShowImages(ext);
    },
  },
  created() {
    // 从路由原信息得到id
    this.id = this.$route.query.id;
    if (this.$route.query.is_personid) {
      this.is_personid = this.$route.query.is_personid
    };
    // 初始化数据
    this.init();
  },
  methods: {
    //切换样式
    showItem(index) {
      this.activeClass = index;
    },
    init() {
      if (this.is_personid == 1) {
        this.$axios.get(`course/get_info/${this.id}?is_personid=${this.is_personid}`).then((res) => {
          let resData = res.data.data;
          this.form = resData;
          this.mp4Url = this.form.video_resource.resource_url;
          this.saveList = res.data.data.class_resource;
        });
      } else {
        LuBoDetail({ id: this.id, is_personid: this.is_personid, }).then((res) => {
          this.form = res.data;
          this.mp4Url = res.data.video_resource.resource_url;
          this.saveList = res.data.class_resource;
        });
      }
    },
    //修改课程
    toUpdataCourse() {
      this.$router.push({
        path: '/createCourse',
        query: {
          id: this.id,
          is_personid: this.form.is_personid,
          groupId: this.form.group_id,
        },
      });
    },
    //发布课程
    async pushCourse(id) {
      this.$confirm("是否发布课程，发布后不可修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (this.is_personid == 1) {
          this.$axios.put(`courses/${id}/publish`).then((res) => {
            this.$message({
              type: "success",
              message: "发布成功!",
            });
            setTimeout(() => {
              if (this.form.is_personid == 2 && this.form.teaching_studio_id) {
                this.$router.push({
                  path: "/TeachIndex",
                  query: {
                    teaching_studio_id: this.form.teaching_studio_id,
                  },
                });
                return false;
              };

              let path = "";
              if (this.$route.query.groupId) {
                path = `/groupDetailCourse?groupId=${this.$route.query.groupId}&navCurrentType=course`;
              } else {
                path = "/course?pageType=3&statusType=0";
              }
              this.$router.push(path);
            }, 1000);
          });
        } else {
          SendCourse(id).then((res) => {
            this.$message({
              type: "success",
              message: "发布成功!",
            });
            setTimeout(() => {
              if (this.form.is_personid == 2 && this.form.teaching_studio_id) {
                this.$router.push({
                  path: "/TeachIndex",
                  query: {
                    teaching_studio_id: this.form.teaching_studio_id,
                  },
                });
                return false;
              };

              let path = "";
              if (this.$route.query.teaching_studio_id) {
                path = `/TeachIndex?teaching_studio_id=${this.$route.query.teaching_studio_id}`;
              } else {
                path = "/course?pageType=3&statusType=0";
              }
              this.$router.push(path);
            }, 1000);
          });
        }
      }).catch(() => {
        //   this.$message.warning("已取消发布课程");
      });
    },
    //删除课程
    deleteOne(id) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let p = null;
          if (this.is_personid == 1) {
            p = this.$axios.delete("courses/delCourse", {
              params: {
                id: id,
              },
            });
          } else {
            p = this.$axios.delete(
              "index/TeachingStudioCourse/deleteTeachingStudioCourse",
              {
                params: {
                  id: id,
                },
              }
            );
          }
          p.then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          });
        })
        .catch(() => {
          this.$message({
            message: "已取消",
            type: "warning",
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
.ThBox_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ThBox_box {
  width: 1400px;
  margin: 0 auto;
}
.nextTopCourse {
  background: #ffffff;
  display: flex;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 30px;
  border-radius: 6px;
  align-items: center;
  .TopLeft {
    width: 520px;
    height: 294px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .TopRight {
    flex: 1;
    box-sizing: border-box;
    padding-left: 30px;
  }
}
.word_one {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  max-height: 58px;
  overflow: hidden;
  font-size: 22px;
  font-weight: 700;
}
.word_two {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 14px;
  flex-wrap: wrap;
  span:nth-child(odd) {
    color: #999999;
    margin-bottom: 10px;
  }
  span:nth-child(even) {
    margin-right: 53px;
    margin-bottom: 10px;
  }
}
.word_three {
  display: flex;
  margin-bottom: 34px;
  font-size: 16px;
  font-weight: 400;
  p {
    span {
      color: #999999;
      margin-right: 20px;
    }
  }
}
.b_Box {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  .title_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
      font-weight: bold;
      font-size: 20px;
    }
    span {
      margin-right: 260px;
    }
  }
  .nextContentBox {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.n_Left {
  width: 1045px;
  background-color: #fff;
  padding-bottom: 20px;
  .btn_list {
    width: 96%;
    margin-left: 2%;
    height: 80px;
    line-height: 80px;
    cursor: pointer;
    border-bottom: 1px solid #ececec;
    margin-bottom: 24px;
    display: flex;
    div {
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
    }
    .active {
      height: 80px;
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
      border-bottom: 2px solid#3489FE;
      color: #3489fe;
    }
  }
  .videoList {
    margin: 0 auto;
    width: 1000px;
    height: 600px;
  }
  .teacher_detail {
    margin: 0 auto;
    width: 1000px;
    font-size: 18px;
  }
}

.n_right_div {
  width: 335px;
  background-color: #fff;
  .title_bottom {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    padding-left: 20px;
    line-height: 80px;
    border-bottom: 1px solid #ececec;
    height: 80px;
  }
  .right_box {
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
  .right_nav {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .r_img {
      flex: 0 0 60px;
      width: 60px;
      height: 50px;
      display: flex;
      align-items: center;
      margin-right: 6px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .r_wenzi {
      cursor: pointer;
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 16px;
      color: #666;
      .title {
        width: 140px;
      }
      span {
        margin-right: 6px;
      }
      .list_zhiyuan {
        display: flex;
        align-items: center;
      }
      .title {
        flex: 1;
      }
      .time_span {
        margin-top: 10px;
        font-size: 16px;
        line-height: 16px;
        color: #999;
      }
    }
  }
}

.discussBox {
  width: 1045px;
  .forItem {
    width: 935px;
    display: flex;
    .forItem_left {
      width: 50px;
      height: 50px;
      background: red;
      border-radius: 50%;
      img {
        width: 100%;
      }
    }
    .forItem_right {
      margin-left: 10px;
      .TitleTop {
        font-size: 14px;
        span:nth-of-type(1) {
          color: #fa8c15;
          margin-right: 5px;
        }
        span:nth-of-type(2) {
          color: #9a9ea5;
        }
      }
      p {
        font-size: 14px;
      }
      .btn1 {
        display: flex;
        font-size: 14px;
        color: #9a9ea5;
        margin-left: 700px;
        div {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
