<template>
  <div class="livedetail_div public_main_bgc">
    <div class="w_d center_one">
      <div class="navBox">
        <p>
          当前位置：研修中心&nbsp;>&nbsp;
          <span @click="toList()">研修视频</span>&nbsp;>&nbsp;
          <span class="font_orange">视频详情</span>
        </p>
      </div>

      <div class="live-box">
        <div class="live-box-left">
          <Ckplayer v-if="detailData && detailData.video_resource && detailData.video_resource.resource_url"
            :videoSrc="detailData.video_resource.resource_url"></Ckplayer>
        </div>
        <div class="live-box-right">
          <div class="live-tabs">
            <span class="tabs-item" :class="tabIndex == item.id ? 'tabs-item-activity' : ''"
              v-for="(item, index) in tabs" :key="index" @click="tabChange(item.id)">{{ item.name }}</span>
          </div>
          <div class="live-tabs-content">
            <div class="live-synopsis" v-if="tabIndex == 1">
              <div class="synopsis-top">
                <div class="flex_Between">
                  <h2 class="live-title">{{ detailData.name }}</h2>
                </div>
                <div class="live-blurb rich_text_div_img" v-html="detailData.presentation"></div>
              </div>
              <div class="synopsis-bottom">
                <div class="course-info">
                  <p class="course-info-item">
                    <span class="info-item-label">视频分类：</span><span class="info-item-value">{{ detailData.course_type
                      }}</span>
                  </p>
                  <p class="course-info-item">
                    <span class="info-item-label">学段学科：</span><span class="info-item-value">{{ detailData.stage_name
                      }}{{ detailData.subject_name }}</span>
                  </p>
                  <p class="course-info-item">
                    <span class="info-item-label">年级：</span><span class="info-item-value">{{ detailData.grade_name
                      }}</span>
                  </p>
                  <p class="course-info-item">
                    <span class="info-item-label">教材版本：</span><span class="info-item-value">{{ detailData.edition_name
                      }}</span>
                  </p>
                  <p class="course-info-item">
                    <span class="info-item-label">适用章节：</span><span class="info-item-value">{{ detailData.chapter_name
                      }}</span>
                  </p>
                </div>
                <div class="flex_Between">
                  <span class="share-button" @click="popupBox()">分享</span>
                </div>
              </div>
            </div>
            <div class="live-resource" v-if="tabIndex == 2">
              <ul class="resource-list">
                <li class="resource-list-item" v-for="(item, index) in detailData.class_resource" :key="index">
                  <div class="resource-icon">
                    <img :src="onShowImages4(item.mime)" />
                  </div>
                  <div class="resource-content">
                    <h3 class="resource-title">{{ item.title }}</h3>
                    <div class="resource-info">
                      <p class="resource-date">{{ item.create_time }}</p>
                      <div class="resource-operate">
                        <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id
            " :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item"
                          :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time"
                          fileNav="研修中心 > 教研直播 > 直播详情 > 查看">
                          <span class="operate-button">查看</span>
                        </new-viewonline>
                        <el-link :href="item.url" target="_blank" v-if="item.permission == 2 && item.url != ''">
                          <span class="operate-button">下载</span>
                        </el-link>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="live-upload-playback" v-if="tabIndex == 3">
              <el-button size="mini" type="primary" @click="PassWay(4)">审核通过</el-button>
              <el-button size="mini" type="danger" @click="noPass">审核不通过</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- //不通过弹框 -->
      <el-dialog title="不通过的理由" :visible.sync="dialogVisible" width="30%">
        <el-input type="textarea" :rows="5" placeholder="请输入审核不通过的理由" resize="none" v-model="form1.refusal">
        </el-input>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="PassWay(5)">确认</el-button>
        </span>
      </el-dialog>

      <div class="comment-box mt20 mb50">
        <!-- 评论组件 -->
        <new-comment :module_type="is_personid == 1 ? 4 : 5" :is_from="+is_personid" :activity_id="id"
          @onChangeTotal="onChangeTotal" @onGetComment="onGetComment" ref="commentRef"></new-comment>

        <div class="comment-head mb14">发表评论</div>
        <div class="comment-form">
          <el-input type="textarea" placeholder="请输入回复内容" resize="none" v-model="commentInput"></el-input>
          <div class="form-operate">
            <span class="form-button" @click="releaseComment()">发表评论</span>
          </div>
        </div>
      </div>
    </div>

    <MyDialog :open="shareisShow" title="分享研修视频" @onClose="shareisShow = false" width="1120px">
      <h4>分享至：</h4>
      <div class="df mt20 mb20">
        <PublicShare></PublicShare>
      </div>
      <div v-if="false" class="top_one">
        <h4>访问码邀请</h4>
        <p>
          将直播访问码分享给直播参与者，参与者可在教研直播频道输入访问码访问直播详情。
        </p>
        <div class="box1">
          <div>
            访问码：
            <span>{{ liveData.visit_code }}</span>
          </div>
          <div v-clipboard:copy="liveData.visit_code" v-clipboard:success="onCopy" v-clipboard:error="onError"
            class="cursor">
            复制访问码
          </div>
        </div>
      </div>
      <div class="top_two">
        <h4>分享链接</h4>
        <p>点击复制链接并分享，参与者打开链接查看研修视频详情。</p>
        <div class="twoListss">
          <p>分享链接：</p>
          <div style="
              background: rgba(247, 247, 247, 1);
              color: rgba(52, 137, 254, 1);
              margin-top: 10px;
              margin-right: 20px;
            " v-clipboard:copy="linkData" v-clipboard:success="onCopy" v-clipboard:error="onError" class="cursor">
            {{ linkData }}
          </div>
        </div>
      </div>
    </MyDialog>

    <div class="live-plug-in" v-if="isLive">
      <span class="plug-in-button" @click="downloadLiveSoftware()">下载直播软件</span>
    </div>
  </div>
</template>

<script>
import { onShowImages4, isOffice, localGet, localSet, localRemove } from "@/utils/utils";
import pdf from "vue-pdf";
// import uploadCom from '@/components/Commons/uploadLiveVideo.vue'
export default {
  components: {
    // "dc-upload": upload
    pdf,
    // uploadCom
  },
  data() {
    return {
      wTrue: false,
      isToken: false,
      //评论框变量
      commentInput: "",
      //用来刷新评论的
      newdata: 1,
      //学科学段字段
      stage_subject: "",
      //设置一个变量用来装分享链接
      linkData: "",
      //分享弹出框变量
      shareisShow: false,
      dialogVisible: false,
      // 直播id
      id: "",
      // 直播详情数据
      liveData: {},
      // 当前用户id
      userId: "",
      //PDF预览
      src: "",
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      // 上传资源表单
      form: {
        name: "",
        resource_id: "",
        // resource_name: "",
        stagedata: "",
        subjectdata: "",
        gradedata: "",
        resourcetypesdata: "",
        versiondata: "",
        chapterdata: "",
        areadata: "",
        yeardata: "",
      },
      videoSrc: "",
      tabs: [
        { id: 1, name: '简介' },
        { id: 2, name: '资源' }
      ], //tabs列表
      tabIndex: 1, //tabs索引
      is_personid: 0,
      isUpOk: false,
      detailData: {},
      // 所选全部或我的评论数据
      commentRadio: "1",
      form1: {
        audit_status: "", //2 审核通过 3审核不通过
        id: "", //审核id
        refusal: "",
        teaching_studio_id: "",
      },
    };
  },
  created() {
    this.init();
    this.unserInfoData = localGet("unserInfoData");
    let token = localStorage.getItem("token");
    this.isToken = token == null ? false : true;
    if (token) {
      this._getAddress();
    }
    //从路由得到当前路由

    // this.linkData="http://hlwjy.co/#"+this.$route.fullPath
    // this.linkData="https://hlwjy.dc-it.cn/#"+this.$route.fulPlath
    this.linkData = "https://" + document.domain + "/#" + this.$route.fullPath;
  },
  computed: {
    //是否显示上传回放视频
    is_Upload() {
      let uid = localStorage.getItem("id");
      if (this.liveData.status == "3" && this.liveData.user_id == uid) {
        return true;
      } else {
        return false;
      }
    },
    //判断是不是主播
    isLive() {
      let uid = localStorage.getItem("id");
      if (uid == this.liveData.anchor_user_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    //不通过
    noPass() {
      this.form1.id = this.$route.query.id; //审核id
      this.dialogVisible = true;
    },
    //通过
    PassWay(type) {
      this.form1.audit_status = type; //4 审核通过 5审核不通过
      this.form1.id = this.$route.query.id; //审核id
      this.form1.teaching_studio_id = this.liveData.teaching_studio_id;
      this.$axios
        .put("TeachingStudioCourse/audit_status", this.form1)
        .then(() => {
          if (type == 4) {
            this.$message.success("审核通过");
          } else {
            this.$message.success("审核不通过");
            this.dialogVisible = false
          }
          this.tabs.splice(0, 1)
          this.tabIndex = 1
        });
    },
    fenxiang(val) {
      if (val == 1) {
        let urlData = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.linkData}&sharesource=qzone&title=${this.liveData.title}&pics=${this.liveData.cover_url}&summary=${this.liveData.label}`;
        window.open(urlData, "_blank");
      }
      if (val == 3) {
        let urlData = `https://connect.qq.com/widget/shareqq/index.html?url=${this.linkData}&sharesource=qzone&title=${this.liveData.title}&pics=${this.liveData.cover_url}&summary=${this.liveData.label}`;
        window.open(urlData, "_blank");
      }
    },
    // 初始化函数
    init() {
      this.id = this.$route.query.id;
      this.is_personid = this.$route.query.is_personid || 0;
      this.userId = localStorage.getItem("id");
      this.getDetail();
    },
    onShowImages4,
    isOffice,
    //路由
    toList() {
      this.$router.push({
        path: "/researchCenter",
        query: {
          navActive: 3,
        },
      });
    },

    //发送心得评论
    setComment() {
      if (!this.commentInput) {
        this.$message.warning("评论内容不能为空。");
        return false;
      }
      let obj = {
        live_id: this.$route.query.id,
        content: this.commentInput,
        is_personid: this.is_personid,
      };
      this.$axios.post("liveReply/insert_comment", obj).then(() => {
        this.newdata = this.newdata + 1;
        this.$message.success("评论成功");
        this.commentInput = "";
      });
    },

    //修改进入直播
    ComeLive() {
      let token = localStorage.getItem("token");
      if (token) {
        //从路由获取id
        let live_id = this.$route.query.id;
        this.$router.push({
          path: "/MyLive",
          query: {
            id: live_id,
            is_form: 1,
          },
        });
      } else {
        this.$message.warning("请先登录，再进行操作。");
      }
    },

    //粘贴和复制
    onCopy() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    onError() {
      this.$message.error("复制失败");
    },
    //弹出分享的弹出框
    popupBox() {
      this.shareisShow = true;
    },
    //跳转修改直播信息页面
    updateInforMation() {
      let gid = this.$route.query.groupId;
      if (gid) {
        // 从路由原信息得到直播id传过去
        this.$router.push({
          path: "/newLive",
          query: {
            id: this.id,
            groupId: gid,
          },
        });
      } else {
        // 从路由原信息得到直播id传过去
        this.$router.push({
          path: "/newLive",
          query: {
            id: this.id,
          },
        });
      }
    },

    // 判断是否显示启动按钮函数
    ifshowStart() {
      if (this.userId == this.liveData.user_id) {
        if (this.liveData.status == "2") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 判断是否显示进入按钮函数
    ifshowinto() {
      if (this.ifshowStart() == false) {
        if (this.liveData.status == "1") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 判断是否显示上传回放按钮函数
    ifshowend() {
      if (this.ifshowStart() == false) {
        if (this.liveData.status == "3") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 请求后台删除直播函数
    deleteLive() {
      this.$axios.delete(`live/delLive/${this.id}/${this.userId}`).then((res) => {
        this.$router.push("researchCenter?navActive=2");
      });
    },
    // 请求后台进入直播间
    getintonowLive() {
      const loading = this.$loading({
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
      this.$axios.get(`live/room/${this.id}`).then((res) => {
        // this.$message.info(res.data.msg)
        var resdata = res.data;
        var url = resdata.data.url;
        window.open(url);
      });
    },
    // 请求后台启动直播函数
    stasrtupLive() {
      this.$axios.put(`live/room/${this.id}/start`).then((res) => {
        this.init();
      });
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");

      this.form.name = data.filename;

      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });

      this.form.resource_id = res.data.data.id;
      let obj = {
        live_id: this.id,
        resource_id: this.form.resource_id,
      };
      this.$axios.put("live/uploadPlayback", obj).then((res) => {
        this.$message.success("上传成功");
        this.init();
      });
    },
    tabChange(index) {
      this.tabIndex = index;
    },
    onChangeTotal(val) { },
    enterLive() {
      if (this.liveData.live_url) {
        window.open(this.liveData.live_url);
      } else {
        this.$message.error("直播室还没准备就绪，请刷新页面再试");
      }
    },
    startLive() {
      //window.open(`liveav://${this.unserInfoData.sid},2,${this.liveData.ykt_id}`);
      if (this.liveData.live_type == 1) {
        window.open(this.liveData.live_url);
      }
      if (this.liveData.live_type == 2) {
        this.$axios
          .put(`live/startVideoLive/${this.liveData.id}`, {
            is_personid: this.is_personid,
          })
          .then((res) => {
            this.$message.success("开始直播");
            this.init();
            this.enterLive();
          });
      }
    },
    downloadLiveSoftware() {
      window.open(this.liveData.download_url);
    },

    //请求后台获取视频详情
    getDetail() {
      if (this.is_personid == 1) {
        this.$axios
          .get(`course/get_info/${this.id}?is_personid=${this.is_personid}`)
          .then((res) => {
            this.detailData = res.data.data;
          });
      } else {
        //四名工作室
        this.$axios
          .get(`index/TeachingStudioCourse/infoTeachingStudioCourse`, {
            params: {
              id: this.id,
              is_personid: this.is_personid,
            },
          })
          .then((res) => {
            this.detailData = res.data.data;
            if (this.detailData.publish_status == 1) {
              this.getRole()
            }
          });
      }
    },
    async getRole() {
      let res = await this.$axios.get(`/index/TeachingStudio/TeachingStudioInfo`, {
        params: { user_id: localStorage.getItem("id"), id: this.detailData.teaching_studio_id },
      });
      console.log(res.data.data);
      if (res.data.data.role) {
        this.tabs.unshift({ id: 3, name: '审核' })
        this.tabIndex = 3
      }
    },
    // 重新请求评论
    onGetComment() {
      if (this.commentRadio == 1) {
        // 全部评论
        this.$refs.commentRef.getCommentList();
      } else {
        // 个人评论评论
        this.$refs.commentRef.getMyCommentInfo();
      }
    },

    // 请求后台发布评论函数
    releaseComment() {
      if (!this.commentInput) {
        this.$message.warning("评论内容不能为空。");
        return false;
      }
      let p = null;
      let obj = {
        user_id: this.userId,
        content: this.commentInput,
      };
      if (this.is_personid == 1) {
        obj.course_id = this.id;
        obj.is_personid = this.is_personid;
        p = this.$axios.post("courseReply/insert_comment", obj);
      } else {
        obj.teaching_studio_course_id = this.id;
        obj.is_personid = this.is_personid;
        p = this.$axios.post("TeachingStudioCourseReply/insert_comment", obj);
      }
      p.then((res) => {
        this.$message.success("评论成功");
        this.commentInput = "";
        // 全部评论
        this.$refs.commentRef.getCommentList();
        // 个人评论评论
        this.$refs.commentRef.getMyCommentInfo();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.livedetail_div {
  display: flex;
  flex-direction: column;
  align-items: center;

  .center_one {
    .navBox {
      width: 1400px;
      display: flex;
      align-items: center;
      height: 48px;
      background: transparent;

      p {
        font-size: 14px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #666;

        span:last-child {
          color: #ff8201;
        }

        span:first-of-type {
          cursor: pointer;

          &:hover {
            color: #3489fe;
          }
        }
      }
    }

    .live-box {
      height: 609px;
      background: #1b2128;
      border-radius: 8px;
      overflow: hidden;
      display: flex;

      .live-box-left {
        flex: 1;
        overflow: hidden;
        padding: 22px;

        .live-cover {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
          position: relative;

          .live-cover-img {
            width: 100%;
            height: 100%;
          }

          .live-cover-mask {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.33);

            .live-play-button {
              cursor: pointer;

              .live-play-icon {
                width: 80px;
                height: 80px;
                background: url("~@/assets/live/play.png") no-repeat center #3489fe;
                border-radius: 100%;
                display: block;
              }

              .live-play-text {
                font-size: 16px;
                color: #fff;
                text-align: center;
                margin-top: 6px;
              }
            }
          }
        }

        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
      }

      .live-box-right {
        width: 354px;

        .live-tabs {
          height: 64px;
          background: #3f3f3f;

          .tabs-item {
            height: 64px;
            font-size: 20px;
            color: #8590a6;
            line-height: 64px;
            border-bottom: 4px solid transparent;
            display: inline-block;
            box-sizing: border-box;
            cursor: pointer;
            margin-left: 42px;
          }

          .tabs-item-activity {
            color: #06a7ff;
            border-color: #06a7ff;
          }
        }

        .live-tabs-content {
          height: 545px;
          background: #333333;
          overflow-y: auto;

          .live-synopsis {
            padding: 30px;

            .synopsis-top {
              height: 300px;

              .live-title {
                font-size: 24px;
                font-weight: bold;
                color: #ffffff;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 8px;
              }

              .live-state {
                height: 26px;
                font-size: 14px;
                color: #fff;
                line-height: 26px;
                white-space: nowrap;
                padding: 0 10px;
                border-radius: 4px;
                background: #06a7ff;
                display: inline-block;
              }

              .live-anchor {
                font-size: 16px;
                color: #ffffff;
                display: inline-block;
                margin: 0 10px 4px 0;
              }

              .live-label {
                min-height: 20px;
                max-height: 60px;
                padding: 0 8px;
                background: #edf7f1;
                border: 1px solid #51b875;
                border-radius: 4px;
                display: inline-flex;
                //align-items: center;
                font-size: 14px;
                color: #51b875;
                line-height: 20px;
                overflow: hidden;
              }

              .live-blurb {
                height: 220px;
                font-size: 16px;
                color: #999999;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                margin-top: 10px;
              }
            }

            .synopsis-bottom {
              height: 180px;
              display: flex;
              flex-direction: column;

              .live-date {
                margin-bottom: 30px;

                p {
                  font-size: 16px;
                  color: #ff8201;
                  margin-bottom: 6px;

                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }

              .live-join-count {
                font-size: 16px;
                color: #999999;
                margin-bottom: 14px;

                .count-number {
                  color: #3489ff;
                }
              }

              .share-button {
                width: 98px;
                height: 46px;
                background: #ffffff;
                border: 1px solid #3489fe;
                border-radius: 8px;
                box-sizing: border-box;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #3489ff;
                line-height: 16px;
                cursor: pointer;
              }

              .live-button {
                width: 176px;
                height: 46px;
                background: #ff8202;
                border-radius: 8px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #fff;
                line-height: 16px;
                cursor: pointer;
              }

              .course-info {
                flex: 1;
                overflow: hidden;

                .course-info-item {
                  font-size: 14px;
                  color: #bbbbbb;
                  margin-bottom: 6px;

                  .info-item-label {
                    margin-right: 4px;
                  }

                  .info-item-value {}
                }
              }
            }
          }

          .live-resource {
            .resource-list {
              padding: 15px 20px;

              .resource-list-item {
                padding: 10px 0 15px 0;
                border-bottom: 1px dashed #3f3f3f;
                display: flex;

                .resource-icon {
                  width: 40px;
                  height: 40px;
                  margin-right: 14px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .resource-content {
                  flex: 1;
                  overflow: hidden;

                  .resource-title {
                    font-size: 16px;
                    color: #ffffff;
                    font-weight: normal;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .resource-info {
                    padding-top: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .resource-date {
                      font-size: 14px;
                      color: #999999;
                    }

                    .resource-operate {
                      .operate-button {
                        font-size: 14px;
                        color: #3489fe;
                        cursor: pointer;
                        margin-left: 20px;
                      }
                    }
                  }
                }
              }
            }
          }

          .live-upload-playback {
            padding: 30px;

            /deep/.el-upload-list__item-name {
              color: #fff;

              .el-icon-document {
                color: #fff;
              }
            }

            /deep/.el-progress__text {
              color: #fff;
            }

            /deep/.el-icon-close {
              color: #fff;
            }

            /deep/.el-upload-list__item {
              &:hover {
                background: #409eff;
              }
            }
          }
        }
      }
    }

    .comment-box {
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 8px;

      .comment-head {
        font-size: 20px;
        font-weight: bold;
        color: #1a1a1a;
      }

      .comment-form {
        /deep/.el-textarea__inner {
          min-height: 184px !important;
          border-color: #ececec;
        }

        .form-operate {
          padding-top: 14px;
          display: flex;
          justify-content: flex-end;

          .form-button {
            width: 92px;
            height: 34px;
            background: #ff8201;
            border-radius: 4px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.public_main_bgc {
  background-image: url("~@/assets/live/bg.png") !important;
  background-size: 100% !important;
  min-height: calc(100vh - 180px) !important;
}
</style>
