<template>
  <div class="xianxia">
    <div class="six_xianxia">环节总结</div>
    <!-- 已提交总结 -->
    <div v-if="resourceData.finish_status">
      <div class="ten_xianxia rich_text_div_img" v-html="resourceData.summary.content"></div>
      <el-link type="primary" :href="resourceData.summary.accessory_url" target="_blank" style="margin-top: 20px">
        {{ resourceData.summary.accessory_name }}</el-link>
    </div>
    <!-- 未提交总结 -->
    <div v-else>
      <div class="seven_xianxia">
        <Editor v-model="xianxiaform.xianxiaData"></Editor>
      </div>
      <div class="eight_xianxia">
        <MyUpload @onGetId="onGetId" @onRemoveFile="deleteUpload" accept=".doc,docx,.pdf,.jpg,.png,.xlsx,.xls" :showList="true" :limit="1">
          <div class="eight_one cursor">
            <i class="el-icon-top"></i>
            <span>上传附件</span>
          </div>
          <p>支持扩展名：doc .docx .pdf .jpg .xlsx .xls</p>
        </MyUpload>
      </div>
      <div class="nine_xianxia">
        <el-button type="warning" class="commond_button" @click="submitSummary">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { CommitBilan } from "@/api/teacherStudio";
export default {
  props: {
    resourceData: {
      type: Object,
      default: {},
    },
    is_from: {
      type: Number,
      default: 1,
    },
    activityId: {
      type: Number,
      default: 1,
    },
    linkId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      //线下表单
      xianxiaform: {
        accessoryId: "",
        xianxiaData: "",
      },
    };
  },
  methods: {
    // 获取上传id
    onGetId({ id }) {
      this.xianxiaform.accessoryId = id;
    },
    // 删除线下区域上传文件函数
    deleteUpload() {
      this.xianxiaform.accessoryId = "";
    },
    // 请求后台提交总结数据
    submitSummary() {
      if (this.resourceData.status == "2") {
        var obj = {
          content: this.xianxiaform.xianxiaData,
          accessoryId: this.xianxiaform.accessoryId,
        };
        if (this.is_from == 1) {
          this.$axios
            .post(
              `activity/${this.activityId}/activityLink/${this.linkId}/summary`,
              obj
            )
            .then((res) => {
              this.$message.success("提交成功");
              this.$emit('getResourceData')
            });
        } else {
          obj.teaching_studio_id = [localStorage.getItem("studioId")];
          CommitBilan(this.activityId, this.linkId, obj).then((res) => {
            this.$message.success("提交成功");
            this.$emit('getResourceData')
          });
        }
        //刷新数据
        this.$emit("cancelLink", true)
      } else if (this.resourceData.status == "1") {
        this.$message.info("活动环节未开始");
      } else if (this.resourceData.status == "3") {
        this.$message.info("活动环节已结束");
      }



    },
  },
  watch: {
    resourceData: {
      handler(newVal) {
      },
      deep: true,
    },
  },
};
</script>
 
<style lang="less" scoped>
.xianxia {
  .seven_xianxia {
    /deep/ .ql-editor {
      height: 200px !important;
    }
  }
  /deep/ .eight_xianxia {
    .el-upload {
      display: flex !important;
      align-items: center;
    }
  }
}
</style>