<template>
   <!-- 排序题 -->
  <el-dialog
    title="添加排序题"
    width="1200px"
    top="30px"
    :close-on-click-modal="false"
    :before-close="dialogHandleClose"
    :visible="dialogShow"
  >

    
    <el-form
      ref="subject"
      :rules="subjectRules"
      :model="subject"
      class="subject-form"
    >
      <el-form-item prop="title">
        <Editor v-model="subject.title"></Editor>
      </el-form-item>
      <el-form-item prop="type">
         <!-- @change="subjectRequiredChange"  -->
        <el-checkbox
          v-model="subject.is_required"
          :true-label="2"
          :false-label="1"
         
        >是否必填</el-checkbox>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <data-table
      class="choiceOptionTable"
      :list="subject.options"
      ref="choiceOptionTable"
      :noPage="false"
    >
      <el-table-column label="选项">
        <template slot-scope="scope">
          <div class="choiceOption-input">
            <el-input
              size="mini"
              :placeholder="'请输入选项' + (scope.$index + 1)"
              v-model="scope.row.content"
            ></el-input>
            <i
              class="choiceOption-button el-icon-circle-plus-outline"
              @click="addOptionButton()"
            ></i>
            <i
              class="choiceOption-button el-icon-remove-outline"
              @click="delOptionButton(scope.$index)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="允许填空"
        align="center"
        width="180px"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.allow_fill_in"
            :true-label="2"
            :false-label="1"
            @change="writeAllowChange($event,scope.$index)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        label="填空是否必填"
        align="center"
        width="180px"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.fill_in_required"
            :true-label="2"
            :false-label="1"
            @change="writeRequiredChange($event,scope.$index)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        label="上移下移"
        align="center"
        width="230px"
      >
        <template slot-scope="scope">
          <el-button
            type="mini"
            @click="upOptionButton(scope.row, scope.$index)"
            v-if="scope.$index!=0"
          >
            上移
          </el-button>
          <el-button
            type="mini"
            @click="downOptionButton(scope.row, scope.$index)"
            v-if="scope.$index!=subject.options.length-1"
          >
            下移
          </el-button>
        </template>
      </el-table-column>
    </data-table>

    <div class="bottom-box">
      <el-button @click="cancelButton()">取消</el-button>
      <el-button
        type="primary"
        @click="defineButton('subject')"
      >
        {{ subject.hasOwnProperty("index") ? "修改" : "添加" }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import SortSubject from "./sortSubject.js";
export default SortSubject;
</script>

<style lang="less" scoped>
@import "./sortSubject.less";
</style>