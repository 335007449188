<template>
  <div class="researchcource">
    <div class="w_d center" style="margin: 0 auto">
      <!-- <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">研修视频</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div> -->
      <div class="navBox">
        <p>当前位置：研修中心&nbsp;>&nbsp; <span>研修视频</span></p>
      </div>

      <div class="select">
        <div class="select_left">
          <div class="xueduan">
            <p>类型：</p>

            <div :class="[
                selectedType == item.id ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" v-for="(item, index) in typeOption" :key="index" @click="handleType(item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan">
            <p>学段：</p>
            <div :class="[
                pharseid == '' ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" @click="handleStage('')">
              全部
            </div>
            <div :class="[
                pharseid == item.id ? 'active_block' : '',
                'xueduan_block',
                'cursor',
              ]" v-for="(item, index) in stage" :key="index" @click="handleStage(item.id)">
              {{ item.name }}
            </div>
          </div>
          <div class="xueduan2">
            <p>学科：</p>
            <div class="xueduan2_one">
              <div :class="[
                  search_subject_id == '' ? 'active_block' : '',
                  'xueduan_block',
                  'cursor',
                ]" @click="handleSubject('')">
                全部
              </div>
              <div :class="[
                  search_subject_id == item.subjectId ? 'active_block' : '',
                  'xueduan_block',
                  'cursor',
                ]" v-for="(item, index) in subjectData" :key="index" @click="handleSubject(item.subjectId)">
                {{ item.subjectName }}
              </div>
            </div>
          </div>

          <div class="sousuo">
            <p>查找：</p>
            <el-input size="medium" placeholder="请输入搜索关键字" prefix-icon="el-icon-search" class="sousuo_one" v-model="search_title"></el-input>
            <el-button type="primary" size="medium" @click="screensousuo">搜索</el-button>
          </div>
        </div>
        <div class="select_right">
          <!-- <div class="create cursor" @click="goOther">+ 创建课程</div> -->
          <div class="create"></div>
          <div class="sousuo_one">
            <el-input placeholder="通过访问码查找" prefix-icon="el-icon-search" v-model="search_visit_code" size="medium"></el-input>
            <el-button type="primary" @click="rightsousuo" size="medium">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间和分页 -->
    <div class="CenterPage">
      <div v-if="courseData && courseData.length != 0">
        <div class="main">
          <el-card class="live_block cursor" shadow="hover" :body-style="{ padding: '0px' }" v-for="(item, index) in courseData" :key="index">
            <div @click="intoDetail(item)">
              <div class="img" :style="{ backgroundImage: 'url(' + item.cover_url + ')' }">
                <!-- <div class="writer">
                <p>8月15日 13:00开播</p>
              </div> -->
              </div>
              <p class="word1 ellipsis">{{ item.name }}</p>
              <div class="word3">
                <span>{{ item.type_text }}</span>
                <div class="shuxian"></div>
                <span>{{ item.stage_text }}</span>
                <div class="shuxian"></div>
                <span>{{ item.subject_text }}</span>
              </div>
              <div class="word2" v-if="
                  item.type == 1 ||
                  item.type == 2 ||
                  item.type == 3 ||
                  item.type == 6
                ">
                <span>{{ item.username }}</span>
                <span>{{ item.org_name }}</span>
              </div>
              <div class="word2" v-if="item.type == 4">
                <span>{{ item.username }}</span>
                <span>{{ item.org_name }}</span>
              </div>
              <div class="word2" v-if="item.type == 5">
                <span>{{ item.author }}</span>
                <span v-if="item.sign_type == 1">{{ item.org_name }}</span>
              </div>
            </div>
          </el-card>
          <div class="pick"></div>
        </div>
        <div class="pagin">
          <el-pagination background :current-page="page" :page-size="limit" layout="prev, pager, next, jumper" :total="total"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
      <div v-else class="nodata">暂无数据.....</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //课程类型所有数据
      typeOption: [
        {
          id: "",
          name: "全部",
        },
        {
          id: "1",
          name: "上课",
        },
        {
          id: "2",
          name: "说课",
        },
        {
          id: "3",
          name: "讲座",
        },
        {
          id: "4",
          name: "听评课",
        },
        {
          id: "5",
          name: "集中备课",
        },
        {
          id: "6",
          name: "其他",
        },
      ],
      // 选中的课程类型
      selectedType: "",
      //学段数据
      stage: [],
      // 选中的学段id
      pharseid: "",
      //科目数据
      subjectData: [],
      // 选中的学科id
      search_subject_id: "",
      // 搜索标题数据
      search_title: "",
      // 搜索访问码
      search_visit_code: "",
      // 每页条数
      limit: 20,
      // 页数
      page: 1,
      // 总数据数目
      total: 0,
      // 课程总数据
      courseData: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    go() {
      this.$router.push("/createactivity");
    },
    // 切换所选课程类型函数
    handleType(id) {
      this.selectedType = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 初始化函数
    init() {
      this.getStateList();
      this.leftsousuo();
    },
    // 请求后台获取学段信息
    getStateList() {
      this.$axios.get("stage/stage_list").then((res) => {
        var resdata = res.data.data;
        this.stage = resdata;
      });
    },
    // 点击切换所选学段函数
    handleStage(id) {
      this.pharseid = id;
      // this.getSubjectList();
      // this.search_subject_id = "0";
      if (id != "") {
        this.getSubjectList();
      }
      // this.leftsousuo();
      this.screensousuo();
    },
    // 请求后台获取科目列表
    getSubjectList() {
      var obj = {
        // pharseid:'1'
        params: {
          pharseid: this.pharseid,
        },
      };
      this.$axios.get("subject/list", obj).then((res) => {
        var resdata = res.data;
        if (resdata.code == "900") {
          this.subjectData = resdata.data;
        } else {
          this.$message({
            message: resdata.msg,
          });
        }
      });
    },
    // 点击切换所选学科函数
    handleSubject(id) {
      this.search_subject_id = id;
      // this.leftsousuo();
      this.screensousuo();
    },
    // 左边搜索函数
    leftsousuo() {
      var obj = {
        // a
        limit: this.limit,
        page: this.page,
        type: this.selectedType,
        stage_id: this.pharseid,
        subject_id: this.search_subject_id,
        title: this.search_title,
        visit_code: "",
      };
      this.getActivity(obj);
    },
    // 右边访问码搜索函数
    rightsousuo() {
      var obj = {
        limit: "24",
        page: "1",
        type: "",
        stage_id: "",
        subject_id: "",
        title: "",
        visit_code: this.search_visit_code,
      };
      this.getActivity(obj);
    },
    //筛选搜索函数
    screensousuo() {
      this.page = 1;
      var obj = {
        limit: this.limit,
        page: 1,
        type: this.selectedType,
        stage_id: this.pharseid,
        subject_id: this.search_subject_id,
        title: this.search_title,
        visit_code: "",
      };
      this.getActivity(obj);
    },
    // 请求后台获取活动数据
    getActivity(obj) {
      this.$axios.get("courses/more", { params: obj }).then((res) => {
        var resdata = res.data;
        this.courseData = resdata.data.data;
        this.total = resdata.data.total;
      });
    },
    //   切换当前页码时触发
    handleCurrentChange(val) {
      this.page = val;
      this.leftsousuo();
    },
    // 跳转到具体详情页面函数
    intoDetail(item) {
      switch (Number(item.type)) {
        //上课
        case 1:
          this.$router.push({
            name: "goclassdetail",
            query: {
              id: item.id,
              is_from: 1,
            },
          });
          break;
        // 说课
        case 2:
          this.$router.push({
            name: "goclassdetail",
            query: {
              id: item.id,
              is_from: 1,
            },
          });
          break;
        // 讲座
        case 3:
          this.$router.push({
            name: "goclassdetail",
            query: {
              id: item.id,
              is_from: 1,
            },
          });
          break;
        // 听评课
        case 4:
          this.$router.push({
            name: "listeningcommentdetail",
            query: {
              id: item.id,
              is_from: 1,
            },
          });
          break;
        // 集中备课
        case 5:
          this.$router.push({
            name: "preparelessons",
            query: {
              id: item.id,
              is_from: 1,
            },
          });
          break;
        // 其他
        case 6:
          this.$router.push({
            name: "goclassdetail",
            query: {
              id: item.id,
              is_from: 1,
            },
          });
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.researchcource {
  // display: flex;
  // justify-content: center;
  .navBox {
    width: 1400px;
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 18px;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      span {
        color: #ff8201;
      }
    }
  }
  .center {
    margin-bottom: 60px;
    // border: 1px solid black;
    .select {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .select_left {
        .xueduan {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          p {
            color: #999999;
            margin-right: 20px;
          }
          .xueduan_block {
            width: 72px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }
        .xueduan2 {
          display: flex;
          // align-items: center;
          // margin-bottom: 39px;
          width: 780px;

          p {
            color: #999999;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .xueduan2_one {
            display: flex;
            align-items: center;
            width: 780px;
            flex-wrap: wrap;
          }

          .xueduan_block {
            // width: 72px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            // padding: 0 27px;
            padding: 0 20px;
            margin-right: 20px;
          }
          .active_block {
            background: #3489fe;
            border-radius: 4px 3px 4px 4px;
            color: #ffffff;
          }
        }
        .sousuo {
          display: flex;
          align-items: center;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            border-radius: 4px 4px 4px 4px;
          }
          /deep/ .el-button {
            // border-radius: 0px;
            border-radius: 4px 4px 4px 4px;
          }

          p {
            color: #999999;
            flex-shrink: 0;
            margin-right: 20px;
          }
          .sousuo_one {
            width: 388px;
            margin-right: 20px;
          }
        }
      }
      .select_right {
        // .create {
        //   width: 140px;
        //   height: 38px;
        //   background: #ff8201;
        //   border-radius: 6px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   color: #ffffff;
        //   font-weight: 700;
        // }
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .create {
          width: 140px;
          height: 38px;
          // background: #ff8201;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: 700;
        }
        .sousuo_one {
          display: flex;
          align-items: center;
          /deep/ .el-input__inner {
            // border-radius: 0px;
            border-radius: 4px 0px 0px 4px;
          }
          /deep/ .el-button {
            // border-radius: 0px;
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }
  }
  .CenterPage {
    width: 100%;
    background: #f7f7f7;
    padding: 24px 0;
    .main {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .live_block {
        width: 264px;
        height: 286px;
        margin-right: 13px;
        margin-bottom: 26px;
        .img {
          width: 264px;
          height: 149px;
          // background-image: url(../../../assets/images/live.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column-reverse;
          .writer {
            width: 100%;
            height: 32px;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              color: #fffffe;
            }
          }
        }
        .ellipsis {
          white-space: normal;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .word1 {
          margin-left: 30px;
          margin-top: 20px;
          margin-bottom: 15px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .word2 {
          margin-left: 30px;
          color: #666666;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 7px;
          span:nth-child(1) {
            margin-right: 20px;
          }
        }
        .word3 {
          margin-left: 30px;
          color: #666666;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // span:nth-child(1) {
          //   margin-right: 30px;
          // }
          display: flex;
          align-items: center;
          .shuxian {
            height: 12px;
            width: 1px;
            background-color: #ebebeb;
            margin: 0px 12px;
          }
        }
      }
    }
    .main:after {
      content: "";
      width: 335px;
    }
    .pick {
      width: 335px;
      border: 1px solid transparent;
      overflow: hidden;
    }
    .nodata {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #888;
      margin-top: 100px;
    }
  }
}
</style>