<template>
  <!-- 公用头部组件 -->
  <div class="Public_Header">
    <div class="public_main_div Public_box_Header">

      <div class="header_logo_div"
        @click="toNavEvent(webName == 'web_msgzs' ? { path: '/MsHome', identity: 'MsHome' } : { path: '/index', identity: 'index' })">
        <img class="header_logo_img" v-if="logo1_url" :src="logo1_url" />
      </div>

      <div class="header_nav_div">
        <!-- 登录_前 -->
        <template v-if="!unserInfoData">
          <template v-for="(item, index) in web_all.auth">
            <!-- <div class="header_nav_item" :class="headerActive.indexOf(item.identity) != -1 ? 'active' : ''"
              @click="toNavEvent(item)" v-if="item.identity != '#'" :key="index">
              {{ item.name }}
            </div> -->
            <div class="header_nav_item" :class="headerActive.indexOf(item.identity) != -1 ? 'active' : ''"
              @click="toNavEvent(item)" v-if="item.children.length == 0" :key="index">
              {{ item.name }}
            </div>
            <template v-else>
              <el-popover placement="bottom" width="120" trigger="hover" :key="index">
                <div class="xueke-nav" v-for="(noon, idx) in item.children" :key="idx" @click="toNavEvent(noon)">
                  {{ noon.name }}
                </div>

                <div slot="reference" class="header_nav_item mr50"
                  :class="headerActive.indexOf(item.identity) != -1 ? 'active' : ''" @click="toNavEvent(item)"
                  :key="index">
                  {{ item.name }}
                </div>
              </el-popover>
            </template>
            <!-- <template v-else>
              <el-popover width="250" trigger="hover" @show="xuekePopoverShow" :key="index" v-if="item.children == ''">
                <div class="xueke-nav" v-for="(item, index) in xuekeNavList" :key="index">
                  <div class="nav-grade">{{ item.name }}</div>
                  <ul class="nav-list" v-if="item.children.length > 0">
                    <li class="nav-list-item"
                      :class="toXueKeNavIndex == `${index},${index1}` ? 'nav-list-item-active' : ''"
                      v-for="(item1, index1) in item.children" :key="index1"
                      @click="toXueKeNavEvent(item.id, item1.id, index, index1)">
                      {{ item1.name }}
                    </li>
                  </ul>
                </div>
                <div class="header_nav_item"
                  :class="headerActive.indexOf('StudioListOld?navActive=2&stage_id=') != -1 ? 'active' : ''"
                  slot="reference">{{ item.name }}</div>
              </el-popover>
            </template> -->
          </template>
        </template>

        <!-- 登录_后 -->
        <template v-if="unserInfoData">
          <template v-for="(item, index) in web_all.auth">
            <template v-if="item.children.length">
              <el-popover placement="bottom" width="120" trigger="hover" :key="index">
                <div class="xueke-nav" v-for="(noon, idx) in item.children" :key="idx" @click="toNavEvent(noon)">
                  {{ noon.name }}
                </div>

                <div slot="reference" class="header_nav_item mr50"
                  :class="headerActive.indexOf(item.identity) != -1 ? 'active' : ''" @click="toNavEvent(item)"
                  :key="index">
                  {{ item.name }}
                </div>
              </el-popover>
            </template>
            <template v-else>
              <div class="header_nav_item" :class="headerActive.indexOf(item.identity) != -1 ? 'active' : ''"
                @click="toNavEvent(item)" v-if="item.name != '云资源' && item.identity != '#'" :key="index">
                {{ item.name }}
              </div>
              <div class="header_nav_item" :class="headerActive.indexOf('publicPages1') != -1 ? 'active' : ''"
                @click="toNavEvent(item)" v-if="item.name == '云资源' && item.identity != '#'" :key="index">
                {{ item.name }}
              </div>
            </template>

            <!-- <template v-else>
              <el-popover width="250" trigger="hover" @show="xuekePopoverShow" :key="index" v-if="item.name == '学科四名'">
                <div class="xueke-nav" v-for="(item, index) in xuekeNavList" :key="index">
                  <div class="nav-grade">{{ item.name }}</div>
                  <ul class="nav-list" v-if="item.children.length > 0">
                    <li class="nav-list-item"
                      :class="toXueKeNavIndex == `${index},${index1}` ? 'nav-list-item-active' : ''"
                      v-for="(item1, index1) in item.children" :key="index1"
                      @click="toXueKeNavEvent(item.id, item1.id, index, index1)">
                      {{ item1.name }}
                    </li>
                  </ul>
                </div>
                <div class="header_nav_item"
                  :class="headerActive.indexOf('StudioListOld?navActive=2&stage_id=') != -1 ? 'active' : ''"
                  slot="reference">{{ item.name }}</div>
              </el-popover>
            </template> -->

          </template>
        </template>
      </div>

      <div class="mr20" v-if="webName == 'teachercamp_msgzs'">
        <!-- <el-button type="text">[ 地方频道 ]</el-button> -->
      </div>
      <div class="mr20" v-if="webName == 'teachercamp_msgzs'">
        <el-input @keyup.enter.native="goMsSctiList" class="input-with-select width180" size="small"
          placeholder="请输入搜索关键词" v-model="input3">
          <el-button slot="append" icon="el-icon-search" @click="goMsSctiList"></el-button>
        </el-input>
      </div>

      <div class="header_right_div">
        <template v-if="unserInfoData">
          <div class="header_right_user">
            <div class="message_div" @click="toPageChange(2)">
              <img class="message_img" src="@/assets/images/personal/tz.png" />
              <div v-if="msgObj.count" class="message_num"> {{ msgObj.count }} </div>
            </div>

            <div class="message_box" style="margin-right: 20px;" v-if="studioObj.total == 1">
              <!-- <div class="message_name_box" @click="goStudio">
                <img class="message_name_img" src="@/assets/images/gongzuoshi.png" />
              </div> -->
              <p class="message_name_text" @click="goStudio">我的工作室 ›</p>
            </div>
            <el-dropdown v-if="studioObj.total != 1 && studioObj.total != 0" @command="handgoStudio" trigger="hover"
              placement="bottom" style="margin-right: 20px;">
              <div class="message_box">
                <!-- <div class="message_name_box">
                  <img class="message_name_img" src="@/assets/images/gongzuoshi.png" />
                </div> -->
                <p class="message_name_text">我的工作室 ›</p>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in studioObj.data" :key="index" :divided="index != 0"
                  :command="item">{{
          item.teaching_studio_name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>


            <el-dropdown @command="handleUserEvent" trigger="hover">
              <div class="message_box">
                <div class="message_name_box">
                  <img class="message_name_img" v-if="unserInfoData.photo_url" :src="unserInfoData.photo_url" />
                  <img class="message_name_img" v-else src="@/assets/images/user.png" />
                </div>
                <div class="message_name_name">
                  {{ unserInfoData.username || "---" }}
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in userArr" :key="index" :divided="index != 0" :command="item">{{
          item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>

        <div v-if="!unserInfoData" class="header_right_login" @click="toPageChange(1)">
          登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StudioList } from "@/api/teacherStudio";
import store from '@/utils/store.js'
import { mapState } from 'vuex';
import { localGet, localSet, localRemove } from "@/utils/utils.js";
export default {
  name: "PublicHeader",
  props: {
    logo1_url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      input3: "",
      select: 1,
      nowUrl: "",
      // 登录_前
      navArr_before: [
        { name: "首页", id: 1, path: "/index" },
        { name: "研修中心", id: 2, path: "/researchCenter" },
        { name: "教研组", id: 3, path: "/groupIndex" },
        // { name: "四名工作室", id: 4, path: "/StudioList" },
      ],
      // 登录_后
      navArr_after: [
        { name: "首页", id: 1, path: "/index" },
        { name: "研修中心", id: 2, path: "/researchCenter" },
        {
          pulldown: true,
          name: "资源中心",
          id: 6,
          path: "/resourceCentre",
          children: [
            {
              name: "资源库",
              identity: "/home/#/resourcePool/resourceHome",
            },
            {
              name: "试题库",
              identity: "/home/#/questionBank/questionHome",
            },
            {
              name: "试卷库",
              identity: "/home/#/questionBank/paperTopic",
            },
          ],
        },
        { name: "教研组", id: 3, path: "/groupIndex" },
        { name: "研讨社区", id: 5, path: "/community" },
        // { name: "四名工作室", id: 4, path: "/StudioList" },
      ],
      userArr: [
        { name: "个人中心", path: "/perSonIndex" },
        { name: "个人资料", path: "/personInfo" },
        { name: "修改密码", path: "/changePwd" },
        { isLogout: true, name: "退出登录", path: "" },
      ],
      headerActive: "",
      unserInfoData: null,
      web_all: {},
      webName: "",
      xuekeNavList: [],
      toXueKeNavIndex: "",
      studioObj: {}
    };
  },

  watch: {
    $route: {
      handler(newVal, oldval) {
        this.headerActive = newVal.fullPath;
      },
      deep: true,
    },
  },

  computed: {
    ...mapState([
      'msgObj',
    ]),
  },

  components: {},

  methods: {
    goMsSctiList() {
      if (this.input3) {
        this.$router.push({
          name: "searchList",
          query: { selName: this.input3 }
        })
      } else {
        this.$message.warning("请输入搜索关键字")
      }
    },
    goStudio() {
      this.$router.push({
        path: "/TeachLectureIndex",
        query: {
          id: this.studioObj.data[0].id,
          teaching_studio_id: this.studioObj.data[0].id,
        },
      });
    },
    handgoStudio(item) {
      let flag = false
      if (this.$route.name == 'TeachLectureIndex') {
        flag = true
      }
      this.$router.push({
        path: "/TeachLectureIndex",
        query: {
          id: item.id,
          teaching_studio_id: item.id,
        },
      });
      // setTimeout(() => {
      //   this.$router.go(0)
      // }, 300);
      if (flag) {
        this.$router.go(0)
      }
    },
    onGetList() {
      StudioList({ page: 1, limit: 5, user_id: localStorage.getItem("id") }).then((res) => {
        this.studioObj = res.data;
      });
    },
    setWeb_all() {
      let temArr = [];
      this.web_all.auth.forEach((item) => {
        if (item.is_permit == 1) {
          // if (item.name == '四名广场') {
          //   item.pulldown = true
          //   item.children = [
          //     { children: [], identity: "/StudioList?view=index", is_permit: 1, name: "广场首页", pulldown: false },
          //     { children: [], identity: "/StudioListOld", is_permit: 1, name: "查找四名", pulldown: false },
          //     { children: [], identity: "/researchCenter?navActive=2", is_permit: 1, name: "四名讲堂", pulldown: false },
          //     { children: [], identity: "/researchCenter?navActive=1", is_permit: 1, name: "教研活动", pulldown: false },
          //   ]
          // }
          // if (item.name == '四名伴我行') {
          //   item.pulldown = true
          //   item.children = [
          //     { children: [], identity: "/MsWelfare", is_permit: 1, name: "全部活动", pulldown: false },
          //     { children: [], identity: "/MsLiveList", is_permit: 1, name: "活动直播", pulldown: false },
          //     { children: [], identity: "/MsAdvanceList?type2=1", is_permit: 1, name: "四名大讲堂", pulldown: false },
          //     { children: [], identity: "/MsAdvanceList?type2=2", is_permit: 1, name: "四名公开课", pulldown: false },
          //     { children: [], identity: "/MsAdvanceList?type2=4", is_permit: 1, name: "教育帮扶", pulldown: false },
          //   ]
          // }
          temArr.push(item);
        }
      });
      this.$set(this.web_all, "auth", temArr);
      console.log('666', this.web_all.auth);
      var hoststs = window.location.host;
      // if (false && hoststs == 'www.teachercamp.net' || hoststs == 'msgzs.teachercamp.net' ||
      //   hoststs == 'jypthouduan.co' || hoststs == 'jypthouduan.co:81' || hoststs == 'hlwjy.dc-it.cn') {
      //   this.webName = "teachercamp_msgzs";
      //   temArr = [];
      //   temArr.push(
      //     { children: [], identity: "/MsHome", is_permit: 1, name: "首页", pulldown: false },
      //     // { children: [], identity: "/StudioListOld?navActive=2&grade=1", is_permit: 1, name: "省级四名", pulldown: false },
      //     // { children: [], identity: "/StudioListOld?navActive=2&grade=2", is_permit: 1, name: "市级四名", pulldown: false },
      //     // { children: [], identity: "/StudioListOld?navActive=2&grade=3", is_permit: 1, name: "县区四名", pulldown: false },
      //     // { children: "", identity: "#", is_permit: 1, name: "学科四名", pulldown: false },
      //     { children: [], identity: "/StudioList?view=index", is_permit: 1, name: "四名广场", pulldown: false },
      //     // { children: [], identity: "/MsActivity", is_permit: 1, name: "四名活动", pulldown: false },
      //     { children: [], identity: "/MsWelfare", is_permit: 1, name: "四名伴我行", pulldown: false },
      //     // { children: [], identity: "/MsElegant", is_permit: 1, name: "四名风采", pulldown: false },
      //     { children: [], identity: "/serviceEight", is_permit: 1, name: "八大服务", pulldown: false },
      //   )
      //   this.$set(this.web_all, "auth", temArr);
      //   this.getXuekeNav();
      // } else {
      //   this.$set(this.web_all, "auth", temArr);
      // }

      if (hoststs == 'msgzs.teachercamp.net' || hoststs == 'www.teachercamp.net' || hoststs == 'jypn.teachercamp.net' || hoststs == 'hlwjy.dc-it.cn' || hoststs == 'jypthouduan.co') {
        this.webName = "web_msgzs";
      }
    },
    async getPower() {
      let res = await this.$axios.get("webConfig/getWebConfig");
      sessionStorage.setItem("webAll", JSON.stringify(res.data.data));
      this.web_all = res.data.data;
      this.setWeb_all();
    },
    // // 资源库跳转
    // handleOuterEvent(path) {
    //   let nowPath = window.location.host;
    //   console.log(nowPath);
    //   let unserInfoData = localGet("unserInfoData");
    //   // let goSrc = "";
    //   let openSrc = `${path}?aes_code=${unserInfoData.aes_code}`;
    //   // if (unserInfoData && unserInfoData.aes_code) {
    //   //   openSrc = `${path}?aes_code=${unserInfoData.aes_code}`;
    //   // } else {
    //   //   openSrc = path;
    //   // }
    //   // if (nowPath.indexOf("hlwjy.dc-it.cn") != -1) {
    //   //   goSrc = "https://bx20.dc-it.cn" + openSrc;
    //   // } else {
    //   //   goSrc = "https://www.bxwang.net" + openSrc;
    //   // }
    //   window.open("https://" + openSrc);
    // },
    // 个人中心模块
    handleUserEvent(item) {
      if (item.isLogout) {
        let hostName = location.host
        let domes = 'https://uc.dc-it.cn'
        if (hostName.indexOf("teachercloud.com.cn") != -1) {
          domes = "https://dcuc.teachercloud.com.cn"
        }
        $.get(`${domes}/login/logout`, {
        }, function (ress) { })
        // uc退出登录
        // let iframe = document.getElementById('iframe');
        // console.log(iframe);
        // iframe.contentWindow.postMessage({
        //   "type": "logout",
        // }, '*');
        this.$axios.get("/logout").then((res) => {
          this.$message({
            message: "退出成功",
            type: "success",
          });
          //清除缓存信息
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "./";
          }, 500);
        });
        return false;
      }

      this.headerActive = "";
      this.$router.push({
        path: item.path,
      });
    },
    // 导航切换
    toNavEvent(item) {
      // if (item.name == '小学四名') {
      //   this.headerActive = 'stage_id=1'
      // } else if (item.name == '初中四名') {
      //   this.headerActive = 'stage_id=2'
      // } else if (item.name == '高中四名') {
      //   this.headerActive = 'stage_id=3'
      // } else if (item.name == '四名首页') {
      //   this.headerActive = 'view=index'
      // } else {
      //   this.headerActive = item.identity;
      // }
      // 获取深拷贝的路由名称
      let oldUrl = JSON.parse(JSON.stringify(this.$route.name));
      if (item.name == '云资源') {
        this.$router.push({
          name: 'publicPages1',
          query: { url: item.identity }
        });
      } else if (item.name == '八大服务') {
        let routeData = this.$router.resolve({
          path: item.identity,
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({
          path: item.identity,
        });
      }
      setTimeout(() => {
        let newUrl = JSON.parse(JSON.stringify(this.$route.name));
        if (oldUrl == newUrl) {
          this.$router.go(0);
        }
      }, 300);
    },
    toXueKeNavEvent(stage_id, subject_id, stage_index, subject_index) {
      this.toXueKeNavIndex = `${stage_index},${subject_index}`
      this.$router.push({
        path: `/StudioListOld?navActive=2&stage_id=${stage_id}&subject_id=${subject_id}&stage_index=${stage_index}&subject_index=${subject_index}`,
      });
    },
    // 跳转
    toPageChange(num) {
      this.headerActive = "";
      switch (Number(num)) {
        case 1:
          // 登录
          this.$router.push({
            path: "/logo",
          });
          break;
        case 2:
          // 消息中心
          this.$router.push({
            path: "/message",
          });
          break;
        case 3:
          // 个人中心
          this.$router.push({
            path: "/perSonIndex",
          });
          break;
        default:
          break;
      }
    },
    getMessage() {
      store.dispatch('getMessage');
    },
    async getXuekeNav() {
      this.xuekeNavList = [];
      const res = await this.$axios.get("/stage/stage_list");
      if (res.data.data.length) {
        res.data.data.forEach((item, index) => {
          //if(item.name != "其他") {
          this.xuekeNavList.push({
            name: item.name,
            id: item.id,
            children: []
          })
          this.$axios.get("/subject/list", {
            params: { pharseid: item.id },
          }).then((res1) => {
            if (res1.data.data.length) {
              res1.data.data.forEach((item) => {
                //if(item.subjectName != "其他") {
                this.xuekeNavList[index].children.push({
                  name: item.subjectName,
                  id: item.subjectId
                })
                //}
              })
            }
          })
          //}
        });
      }
    },
    xuekePopoverShow() {
      this.toXueKeNavIndex = `${this.$route.query.stage_index},${this.$route.query.subject_index}`
    }
  },

  destroyed() { },

  mounted() {

  },

  created() {
    if (sessionStorage.getItem("webAll")) {
      this.web_all = JSON.parse(sessionStorage.getItem("webAll"));
      this.setWeb_all();
    } else {
      this.getPower();
    }
    let nowPath = window.location.host;
    if (nowPath.indexOf("gy.teachercamp.net") != -1) {
      this.nowUrl = 1;
    } else {
      this.navArr_before.push({
        name: "四名工作室",
        id: 4,
        path: "/StudioList",
      });
      this.navArr_after.push({
        name: "四名工作室",
        id: 4,
        path: "/StudioList",
      });
    }
    this.headerActive = this.$route.fullPath;
    let unserInfoData = localGet("unserInfoData");
    if (unserInfoData && unserInfoData.id && unserInfoData.token) {
      this.unserInfoData = unserInfoData;
      this.getMessage();
      this.onGetList()
    } else {
      this.unserInfoData = null;
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input-group__append {
  padding: 0 10px;
}

/deep/.el-input__inner {
  padding-left: 5px;
}

.Public_Header {
  position: fixed;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  height: 64px !important;
  flex: 0 0 64px;
  box-shadow: 0 10px 19px 1px rgba(223, 236, 246, 0.5);
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }
}

.Public_box_Header {
  height: 100%;
  display: flex;
  align-items: center;
}

.header_logo_div {
  width: 220px;
  height: 42px;
  margin-right: 80px;
  cursor: pointer;

  .header_logo_img {
    width: 220px;
    height: 42px;
  }
}

.header_nav_div {
  flex: 1;
  height: 64px;
  display: flex;

  .header_nav_item {
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    color: #333;
    border-bottom: 3px solid #fff;
    cursor: pointer;
    margin-right: 50px;
    box-sizing: border-box;

    &:hover {
      color: #3489fe;
    }

    &.active {
      font-weight: bold;
      color: #3489fe;
      border-color: #3489fe;
    }

    &:last-child {
      margin-right: 0;
    }

    .header_nav_span {
      margin-right: 0;
      display: inline-block;

      i {
        margin: 0;
      }
    }
  }
}

.header_right_div {
  display: flex;
  align-items: center;

  .header_right_user {
    display: flex;
    align-items: center;

    .message_div {
      width: 22px;
      height: 26px;
      cursor: pointer;
      margin-right: 35px;

      .message_img {
        width: 22px;
        height: 26px;
      }

      .message_num {
        position: absolute;
        top: 12px;
        margin-left: 8px;
        padding: 0 10px;
        height: 18px;
        display: flex;
        align-items: center;
        background-color: #ff0607;
        line-height: 18px;
        border-radius: 9px;
        font-size: 12px;
        color: #ffffff;
      }
    }

    .message_box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .message_name_box,
      .message_name_img {
        width: 34px;
        height: 34px;
        border-radius: 4px;
      }

      .message_name_name {
        font-size: 16px;
        line-height: 64px;
        color: #333;
        margin-left: 8px;
      }

      .message_name_text {
        font-size: 16px;
        color: #fff;
        width: 120px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: rgba(87, 168, 239, 1);
        border-radius: 8px;

        &:hover {
          background: rgba(87, 168, 239, 0.8);
        }
      }
    }
  }

  .header_right_login {
    background: linear-gradient(-24deg,
        rgba(30, 111, 255, 1) 0%,
        rgba(30, 162, 255, 1) 100%);
    border-radius: 8px;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 6px 10px;
  }
}

.el-popover /deep/ {
  min-width: 120px;
}

.xueke-nav {
  font-size: 16px;
  padding: 8px;
  text-align: center;
  color: #333;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebeef5;
  cursor: pointer;

  &:hover {
    color: #3489fe;
  }
}
</style>