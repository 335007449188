<template>
  <div class="ArticleList">
    <div class="w_layout ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>活动报道</span>
        </p>
      </div>
      <div class="bottomBox">
        <div class="Art_left">
          <h4 class="ml10">活动类型</h4>
          <ul v-if="typeList.length > 0">
            <li v-for="(item, index) in typeList" :key="index" :class="{ 'active': item.id == form.search_type }">
              <p @click="setType(item.id)">{{ item.name }}</p>
              <div class="childBox" v-if="item.children.length">
                <div :class="{ 'active': noon.id == form.search_nature }" class="childSty"
                  v-for="(noon, dex) in item.children" :key="dex" v-show="item.children.length"
                  @click="setType2(item.id, noon.id)">
                  {{ noon.name }}
                </div>
              </div>
            </li>
          </ul>
          <CommonEmpty v-else />
        </div>
        <div class="Art_right">
          <div class="df dfb mb10">
            <p class="topT">活动报道</p>
            <template v-if="detailData.role != 0">
              <div class="btnBox" @click="pushText()" v-if="workData.is_addUser == 1 || workData.role">
                新增活动报道
              </div>
            </template>
          </div>
          <div v-if="total > 0">
            <div class="demoBox">
              <!-- //循环 -->
              <div v-for="(item, index) in articleList" :key="index" class="fc forBox">
                <div class="imgBox cp" @click="toArticleDetail(item.id)">
                  <img :src="item.cover_url" v-if="item.cover_url" alt="">
                  <div class="about" :class="item.status == 4 ? 'aboutFas' : ''"
                    v-if="item.status == 1 || item.status == 4">
                    {{ item.status == 1 ? '待审核' : '未通过' }}
                  </div>
                  <div class="about aboutLeft" v-if="item.top == 1 && item.status != 1 && item.status != 3">
                    置顶
                  </div>
                </div>
                <div class="txtBox cp" @click="toArticleDetail(item.id)">
                  <h4 class="ellipsis-1">{{ item.title }}</h4>
                  <p class="towp ellipsis-2 mt10 mb10">{{ item.abstract }}</p>
                  <div class="divtwo">
                    <p>{{ item.type_name }} / {{ item.nature_name }}</p>
                  </div>
                  <div class="iconBox mt10">
                    <p>发布者：{{ item.username }}</p>
                    <p>发布时间：{{ item.create_time }}</p>
                    <p>浏览量：{{ item.view_count }}</p>
                    <p>评论：{{ item.reply_count }}</p>
                  </div>
                </div>
                <div class="btnBoxs" v-if="item.user_id == user_id">
                  <div class="cursor mb20" @click="pushText(item.id)">编辑 ></div>
                  <div class="cursor" @click="delList(item.id)">删除 ></div>
                </div>
              </div>
            </div>

            <div v-show="total" class="pagina_div" style="height:100px">
              <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>

          </div>
          <CommonEmpty v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["workData"],
  data() {
    return {
      total: 0,
      form: {
        limit: 12,
        page: 1,
        teaching_studio_id: "",
        type: "",
        search_type: "",
        search_nature: "",
      },
      articleList: [],
      typeList: [],
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
      user_id: localStorage.getItem("id") || 0
    };
  },
  methods: {
    delList(id) {
      this.$confirm('确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete("TeachingStudioReport/delete", {
          params: {
            teaching_studio_id: localStorage.getItem("studioId"),
            ids: id,
          },
        }).then((res) => {
          this.form.page = 1
          this.getArtiCleList()
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    //跳转文章详情
    toArticleDetail(id) {
      let routeData = this.$router.resolve({
        path: "/ReportDetail",
        query: {
          tid: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //发布文章
    pushText(id) {
      if (id) {
        this.$router.push("/addReportIndex?id=" + id);
        return
      }
      this.$router.push("/addReportIndex");
    },
    setType(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "search_nature", '');
      this.$set(this.form, "search_type", val);
      this.getArtiCleList();
    },
    setType2(val1, val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "search_type", val1);
      this.$set(this.form, "search_nature", val);
      this.getArtiCleList();
    },
    //文章
    getArtiCleList() {
      this.$axios.get("TeachingStudioReport/listTeachingStudioReport", { params: this.form, }).then((res) => {
        this.articleList = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    //获取文章分类
    getArticleType() {
      let obj = {
        id: 41
      };
      this.$axios.get("index/dicTreeList", { params: obj, }).then((res) => {
        let tempItem = {
          "id": "",
          "name": "全部",
          "children": [],
        };
        res.data.data.unshift(tempItem);
        this.typeList = res.data.data;
      });
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.getArtiCleList();
    },
    //   跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.getArtiCleList();
    },
  },
  created() {
    this.form.teaching_studio_id = this.$route.query.studioId
      ? this.$route.query.studioId
      : localStorage.getItem("studioId");
    this.getArtiCleList();
    this.getArticleType();
    this.getAdminData();
  },
};
</script>

<style lang="less" scoped>
.ArticleList {
  .ArrBox {
    padding-bottom: 60px;

    .title {
      height: 48px;
      font-size: 14px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    .bottomBox {
      display: flex;
      align-items: flex-start;

      .Art_left {
        width: 274px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 20px;
        padding: 9px 0px;
        box-sizing: border-box;

        h4 {
          font-size: 18px;
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-weight: 700;
          color: #333333;
          box-sizing: border-box;
          padding-left: 20px;
        }

        ul {
          box-sizing: border-box;
          width: 234px;
          margin-left: 20px;
          cursor: pointer;
          height: 100%;

          &::-webkit-scrollbar {
            width: 5px;
            height: 1px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: skyblue;
            background-image: -webkit-linear-gradient(45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent);
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #ededed;
            border-radius: 10px;
          }

          li {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            background: rgba(238, 246, 255, 1);
            margin-top: 4px;



            p {
              border-radius: 4px;
              font-size: 18px;
              padding: 10px 10px 10px 20px;

              &:hover {
                color: #fff;
                background: #3489FF;

                &::before {
                  content: "";
                  left: 10px;
                  top: 21px;
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  background: #fff;
                  border-radius: 50%;
                }
              }
            }

            .childBox {
              background: #fff;
              padding: 2px 0;

              .childSty {
                border-radius: 4px;
                font-size: 16px;
                padding: 10px 10px 10px 40px;
                margin-top: 2px;
                background: rgba(238, 246, 255, 1);
                font-size: 16px;
                font-family: Microsoft YaHei;
                color: rgba(51, 51, 51, 1);

                &:first-child {
                  margin-top: 0;
                }

                &:hover {
                  color: #fff;
                  background: #3489FF;
                }

                &.active {
                  color: #fff;
                  background: #3489FF;
                }
              }
            }

            &.active {
              p {
                color: #fff;
                background: #3489FF;
              }

              &::before {
                content: "";
                left: 10px;
                top: 21px;
                position: absolute;
                width: 5px;
                height: 5px;
                background: #fff;
                border-radius: 50%;
              }
            }

            // &:hover {
            //   background: rgba(#3489ff, 0.08);
            // }
            &::before {
              content: "";
              left: 10px;
              top: 21px;
              position: absolute;
              width: 5px;
              height: 5px;
              background: #3489ff;
              border-radius: 50%;
            }
          }
        }
      }

      .Art_right {
        flex: 1;
        background: #ffffff;
        border-radius: 4px;
        padding: 20px 16px;
        box-sizing: border-box;

        .topT {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 10px;
          padding-left: 10px;
        }

        .demoBox {
          .forBox {
            padding: 10px;
            border-bottom: 1px dashed #ccc;

            &:hover {
              background-color: #eef6ff;
            }

            .imgBox {
              width: 250px;
              height: 141px;
              position: relative;

              .about {
                position: absolute;
                top: 0;
                right: 0;
                background: #ff8201;
                color: #fff;
                font-size: 14px;
                padding: 2px 5px;
                border-radius: 0 5px 0 5px;

                &.aboutFas {
                  background: #ff0607;
                }
              }

              .aboutLeft {
                left: 0;
                width: 40px;
                text-align: center;
                border-radius: 5px 0 5px 0;
                background: #ff0607;
              }

              img {
                border-radius: 8px;
                width: 100%;
                height: 100%;
              }
            }

            .txtBox {
              width: calc(100% - 400px);
              margin-left: 24px;
              margin-right: 24px;

              p {
                color: #666;
                font-size: 14px;
              }

              .towp {
                height: 38px;
              }

              .divtwo {
                display: flex;

                p {
                  background: rgba(229, 244, 255, 1);
                  border: 1px solid rgba(4, 147, 255, 1);
                  border-radius: 4px;
                  font-weight: normal;
                  color: rgba(4, 147, 255, 1);
                  padding: 2px 6px;
                }
              }

              .iconBox {
                display: flex;

                p {
                  margin-right: 20px;
                }
              }
            }

            .btnBoxs {
              width: 100px;

              >div {
                width: 92px;
                height: 32px;
                background: #fef0f0;
                border: 1px solid #f78383;
                border-radius: 4px;
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f78383;
                text-align: center;
                line-height: 32px;

                &:first-child {
                  border: 1px solid #67c23a;
                  color: #67c23a;
                  background: #f0f9eb;
                }

                &:hover {
                  background: #f78383;
                  color: #fff;

                  &:first-child {
                    background: #67c23a;
                    color: #fff;
                  }
                }
              }
            }

          }
        }

        .block {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    }
  }
}

.btnBox {
  text-align: center;
  line-height: 34px;
  background: #3489fe;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 0 10px;
}
</style>