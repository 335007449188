<template>
  <div class="noticeDetail">
    <div class="w_layout DetailMain">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>公告详情</span>
        </p>
      </div>
      <!-- 内容 -->
      <div class="mainContent">
        <p>{{ DetailRes.title }}</p>
        <p>
          <span>发布人：{{ DetailRes.realname }}</span>
          <span>发布时间：{{ DetailRes.create_time }}</span>
          <span>浏览：{{ DetailRes.view_count }}</span>
        </p>
        <div class="textB">
          <div class="rich_text_div_img" v-html="DetailRes.content"> </div>
        </div>
        <!-- //附件 -->
        <div class="FJ_div">
          <template v-for="(item, index) in DetailRes.resource_file">
            <new-viewonline :key="index" :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item"
              :fileTitle="item.name" :fileSize="item.size" :fileTime="item.create_time" fileNav="四名工作室 > 公告详情 > 查看">
              <p class="cp">{{ item.name }}</p>
            </new-viewonline>
          </template>
        </div>
        <!-- 上下翻页 -->
        <div class="Fy_div">
          <div v-if="upData" class="cursor TopPage" @click="toDetail(upData,'已经是第一篇了')">
            <span>上一篇：</span>
            <p class="ellipsis">{{ upData && upData.title }}</p>
          </div>
          <div v-if="downData" class="cursor lastPage" @click="toDetail(downData,'已经是最后一篇了')">
            <span>下一篇：</span>
            <p class="ellipsis">{{ downData && downData.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DetailRes: "",
      upData: "",
      downData: "",
      id: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.init();
  },
  computed: {
    // upData(){
    //     return this.DetailRes.uppe || {content:"",id:""} ;
    // },
    // downData(){
    //     return this.DetailRes.next;
    // },
  },
  methods: {
    init() {
      this.$axios.get("index/teachingStudioNotice/infoTeachingStudioNotice", {
        params: { id: this.id },
      }).then((res) => {
        this.DetailRes = res.data.data;
        this.upData = this.DetailRes.uppe;
        this.downData = this.DetailRes.next;
      });
    },
    //下载附件
    toOpen(item) {
      window.open(item.url);
    },
    //跳转下个广告详情
    toDetail(data, txt) {
      if (data) {
        this.id = data.id;
        this.init();
      } else {
        this.$message.warning(txt)
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  word-wrap: break-word;
  word-break: break-all;
}
.noticeDetail {
  padding-bottom: 35px;
  .DetailMain {
    .title {
      width: 232px;
      height: 48px;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;
      p {
        span {
          color: #ff8201;
        }
      }
    }
    //内容
    .mainContent {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      background: #ffffff;
      border-radius: 6px;
      padding: 72px 80px;
      padding-top: 30px;
      p:nth-of-type(1) {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 32px;
        margin-bottom: 18px;
      }
      p:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }
      }
      .textB {
        border-top: 1px solid #f0f0f0;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
      }
      .FJ_div {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        p {
          width: 400px;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #3489fe;
          line-height: 32px;
        }
      }
      //翻页样式
      .Fy_div {
        display: flex;
        .TopPage {
          flex: 1;
          height: 68px;
          background: #fafafa;
          border: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          padding-left: 20px;
          box-sizing: border-box;
          span {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            line-height: 32px;
          }
          p {
            width: 450px;
            margin: 0;
            color: #3489fe;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
          }
        }
        .lastPage {
          flex: 1;
          height: 68px;
          background: #fafafa;
          border: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          padding-left: 20px;
          box-sizing: border-box;
          span {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            line-height: 32px;
          }
          p {
            width: 450px;
            margin: 0;
            color: #3489fe;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>