<template>
  <div class="public_main_bgc">
    <div class="bannerSty">
      <el-carousel trigger="click" height="320px">
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/banner_sanming.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/shouye_banner.mingshi.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/mingshi_banner.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="public-width">
      <!-- 四名活动 -->
      <div class="public2box">
        <div class="publicLeft">
          <!-- 大标题 -->
          <div class="titleBox">
            <div class="titleLeft">
              <p>四名活动</p>
            </div>
            <div class="titleRight">
              <p class="cp" @click="goMsSctiList">更多></p>
            </div>
          </div>
          <div class="public-box-1">
            <div class="leftMain1">
              <div class="mainCenter">
                <div class="main1Left">
                  <template v-if="dataList1.length">
                    <div class="imgBox cp">
                      <img class="imgbig" v-if="dataList1.length && dataList1[active1].thumb"
                        :src="dataList1[active1].thumb" alt="" @click="goMsDetails(dataList1[active1].id)">
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                      <div class="abdian" v-if="dataList1.length > 1">
                        <div class="diandf">
                          <div v-for="(its, ind) in dataList1" v-show="ind <= 2" :key="ind" class="dian"
                            :class="ind == active1 ? 'activeDian' : ''" @mouseover.stop="changeActive1(ind)"></div>
                        </div>
                      </div>
                    </div>
                    <div class="txtBox cp" @click="goMsDetails(dataList1[active1].id)">
                      <p class="ellipsis-1 mb5">【{{ typeName(dataList1[active1].type) }}】{{ dataList1[active1].title }}
                      </p>
                      <p class="ellipsis-2">{{ setText(dataList1[active1].content) }}</p>
                    </div>
                  </template>
                  <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                </div>
                <div class="main1Right">
                  <div class="slrightTop df" v-if="dataList1.length >= 3">
                    <div class="imgBox cp" @click="goMsDetails(dataList1[3].id)">
                      <img class="imgbig" v-if="dataList1[3].thumb" :src="dataList1[3].thumb" alt="">
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    </div>
                    <div class="txtbox cp" @click="goMsDetails(dataList1[3].id)">
                      <h4 class="ellipsis-1 mb11">【{{ typeName(dataList1[3].type) }}】{{ dataList1[3].title }}</h4>
                      <p class="ellipsis-3">{{ setText(dataList1[3].content) }}</p>
                    </div>
                  </div>
                  <div class="mt20">
                    <template v-for="(its, ind) in dataList1">
                      <p class="ellipsis-1 cp line-h32 hoverBlue" :key="ind" v-if="ind > 3" @click="goMsDetails(its.id)">
                        <span class="fwb"><i class="el-icon-caret-right fz12 lanse"></i></span>
                        【{{ typeName(its.type) }}】{{ its.title }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftMain2">
              <div>
                <div class="mtitle">
                  <p>活动预告</p>
                </div>
                <!-- <div class="main2Center">
                  <ul class="eventTrailer-list" v-if="advanceList.length">
                    <li class="eventTrailer-list-item" v-for="(item, index) in advanceList" :key="index"
                      @click="goAdvanceDetails(item)">
                      <div class="item-img">
                        <img :src="item.thumb">
                      </div>
                      <div class="item-info">
                        <div class="info-text">
                          <p class="ellipsis-1">活动地点：{{ item.address }}</p>
                          <p class="ellipsis-1">活动时间：{{ item.start_date }} ~ {{ item.end_date }}</p>
                        </div>
                        <span class="info-button">了解详情></span>
                      </div>
                    </li>
                  </ul>
                  <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
                </div> -->
                <el-carousel trigger="click" height="186px" v-if="advanceList.length">
                  <el-carousel-item v-for="(item, index) in advanceList" :key="index">
                    <div class="activity-preview" :class="item.className">
                      <h3 class="activity-title">{{ item.title }}</h3>
                      <p class="activity-address">地址：{{ item.address }}</p>
                      <p class="activity-date">{{ item.start_date }} ~ {{ item.end_date }}</p>
                      <span class="activity-button" @click="goAdvanceDetails(item)">查看详情 ></span>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              </div>
            </div>
          </div>
        </div>
        <div class="publicRight">
          <template v-if="dataList5.length">
            <div class="titleBox">
              <div class="titleLeft">
                <p>活动申报</p>
              </div>
            </div>
            <div class="rightMain2">
              <div class="main2Img">
                <template v-if="dataList5.length">
                  <img class="imgbig cp" v-if="dataList5.length && dataList5[0].cover_url" :src="dataList5[0].cover_url"
                    alt="" @click="goMsAdvanceDetails(dataList5[0].id)">
                  <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                </template>
                <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              </div>
            </div>
          </template>
          <!-- 大标题 -->
          <div :class="dataList5.length != 0 ? 'live-list-box' : ''">
            <div class="titleBox">
              <div class="titleLeft">
                <p>活动直播</p>
              </div>
              <div class="titleRight">
                <p class="cp" @click="goMsLiveList">更多></p>
              </div>
            </div>
            <div class="rightMain1">
              <template v-if="dataList4.length">
                <div v-for="(item, index) in dataList4" :key="index">
                  <div class="rightTop df dfb">
                    <div class="imgBox">
                      <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="">
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                      <span class="status"
                        :class="item.status == 1 ? 'bg1' : item.status == 2 ? 'bg2' : item.playback_resource_url ? 'bg3' : 'bg4'">{{
                          item.status == 1 ? '预告' : item.status == 2 ? '直播中' : item.playback_resource_url ? "回放" : "已结束"
                        }}</span>
                    </div>
                    <div class="txtbox">
                      <h4 class="ellipsis-1 mb5">{{ item.title }}</h4>
                      <p class="ellipsis-2">{{ setText(item.abstract) }}</p>
                    </div>
                  </div>
                  <div class="rightBom mt14 df dfb">
                    <div class="bom1">
                      <p class="p-1">{{ item.start_date }} {{ item.start_time.substring(0, 5) }}~{{
                        item.end_time.substring(0, 5) }}</p>
                      <p class="p-2">观看人次：{{ item.view_count }}</p>
                    </div>
                    <div class="bom2">
                      <div class="btn cp" @click="goMsLiveDetails(item.id)">马上看</div>
                    </div>
                  </div>
                </div>
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>
        </div>
      </div>

      <!-- 四名公益行 -->
      <div class="publicWelfarebox mt26">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>四名伴我行</p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goPublicWelfareList">更多></p>
          </div>
        </div>
        <div class="public3main" v-if="publicWelfareList.length">
          <div class="cp" v-for="(item, index) in publicWelfareList" :key="index" @click="goMsDetails(item.id)">
            <div class="imgBox">
              <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
              <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
            </div>
            <div class="txtBox">
              <h4 class="ellipsis-1">{{ item.title }}</h4>
              <p class="ellipsis-2 mt10">{{ setText(item.content) }}</p>
            </div>
          </div>
        </div>
        <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
      </div>

      <!-- 活跃工作室推荐 -->
      <div class="public3box mt26">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>活跃工作室</p>
            <ul class="filter-list">
              <li class="filter-item" :class="index == studioFilterIndex ? 'filter-item-active' : ''" v-for="(item, index) in studioFilterList" :key="index" @click="studioFilterChange(index)">{{ item.label }}</li>
            </ul>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goStudioListOldList">更多></p>
          </div>
        </div>
        <div class="public3main" v-if="dataList11.length">
          <!-- 左右点击按钮 -->
          <div class="btn leftBtn" @click="changeBtn(1)">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="btn rightBtn" @click="changeBtn(2)">
            <i class="el-icon-arrow-right"></i>
          </div>
          <!-- 列表 -->
          <div class="main3Ul df">
            <div class="accordion-item " v-for="(item, index) in dataList11" :key="index"
              @mouseover.stop="changeActive(index)" v-show="index >= showArr[0] && index < showArr[1]">
              <!-- 大样式 -->
              <div class="bigBox cp" :class="`bigBox-${item.provinces_cities_counties}`" v-if="index == activeIndex"
                @click="go_urls(item.go_url)">
                <div class="topBox df">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.photo_url" :src="item.photo_url" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtBox">
                    <h3 class="txtBox-title ellipsis-1">{{ item.teaching_studio_name }}</h3>
                    <div class="txtBox-label">
                      <span class="ellipsis-1">{{ item.label }}</span>
                      <span class="ellipsis-1">{{ item.stage_name }}{{ item.subject_name }}</span>
                    </div>
                    <div class="txtBox-text">{{ item.org_name }}</div>
                  </div>
                </div>
                <div class="statistics-block">
                  <p class="statistics-block-item"><span>{{ item.user_count || "0" }}</span>成员数</p>
                  <p class="statistics-block-item"><span>{{ item.live_course_article_count || "0" }}</span>资源总数</p>
                  <p class="statistics-block-item"><span>{{ item.view_count || "0" }}</span>访问量</p>
                </div>
                <div class="bomBox">
                  <p class="ellipsis-3">{{ item.studio_synopsis }}</p>
                </div>
                <p class="look">去看看></p>
                <span class="label">{{ item.provinces_cities_counties | provincesCitiesCounties }}</span>
              </div>
              <!-- 小样式 -->
              <div class="minBox cp" v-else>
                <span :class="`label label-${item.provinces_cities_counties}`">{{ item.provinces_cities_counties |
                  provincesCitiesCounties }}</span>
                <div class="imgBox">
                  <img v-if="item.photo_url" :src="item.photo_url" alt="">
                  <img v-else src="@/assets/images/banner/home_news_pic.png" alt="">
                </div>
                <div class="txtBox">
                  <h3 class="txtBox-title ellipsis-1">{{ item.teaching_studio_name }}</h3>
                  <div class="txtBox-label">
                    <span class="ellipsis-1">{{ item.label }}</span>
                    <span class="ellipsis-1">{{ item.stage_name }}{{ item.subject_name }}</span>
                  </div>
                  <div class="txtBox-text">{{ item.org_name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
      </div>

      <!-- 四名风采 -->
      <div class="public1box mt16">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p></p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goMsElegant">更多></p>
          </div>
        </div>
        <div class="public1main">
          <template v-if="dataList10.length">
            <div class="df">
              <div class="df dfb cp" v-for="(item, index) in dataList10" :key="index" @click="goMsElegDetails(item.id)">
                <div class="imgBox">
                  <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                  <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                </div>
                <div class="txtbox">
                  <h4 class="ellipsis-1 mb6">{{ item.title }}</h4>
                  <p class="ellipsis-2">{{ setText(item.content) }}</p>
                </div>
              </div>
            </div>
          </template>
          <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
        </div>
      </div>

      <!-- 优质原创内容 -->
      <div class="public4box mt26">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>优质原创内容</p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goweChatList">更多></p>
          </div>
        </div>
        <div class="public4main df dfb">
          <div class="leftMain">
            <div class="df dfw" v-if="dataList13.length">
              <div class="cp" v-for="(item, index) in dataList13" :key="index" @click="goweChatDetails(item)">
                <div class="df dfb" v-if="index < 8">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>
                  <div class="txtbox">
                    <h4 class="ellipsis-1 mb6">{{ item.title }}</h4>
                    <p class="ellipsis-1 mb6">{{ item.create_time }}</p>
                    <p class="ellipsis-1 p2">{{ item.teaching_studio_name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <el-empty :image-size="100" v-else description="暂无数据"></el-empty>
          </div>
          <div class="rightMain">
            <div class="cp" v-for="(item, index) in dataList13_2" :key="index" @click="goweChatDetails(item)">
              <div class="df dfb" v-if="index < 4">
                <div class="imgBox">
                  <img class="imgbig" v-if="item.photo_url" :src="item.photo_url" alt="">
                  <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                </div>
                <div class="txtbox">
                  <h4 class="ellipsis-1 mb6">{{ item.teaching_studio_name }}</h4>
                  <p class="ellipsis-1">推送{{ item.num_article }}篇文章至{{ item.account_name_str }}</p>
                  <p>{{ item.create_time }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 合作媒体 -->
      <div class="public5box mt26">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>合作媒体</p>
          </div>
          <div class="titleRight">
            <!-- <p class="cp">更多></p> -->
          </div>
        </div>
        <div class="public5main df">
          <div class="cp" v-for="(item, index) in dataList12" :key="index" @click="goNewUrl(item.friend_link)">
            <div class="imgBox">
              <img v-if="item.thumb" :src="item.thumb" alt="">
              <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
            </div>
            <p class="ellipsis-1 tc mt15">{{ item.title }}</p>
          </div>
        </div>

        <div class="serve-module">
          <h4 class="serve-module-title">四名工程八大服务</h4>
          <p class="serve-module-text">聚成一团火 散作满河星</p>
          <ul class="serve-list">
            <li class="serve-list-item">
              <h5 class="item-title">论坛活动</h5>
              <p class="item-text">四名沙龙活动</p>
              <p class="item-text">高峰论坛活动</p>
              <p class="item-text">四名伴我行</p>
              <p class="item-text">竞赛活动</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">书籍出版服务</h5>
              <p class="item-text">文稿整理</p>
              <p class="item-text">排版设计</p>
              <p class="item-text">编辑校对</p>
              <p class="item-text">书号提供</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">课题服务</h5>
              <p class="item-text">论文指导</p>
              <p class="item-text">课题实施指导</p>
              <p class="item-text">省级教学成果奖申报</p>
              <p class="item-text">国家级教学成果奖申报</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">培训服务</h5>
              <p class="item-text">著名专家讲座</p>
              <p class="item-text">教育科研能力提升工程</p>
              <p class="item-text">数据素养、教学评价能力提升培训</p>
              <p class="item-text">命题能力提升、国际化培训</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">评估服务</h5>
              <p class="item-text">工作室过程管理与绩效评估</p>
              <p class="item-text">工作室期满绩效评估</p>
              <p class="item-text">职称评定评估</p>
              <p class="item-text">教学能力评估</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">平台服务</h5>
              <p class="item-text">四名网络工作室</p>
              <p class="item-text">课堂评价系统</p>
              <p class="item-text">职称评定系统</p>
              <p class="item-text">教师专业发展云平台</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">基地服务</h5>
              <p class="item-text">精准教与学应用示范校</p>
              <p class="item-text">精准教与学应用研究中心</p>
            </li>
            <li class="serve-list-item">
              <h5 class="item-title">媒体宣传</h5>
              <p class="item-text">八大媒体宣传服务</p>
              <p class="item-text">（南方都市报、南方+、今日头条、腾讯、网易、UC、四名网络工作室、四名伴我行）</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 底部背景条 -->
    <div class="foterstr"></div>
  </div>
</template>
<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      dataList1: [],
      active1: 0,
      dataList4: [],
      dataList5: [],

      active6: 0,
      dataList7: [],
      dataList8: [],
      dataList9: [],
      dataList10: [],
      dataList11: [],

      dataList12: [],
      dataList13: [],
      dataList13_2: [],

      activeIndex: 1,
      showArr: [0, 6],
      publicWelfareList: [],
      advanceList: [],
      studioFilterList: [
        {
          label: "名教师",
          value: 1
        },
        {
          label: "名班主任",
          value: 2
        },
        {
          label: "名校（园）长",
          value: 3
        },
        {
          label: "名书记",
          value: 5
        },
      ],
      studioFilterIndex: 0
    };
  },
  created() {
    this.getAllList()
  },
  methods: {
    typeName(val) {
      if (val == 1) {
        return '四名沙龙'
      }
      if (val == 2) {
        return '四名论坛'
      }
      if (val == 3) {
        return '竞赛活动'
      }
    },
    getAllList() {
      // 1四名沙龙
      this.getList1()
      // 5活动预告
      if (localStorage.getItem("unserInfoData")) {
        this.getList5()
      } else {
        this.getList4(4)
      }
      // 7送教下乡
      this.getList7()
      // 8教育帮扶
      this.getList8()
      // 9热点活动关注
      this.getList9()
      // 10四名风采
      this.getList10()

      // 11活跃工作室推荐
      this.getList11()
      this.getList12()

      // 13优质原创内容
      this.getList13()

      // 四名公益行
      this.getPublicWelfareList()

      // 活动预告
      this.getAdvanceList()
    },
    // 1四名沙龙
    getList1() {
      let param = {
        show_type: 1,
        limit: 9,
        hidden_type: 4
      }
      msAllApi.activityList(param).then(res => {
        this.dataList1 = res.data.data.data
        if (this.dataList1.length > 0) {
          setInterval(() => {
            this.active1 = this.active1 + 1
            if (this.active1 > 2) {
              this.active1 = 0
            }
          }, 5000);
        }
      })
    },
    changeActive1(index) {
      this.active1 = index
    },

    // 4活动直播
    getList4(val) {
      let param = {
        limit: 2,
      }
      if (val) param.limit = val
      msAllApi.LiveList(param).then(res => {
        this.dataList4 = res.data.data.data
      })
    },

    // 5活动申报
    getList5() {
      let param = {
        limit: 3,
      }
      msAllApi.SalonReg(param).then(res => {
        console.log(res.data.data);
        if (res.data.data) {
          this.dataList5 = [res.data.data]
          this.getList4()
        } else {
          this.dataList5 = []
          this.getList4(4)
        }
      })
    },


    changeActive6(index) {
      this.active6 = index
    },


    // 7送教下乡
    getList7() {
      let param = {
        show_type: 1,
        type1: 4,
        type2: 3,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList7 = res.data.data.data
      })
    },
    // 8教育帮扶
    getList8() {
      let param = {
        show_type: 1,
        type1: 4,
        type2: 4,
        limit: 6,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList8 = res.data.data.data
      })
    },
    // 9热点活动关注
    getList9() {
      let param = {
        show_type: 1,
        sort: 'hot',
        limit: 5,
      }
      msAllApi.activityList(param).then(res => {
        this.dataList9 = res.data.data.data
      })
    },
    // 10四名风采
    getList10() {
      let param = {
        show_type: 1,
        limit: 8,
      }
      msAllApi.MienTypeList(param).then(res => {
        this.dataList10 = res.data.data.data
      })
    },

    // 10四名风采
    getList11(search_host_type = null) {
      let param = {
        page: 1,
        limit: 10,
        search_stage_id: "",
        search_subject_id: "",
        search_name: "",
        provinces_cities_counties: "",
        all_web_id: 2,
        is_homepage: 2,
        search_host_type: 1
      }
      if (search_host_type !== null) {
        param.search_host_type = search_host_type
      }
      msAllApi.TeachingList(param).then(res => {
        this.dataList11 = res.data.data.data
      })
    },

    changeActive1(index) {
      this.active1 = index
    },

    // 12合作媒体
    getList12() {
      let param = {
        limit: 10,
      }
      msAllApi.partnerLists(param).then(res => {
        this.dataList12 = res.data.data.data
      })
    },

    // 13优质原创内容
    getList13() {
      let param = {
        get_num: 8,
      }
      msAllApi.gzsWeChatArticleList(param).then(res => {
        this.dataList13 = res.data.data
      })
      let param2 = {
        get_num: 4,
      }
      msAllApi.gzsgoodList(param2).then(res => {
        this.dataList13_2 = res.data.data
      })
    },
    // 四名公益行
    getPublicWelfareList() {
      let param = {
        show_type: 1,
        type1: 4,
        limit: 8,
      }
      msAllApi.activityList(param).then(res => {
        this.publicWelfareList = res.data.data.data
      })
    },
    // 活动预告
    getAdvanceList() {
      let param = {
        limit: 2,
      }
      msAllApi.AdvanceList(param).then(res => {
        this.advanceList = res.data.data.data

        const coverList = [
          {
            imgUrl: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/2024-05-09/8bf16015-363b-43b5-a483-da330ae75f5a.png",
            className: "activity-preview-1",
          },
          {
            imgUrl: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/2024-05-09/5f34582a-b7fe-464f-ad22-ae9fd8ea401b.png",
            className: "activity-preview-2",
          },
          {
            imgUrl: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/2024-05-09/5b013c50-bd83-46c6-bb3b-4abf6d2c71fd.png",
            className: "activity-preview-3",
          },
          {
            imgUrl: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/2024-05-09/47943d92-4bab-4614-8084-6f87c443a82b.png",
            className: "activity-preview-4",
          },
        ];
        if (this.advanceList.length > 0) {
          this.advanceList.forEach((item) => {
            coverList.forEach((item1) => {
              if (item1.imgUrl == item.thumb) {
                item.className = item1.className;
              }
            });
          });
        }
      })
    },
    // ---------------------------------------
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },
    // 跳转活动列表
    goMsSctiList() {
      this.$router.push({
        name: "msSctiList",
      })
    },

    // 跳转直播详情
    goMsLiveDetails(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id }
      })
    },
    // 跳转直播列表
    goMsLiveList() {
      this.$router.push({
        name: "MsLiveList",
      })
    },

    // 跳转公益行列表
    goMsAdvanceList() {
      this.$router.push({
        name: "MsAdvanceList",
      })
    },

    // 四名风采详情
    goMsElegDetails(id) {
      this.$router.push({
        name: "MsElegDetails",
        query: { id: id }
      })
    },
    // 跳转风采列表
    goMsElegant() {
      this.$router.push({
        name: "MsElegant",
      })
    },

    // 活动预告详情
    goMsAdvanceDetails(id) {
      this.$router.push({
        name: "ShenBao",
        query: { id: id }
      })
    },

    // 跳转工作室详情
    goStudioListOldList() {
      this.$router.push({
        name: "StudioListOld",
      })
    },

    // 跳转四名公益行
    goPublicWelfareList() {
      this.$router.push({
        name: "MsAdvanceList",
      })
    },

    goweChatList() {
      this.$router.push({
        name: "weChatList",
      })
    },
    goweChatDetails(item) {
      this.$router.push({
        name: "weChatDetails",
        query: { id: item.group_id }
      })
    },
    // 跳转活动预告详情
    goAdvanceDetails(item) {
      this.$router.push({
        name: "MsAdvanceDetails",
        query: { id: item.id }
      })
    },
    // 查看详情
    go_urls(url, item) {
      let link_src = "";
      if (item && item.teaching_studio_id) {
        link_src = `&teaching_studio_id=${item.teaching_studio_id}`;
      };
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + url)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}${link_src}`)
      } else {
        window.open(`${url}${link_src}`);
      }
    },

    goNewUrl(url) {
      //window.open(url, "_blank");
    },
    changeActive(index) {
      this.activeIndex = index
    },
    changeBtn(val) {
      if (val == 1 && this.showArr[0] > 0) {
        this.activeIndex = this.activeIndex - 1
        this.showArr = [this.showArr[0] - 1, this.showArr[1] - 1]
      }
      if (val == 2 && this.showArr[1] <= this.dataList11.length) {
        this.activeIndex = this.activeIndex + 1
        this.showArr = [this.showArr[0] + 1, this.showArr[1] + 1]
      }
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
    studioFilterChange(val) {
      this.studioFilterIndex = val;
      this.getList11(this.studioFilterList[val].value);
    },
  },

  mounted() {
  },
  filters: {
    provincesCitiesCounties(type) {
      let name = '暂无'
      if (type == 1) {
        name = '省级'
      }
      if (type == 2) {
        name = '市级'
      }
      if (type == 3) {
        name = '县级'
      }
      return name
    }
  }
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: left;
}

.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.foterstr {
  width: 100%;
  height: 90px;
  background-image: url("~@/assets/images/myImg/home_footer_bg.png");
  background-size: 100% 100%;
  z-index: 164;
}

// 内容样式开始
.public2box {
  display: flex;
  justify-content: space-between;

  .publicLeft {
    width: 956px;

    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .titleLeft {
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .titleRight {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #667280;
          line-height: 29px;
        }
      }
    }

    .public-box-1 {
      width: 956px;
      height: 571px;
      padding: 24px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;

      .leftMain1 {
        width: 100%;
        height: 283px;
        box-sizing: border-box;

        .mainCenter {
          display: flex;
          justify-content: space-between;

          .main1Left {
            width: 330px;
            height: 283px;
            border-radius: 8px;

            .mtitle {
              margin-bottom: 14px;
              height: 24px;

              p {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #222427;
              }
            }

            .imgBox {
              width: 330px;
              height: 186px;
              border-radius: 8px 8px 0 0;
              position: relative;

              img {
                border-radius: 8px 8px 0 0;
              }

              .abdian {
                width: 100%;
                position: absolute;
                bottom: 12px;
                left: 0;

                .diandf {
                  width: 100%;
                  display: flex;
                  justify-content: center;

                  .dian {
                    cursor: pointer;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    background: #84b8fb;
                    width: 6px;
                    height: 6px;
                    margin: 0 5px;

                    &:hover {
                      background: #3489FF;
                    }
                  }

                  .activeDian {
                    background: #3489FF;
                  }
                }

              }
            }

            .txtBox {
              padding: 18px 16px;
              box-sizing: border-box;
              width: 330px;
              height: 97px;
              border-radius: 0 0 8px 8px;
              background: rgba(101, 205, 138, 1);

              p {
                color: #ffffff;

                &:first-child {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                }

                &:last-child {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                }
              }
            }
          }

          .main1Right {
            width: 545px;
            height: 283px;
            border-radius: 8px;

            .mtitle {
              margin-bottom: 14px;
              height: 24px;

              p {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #222427;
              }
            }

            .slrightTop {
              justify-content: space-between;

              &:hover {
                .txtbox {
                  h4 {
                    color: #3489FF;
                  }
                }
              }

              .imgBox {
                width: 168px;
                height: 95px;

                img {
                  border-radius: 4px;
                  width: 100%;
                  height: 100%;
                }
              }

              .txtbox {
                width: 360px;

                h4 {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #2a3037;
                }

                p {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                  color: #666666;
                }
              }
            }
          }
        }
      }

      .leftMain2 {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;

        >div {
          width: 100%;

          .mtitle {
            margin-bottom: 12px;

            p {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #222427;
            }
          }

          .main2Center {
            .eventTrailer-list {
              display: flex;
              justify-content: space-between;

              .eventTrailer-list-item {
                width: 442px;
                border-radius: 8px;
                overflow: hidden;
                cursor: pointer;

                .item-img {
                  width: 100%;
                  height: 250px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .item-info {
                  width: 100%;
                  height: 88px;
                  padding: 20px;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
                  background: rgba(52, 137, 255, 1);

                  .info-text {
                    flex: 1;
                    overflow: hidden;
                    margin-right: 5px;

                    p {
                      font-size: 16px;
                      color: rgba(255, 255, 255, 1);
                      line-height: 25px;
                    }
                  }

                  .info-button {
                    width: 106px;
                    height: 38px;
                    background: rgba(255, 255, 255, 1);
                    border-radius: 19px;
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba(255, 122, 41, 1);
                    text-align: center;
                    line-height: 38px;
                  }
                }
              }
            }
          }

          .activity-preview {
            width: 100%;
            height: 186px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            .activity-title {
              width: 70%;
              font-size: 26px;
              font-weight: bold;
              line-height: 26px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 40px 0 16px 65px;
            }

            .activity-address {
              width: 70%;
              font-size: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0 0 16px 65px;
            }

            .activity-date {
              width: 250px;
              height: 32px;
              background: rgba(240,148,29,1);
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              line-height: 32px;
              margin-left: 65px;
            }

            .activity-button {
              width: 138px;
              height: 44px;
              background: rgba(255,255,255,1);
              border-radius: 22px;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              line-height: 44px;
              cursor: pointer;
              position: absolute;
              right: 58px;
              top: 70px;
            }
          }

          .activity-preview-1 {
            background: url("~@/assets/yugao/yugao_bg1.png") no-repeat center;

            .activity-title {
              color: #009DA7;
            }

            .activity-address {
              color: #009DA7;
            }

            .activity-date {
              background: #009DA7;
              color: #ffffff;
            }

            .activity-button {
              background-image: linear-gradient(to bottom, #ffffff 25%, #CBEBFE 100%);
              box-shadow:  2px 2px 13px 0 rgba(70,196,211,0.43);
              color: #009DA7;
            }
          }

          .activity-preview-2 {
            background: url("~@/assets/yugao/yugao_bg2.png") no-repeat center;

            .activity-title {
              color: #025FBC;
            }

            .activity-address {
              color: #025FBC;
            }

            .activity-date {
              background: #F0941D;
              color: #ffffff;
            }

            .activity-button {
              background-image: linear-gradient(to bottom, #ffffff 25%, #CBEBFE 100%);
              box-shadow:  2px 2px 13px 0 rgba(26,129,240,0.43);
              color: #3489FF;
            }
          }

          .activity-preview-3 {
            background: url("~@/assets/yugao/yugao_bg3.png") no-repeat center;

            .activity-title {
              color: #FFFFFF;
            }

            .activity-address {
              color: #FFFFFF;
            }

            .activity-date {
              background: #F0941D;
              color: #ffffff;
            }

            .activity-button {
              background-image: linear-gradient(to bottom, #ffffff 25%, #FFD8DB 100%);
              box-shadow:  2px 2px 13px 0 rgba(236,169,147,0.43);
              color: #914FA4;
            }
          }

          .activity-preview-4 {
            background: url("~@/assets/yugao/yugao_bg4.png") no-repeat center;

            .activity-title {
              color: #FFFFFF;
            }

            .activity-address {
              color: #FFFFFF;
            }

            .activity-date {
              background: #CF4F94;
              color: #ffffff;
            }

            .activity-button {
              background: #0DD6C8;
              color: #FFFFFF;
              border: 1px solid #FFFFFF;
            }
          }
        }
      }
    }
  }

  .publicRight {
    width: 308px;

    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .titleLeft {
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .titleRight {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: normal;
          color: #667280;
          line-height: 29px;
        }
      }
    }

    .rightMain1 {
      width: 308px;
      height: 571px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;
      overflow: hidden;

      >div {
        width: 292px;
        height: 132px;
        background: rgba(238, 246, 255, 1);
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 16px 7px;
        box-sizing: border-box;

        .rightTop {
          .imgBox {
            width: 111px;
            height: 62px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .status {
              width: 52px;
              height: 20px;
              border-radius: 0 0 8px 0;
              font-size: 12px;
              color: #fff;
              text-align: center;
              line-height: 20px;
              position: absolute;
              left: 0;
              top: 0;
            }

            .bg1 {
              background: #409eff;
            }

            .bg2 {
              background: #51B875;
            }

            .bg3 {
              background: #ff7a29;
            }

            .bg4 {
              background: #999;
            }
          }

          .txtbox {
            width: 155px;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
            }
          }
        }

        .rightBom {
          .bom1 {
            .p-1 {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ff7a29;
              margin-top: 5px;
            }

            .p-2 {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
              display: none;
            }
          }

          .bom2 {

            div {
              width: 70px;
              height: 28px;
              line-height: 28px;
              background: rgba(52, 137, 255, 1);
              border-radius: 14px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #ffffff;
              text-align: center;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .rightMain2 {
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 9px;
      box-sizing: border-box;
      margin-bottom: 16px;

      .mtitle {
        margin-bottom: 14px;

        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #222427;
        }
      }

      .main2Img {
        width: 287px;
        height: 164px;
        background: linear-gradient(-45deg, rgba(180, 229, 250, 1) 0%, rgba(215, 239, 250, 1) 100%);
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .abdian {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.3);
          font-size: 18px;
          color: #fff;
          font-weight: bold;
          padding: 0 30px;
          box-sizing: border-box;
        }
      }
    }

    .rightMain3 {
      width: 308px;
      height: 721px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;

      >div {
        width: 292px;
        height: 134px;
        background: rgba(238, 246, 255, 1);
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 16px 7px;
        box-sizing: border-box;

        h4 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2a3037;
        }

        .rightTop {
          .imgBox {
            width: 111px;
            height: 62px;

            img {
              border-radius: 4px;
            }
          }

          .txtbox {
            width: 155px;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.public1box {
  width: 100%;
  height: 562px;
  background-image: url("~@/assets/banner_img/fengCai.png");
  background-size: 100% 100%;
  padding: 24px;
  box-sizing: border-box;

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #2A3037;
        line-height: 29px;
      }
    }
  }

  .public1main {
    width: 1232px;
    height: 467px;
    background: linear-gradient(0deg, rgba(240, 247, 253, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 8px;
    padding: 30px;
    box-sizing: border-box;


    >div {
      flex-wrap: wrap;

      >div {
        width: 553px;
        height: 76px;
        border-radius: 8px;
        margin-right: 66px;
        box-sizing: border-box;
        margin-bottom: 34px;

        &:hover {
          .txtbox {
            h4 {
              color: #3489FF;
            }
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .imgBox {
          width: 142px;
          height: 76px;
          border-radius: 8px;

          img {
            border-radius: 8px;
          }
        }

        .txtbox {
          width: 392px;
          padding-right: 20px;
          padding: 5px 0;

          h4 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #222427;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: #666666;
          }
        }
      }
    }
  }

}

.public3box {
  width: 100%;
  height: 378px;
  box-sizing: border-box;
  position: relative;

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      display: flex;
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }

      .filter-list {
        display: flex;
        align-items: flex-end;
        margin-left: 48px;

        .filter-item {
          font-size: 16px;
          color: #333333;
          border-bottom: 2px solid transparent;
          padding-bottom: 2px;
          cursor: pointer;
          margin-right: 32px;
        }

        .filter-item-active {
          color: #3489FF;
          font-weight: bold;
          border-color: #3489FF;
        }
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }


  .public3main {
    position: relative;

    .btn {
      position: absolute;
      width: 24px;
      height: 56px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(196, 207, 232, 1);
      border-radius: 4px;
      text-align: center;
      line-height: 56px;
      cursor: pointer;

      &:hover {
        background: #3489FF;
        color: #fff;
      }

      &.leftBtn {
        bottom: 137px;
        left: -47px;
      }

      &.rightBtn {
        bottom: 137px;
        right: -47px;
      }
    }

    .main3Ul {
      overflow: hidden;

      .accordion-item {
        margin-right: 15px;

        .bigBox,
        .bigBox-1,
        .bigBox-2,
        .bigBox-3 {
          width: 418px;
          height: 330px;
          position: relative;
          background-image: url("~@/assets/images/myImg/gzstjq_bg_1.png");
          background-color: #fff;
          background-size: 100% 100%;
          border-radius: 8px;
          overflow: hidden;
          transition: width 0.1s linear;

          .topBox {
            padding-top: 40px;
            padding: 25px 30px 0 40px;

            .imgBox {
              width: 120px;
              height: 120px;
              border-radius: 8px;
              overflow: hidden;
              background: #eef2fb;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .txtBox {
              padding: 0 10px;
              position: relative;
              padding-left: 17px;
              padding-top: 10px;

              .txtBox-title {
                font-size: 18px;
                font-weight: bold;
                color: rgb(34,36,39);
              }

              .txtBox-label {
                width: 160px;
                display: flex;
                align-items: center;
                margin-top: 12px;

                span {
                  &:nth-of-type(1) {
                    width: 92px;
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(255, 255, 255, 1);
                    padding: 0 10px;
                    box-sizing: border-box;
                  }

                  &:nth-of-type(2) {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgb(43,202,99);
                  }
                }
              }

              .txtBox-text {
                font-size: 14px;
                color: rgb(102,102,102);
                margin-top: 30px;
              }

            }
          }

          .statistics-block {
            width: 340px;
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
            margin: auto;
            .statistics-block-item {
              font-size: 14px;
              color: rgba(51,51,51,1);
              span {
                font-size: 20px;
                margin-right: 4px;
              }
            }
          }

          .bomBox {
            padding: 0 40px;

            p {
              font-size: 14px;
              color: rgb(102,102,102);
              line-height: 24px;
            }
          }

          .look {
            font-size: 14px;
            color: rgb(52,137,255);
            position: absolute;
            left: 40px;
            bottom: 25px;
          }

          .label {
            width: 42px;
            height: 32px;
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 32px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .bigBox-1 {
          background-image: url("~@/assets/images/myImg/gzstjq_bg_1.png");
        }

        .bigBox-2 {
          background-image: url("~@/assets/images/myImg/gzstjq_bg_2.png");
        }

        .bigBox-3 {
          background-image: url("~@/assets/images/myImg/gzstjq_bg_3.png");
        }

        .minBox {
          width: 200px;
          height: 330px;
          background: #57A8EF;
          border-radius: 8px;
          border: 1px solid #fff;
          box-sizing: border-box;
          overflow: hidden;
          position: relative;
          transition: width 0.1s linear;

          .label {
            width: 42px;
            height: 32px;
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 32px;
            background: linear-gradient(-36deg, rgba(44, 127, 242, 1) 0%, rgba(52, 157, 255, 1) 100%);
            border-radius: 0 0 0 8px;
            position: absolute;
            right: 0;
            top: 0;
          }

          .label-1 {
            background: linear-gradient(-36deg, rgba(44, 127, 242, 1) 0%, rgba(52, 157, 255, 1) 100%);
          }

          .label-2 {
            background: linear-gradient(-36deg, rgba(72, 177, 109, 1) 0%, rgba(84, 189, 96, 1) 100%);
          }

          .label-3 {
            background: linear-gradient(-36deg, rgba(64, 171, 167, 1) 0%, rgba(90, 206, 201, 1) 100%);
          }

          .imgBox {
            width: 100%;
            height: 200px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .txtBox {
            width: 100%;
            height: 130px;
            padding: 11px 15px 10px 22px;
            box-sizing: border-box;
            background: url("~@/assets/images/myImg/gzstj_bg.png");
            background-size: cover;

            .txtBox-title {
              font-size: 18px;
              font-weight: bold;
              color: rgb(34,36,39);
            }

            .txtBox-label {
              width: 164px;
              display: flex;
              align-items: center;
              margin-top: 12px;

              span {
                &:nth-of-type(1) {
                  width: 100px;
                  font-size: 14px;
                  font-weight: bold;
                  color: rgba(255, 255, 255, 1);
                  padding: 0 10px;
                  box-sizing: border-box;
                }

                &:nth-of-type(2) {
                  font-size: 14px;
                  font-weight: normal;
                  color: rgb(43,202,99);
                }
              }
            }

            .txtBox-text {
              font-size: 14px;
              color: rgb(102,102,102);
              margin-top: 22px;
            }
          }

        }

        .shengBig {
          background-image: url("~@/assets/banner_img/shengBig.png");
          background-size: 100% 100%;
        }

        .shengMin {
          background-image: url("~@/assets/banner_img/shengMin.png");
          background-size: 100% 100%;
        }

        .shiMin {
          background-image: url("~@/assets/banner_img/shiMin.png");
          background-size: 100% 100%;
        }

        .shiMin {
          background-image: url("~@/assets/banner_img/quMin.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}

.public4box {
  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }

  .public4main {
    background: #fff;
    padding: 8px;
    border-radius: 8px;

    .leftMain {
      width: 948px;
      height: 388px;
      background: rgba(238, 246, 255, 1);
      border-radius: 8px;
      flex-wrap: wrap;
      padding: 16px 9px;
      box-sizing: border-box;

      >div {
        >div {
          width: 440px;
          height: 76px;
          border-radius: 8px;
          margin-right: 50px;
          box-sizing: border-box;
          margin-bottom: 17px;

          &:hover {
            .txtbox {
              h4 {
                color: #3489FF;
              }
            }
          }

          &:nth-child(2n) {
            margin-right: 0;
          }

          .imgBox {
            width: 142px;
            height: 76px;
            border-radius: 8px;

            img {
              border-radius: 8px;
            }
          }

          .txtbox {
            width: 280px;
            padding-right: 20px;
            padding: 5px 0;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #222427;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #666666;
            }

            .p2 {
              color: #677C9B;
            }
          }
        }
      }
    }

    .rightMain {
      width: 308px;
      height: 388px;
      background: rgba(238, 246, 255, 1);
      border-radius: 8px;
      padding: 20px 18px;
      box-sizing: border-box;

      >div {
        width: 272px;
        height: 76px;
        border-radius: 8px;
        box-sizing: border-box;
        margin-bottom: 17px;

        &:hover {
          .txtbox {
            h4 {
              color: #3489FF;
            }
          }
        }

        &:nth-child(4n) {
          margin-bottom: 0;
        }

        .imgBox {
          width: 50px;
          height: 50px;

          img {
            border-radius: 50%;
          }
        }

        .txtbox {
          width: 210px;
          padding-right: 20px;
          padding: 5px 0;

          h4 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #222427;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: #666666;
          }

          .p2 {
            color: #677C9B;
          }
        }
      }
    }
  }
}

.public5box {

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }

  .public5main {
    width: 1280px;
    max-height: 435px;
    background: linear-gradient(0deg, rgba(200, 228, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 8px;
    position: relative;
    padding: 55px;
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-bottom: 24px;

    >div {
      width: 197px;
      margin-right: 46px;
      margin-bottom: 30px;

      &:hover {
        p {
          color: #3489FF;
        }
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      .imgBox {
        width: 197px;
        height: 112px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 197px;
          max-height: 112px;
          border-radius: 8px;
        }
      }
    }
  }
}

.serve-module {
  width: 1280px;
  height: 666px;
  padding: 88px 62px 57px 62px;
  box-sizing: border-box;
  background: url("~@/assets/images/myImg/serve_bg.png");
  border-radius: 8px;
  position: relative;
  margin-bottom: -50px;

  .serve-module-title {
    font-size: 36px;
    font-weight: bold;
    color: rgba(34,36,39,1);
    line-height: 36px;
    margin-bottom: 29px;
  }

  .serve-module-text {
    width: 226px;
    height: 38px;
    font-size: 18px;
    font-weight: normal;
    color: rgba(255,255,255,1);
    line-height: 38px;
    text-align: center;
    background: url("~@/assets/images/myImg/wenzi_bg.png");
  }

  .serve-list {
    display: flex;
    flex-wrap: wrap;

    .serve-list-item {
      width: 288px;
      height: 218px;
      padding: 0 18px;
      box-sizing: border-box;
      background: rgba(52,137,255,0.95);

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7) {
        background: rgba(46,126,237,0.95);

        .item-title {
          &::before {
            //background: rgba(255, 255, 255, 1);
          }
        }
      }

      .item-title {
        font-size: 24px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        margin: 32px 0;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 26px;
          height: 2px;
          background: rgba(43, 202, 99, 1);
          transform: translateX(-50%);
          position: absolute;
          left: 50%;
          bottom: -15px;
        }
      }

      .item-text {
        font-size: 16px;
        font-weight: normal;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        line-height: 16px;
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.publicWelfarebox {
  width: 100%;
  height: 620px;
  box-sizing: border-box;
  position: relative;

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }


  .public3main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    >div {
      width: 308px;
      height: 278px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      margin-right: 16px;
      margin-bottom: 16px;

      &:hover {
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      .imgBox {
        width: 284px;
        height: 160px;
        border-radius: 8px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .txtBox {
        padding: 0 16px 5px 16px;
        box-sizing: border-box;
        width: 100%;

        h4 {
          font-size: 16px;
          font-weight: bold;
          color: #2a3037;
        }

        p {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
}

.live-list-box {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;

  .titleBox {
    padding: 10px 8px 4px 8px;
    align-items: center !important;
    margin-bottom: 0 !important;

    .titleLeft {
      p {
        font-size: 18px !important;
        color: #2A3037 !important;
      }
    }
  }

  .rightMain1 {
    height: 328px !important;

    >div {
      height: 150px !important;

      .p-2 {
        display: block !important;
      }

      .rightBom {

        .bom1 {
          .p-1 {
            margin: 0 0 5px 0 !important;
          }
        }

        .bom2 {
          
          div {
            margin-top: 7px !important;
          }
        }
      }
    }
  }
}
</style>