<template>
  <div class="CourseAdd_div">

    <div class="form_box_div">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-position="right" label-width="150px"
        style="width: 100%; margin-left: 0px;">

        <el-form-item label="课程视频 :" prop="timeFn3">
          <dc-upload v-if="!resource_video_data.length && isUpOk" class="upload-demo" ref="upload" :dcAutoUpload="true"
            :limit="1" dcdom="ceshi01" :dcConfig="dcConfig" @dc-success="dcFile" accept=".mp4,.MP4">
            <el-button slot="trigger" size="small" type="primary">选取视频文件</el-button>
            <div slot="tip" class="el-upload__tip">
              <span style="color: red">*</span>
              <span>请上传mp4格式视频，限制1个</span>
            </div>
          </dc-upload>
          <div class="videoBox" v-if="resource_video_data.length">
            <div class="forItemBox" v-for="(item, index) in resource_video_data" :key="index">
              <div class="one ellipsis_1"> {{ item.resource_name }} </div>
              <div class="two"> <span @click="clearVideo()" v-if="!form.id">删除</span> </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="课程名称 :" prop="name">
          <el-input v-model="form.name" style="width: 600px" :maxlength="50" placeholder="请输入课程名称"></el-input>
        </el-form-item>

        <el-form-item label="课程标签 :" prop="label">
          <el-input v-model="form.label" style="width: 600px" placeholder="请输入标签"></el-input>
        </el-form-item>

        <el-form-item label="课程封面 :" prop="cover_id">
          <div class="active_selected_box">
            <div class="active_selected_img">
              <img v-if="form.cover_id" style="width: 100%; height: 100%;" :src="form.cover_url" alt="">
            </div>
            <div class="selection_img_list">
              <div class="selection_img_item" v-for="(item_1, idnex_1) in selectPhoto" :key="idnex_1"
                @click="selectOnePhoto(item_1)">
                <img class="selection_img_img" :src="item_1.photo_url" alt="">
                <div v-if="item_1.id == form.cover_id" class="selection_img_mask"><i
                    class="el-icon-check selection_img_icon"></i>
                </div>
              </div>
            </div>
            <div class="active_selected_uploading">
              <dc-upload ref="activityUpload" :dcAutoUpload="true" :show-file-list="false" dcdom="activityUpload"
                :dcConfig="dcConfig" @dc-success="dcSuccess"
                accept=".jpg,.png,.swf,.bmp,.jpeg,.JPG,.PNG,.SWF,.BMP,.JPEG" v-if="isUpOk">
                <div class="upload_img_box">
                  <i class="el-icon-plus upload_icon_div"></i>
                </div>
              </dc-upload>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="课程分类 :" prop="type">
          <div class="list_type_div">
            <el-radio-group v-model="form.type" @change="handleSelectType(1)">
              <el-radio-button v-for="(item_1, index_1) in typeList" :key="index_1" :label="item_1.id">
                {{ item_1.name }}</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item label="学段学科 :" required>
          <div class="df">
            <el-form-item label="" prop="stage_id">
              <el-select v-model="form.stage_id" placeholder="请选择学段" @change="changeEvent_1">
                <el-option v-for="item in option_list_data_1" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="subject_id" class="ml10">
              <el-select v-model="form.subject_id" placeholder="请选择学科" no-data-text="请先选择学段/暂无数据"
                @change="changeEvent_2">
                <el-option v-for="item in option_list_data_2" :key="item.subjectId" :label="item.subjectName"
                  :value="item.subjectId">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="教材信息 :">
          <div class="df">
            <el-form-item label="" prop="">
              <el-select v-model="form.grade_id" placeholder="请选择教材版本" no-data-text="请先选择学科/暂无数据"
                @change="changeEvent_3">
                <el-option v-for="item in option_list_data_3" :key="item.gradeId" :label="item.gradeName"
                  :value="item.gradeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="" class="ml10">
              <el-select v-model="form.version_id" placeholder="请选择册别" no-data-text="请先选择年级/暂无数据"
                @change="changeEvent_4">
                <el-option v-for="item in option_list_data_4" :key="item.Id" :label="item.Name" :value="item.Id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="章节信息: " prop="">
          <el-select v-model="form.chapter_id" placeholder="请选择章节" no-data-text="请先选择版本/暂无数据">
            <el-option v-for="item in option_list_data_5" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 上课 说课 讲座 其他 -->
        <template v-if="[1, 2, 3, 6].includes(Number(form.type))">
          <el-form-item label="课程介绍 :" prop="presentation">
            <Editor v-model="form.presentation"></Editor>
          </el-form-item>



          <el-form-item label="课程资源 :" prop="timeFn4">
            <div class="df">
              <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource>
              <span class="ml20 fz16" style="color: rgba(154, 158, 165, 1)">添加资源后，在上课过程中可查看添加的资源文件。</span>
            </div>
            <div class="listBOx">
              <div class="listItem" v-for="(item, index) in resource_class_data" :key="index">
                <!-- 左边 -->
                <div class="Item-left df">
                  <p class="ellipsis">{{ item.resource_name }}</p>
                  <span class="hongse ml20 cp" @click="deteleOne(index)">删除</span>
                </div>
                <div class="Item-right">
                  <el-checkbox v-for="items in downData" :key="items.id" v-model="item.is_download"
                    :checked="item.is_download == 1 ? true : false">{{ items.name }}</el-checkbox>
                  <!-- <el-radio-group v-model="item.is_download">
                    <el-radio v-for="items in downData" :key="items.id" :label="items.id">{{ items.name }}</el-radio>
                  </el-radio-group> -->
                </div>
              </div>
            </div>
          </el-form-item>
        </template>

        <!-- 听评课 -->
        <template v-if="[4].includes(Number(form.type))">
          <el-form-item label="授课老师 :" prop="teacher">
            <el-input v-model="form.teacher" style="width: 600px" placeholder="请输入授课老师"></el-input>
          </el-form-item>
          <el-form-item label="授课说明 :" prop="presentation">
            <Editor v-model="form.presentation"></Editor>
          </el-form-item>
          <el-form-item label="授课形式 :" prop="teaching_form">
            <el-radio-group v-model="form.teaching_form" @change="handleSelectType">
              <el-radio v-for="item in radioList1" :key="item.id" :value="item.id" :label="item.id"> {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- //线下 -->
          <div v-if="form.teaching_form == 1">
            <el-form-item label="授课时间 :" prop="timeFn5">
              <el-date-picker v-model="form.start_date" type="date" placeholder="选择开始日期" format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd" style="width: 200px; margin-right: 5px">
              </el-date-picker>
              <el-time-picker type="fixed-time" placeholder="选择开始时间" v-model="form.start_time" value-format="HH:mm"
                format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
              <span>-</span>
              <el-date-picker v-model="form.end_date" type="date" placeholder="选择结束日期" format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd" style="width: 200px; margin-right: 5px; margin-left: 5px">
              </el-date-picker>
              <el-time-picker type="fixed-time" placeholder="选择结束时间" v-model="form.end_time" value-format="HH:mm"
                format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
            </el-form-item>
            <el-form-item label="授课地点 :" prop="course_address">
              <el-input v-model="form.course_address" style="width: 600px" placeholder="请输入授课地点"></el-input>
            </el-form-item>
          </div>
          <!-- 线上 -->
          <div v-if="form.teaching_form == 2">
            <el-form-item label="授课视频 :" prop="timeFn3">
              <dc-upload v-if="!resource_video_data.length && isUpOk" class="upload-demo" ref="upload"
                :dcAutoUpload="true" :limit="1" dcdom="ceshi01" :dcConfig="dcConfig" @dc-success="dcFile"
                accept=".mp4,.MP4">
                <el-button slot="trigger" size="small" type="primary">选取视频文件</el-button>
                <div slot="tip" class="el-upload__tip">
                  <span style="color: red">*</span>
                  <span>请上传mp4格式视频，限制1个</span>
                </div>
              </dc-upload>
              <div class="videoBox" v-if="resource_video_data.length">
                <div class="forItemBox" v-for="(item, index) in resource_video_data" :key="index">
                  <div class="one ellipsis_1"> {{ item.resource_name }} </div>
                  <div class="two"> <span @click="clearVideo()">删除</span> </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <el-form-item label="评课时间 :" prop="timeFn6">
            <el-date-picker v-model="form.lesson_start_date" type="date" placeholder="选择开始日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd" style="width: 200px; margin-right: 5px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="选择开始时间" v-model="form.lesson_start_time" value-format="HH:mm"
              format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
            <span>-</span>
            <el-date-picker v-model="form.lesson_end_date" type="date" placeholder="选择结束日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd" style="width: 200px; margin-right: 5px; margin-left: 5px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="选择结束时间" v-model="form.lesson_end_time" value-format="HH:mm"
              format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
          </el-form-item>

          <el-form-item label="课程资源 :" prop="timeFn4">
            <div class="df">
              <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource>
              <span class="ml20 fz16" style="color: rgba(154, 158, 165, 1)">添加资源后，在上课过程中可查看添加的资源文件。</span>
            </div>
            <div class="listBOx">
              <div class="listItem" v-for="(item, index) in resource_class_data" :key="index">
                <!-- 左边 -->
                <div class="Item-left df">
                  <p class="ellipsis">{{ item.resource_name }}</p>
                  <span class="hongse ml20 cp" @click="deteleOne(index)">删除</span>
                </div>
                <div class="Item-right">
                  <el-checkbox v-for="items in downData" :key="items.id" v-model="item.is_download"
                    :checked="item.is_download == 1 ? true : false">{{ items.name }}</el-checkbox>
                  <!-- <el-radio-group v-model="item.is_download">
                    <el-radio v-for="items in downData" :key="items.id" :label="items.id">{{ items.name }}</el-radio>
                  </el-radio-group> -->
                </div>
              </div>
            </div>
          </el-form-item>
        </template>

        <!-- 集中备课 -->
        <template v-if="[5].includes(Number(form.type))">
          <el-form-item label="课程概述 :" prop="presentation">
            <Editor v-model="form.presentation"></Editor>
          </el-form-item>

          <el-form-item label="备课时间 :" prop="timeFn5">
            <el-date-picker v-model="form.start_date" type="date" placeholder="选择开始日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd" style="width: 200px; margin-right: 5px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="选择开始时间" v-model="form.start_time" value-format="HH:mm"
              format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
            <span>-</span>
            <el-date-picker v-model="form.end_date" type="date" placeholder="选择结束日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd" style="width: 200px; margin-right: 5px; margin-left: 5px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="选择结束时间" v-model="form.end_time" value-format="HH:mm"
              format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
          </el-form-item>

          <el-form-item v-if="false" label="作者署名 :" prop="timeFn8">
            <el-radio-group v-model="form.sign_type">
              <el-radio v-for="item in radioList2" :key="item.id" :value="item.id" :label="item.id">
                {{ item.name }}
                <i class="el-icon-question" :title="item.tip"></i>
              </el-radio>
            </el-radio-group>
            <el-input v-if="form.sign_type == 4" v-model="form.sign_text" placeholder="请输入内容"
              style="width: 200px; margin-left: 20px"></el-input>
            <div v-if="form.sign_type == 2" class="jyz_box">
              <el-select style="width: 260px;" v-model="form.sign_group_id" filterable placeholder="请选择教研组">
                <el-option v-for="item in JyGronpName" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
          </el-form-item>
        </template>

        <el-form-item label="发起人2选1:" prop="timeFn9" v-if="false">
          <el-select v-if="!form.teaching_studio_id" v-model="form.group_id" clearable placeholder="请选择教研组"
            no-data-text="暂无数据" style="margin-right: 20px" @change="changeEvent_6" :disabled="!!dialogStatus">
            <el-option v-for="item in selectArrList_1" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
          <el-select v-if="!form.group_id" v-model="form.teaching_studio_id" clearable placeholder="请选择工作室"
            no-data-text="暂无数据" @change="changeEvent_7" :disabled="!!dialogStatus">
            <el-option v-for="item in selectArrList_2" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="[4].includes(Number(form.type)) && (form.group_id || form.teaching_studio_id)"
          label="允许谁评课 :" prop="timeFn7">
          <el-button type="primary" @click="selectTearm()">选择成员</el-button>
          <span style="margin-left: 15px; cursor: pointer">已选<span style="color: red">{{ form.permiss_user_ids.length
              }}</span> 人>></span>
        </el-form-item>

        <el-form-item v-if="[5].includes(Number(form.type)) && (form.group_id || form.teaching_studio_id)"
          label="允许谁参与 :" prop="timeFn7">
          <el-button type="primary" @click="selectTearm()">选择成员</el-button>
          <span style="margin-left: 15px; cursor: pointer">已选<span style="color: red">{{ form.permiss_user_ids.length
              }}</span> 人>></span>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button class="width148" @click="toBack0()">取 消</el-button>
          <el-button class="width148" type="primary" style="background: rgba(255, 130, 1, 1); border: none"
            @click="saveCourseEvent" :loading="save_loading_btn">保 存
            <span class="temp_class_span_div">-661</span>
          </el-button>
        </el-form-item>

      </el-form>
    </div>

    <!-- <AppointUser :isOk="isOk" @closeTable="isOk=false" @cancleSelect="cancleSelect" :outer_studioId="this.outer_studioId"
      :getLiveMember="deepClone(form.permiss_user_ids.toString())">
    </AppointUser> -->

    <UserSelect2Table v-if="isOk" :isShowTable="isOk" :tearmCheckedList="form.permiss_user_ids"
      :group_id="form.group_id" :teaching_studio_id="form.teaching_studio_id" :is_personid="is_personid"
      @confirmEvent="confirmEvent">
    </UserSelect2Table>
  </div>
</template>
<script>
import { deepClone } from "@/utils/utils.js";
import { debounce, throttle } from "@/utils/utils.js";
import UserSelect2Table from '@/views/teachingGroup/createActivity/components/UserSelectTable/UserSelect2Table.vue';
export default {
  components: {
    UserSelect2Table
  },
  data() {
    const verifyFn_3 = (rule, value, callback) => {
      if (!this.resource_video_data || !this.resource_video_data.length) {
        callback(new Error("请选择上传视频"))
      } else {
        callback()
      }
    };
    const verifyFn_4 = (rule, value, callback) => {
      if (!this.resource_class_data.length) {
        callback(new Error("请选择上课资源"))
      } else {
        callback()
      }
    };
    const verifyFn_5 = (rule, value, callback) => {
      if (!this.form.start_date) {
        callback(new Error("选择开始日期"))
      } else if (!this.form.start_time) {
        callback(new Error("选择开始时间"))
      } else if (!this.form.end_date) {
        callback(new Error("选择结束日期"))
      } else if (!this.form.end_time) {
        callback(new Error("选择结束时间"))
      } else {
        callback()
      }
    };
    const verifyFn_6 = (rule, value, callback) => {
      if (!this.form.lesson_start_date) {
        callback(new Error("选择开始日期"))
      } else if (!this.form.lesson_start_time) {
        callback(new Error("选择开始时间"))
      } else if (!this.form.lesson_end_date) {
        callback(new Error("选择结束日期"))
      } else if (!this.form.lesson_end_time) {
        callback(new Error("选择结束时间"))
      } else {
        callback()
      }
    };
    const verifyFn_7 = (rule, value, callback) => {
      if (!this.form.permiss_user_ids.length) {
        callback(new Error("请选择成员"))
      } else {
        callback()
      }
    };
    const verifyFn_8 = (rule, value, callback) => {
      if (!this.form.sign_type) {
        callback(new Error("请选择作者署名"))
      } else if (this.form.sign_type == 4 && !this.form.sign_text) {
        callback(new Error("请输入自定义签名"))
      } else if (this.form.sign_type == 2 && !this.form.sign_group_id) {
        callback(new Error("请选择教研组"))
      } else {
        callback()
      }
    };
    const verifyFn_9 = (rule, value, callback) => {
      if (this.form.group_id || this.form.teaching_studio_id) {
        callback()
      } else {
        callback(new Error("请选择发起人"))
      }
    };
    return {
      save_loading_btn: false,
      dialogStatus: '', // 编辑 添加
      // 视频数据
      resource_video_data: [],
      // 往组件传关闭窗口变量
      isOk: false,
      selectPhoto: [
        {
          id: 5886204,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/75aa1f66-f735-433e-bcb8-59484cb2804d.png",
        },
        {
          id: 5886205,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/16b3520d-57ee-4872-a215-c43ab68e6051.png",
        },
        {
          id: 5886206,
          photo_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/f35d2f10-bd09-4818-b049-e626b52348ad.png",
        },
      ],
      radioList1: [
        { id: 1, name: "线下", },
        { id: 2, name: "线上", },
      ],
      JyGronpName: [],
      // 教研组
      JyList: {
        limit: "",
        page: "",
        my_type: "1",
      },
      radioList2: [
        {
          id: 1,
          name: "个人署名",
          tip: "个人署名：选择后，课程的作者为个人。",
        },
        {
          id: 2,
          name: "教研组署名",
          tip: "教研组署名：选择后，课程的作者为指定教研组。",
        },
        {
          id: 3,
          name: "单位署名",
          tip: "单位署名：选择后，课程的作者为本单位。",
        },
        {
          id: 4,
          name: "自定义署名",
          tip: "自定义署名：选择后，课程的作者为自定义名称。",
        },
      ],
      // 定义可查看
      downData: [
        // { id: 1, name: "仅查看", },
        { id: 2, name: "可下载", },
      ],
      //定义个数组存放资源返回
      resource_class_data: [],
      // 学段
      option_list_data_1: [],
      // 学科
      option_list_data_2: [],
      // 年级
      option_list_data_3: [],
      // 版本
      option_list_data_4: [],
      // 章节
      option_list_data_5: [],
      typeList: [
        { id: 1, name: "上课", },
        { id: 2, name: "说课", },
        { id: 3, name: "讲座", },
        // { id: 4, name: "听评课", },
        // { id: 5, name: "集中备课", },
        { id: 6, name: "其他", },
      ],
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      form: {
        name: "",
        label: "",
        cover_id: 5886204,
        cover_url: "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/75aa1f66-f735-433e-bcb8-59484cb2804d.png",
        type: 1,
        stage_id: "",
        subject_id: "",
        grade_id: "",
        version_id: "",
        chapter_id: "",
        presentation: "",
        // 上传视频的id
        video_resource_id: [],
        // 上课资源视频的文件id数组
        class_resource_ids: [],
        //对应多个资源是否可下载
        class_resource_is_down: [],
        teacher: "",
        teaching_form: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        lesson_start_date: "",
        lesson_start_time: "",
        lesson_end_date: "",
        lesson_end_time: "",
        initiator_type: "",
        group_id: "",
        teaching_studio_id: "",
        permiss_range: "",
        course_address: "",
        sign_type: "",
        sign_text: "",
        //存放指定成员和指定教研组id的
        permiss_user_ids: [],
        // 弹出框单选教研组
        sign_group_id: "",
      },
      rules: {
        cover_id: [{ required: true, message: '请选择/上传课程封面', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入教研活动名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '请输入标签', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        type: [{ required: true, message: "请选择课程分类", trigger: "change" }],
        presentation: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        teacher: [{ required: true, message: '请输入授课老师', trigger: 'blur' }],
        course_address: [{ required: true, message: '请输入授课地点', trigger: 'blur' }],
        stage_id: [{ required: true, message: "请选择学段", trigger: "change" }],
        subject_id: [{ required: true, message: "请选择学科", trigger: "change" }],
        grade_id: [{ required: true, message: "请选择年级", trigger: "change" }],
        teaching_form: [{ required: true, message: "请选择授课形式", trigger: "change" }],
        version_id: [{ required: true, message: "请选择版本", trigger: "change" }],
        chapter_id: [{ required: true, message: "请选择章节", trigger: "change" }],
        group_id: [{ required: true, message: "请选择教研组", trigger: "change" }],
        timeFn3: [{ required: true, validator: verifyFn_3, trigger: 'change' }],
        // timeFn4: [{ required: true, validator: verifyFn_4, trigger: 'change' }],
        timeFn5: [{ required: true, validator: verifyFn_5, trigger: 'change' }],
        timeFn6: [{ required: true, validator: verifyFn_6, trigger: 'change' }],
        timeFn7: [{ required: true, validator: verifyFn_7, trigger: 'change' }],
        timeFn8: [{ required: true, validator: verifyFn_8, trigger: 'change' }],
        timeFn9: [{ required: true, validator: verifyFn_9, trigger: 'change' }],
      },
      selectArrList_1: [], // 教研组
      selectArrList_2: [], // 工作室
      is_personid: "",
      outer_studioId: "",
      isUpOk: false,
    };
  },
  watch: {
    "form.presentation"() {
      this.$nextTick(() => {
        this.$refs['ruleForm'].validateField('presentation'); //单独校验
      })
    }
  },
  computed: {},
  mounted() {
    this.getListEvent_1();
    this.getListEvent_2();
    this.getList_1();
    this.getAllList();
    this._getAddress();
    if (this.$route.query.is_personid) {
      this.is_personid = Number(this.$route.query.is_personid)
    };
    if (this.$route.query.groupId) {
      this.$set(this.form, "group_id", Number(this.$route.query.groupId));
      this.dialogStatus = 'add';
    };
    if (this.$route.query.teaching_studio_id) {
      this.$set(this.form, "teaching_studio_id", Number(this.$route.query.teaching_studio_id));
      this.dialogStatus = 'add';
    };
    if (this.$route.query.id) {
      this.dialogStatus = 'update';
      this.$set(this.form, "id", this.$route.query.id);
      this.getItemInfo();
    };
  },
  methods: {
    //获取教研组
    getListEvent_1() {
      this.$axios.get("/my_ground_list").then((res) => {
        let resdata = res.data;
        this.selectArrList_1 = resdata.data;
      });
    },
    //获取工作室
    getListEvent_2() {
      this.$axios.get("/my_teachingstudio_list").then((res) => {
        let resdata = res.data;
        this.selectArrList_2 = resdata.data;
      });
    },
    //选择指定成员
    confirmEvent(isOk, list) {
      if (isOk && Array.isArray(list)) {
        this.$set(this.form, "permiss_user_ids", list);
      };
      this.isOk = false;
      this.$refs['ruleForm'].validateField('timeFn7'); //单独校验
    },
    deepClone,
    //获取 编辑信息
    async getItemInfo() {
      let res_1 = await this.$axios.get(`/course/editInfo?id=${this.form.id}&is_personid=${this.is_personid}`);
      let resData = res_1.data.data;
      this.form = Object.assign(deepClone(this.form), deepClone(resData));
      if (resData.resource_video_data) {
        this.resource_video_data = [resData.resource_video_data];
      } else {
        this.resource_video_data = [];
      };
      this.resource_class_data = resData.resource_class_data || [];
      this.getList_2();
      this.getList_3();
      this.getList_4();
      this.getList_5();
    },
    toBack0() {
      this.$router.go(-1);
    },
    //接收传过来的ids
    cancleSelect(ids, sum, closeNum) {
      if (ids) {
        let permiss_user_ids = ids.split(",");
        permiss_user_ids = permiss_user_ids.map(Number);
        this.$set(this.form, "permiss_user_ids", permiss_user_ids);
      } else {
        this.$set(this.form, "permiss_user_ids", []);
      };
      this.isOk = false;
      this.$refs['ruleForm'].validateField('timeFn7'); //单独校验
    },
    //选择成员,打开弹框
    selectTearm() {
      this.isOk = true;
    },
    //选择图片
    selectOnePhoto(item) {
      this.$set(this.form, "cover_url", item.photo_url);
      this.$set(this.form, "cover_id", item.id);
      this.$refs['ruleForm'].validateField('cover_id'); //单独校验
    },
    // 保存
    saveCourseEvent: throttle(function (formName) {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs["ruleForm"].validate(async (valid) => {
        if (!valid) {
          return false;
        };
        let reqObj = deepClone(this.form);
        if (this.resource_class_data.length) { // 资源
          let class_resource_ids = [];
          let class_resource_is_down = [];
          this.resource_class_data.forEach(item_1 => {
            class_resource_ids.push(item_1.id);
            class_resource_is_down.push(item_1.is_download == true ? 1 : 0);
          });
          reqObj['class_resource_ids'] = class_resource_ids.toString();
          reqObj['class_resource_is_down'] = class_resource_is_down.toString();
        } else {
          reqObj['class_resource_ids'] = "";
          reqObj['class_resource_is_down'] = "";
        };

        if (this.resource_video_data.length) { // 视频
          reqObj['video_resource_id'] = this.resource_video_data[0].id;
          reqObj['video_resource_name'] = this.resource_video_data[0].resource_name;
        } else {
          reqObj['video_resource_id'] = "";
          reqObj['video_resource_name'] = "";
        };
        reqObj['user_id'] = localStorage.getItem("id");
        reqObj['is_personid'] = this.is_personid;
        reqObj['permiss_ids'] = reqObj.permiss_user_ids;
        reqObj['sign_type'] = 1;
        if (this.dialogStatus == "update") {
          this.updateData(reqObj);
        } else {
          this.createData(reqObj);
        }
      });
    }),
    // 创建 保存
    async createData(reqObj) {
      this.save_loading_btn = true;
      try {
        let res = await this.$axios.post("/courses/insertCourse", reqObj);
        this.save_loading_btn = false;
        let cid = res.data.data.id;
        this.$message.success("操作成功");
        // 上课 说课 讲座 其他
        if ([1, 2, 3, 6].includes(Number(reqObj.type))) {
          let urlName = this.$route.name
          if (urlName == "CreateRecord") {
            this.$router.push({
              path: "/RecordManage",
            });
          }

          if (urlName == "updataCourseInfoSet" || urlName == "CreateRecordSet") {
            this.$router.push({
              path: "/TeachIndex",
            });
          }
        };

        // 听评课
        if ([4].includes(Number(reqObj.type))) {
          // 线下
          if ([1].includes(Number(reqObj.teaching_form))) {
            this.$router.push({
              path: "/nextListingCourseOff",
              query: {
                id: cid,
                is_personid: reqObj.is_personid,
              },
            });
          };
          // 线上
          if ([2].includes(Number(reqObj.teaching_form))) {
            this.$router.push({
              path: "/nextListingOnline",
              query: {
                id: cid,
                is_personid: reqObj.is_personid,
              },
            });
          };
        };

        // 集中备课
        if ([5].includes(Number(reqObj.type))) {
          this.$router.push({
            path: "/nextFocusCourse",
            query: {
              id: cid,
              is_personid: reqObj.is_personid,
            },
          });
        };
      } catch (error) {
        this.save_loading_btn = false;
      }
    },
    // 编辑 保存
    async updateData(reqObj) {
      this.save_loading_btn = true;
      try {
        let res = await this.$axios.put("/courses/editCourse", reqObj);
        this.save_loading_btn = false;
        this.$message.success("操作成功");
        // 上课 说课 讲座 其他
        if ([1, 2, 3, 6].includes(Number(reqObj.type))) {
          let urlName = this.$route.name
          if (urlName == "CreateRecord") {
            this.$router.push({
              path: "/RecordManage",
            });
          }

          if (urlName == "updataCourseInfoSet" || urlName == "CreateRecordSet") {
            this.$router.push({
              path: "/TeachIndex",
            });
          }
        };

        // 听评课
        if ([4].includes(Number(reqObj.type))) {
          // 线下
          if ([1].includes(Number(reqObj.teaching_form))) {
            this.$router.push({
              path: "/nextListingCourseOff",
              query: {
                id: reqObj.id,
                is_personid: reqObj.is_personid,
              },
            });
          };
          // 线上
          if ([2].includes(Number(reqObj.teaching_form))) {
            this.$router.push({
              path: "/nextListingOnline",
              query: {
                id: reqObj.id,
                is_personid: reqObj.is_personid,
              },
            });
          };
        };

        // 集中备课
        if ([5].includes(Number(reqObj.type))) {
          this.$router.push({
            path: "/nextFocusCourse",
            query: {
              id: reqObj.id,
              is_personid: reqObj.is_personid,
            },
          });
        };
      } catch (error) {
        this.save_loading_btn = false;
      }
    },
    //  获取教研组
    async getAllList() {
      let res = await this.$axios.get("TeacherGroup/get_my_list", {
        params: {
          limit: this.JyList.limit,
          page: this.JyList.page,
          my_type: this.JyList.my_type,
          audit_status: "",
        },
      });
      this.JyGronpName = res.data.data.data || [];
    },
    // 资源上传
    getResource(data) {
      this.resource_class_data = [];
      let tempArr = [];
      data.forEach((item) => {
        tempArr.push({
          id: item.id,
          is_download: 1,
          resource_name: item.title,
        });
      });
      this.resource_class_data = tempArr;
      // this.$refs['ruleForm'].validateField('timeFn4'); //单独校验
    },
    // 移除一条资源
    deteleOne(index) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.resource_class_data.splice(index, 1);
      }).catch(() => {
      });
    },
    //获取 章节
    getList_5() {
      if (!this.form.stage_id || !this.form.subject_id || !this.form.grade_id || !this.form.version_id) {
        this.option_list_data_5 = [];
        return false;
      };
      this.$axios.get("/chapter/list", {
        params: {
          pharseid: this.form.stage_id,
          subjectid: this.form.subject_id,
          gradeid: this.form.grade_id,
          editionid: this.form.version_id,
        },
      }).then((res) => {
        let resdata = res.data;
        this.option_list_data_5 = resdata.data || [];
      });
    },
    // 获取 版本
    getList_4() {
      if (!this.form.stage_id || !this.form.subject_id || !this.form.grade_id) {
        this.option_list_data_4 = [];
        return false;
      };
      this.$axios.get("/versions/list", {
        params: {
          pharseid: this.form.stage_id,
          subjectId: this.form.subject_id,
          gradeId: this.form.grade_id,
        },
      }).then((res) => {
        let resdata = res.data;
        this.option_list_data_4 = resdata.data || [];
      });
    },
    // 获取 年级
    getList_3() {
      if (!this.form.stage_id || !this.form.subject_id) {
        this.option_list_data_3 = [];
        return false;
      };
      this.$axios.get("/grade/list", {
        params: {
          pharseid: this.form.stage_id,
          subjectId: this.form.subject_id,
        },
      }).then((res) => {
        let resdata = res.data;
        this.option_list_data_3 = resdata.data || [];
      });
    },
    // 获取 学科
    getList_2() {
      if (!this.form.stage_id) {
        this.option_list_data_2 = [];
        return false;
      };
      this.$axios.get("/subject/list", {
        params: { pharseid: this.form.stage_id },
      }).then((res) => {
        let resdata = res.data;
        this.option_list_data_2 = resdata.data || [];
      });
    },
    // 获取 学段
    getList_1() {
      this.$axios.get("/stage/stage_list", {
        params: {},
      }).then((res) => {
        let resdata = res.data;
        this.option_list_data_1 = resdata.data || [];
      });
    },
    // 发起人 教研组改变
    changeEvent_6() {
      this.$set(this.form, "teaching_studio_id", "");
      this.$set(this.form, "permiss_user_ids", []);
      this.is_personid = 1;
    },
    // 发起人 工作室改变
    changeEvent_7() {
      this.$set(this.form, "group_id", "");
      this.$set(this.form, "permiss_user_ids", []);
      this.is_personid = 2;
    },
    // 学段 改变
    changeEvent_1() {
      this.$set(this.form, "subject_id", "");
      this.$set(this.form, "grade_id", "");
      this.$set(this.form, "version_id", "");
      this.$set(this.form, "chapter_id", "");
      this.getList_2();
      this.getList_3();
      this.getList_4();
      this.getList_5();
    },
    // 学科 改变
    changeEvent_2() {
      this.$set(this.form, "grade_id", "");
      this.$set(this.form, "version_id", "");
      this.$set(this.form, "chapter_id", "");
      this.getList_3();
      this.getList_4();
      this.getList_5();
    },
    // 年级 改变
    changeEvent_3() {
      this.$set(this.form, "version_id", "");
      this.$set(this.form, "chapter_id", "");
      this.getList_4();
      this.getList_5();
    },
    // 版本 改变
    changeEvent_4() {
      this.$set(this.form, "chapter_id", "");
      this.getList_5();
    },
    // 分类 清除校验
    handleSelectType(num) {
      if (num == 1) {
        this.$set(this.form, "permiss_user_ids", []);
      };
      this.$refs["ruleForm"].clearValidate();
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      let etag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", { params: { etag: etag } });
      this.$set(this.form, "cover_url", res.data.data.url);
      this.$set(this.form, "cover_id", res.data.data.id);
      this.$refs['ruleForm'].validateField('cover_id'); //单独校验
    },
    // 清除视频
    clearVideo() {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.resource_video_data = [];
      }).catch(() => {
      });
    },
    // 上传视频
    async dcFile(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      let etag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", { params: { etag: etag } });
      this.resource_video_data = [{
        id: res.data.data.id,
        resource_name: res.data.data.name,
        url: res.data.data.url,
      }];
      this.form.name = res.data.data.name
      this.$refs['ruleForm'].validateField('timeFn3'); //单独校验
    },
  },
};
</script>

<style lang="less" scoped>
.CourseAdd_div {
  background-color: #fff;
}

.active_selected_box {
  display: flex;
  align-items: flex-end;

  .active_selected_img {
    width: 160px;
    height: 100px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    margin-right: 20px;
  }

  .selection_img_list {
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    margin-right: 10px;

    .selection_img_item {
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      width: 160px;
      height: 100px;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .selection_img_img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }

    .selection_img_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .selection_img_icon {
      color: #fff;
      font-size: 40px;
    }
  }

  .active_selected_uploading {
    position: relative;
    height: 100px;
    width: 100px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;

    .upload_img_box {
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload_icon_div {
      font-size: 30px;
    }
  }
}

.form_box_div {
  width: 100%;
  box-sizing: border-box;
  padding-right: 100px;

  .list_type_div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    /deep/ .el-radio-button {
      margin-right: 20px;
      border: none !important;
    }

    /deep/ .el-radio-button__inner {
      border: 1px solid #dcdfe6 !important;
      box-shadow: none !important;
    }

    /deep/ .is-active {
      .el-radio-button__inner {
        background-color: #409eff !important;
        border-color: #409eff !important;
        color: #fff !important;
      }
    }
  }

  .videoBox {
    width: 60%;
    margin-top: 5px;
    padding: 0 10px;
    background: rgba(238, 242, 251, 1);
    border-radius: 4px;

    .forItemBox {
      display: flex;

      .one {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .two {
        cursor: pointer;

        span {
          margin-left: 10px;
          color: #409eff;
        }
      }
    }
  }

  .jyz_box {
    margin-top: 10px;
    margin-left: 130px;
  }

  .listBOx {
    margin-top: 20px;

    .listItem {
      background: rgba(238, 242, 251, 1);
      border-radius: 2px;
      width: 100%;
      display: flex;
      margin-top: 8px;
      padding: 0 10px;

      .Item-left {
        width: calc(100% - 200px);

        p {
          max-width: 370px;
          overflow: hidden;
        }
      }

      .Item-right {
        width: 200px;

        span {
          color: rgba(154, 158, 165, 1);
        }
      }
    }
  }
}
</style>