<template>
  <div class="goclasssteptwo">
    <div class="w_d center_one">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>研修视频</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">创建课程</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="one_content">
        <div class="images">
          <img src="@/assets/images/live.png" />
        </div>
        <div class="word">
          <div class="word_one">
            <p>Adobe xd ui设计/交互动效教程入门到精通全集【51RGB在线教育】</p>
            <i class="el-icon-edit-outline"></i>
            <i class="el-icon-delete"></i>
          </div>
          <div class="word_two">
            <span>学科学科：</span>
            <span>小学语文</span>
            <span>年级：</span>
            <span>小学一年级</span>
            <span>教材版本：</span>
            <span>xxxx</span>
            <span>适用章节：</span>
            <span>章/节</span>
          </div>
          <div class="word_three">
            <span>视频介绍：</span>
            <p>构图、色彩和光影属于设计中非常重要的3块体系，但构图和色彩大多是偏感性的主观理解的凤凰山房符多是偏感性的主观理解的凤凰山房符</p>
          </div>
          <div class="word_four">
            <div class="startup" @click="one">发布课程</div>

            <div class="word_four_one">
              <p>
                <span>温馨提示：</span>启动直播后，无法再编辑课程，请确保课程信息和内容无误后再发布。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="two_content">
      <div class="center_main-content w_d">
        <div class="left">
          <div class="left_one">
            <p>视频内容</p>
            <!-- <div class="add_video">添加视频</div> -->
            <!-- <p>提示：若已上传授课视频，则新上传的视频将替换旧视频。</p> -->
          </div>
          <div class="left_two"></div>
        </div>
        <div class="right">
          <div class="right_one">
            <p>上课资源</p>
            <!-- <div class="add_resource">添加视频</div> -->
          </div>
          <div class="right_two">
            <div class="two_block" v-for="(item,index) in 6" :key="index">
              <p class="goclass">万字干货！帮你深度掌握设计中的「光影」知识点</p>
              <div class="check">
                <p>上传于 2020-04-21</p>
                <!-- <el-select v-model="value" placeholder="请选择" class="check_right">
                  <el-option></el-option>
                </el-select> -->
                <div class="check_right">
                  <span>查看</span>
                  <span>下载</span>
                </div>
              </div>
              <div class="hengxian"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除对话框 -->
    <div class="mydialog">
      <el-dialog :visible.sync="deleteDialogVisible" width="400px" :show-close="false" top="35vh">
        <div class="delete_dialog">
          <div class="delete_one">
            <div class="one_one">
              <i class="el-icon-close"></i>
            </div>
            <p>确定删除此课程吗？</p>
          </div>
          <div class="delete_two">
            <div class="two_cancel">取消</div>
            <div class="two_comfirm">确定</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleteDialogVisible: true
    }
  },
  methods: {
    one() {
      this.$router.push('/goclassdetail')
    }
  }
};
</script>

<style scoped lang="less">
.goclasssteptwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  .center_one {
    // border: 1px solid black;
    .one_content {
      display: flex;
      margin-bottom: 44px;
      //   justify-content: space-between;
      .images {
        img {
          width: 520px;
          height: 293px;
          border-radius: 6px;
        }
      }
      .word {
        margin-left: 58px;
        margin-top: 40px;
        .word_one {
          display: flex;
          align-items: center;
          margin-bottom: 31px;
          p {
            font-size: 22px;
            font-weight: 700;
          }
          i {
            font-size: 22px;
            margin-left: 15px;
            color: #009dff;
          }
        }
        .word_two {
          display: flex;
          margin-bottom: 29px;
          span:nth-child(odd) {
            color: #999999;
          }
          span:nth-child(even) {
            margin-right: 53px;
          }
        }
        .word_three {
          display: flex;
          margin-bottom: 58px;
          span:nth-child(1) {
            color: #999999;
          }
          p:nth-child(2) {
            width: 710px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .word_four {
          display: flex;
          align-items: center;
          .startup {
            width: 169px;
            height: 46px;
            background: #ff8201;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #ffffff;
            margin-right: 30px;
          }
          .word_four_one {
            span {
              font-weight: 700;
            }
            font-size: 14px;
            font-weight: 400;
            color: #ff0607;
            p:nth-child(1) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .two_content {
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    justify-content: center;

    .center_main-content {
      // border: 1px solid black;
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      .left {
        width: 1045px;
        .left_one {
          margin: 40px 0px;
          display: flex;
          align-items: center;
          p:nth-child(1) {
            font-size: 22px;
            font-weight: 700;
            margin-right: 26px;
          }
          .add_video {
            width: 108px;
            height: 38px;
            background: #3489fe;
            border-radius: 6px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px;
          }
          p:nth-child(3) {
            color: #9a9ea5;
          }
        }
        .left_two {
          width: 1045px;
          height: 659px;
          background: #ffffff;
          border-radius: 6px;
        }
      }
      .right {
        width: 335px;
        //  border: 1px solid black;

        .right_one {
          margin: 40px 0px;
          display: flex;
          align-items: center;
          p:nth-child(1) {
            font-size: 22px;
            font-weight: 700;
            margin-right: 25px;
          }
          .add_resource {
            width: 108px;
            height: 38px;
            background: #3489fe;
            border-radius: 6px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .right_two {
          width: 272px;
          height: 659px;
          padding-left: 30px;
          padding-right: 33px;
          background: #ffffff;
          border-radius: 6px;
          overflow: auto;
          .two_block {
            height: 134px;
            .goclass {
              font-weight: 700;
              margin-top: 30px;
              margin-bottom: 12px;
              line-height: 26px;
            }
            .check {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 24px;
              p:nth-child(1) {
                font-size: 14px;
                color: #999999;
              }
              .check_right {
                // width: 82px;
                // height: 26px;
                font-size: 14px;
                span:nth-child(2) {
                  margin-left: 20px;
                }
              }
              /deep/ .el-input__inner {
                height: 28px;
                padding: 0 11px;
              }
              /deep/ .el-input__icon {
                line-height: 0px;
              }
              /deep/ .el-input--suffix .el-input__inner {
                padding-right: 0px;
              }
            }
            .hengxian {
              width: 100%;
              border-top: 1px dashed #ebebeb;
            }
          }
        }
      }
    }
  }
}
</style>