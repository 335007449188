<template>
  <div class="sendCon">
      <div class="mBox">
          <h4>发起会议</h4>
          <div class="mComtent">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="会议主题" prop="title">
                    <el-input v-model="form.title" placeholder="最多填50个字" style="margin-bottom:10px"></el-input>
                </el-form-item>
              
                <el-form-item label="会议时间" required>

                    <el-form-item prop="date1">
                        <div class="hyBox">
                            <div>
                                <el-date-picker type="date" placeholder="选择开始日期" v-model="form.start_date" style="width:200px;margin-right:10px"></el-date-picker>&nbsp;&nbsp;
                                <el-time-picker type="fixed-time" placeholder="选择开始时间" v-model="form.start_time" style="width: 200px;margin-right:10px"></el-time-picker>
                                 <span>——</span>
                                 <el-date-picker type="date" placeholder="选择结束日期" v-model="form.start_date" style="width: 200px;margin-right:10px;margin-left:10px"></el-date-picker>
                                 <el-time-picker type="fixed-time" placeholder="选择结束时间" v-model="form.start_time" style="width:200px;"></el-time-picker>
                            </div>
                        </div>
                    </el-form-item>

                </el-form-item>
                <!-- 参与人员 -->
                <el-form-item label="参与人员">
                     <el-button type="primary" @click="dialogFormVisible = true">选择参与人员>>></el-button>
                      <span style="color:#666666; margin-left: 50px;cursor: pointer;">清空所有</span>
                </el-form-item>
                <!-- 选择的dic -->
                <div class="poepleName">
                    
                </div>
                <el-form-item>
                    <el-button type="primary" style="margin-right:50px">发起会议</el-button>
                    <el-button >取消</el-button>
                </el-form-item>
                <!-- 回话 -->
                <el-dialog title="选择用户" :visible.sync="dialogFormVisible">
                <el-form :model="form1">
                  <div class="box1">
                      <!-- 左边 -->
                        <div class="LBox">
                            <span style="margin-right:10px">地区:</span>
                        <el-select v-model="value" placeholder="请选择省份">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="value" placeholder="请选择地市">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="value" placeholder="请选择区县">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        </div>
                        <!-- 右边 -->
                         <div class="LBox">
                            <span style="margin-right:10px">学科学段:</span>
                        <el-select v-model="value" placeholder="请选择省份">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="value" placeholder="请选择地市">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        
                        </div>
                  </div>
                  <div class="box2">
                        <div class="spa">
                            <span>姓名：</span>
                        </div>
                        <div class="LBox">
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </div>
                         <div class="spa">
                            <span>单位：</span>
                        </div>
                        <div class="LBox">
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </div>
                         <div class="spa">
                            <span>教研组：</span>
                        </div>
                        <div class="LBox">
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </div>
                        <div class="LBox" style="margin-left:20px">
                            <el-button type="primary">查询</el-button>
                            <el-button>重置</el-button>
                        </div>
                  </div>
                  <div class="box3">
                    <el-radio v-model="radio" label="1">全部</el-radio>
                   <el-radio v-model="radio" label="2">已选</el-radio>         
                  </div>
                  <div class="box4">
                      <el-table
                            ref="multipleTable"
                            :data="gridData"
                            tooltip-effect="dark"
                            style="margin-left:20px;margin-top:15px"
                            @selection-change="handleSelectionChange">
                                <el-table-column
                                type="selection"
                                width="55">
                                </el-table-column>
                                <el-table-column property="date" label="姓名" width="150"></el-table-column>
                                <el-table-column property="name" label="学段" width="150"></el-table-column>
                                <el-table-column property="address" label="学科" idth="150"></el-table-column>
                                <el-table-column property="date" label="所属教研组" width="150"></el-table-column>
                                <el-table-column property="name" label="所属单位" width="150"></el-table-column>
                                <el-table-column property="address" label="所属地区"></el-table-column>
                        </el-table>
                       
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                </div>
                </el-dialog>
                <!-- end -->
            </el-form>
          </div>
      </div>
  </div>
</template>

<script>
export default {
   data(){
       return{
           gridData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                        }, {
                        date: '2016-05-04',
                        name: '王小虎',
                        address: '上海市普陀区金沙江路 1518 弄'
                        }, 
                      ],

           radio: '1',

           input:"",

           options: [{
                value: '1',
                label: '广州'
                }, {
                value: '2',
                label: '天河'
                }, {
                value: '3',
                label: '北京'
                }, {
                value: '4',
                label: '上海'
                }, {
                value: '5',
                label: '江苏'
            }],
           value: '',

           dialogFormVisible:false,

           form:{
            title:"",
            start_date:"",
            start_time:"",
            end_date:"",
            end_time:"",
            join_user:"",
            join_user:"",
           },

           form1:{

           },
          rules: {
          title: [
            { required: true, message: '请输入会议名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度最多50个字符', trigger: 'blur' }
          ],
         
        }
       }
   },
   methods:{
       handleSelectionChange(val) {
        this.multipleSelection = val;
      }
   }
}
</script>

<style lang="less">
   .sendCon{
       .mBox{
           .mComtent{
               .el-form{
                    width: 100%;
                    margin-top: 50px;
                    .el-dialog{
                       .el-form{
                           padding:0;
                           margin:0;
                           box-sizing: border-box;
                           .box1{
                               display: flex;
                               margin-left: 30px;
                               .el-select{
                                   width: 140px;
                                   margin-right: 10px;
                               }
                           }
                           .box2{
                               display: flex;
                               margin-left: 30px;
                               margin-top: 20px;
                               .spa{
                                   line-height: 40px;

                               }
                               .LBox{
                                        display: flex;
                                        .el-input__inner{
                                        width: 140px;
                                        margin-right: 10px;
                                    }
                               }
                           }
                           .box3{
                               margin-left: 30px;
                               margin-top: 20px;
                           }
                       }
                    }
                   .hyBox{
                       display: flex;
                       div{
                           width: 1000px;
                           display: flex;
                           
                       }
                   }
                //    名字
                .poepleName{
                    width: 80%;
                    height: 200px;
                    border: 1px solid #EBEBEB;
                    margin-left: 30px;
                    margin-bottom: 20px;
                    
                }
               }
           }
       }
   }
</style>