<template>
  <!-- 资源浏览页面、 -->
  <div class="resourcebrowsing study-active-introduce">
    <div class="title">{{ resourceData.name }}</div>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="one_box">
        <span class="link-date">环节时间：{{ resourceData.start_date }} 至 {{ resourceData.end_date }}</span>

        <el-popover placement="top" width="" trigger="click" v-if="isEdit">
          <el-date-picker v-model="activityInfoTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeTImeEvent">
          </el-date-picker>
          <div class="change_time_div_btn" slot="reference" @click="openTImeSelectBox">
            更改时间
          </div>
        </el-popover>
        <el-dialog title="" :visible.sync="time_visible" width="500px" top="20vh">
          <div style="text-align: center;">
            <el-button type="primary" @click="handleSync(2)" v-if="activityInfoData.audit_status == 1">确定</el-button>
            <template v-else>
              <el-button type="primary" @click="handleSync(1)">确认并同步活动状态</el-button>
              <el-button type="primary" @click="handleSync(2)">不同步活动状态</el-button>
            </template>
            <el-button @click="time_visible = false">取消</el-button>
          </div>
        </el-dialog>

        <div v-if="resourceData.status ==1" class="conduct nostart"> 未开始 </div>
        <div v-if="resourceData.status ==2" class="conduct going"> 进行中 </div>
        <div v-if="resourceData.status ==3" class="conduct end"> 已结束 </div>
        <EndLink :id="form.activity_link_resource_id" :is_from="is_from" :status="resourceData.status" @success="endLinkSuccess" v-if="isEdit">
        </EndLink>
      </div>
      <div class="two">
        <span>环节类型：资源浏览</span>
        <span>开展方式：{{
            resourceData.implement_type == "1" ? "线上" : "线下"
          }}</span>
        <span>参与人数:{{ resourceData.user_count }}人</span>
      </div>
      <div class="public-line mb30"></div>
      <div class="three">环节描述</div>
      <div class="four">
        <!-- <p>{{ resourceData.description }}</p> -->
        <div v-bind:class="{ expansion: textActive }" class="show_text_all">
          <p class="rich_text_div_img" v-html="resourceData.description"></p>
        </div>
      </div>
      <div class="public-line mb30"></div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->

      <div v-if="resourceData.implement_type == '1'">
        <div v-if="resourceData.resource_list != null">

          <div class="cursor six fsbc" v-for="(item, index) in resourceData.resource_list" :key="index">
            <div class="fc">
              <img :src="item.resource_url | showicon" class="images" />
              <new-viewonline v-if="resourceData.status==2 && $store.state.activity_isJoin" :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id"
                :fileExt="item.mime || item.ext" :itemData="item" @browseFinish="browseFinish" :fileTitle="item.resource_name" :fileSize="item.size"
                :fileTime="item.create_time" fileNav="教研活动 > 活动详情 > 查看">
                <p class="six_word">{{ item.resource_name }}</p>
              </new-viewonline>
              <p class="six_word" v-else>{{ item.resource_name }}</p>
            </div>
            <div class="fc">
              <div v-if="resourceData.status==2 && $store.state.activity_isJoin" class="six_one cursor">
                <el-link target="_blank" :underline="false">
                  <p class="dow_two" @click.stop="handleFinish(item)">
                    下载
                  </p>
                </el-link>
              </div>

              <!-- 浏览记录 -->
              <div class="cursor view_record" @click.stop="browseRecords(item.id)" v-if="isShow">
                浏览记录（{{item.yes.total}} / {{(item.yes.total+item.no.total)}}）
              </div>
            </div>
          </div>

        </div>

        <div class="public-line mb30"></div>

        <div class="seven" v-if="$store.state.isLogin">
          <div class="seven_left">
            <div class="images">
              <!-- <img src="@/assets/images/avator.png" /> -->
              <img :src="photo_url" />
            </div>
            <div class="left_word">{{ username }}</div>
          </div>
          <div class="seven_right">
            <el-input type="textarea" :rows="6" placeholder="写下你的评论..." v-model="myContent" resize="none"></el-input>
            <div v-if="resourceData.status==2 && $store.state.activity_isJoin" class="release cursor" @click="releaseComment">发布</div>
          </div>
        </div>
        <!-- 评论列表组件区域 :item_status="resourceData.status"-->
        <div class="new_comment">
          <new-comment :activity_id="activityId" :link_id="form.activity_link_resource_id" :random="random" :module_type='is_from== 1 ? 1 : 6'>
          </new-comment>
        </div>
      </div>
      <!-- 线下区域 -->
      <UnderLineDetail :is_from="+is_from" :resourceData="resourceData" v-else-if="resourceData.implement_type == '2'"
        @getResourceData="getResourceData" :activityId="+activityId" :linkId="+form.activity_link_resource_id" @cancelLink="getInit" />
    </div>

    <!-- 资源浏览记录弹窗 -->
    <MyDialog :open="open" title="浏览记录" @onClose="open = false" width="766px">
      <div class="visit_dialog">
        <div class="pb20 fc">
          <p class="cursor pp" :class="i == form2.r_id ? 'active' :  '' " v-for="(e, i) in visitArr" :key="i" @click="onTriggerVisit(i)">
            {{ e }}
          </p>
        </div>
        <ListOne :typeVal="3" :list="previewList" />
      </div>
    </MyDialog>

  </div>
</template>

<script>
import {
  ActiveHjDetail,
  ResourceViewRecord,
  AddResourceViewRecord,
  cancelGroupActivity,
  cancelTeachActivity
} from "@/api/teacherStudio";
import { onShowImages } from "@/utils/utils";
import pdf from "vue-pdf";
import ListOne from "@/components/CommonList/ListOne.vue";
import UnderLineDetail from "./components/underlineDetail/index.vue";
import EndLink from "./components/endLink/endLink.vue";
import * as researchApi from '@/api/researchApi.js';
export default {
  components: {
    ListOne,
    pdf,
    UnderLineDetail,
    EndLink
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      time_visible: false,
      time_reqObj: {},
      activityInfoTime: [],
      //判断是不是创建者，显示浏览记录按钮
      isShow: false,
      //浏览的资源ID
      resourseId: '',

      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 资源浏览数据
      resourceData: {},
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",
      // 当前用户评论内容
      myContent: "",

      // 文本是否隐藏
      textActive: true,
      // 控制评论列表更新
      random: 0,
      // 当前用户头像url
      photo_url: "",
      open: false,
      visitArr: ["未浏览", "已浏览"],
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
      form: {

        //这个环节的资源ID
        activity_link_resource_id: "",

        //活动id
        group_studio_id: "",

        type: "",  //type 1 为教研组 2工作室
        page: 1,
        limit: 10,
        //   0 为未预览 1为已预览
        r_id: 1,
      },
      previewList: [],

      //文件浏览数
      had_preview: 0,


      //资源列表浏览记录列表
      form2: {
        //这个环节的资源ID
        activity_link_resource_id: "",
        //活动id
        group_studio_id: "",

        type: "",  //type 1 为教研组 2工作室
        page: 1,
        limit: 1000,
        //   0 为未预览 1为已预览
        r_id: 1,
      }
    };
  },
  created() {
    this.init();
  },
  filters: {
    showicon(url) {
      let index = url.lastIndexOf(".");
      let ext = url.substring(index + 1);
      return onShowImages(ext);
    },
  },
  methods: {
    //浏览记录
    browseRecords(id) {
      //活动资源ID
      this.form2.activity_link_resource_id = id;
      this.onGetViews();
      this.open = true;
    },
    // 点击更改时间
    openTImeSelectBox() {
      this.activityInfoTime = [`${this.resourceData.start_date}`, `${this.resourceData.end_date}`]
    },
    // 同步迟环节
    async handleSync(num) {
      try {
        let time_reqObj = this.time_reqObj;
        time_reqObj['is_sync_status'] = num;
        time_reqObj['is_personid'] = this.is_from;
        let res_1 = await researchApi.get_activity_activitylink_update_start_end_date(time_reqObj);
        this.time_visible = false;
        if (res_1.data.data.status) {
          this.$set(this.resourceData, "status", res_1.data.data.status);
          this.$set(this.resourceData, "start_date", res_1.data.data.start_date);
          this.$set(this.resourceData, "end_date", res_1.data.data.end_date);
        };
        this.$message.success("操作成功");
        this.$parent.$refs.linkNav.init();
      } catch (error) {
        this.time_visible = false;
      }
    },
    // 确定更改l环节时间
    changeTImeEvent(e) {
      if (e) {
        let time_reqObj = {
          id: this.form.activity_link_resource_id,
          start_date: e[0],
          end_date: e[1],
        };
        this.time_visible = true;
        this.time_reqObj = time_reqObj;
      }
    },
    //结束环节
    endLinkSuccess(status) {
      this.$set(this.resourceData, "status", status);
      this.$parent.$refs.linkNav.init();
    },
    // 初始化函数
    init() {
      //活动id
      this.activityId = this.$route.query.activityId;
      this.form.activity_link_resource_id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("user_name");
      this.userId = window.localStorage.getItem("id");
      if (this.is_from == 1) {
        //   this.form.group_studio_id = localStorage.getItem("studioId")
        this.form.type = 1;
        this.form2.type = 1;
      } else {
        this.form.group_studio_id = localStorage.getItem("studioId");
        this.form.type = 2;
        this.form2.type = 2;

      }
      this.photo_url = window.localStorage.getItem("photo_url") || "";
      this.getResourceData();
    },
    //父传父
    getInit() {
      this.$parent.$refs.linkNav.init();
    },
    // --------------------重改--------------
    // 资源浏览记录
    onGetViews() {
      //活动ID
      this.form2.group_studio_id = this.activityId
      ResourceViewRecord(this.form2).then((res) => {
        this.previewList = res.msg.data
        // if(this.form.r_id == 1){
        // 已浏览数
        //this.had_preview = this.previewList.length
        // }
      });
    },
    // 预览
    onPreview(item) {
      return
      // console.log(item,"预览");
      window.open(item.view_url, "_blank");
      // 请求浏览记录接口保存浏览
      AddResourceViewRecord({
        type: this.form.type,
        group_studio_id: this.form.group_studio_id,
        activity_link_resource_id: item.id,
      }).then((res) => {
        this.onGetViews();
      });
    },
    //下载
    handleFinish(item) {
      //完成环节
      this.cancelLink(item.id);
      location.href = item.resource_url;
    },
    //完成环节
    cancelLink(id) {
      this.AddLookNum(id);
      // console.log("完成环节在弄");
      if (this.is_from == 1) {
        let obj = {
          activityId: this.$route.query.activityId,
          id: this.$route.query.id,
        };
        cancelGroupActivity(obj).then(() => {
          this.init();
        })
      } else if (this.is_from == 2) {
        let obj = {
          activityId: this.$route.query.activityId,
          id: this.$route.query.id,
        };
        cancelTeachActivity(obj).then(() => {
          this.init();
        })
      }
      this.$parent.$refs.linkNav.init();
    },

    // 添加资源浏览记录
    AddLookNum(rid) {
      let obj = {
        type: this.is_from,
        group_studio_id: this.$route.query.activityId,
        activity_link_resource_id: rid,
        is_personid: this.is_from
      }
      this.$axios.post("ResourceRecord/add", obj)
        .then(() => {
          console.log("浏览加1");
        });
    },
    // 切换浏览人数
    onTriggerVisit(i) {
      this.form2.r_id = i;
      this.onGetViews()
    },

    // --------------------重改end--------------
    //请求后台获取资源浏览数据
    getResourceData() {
      if (this.is_from == 1) { //教研组的
        this.$axios.get(`activity/${this.activityId}/activityLink/${this.form.activity_link_resource_id}`).then((res) => {
          this.resourceData = res.data.data;
          this.resourseId = this.resourceData.resource_list[0].id;
          this.isShow = (localStorage.getItem("id") == this.resourceData.user_id) ? true : false;
        });
      } else { //四名工作室的
        ActiveHjDetail(
          this.activityId,
          this.form.activity_link_resource_id
        ).then((res) => {
          this.resourceData = res.data;
          //  console.log(this.resourceData.resource_list[0].id,"4545454545");
          this.resourseId = this.resourceData.resource_list[0].id;
          this.isShow = (localStorage.getItem("id") == this.resourceData.user_id) ? true : false;
        });
      }
    },
    // 请求后台发布评论函数
    releaseComment() {
      if (this.resourceData.status == "2") {
        if (!this.myContent.trim()) {
          return this.$message.warning("发布内容不能为空");
        }
        var obj = {
          activity_id: this.activityId,
          user_id: this.userId,
          content: this.myContent,
          link_id: this.form.activity_link_resource_id,
        };
        let p = '';
        if (this.is_from == 1) {  //教研组评论发布
          p = this.$axios.post("ActivityReply/insertComment", obj)
        } else { //四名工作室评论发布
          p = this.$axios.post("TeachingStudioActivityReply/insertComment", obj)
        }
        p.then(() => {
          // this.$message.success(res.data.data.msg);
          this.$message.success("发布成功");
          this.random++;
          this.myContent = "";
        });
      } else if (this.resourceData.status == "1") {
        this.$message.warning("活动环节未开始");
      } else if (this.resourceData.status == "3") {
        this.$message.warning("活动环节已结束");
      }
    },
    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    browseFinish(id) {
      //完成环节
      this.cancelLink(id);
    }
  },
  computed: {
    //判断是否能编辑
    isEdit() {
      let uid = localStorage.getItem('id');
      if (uid == this.resourceData.user_id) {
        return true
      } else {
        return false
      }
    },
  },
};
</script>

<style scoped lang="less">
.resourcebrowsing {
  width: 1116px;
  //   height: 2157px;
  background: #ffffff;
  border: NaNpx solid #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 4px 7px 0px #f5f5f5;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    font-size: 20px;
    font-weight: 700;
    margin: 49px 0px 45px 0px;
    display: flex;
    justify-content: center;
  }
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    padding: 0 30px;
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .one_box {
      display: flex;
      margin: 41px 0px 23px;
      align-items: center;
    }
    .conduct {
      width: 51px;
      height: 18px;
      background: #ff8201;
      border-radius: 9px;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 18px;
    }
    .going {
      background: #ff8201;
    }
    .nostart {
      background: #3489fe;
    }
    .end {
      background: #cecece;
    }
    .two {
      color: #000000;
      margin-bottom: 39px;
      span {
        margin-right: 56px;
      }
    }
    .three {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 23px;
    }
    .four {
      margin-bottom: 25px;
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      margin-bottom: 30px;
      padding: 26px 24px 26px 30px;
      .view_record {
        padding: 10px 3px 10px 13px;
        background: #3489fe;
        border-radius: 6px;
        color: #fff;
        margin-left: 20px;
      }
      .images {
        // margin-left: 33px;
        margin-right: 27px;
        width: 68px;
        height: 51px;
      }
      .six_word {
        max-width: 460px;
        // margin-right: 261px;
        overflow: hidden; //文字长度超出限定宽度，则隐藏超出的内容）
        text-overflow: ellipsis; //（设置文字在一行显示，不能换行）
        white-space: nowrap; //（规定当文本溢出时，显示省略符号来代表被修剪的文本）
      }
      .six_one {
        width: 120px;
        // height: 34px;
        padding: 10px 0px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .six_two {
        width: 120px;
        height: 34px;
        background: #3489fe;
        border: 1px solid #ececec;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
    }
    .seven {
      display: flex;
      justify-content: space-between;
      margin-bottom: 37px;

      .seven_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .images {
          margin-bottom: 13px;
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
      .seven_right {
        flex: 1;
        overflow: hidden;
        border: 1px solid #ececec;
        border-radius: 4px;
        padding: 20px;
        margin-left: 25px;
        .release {
          width: 92px;
          height: 34px;
          background: #fa8c15;
          border-radius: 4px;
          font-weight: 700;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          float: right;
        }
        /deep/ .el-textarea__inner {
          height: 80px;
          padding: 0;
          border: none;
        }
      }
    }
    .eight {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .eight_checkbox {
        margin-right: 32px;
      }
    }
    .nine {
      .comment_block {
        margin-right: 32px;
        margin-bottom: 40px;
        .block_one {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .images {
            margin-right: 19px;
            img {
              width: 50px;
              height: 50px;
            }
          }
          .one_word {
            font-size: 14px;
            p:nth-child(1) {
              color: #fa8c15;
              margin-bottom: 5px;
            }
            p:nth-child(2) {
              color: #9a9ea5;
            }
          }
        }
        .block_two {
          margin-left: 69px;
          margin-bottom: 21px;
          p {
            line-height: 30px;
          }
        }
        .block_three {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          color: #9a9ea5;
          margin-bottom: 26px;
          .three_one {
            margin-right: 26px;
          }
          i {
            margin-right: 6px;
          }
        }
        .block_four {
          display: flex;
          justify-content: flex-end;
          .reply_block {
            width: 858px;
            // height: 542px;
            background: #f7f7f7;
            border-radius: 8px;
            padding: 30px 27px 34px 30px;
            .reply_block_one {
              .reply_one {
                display: flex;
                align-items: flex-end;
                margin-bottom: 19px;
                .images {
                  margin-right: 14px;
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  overflow: hidden;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
                .reply_one_word {
                  font-size: 14px;
                  p:nth-child(1) {
                    margin-bottom: 5px;
                    span:nth-child(odd) {
                      color: #fa8c15;
                    }
                    span:nth-child(2) {
                      margin: 0px 10px;
                    }
                  }
                  p:nth-child(2) {
                    color: #9a9ea5;
                  }
                }
              }
              .reply_two {
                margin-left: 64px;
                margin-bottom: 17px;
                p {
                  line-height: 30px;
                }
              }
              .reply_three {
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                color: #9a9ea5;
                margin-bottom: 26px;
                .three_one {
                  margin-right: 26px;
                }
                i {
                  margin-right: 6px;
                }
              }
              .reply_four {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 30px;
                .reply_four_hengxian {
                  width: 794px;
                  // height: 1px;
                  border-top: 1px dashed #ececec;
                }
              }
              .reply_five {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 25px;
                .reply_five_block {
                  width: 792px;
                  height: 100px;
                  background: #ffffff;
                  border-radius: 8px;
                  padding-top: 10px;
                  .release_block {
                    width: 92px;
                    height: 36px;
                    background: #fa8c15;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ffffff;
                    float: right;
                    margin-right: 20px;
                  }
                  /deep/ .el-textarea__inner {
                    border: none;
                  }
                }
              }
            }
            .reply_block_one:last-child {
              .reply_four {
                margin-bottom: 0px;
                .reply_four_hengxian {
                  border-top: 0px;
                }
              }
            }
          }
        }
        .block_five {
          width: 914px;
          height: 1px;
          border-top: 1px dashed #ececec;
          margin-top: 26px;
          float: right;
        }
        .comment_block_reply {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 25px;
          .block {
            width: 914px;
            height: 100px;
            background: #ffffff;
            border: 1px solid #ececec;
            border-radius: 8px;
            padding-top: 10px;
            .release {
              width: 92px;
              height: 36px;
              background: #fa8c15;
              border-radius: 4px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              margin-right: 20px;
            }
            /deep/ .el-textarea__inner {
              border: none;
            }
          }
        }
      }
      .comment_block:last-child {
        .block_five {
          border-top: 0px;
          margin-top: 0px;
        }
      }
    }
  }

  .new_comment {
    margin-right: 17px;
  }
  .visit_dialog {
    .pp {
      width: 96px;
      height: 30px;
      background: rgba(52, 137, 254, 0.1);
      border-radius: 15px;
      line-height: 30px;
      text-align: center;
      margin-right: 26px;
      &.active {
        background: #3489fe;
        color: #fff;
      }
    }
  }
}
</style>