<template>
  <div class="noticeAndInfo">
    <div class="rTop">
      <!-- 左边公告 -->
      <div class="noticeData">
        <div class="pb18 titleClass">
          <span>通知公告</span>
          <span @click="toMore1()" v-if="listData.length > 0">更多>></span>
        </div>
        <template v-if="listData.length > 0">
          <div class="topbox df">
            <div class="imgBox">
              <img class="imgBox"
                src="@/assets/notify_default.png"
                alt="">
            </div>
            <div class="txtBox">
              <ul v-for="(item, index) in listData" v-show="index < 4" :key="index" @click="onGoNoticeDetail(item.id)">
                <li style="">{{ item.title }}</li>
              </ul>
            </div>
          </div>
          <div v-if="listData.length > 4">
            <ul v-for="(item, index) in listData" v-show="index >= 4" :key="index" @click="onGoNoticeDetail(item.id)">
              <li style="">{{ item.title }}</li>
              <li>{{ item.update_time.slice(0, 10) }}</li>
            </ul>
          </div>
        </template>
        <CommonEmpty v-else />
      </div>

      <!-- 竖线 -->
      <!-- <div class="line"></div> -->
      <!-- 右边动态 -->

      <div class="newInfo">
        <div class="titleClass">
          <span>最新动态</span>
        </div>
        <div v-if="NewList.length > 0">
          <ul class="cp" v-for="(item, index) in NewList" :key="index" @click="handlePageEvent(item)">
            <li>
              <div>
                <img :src="item.photo_url" alt="" />
              </div>
            </li>
            <li>
              <div class="top">
                <p class="ellipsis" :title="item.username">{{ item.username }}</p>
                <p>{{ item.update_time.slice(0, 10) }}</p>
              </div>
              <div class="bottom">
                <p>{{ item.commet }}</p>
                <p class="ellipsis">{{ item.title }}</p>
              </div>
            </li>
          </ul>
        </div>
        <CommonEmpty v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        limit: 10,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
        search_name: "",
      },
      listData: [],
      // 最新动态列表
      NewList: [],
      form1: {
        limit: 10,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    handlePageEvent(item) {
      let link_src = "";
      // if (item && item.teaching_studio_id) {
      //   link_src = `&is_show_head=1&teaching_studio_id=${item.teaching_studio_id}`;
      // };
      window.open(`${item.go_url}${link_src}`, "_blank");
    },
    init() {
      this.getNotice();
      this.NewStatus();
    },
    //公告列表
    getNotice() {
      this.$axios.get("index/teachingStudioNotice/listsTeachingStudioNotice", {
        params: this.form,
      }).then((res) => {
        this.listData = res.data.data.data.slice(0, 6);
      });
    },
    //最新动态列表
    NewStatus() {
      this.$axios.get("index/TeachingStudio/ListTeachingStudioUserLog", {
        params: this.form1,
      }).then((res) => {
        this.NewList = res.data.data.data.slice(0, 3);
      });
    },

    toMore1() {
      //跳转更多公告
      this.$router.push({
        path: "/noticeIndex",
      });
    },
    onGoNoticeDetail(id) {
      this.$router.push({
        path: "/noticeDetail",
        query: {
          id
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.noticeAndInfo {
  border-radius: 8px;

  .rTop {
    display: flex;
    width: 1106px;
    height: 310px;
    border-radius: 6px;
    justify-content: space-between;

    //通知公告
    .noticeData {
      background: #fff;
      box-sizing: border-box;
      width: 688px;
      border-radius: 8px;
      padding: 16px 16px 23px 16px;

      .titleClass {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        span:nth-of-type(1) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }

        span:nth-of-type(2) {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
      }

      .topbox {
        .imgBox {
          width: 224px;
          height: 126px;
          position: relative;
        }

      }

      .txtBox {
        margin-left: 15px;

        ul {
          display: flex;
          justify-content: space-between;
          height: initial;
          align-items: center;
          cursor: pointer;
          position: relative;
          padding-left: 10px;
          margin-bottom: 15px;

          &:hover {
            background: #f7f7f7;
          }

          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            background: #3489ff;
            border-radius: 50%;
            left: -1px;
          }

          li:nth-of-type(1) {
            width: 367px;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: #333333;
            white-space: pre-line;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        height: 38px;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding-left: 10px;

        &:hover {
          background: #f7f7f7;
        }

        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          background: #3489ff;
          border-radius: 50%;
          left: -1px;
        }

        li:nth-of-type(1) {
          width: 530px;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        li:nth-of-type(2) {
          // width: 68px;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }

        li:nth-of-type(3) {
          p:nth-of-type(2) {
            white-space: pre-line;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    // .line {
    //   width: 1px;
    //   height: 244px;
    //   border: 1px dashed #f0f0f0;
    // }
    //最新动态
    .newInfo {
      width: 398px;
      box-sizing: border-box;
      padding: 16px 16px 23px 16px;
      background: #fff;
      border-radius: 8px;

      .titleClass {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:nth-of-type(1) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
        }

        span:nth-of-type(2) {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
      }

      ul {
        display: flex;
        height: 44px;
        align-items: center;
        position: relative;
        margin-top: 17px;
        background: rgba(238, 246, 255, 1);
        border-radius: 4px;
        padding: 10px;

        li:nth-of-type(1) {
          div {
            width: 50px;
            height: 50px;

            img {
              width: 50px;
              height: 50px;
              border-radius: 4px;
            }
          }
        }

        li:nth-of-type(2) {
          flex: 1;
          overflow: hidden;
          margin-left: 20px;
          .top,
          .bottom {
            display: flex;
            align-items: center;
          }
          .top {
            p:nth-of-type(1) {
              flex: 1;
              overflow: hidden;
              color: #333333;
              margin-bottom: 6px;
              margin-right: 10px;
              font-size: 16px;
            }
            p:nth-of-type(2) {
              color: #999999;
              margin-bottom: 6px;
              font-size: 14px;
            }
          }

          .bottom {
            p:nth-of-type(1) {
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              color: #fff;
              text-align: center;
              line-height: 20px;
              background: rgb(135,158,184);
              padding: 0 6px;
              margin-right: 10px;
            }
            p:nth-of-type(2) {
              flex: 1;
              overflow: hidden;
              font-size: 16px;
              color: #3489ff;
            }
          }
        }
      }
    }
  }
}
</style>