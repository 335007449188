import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  postsidLogin,
} from '@/api/researchApi'
import store from '../utils/store.js'
import Home from '../views/Home.vue'
import ResearchLive from '../views/researchCenter/researchLive/ResearchLive.vue'
import NoticeList from '../views/index/NoticeList.vue'
import NoticeDetail from '../views/index/NoticeDetail.vue'
import CreateLive from '../views/researchCenter/researchLive/CreateLive.vue'
import LiveDetail from '../views/researchCenter/researchLive/LiveDetail.vue'
import ResearchActivity from '../views/researchCenter/researchActivity/ResearchActivity.vue'
import CreateActivity from '../views/researchCenter/researchActivity/CreateActivity.vue'
import ActivityDetail from '../views/researchCenter/researchActivity/ActivityDetail.vue'
import BasicInfo from '../views/researchCenter/researchActivity/BasicInfo.vue'
import ResourceBrowsing from '../views/researchCenter/researchActivity/ResourceBrowsing.vue'
import QuestionBrowsing from '../views/researchCenter/researchActivity/QuestionBrowsing.vue'
import ResourceuploadBrowsing from '../views/researchCenter/researchActivity/ResourceuploadBrowsing.vue'
import VoteBrowsing from '../views/researchCenter/researchActivity/VoteBrowsing.vue'
import CollaborationBrowsing from '../views/researchCenter/researchActivity/CollaborationBrowsing.vue'
import LiveBrowsing from '../views/researchCenter/researchActivity/LiveBrowsing.vue'
import ReupBrowsing from '../views/researchCenter/researchActivity/ReupBrowsing.vue'
import CreateCource from '../views/researchCenter/researchCourse/CreateCource.vue'
import GoClassSteptwo from '../views/researchCenter/researchCourse/GoClassSteptwo.vue'
import GoClassDetail from '../views/researchCenter/researchCourse/GoClassDetail.vue'
// 修改后
// import ListeningComment from '../views/researchCenter/researchCourse/ListeningComment.vue'
import ListeningCommentDetail from '../views/researchCenter/researchCourse/ListeningCommentDetail.vue'
import PrepareLessons from '../views/researchCenter/researchCourse/PrepareLessons.vue'
import AddPaper from '../views/researchCenter/researchCourse/AddPaper.vue'
import GroupIndex from '../views/teachingGroup/GroupIndex.vue'
import GroupDetail from '../views/teachingGroup/GroupDetail.vue'
import GroupDetailShouye from '../views/teachingGroup/GroupDetailShouye.vue'
import GroupDetailLive from '../views/teachingGroup/GroupDetailLive.vue'
import GroupDetailActivity from '../views/teachingGroup/GroupDetailActivity.vue'
import GroupDetailResult from '../views/teachingGroup/GroupDetailResult.vue'
import GroupDetailTalk from '../views/teachingGroup/GroupDetailTalk.vue'
import GroupDetailMember from '../views/teachingGroup/GroupDetailMember.vue'
import EditLive from '../views/researchCenter/researchLive/EditLive.vue'
// import LiveRoom from '../views/researchCenter/researchLive/LiveRoom.vue'
import DiscussBrowsing from '../views/researchCenter/researchActivity/DiscussBrowsing.vue'
import ResearchCource from '../views/researchCenter/researchCourse/ResearchCource.vue'
import AddSection from '../views/researchCenter/researchCourse/AddSection.vue'
//研修视频
import GroupDetailCourse from '../views/teachingGroup/GroupDetailCourse.vue'
//研修视频创建课程
import CreteCoursePage from "../views/teachingGroup/GroupCreate/GroupCreteCourse.vue"
//创建研修直播
import CreateGroupNewLive from "../views/teachingGroup/GroupCreate/GroupCreateLive.vue"
//修改直播信息
import UpdateLiveInfo from "../views/teachingGroup/GroupCreate/InForChange/ChangeLiveCom.vue"
//集中备课
import NextFocusCourse from "../views/teachingGroup/GroupCreate/InForChange/NextFocusCourse.vue"

//个人中心路由
import Personal from "../views/personal/personal_index.vue"

//个人中心主页
import PerSonIndex from "../components/personalCom/personalIndex.vue"
// 个人中心子路由
import Research from "../components/personalCom/research.vue"
import Live from "../components/personalCom/live.vue"
import Activity from "../components/personalCom/activity.vue"
import Course from "../components/personalCom/course.vue"
import Test from "../components/personalCom/test.vue"
import TestPaper from "../components/personalCom/testPaper.vue"
import Conference from "../components/personalCom/conference.vue"
import MyTopic from "../components/personalCom/myTopic.vue"
import Message from "../components/personalCom/message.vue"
import Resource from "../components/personalCom/resource.vue"
//跳转页面
// 创建教研
import CreateResearch from "../components/personChildPage/createResearch.vue"
//创建话题
import CreateMyTopic from "../components/personChildPage/createMyTopic.vue"
// 查看会议详情
import DetailConference from "../components/personChildPage/detailConference.vue"
// 上传资源
import UploadResource from "../components/personChildPage/UploadResource.vue"
//创建课程
import CreateCourse from "../components/personChildPage/createCourse.vue"

//活动详情
import ActivityDetails from "../components/personChildPage/activityDetail.vue"

//创建试题
import CreateTest from "../components/personChildPage/createTest.vue"
//编辑个人信息
import PersonInfo from "../components/personalCom/personInfo.vue"
//更换手机
import ReplaceMobile from "../components/personalCom/replaceMobile.vue"
// 创建直播
import NewLive from "../components/personChildPage/newLive.vue"
// 直播详情
import LiveBroadcast from "../components/personChildPage/liveBroadcast.vue"

//研讨社区主页

import CommunityDetail from "../components/Community/CommunityDetail.vue"

//话题详情页
import TopicDetails from "../components/Community/TopicDetails.vue"

//发起话题
import SendTopic from "../components/Community/SendTopic.vue"

//修改密码 
import ChangePwd from "../components/personalCom/changePwd.vue"
//主页路由
import Index from "../views/index/Index.vue"

//手工组卷
import Manually from "../views/personal/manually.vue"
//发起会议
import SendConference from "../components/personChildPage/sendConference.vue"
//教研组审核成员页面
import GroupDetailExamine from "@/views/teachingGroup/GroupDetailExamine.vue"
// 教研活动直播页面
import LiveBrowsingRoom from "../views/researchCenter/researchActivity/LiveBrowsingRoom"
//听评课详情页
import NextListingCourse from "../views/teachingGroup/GroupCreate/InForChange/NextListingCourse.vue"
//三大模块课程
import NextThree from "../views/teachingGroup/GroupCreate/InForChange/NextThreeCourse.vue"
//修改课程信息
import UpdataCourseInfo from "../views/teachingGroup/GroupCreate/InForChange/ChangeCourseCom.vue"
//线下听评课下一步
import NextListingCourseOff from "../views/teachingGroup/GroupCreate/InForChange/NextListingoffline.vue"
//线上听评课
import NextListingOnline from "../views/teachingGroup/GroupCreate/InForChange/NextListingOnline.vue"


//创建问卷/投票
import CreateProblem from "../views/teachingGroup/createProblem/createProblem.vue"
//测试页面
import ceshi from "@/views/ceshi/ceshi.vue"
// 教研活动教研会议环节页面
import MeetBrowsing from "@/views/researchCenter/researchActivity/MeetBrowsing.vue"
//修改资源

import UpdataResource from "../components/personChildPage/updateResource.vue"

// ----新增
import LiveGuideDetail from "@/views/researchCenter/researchLive/LiveGuideDetail.vue"
//直播页面
import MyLive from "@/views/Live/MyLive.vue"

//四名工作室列表
import StudioList from '@/views/MasterStudio/StudioList.vue'

//四名首页
import MsHome from '@/views/MasterStudio/msFile/msHome.vue'

//四名活动
import MsActivity from '@/views/MasterStudio/msFile/msActivity.vue'

//四名伴我行
import MsWelfare from '@/views/MasterStudio/msFile/msWelfare.vue'

//四名风采
import MsElegant from '@/views/MasterStudio/msFile/msElegant.vue'

//四名活动详情
import MsDetails from '@/views/MasterStudio/msFile/msAllDetails/msDetails.vue'

//活动直播详情
import MsLiveDetails from '@/views/MasterStudio/msFile/msAllDetails/msLiveDetails.vue'

//活动直播播放
import MsLiveLook from '@/views/MasterStudio/msFile/msAllDetails/msLiveLook.vue'

//活动风采详情
import MsElegDetails from '@/views/MasterStudio/msFile/msAllDetails/msElegDetails.vue'

//活动风采详情
import MsAdvanceDetails from '@/views/MasterStudio/msFile/msAllDetails/msAdvanceDetails.vue'

//优质原创详情
import weChatDetails from '@/views/MasterStudio/msFile/msAllDetails/weChatDetails.vue'

// ----------------------------

import serviceEight from '@/views/MasterStudio/msFile/serviceEight/index.vue'
import serveTab1 from '@/views/MasterStudio/msFile/serviceEight/serveTab1.vue'
import serveTab2 from '@/views/MasterStudio/msFile/serviceEight/serveTab2.vue'
import serveTab3 from '@/views/MasterStudio/msFile/serviceEight/serveTab3.vue'
import serveTab4 from '@/views/MasterStudio/msFile/serviceEight/serveTab4.vue'
import serveTab5 from '@/views/MasterStudio/msFile/serviceEight/serveTab5.vue'
import serveTab6 from '@/views/MasterStudio/msFile/serviceEight/serveTab6.vue'
import serveTab7 from '@/views/MasterStudio/msFile/serviceEight/serveTab7.vue'
import serveTab8 from '@/views/MasterStudio/msFile/serviceEight/serveTab8.vue'


//活动直播列表
import ShenBao from '@/views/index/ShenBao.vue'

// ----------------------------
//四名活动列表
import msSctiList from '@/views/MasterStudio/msFile/msAllList/msSctiList.vue'
import searchList from '@/views/MasterStudio/msFile/msAllList/searchList.vue'

//活动直播列表
import MsLiveList from '@/views/MasterStudio/msFile/msAllList/msLiveList.vue'

//活动直播列表
import msLiveTopic from '@/views/MasterStudio/msFile/msAllList/msLiveTopic.vue'

//更多优质原创
import weChatList from '@/views/MasterStudio/msFile/msAllList/weChatList.vue'

//活动伴我行列表
import MsAdvanceList from '@/views/MasterStudio/msFile/msAllList/msAdvanceList.vue'

//活动风采列表
import MsElegList from '@/views/MasterStudio/msFile/msAllList/msElegList.vue'

// -----------------------------

//四名工作室首页
import StudioIndex from '@/views/MasterStudio/StudioIndex.vue'

//工作室公告页面
import noticeIndex from '@/views/MasterStudio/workNotice/noticeIndex.vue'

//话题研讨页面
import TopicsIndex from '@/views/MasterStudio/Topics/TopicsIndex.vue'

//教师文章
import ArticleIndex from '@/views/MasterStudio/TeachArticle/ArticleList.vue'

//教师文章
import ReportIndex from '@/views/MasterStudio/TeachReport/ReportIndex.vue'

//研修资源
import TrainResIndex from '@/views/MasterStudio/TrainRes/TrainResIndex.vue'


//四名工作室四名讲堂
import TeachIndex from "@/views/MasterStudio/TeachLecture/TeachIndex.vue"

//四名工作室教研活动
import TrainActivityIndex from "@/views/MasterStudio/TrainActivity/TrainActivity.vue"

//四名工作首页
import TeachLectureIndex from "@/views/MasterStudio/TeachIndex/TeachIndex.vue"

//四名工作室公告详情
import noticeDetail from '@/views/MasterStudio/workNotice/noticeDetail.vue'

//四名工作室荣誉证书列表
import Certificate from '@/views/MasterStudio/Certificate/CertificateIndex.vue'

//添加四名工作室荣誉证书
import AddCertificate from '@/views/MasterStudio/Certificate/AddCertificates.vue'


//四名工作室荣誉证书详情页
import CertificateDetail from '@/views/MasterStudio/Certificate/CertificateDetail.vue'

//四名工作室四名简介

import WorkRoomInduction from '@/views/MasterStudio/StudioIntroduction.vue'

import memberList from '@/views/MasterStudio/memberList.vue'

//四名工作室话题管理中话题详情
import TopicComs from '@/views/StudioAdmin/TopicManageDetail.vue'

//教研组成员审核直播活动课程通用
import GroupTearmAudit from '@/views/teachingGroup/AuditTearm.vue'


//活动直播
import TeachingActivityLive from '@/views/researchCenter/researchActivity/ActivetyLiveRoom.vue'

// 文件预览
import fileViewPage from '@/views/fileViewPage/fileViewPage.vue';

Vue.use(VueRouter)
const routes = [
  //四名工作室后台直播详情
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@/views/StudioAdmin/transfer.vue'),
  },

  // 主页
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect: {
    //   path: '/index',
    // },
    children: [

      // 首页模块
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/Index.vue'),
      },

      //活动直播
      {
        path: '/ActivityLive',
        name: 'ActivityLive',
        component: TeachingActivityLive,
      },


      //四名工作室后台直播详情
      {
        path: '/AdminLiveDetail',
        name: 'AdminLiveDetail',
        component: () => import('@/views/StudioAdmin/LiveDetail.vue'),
      },
      //四名工作室列表
      {
        path: '/StudioList',
        name: 'StudioList',
        component: StudioList
      },
      //四名首页
      {
        path: '/MsHome',
        name: 'MsHome',
        component: MsHome
      },
      //四名活动
      {
        path: '/MsActivity',
        name: 'MsActivity',
        component: MsActivity
      },
      //四名伴我行
      {
        path: '/MsWelfare',
        name: 'MsWelfare',
        component: MsWelfare
      },
      //四名风采
      {
        path: '/MsElegant',
        name: 'MsElegant',
        component: MsElegant
      },
      //四名活动详情
      {
        path: '/MsDetails',
        name: 'MsDetails',
        component: MsDetails
      },
      //四名直播详情
      {
        path: '/MsLiveDetails',
        name: 'MsLiveDetails',
        component: MsLiveDetails
      },
      //四名直播详情
      {
        path: '/MsLiveLook',
        name: 'MsLiveLook',
        component: MsLiveLook
      },
      //四名直播详情
      {
        path: '/MsElegDetails',
        name: 'MsElegDetails',
        component: MsElegDetails
      },
      //四名预告详情
      {
        path: '/MsAdvanceDetails',
        name: 'MsAdvanceDetails',
        component: MsAdvanceDetails
      },
      //优质原创详情
      {
        path: '/weChatDetails',
        name: 'weChatDetails',
        component: weChatDetails
      },
      //四名活动列表
      {
        path: '/ShenBao',
        name: 'ShenBao',
        component: ShenBao
      },
      //四名活动列表
      {
        path: '/msSctiList',
        name: 'msSctiList',
        component: msSctiList
      },
      {
        path: '/searchList',
        name: 'searchList',
        component: searchList
      },
      //活动直播列表
      {
        path: '/MsLiveList',
        name: 'MsLiveList',
        component: MsLiveList
      },
      //活动直播列表
      {
        path: '/msLiveTopic',
        name: 'msLiveTopic',
        component: msLiveTopic
      },
      //更多优质原创
      {
        path: '/weChatList',
        name: 'weChatList',
        component: weChatList
      },
      //活动伴我行列表
      {
        path: '/MsAdvanceList',
        name: 'MsAdvanceList',
        component: MsAdvanceList
      },
      //活动风采列表
      {
        path: '/MsElegList',
        name: 'MsElegList',
        component: MsElegList
      },

      //四名工作室列表
      {
        path: '/StudioListOld',
        name: 'StudioListOld',
        component: () => import('@/views/MasterStudio/StudioListOld.vue'),
      },

      // --------------------------
      //八大服务
      {
        path: '/serviceEight',
        name: 'serviceEight',
        component: serviceEight
      },
      {
        path: '/serviceEight/serveTab1',
        name: 'serveTab1',
        component: serveTab1
      },
      {
        path: '/serviceEight/serveTab2',
        name: 'serveTab2',
        component: serveTab2
      },
      {
        path: '/serviceEight/serveTab3',
        name: 'serveTab3',
        component: serveTab3
      },
      {
        path: '/serviceEight/serveTab4',
        name: 'serveTab4',
        component: serveTab4
      },
      {
        path: '/serviceEight/serveTab5',
        name: 'serveTab5',
        component: serveTab5
      },
      {
        path: '/serviceEight/serveTab6',
        name: 'serveTab6',
        component: serveTab6
      },
      {
        path: '/serviceEight/serveTab7',
        name: 'serveTab7',
        component: serveTab7
      },
      {
        path: '/serviceEight/serveTab8',
        name: 'serveTab8',
        component: serveTab8
      },
      // --------------------------
      //四名工作室后台(路由需要改动)
      //资源详情
      {
        path: '/resourcedetail',
        name: 'ResourceDetail',
        component: () => import('@/views/StudioAdmin/ResourceDetail.vue'),
      },
      // 协作文档详情
      {
        path: '/collaborationworddetail',
        name: 'CollaborationWordDetail',
        component: () => import('../views/researchCenter/researchActivity/CollaborationWordDetail.vue'),
      },
      // 编辑在线文档详情
      {
        path: '/editorworddetail',
        name: 'EditorWordDetail',
        component: () => import('../views/researchCenter/researchActivity/EditorWordDetail.vue'),
      },

      // 编辑在线文档详情
      {
        path: '/addmsShuCai',
        name: 'addmsShuCai',
        component: () => import('@/views/StudioAdmin/msPages/addmsShuCai.vue'),
      },
      // 编辑在线文档详情
      {
        path: '/lookChatDetails',
        name: 'lookChatDetails',
        component: () => import('@/views/StudioAdmin/msPages/lookChatDetails.vue'),
      },

      //四名工作室后台
      {
        path: '/StudioAdminIndex',
        name: 'StudioAdminIndex',
        component: () => import('../views/StudioAdmin/StudioAdminIndex.vue'),
        redirect: '/AdminHome',
        children: [{
            path: '/AdminHome',
            name: 'AdminHome',
            component: () => import('@/views/StudioAdmin/AdminHome.vue'),
          },
          //话题详情

          {
            path: '/TopicManageDetail',
            name: 'TopicManageDetail',
            component: TopicComs,
          },
          //荣誉证书详情
          {
            path: '/HonorDetail',
            name: 'HonorDetail',
            component: () => import('@/views/StudioAdmin/HonorDetail.vue'),
          },

          //创建活动
          {
            path: '/CreateActivity',
            name: 'CreateActivity',
            component: () => import('@/views/activityStudy/create/create.vue'),
          },
          {
            path: '/BaseInfo',
            name: 'BaseInfo',
            component: () => import('@/views/StudioAdmin/BaseInfo.vue'),
          },
          //成员管理
          {
            path: '/TearmAdmin',
            name: 'TearmAdmin',
            component: () => import('@/views/StudioAdmin/TearmAdmin.vue'),
          },
          //课程评论管理
          {
            path: '/CourseDiscuss',
            name: 'CourseDiscuss',
            component: () => import('@/views/StudioAdmin/CourseDiscuss.vue'),
          },
          //工作室绩效考核
          {
            path: '/performancePage',
            name: 'performancePage',
            component: () => import('@/views/performancePage/performancePage.vue'),
          },

          //公众号素材管理
          {
            path: '/msShuCai',
            name: 'msShuCai',
            component: () => import('@/views/StudioAdmin/msPages/msShuCai.vue'),
          },

          //公众号绑定管理
          {
            path: '/msBangDing',
            name: 'msBangDing',
            component: () => import('@/views/StudioAdmin/msPages/msBangDing.vue'),
          },

          // 编辑在线文档详情
          {
            path: '/addmsBangDing',
            name: 'addmsBangDing',
            component: () => import('@/views/StudioAdmin/msPages/addmsBangDing.vue'),
          },

          //成员审核
          {
            path: '/TearmAudit',
            name: 'TearmAudit',
            component: () => import('@/views/StudioAdmin/TearmAudit.vue'),
          },
          //公告管理审核
          {
            path: '/NoticeManage',
            name: 'NoticeManage',
            component: () => import('@/views/StudioAdmin/NoticeManage.vue'),
          },
          //发布公告
          {
            path: '/PushNotice',
            name: 'PushNotice',
            component: () => import('@/views/StudioAdmin/PushNotice.vue'),
          },
          //直播课管理
          {
            path: '/LiveManage',
            name: 'LiveManage',
            component: () => import('@/views/StudioAdmin/LiveManage.vue'),
          },
          //创建直播课管理
          {
            path: '/CreateLive',
            name: 'CreateLive',
            component: () => import('@/views/StudioAdmin/CreateLive.vue'),
          },
          //录播课管理
          {
            path: '/RecordManage',
            name: 'RecordManage',
            component: () => import('@/views/StudioAdmin/RecordManage.vue'),
          },
          //创建录播课
          {
            path: '/CreateRecord',
            name: 'CreateRecord',
            component: () => import('@/views/StudioAdmin/CreateRecord.vue'),
          },
          //文章列表
          {
            path: '/ArticleList',
            name: 'ArticleList',
            component: () => import('@/views/StudioAdmin/ArticleList.vue'),
          },
          //文章列表
          {
            path: '/AddArticleSet',
            name: 'AddArticleSet',
            component: () => import('@/views/MasterStudio/TeachArticle/PushArticle.vue'),
          },
          // 活动报道管理
          {
            path: '/ReportList',
            name: 'ReportList',
            component: () => import('@/views/StudioAdmin/ReportList.vue'),
          },
          // 活动报道管理
          {
            path: '/addReportIndexSet',
            name: 'addReportIndexSet',
            component: () => import('@/views/MasterStudio/TeachReport/addReportIndex.vue'),
          },
          //文章审核详情
          {
            path: '/ArticleAudit',
            name: 'ArticleAudit',
            component: () => import('@/views/StudioAdmin/ArticleAudit.vue'),
          },
          //文章类型
          {
            path: '/ArticleType',
            name: 'ArticleType',
            component: () => import('@/views/StudioAdmin/ArticleType.vue'),
          },
          //话题管理
          {
            path: '/TopicManage',
            name: 'TopicManage',
            component: () => import('@/views/StudioAdmin/TopicManage.vue'),
          },
          //研究成果管理
          {
            path: '/ResearchAchievement',
            name: 'ResearchAchievement',
            component: () => import('@/views/StudioAdmin/ResearchAchievement.vue'),
          },
          //研究成果编辑
          {
            path: '/ResearchAchievementEdit',
            name: 'ResearchAchievementEdit',
            component: AddCertificate,
          },
          //研究课题管理
          {
            path: '/ResearchTopic',
            name: 'ResearchTopic',
            component: () => import('@/views/StudioAdmin/ResearchTopic.vue'),
          },
          //研究课题编辑
          {
            path: '/ResearchTopicEdit',
            name: 'ResearchTopicEdit',
            component: AddCertificate,
          },
          //荣誉证书管理
          {
            path: '/HonorManage',
            name: 'HonorManage',
            component: () => import('@/views/StudioAdmin/HonorManage.vue'),
          },
          //荣誉证书管理
          {
            path: '/AddCertificateSet',
            name: 'AddCertificateSet',
            component: () => import('@/views/MasterStudio/Certificate/AddCertificates.vue'),
          },
          //文章评论管理
          {
            path: '/ArticleDiscuss',
            name: 'ArticleDiscuss',
            component: () => import('@/views/StudioAdmin/ArticleDiscuss.vue'),
          },
          //资源文件夹管理
          {
            path: '/ResourceManage',
            name: 'ResourceManage',
            component: () => import('@/views/StudioAdmin/ResourceManage.vue'),
          },
          //资源列表
          {
            path: '/ResourceList',
            name: 'ResourceList',
            component: () => import('@/views/StudioAdmin/ResourceList.vue'),
          },
          {
            path: '/uploadResSet',
            name: 'uploadResSet',
            component: () => import('@/views/MasterStudio/TrainRes/uploadRes.vue'),
          },
          //教研活动管理
          {
            path: '/ActivityManage',
            name: 'ActivityManage',
            component: () => import('@/views/StudioAdmin/ActivityManage.vue'),
          },
          //教研活动编辑
          {
            path: '/PushActivity',
            name: 'PushActivity',
            component: () => import('@/views/activityStudy/create/create.vue'),
          },
        ]
      },
      //四名工作室首页
      {
        path: '/StudioIndex',
        name: 'StudioIndex',
        component: () => import('@/views/MasterStudio/StudioIndex.vue'),
        redirect: '/TeachLectureIndex',
        children: [
          //四名工作是首页
          {
            path: '/TeachLectureIndex',
            name: 'TeachLectureIndex',
            component: TeachLectureIndex,
            // meta: {
            //     requireAuth: true //需要登录
            // }
          },

          //上传资源
          {
            path: '/uploadRes',
            name: 'uploadRes',
            component: () => import('@/views/MasterStudio/TrainRes/uploadRes.vue'),
          },
          //添加文章
          {
            path: '/AddArticle',
            name: 'AddArticle',
            component: () => import('@/views/MasterStudio/TeachArticle/PushArticle.vue'),
          },
          //文章详情
          {
            path: '/ArticleDetail',
            name: 'ArticleDetail',
            component: () => import('@/views/MasterStudio/TeachArticle/ArticleDetail.vue'),
          },

          //添加活动报道
          {
            path: '/addReportIndex',
            name: 'addReportIndex',
            component: () => import('@/views/MasterStudio/TeachReport/addReportIndex.vue'),
          },
          //活动报道详情
          {
            path: '/ReportDetail',
            name: 'ReportDetail',
            component: () => import('@/views/MasterStudio/TeachReport/ReportDetail.vue'),
          },

          //四名工作室简介
          {
            path: '/WorkRoomInduction',
            name: 'WorkRoomInduction',
            component: WorkRoomInduction,
          },
          {
            path: '/memberList',
            name: 'memberList',
            component: memberList,
          },
          //四名工作室荣誉证书列表
          {
            path: '/Certificate',
            name: 'Certificate',
            component: Certificate,
          },

          //荣誉证书详情
          {
            path: '/CertificateDetail',
            name: 'CertificateDetail',
            component: CertificateDetail,
          },

          //四名工作室添加荣誉证书
          {
            path: '/AddCertificate',
            name: 'AddCertificate',
            component: AddCertificate,
          },


          //工作室公告
          {
            path: '/noticeIndex',
            name: 'noticeIndex',
            component: noticeIndex,
          },
          //工作室公告详情
          {
            path: '/noticeDetail',
            name: 'noticeDetail',
            component: noticeDetail,
          },
          //话题研讨
          {
            path: '/TopicsIndex',
            name: 'TopicsIndex',
            component: TopicsIndex,
          },


          //添加话题
          {
            path: '/AddTopic',
            name: 'AddTopic',
            component: () => import('@/views/MasterStudio/Topics/sendTopic.vue'),
          },

          //话题详情
          {
            path: '/TopicDetail',
            name: 'TopicDetail',
            component: () => import('@/views/MasterStudio/Topics/TopicDetail.vue'),
          },

          //教师文章
          {
            path: '/ArticleIndex',
            name: 'ArticleIndex',
            component: ArticleIndex,
          },
          //活动报道
          {
            path: '/ReportIndex',
            name: 'ReportIndex',
            component: ReportIndex,
          },
          //研修资源
          {
            path: '/TrainResIndex',
            name: 'TrainResIndex',
            component: TrainResIndex,
          },
          //四名讲堂
          {
            path: '/TeachIndex',
            name: 'TeachIndex',
            component: TeachIndex,
          },
          {
            path: '/CreateLiveSet',
            name: 'CreateLiveSet',
            component: () => import('@/views/StudioAdmin/CreateLive.vue'),
          },
          //创建录播课
          {
            path: '/CreateRecordSet',
            name: 'CreateRecordSet',
            component: () => import('@/views/StudioAdmin/CreateRecord.vue'),
          },
          {
            path: '/updataCourseInfoSet',
            name: 'updataCourseInfoSet',
            component: UpdataCourseInfo
          },
          //四名讲堂
          {
            path: '/TrainActivityIndex',
            name: 'TrainActivityIndex',
            component: TrainActivityIndex,
          },
          {
            path: '/TrainActivityIndexSet',
            name: 'TrainActivityIndexSet',
            component: () => import('@/views/activityStudy/create/create.vue'),
          },
        ]
      },

      //新增跳转推流详情
      {
        path: '/liveGuideDetail',
        name: 'liveGuideDetail',
        component: LiveGuideDetail
      },
      //新增直播页面
      {
        path: '/MyLive',
        name: 'MyLive',
        component: MyLive
      },

      //创建研修视频
      {
        path: '/groupCreteCourse',
        name: 'groupCreteCourse',
        component: CreteCoursePage
      },
      //创建研修直播
      {
        path: '/createGroupNewLive',
        name: 'createGroupNewLive',
        component: CreateGroupNewLive
      },
      //听评课线上
      {
        path: '/nextListingOnline',
        name: '/nextListingOnline',
        component: NextListingOnline
      },
      //听评课线下
      {
        path: '/nextListingCourseOff',
        name: '/nextListingCourseOff',
        component: NextListingCourseOff
      },
      //创建问卷/投票
      {
        path: '/createProblem',
        name: 'createProblem',
        component: CreateProblem
      },
      //修改课程信息
      {
        path: '/updataCourseInfo',
        name: 'updataCourseInfo',
        component: UpdataCourseInfo
      },
      //三大模块
      {
        path: '/nextThree',
        name: 'nextThree',
        component: NextThree
      },
      //听评课
      {
        path: '/nextListingCourse',
        name: 'nextListingCourse',
        component: NextListingCourse
      },
      //集中备课
      {
        path: '/nextFocusCourse',
        name: 'nextFocusCourse',
        component: NextFocusCourse
      },
      //修改直播信息
      {
        path: '/updateLiveInfo',
        name: 'updateLiveInfo',
        component: UpdateLiveInfo
      },
      //主页子路由(维护注释掉)
      {
        path: '/researchCenter',
        name: 'researchCenter',
        component: () => import('@/views/researchCenter/researchCenter.vue'),
      },
      // 工作室绩效考核
      {
        path: '/performanceDetails',
        name: 'performanceDetails',
        component: () => import('@/views/performancePage/performanceDetails.vue'),
      },
      { //   手工组卷
        path: '/manually',
        name: 'manually',
        component: Manually
      },
      { //   发起话题
        path: '/sendTopic',
        name: 'sendTopic',
        component: SendTopic
      },
      //   话题详情页
      {
        path: '/topicDetails',
        name: 'topicDetails',
        // meta: {
        //     requireAuth: true, //判断是否需要登录
        // },
        component: TopicDetails
      },
      //研讨社区主页
      {
        //直播详情
        path: '/community',
        name: 'community',
        component: CommunityDetail
      },
      {
        //上传资源
        path: '/uploadResource',
        name: 'uploadResource',
        component: UploadResource
      },
      {
        //创建直播
        path: '/newLive',
        name: 'newLive',
        component: NewLive
      },
      {
        //直播详情
        path: '/liveBroadcast',
        name: 'liveBroadcast',
        component: LiveBroadcast
      },

      {
        //创建试题
        path: '/createTest',
        name: 'createTest',
        component: CreateTest
      },
      {
        //创建教研
        path: '/createResearch',
        name: 'createResearch',
        component: CreateResearch
      },
      {
        //创建话题
        path: '/createMyTopic',
        name: 'createMyTopic',
        component: CreateMyTopic
      },
      {
        //创建课程
        path: '/createCourse',
        name: 'CreateCourse',
        component: CreateCourse
      },
      // {
      //   //创建活动
      //   path: '/newActivity',
      //   name: 'newActivity',
      //   component: newActivity
      // },
      {
        //创建活动
        path: '/newActivity',
        name: 'newActivity',
        component: () => import('@/views/activityStudy/create/create.vue'),
      },
      {
        //活动详情
        path: '/activityDetails',
        name: 'activityDetails',
        component: ActivityDetails
      },


      // 个人中心
      {
        path: "/personal",
        name: "personal",
        component: Personal,
        children: [
          //四名讲堂
          {
            path: '/MasterStudio',
            name: '/MasterStudio',
            component: () => import('@/views/personal/MasterStudio.vue')
          },
          // 修改资源
          {
            path: '/updataResource',
            name: '/updataResource',
            component: UpdataResource
          },
          {
            //发起会议
            path: '/perSonIndex',
            name: 'perSonIndex',
            component: PerSonIndex
          },
          {
            //发起会议
            path: '/sendConference',
            name: 'sendConference',
            component: SendConference
          },
          {
            //会议详情
            path: '/detailConference',
            name: 'detailConference',
            component: DetailConference
          },
          //  修改密码
          {
            path: "/changePwd",
            name: "changePwd",
            component: ChangePwd
          },
          //  更换手机
          {
            path: "/replaceMobile",
            name: "replaceMobile",
            component: ReplaceMobile
          },
          //编辑个人信息
          {
            path: "/personInfo",
            name: "personInfo",
            component: PersonInfo
          },
          //  教研
          {
            path: "/research",
            name: "research",
            component: Research
          },
          // 我的四名工作室
          {
            path: '/teacherstudio',
            name: '/TeacherStudio',
            component: () => import('../components/personalCom/TeacherStudio.vue')
          },
          // 四名工作室证书详情
          {
            path: '/pscertificatedetail',
            name: 'CertificateDetail',
            component: () => import('../views/MasterStudio/Certificate/CertificateDetail.vue')
          },
          //直播
          {
            path: "/live",
            name: "live",
            component: Live
          },
          // 活动
          {
            path: "/activity",
            name: "activity",
            component: Activity
          },
          //  课程
          {
            path: "/course",
            name: "course",
            component: Course
          },
          //  资源
          {
            path: "/resource",
            name: "resource",
            component: Resource
          },
          //  试题库
          {
            path: "/test",
            name: "test",
            component: Test
          },
          //  试卷库
          {
            path: "/testPaper",
            name: "testPaper",
            component: TestPaper
          },
          //  会议
          {
            path: "/conference",
            name: "conference",
            component: Conference
          },
          //  话题
          {
            path: "/myTopic",
            name: "myTopic",
            component: MyTopic
          },
          // 消息
          {
            path: "/message",
            name: "message",
            component: Message
          },
          // 我的文章
          {
            path: "/TeachingPersonalArticle",
            name: "TeachingPersonalArticle",
            component: () => import('@/components/personalCom/TeachingPersonalArticle.vue')
          },
          // 我的证书
          {
            path: "/TeachingPersonalCertificate",
            name: "TeachingPersonalCertificate",
            component: () => import('@/components/personalCom/TeachingPersonalCertificate.vue')
          },
          // 我的收藏
          {
            path: "/TeachingPersonalCollect",
            name: "TeachingPersonalCollect",
            component: () => import('@/components/personalCom/TeachingPersonalCollect.vue')
          },
        ]
      },
      //  个人中心end
      //  修改主页子路由
      {
        path: '/researchlive',
        name: 'researchlive',
        component: ResearchLive
      },
      {
        path: '/noticelist',
        name: 'noticelist',
        component: NoticeList
      },
      //首页公告详情
      {
        path: '/NoticesDetail',
        name: '/NoticesDetail',
        component: NoticeDetail
      },
      {
        path: '/createlive',
        name: 'createlive',
        component: CreateLive
      },

      //直播视频详情
      {
        path: '/livedetail',
        name: 'livedetail',
        // meta: {
        //     requireAuth: true, //判断是否需要登录
        // },
        component: LiveDetail
      },



      {
        path: '/researchactivity',
        name: 'researchactivity',
        component: ResearchActivity
      },
      // {
      //   path: '/createactivity',
      //   name: 'createactivity',
      //   component: CreateActivity
      // },

      //活动详情
      // 修改后子路由的子路由
      // {
      //   path: '/activitydetail',
      //   name: 'activitydetail',
      //   component: ActivityDetail,
      //   children: [{
      //       path: '/basicinfo',
      //       name: 'basicinfo',
      //       // meta: {
      //       //     requireAuth: true, //判断是否需要登录
      //       // },
      //       component: BasicInfo
      //     },
      //     {
      //       path: '/resourcebrowsing',
      //       name: 'resourcebrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: ResourceBrowsing
      //     },
      //     {
      //       path: '/questionbrowsing',
      //       name: 'questionbrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: QuestionBrowsing
      //     },
      //     {
      //       path: '/resourceuploadbrowsing',
      //       name: 'resourceuploadbrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: ResourceuploadBrowsing
      //     },
      //     {
      //       path: '/votebrowsing',
      //       name: 'votebrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: VoteBrowsing
      //     },
      //     {
      //       path: '/collaborationbrowsing',
      //       name: 'collaborationbrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: CollaborationBrowsing
      //     },
      //     {
      //       path: '/livebrowsing',
      //       name: 'livebrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: LiveBrowsing
      //     },
      //     {
      //       path: '/reupbrowsing',
      //       name: 'reupbrowsing',
      //       meta: {
      //         requireAuth: true, //判断是否需要登录
      //       },
      //       component: ReupBrowsing
      //     },
      //     {
      //       path: '/discussbrowsing',
      //       name: 'discussbrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: DiscussBrowsing
      //     },
      //     {
      //       path: '/meetbrowsing',
      //       name: 'meetbrowsing',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: MeetBrowsing
      //     },
      //     // 在线文档环节
      //     {
      //       path: '/onlineword',
      //       name: 'OnLineWord',
      //       meta: {
      //         //requireAuth: true, //判断是否需要登录
      //       },
      //       component: () => import("@/views/researchCenter/researchActivity/OnLineWord.vue")
      //     }
      //   ]
      // },



      //home子路由
      {
        path: '/createcource',
        name: 'createcource',
        component: CreateCource
      },
      {
        path: '/addsection',
        name: 'addsection',
        component: AddSection
      },
      {
        path: '/addpaper',
        name: 'addpaper',
        component: AddPaper
      },
      {
        path: '/goclasssteptwo',
        name: 'goclasssteptwo',
        component: GoClassSteptwo
      },



      // 视频详情
      {
        path: '/goclassdetail',
        name: 'goclassdetail',
        // meta: {
        //     requireAuth: true, //判断是否需要登录
        // },
        component: GoClassDetail
      },

      // 修改后homezi路由
      // {
      //     path: '/listeningcomment',
      //     name: 'listeningcomment',
      //     component: ListeningComment
      // },
      {
        path: '/listeningcommentdetail',
        name: 'listeningcommentdetail',
        component: ListeningCommentDetail
      },




      {
        path: '/preparelessons',
        name: 'preparelessons',
        component: PrepareLessons
      },
      {
        path: '/groupindex',
        name: 'groupindex',
        component: GroupIndex
      },
      {
        path: '/GroupListOld',
        name: 'groupindex',
        component: () => import("@/views/teachingGroup/GroupListOld.vue")
      },
      {
        path: '/groupdetail',
        name: 'groupdetail',
        component: GroupDetail,
        children: [{
            path: '/groupdetailshouye',
            name: 'groupdetailshouye',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailShouye
          },
          {
            path: '/groupdetaillive',
            name: 'groupdetaillive',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailLive
          },

          //审核直播列表
          {
            path: '/GroupTearmAudit',
            name: 'GroupTearmAudit',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupTearmAudit
          },

          {
            path: '/groupdetailactivity',
            name: 'groupdetailactivity',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailActivity
          },
          {
            path: '/groupdetailresult',
            name: 'groupdetailresult',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailResult
          },
          {
            path: '/groupdetailtalk',
            name: 'groupdetailtalk',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailTalk
          },
          {
            path: '/groupdetailmember',
            name: 'groupdetailmember',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailMember
          },
          {
            path: '/groupDetailCourse',
            name: 'groupDetailCourse',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailCourse
          },
          {
            path: '/groupdetailexamine',
            name: 'groupdetailexamine',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: GroupDetailExamine
          },
          //创建研修视频
          {
            path: '/groupCreteCourse',
            name: 'groupCreteCourse',
            meta: {
              requireAuth: true, //判断是否需要登录
            },
            component: CreteCoursePage
          },
          //创建研修视频
          {
            path: '/createGroupNewLive',
            name: 'createGroupNewLive',
            meta: {
              requireAuth: true, //判断是否需要登录
            },
            component: CreateGroupNewLive
          }
          // GroupCreteCourse
        ]
      },
      {
        path: '/editlive',
        name: 'editlive',
        component: EditLive
      },
      // {
      //     path: '/liveroom',
      //     name: 'liveroom',
      //     component: LiveRoom
      // },
      {
        path: '/researchcource',
        name: 'researchcource',
        component: ResearchCource
      },
      {
        path: '/livebrowsingroom',
        name: 'livebrowsingroom',
        component: LiveBrowsingRoom
      },
      {
        path: '/ceshi',
        name: 'ceshi',
        component: ceshi
      },
      {
        path: '/testdetail',
        name: "testdetail",
        component: () =>
          import('@/components/personalCom/testdetail.vue')
      },
      {
        path: '/paperdetail',
        name: "paperdetail",
        component: () =>
          import('@/components/personalCom/paperdetail.vue')
      },
      {
        path: '/publicPages1',
        name: "publicPages1",
        component: () =>
          import('@/views/publicPages.vue')
      },
      //研修活动创建基本信息
      {
        path: '/activityStudyCreate',
        name: 'activityStudyCreate',
        component: () => import('@/views/activityStudy/create/create.vue'),
      },
      //研修活动创建环节
      {
        path: '/activityStudyCreateLink',
        name: 'activityStudyCreateLink',
        component: () => import('@/views/activityStudy/create/createLink.vue'),
      },
      //研修活动
      {
        path: '/activityStudyFrame',
        name: 'activityStudyFrame',
        component: () => import('@/views/activityStudy/activity/frame.vue'),
        children: [{
            path: '/basicinfo', //activityStudyLink
            name: 'basicinfo', //activityStudyLink
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: () => import('@/views/activityStudy/activity/link.vue'),
          },
          {
            path: '/activityStudyDescribe',
            name: 'activityStudyDescribe',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: () => import('@/views/activityStudy/activity/describe.vue'),
          },
          {
            path: '/activityStudyAchievement',
            name: 'activityStudyAchievement',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: () => import('@/views/activityStudy/activity/achievement.vue'),
          },
          {
            path: '/activityStudyMember',
            name: 'activityStudyMember',
            // meta: {
            //     requireAuth: true, //判断是否需要登录
            // },
            component: () => import('@/views/activityStudy/activity/member.vue'),
          }
        ]
      },
      //研修活动环节详情
      {
        path: '/activityStudyLinkFrame',
        name: 'activityStudyLinkFrame',
        component: () => import('@/views/activityStudy/link/frame.vue'),
        children: [{
            path: '/resourcebrowsing',
            name: 'resourcebrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/ResourceBrowsing.vue")
          },
          {
            path: '/questionbrowsing',
            name: 'questionbrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/QuestionBrowsing.vue")
          },
          {
            path: '/resourceuploadbrowsing',
            name: 'resourceuploadbrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/ResourceuploadBrowsing.vue")
          },
          {
            path: '/votebrowsing',
            name: 'votebrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/VoteBrowsing.vue")
          },
          {
            path: '/collaborationbrowsing',
            name: 'collaborationbrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/CollaborationBrowsing.vue")
          },
          {
            path: '/livebrowsing',
            name: 'livebrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/LiveBrowsing.vue")
          },
          {
            path: '/discussbrowsing',
            name: 'discussbrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/DiscussBrowsing.vue")
          },
          {
            path: '/meetbrowsing',
            name: 'meetbrowsing',
            meta: {
              //requireAuth: true, //判断是否需要登录
            },
            component: () => import("@/views/activityStudy/link/MeetBrowsing.vue")
          }
        ]
      },
    ]
  },

  //登录
  {
    path: '/logo',
    name: 'logo',
    component: () => import('@/views/logo.vue'),
  },
  // 视频播放
  {
    path: '/videoPlayPage',
    name: 'videoPlayPage',
    component: () => import('@/views/videoPlayPage/videoPlayPage.vue'),
  },
  {
    path: '/pageAutoClose',
    name: 'pageAutoClose',
    component: () => import('@/views/pageAutoClose.vue'),
  },
  // 文件预览
  {
    path: '/fileViewPage',
    name: 'fileViewPage',
    component: fileViewPage,
  },
  //登录
  {
    path: '/noSite',
    name: 'noSite',
    component: () => import('@/views/noSite.vue'),
  },
  //  //设置404
  // {
  //     name: '404',
  //     path: '/404',
  //     component: () => import('@/views/notFound.vue')
  // },
  // // 此处需特别注意至于最底部
  // {
  // path: '*', 
  // redirect: '/404'
  // }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  store.dispatch("setLoginState");
  if (to.query.is_hide == 1) {
    sessionStorage.setItem("is_hide", 1)
  }
  if (to.query.is_hide == 0) {
    sessionStorage.removeItem("is_hide")
  }
  let host_src = window.location.host;

  if (localStorage.getItem("ucPath")) {
    let ucPath = JSON.parse(JSON.stringify(localStorage.getItem("ucPath")))
    localStorage.removeItem("ucPath")
    next(ucPath)
  }
  let gofullPath = to.fullPath
  if (to.fullPath.indexOf('lonce=1') != -1 && !localStorage.getItem("ucPath")) {
    sessionStorage.removeItem("loginOnce")
    gofullPath = to.fullPath.replaceAll("lonce=1", "lonce=0")
    next(gofullPath)
    return
  }
  if (to.fullPath.indexOf('lonce=2') != -1) {
    sessionStorage.removeItem("loginOnce")
    gofullPath = to.fullPath.replaceAll("lonce=2", "lonce=0")
    next(gofullPath)
    return
  }
  if ($cookies.get("local_login") && sessionStorage.getItem('loginOnce') != 1 && !localStorage.getItem("newfullPath") && !localStorage.getItem("ucPath")) {
    //清除缓存信息
    localStorage.removeItem("unserInfoData")
    localStorage.removeItem("aes_code")
    localStorage.removeItem("user_name")
    localStorage.removeItem("token")
    localStorage.removeItem("id")
    localStorage.removeItem("isShow")
    localStorage.removeItem("photo_url")
    let hostName = location.host
    let domes = 'https://uc.dc-it.cn'
    if (hostName.indexOf("teachercloud.com.cn") != -1) {
      domes = "https://dcuc.teachercloud.com.cn"
    }
    $.post(`${domes}/login/check`, {
      local_login: $cookies.get("local_login")
    }, function (res) {
      if (res.data.state == 2) {
        const dataObjs = res.data.data;
        let dataObj = dataObjs[0]
        sessionStorage.removeItem("webAll")
        // localStorage.removeItem("webAll")
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj))
        localStorage.setItem("aes_code", dataObj.aes_code);
        localStorage.setItem("user_name", dataObj.username);
        localStorage.setItem("token", dataObj.token);
        localStorage.setItem("id", dataObj.id);
        localStorage.setItem("isShow", 1);
        localStorage.setItem("photo_url", dataObj.photo_url);
        sessionStorage.setItem('loginOnce', 1)
        localStorage.setItem("ucPath", gofullPath)
        location.reload()
      } else {
        sessionStorage.setItem('loginOnce', 1)
        next(gofullPath)
      }
    })
  }



  if (to.query.sid && !localStorage.getItem("newfullPath")) {
    if (to.query.sid == 'dc_001') {
      localStorage.clear();
      let lodfullPath = to.fullPath
      let newfullPath = lodfullPath.substring(0, lodfullPath.indexOf('sid='))
      localStorage.setItem("newfullPath", newfullPath)
      location.reload()
      return
    } else {
      postsidLogin({
        sid: to.query.sid
      }).then(res => {
        const dataObj = res.data.data;
        console.log(dataObj);
        sessionStorage.removeItem("webAll")
        // localStorage.removeItem("webAll")
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj))
        localStorage.setItem("aes_code", dataObj.aes_code);
        localStorage.setItem("user_name", dataObj.username);
        localStorage.setItem("token", dataObj.token);
        localStorage.setItem("id", dataObj.id);
        localStorage.setItem("isShow", 1);
        localStorage.setItem("photo_url", dataObj.photo_url);
        let lodfullPath = to.fullPath
        let newfullPath = lodfullPath.substring(0, lodfullPath.indexOf('sid='))
        localStorage.setItem("newfullPath", newfullPath)
        location.reload()
        return
      })
      return
    }
  }
  if (localStorage.getItem("newfullPath")) {
    let newfullPath = JSON.parse(JSON.stringify(localStorage.getItem("newfullPath")))
    localStorage.removeItem("newfullPath")
    next(newfullPath)
  }
  if (to.path == "/") {
    if (host_src == "www.xbyx.cn" || host_src == "www.teachercamp.net" || host_src == "msgzs.teachercamp.net" || host_src == "jypthouduan.co" || host_src == "jypthouduan.co:81" || host_src == "hlwjy.dc-it.cn" || host_src == 'jypn.teachercamp.net') {
      next({
        path: "/MsHome"
      })
    } else {
      next({
        path: "/index"
      })
    }
    return
  }

  //四名工作室浏览器地址id传参转本地存储
  if (to.path == "/TeachLectureIndex") {
    if (to.query.id) {
      localStorage.setItem("studioId", to.query.id);
    }
  };

  if (to.query.token && !to.query.appId1) {
    localStorage.setItem("token", to.query.token);
    localStorage.setItem("isShow", 1);
    if (to.query.id) {
      localStorage.setItem("id", to.query.id);
    }
    if (to.query.aes_code) {
      localStorage.setItem("aes_code", to.query.aes_code);
    }
    if (to.query.use_rname) {
      localStorage.setItem("user_name", to.query.use_rname);
    }
    let unserInfo = {
      aes_code: to.query.aes_code,
      id: to.query.id,
      username: to.query.username,
      token: to.query.token,
    }
    localStorage.setItem("unserInfoData", JSON.stringify(unserInfo))
  }
  //判断是否需要登录
  if (to.meta.requireAuth) {
    store.dispatch("checkLogin").then((state) => {
      //判断是否直接进入需要登录的页面
      if (from.path == "/" && to.path != "/index" && !state) {
        next({
          path: "/index", //正常打开，维护关闭
          query: {
            dialogLogin: 1
          }
        })
      }
      //判断已经登录
      if (state) {
        next()
      }

    })
    return false
  }
  next()
})

export default router