var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liveBroadCast"},[_c('div',{staticClass:"items"},[_c('div',{staticClass:"items_top"},[_c('div',{staticClass:"nav"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("个人中心")]),_c('el-breadcrumb-item',[_vm._v("我的教研直播")]),_c('el-breadcrumb-item',[_vm._v("直播详情")])],1)],1),_c('div',{staticClass:"banner"},[_vm._m(0),_c('div',{staticClass:"b_wz",staticStyle:{"margin-top":"50px"}},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"btn",staticStyle:{"display":"flex","margin-top":"50px"}},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary"}},[_vm._v("启动直播")]),_vm._m(4)],1)])])]),_vm._m(5),_vm._m(6),_c('span',{staticStyle:{"font-weight":"bold","margin-left":"150px"}},[_vm._v(" 直播资源 ")]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b_img"},[_c('img',{attrs:{"src":"","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v("Adobe xd ui设计/交互")]),_c('div',{},[_c('img',{attrs:{"src":require("../../assets/images/personal/bj.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/images/personal/ljt.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"color":"#999999"}},[_vm._v("直播时间：")]),_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v("2020年02月10日9:00~11:00")]),_c('div',{staticClass:"box_wz",staticStyle:{"border":"1px solid #999999","border-radius":"4px","margin-bottom":"20px","color":"#999999"}},[_vm._v(" 未开始 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"color":"#999999"}},[_vm._v("学科学段：")]),_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v("小学语文")]),_c('span',{staticStyle:{"color":"#999999"}},[_vm._v("主持人：")]),_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v("XXX老师")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"red","font-size":"14px"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" 温馨提示： ")]),_vm._v(" 启动直播后，无法再编辑直播，请确保直播信息和内容无误后再启动。"),_c('br'),_vm._v(" 若您未手动启动直播，当达到直播时间时，系统将会自动启动直播。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticStyle:{"font-weight":"bold","margin-left":"150px","margin-right":"10px"}},[_vm._v(" 直播介绍 ")]),_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":require("../../assets/images/personal/bj_b.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items_bottom"},[_c('p',{staticStyle:{"letter-spacing":"5px","word-wrap":"break-word","text-indent":"30px","line-height":"22px","font-size":"14px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items_last"},[_c('div',{staticClass:"boxs"},[_c('div',{staticClass:"b_left"},[_c('img',{attrs:{"src":require("../../assets/images/word.png"),"alt":""}})]),_c('div',{staticClass:"b_right"},[_c('div',{staticClass:"r_box"},[_c('div',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"13px","margin-right":"10px"}},[_vm._v("标题标题")])]),_c('div',[_c('span',{staticStyle:{"color":"#3489FE","font-weight":"bold","font-size":"12px","margin-right":"10px"}},[_vm._v("在线查看")]),_c('span',{staticStyle:{"color":"#3489FE","font-weight":"bold","font-size":"12px"}},[_vm._v("下载")])])]),_c('p',[_c('span',{staticStyle:{"font-size":"12px","color":"#666666"}},[_vm._v("XXXX老师上传于2020-02-21")])])])]),_c('div',{staticClass:"boxs"},[_c('div',{staticClass:"b_left"},[_c('img',{attrs:{"src":require("../../assets/images/word.png"),"alt":""}})]),_c('div',{staticClass:"b_right"},[_c('div',{staticClass:"r_box"},[_c('div',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"13px","margin-right":"10px"}},[_vm._v("标题标题")])]),_c('div',[_c('span',{staticStyle:{"color":"#3489FE","font-weight":"bold","font-size":"12px","margin-right":"10px"}},[_vm._v("在线查看")]),_c('span',{staticStyle:{"color":"#3489FE","font-weight":"bold","font-size":"12px"}},[_vm._v("下载")])])]),_c('p',[_c('span',{staticStyle:{"font-size":"12px","color":"#666666"}},[_vm._v("XXXX老师上传于2020-02-21")])])])])])
}]

export { render, staticRenderFns }