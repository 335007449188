<template>
    <div class="content">
        <!-- <div class="center-content">
            <new-comment comment_list_url="ActivityReply/comment_list" activity_id="120" link_id="299" reply_list_url="ActivityReply/reply_list" delete_url="ActivityReply/" thumbs_url="ActivityReply/insertLike" ></new-comment>
        </div> -->
        <!-- <div class="center-content">
            <new-comment activity_id="120" link_id="299" ></new-comment>
        </div>
        <div class="center-content">
            <new-comment  activity_id="74" :module_type="2"></new-comment>
        </div> -->
        <new-addresource :onlyupload="true" @ouput-value="ceshi"></new-addresource>
    </div>
</template>

<script>
export default {
    data(){
        return {}
    },
    methods:{
        ceshi(val){
            console.log("值",val)
        }
    }
}
</script>

<style lang="less" scoped>
.content{
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
    .center-content{
        width:750px;
        border: 1px solid black;
    }
}
</style>