<template>
  <div class="groupdetaillive">
    <div class="content_one">
      <div class="one_left">研修成果</div>
      <div class="one_right">教研直播、活动和课程中的资源都为研修成果。</div>
    </div>

    <div class="content_two">
      <div class="content_item">
        <span class="span_text">来源 :</span>
        <div class="select_box_div">
          <el-radio-group v-model="form.type" @change="selectDataChange">
            <el-radio v-for="item in optiones2" :key="item.id" :label="item.id">{{item.name}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="content_item">
        <span class="span_text">类型 :</span>
        <div class="select_box_div">
          <el-radio-group v-model="form.source_id" @change="selectDataChange">
            <el-radio label="">全部</el-radio>
            <el-radio v-for="item in optiones1" :key="item.id" :label="item.id">{{item.name}}</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>

    <div class="content_three" v-loading="listLoading">
      <CommonEmpty v-if="!getData.length" hVal="100%"></CommonEmpty>
      <div class="main_block" v-for="(item, index) in getData" :key="index">
        <div class="images">
          <div>
            <!-- <img :src="item.mime | fileTypeSmall " alt=""> -->
            <img :src="onShowImages(item.mime)" alt="" />
          </div>
          <span>{{ item.sources_name || "-" }}</span>
        </div>
        <div class="block_word">
          <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item"
            :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time" fileNav="教研组 > 教研组列表 > 教研组详情 > 查看">
            <p style="cursor: pointer;">{{ item.title }}</p>
          </new-viewonline>
          <p>
            {{ item.pharse_name || "-" }}
            {{ item.subject_name || "-" }} /
            {{ item.grade_name  || "-" }}
          </p>
          <div v-if="false" class="block_gion">
            <div class="block_gion_one">{{ item.area }}</div>
            <div class="block_gion_one">{{ item.year }}</div>
          </div>
          <div class="block_time">
            <span>上传时间：{{ item.create_time || "-" }}</span>
            <span>收藏次数：{{ item.collect_count || 0 }}</span>
            <span>文件大小：{{ item.size | fileSizeFilter }}</span>
          </div>
        </div>
        <div class="rightbOx" v-if="item.user_id == userId">
          <el-checkbox @change="changeRecommend(item.id, item)" v-model="item.is_recommend">置顶首页显示</el-checkbox>
        </div>
      </div>
      <!-- 分页 -->
      <div v-if="total" class="block" style="margin-left: 350px">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="form.page"
          :page-size="form.limit" layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onShowImages } from "@/utils/utils";
export default {
  data() {
    return {
      userId: null, //用户id
      listLoading: true, //表格Loading
      total: 0,
      optiones2: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "教研直播",
        },
        {
          id: 2,
          name: "教研活动",
        },
        {
          id: 3,
          name: "研修视频",
        },
      ],
      optiones1: [],
      value1: [],
      value2: [],
      getData: [],
      form: {
        limit: 10,
        page: 1,
        group_id: "",
        type: 0,
        source_id: "",
      },
    };
  },
  created() {
    this.userId = localStorage.getItem("id") || null;
    this.init();
    this.ResourceData();
  },

  methods: {
    onShowImages,
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
    // 筛选
    selectDataChange() {
      this.$set(this.form, "page", 1);
      this.init();
    },
    init() {
      this.getList();
    },
    async changeRecommend(id, item) {
      try {
        let reqObj = {
          group_id: this.form.group_id,
          resource_id: id,
          id: id,
          type: item.type,
        };
        if (item.is_recommend) {
          reqObj['recommend'] = 2
        } else {
          reqObj['recommend'] = 1
        }
        await this.$axios.put("/TeacherGroup/recommend_group_resource", reqObj);
        this.$message.success("操作成功");
        this.init();
      } catch (error) {
        this.init();
      }
    },
    async ResourceData() {
      let res = await this.$axios.get("resourceTypes");
      this.optiones1 = res.data.data;
    },
    // 初始化数据
    async getList() {
      try {
        this.listLoading = true;
        let gid = this.$route.query.groupId;
        this.form.group_id = gid;
        let res = await this.$axios.get("/TeacherGroup/get_resource_list", {
          params: {
            limit: this.form.limit,
            page: this.form.page,
            group_id: this.form.group_id,
            type: this.form.type,
            source_id: this.form.source_id,
          },
        });
        this.getData = res.data.data.data;
        this.getData.forEach((item) => {
          if (item.is_recommend == 2) {
            this.$set(item, 'is_recommend', true);
          } else {
            this.$set(item, 'is_recommend', false);
          }
        });
        this.total = res.data.data.total;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      } catch (error) {
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.groupdetaillive {
  background: #ffffff;
  border-radius: 6px;
  padding-bottom: 40px;
  margin-bottom: 60px;
  padding-left: 30px;
  overflow: hidden;
  .content_one {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    .one_left {
      font-weight: 700;
      margin-right: 16px;
    }
    .one_right {
      color: #9a9ea5;
    }
  }
  .content_two {
    .content_item {
      display: flex;
      margin-bottom: 10px;
    }
    .span_text {
      width: 60px;
    }
    .select_box_div {
      flex: 1;
    }
    /deep/.el-radio {
      margin-top: 4px;
      margin-bottom: 10px;
    }
  }
  .content_three {
    .main_block {
      width: 985px;
      height: 200px;
      border: 1px solid #ececec;
      border-radius: 6px;
      display: flex;
      margin-bottom: 20px;
      .images {
        width: 160px;
        height: 160px;
        border: 1px solid #ececec;
        border-radius: 6px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-top: 43px;
          margin-bottom: 20px;
        }
      }
      .block_word {
        p:nth-child(1) {
          margin-top: 34px;
          margin-bottom: 18px;
          font-weight: 700;
          width: 494px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        p:nth-child(2) {
          color: #666666;
          margin-bottom: 18px;
        }
        .block_gion {
          display: flex;
          margin-bottom: 14px;
          .block_gion_one {
            width: 69px;
            height: 24px;
            background: #ffffff;
            border: 1px solid #ff8201;
            border-radius: 4px;
            color: #ff8201;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
          }
        }
        .block_time {
          color: #999999;
          span {
            margin-right: 30px;
          }
        }
      }
      .rightbOx {
        width: 200px;
        text-align: center;
        padding-top: 25px;
      }
    }
  }
}
</style>