<template>
  <div class="problem-subject">
    <div class="subject-left">
      <div class="public-box">
        <h3 class="box-title">{{ subject.type == 1 ? "问卷" : "投票" }}列表</h3>
        <ul class="box-list">
          <li v-for="(item, index) in subjectList" :key="index" @click="choiceSubject(item)">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="subject-right">
      <div class="subject-box">
        <div class="box-top">
          <h2 class="top-title">
            {{ subject.title }}
          </h2>
          <div class="top-describe rich_text_div_img" v-html="subject.details"></div>
        </div>
        <div class="box-bottom">
          <SubjectList :subject="subject" v-if="subject.topics.length > 0">
          </SubjectList>
          <p class="list-tips" v-else>
            暂无题目，请选择左侧的
            {{ subject.type == 1 ? "问卷" : "投票" }}
            列表。
          </p>
          <div class="operate-button">
            <el-button type="primary" @click="copyButton()">
              复制此{{ subject.type == 1 ? "问卷" : "投票" }}
            </el-button>
            <el-button type="info" @click="cancelButton()">取消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import History from "./history.js";
export default History;
</script>

<style lang="less" scoped>
@import "./history.less";
</style>