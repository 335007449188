import Vue from "vue";
import https from '@/api/request.js'

let request = Vue.prototype.$axios


export const SecretLogin = (params) => request.post("secretLogin", params).then(res => {
    return res.data
})

// 四名工作室列表
// export const StudioList = (params) => request.get("user/TeachingStudioList", {
//     params
// }).then(res => {
//     return res.data
// })
export function StudioList(data) {
    return https({
        url: 'user/TeachingStudioList',
        method: 'get',
        params: data
    }).then(res => {
        return res.data
    })
}
// 我的文章列表
export const ArticleList = (params) => request.get("user/TeachingStudioListArticle", {
    params
}).then(res => {
    return res.data
})

// 文章分类列表
export const ArticleCategoryList = (params) => request.get("user/TeachingStudioListArticleType", {
    params
}).then(res => {
    return res.data
})

// 删除文章
export const delArticle = (params) => request.delete("TeachingStudioArticle/user_delete", {
    params
}).then(res => {
    return res.data
})

// 我的证书列表
export const CertificateList = (params) => request.get("user/TeachingStudioListCertificate", {
    params
}).then(res => {
    return res.data
})

// 证书分类
export const CertificateCategory = (params) => request.get("user/TeachingStudioListCertificateType", {
    params
}).then(res => {
    return res.data
})

// 删除证书
export const delCertificate = (params) => request.delete("user/Certificatedelete", {
    params
}).then(res => {
    return res.data
})

// 话题评论列表
export const commentList = (params) => request.get("TeachingStudio/gambitComment/list", {
    params
}).then(res => {
    return res.data
})

// 评论回复列表
export const ReplyList = (params) => request.get("TeachingStudio/gambitReply/list", {
    params
}).then(res => {
    return res.data
})

// 发表评论
export const SendComment = (params) => request.post("TeachingStudio/gambitReply/set_comment", params).then(res => {
    return res.data
})

// 话题回复
export const TopicReplay = (params) => request.post("TeachingStudio/gambitReply/set_reply", params).then(res => {
    return res.data
})

// 话题回复的点赞
// export const ReplayClickLike = (params) => request.post("TeachingStudio/gambitReply/set_like", params).then(res => {
//     return res.data
// })

// 删除话题
export const DelTopic = (id, user_id) => request.delete(`TeachingStudio/gambit/${id}/${user_id}`).then(res => {
    return res.data
})

// 删除话题
export const DeleteTopic = (params) => request.delete(`TeachingStudioGambit/reply_del`, {
    params
}).then(res => {
    return res.data
})

// 删除活动
export const DelActive = (params) => request.delete(`index/teachingStudioActivity/delete`, {
    params
}).then(res => {
    return res.data
})

// 资源详情
export const ResourceDetail = (params) => request.get("TeachingStudio/getResourceInfo", {
    params
}).then(res => {
    return res.data
})

// 资源详情推荐列表
export const ResourceRecomments = (params) => request.get("resource/relation_list", {
    params
}).then(res => {
    return res.data
})

// 资源收藏
export const CollerctionResource = (params) => request.post("resource/collect_count", params).then(res => {
    return res.data
})

// 最近访客统计
export const RecentStatistics = (params) => request.get(`index/TeachingStudio/TeachingStudioVisitTime`, {
    params
}).then(res => {
    return res.data
})

// 活动环节详情
export const ActiveHjDetail = (activityId, id) => request.get(`TeachingStudioactivity/${activityId}/activityLink/${id}`).then(res => {
    return res.data
})

// 获取直播链接
export const LiveLink = (live_id) => request.get(`TeachingStudioActivityLive/${live_id}`).then(res => {
    return res.data
})

// 获取直播配置
export const LiveConfig = (live_id) => request.get(`TeachingStudioActivityLive/${live_id}/config`).then(res => {
    return res.data
})

// 获取进入会议链接
export const MeetLink = (params) => request.get(`TeachingStudioactivities/goIntoMeeting`, {
    params
}).then(res => {
    return res.data
})

// 线下提交总结
export const CommitBilan = (activityId, linkId, params) => request.post(`TeachingStudioactivity/${activityId}/activityLink/${linkId}/summary`, params).then(res => {
    return res.data
})

// 修改公告
export const UpdateNotice = (params) => request.put(`index/teachingStudioNotice/editTeachingStudioNotice`, params).then(res => {
    return res.data
})

// 删除荣誉证书
export const DelCertificat = (params) => request.delete(`TeachingStudioCertificate/del`, {
    params
}).then(res => {
    return res.data
})

// 资源浏览记录
export const ResourceViewRecord = (params) => request.get(`ResourceRecord/lists`, {
    params
}).then(res => {
    return res.data
})

// 添加资源浏览记录
export const AddResourceViewRecord = (params) => request.post(`ResourceRecord/add`, params).then(res => {
    return res.data
})

// 录播课详情
export const LuBoDetail = (params) => request.get(`index/TeachingStudioCourse/infoTeachingStudioCourse`, {
    params
}).then(res => {
    return res.data
})

// 发布课程
export const SendCourse = (id) => request.put(`TeachingStudioCourse/${id}/publish`).then(res => {
    return res.data
})

// 编辑录播课
export const EditorLuBo = (params) => request.put(`index/TeachingStudioCourse/editTeachingStudioCourse`, params).then(res => {
    return res.data
})



// 保存直播视频回放
export const StudioSaveVideo = (params) => request.put(`TeachingStudioActivityLive/uploadPlayback`, params).then(res => {
    return res.data
})

// 保存直播视频回放
export const JySaveVideo = (params) => request.put(`ActivityLive/uploadPlayback`, params).then(res => {
    return res.data
})

// 投诉
export const Reclamation = (params) => request.post(`index/Complaint/addComplaint`, params).then(res => {
    return res.data
})

// 教研申请参与活动
export const ApplyJoinActivity = (params) => request.post(`UserActivity/add`, params).then(res => {
    return res.data
})

// 四名申请参与活动
export const StudipApplyJoinActivity = (params) => request.post(`TeachingStudioActivityUser/add`, params).then(res => {
    return res.data
})


//创建荣誉证书类型
export function CertificateType(params) {
    return https({
        url: 'index/TeachingStudioCertificate/type_list',
        method: 'get',
        params
    })
}

// 修改活动环节状态
// export const ChangeActivityStatus = (activityId,id) => request.post(`TeachingStudioactivity/${activityId}/activityLink/${id}`).then(res => {
//     return res.data
// })




// ------------------------------------------教研组接口-----------------------------


//活动成员 待审核列表
export const WaitCheckList = (params) => request.get(`index/UserActivity/lists`, {
    params
}).then(res => {
    return res.data
})

// 组长审核成员加入活动

export function CheckMembre(data) {
    return https({
        url: 'index/UserActivity/status',
        method: 'post',
        data
    })
}


//完成教研组活动环节
export function cancelGroupActivity(params) {
    return https({
        url: `activity/${params.activityId}/activityLink/${params.id}`,
        method: 'post',
    })
}


//完成四名工作室活动环节
export function cancelTeachActivity(params) {
    return https({
        url: `TeachingStudioactivity/${params.activityId}/activityLink/${params.id}`,
        method: 'post',
    })
}



// export const CheckMembre = (params) => request.post(`index/ActivityExamineUser/status`,params).then(res => {
//     return res.data
// })