<template>
  <div class="livebrowsingroom">
    <div class="w_d center">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item> 研修中心 </el-breadcrumb-item>
            <el-breadcrumb-item>教研活动</el-breadcrumb-item>
            <el-breadcrumb-item><span class="font_orange">活动详情</span></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="main_content">
        <!-- 左 -->
        <div class="content_left">
          <!-- <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          ></video-player> -->
          <div id="video" style="height: 800px"></div>
          <div>
            <p>直播主题</p>
            <div class="fc">
              <div>
                <img src="@/assets/images/shoucang_had.png" alt="" />
                <span>主播：</span>
                <span>沈xx</span>
              </div>
              <div>
                <img src="@/assets/images/shoucang_had.png" alt="" />
                <span>直播时间：</span>
                <span>2019-5-54 39:39~21:43</span>
              </div>
              <div>
                <img src="@/assets/images/shoucang_had.png" alt="" />
                <span>在线人数：</span>
                <span>100</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 右 -->
        <div class="content_right">
          <div class="fsbc mb20">
            <span>讨论区（30）</span>
            <i class="el-icon-s-unfold" style="font-size: 20px"></i>
          </div>
          <ul>
            <li v-for="(item, index) in cgList" :key="index" class="" @click="onSearchCg(item.id)">
              <img :src="item.id == active ? item.icon1 : item.icon2" alt="" />
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <!-- 发布评论 -->
          <div class="my_comment_right">
            <el-input type="textarea" :rows="4" placeholder="写下你的评论..." v-model="myContent" resize="none"></el-input>
            <div class="release cursor" @click="releaseComment">发表评论</div>
          </div>
          <!-- 评论列表 -->
          <div style="padding: 0 40px">
            <new-comment :activity_id="1" :is_from="+2" :module_type="6" ref="commentRef"></new-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LiveLink } from "@/api/teacherStudio";
import "videojs-contrib-hls";
export default {
  name: "living",
  data() {
    return {
      // 直播配置
      playerOptions: {
        //playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "application/x-mpegURL",
            src: "",
            // src: "http://hls.dccloud.com.cn/live/8a5c5e89c9d9ff45/index.m3u8" //你的m3u8地址（必填）
            // src:"http://hls01open.ys7.com/openlive/6d499d610a0c4a6182e36ac7dca124ad.m3u8"
          },
        ],
        // poster: "poster.jpg", //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        //  controlBar: {
        //   timeDivider: true,
        //   durationDisplay: true,
        //   remainingTimeDisplay: false,
        //   fullscreenToggle: true //全屏按钮
        //  }
      },
      // 直播id
      live_id: "",
      // 活动id
      activity_id: "",
      // 环节id
      link_id: "",
      // 当前登录的用户名
      username: "",
      videoObject: {
        width: 1015, // 宽度，也可以支持百分比(不过父元素宽度要有)
        height: 574, // 高度，也可以支持百分比
        container: "#video", //“#”代表容器的ID，“.”或“”代表容器的class
        variable: "player", //该属性必需设置，值等于下面的new chplayer()的对象
        autoplay: true, //自动播放
        live: true,
        video: "", //视频地址(必填)
      },
      cgList: [
        {
          name: "全部",
          id: 1,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
        {
          name: "我的发言",
          id: 2,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
      ],
      active: 1,
      myContent: "",
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },

  created() {
    this.init();
  },
  methods: {
    //初始化函数
    init() {
      this.live_id = this.$route.query.live_id;
      this.activity_id = this.$route.query.activity_id;
      this.link_id = this.$route.query.link_id;
      this.is_from = this.$route.query.is_from;
      this.getliveurl();
      this.username = window.localStorage.getItem("user_name");
    },
    // 全部/我的发言
    onSearchCg(id) {
      this.active = id;
    },
    // 请求后台发布评论函数
    releaseComment() {
      let p = null;
      var obj = {
        user_id: this.userId,
        content: this.myContent,
      };
      if (this.is_from == 1) {
        obj.course_id = this.id;
        p = this.$axios.post("courseReply/insert_comment", obj);
      } else {
        obj.teaching_studio_course_id = this.id;
        p = this.$axios.post("TeachingStudioCourseReply/insert_comment", obj);
      }
      p.then((res) => {
        this.$message.success("评论成功");
        this.myContent = "";
        // 全部评论
        this.$refs.commentRef.getCommentList();
        // 个人评论评论
        this.$refs.commentRef.getMyCommentInfo();
      });
    },
    // 请求后台获取直播地址
    getliveurl() {
      if (this.is_from == 1) {
        this.$axios.get(`ActivityLive/${this.live_id}`).then((res) => {
          this.playerOptions.sources[0].src = res.data.data.live_url;
          this.videoObject.video = res.data.data.live_url;
          let player = new ckplayer(this.videoObject);
        });
      } else {
        LiveLink(this.live_id).then(res => {

        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
.livebrowsingroom {
  display: flex;
  justify-content: center;
  .center {
    margin-bottom: 60px;
    .main_content {
      display: flex;
      justify-content: space-between;
      .content_left {
        width: 70%;
        margin-right: 20px;
      }
      .content_right {
        width: 30%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 20px 10px 20px 20px;
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          li {
            margin-right: 10%;
            > img {
              vertical-align: text-bottom;
              margin-right: 16px;
            }
          }
        }
        .my_comment_right {
          height: 150px;
          border: 1px solid #ececec;
          border-radius: 4px;
          position: relative;
          .release {
            width: 92px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background: #fa8c15;
            border-radius: 4px;
            font-weight: 700;
            color: #ffffff;
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
          /deep/ .el-textarea__inner {
            border: none;
          }
        }
      }
    }
  }
}
</style>