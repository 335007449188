<template>
  <ul class="subject-list" v-if="subject.topics.length > 0">
    <li v-for="(item, index) in subject.topics" :key="index">
      <div>
        <div class="subject-title">
          <span class="subject-serial"><span class="serial-must" v-if="item.is_required==2">*</span>{{ index + 1 }}、</span>
          <div class="subject-content rich_text_div_img" v-html="item.title"></div>
          <span class="subject-type">
            [{{ item.type|subjectType(item.selected_type?item.selected_type:"") }}]
          </span>
        </div>
        <div class="subject-option option">

          <template v-if="item.type == 1">
            <div class="option-box" v-for="(item1, index1) in item.options" :key="index1" v-if="item.selected_type == 1">
              <el-radio :disabled="true" :label="index1">
                {{ item1.content }}
              </el-radio>
              <div class="input-box">
                <el-input class="option-input" :disabled="true" v-if="item1.allow_fill_in==2"></el-input>
                <span class="input-must" v-if="item1.fill_in_required==2">*</span>
              </div>
            </div>

            <div class="option-box" v-for="(item1, index1) in item.options" :key="index1" v-if="item.selected_type == 2">
              <el-checkbox :disabled="true" :label="index1">
                {{ item1.content }}
              </el-checkbox>
              <div class="input-box">
                <el-input class="option-input" :disabled="true" v-if="item1.allow_fill_in==2"></el-input>
                <span class="input-must" v-if="item1.fill_in_required==2">*</span>
              </div>
            </div>
          </template>

          <template v-if="item.type == 2">
            <div class="input-box" v-for="(item,index1) in item.options" :key="index1">
              <el-input class="option-input option-input-m10" :disabled="true" :placeholder="'填空'+(index1+1)"></el-input>
              <span class="input-must" v-if="item.fill_in_required==2">*</span>
            </div>
          </template>

          <template v-if="item.type == 3">
            <div class="option-box" v-for="(item1, index1) in item.options" :key="index1">
              <p class="option-text">{{ item1.content }}</p>
              <div class="input-box">
                <el-input class="option-input" :disabled="true" v-if="item1.allow_fill_in==2"></el-input>
                <span class="input-must" v-if="item1.fill_in_required==2">*</span>
              </div>
            </div>
          </template>

          <template v-if="item.type == 4">

            <table class="gauge-table">
              <tr>
                <td></td>
                <td v-for="(item1, index1) in item.options" :key="index1">
                  {{item1.content}}
                </td>
              </tr>
              <tr v-if="item.selected_type==3" class="table-tr-score">
                <td>分值</td>
                <td v-for="(item1, index1) in item.options" :key="index1">{{item1.score}}</td>
              </tr>
              <tr v-for="(item1, index1) in item.lines" :key="index1">
                <td>
                  {{item1.title}}
                </td>
                <td v-for="(item1, index1) in item.options" :key="index1">
                  <div class="table-td-flex">
                    <el-checkbox disabled v-if="item.selected_type==2"></el-checkbox>
                    <el-radio disabled v-if="item.selected_type==1||item.selected_type==3"></el-radio>
                    <div class="input-box">
                      <el-input class="option-input" :disabled="true" v-if="item1.allow_fill_in==2"></el-input>
                      <span class="input-must" v-if="item1.fill_in_required==2">*</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

          </template>
        </div>
      </div>
      <slot name="operate" :data="{item, index}"></slot>
    </li>
  </ul>
</template>

<script>
import SubjectList from "./subjectList.js";
export default SubjectList;
</script>

<style lang="less" scoped>
@import "./subjectList.less";
</style>