<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="
            height: 49px;
            display: flex;
            align-items: center;
          ">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item to="/MsActivity">
                  <span class="c-b">四名活动</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">活动列表</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>


      <div class="mainCenter df dfb">
        <div class="mainLeft">
          <!-- 名称 -->
          <div class="titleBox df dfb">
            <div class="titleLeft">
              <p>四名活动</p>
            </div>
            <div class="titleRight">
              <el-input class="input-with-select" size="small" v-model="form.keyword" placeholder="请输入搜索关键词">
                <el-select v-model="form.type1" @change="seletBtn" slot="prepend" class="width120">
                  <el-option label="全部类型" :value="0"></el-option>
                  <el-option label="四名沙龙" :value="1"></el-option>
                  <el-option label="四名论坛" :value="2"></el-option>
                  <el-option label="竞赛活动" :value="3"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="seletBtn"></el-button>
              </el-input>
            </div>
          </div>
          <!-- 内容 -->
          <div class="mainBox">
            <div class="ulBox" v-if="total">
              <div class="liBox df dfb cp" v-for="(item, index) in dataList1" :key="index" @click="goMsDetails(item.id)">
                <div class="imgBox">
                  <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                  <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                </div>

                <div class="txtbox df dfb">
                  <div class="width490">
                    <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
                    <p class="ellipsis-3 fz14">{{ setText(item.content) }}</p>
                    <div class="df mt30">
                      <p class="fz14 c666 mr30 flex_align_center">
                        {{ item.type_str }}
                      </p>
                      <p class="fz14 c666 mr30 flex_align_center">
                        <img class="width20 h-16 mr5" src="@/assets/banner_img/liulan.png" alt="">
                        浏览：{{ item.view_num || 0 }}
                      </p>
                      <p class="fz14 c666 mr30 flex_align_center">
                        <img class="width20 h-16 mr5" src="@/assets/banner_img/pinglun.png" alt="">
                        评论：{{ item.num_comment || 0 }}
                      </p>
                    </div>
                  </div>
                  <div class="width120">
                    <p class="tr fz14 c999">
                      {{ item.create_time }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            <el-pagination v-if="total" class="tc mt30 mb30" background @current-change="handleCurrentChange"
              :current-page.sync="form.page" :page-size="10" layout="total, prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>
        <div class="mainRight">
          <!-- 名称 -->
          <div class="titleBox df dfb" v-if="dataList5.length">
            <div class="titleLeft">
              <p>活动申报</p>
            </div>
          </div>
          <div class="mainBox mb20" v-if="dataList5.length">
            <div class="main2Img">
              <template v-if="dataList5.length">
                <img class="imgbig cp" v-if="dataList5.length && dataList5[0].cover_url" :src="dataList5[0].cover_url"
                  alt="" @click="goMsAdvanceDetails(dataList5[0].id)">
                <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>

          <!-- 名称 -->
          <div class="titleBox df dfb">
            <div class="titleLeft">
              <p>热点活动关注</p>
            </div>
          </div>
          <div class="mainBox">
            <div class="ulBox">
              <template v-if="dataList2.length">
                <div class="liBox cp" v-for="(item, index) in dataList2" :key="index" @click="goMsDetails(item.id)">
                  <h4 class="ellipsis-1 mb12">
                    <img class="width16 h-22" src="@/assets/banner_img/hot_icon.png" alt="">
                    {{ item.title }}
                  </h4>
                  <div class="rightTop df dfb">
                    <div class="imgBox">
                      <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    </div>
                    <div class="txtbox">
                      <p class="ellipsis-3">{{ setText(item.content) }}</p>
                    </div>
                  </div>
                </div>
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      dataList1: [],
      dataList2: [],
      dataList5: [],
      total: 0,
      form: {
        keyword: "",
        type1: 0,
        hidden_type: 4,
        page: 1,
        limit: 10,
      },
    };
  },

  created() {
    if (localStorage.getItem("selName")) {
      this.form.keyword = localStorage.getItem("selName")
      setTimeout(() => {
        localStorage.removeItem("selName")
      }, 1000);
    }
    this.getList1()
    this.getList2()
    if (localStorage.getItem("unserInfoData")) {
      this.getList5()
    }
  },
  methods: {
    // 5活动申报
    getList5() {
      let param = {
        limit: 3,
      }
      msAllApi.SalonReg(param).then(res => {
        if (res.data.data) {
          this.dataList5 = [res.data.data]
        } else {
          this.dataList5 = []
        }
      })
    },
    getList1() {
      msAllApi.activityList(this.form).then(res => {
        this.dataList1 = res.data.data.data
        this.total = res.data.data.total
      })
    },
    getList2() {
      msAllApi.activityList({ sort: 'hot', limit: 8, hidden_type: 4, }).then(res => {
        this.dataList2 = res.data.data.data
      })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getList1()
    },
    seletBtn() {
      this.form.page = 1
      this.getList1()
    },
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainCenter {
    .mainLeft {
      width: 956px;
      min-height: 500px;
      background: #ffffff;
      border-radius: 8px;

      .titleBox {
        height: 72px;
        align-items: center;
        padding: 0 25px;

        .titleLeft {
          p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }
        }
      }

      .mainBox {
        padding: 0 24px;

        .ulBox {
          .liBox {
            padding: 12px;
            border-radius: 8px;

            &:hover {
              background: rgba(238, 246, 255, 1);

              h4 {
                color: #3489ff;
              }
            }

            .imgBox {
              width: 250px;
              height: 141px;

              img {
                border-radius: 8px;

              }
            }

            .txtbox {
              width: 610px;
            }
          }
        }
      }
    }

    .mainRight {
      width: 308px;
      min-height: 500px;

      .titleBox {
        border-radius: 8px 8px 0 0;
        height: 72px;
        align-items: center;
        padding: 0 25px;
        background: #ffffff;

        .titleLeft {
          p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }
        }
      }

      .mainBox {
        background: #fff;
        border-radius: 0 0 8px 8px;

        .ulBox {
          background: #ffffff;
          padding: 0 8px 8px 8px;
          border-radius: 0 0 8px 8px;

          .liBox {
            width: 292px;
            height: 134px;
            background: rgba(238, 246, 255, 1);
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 16px;
            box-sizing: border-box;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            .rightTop {
              .imgBox {
                width: 111px;
                height: 62px;

                img {
                  border-radius: 4px;
                }
              }

              .txtbox {
                width: 140px;

                h4 {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #2a3037;
                }

                p {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                  color: #666666;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .main2Img {
          width: 287px;
          height: 164px;
          border: 1px solid rgba(255, 255, 255, 1);
          border-radius: 8px;
          position: relative;
          overflow: hidden;
          margin: 0 auto;
          padding-bottom: 10px;

          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>