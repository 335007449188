<template>
  <div class="Community public_main_bgc">
    <div class="main_box">
      <div class="box_content">

        <div class="middle">
          <div class="m_left">
            <div class="l_item1" style="margin-bottom: 10px">
              <div class="one">
                <span>学段:</span>
              </div>
              <!-- //循环按钮 -->
              <div :class="activeClass == index ? 'active' : ''" v-for="(item, index) in list" :key="index" style="padding: 0 15px"
                @click="getItem(index, item.typeId)">
                {{ item.name }}
              </div>
            </div>
            <div class="l_item1" style="margin-bottom: 40px">
              <div class="one">
                <span>学科:</span>
              </div>
              <!-- //循环按钮 -->
              <div :class="activeClass_One == index ? 'active' : ''" v-for="(item, index) in list_one" :key="index" style="padding: 0 15px"
                @click="getItemOne(index, item.typeId)">
                {{ item.name }}
              </div>
            </div>

            <div class="l_item2">
              <div class="one">
                <span>查找:</span>
              </div>
              <div class="two">
                <el-input placeholder="发起人姓名、教研组名称" v-model="form.select_input" style="width: 300px" class="two_one">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <div class="tow_two">
                  <el-button type="primary" @click="searchBtn" class="tow_two" style="border-radius: 0px 4px 4px 0px">搜索</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="m_right cursor" @click="SendTopic" v-if="isToken">
            <i class="el-icon-plus"></i>
            <p>发起话题</p>
          </div>
        </div>
      </div>
    </div>

    <div class="B_Box">
      <!-- 主体内容 -->
      <div class="bottom">
        <div class="leftBox">
          <h4 style="font-size: 16px; font-weight: 700">话题研讨</h4>
          <!-- 循环部分 -->
          <CommonEmpty v-if="!getList.length" hVal="100%"></CommonEmpty>
          <div v-if="getList && getList.length != 0">
            <div class="b_contents" v-for="(item, index) in getList" :key="index">
              <!-- 左边文字 -->
              <div class="item_l">
                <h5 @click="toDetails(item.id)" style="font-weight: 700; font-size: 16px">
                  #{{ item.title }}
                </h5>
                <div>
                  <span v-if="item.username" style="margin-right: 20px">
                    {{ item.username }}</span>
                  <span v-else-if="item.teacher_group_name" style="margin-right: 20px">
                    {{ item.teacher_group_name }}</span>
                  <span v-if="item.username || item.teacher_group_name">于</span>
                  <span style="margin-right: 50px">{{ item.create_time }}发起</span>
                  <span>最新回复时间：{{ item.reply_time }}</span>
                </div>

                <!-- <span>最新回复时间：{{ item.reply_time }}</span> -->
              </div>
              <!-- 右边图标 -->
              <div class="item_r">
                <div>
                  <img src="../../assets/images/personal/ll.png" style="margin-right: 8px" alt="" />
                  <span>浏览&nbsp;&nbsp;{{ item.visit_count }}</span>
                </div>
                <div style="margin-left: 40px">
                  <img src="../../assets/images/personal/hf.png" style="margin-right: 8px" alt="" />
                  <span>回复&nbsp;&nbsp;{{ item.reply_count }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
          <!-- 分页 -->
          <div class="block" v-if="getList && getList.length != 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
              :page-sizes="[10, 20, 30, 50]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
        <!-- 右边排行 -->
        <div class="b_right">
          <!-- 标题 -->
          <div class="title">
            <span>热门话题</span>
          </div>
          <!-- 内容循环部分 -->
          <CommonEmpty v-if="!getHotList.length" hVal="100%"></CommonEmpty>
          <div class="main_item" v-for="(item, index) in getHotList" :key="index">
            <div class="m_content">
              <div class="content_left">
                <!-- <span>{{ item.id }}</span> -->
                <div :class="
                    index + 1 == 1
                      ? 'span1'
                      : index + 1 == 2
                      ? 'span2'
                      : index + 1 == 3
                      ? 'span3'
                      : 'others'
                  ">
                  {{ index + 1 }}
                </div>
              </div>
              <div class="content_right">
                <h5 class="cursor" @click="toDetails(item.id)">
                  #{{ item.title }}
                </h5>
                <p>
                  <span>浏览：{{ item.visit_count }}</span>
                  <span>评论：{{ item.reply_count }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- end循环 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //是否显示发起话题按钮
      isToken: true,
      num: "",
      ab: "",
      form: {
        limit: 10,
        page: 1,
        search_stage_id: "",
        search_subject_id: "",
        select_input: "",
        username: "",
        teacher_group_name: "",
      },
      // 控制学段样式的变量
      activeClass: 0,
      activeClass_One: 0,
      //按钮学段list
      list: [
        { typeId: "", name: "全部", number: "50" },
        { typeId: "1", name: "小学", number: "50" },
        { typeId: "2", name: "初中", number: "50" },
        { typeId: "3", name: "高中", number: "50" },
        //  {"typeId":"5","name":"学段","number":"50"},
      ],
      list_one: [
        { typeId: "", name: "全部", number: "50" },
        // { typeId: "1", name: "语文", number: "50" },
        // { typeId: "2", name: "数学", number: "50" },
        // { typeId: "3", name: "英语", number: "50" },
        // { typeId: "4", name: "历史", number: "50" },
        // { typeId: "5", name: "地理", number: "50" },
        // { typeId: "6", name: "政治", number: "50" },
        // { typeId: "7", name: "生物", number: "50" },
        // { typeId: "8", name: "物理", number: "50" },
        // { typeId: "9", name: "化学", number: "50" },
        // { typeId: "10", name: "科学", number: "50" },
        // { typeId: "11", name: "道德与法治", number: "50" },
      ],
      //不同学段下的学科
      diffSubject: {
        0: [{ typeId: "", name: "全部", number: "50" }],
        1: [
          { typeId: "", name: "全部", number: "50" },
          { typeId: "1", name: "语文", number: "50" },
          { typeId: "2", name: "数学", number: "50" },
          { typeId: "3", name: "英语", number: "50" },
        ],
        2: [
          { typeId: "", name: "全部", number: "50" },
          { typeId: "1", name: "语文", number: "50" },
          { typeId: "2", name: "数学", number: "50" },
          { typeId: "3", name: "英语", number: "50" },
          { typeId: "4", name: "历史", number: "50" },
          { typeId: "5", name: "地理", number: "50" },
          { typeId: "6", name: "政治", number: "50" },
          { typeId: "7", name: "生物", number: "50" },
          { typeId: "8", name: "物理", number: "50" },
          { typeId: "9", name: "化学", number: "50" },
          { typeId: "10", name: "科学", number: "50" },
          { typeId: "11", name: "道德与法治", number: "50" },
        ],
        3: [
          { typeId: "", name: "全部", number: "50" },
          { typeId: "1", name: "语文", number: "50" },
          { typeId: "2", name: "数学", number: "50" },
          { typeId: "3", name: "英语", number: "50" },
          { typeId: "4", name: "历史", number: "50" },
          { typeId: "5", name: "地理", number: "50" },
          { typeId: "6", name: "政治", number: "50" },
          { typeId: "7", name: "生物", number: "50" },
          { typeId: "8", name: "物理", number: "50" },
          { typeId: "9", name: "化学", number: "50" },
          { typeId: "10", name: "科学", number: "50" },
          { typeId: "11", name: "道德与法治", number: "50" },
        ],
      },
      currentPage4: 0,
      // 话题研讨列表
      getList: "",
      //热门信息列表
      getHotList: "",
      //话题列表数据总数目
      total: 0,
    };
  },
  created() {
    let token = localStorage.getItem("token");
    if (token) {
      this.isToken = true;
    } else {
      this.isToken = false;
    }
    //热门话题
    this.initHot();
    this.init();
  },
  methods: {
    //初始化数据
    async init() {
      let res = await this.$axios.get("/gambits/listAll", {
        params: {
          limit: this.form.limit,
          page: this.form.page,
          search_stage_id: this.form.search_stage_id,
          search_subject_id: this.form.search_subject_id,
          select_input: this.form.select_input,
        },
      });

      console.log(res.data.data.data, "json数据");
      this.getList = res.data.data.data;
      this.total = res.data.data.total;
    },
    // 按条件搜索函数
    async conditionSousuo() {
      this.form.page = 1;
      let res = await this.$axios.get("/gambits/listAll", {
        params: {
          limit: this.form.limit,
          page: 1,
          search_stage_id: this.form.search_stage_id,
          search_subject_id: this.form.search_subject_id,
          select_input: this.form.select_input,
        },
      });

      console.log(res.data.data.data, "json数据");
      this.getList = res.data.data.data;
      this.total = res.data.data.total;
    },
    //初始化热门话题数据
    async initHot() {
      let res = await this.$axios.get("/gambits/hot");
      console.log(res, "热门话题");
      this.getHotList = res.data.data;
    },
    // 发起话题
    SendTopic() {
      this.$router.push({
        path: "/sendTopic",
      });
    },
    // 跳转话题详情页
    toDetails(uid) {
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: uid,
        },
      });
    },
    // 编辑个人信息
    editor_info() {
      this.$router.push("/personInfo");
    },
    //学段点击按钮变色
    getItem(index, tId) {
      this.activeClass = index;
      //  console.log(tId,"所选");\
      // 根据学段搜索
      this.form.search_stage_id = tId;
      //  console.log("rrr",this.diffSubject[index])
      this.list_one = this.diffSubject[index];
      // 重新调用
      // this.init();
      this.conditionSousuo();
    },
    //学科点击按钮变色
    getItemOne(index, tId) {
      this.activeClass_One = index;
      // console.log(tId);
      this.form.search_subject_id = tId;

      // 重新调用
      // this.init();
      this.conditionSousuo();
    },
    // 搜索
    searchBtn() {
      console.log(this.form.select_input);
      // this.init();
      this.conditionSousuo();
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.form.limit = val;
      // this.init();
      this.conditionSousuo();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.form.page = val;
      this.init();
    },
    // 请求后台获取学科信息
    // getSubject(){
    //   this.
    // }
  },
};
</script>

<style lang="less" scoped>
.Community {
  width: 100%;
  .main_box {
    width: 100%;
    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;
      padding-top: 20px;
      // 顶部
      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
      // 中部
      .middle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-radius: 6px;
        .m_left {
          height: 190px;
          .l_item1 {
            display: flex;
            .one {
              width: 54px;
              // height: 30px;
              span {
                font-size: 15px;
                color: #999999;
              }
            }
            div {
              padding: 0;
              height: 26px;
              line-height: 26px;
              text-align: center;
              background-color: #ffffff;
              cursor: pointer;
              margin-right: 10px;
              margin-top: 20px;
              font-size: 15px;
              border-radius: 4px 3px 4px 4px;
              flex-shrink: 0;
            }
            //点击div之后的样式
            .active {
              background-color: #3489fe;
              color: #ffffff;
            }
          }
          .l_item2 {
            display: flex;
            align-items: center;
            margin-top: -20px;
            .one {
              margin-left: 10px;
              margin-right: 20px;
              color: #999999;
            }
            .two {
              display: flex;
              .two_one {
                /deep/ .el-input__inner {
                  border-radius: 4px 0px 0px 4px;
                }
              }
            }
          }
        }
        // 右边按钮
        .m_right {
          width: 140px;
          height: 38px;
          background: #ff8201;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-weight: 700;
          margin: 20px 10px 0 0;
          i {
            margin-right: 10px;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .B_Box {
    width: 100%;
    padding: 24px 0;
    // 底部
    .bottom {
      width: 1400px;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      margin: 0 auto;
      // margin-bottom: 60px;
      display: flex;
      align-items: flex-start;
      .leftBox {
        width: 1045px;
        padding: 40px 30px 0 30px;
        border: 1px solid #fff;
        background-color: #fff;
        border-radius: 6px;
        margin-right: 20px;
        h4 {
          margin-bottom: 20px;
        }
        .b_contents {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          width: 985px;
          border-bottom: 1px dashed #ececec;
          display: flex;
          justify-content: space-between;
          padding: 22px 0px;

          //  内容的左边
          .item_l {
            h5 {
              margin-bottom: 20px;
              cursor: pointer;
            }
            span {
              font-size: 14px;
              color: #999999;
            }
          }

          //  内容的右边
          .item_r {
            display: flex;
            // margin-right: 80px;
            height: 19px;
            line-height: 19px;
            div {
              color: #999999;
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-left: 10px;
              span {
                // margin-right: 20px;
              }
            }
          }
        }
        .no_info {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #888;
          margin-top: 100px;
        }
        //分页
        .block {
          width: 650px;
          background-color: #fff;
          margin: 40px auto;
        }
      }
      .b_right {
        width: 335px;
        border: 1px solid #fff;
        background-color: #fff;
        border-radius: 6px;
        padding: 24px 24px 0px 24px;
        .title {
          margin-bottom: 20px;
          span {
            font-size: 16px;
            font-weight: bold;
          }
        }
        //  循环部分
        .main_item {
          margin-bottom: 19px;
          .m_content {
            display: flex;
            // justify-content: center;
            //  内容左边序号
            .content_left {
              margin-right: 27px;
              .span1 {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #fa8c15;
              }
              .span2 {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #3489ff;
              }
              .span3 {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #00c1ae;
              }
              .others {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #c0c4cc;
              }
            }
            //  内容右边
            .content_right {
              border-bottom: 1px dashed #ebebeb;
              width: 217px;
              margin-top: -5px;
              padding-bottom: 19px;
              h5 {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 26px;
              }
              h5:hover {
                color: #3489fe;
              }
              p {
                margin-top: 10px;
                span {
                  font-size: 14px;
                  color: #999999;
                  margin-right: 20px;
                }
              }
            }
          }
        }

        .main_item:last-child {
          margin-bottom: 0px;
          .content_right {
            border-bottom: 0px dashed #ebebeb;
          }
        }
      }
    }
  }
}
</style>