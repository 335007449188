<template>
  <div class="NewFileUploadModule">

    <input class="bigFile_box_1" type="file" :multiple="fileLimit != 1" @change="inputChangeEvent" :accept="fileType">

    <slot></slot>

    <div v-if="isShowFileRule" class="uploading_tips_div">只能上传{{fileType.join('、')}}文件，且不超过{{fileMaxSize}}MB </div>

    <div v-if="isShowProgressBar" class="progress_div">
      <div class="progress_item" v-for="(item, index) in filesArr" :key="index">
        <div class="progress_name"> <i class="el-icon-document progress_text_icon"></i>{{item.name}}</div>
        <el-progress v-if="item.progressNum!=100" :percentage="item.progressNum" :stroke-width="2"></el-progress>
        <div v-if="item.progressNum==100" class="progress_icon_box">
          <i class="el-icon-circle-check"></i>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { MessageBox, Message, Loading } from 'element-ui';
const jsyUploadFn = require('./jsyUpload-new.js');
export default {
  name: "NewFileUploadModule", // 金山云上传组件
  props: {
    // 是否显示文件规则提示
    isShowFileRule: {
      type: [Boolean],
      default: true,
    },
    // 是否显示进度条
    isShowProgressBar: {
      type: [Boolean],
      default: true,
    },
    // 文件上传个数
    fileLimit: {
      type: [Number, String],
      default: 1,
    },
    // 上传文件大小
    fileMaxSize: {
      type: [Number, String],
      default: 20,
    },
    // 上传文件类型
    fileType: {
      type: Array,
      default: () => [],
      // default: () => [".jpg", ".png", ".gif", ".jpeg", ".webp", ".bmp"],
      // default: () => [".avi", ".wmv", ".mpeg", ".mp4", ".m4v", ".mov", ".asf", ".flv", ".f4v", ".rmvb", ".rm", ".3gp", ".vob"],
    },
    // 金山云配置项
    dcConfig: {
      type: Object,
      default: function () {
        return {
          key: "", // 后台域名对应key
          notify_url: "", // 上传接口地址
        };
      },
    },
  },
  data() {
    return {
      jsyUploadInit: null, // 金山云初始化对象
      isDisabled: true,
      filesArr: [],
    }
  },
  computed: {},

  components: {},

  methods: {
    // 暂停上传
    jsyPause() {
      this.jsyUploadInit.pause();
    },
    // 取消上传
    jsyCancel() {
      this.jsyUploadInit.cancel();
      this.isDisabled = false;
    },
    // 选择了文件
    inputChangeEvent(e) {
      let files = e.target.files || e.dataTransfer.files;
      let file_all_arr = Array.prototype.slice.call(files);
      e.target.value = ''; // 重复选择同一个文件也要生效

      let file_limit = this.fileLimit; // 文件个数限制
      let file_type = this.fileType; // 文件类型
      let file_size = this.fileMaxSize; // 单个文件大小（单位 MB）
      let durationTime = 3 * 1000; // 弹框持续时间

      if (file_all_arr.length > file_limit) {
        Message({
          dangerouslyUseHTMLString: true,
          message: `当前限制选择 ${file_limit} 个文件，本次选择了 ${file_all_arr.length} 个文件`,
          type: 'warning',
          duration: durationTime,
        });
        return false;
      };

      let filesArr = file_all_arr.filter((item_1, index_1) => {
        let file_name = item_1.name;
        let ext_index = file_name.lastIndexOf('.');
        let jsy_ext = file_name.substring(ext_index);
        // 校验 格式
        if (ext_index < 0 || (file_type.length && !file_type.includes(jsy_ext.toLowerCase()))) {
          let setTime_1 = setTimeout(() => {
            Message({
              dangerouslyUseHTMLString: true,
              message: `
              文件名字：${file_name}
              <br/>
              <br/>
              错误原因：文件格式不符合要求
              <br/>
              <br/>
              格式要求：文件格式为【${file_type.join('、')}】
              `,
              type: 'warning',
              duration: durationTime,
            });
            clearTimeout(setTime_1);
          }, 10);
          return false;
        };
        // 校验 大小
        if (item_1.size > (file_size * 1024 * 1024)) {
          let setTime_2 = setTimeout(() => {
            Message({
              dangerouslyUseHTMLString: true,
              message: `
              文件名字：${file_name}
              <br/>
              <br/>
              错误原因：文件大小不符合要求
              <br/>
              <br/>
              大小要求：单个文件大小限制【${file_size}MB】
              `,
              type: 'warning',
              duration: durationTime,
            });
            clearTimeout(setTime_2);
          }, 10);
          return false;
        };
        return true;
      });
      if (filesArr.length) {
        this.isDisabled = true;
        filesArr.forEach((item_2, index_2) => {
          this.$set(item_2, 'progressNum', 0);
          this.$set(item_2, 'file_index', index_2);
        });
        let filesAllArr = [];
        filesArr.forEach((item_file, index_2) => {
          filesAllArr.push(this.start_item_upload(item_file, filesArr));
        });
        Promise.allSettled([...filesAllArr]).then(res_All => {
          filesArr.forEach(itemFile => {
            this.$set(itemFile, 'progressNum', 100);
            this.$set(itemFile, 'tips_text', `正在上传... ${100}%`);
          });
          this.filesArr = filesArr;
          this.$forceUpdate();
          this.$emit("jsyResolve", filesArr); // 全部完成
          this.isDisabled = false;
        });
      };
    },
    // 上传一个文件
    start_item_upload(item_file, filesArr) {
      return new Promise((resolve, reject) => {
        let _this = this;
        this.jsyUploadInit.start({
          // 自定义参数
          data: {
            xk: "自定义1",
            xd: "自定义2",
            type: "自定义3"
          },
          // 上传进度
          on_progress: function (index, count) {
            let progressNum = Number((((index / count) * 100) - 0.2).toFixed(1));
            _this.$set(item_file, 'progressNum', progressNum);
            _this.$set(item_file, 'tips_text', `正在上传... ${progressNum}%`);
            _this.filesArr = filesArr;
            _this.$forceUpdate();
            _this.$emit("jsyPending", filesArr); // 进行中事件
          }
        }, function (err, results) {
          //上传结束
          if (err) {
            if (err.msg != 'stop') {
              console.log(err, '上传错误');
            } else {
              console.log(err, '上传暂停 错误');
            };
          } else {
            // 上传完成
            let tempObj_2 = results.upload.CompleteMultipartUploadResult;
            if (tempObj_2?.ETag) {
              _this.$set(item_file, 'Location', tempObj_2['Location']);
              _this.$set(item_file, 'ETag', tempObj_2['ETag'].slice(1, tempObj_2['ETag'].length - 1));
              _this.$set(item_file, 'etag', item_file['ETag']);
            };
            return resolve(tempObj_2);
          }
        }, item_file);
      });
    },
  },

  destroyed() {
  },

  async mounted() {
    this.jsyUploadInit = new jsyUploadFn(this.dcConfig);
    this.isDisabled = false;
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
.NewFileUploadModule {
  position: relative;
  display: inline-block;
  min-width: 500px;
  .bigFile_box_1 {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.uploading_tips_div {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
.progress_div {
  width: 100%;
  .progress_item {
    position: relative;
    height: 26px;
    width: 100%;
    margin-top: 5px;
  }
  .progress_name {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-right: 60px;
    font-size: 14px;
    line-height: 26px;
    color: #606266;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    padding-left: 4px;
  }
  .progress_text_icon {
    margin-right: 7px;
    color: #606266 !important;
  }
  /deep/.el-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-progress-bar {
      width: 100%;
      padding: 0;
      margin: 0;
      position: absolute;
      left: 0;
      top: 24px;
    }
    .el-progress__text {
      width: 60px;
      box-sizing: border-box;
      padding-right: 4px;
      text-align: right;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 26px;
      font-size: 12.8px;
    }
  }
  .progress_icon_box {
    width: 60px;
    box-sizing: border-box;
    padding-right: 4px;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 26px;
    color: #67c23a;
    font-size: 14px;
  }
  .progress_item {
    &:hover .progress_name {
      background-color: #f5f7fa;
      color: #409eff;
    }
    /deep/ &:hover .el-progress {
      .el-progress__text {
        color: #409eff !important;
      }
    }
  }
}
</style>