<template>
  <el-dialog title="选择用户" width="1200px" top="30px" :close-on-click-modal="false" :before-close="dialogHandleClose" :visible="dialogShow">
    <div class="main">
      <span class="temp_class_span_div">-777</span>
      <div v-if="false" class="main_one">
        <div class="item_box">
          <p>地区：</p>
          <el-select v-model="tearm.province_id" placeholder="选择省" @change="provinceChange">
            <el-option v-for="(item, index) in provinceOption" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="tearm.city_id" placeholder="选择市" @change="cityChange">
            <el-option v-for="(item, index) in cityOption" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="tearm.county_id" placeholder="选择区">
            <el-option v-for="(item, index) in areaOption" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="item_box">
          <p>学段学科：</p>
          <el-select v-model="tearm.stage_id" placeholder="请选择学段" @change="sectionChange">
            <el-option v-for="(item, index) in sectionOption" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="tearm.subject_id" placeholder="请选择学科">
            <el-option v-for="(item, index) in courseOption" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <div class="main_two">
        <div class="item_box">
          <p>教研组：</p>
          <el-select v-model="tearm.group_id" placeholder="请选择教研组">
            <el-option v-for="(item, index) in teachingGroupOption" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="item_box">
          <p>姓名：</p>
          <el-input v-model="tearm.realname" placeholder="请输入姓名"></el-input>
        </div>
        <div class="button_box">
          <div class="reset_button" @click="resetButton()">重置</div>
          <div class="sousuo_button" @click="queryButton()">查询</div>
        </div>
      </div>
      <div class="main_three">
        <el-checkbox v-model="tearm.is_org" true-label="1">
          本校成员
        </el-checkbox>
        <el-checkbox v-model="isSelect">
          查看已选成员（
          <span class="member_number"> {{ tearmTableChecked.length }} </span>
          ）
        </el-checkbox>
      </div>
    </div>

    <el-table :data="tearmList" stripe @select-all="tearmChoiceAll" ref="tearmTable" class="dataTable">
      <el-table-column type="selection" width="50">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.selectState" @change="tearmChoice(scope.row)">
          </el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="realname" label="姓名" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="stage_name" label="学段" width="100"></el-table-column>
      <el-table-column prop="subject_name" label="学科" width="100"></el-table-column>
      <el-table-column prop="group_name" label="所属教研组" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="org_name" label="所属单位" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="area" label="所属地区"></el-table-column>
    </el-table>

    <div class="pagination-box">
      <el-pagination background :current-page="tearm.page" :page-size="tearm.limit" layout="prev, pager, next, jumper" :total="tearm.total"
        @current-change="currentChange"></el-pagination>
    </div>

    <!-- <DataTable
      class="dataTable"
      :fetch="apiTearm"
      :filter="tearm"
      v-model="tearmTableChecked"
      ref="dataTable"
    >
      <DataTableColumn type="selection" width="55"> </DataTableColumn>
      <DataTableColumn column="realname" title="姓名"></DataTableColumn>
      <DataTableColumn
        column="stage_name"
        title="学段"
        width="100"
      ></DataTableColumn>
      <DataTableColumn
        column="subject_name"
        title="学科"
        width="100"
      ></DataTableColumn>
      <DataTableColumn column="group_name" title="所属教研组"></DataTableColumn>
      <DataTableColumn column="org_name" title="所属单位"></DataTableColumn>
      <DataTableColumn column="area" title="所属地区"></DataTableColumn>
    </DataTable> -->

    <div class="bottom_button_box">
      <el-button @click="cancelButton()">取消</el-button>
      <el-button type="primary" @click="defineButton()">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import TearmTable from "./tearmTable.js";
export default TearmTable;
</script>

<style lang="less" scoped>
@import "./tearmTable.less";
</style>