<template>
    <!-- 填空题 -->
  <el-dialog
    title="添加填空题"
    width="1200px"
    top="30px"
    :close-on-click-modal="false"
    :before-close="dialogHandleClose"
    :visible="dialogShow"
  >
    <el-form
      ref="subject"
      :rules="subjectRules"
      :model="subject"
      class="subject-form"
    >
      <el-form-item prop="title">
        <Editor v-model="subject.title"></Editor>
      </el-form-item>
      <el-form-item prop="type">
        <el-checkbox
          v-model="subject.is_required"
          :true-label="2"
          :false-label="1"
        >是否必填</el-checkbox>
      </el-form-item>
    </el-form>

    <div class="bottom-box">
      <el-button @click="cancelButton()">取消</el-button>
      <el-button
        type="primary"
        @click="defineButton('subject')"
      >
        {{ subject.hasOwnProperty("index") ? "修改" : "添加" }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import WriteSubject from "./writeSubject.js";
export default WriteSubject;
</script>

<style lang="less" scoped>
@import "./writeSubject.less";
</style>