<template>
  <div class="listeningcommentdetail">
    <StudioIndex v-if="is_show_head==1"></StudioIndex>
    <div class="w_d center_one">
      <div class="navBox">
        <p>
          当前位置：研修中心&nbsp;>&nbsp;
          <span>研修视频</span>&nbsp;>&nbsp;
          <span>视频详情</span>
        </p>
      </div>
      <div class="one_content">
        <div class="images">
          <img :src="detailData.cover_url" />
          <div class="textbox">
            <comPlain :type="3" :id="detailData.id" :is_from="is_from" />
          </div>
        </div>
        <div class="word">
          <div class="word_one">
            <p>{{ detailData.name }}</p>
          </div>
          <div class="word_two">
            <span>课程分类：</span>
            <span>听评课{{ showxianStr() }}</span>
            <span>学段学科：</span>
            <span>{{ detailData.stage_name }}{{ detailData.subject_name }}</span>
            <span>年级：</span>
            <span>{{ detailData.stage_name }}{{ detailData.grade_name }}</span>
            <span>教材版本：</span>
            <span>{{ detailData.edition_name }}</span>
          </div>
          <div class="word_three">
            <span>适用章节：</span>
            <span :title="detailData.chapter_name">{{
              detailData.chapter_name
            }}</span>
            <span>授课教师：</span>
            <span>{{ detailData.teacher }}老师</span>
            <span v-if="detailData.teaching_form && detailData.teaching_form == '1'">授课时间：</span>
            <span v-if="detailData.teaching_form && detailData.teaching_form == '1'" class="ellipsis">
              {{ detailData.start_date }}&nbsp;{{ detailData.start_time }}~{{
                detailData.end_date
              }}&nbsp;{{ detailData.end_time }}
            </span>
          </div>
          <div class="word_five">
            <span v-if="detailData.teaching_form && detailData.teaching_form == '1'">授课地点：</span>
            <span v-if="detailData.teaching_form && detailData.teaching_form == '1'">{{ detailData.course_address }}</span>
            <span>评课时间：</span>
            <span>
              {{ detailData.lesson_start_date }}&nbsp;&nbsp;{{ detailData.lesson_start_time }}
              ~ {{ detailData.lesson_end_date }}&nbsp;&nbsp;{{detailData.lesson_end_time}}</span>
          </div>
          <p v-if="false">
            课程访问码：<span style="color: #ff8201; font-weight: bold">{{
              detailData.visit_code
            }}</span>
          </p>
          <div class="word_four">
            <new-invitation :access_code="detailData.visit_code" describe_string="课程" describe_two_string="听评课"></new-invitation>
          </div>
          <!-- <div class="word_six">
            <span>参与范围：</span>
            <span>指定成员</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="two_content">
      <div class="center_main-content w_d">
        <div class="one">
          <h4>授课说明</h4>
          <!-- <i class="el-icon-edit-outline"></i> -->
          <div class="two">
            <p class="rich_text_div_img" v-html="detailData.presentation"></p>
          </div>
        </div>

        <div v-if="detailData.teaching_form && detailData.teaching_form == '1'">
          <div class="five">
            <p>上课资源</p>
          </div>
          <div class="six">
            <div class="resource_block" v-for="(item, index) in detailData.class_resource" :key="index">
              <div class="images">
                <img src="@/assets/images/word.png" />
              </div>
              <div class="word">
                <p>
                  {{ item.resource_name }}
                </p>
                <p>上传于 {{ item.create_time }}</p>
              </div>
              <new-viewonline v-if="unserInfoData" :viewSrc="item.resource_url"
                :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id"
                :fileExt="item.mime || item.ext" :itemData="item" :fileTitle="item.resource_name" :fileSize="item.size" :fileTime="item.create_time"
                fileNav="研修中心 > 研修视频 > 视频详情 > 查看">
                <p class="view cursor">在线查看</p>
              </new-viewonline>
              <el-link :href="item.resource_url" target="_blank" :underline="false" v-if="item.is_download==2 && unserInfoData"><span
                  class="down">下载</span>
              </el-link>
            </div>
          </div>
        </div>

        <div class="new_two_content" v-else>
          <div class="center_main-content w_d">
            <div class="left">
              <div class="left_one">
                <p>授课视频</p>
              </div>
              <div class="left_two" v-if="detailData.video_resource">
                <div v-if="!unserInfoData" class="page_login_div">
                  请先登录
                </div>

                <video v-if="unserInfoData" :src="detailData.video_resource.resource_url" type="video/mp4" poster="false.png" autoplay="autoplay"
                  controls="controls" style="width: 100%; height: 659px">
                  <p>你的浏览器不支持video标签.</p>
                </video>
              </div>
            </div>
            <div class="right">
              <div class="right_one">
                <p>上课资源</p>
                <!-- <div class="add_resource">添加视频</div> -->
              </div>
              <div class="right_two">
                <div class="two_block" v-for="(item, index) in detailData.class_resource" :key="index">
                  <p class="goclass">
                    {{ item.resource_name }}
                  </p>
                  <div class="check">
                    <p>上传于 {{ item.create_time }}</p>
                    <div class="check_right">
                      <new-viewonline v-if="unserInfoData" :viewSrc="item.view_url"
                        :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id" :resourceId="item.r_resource_id || item.resource_id"
                        :fileExt="item.mime || item.ext" :itemData="item" :fileTitle="item.resource_name" :fileSize="item.size"
                        :fileTime="item.create_time" fileNav="研修中心 > 研修视频 > 视频详情 > 查看">
                        <el-link style="margin-right: 5px" target="_blank" :underline="false" type="primary"><span>查看</span></el-link>
                      </new-viewonline>
                      <el-link v-if="item.is_download==2 && unserInfoData" :href="item.resource_url" target="_blank" :underline="false"
                        type="primary">
                        <span>下载</span>
                      </el-link>
                    </div>
                  </div>
                  <div class="hengxian"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="seven">
          <p class="tsBox">评课笔记（{{ total }}）</p>
          <!-- v-if="detailData.is_permit == '1'" -->
          <el-button type="primary" @click="noteVisible = true">提交评课笔记</el-button>
        </div>

        <div class="eight_div">
          <CommonEmpty v-if="!noteData.length" hVal="200px"></CommonEmpty>
          <div class="eight_block" v-for="(item, index) in noteData" :key="index">
            <div class="eight_one">
              <div class="images">
                <img :src="item.photo_url" />
              </div>
              <div class="eight_word">
                <p>{{ item.username }}</p>
                <p>{{ item.create_time }}</p>
              </div>
            </div>

            <div class="eight_three">
              <p> {{ item.content }} </p>
            </div>
            <div class="eight_four"></div>
          </div>
          <div v-if="total" class="pagin">
            <el-pagination background :current-page="page" :page-size="limit" layout="prev, pager, next, jumper" :total="total"
              @current-change="handleCurrentChange"></el-pagination>
          </div>
        </div>

      </div>
    </div>

    <!-- 提交评课笔记对话框 -->
    <el-dialog title="提交评课笔记" width="767px" :visible.sync="noteVisible" @close="notecloseDialog">
      <el-form label-width="120px" ref="FormTempData" :model="form" :rules="rules1">

        <el-form-item label="评课内容:" prop="content">
          <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="form.content" style="width: 508px" resize="none">
          </el-input>
        </el-form-item>

        <el-form-item v-if="false" label="上传图片/视频:">
          <new-addresource :ifsingle="false" @ouput-value="getResource"></new-addresource>
          <p>
            {{ form.resource_name }}
            <span style="color: #409eff; margin-left: 20px" class="cursor" v-if="form.resource_name != ''" @click="deleteUpResource">删除</span>
          </p>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="postNote">提交</el-button>
          <el-button @click="noteVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { localGet, localSet, localRemove } from "@/utils/utils.js";
import { isHasStudioIdFn } from "@/utils/utils.js";
export default {
  components: {
  },
  data() {
    return {
      rules1: {
        content: [{ required: true, message: '请输入评课内容', trigger: 'blur' }],
      },
      unserInfoData: null,
      isToken: false,
      // 课程id
      id: "",
      // 详情总数据
      detailData: {},
      // 邀请对话框
      invitationDilog: false,
      // 当前页面的地址
      dizhi: window.location.href,
      //PDF预览
      viewVisible: false,
      src: "",
      pdfPage: 1,
      pageCount: 0,
      //视频显示与否
      shipinDialogVisible: false,
      //视频url
      shipingurl: "",
      // 文档的url
      wordurl: "",
      // 文档显示与否
      docxDialogVisible: false,
      //图片url
      imageurl: "",
      // 图片预览的显示与隐藏
      imageDialogVisible: false,
      // 当前页面
      page: 1,
      // 每页条数
      limit: 24,
      // 评课笔记总数目
      total: 0,
      // 笔记数据
      noteData: [],
      // 提交评课笔记对话框
      noteVisible: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      // 上传资源表单
      form: {
        resource_name: "",
        resource_id: "",
        text: "",
      },
      // 1研修视频，2四名工作室的录播课程
      is_from: 1,
      is_show_head: null,
    };
  },
  created() {
    let unserInfoData = localGet("unserInfoData");
    if (unserInfoData && unserInfoData.id && unserInfoData.token) {
      this.unserInfoData = unserInfoData;
    } else {
      this.unserInfoData = null;
    };
    if (this.$route.query.is_show_head) {
      if (isHasStudioIdFn(this)) {
        this.is_show_head = this.$route.query.is_show_head
      }
    };
    this.init();
    let token = localStorage.getItem("token");
    this.isToken = token == null ? false : true;
    if (this.isToken) {
      this._getAddress();
    }
  },
  methods: {
    //跳转上一列表
    toList() {
      this.$router.push("/researchcource");
    },
    one() {
      this.$router.push("/goclassdetail");
    },
    // 初始化函数
    init() {
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.getDetail();
      this.getbiji();
    },
    //请求后台获取视频详情
    getDetail() {
      if (this.is_from == 1) {
        this.$axios.get(`course/get_info/${this.id}`).then((res) => {
          this.detailData = res.data.data;
        });
      } else {
        this.$axios.get(`index/TeachingStudioCourse/infoTeachingStudioCourse`, {
          params: {
            id: this.id,
          },
        }).then((res) => {
          this.detailData = res.data.data;
        });
      }
    },
    // 请求后台获取评课笔记数据
    getbiji() {
      var obj = {
        params: {
          limit: this.limit,
          page: this.page,
        },
      };
      if (this.is_from == 1) {
        obj.params.course_id = this.id;
        this.$axios.get("courseNote/get_list", obj).then((res) => {
          this.total = res.data.data.total;
          this.noteData = res.data.data.data;
        });
      } else {
        obj.params.teaching_studio_course_id = this.id;
        this.$axios.get("index/TeachingStudioCourseNote/get_list", obj).then((res) => {
          this.total = res.data.data.total;
          this.noteData = res.data.data.data;
        });
      }

    },
    //   切换当前页码时触发
    handleCurrentChange(val) {
      this.page = val;
      this.getbiji();
    },
    //上传失败的时候会出现Bug，故在上传失败的时候也进行一下判断
    error: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      var resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      this.form.ETag = resData.ETag;
      this.form.name = data.filename;
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: this.form.ETag,
        },
      });
      this.form.cover_url = resData.Location;
      this.form.resource_id = res.data.data.id;
    },
    //显示线上线下字符串
    showxianStr() {
      if (this.detailData.teaching_form) {
        if (this.detailData.teaching_form == "2") {
          return "（线上）";
        } else if (this.detailData.teaching_form == "1") {
          return "（线下）";
        }
      }
    },
    // 请求后台提交评课笔记
    postNote() {
      this.$refs['FormTempData'].validate((valid) => {
        if (valid) {
          let obj = {
            content: this.form.content,
            // resource_id: this.form.resource_id,
          };
          if (this.is_from == 1) {
            obj.course_id = this.id;
            this.$axios.post("courseNote/add_note", obj).then((res) => {
              this.noteVisible = false;
              this.getbiji();
            });
          } else {
            obj.teaching_studio_course_id = this.id;
            this.$axios.post("index/TeachingStudioCourseNote/add_note", obj).then((res) => {
              this.noteVisible = false;
              this.getbiji();
            });
          }
        }
      })
    },
    // 评课笔记对话框关闭函数
    notecloseDialog() {
      this.form = {
        resource_name: "",
        resource_id: "",
        text: "",
      };
    },
    // 得到我的资源数据函数
    getResource(val) {
      this.form.resource_id = val[0].id;
      this.form.resource_name = val[0].title;
    },
    // 删除上传的资源
    deleteUpResource() {
      this.form.resource_name = "";
      this.form.resource_id = "";
    },
  },
};
</script>

<style scoped lang="less">
.listeningcommentdetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  .navBox {
    width: 1400px;
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 30px;
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #999999;
      span:last-child {
        color: #ff8201;
      }
      .onTo {
        cursor: pointer;
        &:hover {
          color: #409eff;
        }
      }
    }
  }
  .center_one {
    .one_content {
      display: flex;
      margin-bottom: 44px;
      .images {
        position: relative;
        z-index: 1;
        img {
          width: 520px;
          height: 293px;
          border-radius: 6px;
        }
        .textbox {
          position: absolute;
          bottom: 5px;
          right: 0px;
        }
      }
      .word {
        margin-left: 58px;
        margin-top: 18px;
        .word_one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 33px;
          p {
            font-size: 22px;
            font-weight: 700;
          }
          i {
            font-size: 22px;
            margin-left: 15px;
            color: #009dff;
          }
        }
        .word_two {
          display: flex;
          margin-bottom: 14px;
          span:nth-child(odd) {
            color: #999999;
          }
          span:nth-child(2) {
            margin-right: 30px;
          }
          span:nth-child(4) {
            width: 105px;
            margin-right: 30px;
          }
          span:nth-child(6) {
            width: 105px;
            margin-right: 30px;
          }
        }
        .word_three {
          display: flex;
          margin-bottom: 14px;
          span:nth-child(odd) {
            color: #999999;
          }
          span:nth-child(2) {
            width: 160px;
            margin-right: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span:nth-child(4) {
            width: 105px;
            margin-right: 30px;
          }
        }
        .word_four {
          display: flex;
          align-items: center;
          margin-top: 26px;
          .startup {
            width: 100px;
            height: 34px;
            border: 1px solid #3489fe;
            border-radius: 4px;
            font-weight: 700;
            color: #3489fe;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .word_five {
          display: flex;
          margin-bottom: 32px;
          span:nth-child(odd) {
            color: #999999;
          }
          span:nth-child(2) {
            margin-right: 30px;
          }
        }
        .word_six {
          display: flex;
          margin-bottom: 30px;
          span:nth-child(odd) {
            color: #999999;
          }
        }
      }
    }
  }
  .two_content {
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    justify-content: center;
    .center_main-content {
      .one {
        padding: 38px 34px;
        margin-top: 26px;
        border-radius: 6px;
        background: #ffffff;
        h4 {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 35px;
        }
        i {
          font-size: 22px;
          margin-left: 17px;
          color: #009dff;
        }
        .two {
          width: 1331px;
          max-height: 720px;
          border-radius: 6px;
          overflow: auto;
          p {
            text-indent: 2em;
            height: 1;
          }
        }
      }
      .five {
        margin-top: 41px;
        margin-bottom: 23px;
        display: flex;
        align-items: center;
        p {
          font-size: 22px;
          font-weight: 700;
          margin-right: 25px;
        }
        .add_resource {
          width: 108px;
          height: 38px;
          background: #3489fe;
          border-radius: 6px;
          font-weight: 700;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .six {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .resource_block {
          width: 690px;
          height: 89px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 6px;
          padding-top: 24px;
          padding-bottom: 24px;
          margin-bottom: 26px;
          display: flex;
          align-items: center;
          .images {
            margin-left: 34px;
          }
          .word {
            margin-left: 30px;
            p:nth-child(1) {
              width: 316px;
              font-weight: 700;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 14px;
            }
            p:nth-child(2) {
              font-size: 14px;
              color: #666666;
            }
          }
          .view {
            font-weight: 700;
            color: #3489fe;
            margin-left: 53px;
          }
          .down {
            font-weight: 700;
            // color: #999999;
            color: #3489fe;
            margin-left: 53px;
            font-size: 16px;
          }
        }
      }
      .seven {
        background: #ffffff;
        .tsBox {
          font-size: 22px;
          font-weight: 700;
        }
        padding: 35px 36px;
        padding-bottom: 0px;
        margin-top: 26px;
        display: flex;
        justify-content: space-between;
      }
      .eight_div {
        width: 100%;
        box-sizing: border-box;
        padding: 40px 46px 60px 30px;
        background: #ffffff;
        border-radius: 6px;
        margin-bottom: 30px;
        .eight_block {
          .eight_one {
            display: flex;
            align-items: center;
            .images {
              margin-right: 19px;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 50px;
                height: 50px;
              }
            }
            .eight_word {
              p:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #fa8c15;
                margin-bottom: 5px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                color: #9a9ea5;
              }
            }
          }
          .eight_two {
            width: 420px;
            height: 236px;
            border-radius: 6px;
            margin-left: 69px;
            margin-top: 34px;
            margin-bottom: 33px;
            img {
              width: 420px;
              height: 236px;
            }
          }
          .down_ziyuan {
            margin-left: 69px;
            margin-bottom: 33px;
          }
          .eight_three {
            line-height: 30px;
            margin-left: 69px;
          }
          .eight_four {
            margin-left: 69px;
            border-top: 1px dashed #ececec;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        .eight_block:last-child {
          .eight_four {
            margin: 0px;
            border: 0px;
          }
        }
      }
    }
  }
  .invitation {
    p:nth-child(1) {
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 30px;
    }
    p:nth-child(2) {
      margin-bottom: 30px;
      line-height: 30px;
    }
    p:nth-child(3) {
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 30px;
    }
    .three-input {
      width: 300px;
      height: 25px;
      line-height: 25px;
      margin-right: 10px;
    }
  }
  .new_two_content {
    background-color: #f7f7f7;
    width: 100%;
    display: flex;
    justify-content: center;
    .center_main-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .left {
        width: 1045px;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        background: #ffffff;
        margin-top: 26px;
        padding: 38px 34px;
        padding-bottom: 15px;
        .left_one {
          display: flex;
          align-items: center;
          p:nth-child(1) {
            font-size: 22px;
            font-weight: 700;
            margin-right: 26px;
          }
          .add_video {
            width: 108px;
            height: 38px;
            background: #3489fe;
            border-radius: 6px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 18px;
          }
          p:nth-child(3) {
            color: #9a9ea5;
          }
        }
        .left_two {
          width: 100%;
          height: 659px;
          background: #ffffff;
          border-radius: 6px;
        }
      }
      .right {
        width: 335px;
        background: #ffffff;
        margin-top: 26px;
        .right_one {
          margin: 26px 0px;
          padding-left: 30px;
          padding-right: 33px;
          display: flex;
          align-items: center;
          p:nth-child(1) {
            font-size: 22px;
            font-weight: 700;
            margin-right: 25px;
          }
          .add_resource {
            width: 108px;
            height: 38px;
            background: #3489fe;
            border-radius: 6px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .right_two {
          width: 272px;
          height: 659px;
          padding-left: 30px;
          padding-right: 33px;
          background: #ffffff;
          border-radius: 6px;
          overflow: auto;
          .two_block {
            height: 134px;
            .goclass {
              font-weight: 700;
              margin-bottom: 12px;
              line-height: 26px;
            }
            .check {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 24px;
              p:nth-child(1) {
                font-size: 14px;
                color: #999999;
              }
              .check_right {
                display: flex;
                font-size: 14px;
                span:nth-child(2) {
                  margin-left: 20px;
                }
              }
              /deep/ .el-input__inner {
                height: 28px;
                padding: 0 11px;
              }
              /deep/ .el-input__icon {
                line-height: 0px;
              }
              /deep/ .el-input--suffix .el-input__inner {
                padding-right: 0px;
              }
            }
            .hengxian {
              width: 100%;
              border-top: 1px dashed #ebebeb;
            }
          }
        }
      }
    }
  }
}
.page_login_div {
  display: flex;
  justify-content: center;
  color: #333;
  font-size: 26px;
  padding-top: 30px;
}
</style>