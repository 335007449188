<template>
  <div class="public_main_bgc">
    <div class="bannerSty">
      <el-carousel trigger="click" height="320px">
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/banner_sanming.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/shouye_banner.mingshi.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/mingshi_banner.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="public-width">
      <!-- 四名工作室 -->
      <div class="">
        <div class="title_box">
          <div class="title_name">四名工作室</div>
          <div class="title_btn" @click="goMuchPageMore(1)">查看更多>></div>
        </div>
        <div class="gongzuoBox">
          <div class="gongzuoTop df">
            <CommonEmpty v-if="!gongzuoList2.length" hVal="100%"></CommonEmpty>
            <div class="gongzuoli" v-for="(item, index) in gongzuoList2" :key="index" v-show="index < 3">
              <div class="citiesBox" v-if="item.provinces_cities_counties_str">
                {{ item.provinces_cities_counties_str }}
              </div>
              <div class="iconBox">
                <img v-if="index == 0" src="@/assets/navIcon/studioImg/mingshi1.png" alt="">
                <img v-if="index == 1" src="@/assets/navIcon/studioImg/mingshi2.png" alt="">
                <img v-if="index == 2" src="@/assets/navIcon/studioImg/mingshi3.png" alt="">
              </div>
              <div class="box1 df mb25">
                <div class="imgbox">
                  <img :src="item.photo_url" alt="" />
                </div>
                <div class="txtbox">
                  <h4 class="ellipsis mb10">{{ item.teaching_studio_name }}</h4>
                  <h5 class="mb10">{{ item.org_name }}</h5>
                  <!-- <p>{{ item.dictionary_name }}教师</p> -->
                  <p>{{ item.host_type == 1 ? "名教师" : item.host_type == 2 ? "名班主任" : item.host_type == 3 ? "名校（园）长" : item.host_type == 5 ? "名书记" : "未知类型" }}</p>
                  <p class="ml10">{{ item.stage_name }}{{ item.subject_name }}</p>
                </div>
              </div>
              <div class="box2">
                <div class="box_tiem_2 ellipsis cp" @click="handlePageEvent(item.TeachingStudioCourse.go_url, item)">
                  <p class="ellipsis">
                    <span>【课程推荐】</span>{{ item.TeachingStudioCourse.name || "-" }}
                  </p>
                </div>
                <div class="box_tiem_2 ellipsis cp" @click="handlePageEvent(item.TeachingStudioArticle.go_url, item)">
                  <p class="ellipsis">
                    <span>【活动推荐】</span>{{ item.TeachingStudioArticle.name || "-" }}
                  </p>
                </div>
                <div class="box_tiem_2 ellipsis cp"
                  @click="handlePageEvent(item.TeachingStudioUserResource.go_url, item)">
                  <p class="ellipsis">
                    <span>【资源推荐】</span>{{ item.TeachingStudioUserResource.name || "-" }}
                  </p>
                </div>
                <div class="df box_tiem_3">
                  <p class="mr30"><span>{{ item.user_count }}</span>成员数</p>
                  <i class="line"></i>
                  <p class="ml24 mr30"><span>{{ item.live_course_article_count }}</span>资源总数</p>
                  <i class="line"></i>
                  <p class="ml24"><span>{{ item.view_count }}</span>访问</p>
                </div>
                <p class="look_more cp" @click="go_urls(item.go_url)">进入工作室></p>
              </div>
            </div>
          </div>

          <div class="gongzuoBom df">
            <div class="gongzuoLis" v-for="(item, index) in gongzuoList" :key="index">
              <div class="citiesBox" v-if="item.provinces_cities_counties_str">
                {{ item.provinces_cities_counties_str }}
              </div>
              <div class="litop df cp" @click="go_urls(item.go_url)">
                <div class="lileft">
                  <img :src="item.photo_url" alt="" />
                </div>
                <div class="liright">
                  <h4 class="ellipsis">{{ item.teaching_studio_name }}</h4>
                  <div class="df">
                    <p class="itemsty">成员：{{ item.user_count }}</p>
                    <p class="itemsty ml20">资源总量：{{ item.live_course_article_count }}</p>
                  </div>
                  <div class="stadbox df">
                    <p class="stad">
                      {{ item.stage_name || "年级"
                      }}{{ item.subject_name || "学科" }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="libom">
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioCourse.go_url, item)">
                  <span class="ellipsis">
                    <span>【课程推荐】</span>{{ item.TeachingStudioCourse.name || "-" }}
                  </span>
                </p>
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioArticle.go_url, item)">
                  <span class="ellipsis">
                    <span>【活动推荐】</span>{{ item.TeachingStudioArticle.name || "-" }}
                  </span>
                </p>
                <p class="ellipsis cp" @click="handlePageEvent(item.TeachingStudioUserResource.go_url, item)">
                  <span class="ellipsis">
                    <span>【资源推荐】</span>{{ item.TeachingStudioUserResource.name || "-" }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 四名讲堂/新闻公告 -->
      <div class="leftRightBox df dfb">
        <!-- 四名讲堂 -->
        <div class="leftBox mt30" style="width:100%">
          <div class="publicTitle df dfb mb20">
            <h4>四名讲堂</h4>
            <p class="cp" @click="goMuchCourse()">查看更多>></p>
          </div>
          <div class="msjtBox mb30 df">
            <CommonEmpty v-if="!xiangmuList.length" hVal="100%"></CommonEmpty>
            <div class="msjtli cp" v-for="(item, index) in xiangmuList" :key="index"
              @click="go_urlsJiangt(item.go_url, item)" :class="item.status == 1 ? 'nowLive' : ''">
              <img v-if="item.cover_url" class="titleImg" :src="item.cover_url" alt="" />
              <img class="titleImg" v-else src="@/assets/images/banner/home_banner.png" alt="" />
              <div>
                <p class="titles ellipsis-2 mt18 mb13">
                  {{ item.name }}
                </p>
                <p class="times mb12">培训时间:{{ item.create_time }}</p>
                <div v-if="item.status_text" class="tios_div_box_1_div tios_div_box_2_div">{{ item.status_text }}</div>

                <div class="ziyubox mb10">
                  <div class="df" v-for="(item, index) in item.resource" :key="index">
                    <div class="mr8">
                      <img :src="item.mime | fileTypeMini" alt="" />
                    </div>
                    <p class="ellipsis">
                      {{ item.resource_name }}
                    </p>
                  </div>
                </div>
                <div class="bomimgBox df dfb">
                  <div class="df">
                    <img class="mr12" :src="item.photo_url" alt="" v-if="item.photo_url" />
                    <img v-else class="mr12" src="@/assets/images/user.png" alt="" />
                    <p class="ellipsis-1">{{ item.user_name || item.realname || item.author }}</p>
                  </div>
                  <p class="ellipsis-1">{{ item.teaching_studio_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 新闻公告 -->
        <div v-if="false" class="rightBox mt30">
          <div class="publicTitle df dfb mb20">
            <h4>新闻公告</h4>
            <!-- <p class="cp" @click="goMuchNews()">查看更多>></p> -->
          </div>
          <div class="msXinWen">
            <CommonEmpty v-if="!xinWenlist.length" hVal="100%"></CommonEmpty>
            <div class="xinwenli df dfb" v-for="(item, index) in xinWenlist" :key="index" @click="go_urls(item.go_url)">
              <div class="xwleft">
                <h4 class="ellipsis-2">{{ item.user_name }}{{ item.title }}</h4>
                <p>{{ item.create_time }}</p>
              </div>
              <div class="xwright">
                <img v-if="item.photo_url" :src="item.photo_url" alt="" />
                <img v-else src="@/assets/images/banner/home_banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 教研活动/工作室排行 -->
      <div class="leftRightBox df dfb">
        <!-- 教研活动 -->
        <div class="leftBox">
          <div class="publicTitle df dfb mb20">
            <h4>教研活动</h4>
            <p class="cp" @click="goMuchActive()">查看更多>></p>
          </div>
          <div class="msjtBox-teaching mb30 df">
            <CommonEmpty v-if="!huodongList.length" hVal="100%"></CommonEmpty>
            <div class="msjtli cp" v-for="(item, index) in huodongList" :key="index"
              @click="go_urls(item.go_url, item)">
              <img class="titleImg" :src="item.cover_url" alt="" />
              <div>
                <p class="titles ellipsis-2 mt24 mb8">
                  {{ item.name }}
                </p>
                <div class="timebtn df">
                  <p>{{ item.type_name || "主题研讨" }}</p>
                  <p>{{ item.implement_type || "线上活动" }}</p>
                </div>
                <div class="timesab">
                  活动时间:{{ item.start_date }}~{{ item.end_date }}
                </div>
                <!-- <div class="status">{{ item.status_name }}</div> -->
                <!-- <div v-if="item.status == 1" class="tios_div_box_1_div">进行中 </div>
                <div v-if="item.status == 2" class="tios_div_box_1_div tios_div_box_2_div">未开始 </div>
                <div v-if="item.status == 3" class="tios_div_box_1_div tios_div_box_3_div">已结束 </div> -->

                <div v-if="item.web_status == 1" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
                <div v-if="item.web_status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
                <div v-if="item.web_status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
                <div v-if="item.web_status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
                <div v-if="item.web_status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
                <div v-if="item.web_status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div>
                <div class="ziyubox">
                  <div class="df" v-for="(item, index) in item.resource" :key="index">
                    <div class="mr8">
                      <img :src="item.mime | fileTypeMini" alt="" />
                    </div>
                    <p class="ellipsis">
                      {{ item.resource_name }}
                    </p>
                  </div>
                </div>
                <div class="pt10 bomimgBox df dfb">
                  <div class="df">
                    <img class="mr12" :src="item.photo_url" alt="" v-if="item.photo_url" />
                    <img v-else class="mr12" src="@/assets/images/user.png" alt="" />
                    <p>{{ item.org_name }}</p>
                  </div>
                  <p>{{ item.user_count }}人参加</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 工作室排行 -->
        <div class="rightBox">
          <div class="publicTitle df dfb mb20">
            <h4>研修动态</h4>
            <!-- <p class="cp" @click="goMuchPageMore(1)">查看更多>></p> -->
          </div>

          <div class="NumWorkSty" style="height:416px">
            <div id="parent" class="parent" style="height:370px">
              <div id="child1" class="child">
                <CommonEmpty v-if="!dongtaiList.length" hVal="100%"></CommonEmpty>
                <div class="box" v-for="(item, index) in dongtaiList" :key="index">
                  <div class="boxtop df">
                    <div class="imgbox">
                      <img :src="item.photo_url" alt="" />
                    </div>
                    <div class="txtBox">
                      <div class="txttop df">
                        <p class="ellipsis mr15">{{ item.username }}</p>
                        <span>{{ item.create_time }}</span>
                      </div>
                      <p class="ellipsis mt6">{{ item.username }}的工作室</p>
                    </div>
                  </div>
                  <div class="boxbom cp" @click="handlePageInfo_1(item)">
                    <div class="df">
                      <span>{{ item.commet }}</span>
                      <p class="ellipsis">{{ item.title }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="child2" class="child"></div>
            </div>
          </div>

        </div>
      </div>

      <!-- 研修资源 -->
      <div class="leftRightBox df dfb">
        <!-- 研修资源 -->
        <div class="leftBox left_box">
          <div>
            <div class="publicTitle df dfb mb20">
              <h4>研修资源</h4>
            </div>
            <div class="ziyuanBox ziyuan_box_1 ziyuanBoxab">
              <CommonEmpty v-if="!ziyuanList.length" hVal="100%"></CommonEmpty>
              <div class="aboutbg">
              </div>
              <div class="aboutimg">
                <img src="@/assets/navIcon/studioImg/HOT.png" alt="">
              </div>
              <div class="zitiLi df ziyuan_box_item" v-for="(item, index) in ziyuanList" :key="index">
                <div class="ziti1">
                  <img :src="item.ext | fileTypeSmall" alt="">
                </div>
                <div class="ziti2">
                  <new-viewonline :viewSrc="item.view_url"
                    :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
                    :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext"
                    :itemData="item" :fileTitle="item.title" :fileSize="item.size" :fileTime="item.create_time"
                    fileNav="四名工作室 > 研修资源 > 查看" :is_show_head="item.teaching_studio_id ? 1 : ''"
                    :teaching_studio_id="item.teaching_studio_id ? item.teaching_studio_id : ''">
                    <h4 class="ellipsis-1">
                      {{ item.title }}
                    </h4>
                  </new-viewonline>
                  <p>
                    {{ item.stage_name || "-" }}
                    <i class="line"></i>
                    {{ item.subject_name || "-" }}
                    <i class="line"></i>
                    {{ item.grade_name || "-" }}
                    <span class="census ml40"><i class="el-icon-view mr5"></i>{{ item.view_count }}</span>
                    <span class="census"><i class="el-icon-star-off mr5"></i>{{ item.collect_count }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="publicTitle df dfb mb20">
              <h4>教师文章</h4>
            </div>
            <div class="ziyuanBox ziyuan_box_1 ziyuanBoxab">
              <div class="aboutbg aboutbg2">
              </div>
              <div class="aboutimg">
                <img src="@/assets/navIcon/studioImg/TOP.png" alt="">
              </div>
              <CommonEmpty v-if="!wenzhangList.length" hVal="100%"></CommonEmpty>
              <div class="zitiLi df" v-for="(item, index) in wenzhangList" :key="index">
                <div class="ziti1-article">
                  <img v-if="item.type_name == '案例分析'" src="~@/assets/images/iconFile/wenzhang1.png" alt="" />
                  <img v-else-if="item.type_name == '教学反思'" src="~@/assets/images/iconFile/wenzhang2.png" alt="" />
                  <img v-else-if="item.type_name == '教学总结'" src="~@/assets/images/iconFile/wenzhang3.png" alt="" />
                  <img v-else-if="item.type_name == '学习心得'" src="~@/assets/images/iconFile/wenzhang4.png" alt="" />
                  <img v-else src="~@/assets/images/iconFile/other.png" alt="" />
                </div>
                <div class="ziti2">
                  <h4 @click="go_urls(item.go_url, item)" class="ellipsis-1">
                    {{ item.name }}
                  </h4>
                  <p>
                    {{ item.username }}
                    <span class="ml25 mr15 c-ccc"></span>
                    <i class="el-icon-view mr5"></i>{{ item.view_count }}
                    <span class="ml15"></span>
                    <i class="el-icon-star-off mr5"></i>{{ item.like_count }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 活动报道 -->
        <div class="rightBox">
          <div class="publicTitle df dfb mb20">
            <h4>活动报道</h4>
            <!-- <p class="cp" @click="goMuch(3)">查看更多>></p> -->
          </div>
          <div class="NumWorkSty">
            <div id="parent" class="parent">
              <div id="child1" class="child">
                <CommonEmpty v-if="!studioReportList.length" hVal="100%"></CommonEmpty>
                <div class="hdbdbox df cp" v-for="(item, index) in studioReportList" :key="index"
                  @click="goReportli(item)">
                  <div class="boxtop">
                    <div class="txttop df">
                      <p class="ellipsis-2">{{ item.title }}</p>
                    </div>
                    <div class="bomSty df">
                      <p class="ellipsis-1">{{ item.type_name }}</p>
                      <p class="ellipsis-1">{{ item.nature_name }}</p>
                    </div>
                  </div>
                  <div class="boxbom">
                    <div class="imgbox">
                      <img :src="item.cover_url" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="jiaoshiBox mt33">
        <!-- <div class="h4box">
          <h4>四名大咖，荣誉证书</h4>
          <div class="h4abs"></div>
          <p>光辉战绩，是名誉的象征更是成长的见证</p>
        </div> -->
        <div class="publicTitle df dfb mb20">
          <h4>成果展示</h4>
          <!-- <p class="cp" @click="goMuch(3)">查看更多>></p> -->
        </div>
        <div class="ListBox">
          <div class="forBox" v-for="(item, index) in zhengshuList" :key="index" @click="go_urls(item.go_url)">
            <div class="topBox">
              <img :src="item.resource_url" alt="" />
              <span class="topBox-label">{{ item.certificat_level_str }}</span>
            </div>
            <div class="BtnBox">
              <h4 class="ellipsis-2">{{ item.name }}</h4>
              <span class="BtnBox-label" v-if="item.certificat_type_name">{{ item.certificat_type_name }}</span>
              <div class="BtnBox-bottom">
                <p class="timeSty mt10">发布时间：{{ item.create_time }}</p>
                <div class="info-bottom">
                  <div class="info-bottom-studio mt10">
                    <img :src="item.teaching_studio_photo_url" class="studio-photo mr5">
                    <p class="timeSty ellipsis f1">{{ item.teaching_studio_name }}</p>
                  </div>
                  <p class="timeSty mt10">{{ item.view_count }}人浏览</p>
                </div>
              </div>
            </div>
          </div>
          <CommonEmpty v-if="!zhengshuList.length" hVal="100%"></CommonEmpty>
        </div>
      </div>

      <!-- 工作室排行 -->
      <div class="leftRightBox df dfb mt33 paihangBox">
        <!-- 工作室排行 -->
        <div class="leftBox left_box">
          <div>
            <div class="publicTitle df dfb mb20">
              <h4>工作室排行</h4>
            </div>
            <div class="ziyuanBox ziyuan_box_2">
              <div class="headertit">
                <h4>工作室贡献排行</h4>
                <div class="df dfb mb20">
                  <p>工作室名称</p>
                  <p>总贡献</p>
                </div>
              </div>
              <div class="WorkShiUl-bg-1"></div>
              <div class="WorkShiUl" v-if="paihangList1.length">
                <div class="WorkShili df dfb" v-for="(item, index) in paihangList1" :key="index"
                  @click="go_urls(item.go_url, item)">
                  <div class="imgbox">
                    <img src="@/assets/navIcon/jin.png" v-if="index == 0" />
                    <img src="@/assets/navIcon/yin.png" v-if="index == 1" />
                    <img src="@/assets/navIcon/tong.png" v-if="index == 2" />
                    <div class="imgbox-normal" v-if="index > 2">
                      {{ index + 1 }}
                    </div>
                  </div>
                  <div class="pbox ellipsis">{{ item.teaching_studio_name }}</div>
                  <div class="numbox" style="color:#ff7a29">{{ item.contribute_count }}</div>
                </div>
              </div>
              <CommonEmpty v-else hVal="100%"></CommonEmpty>
            </div>
          </div>

          <div>
            <div class="publicTitle df dfb mb20" style="min-height:25px">
              <h4></h4>
            </div>
            <div class="ziyuanBox ziyuan_box_2">
              <div class="headertit headertit2">
                <h4>工作室研修资源排行</h4>
                <div class="df dfb mb20">
                  <p>工作室名称</p>
                  <p>研修资源</p>
                </div>
              </div>
              <div class="WorkShiUl-bg-2"></div>
              <div class="WorkShiUl" v-if="paihangList2.length">
                <div class="WorkShili df dfb" v-for="(item, index) in paihangList2" :key="index"
                  @click="go_urls(item.go_url, item)">
                  <div class="imgbox">
                    <img src="@/assets/navIcon/jin.png" v-if="index == 0" />
                    <img src="@/assets/navIcon/yin.png" v-if="index == 1" />
                    <img src="@/assets/navIcon/tong.png" v-if="index == 2" />
                    <div class="imgbox-normal" v-if="index > 2">
                      {{ index + 1 }}
                    </div>
                  </div>
                  <div class="pbox ellipsis">{{ item.teaching_studio_name }}</div>
                  <div class="numbox" style="color:#3489ff">{{ item.resource_count }}</div>
                </div>
              </div>
              <CommonEmpty v-else hVal="100%"></CommonEmpty>
            </div>
          </div>
          <div>
            <div class="publicTitle df dfb mb20" style="min-height:25px">
              <h4></h4>
            </div>
            <div class="ziyuanBox ziyuan_box_2">
              <div class="headertit headertit3">
                <h4>工作室教研活动排行</h4>
                <div class="df dfb mb20">
                  <p>工作室名称</p>
                  <p>教研活动</p>
                </div>
              </div>
              <div class="WorkShiUl-bg-3"></div>
              <div class="WorkShiUl" v-if="paihangList3.length">
                <div class="WorkShili df dfb" v-for="(item, index) in paihangList3" :key="index"
                  @click="go_urls(item.go_url, item)">
                  <div class="imgbox">
                    <img src="@/assets/navIcon/jin.png" v-if="index == 0" />
                    <img src="@/assets/navIcon/yin.png" v-if="index == 1" />
                    <img src="@/assets/navIcon/tong.png" v-if="index == 2" />
                    <div class="imgbox-normal" v-if="index > 2">
                      {{ index + 1 }}
                    </div>
                  </div>
                  <div class="pbox ellipsis">{{ item.teaching_studio_name }}</div>
                  <div class="numbox" style="color:#51b875">{{ item.activity_count }}</div>
                </div>
              </div>
              <CommonEmpty v-else hVal="100%"></CommonEmpty>
            </div>
          </div>
        </div>

        <!-- 研修动态 -->
        <div class="rightBox">
          <div class="publicTitle df dfb mb20">
            <h4>研修统计</h4>
            <!-- <p class="cp" @click="goMuch(3)">查看更多>></p> -->
          </div>
          <div class="huizongsty df dfb">
            <div v-for="item in 8" :key="item">
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon01.png" alt="" v-if="item == 1" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon02.png" alt="" v-if="item == 2" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon03.png" alt="" v-if="item == 3" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon04.png" alt="" v-if="item == 4" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon05.png" alt="" v-if="item == 5" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon06.png" alt="" v-if="item == 6" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon07.png" alt="" v-if="item == 7" />
              <img src="@/assets/navIcon/studioImg/msgzs_hzsj_icon08.png" alt="" v-if="item == 8" />
              <div class="tixtBox">
                <p class="bigp" v-if="item == 1">
                  {{ huizongList.count || 0 }}
                </p>
                <p class="bigp" v-if="item == 2">
                  {{ huizongList.user_count }}
                </p>
                <p class="bigp" v-if="item == 3">
                  {{ huizongList.live_course_count }}
                </p>
                <p class="bigp" v-if="item == 4">
                  {{ huizongList.activity_count }}
                </p>
                <p class="bigp" v-if="item == 5">
                  {{ huizongList.resource_count }}
                </p>
                <p class="bigp" v-if="item == 6">
                  {{ huizongList.article_count }}
                </p>
                <p class="bigp" v-if="item == 7">
                  {{ huizongList.resource_count }}
                </p>
                <p class="bigp" v-if="item == 8">
                  {{ huizongList.certificate_count }}
                </p>
                <p v-if="item == 1">四名工作室</p>
                <p v-if="item == 2">工作室成员</p>
                <p v-if="item == 3">四名讲堂</p>
                <p v-if="item == 4">教研活动</p>
                <p v-if="item == 5">研修资源</p>
                <p v-if="item == 6">教师文章</p>
                <p v-if="item == 7">活动报道</p>
                <p v-if="item == 8">成果展示</p>
              </div>

            </div>
          </div>

        </div>
      </div>
      <!-- 底部背景条 -->
    </div>
    <div class="foterstr"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gongzuoList: [],
      gongzuoList2: [],
      dongtaiList: [],
      studioReportList: [],
      ziyuanList: [],
      xiangmuList: [],
      xinWenlist: [],
      huodongList: [],
      wenzhangList: [],
      paihangList1: [],
      paihangList2: [],
      paihangList3: [],
      huizongList: [],
      leftnum: 0,
      zhengshuList: [],
    };
  },

  methods: {
    // 跳转详情
    handlePageInfo_1(item) {
      let link_src = "";
      if (item && item.teaching_studio_id) {
        link_src = `&is_show_head=1&teaching_studio_id=${item.teaching_studio_id}`;
      };
      if (item.go_url) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin;
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `${item.go_url}${link_src}`);
          window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`);
        } else {
          window.open(`${item.go_url}${link_src}`, "_blank");
        }
      }
      return
      // 直播
      if (item.type == 1) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + item.go_url)
          window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
        } else {
          window.open(item.go_url, "_blank");
        }
      };
      // 活动
      if (item.type == 4) {
        window.open(item.go_url, "_blank");
      };
      // window.open(item.go_url, "_self");
    },
    //信息首页_荣誉证书
    async indexTeachingStudioCertificate() {
      const res = await this.$axios.get(
        "/cloud/indexTeachingStudioCertificate",
        {
          params: { limit: 4, page: 1 },
        }
      );
      this.zhengshuList = res.data.data.data;
    },

    scrolls() {
      var parent = document.getElementById("parent");
      var child1 = document.getElementById("child1");
      var child2 = document.getElementById("child2");
      child2.innerHTML = child1.innerHTML;
      setInterval(function () {
        if (parent.scrollTop >= child1.scrollHeight) {
          parent.scrollTop = 0;
        } else {
          parent.scrollTop += 2;
        }
      }, 100);
    },
    //信息首页_四名工作室
    async listTeachingStudioList() {
      const res = await this.$axios.get("index/TeachingStudio/listTeachingStudio", {
        params: { search_name: "", limit: 7, page: 1 },
      });
      let list = res.data.data.data;
      this.gongzuoList = [];
      this.gongzuoList2 = [];
      list.forEach((e, i) => {
        if (i < 3) {
          this.gongzuoList2.push(e);
        } else {
          this.gongzuoList.push(e);
        }
      });
    },
    //信息首页_四名讲堂
    async listTeachingStudioCourseLive() {
      const res = await this.$axios.get("/cloud/listTeachingStudioCourseLive", {
        params: { limit: 7, page: 1 },
      });
      let list = res.data.data.data;
      this.xiangmuList = [];
      list.forEach((e, i) => {
        if (i < 4) {
          this.xiangmuList.push(e);
        }
      });
    },

    //信息首页_新闻公告
    async listsTeachingStudioNotice2() {
      const res = await this.$axios.get("/cloud/listsTeachingStudioNotice", {
        params: { limit: 3, page: 1 },
      });
      let list = res.data.data.data;
      this.xinWenlist = [];
      list.forEach((e, i) => {
        if (i < 4) {
          this.xinWenlist.push(e);
        }
      });
    },
    //信息首页_教研活动
    async TeachingStudioActivitylist() {
      const res = await this.$axios.get("/cloud/TeachingStudioActivitylist", {
        params: { limit: 10, page: 1 },
      });
      let list = res.data.data.data;
      this.huodongList = [];
      list.forEach((e, i) => {
        if (i < 3) {
          this.huodongList.push(e);
        }
      });
    },
    //信息首页_工作室排行
    async listTeachingStudioviewcount() {
      const res1 = await this.$axios.get("/cloud/listTeachingStudioviewcount", {
        params: { limit: 6, page: 1, order_type: 1 },
      });
      this.paihangList1 = res1.data.data.data;

      const res2 = await this.$axios.get("/cloud/listTeachingStudioviewcount", {
        params: { limit: 6, page: 1, order_type: 2 },
      });
      this.paihangList2 = res2.data.data.data;

      const res3 = await this.$axios.get("/cloud/listTeachingStudioviewcount", {
        params: { limit: 6, page: 1, order_type: 3 },
      });
      this.paihangList3 = res3.data.data.data;
    },
    //信息首页_推荐资源
    async TeachingStudioResourcelist() {
      const res = await this.$axios.get("/cloud/TeachingStudioResourcelist", {
        params: { limit: 5, page: 1 },
      });
      this.ziyuanList = res.data.data.data;
    },
    //信息首页_研修动态
    async ListTeachingStudioUserLog() {
      const res = await this.$axios.get("/cloud/ListTeachingStudioUserLog", {
        params: { limit: 99, page: 1 },
      });
      this.dongtaiList = res.data.data.data;
    },
    //信息首页_活动报道
    async getStudioReportList() {
      const res = await this.$axios.get("/TeachingStudioReport/IndexlistTeachingStudioReport", {
        params: { limit: 3, page: 1 },
      });
      this.studioReportList = res.data.data.data;
      console.log(this.studioReportList);
    },
    //信息首页_教师文章
    async IndexlistTeachingStudioArtic() {
      const res = await this.$axios.get("/cloud/IndexlistTeachingStudioArtic", {
        params: { limit: 5, page: 1 },
      });
      this.wenzhangList = res.data.data.data;
    },
    //信息首页_研修统计
    async listTeachingStudioCount() {
      const res = await this.$axios.get("/cloud/listTeachingStudioCount", {
        params: { limit: 10, page: 1 },
      });
      this.huizongList = res.data.data;
    },

    getImgUrl(item) {
      return require("@/assets/images/iconFile/" + item + ".png");
    },
    // 查看更多
    goMuch() {
      this.$message.warning("开发中");
    },
    goMuchPageMore(num) {
      switch (Number(num)) {
        case 1:
          if (sessionStorage.getItem('is_hide') == 1) {
            let hostes = location.origin
            let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/StudioListOld`)
            window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
          } else {
            this.$router.push({
              path: "/StudioListOld",
            });
          }
          break;
        default:
          break;
      }
    },
    goMuchCourse() {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/researchCenter?navActive=3`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/researchCenter",
          query: {
            navActive: 2,
          },
        });
      }
    },
    goMuchActive() {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/researchCenter?navActive=1`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/researchCenter",
          query: {
            navActive: 1,
          },
        });
      }
    },
    goMuchNews() {
      this.$message.warning("开发中");
      // this.$router.push({
      //   path: "/researchCenter",
      //   query: {
      //     navActive: 3,
      //   },
      // });
    },
    goReportli(item) {
      this.$router.push({
        path: "/ReportDetail",
        query: {
          tid: item.id,
          teaching_studio_id: item.teaching_studio_id
        }
      });
    },
    getYueRi(now, val) {
      var date = new Date(now * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + ".";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      if (val == 1) {
        return M + D;
      } else {
        return Y;
      }
    },
    dateTime(now) {
      var date = new Date(now * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      return Y + M + D;
    },
    // 查看详情
    go_urls(url, item) {
      let link_src = "";
      if (item && item.teaching_studio_id) {
        link_src = `&teaching_studio_id=${item.teaching_studio_id}`;
      };
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + url)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}${link_src}`)
      } else {
        window.open(`${url}${link_src}`);
      }
    },
    // 查看详情
    go_urlsJiangt(url, item) {
      // if (!localStorage.getItem("unserInfoData")) {
      //   this.$message.warning("请先进行登录~");
      //   setTimeout(() => {
      //     localStorage.setItem("oldUrl", JSON.stringify(location.href));
      //     this.$router.push({
      //       path: "/logo",
      //     });
      //   }, 1000);
      //   return;
      // }
      let link_src = "";
      if (item && item.teaching_studio_id) {
        link_src = `&teaching_studio_id=${item.teaching_studio_id}`;
      };
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + url)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}${link_src}`)
      } else {
        window.open(`${url}${link_src}`);
      }
    },
    // 进入详情
    handlePageEvent(url, item) {
      let link_src = "";
      if (item && item.id) {
        link_src = `&is_show_head=1&teaching_studio_id=${item.id}`;
      };
      if (url) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + url)
          window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}${link_src}`)
        } else {
          window.open(`${url}${link_src}`, "_blank");
        }
      }
    },
  },

  mounted() {
    this.scrolls();
    this.listTeachingStudioList();
    this.listTeachingStudioCourseLive();
    this.listsTeachingStudioNotice2();
    this.TeachingStudioActivitylist();
    this.listTeachingStudioviewcount();
    this.TeachingStudioResourcelist();
    this.ListTeachingStudioUserLog();
    this.getStudioReportList()
    this.IndexlistTeachingStudioArtic();
    this.listTeachingStudioCount();
    this.indexTeachingStudioCertificate();
  },
};
</script>
<style lang="scss" scoped>
.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 56px;
  height: 24px;
  top: 10px;
  right: 0;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  border-radius: 4px 0 0 4px;
  background-color: #ff8201;
  color: #fff;

  &.tios_div_box_2_div {
    background-color: #0080ff;
  }

  &.tios_div_box_3_div {
    background-color: #ff8201;
  }

  &.tios_div_box_4_div {
    background-color: #ff0607;
  }

  &.tios_div_box_5_div {
    background-color: #0080ff;
  }

  &.tios_div_box_6_div {
    background-color: #ff0607;
  }

  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }
}

// 轮播图框内样式
/deep/.el-carousel {
  border-radius: 10px;
}

.public-width {
  width: 1400px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: left;
}

.title_box {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    padding-top: 20px;
  }

  .title_name {
    font-size: 19px;
    font-weight: bold;
    color: #373637;
  }

  .title_btn {
    font-size: 16px;
    color: #7f889c;
    cursor: pointer;

    &:hover {
      color: #3489fe;
    }
  }
}

.bannerSty {
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 348px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;
    border-radius: 10px 0 0 10px;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      border-radius: 10px 0 10px 0;
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}

// 标题公共样式
.publicTitle {
  display: flex;
  align-items: center;

  h4 {
    font-size: 19px;
    font-weight: bold;
    color: #373637;
  }

  p {
    font-size: 16px;
    color: #7f889c;

    &:hover {
      color: #3489fe;
    }
  }
}

// 左右布局公共样式
.leftRightBox {
  .leftBox {
    width: 1045px;
  }

  .rightBox {
    width: 335px;
  }
}

// 四名工作室
.gongzuoBox {
  .gongzuoTop {
    .gongzuoli {
      width: 453px;
      height: 317px;
      background-size: 100% 100%;
      border-radius: 8px;
      box-sizing: border-box;
      position: relative;
      transition: all 0.3s ease-in-out;
      margin-right: 20px;
      background: #ffffff;

      .citiesBox {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(107, 214, 247, 0.7);
        padding: 2px 10px;
        border-radius: 0 8px 0 8px;
        font-size: 14px;
        color: #fff;
      }

      .iconBox {
        position: absolute;
        bottom: -3px;
        right: 3px;
        width: 112px;
        height: 112px;
        border-radius: 8px;
        color: #fff;

        img {
          border-radius: 8px;
          width: 112px;
          height: 112px;
        }
      }

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:nth-child(1) {

        .box1 {
          background: linear-gradient(-44deg, rgba(72, 165, 255, 1) 0%, rgba(118, 192, 255, 1) 100%);

          .txtbox {
            p {
              background: #2789EA;

              &:last-child {
                color: #3489FF;
              }
            }
          }
        }

        .box2 {
          background: linear-gradient(0deg, rgba(222, 239, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

          .box_tiem_2 {
            >p {
              span {
                color: #3489FF;
              }
            }

            &:hover {
              >p {
                color: #3489FF;
              }
            }
          }

          .look_more {
            background: linear-gradient(-44deg, rgb(72, 165, 255) 0%, rgb(154, 208, 255) 88%);
          }
        }
      }

      &:nth-child(2) {

        .box1 {
          background: linear-gradient(-44deg, rgba(52, 190, 184, 1) 0%, rgba(76, 218, 214, 1) 100%);

          .txtbox {
            p {
              background: #20A7A1;

              &:last-child {
                color: #20A7A1;
              }
            }
          }
        }

        .box2 {
          background: linear-gradient(0deg, rgba(232, 255, 254, 1) 0%, rgba(255, 255, 255, 1) 100%);

          .box_tiem_2 {
            >p {
              span {
                color: #20A7A1;
              }
            }

            &:hover {
              >p {
                color: #20A7A1;
              }
            }
          }

          .look_more {
            background: linear-gradient(-44deg, rgb(52, 190, 185) 0%, rgb(134, 219, 216) 88%);
          }
        }
      }

      &:nth-child(3) {

        .box1 {
          background: linear-gradient(-44deg, rgba(255, 114, 156, 1) 0%, rgba(255, 161, 199, 1) 100%);

          .txtbox {
            p {
              background: #E8517E;

              &:last-child {
                color: #E8517E;
              }
            }
          }
        }

        .box2 {
          background: linear-gradient(0deg, rgba(255, 237, 244, 1) 0%, rgba(255, 255, 255, 1) 100%);

          .box_tiem_2 {
            >p {
              span {
                color: #E8517E;
              }
            }

            &:hover {
              >p {
                color: #E8517E;
              }
            }
          }

          .look_more {
            background: linear-gradient(-44deg, rgb(255, 114, 156) 0%, rgb(255, 185, 213) 88%);
          }
        }
      }

      >div {
        box-sizing: border-box;
      }

      .box1 {
        width: 100%;
        padding: 0 25px;
        align-items: center;
        width: 453px;
        height: 126px;
        border-radius: 8px 8px 0 0;

        .imgbox {
          position: relative;
          margin-right: 15px;
          width: 120px;
          height: 126px;
          background: #fff;

          img {
            width: 120px;
            height: 126px;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
            display: block;
          }

          .imgabs {
            position: absolute;
            left: -8px;
            top: -8px;
            width: 27px;
            height: 27px;
            border: none;
          }
        }

        .txtbox {
          width: calc(100% - 106px);

          h4 {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }

          h5 {
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
          }

          p {
            background: #1ca9a3;
            border-radius: 4px;
            font-size: 14px;
            color: #ffffff;
            padding: 2px 5px;
            display: inline-block;

            &:last-child {
              background: rgba(255, 255, 255, 1);
            }
          }
        }
      }

      .box2 {
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        border-radius: 0 0 8px 8px;
        padding: 0 30px;

        .box_tiem_2 {
          height: 28px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #ffffff;
          border-radius: 5px;
          position: relative;

          p {
            color: #333;
          }
        }

        .box_tiem_3 {
          color: #fff;
          display: flex;
          align-items: center;
          padding-top: 8px;
          margin-bottom: 20px;
          margin-left: 6px;
          position: relative;

          p {
            font-size: 14px;
            color: #111A38;

            span {
              font-size: 20px;
              margin-right: 5px;
            }
          }

          .line {
            width: 1px;
            height: 12px;
            background: #ffffff;
            display: block;
          }
        }

        .look_more {
          width: 128px;
          height: 30px;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(211, 245, 255, 1);
          border-radius: 8px 8px 0 0;
          margin: 0 auto;
          margin-top: 24px;
          font-size: 14px;
          font-weight: normal;
          cursor: pointer;
        }
      }
    }
  }

  .gongzuoBom {
    margin-top: 25px;

    .gongzuoLis {
      width: 335px;
      height: 242px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      box-sizing: border-box;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      position: relative;

      .citiesBox {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(107, 214, 247, 0.7);
        padding: 4px 10px;
        border-radius: 0 8px 0 8px;
        font-size: 14px;
        color: #fff;
      }

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:last-of-type {
        margin-right: 0;
      }

      .litop {
        padding: 30px 20px 0 25px;
        margin-bottom: 15px;

        .lileft {
          margin-right: 15px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 8px;
            display: block;
          }
        }

        .liright {
          position: relative;
          width: calc(100% - 112px);

          h4 {
            font-size: 16px;
            font-weight: bold;
            color: #111a38;
            margin-bottom: 5px;
          }

          .itemsty {
            margin-bottom: 5px;
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
          }

          .stadbox {
            align-content: center;

            .stad {
              background: #edf7f1;
              border-radius: 4px;
              font-size: 14px;
              color: #51b875;
              padding: 2px 5px;
              border: 1px solid #51b875;
              margin-right: 12px;
            }

            span {
              font-size: 14px;
              color: rgba(102, 102, 102, 1);
            }
          }
        }
      }

      .libom {
        padding: 10px 20px 10px 20px;
        height: 97px;
        border-radius: 8px;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        background: linear-gradient(0deg, rgba(229, 243, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);

        p {
          height: 28px;
          display: flex;
          align-items: center;
          font-size: 14px;
          border-radius: 5px;
          color: #666;

          span {
            span {
              color: #487AAC;
            }
          }

          &:hover {
            span {
              color: #487AAC;
            }
          }
        }
      }
    }
  }
}

// 四名讲堂
.msjtBox,
.msjtBox-teaching {
  .msjtli {
    width: 335px;
    height: 406px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background: #fff;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
    }

    &.nowLive {
      //border: 2px solid #245ff4;
    }

    .titleImg {
      width: 335px;
      height: 188px;
      display: block;
    }

    >div {
      padding: 0 20px;

      .titles {
        font-size: 16px;
        font-weight: bold;
        color: #111a38;
      }

      .ohers {
        div {
          border-radius: 4px;
          padding: 3px 8px;
          font-size: 14px;

          &:nth-child(1) {
            color: #0493ff;
            background: rgba(229, 244, 255, 1);
          }

          &:nth-child(2) {
            color: #51b875;
            background: rgba(237, 247, 241, 1);
          }
        }
      }

      .ohersff {
        div {
          border-radius: 4px;
          padding: 6px 8px;
          color: #0493ff;
          background: rgba(229, 244, 255, 1);
          font-size: 14px;
        }
      }

      .stepBox {
        >div {
          align-items: center;
          margin: 6px 0;

          div {
            width: 6px;
            height: 6px;
            border: 3px solid #51b875;
            border-radius: 50%;
          }

          p {
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }

      .ziyubox {
        >div {
          align-items: center;
          margin: 0 0 10px 0;


          div {
            img {
              width: 20px;
              height: 20px;
              display: block;
            }
          }

          p {
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
          }

          &:hover {
            p {
              color: #3489FF;
            }
          }
        }
      }

      .times {
        font-size: 14px;
        color: rgba(102, 102, 102, 1);
      }

      .timesab {
        height: 32px;
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        font-size: 14px;
        color: #ffffff;
        line-height: 32px;
        padding: 0 20px;
        box-sizing: border-box;
        position: absolute;
        top: 156px;
        left: 0;
      }

      .status {
        position: absolute;
        width: 56px;
        height: 24px;
        top: 8px;
        right: 0;
        background: #ff8029;
        border-radius: 4px 0px 0px 4px;
        font-size: 12px;
        line-height: 24px;
        font-weight: bold;
        text-align: center;
        color: #fff;
      }

      .timebtn {
        margin-bottom: 15px;

        p {
          background: #e5f4ff;
          border-radius: 4px;
          font-size: 14px;
          color: #0493ff;
          padding: 2px 8px;
          margin-right: 10px;
        }
      }

      .zhutis {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        height: 18px;
      }

      .bomBox {
        border-top: 1px solid rgba(220, 223, 230, 1);

        p {
          line-height: 17px;

          &:first-child {
            font-size: 20px;
            font-weight: bold;
            color: rgba(255, 128, 41, 1);
          }

          &:last-child {
            font-size: 15px;
            font-weight: bold;
            color: rgba(0, 128, 255, 1);
          }
        }
      }

      .bomimgBox {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 20px;

        div {
          img {
            width: 24px;
            height: 24px;
            border-radius: 4px;
          }

          p {
            line-height: 24px;
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
          }
        }

        >p {
          line-height: 24px;
          font-size: 14px;
          color: rgba(153, 153, 153, 1);
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .zhenli {
    &:hover {
      margin-top: -3px;
      box-shadow: 0 3px 8px 0 rgba(18, 139, 251, 0.26);
    }
  }
}

.msjtBox-teaching {
  .msjtli {
    height: 416px;
  }
}

// 新闻公告
.msXinWen {
  width: 335px;
  height: 406px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 20px 0 20px;
  box-sizing: border-box;

  .xinwenli {
    margin-bottom: 48px;
    cursor: pointer;

    &:hover {
      .xwleft {
        h4 {
          color: #0493ff;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .xwleft {
      width: calc(100% - 142px);
      position: relative;

      h4 {
        font-size: 16px;
        color: #111a38;
      }

      p {
        font-size: 12px;
        color: #999999;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    .xwright {
      img {
        width: 128px;
        height: 72px;
        border-radius: 8px;
        display: block;
      }
    }
  }
}

// 工作室排行
.msWorkShi {
  width: 335px;
  height: 416px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 7px;

  .WorkShiUl {
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/myImg/msgzs_phb_bg.png");
    background-size: 100% 100%;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 124px 16px 0 16px;

    .WorkShili {
      cursor: pointer;
      align-items: center;
      margin-bottom: 35px;

      .imgbox {
        margin-right: 13px;

        img {
          width: 22px;
          height: 22px;
          display: block;
        }
      }

      .imgbox-normal {
        width: 22px;
        height: 22px;
        font-size: 12px;
        color: #8590a6;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebe7e7;
        border-radius: 100%;
      }

      .pbox {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        line-height: 16px;
        flex: 1;
        overflow: hidden;
        margin-right: 10px;
      }

      .numbox {
        font-size: 16px;
        color: #0080ff;
        font-weight: bold;
        text-align: right;
      }
    }
  }
}

.ziyuanBoxab {
  padding: 30px 20px;
}

.ziyuanBox {
  width: 1045px;
  height: 394px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;

  .aboutbg {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 508px;
    height: 55px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(225, 238, 255, 1) 100%);
    border-radius: 8px 8px 0 0;
  }

  .aboutbg2 {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(214, 255, 228, 1) 100%);
  }

  .aboutimg {
    position: absolute;
    right: 9px;
    top: 11px;
    width: 78px;
    height: 32px;

    img {
      width: 78px;
      height: 32px;
    }
  }

  .zitiLi {
    width: 49%;
    height: 59px;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;



    &:hover {
      .ziti2 {
        h4 {
          color: #1e6fff;
        }
      }
    }

    .ziti1 {
      margin-right: 14px;

      img {
        width: 40px;
        height: 40px;
        display: block;
      }
    }

    .ziti1-article {
      margin-right: 14px;

      img {
        width: 32px;
        height: 38px;
        display: block;
      }
    }

    .ziti2 {
      width: calc(100% - 164px);

      h4 {
        font-size: 16px;
        color: #111a38;
        margin-bottom: 6px;
        font-weight: normal;
      }

      p {
        font-size: 14px;
        color: #666666;
        display: flex;
        align-items: center;

        .line {
          width: 1px;
          height: 12px;
          background: #dcdfe6;
          display: block;
          margin: 0 15px;
        }

        .census {
          color: #909399;
          margin-right: 15px;
        }
      }
    }

    .ziti3 {
      margin-left: 12px;
      margin-right: 20px;

      .z3btn {
        opacity: 0;
      }

      button {
        width: 58px;
        height: 30px;
        background: rgba(0, 128, 255, 1);
        border-radius: 8px;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        line-height: 30px;
        border: none;
      }
    }
  }
}

// 工作室排行
.NumWorkSty {
  padding: 24px 20px;
  box-sizing: border-box;
  width: 335px;
  height: 394px;
  background: #ffffff;
  border-radius: 8px;

  .parent {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    overflow: hidden;
  }

  /*设置的子盒子高度大于父盒子，产生溢出效果*/
  .child {
    height: auto;
  }

  .box {
    margin: 0 0 24px 0;
    height: 102px;

    .boxtop {
      margin-bottom: 10px;

      .imgbox {
        width: 50px;
        height: 50px;
        margin-right: 16px;

        img {
          width: 50px;
          height: 50px;
          background: rgba(219, 167, 167, 1);
          border-radius: 4px;
          display: block;
        }
      }

      .txtBox {
        flex: 1;
        overflow: hidden;

        .txttop {
          display: flex;

          p {
            font-size: 16px;
            color: rgba(51, 51, 51, 1);
            flex: 1;
            overflow: hidden;
          }

          span {
            font-size: 16px;
            color: rgba(153, 153, 153, 1);
          }
        }

        >p {
          font-size: 14px;
          color: rgba(102, 102, 102, 1);
        }
      }
    }

    .boxbom {
      height: 50px;
      background: rgba(244, 245, 247, 1);
      border-radius: 8px;

      >div {
        align-items: center;
        height: 50px;
        padding: 0 10px;

        span {
          background: rgba(253, 246, 236, 1);
          border: 1px solid rgba(250, 236, 216, 1);
          border-radius: 4px;
          font-size: 14px;
          color: rgba(230, 162, 60, 1);
          text-align: center;
          padding: 2px 5px;
          min-width: 60px;
          margin-right: 10px;
        }

        p {
          font-size: 16px;
          color: #4e6ea9;
        }
      }
    }
  }
}

.huizongsty {
  width: 335px;
  height: 394px;
  background: rgba(255, 206, 206, 1);
  border-radius: 8px;
  background-image: url("~@/assets/images/myImg/msgzs_hzsj_bg.png");
  background-size: 100% 100%;
  padding: 33px 24px;
  box-sizing: border-box;
  flex-wrap: wrap;

  >div {
    width: 133px;
    height: 64px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(41, 118, 240, 0.2);
    margin-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      margin-right: 10px;
    }

    .tixtBox {
      p {
        font-size: 12px;
        color: #111a38;
        margin-bottom: 3px;
      }

      .bigp {
        font-size: 18px;
        font-weight: bold;
      }
    }


    &:nth-child(1) {
      .bigp {
        color: #3B90FF;
      }
    }

    &:nth-child(2) {
      .bigp {
        color: #0DAC9E;
      }
    }

    &:nth-child(3) {
      .bigp {
        color: #1BD2C5;
      }
    }

    &:nth-child(4) {
      .bigp {
        color: #1EB8F7;
      }
    }

    &:nth-child(5) {
      .bigp {
        color: #F4B74D;
      }
    }

    &:nth-child(6) {
      .bigp {
        color: #486DEB;
      }
    }

    &:nth-child(7) {
      .bigp {
        color: #9F6ADB;
      }
    }

    &:nth-child(8) {
      .bigp {
        color: #C765DF;
      }
    }
  }
}

.jiaoshiBox {
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
  //margin-bottom: -55px;
  // z-index: 166;
  box-sizing: border-box;

  .ListBox {
    display: flex;
    flex-wrap: wrap;
    min-height: 200px;
    border-radius: 6px;

    .forBox {
      width: 335px;
      height: 368px;
      background: #ffffff;
      border-radius: 8px;
      margin-right: 20px;
      box-sizing: border-box;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      cursor: pointer;

      .topBox {
        width: 335px;
        height: 188px;
        background: #f6f2e6;
        border-radius: 8px 8px 0px 0px;
        position: relative;

        .about {
          position: absolute;
          top: 0;
          right: 0;
          background: #ff8201;
          color: #fff;
          font-size: 14px;
          padding: 2px 5px;
          border-radius: 0 5px 0 5px;

          &.aboutFas {
            background: #ff0607;
          }
        }

        img {
          width: 335px;
          height: 188px;
          border-radius: 8px 8px 0px 0px;
        }

        .topBox-label {
          height: 24px;
          padding: 0 8px;
          background: rgba(81, 184, 117, 1);
          border-radius: 0 0 0 4px;
          font-size: 12px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      .BtnBox {
        height: 180px;
        padding: 18px 20px 0 20px;
        box-sizing: border-box;
        position: relative;

        h4 {
          font-size: 15px;
          font-weight: 700;
          text-align: left;
          line-height: 25px;
          margin-bottom: 5px;
        }

        .typeSty {
          margin-bottom: 10px;
          display: flex;

          p {
            font-size: 14px;
            padding: 0 10px;

            &:first-child {
              color: #fff;
              background: #57a8ef;
              border-radius: 3px 0 0 3px;
            }

            &:last-child {
              border: 1px solid #57a8ef;
              color: #57a8ef;
              border-radius: 0 3px 3px 0;
            }

            &.s1 {
              color: #f00;
            }

            &.s2 {
              color: #0dac9e;
            }

            &.s3 {
              color: #666;
            }


          }
        }

        .timeSty {
          font-size: 14px;
          color: rgb(102, 102, 102);
        }

        .BtnBox-label {
          width: 85px;
          height: 24px;
          background: rgba(229, 244, 255, 1);
          padding: 3px 8px;
          border-radius: 4px;
          font-size: 14px;
          color: rgba(4, 147, 255, 1);
        }

        .BtnBox-bottom {
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          bottom: 20px;

          .info-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              &:first-child {
                color: rgb(51, 51, 51);
              }

              &:last-child {
                color: rgb(153, 153, 153);
              }
            }

            .info-bottom-studio {
              display: flex;
              align-items: center;
              flex: 1;
              overflow: hidden;

              .studio-photo {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                overflow: hidden;
              }
            }
          }
        }
      }
    }

    //分页
    .block {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
  }

  .liBox {
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    height: 345px;
    width: 1400px;
    overflow: hidden;

    .bigbox {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      transition: all 2s;

      .imgLi {
        width: 314px;
        height: 345px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        margin-right: 48px;

        &:last-child {
          margin-right: 0;
        }

        .imgpSty {
          width: 314px;
          height: 114px;
          background: rgba(30, 111, 255, 1);
          box-sizing: border-box;
          padding: 30px 16px;
          border-radius: 6px 6px 0 0;

          p {
            font-size: 18px;
            color: rgba(255, 255, 255, 1);
          }
        }

        .imgsSty {
          padding: 22px;

          img {
            width: 318px;
            height: 190px;
          }
        }
      }
    }
  }
}

.foterstr {
  width: 100%;
  height: 90px;
  background-image: url("~@/assets/images/myImg/home_footer_bg.png");
  background-size: 100% 100%;
  z-index: 164;
}

.left_box {
  display: flex !important;
  justify-content: space-between !important;
}

.ziyuan_box_1 {
  width: 510px !important;
  // flex-wrap: nowrap !important;
  // display: flex !important;
  // flex-direction: column !important;

  .ziyuan_box_item {
    width: 100% !important;
    height: 58px;
  }

  .ziti2 {
    width: 100% !important;
  }

  .zitiLi {
    width: 100% !important;
  }
}


.paihangBox {
  margin-bottom: 33px;

  .ziyuan_box_2 {
    width: 335px !important;
    // flex-wrap: nowrap !important;
    // display: flex !important;
    // flex-direction: column !important;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-sizing: border-box;

    // padding: 7px;
    .headertit {
      width: 333px;
      height: 105px;
      background: linear-gradient(-43deg, rgba(255, 122, 41, 1) 0%, rgba(255, 151, 87, 1) 100%);
      border-radius: 8px 8px 0 0;


      h4 {
        color: #fff;
        height: 73px;
        line-height: 73px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        padding: 0 24px;
        box-sizing: border-box;
      }

      >div {
        width: 333px;
        height: 32px;
        background: rgba(255, 255, 255, 0.09);
        padding: 0 24px;
        box-sizing: border-box;
        line-height: 32px;

        p {
          color: #fff;
        }
      }

      &.headertit2 {
        background: linear-gradient(-44deg, rgba(72, 165, 255, 1) 0%, rgba(118, 192, 255, 1) 100%);

        >div {
          background: rgba(255, 255, 255, 0.12);
        }
      }

      &.headertit3 {
        background: linear-gradient(-44deg, rgba(52, 190, 184, 1) 0%, rgba(76, 218, 214, 1) 100%);

        >div {
          background: rgba(255, 255, 255, 0.13);
        }
      }
    }

    .WorkShiUl {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 24px;
      position: relative;

      .WorkShili {
        cursor: pointer;
        align-items: center;
        margin-bottom: 22px;

        .imgbox {
          margin-right: 13px;

          img {
            width: 22px;
            height: 22px;
            display: block;
          }
        }

        .imgbox-normal {
          width: 22px;
          height: 22px;
          font-size: 12px;
          color: #8590a6;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ebe7e7;
          border-radius: 100%;
        }

        .pbox {
          font-size: 16px;
          color: rgba(51, 51, 51, 1);
          line-height: 16px;
          flex: 1;
          overflow: hidden;
          margin-right: 10px;
        }

        .numbox {
          font-size: 16px;
          color: #0080ff;
          font-weight: bold;
          text-align: right;
        }
      }
    }

    .WorkShiUl-bg-1,
    .WorkShiUl-bg-2,
    .WorkShiUl-bg-3 {
      width: 100%;
      height: 68px;
      position: absolute;
    }

    .WorkShiUl-bg-1 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 241, 232, 1) 100%);
    }

    .WorkShiUl-bg-2 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(227, 239, 255, 1) 100%);
    }

    .WorkShiUl-bg-3 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(224, 255, 235, 1) 100%);
    }

    .ziyuan_box_item {
      width: 100% !important;
      height: 58px;
    }

    .ziti2 {
      width: 100% !important;
    }

    .zitiLi {
      width: 100% !important;
    }
  }
}

.hdbdbox {
  width: 303px;
  height: 103px;
  background: rgba(238, 246, 255, 1);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;

  &:nth-child(3) {
    margin-bottom: 0;
  }

  .boxtop {
    width: 140px;

    .txttop {
      height: 42px;
    }

    .bomSty {
      margin-top: 10px;

      p {
        width: 50%;
        color: #3489FF;
        font-size: 12px;
        padding: 0 5px;
        line-height: 24px;


        &:first-child {
          color: #fff;
          background: #3489FF;
          border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border: 1px solid #3489FF;
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  .boxbom {
    .imgbox {
      img {
        width: 128px;
        height: 72px;
        border-radius: 8px;
      }
    }
  }
}
</style>
