<template>
  <MyDialog :open="open" :title="title" @onClose="onClose" width="400px">
    <div class="join_box">
      <p v-if="is_who === 2" class="tips">
        <span>工作室所有活动必须是工作室成员才能参与喔，申请加入工作室</span>
        <span class="tb">（同步参与该活动）</span>
      </p>
      <p v-if="is_who === 4" class="tips">
        <span>教研组所有活动必须是教研组成员才能参与喔，申请加入教研组</span>
        <span class="tb">（同步参与该活动）</span>
      </p>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input
            type="textarea"
            placeholder="请填写加入原因"
            v-model="form.reason"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="fe">
        <div class="sure cursor" @click="onAdd">确认申请</div>
      </div>
    </div>
  </MyDialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    // 1四名工作室首页，2四名活动详情，3教研组详情，4教研组活动详情
    is_who: {
      type: Number,
      default: 1,
    },
    group_id: {
      type: Number,
      default: 1,
    },
    
  },
  data() {
    return {
      open: false,
      form: {
        reason: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
    };
  },
  methods: {
    onClose() {
      this.open = false;
      this.form.reason = "";
    },

    // 确认申请加入
    onAdd() {
      if (!this.form.reason.trim()) {
        return this.$message.warning("申请原因不能为空");
      }
      let p = null;
      if (this.is_who == 1 || this.is_who == 2) {
        // 加入四名工作室接口
        p = this.$axios.post(
          "index/teachingStudioUser/addTeachingStudioUser",
          this.form
        );
      } else {
        // 加入教研组接口
        p = this.$axios.post("group/applyJoin",{
            id:this.group_id,
            user_id:localStorage.getItem("id"),
            reason:this.form.reason,
        });
      }

      p.then((res) => {
        this.$message.success("申请成功");
        this.open = false;
        this.$emit("onCallBack");
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/ .join_box {
  .tips {
    line-height: 24px;
    margin-bottom: 20px;
    .tb {
      color: #ff8201;
    }
  }
  .sure {
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    background: #3489fe;
    border-radius: 6px;
  }
  .el-textarea__inner {
    font-size: 16px;
    color: #9a9ea5;
  }
}
</style>