<template>
  <div class="StudioIndex public_main_bgc" :class="{ 'Studio_Index_nav': is_hidden_nav == 1 }">
    <!-- //banner图 -->
    <div class="banner" :style="{ background: `url(${detailData.background}) repeat-x 0% 0%` }">
      <div class="w_layout fsbc UserInfo">
        <div class="lBox">
          <!-- //左边头像 -->
          <div class="acvtor">
            <img :src="detailData.photo_url" class="img_cover" alt="" />
          </div>
          <!-- 右边个人信息 -->
          <div class="txtBox">
            <div class="Row1">
              <h4>{{ detailData.teaching_studio_name }}</h4>
              <div class="tabs_4" v-show="detailData.label">
                <p v-for="(item, index) in detailData.label.split(',')" :key="index">{{ item }}</p>
              </div>
              <div class="tabs_1" v-if="detailData.stage_name && !detailData.role">
                {{ detailData.stage_name }}-{{ detailData.subject_name }}
              </div>
              <div class="tabs_2" v-if="detailData.stage_name && detailData.role">
                {{ detailData.stage_name }}{{ detailData.subject_name }}
              </div>
              <div class="tabs_3">{{ detailData.provinces_cities_counties_str }}{{ detailData.host_type_str }}</div>
            </div>
            <div class="Row2" :class="{ 'active': detailData.role }">
              <p class="ellipsis">{{ detailData.teacher_synopsis }}</p>
              <span @click="toIntroduction()">[详情]</span>
            </div>
            <!-- is_addUser，null:未申请，0待审核，1审核通过,2拒绝申请 -->
            <div class="Row3" v-if="(detailData.is_addUser == null || detailData.is_addUser == 2) &&
    isToken &&
    detailData.apply_join == 1
    " @click="$refs.joinStudioRef.open = true">
              申请加入工作室
            </div>
            <div class="Row3 wait" v-if="detailData.is_addUser == 0">
              待审批加入工作室
            </div>

            <div v-if="detailData.role" class="Row4">
              <div class="Row_item_4">工作室管理员: {{ detailData.role_studio_admin || 0 }}</div>
              <div class="Row_item_4">骨干成员: {{ detailData.role_studio_elite || 0 }}</div>
              <div class="Row_item_4">网络成员: {{ detailData.role_studio_network || 0 }}</div>
            </div>

            <div class="Row_div">
              <!-- role:true为管理员 -->
              <div v-if="detailData.role" class="Row3" @click="toWorkAdmin()" style="margin-right: 20px;">
                工作室管理
                <span class="temp_class_span_div">-333</span>
              </div>
              <div class="Row5" @click="handleToInfo()"
                v-if="(detailData.user_id == nav_userId) && examination_details.teaching_studio_appraisal_id">
                <span v-if="examination_details.tsa_status">{{ examination_details.tsa_status.status_str }}</span>
                <p class="ellipsis"> {{ examination_details.show_title }} > </p>
              </div>
            </div>

          </div>
        </div>

        <div v-if="!detailData.role" class="rBox_1">
          <div class="txt_left">
            <p>{{ detailData.role_studio_elite || 0 }}</p>
            <p>骨干成员</p>
          </div>
          <div class="txt_right">
            <p>{{ detailData.role_studio_network || 0 }}</p>
            <p>网络成员</p>
          </div>
        </div>

        <div v-if="detailData.role" class="rBox_2">
          <div class="rBox_nav_2">待审核</div>
          <div class="rBox_list_2">
            <div class="rBox_item_2 rBox_color_1" @click="handlePage(1)">
              <p>成员加入：</p>
              <p>{{ toAuditData.status_user || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_2" @click="handlePage(2)">
              <p>直播管理：</p>
              <p>{{ toAuditData.audit_status_live || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_8" @click="handlePage(3)">
              <p>录播管理：</p>
              <p>{{ toAuditData.audit_status_course || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_3" @click="handlePage(4)">
              <p>教研活动：</p>
              <p>{{ toAuditData.audit_status_activity || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_4" @click="handlePage(5)">
              <p>研修资源：</p>
              <p>{{ toAuditData.audit_status_resource || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_5" @click="handlePage(6)">
              <p>教师文章：</p>
              <p>{{ toAuditData.status_article || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_6" @click="handlePage(7)">
              <p>研究成果：</p>
              <p>{{ toAuditData.status_certificate1 || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_7" @click="handlePage(8)">
              <p>研究课题：</p>
              <p>{{ toAuditData.status_certificate2 || 0 }}</p>
            </div>
            <div class="rBox_item_2 rBox_color_9" @click="handlePage(9)">
              <p>活动报道：</p>
              <p>{{ toAuditData.status_report || 0 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 导航 -->
    <div class="navBox">
      <div class="middleBox">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" background-color="#fff"
          text-color="#333333" active-text-color="#3489FF" :router="false">
          <template v-for="(item) in navListArr_1">
            <el-menu-item :key="item.id" :index="item.url">{{ item.title }}</el-menu-item>
          </template>
        </el-menu>

        <el-popover v-if="detailData.role" placement="bottom" width="" :open-delay="500" trigger="hover"
          popper-class="popper_class_edit_nav_box">
          <div>
            <div class="nav_title_div">
              <div class="nav_name">编辑导航
                <div class="nav_res_1_btn" @click="restoreNavList()">还原默认</div>
                <div class="nav_res_1_btn nav_res_2_btn" @click="saveNavList()">保存</div>
              </div>
              <div class="tips_title">点击下列输入框更改导航名称</div>
            </div>
            <div class="nav_list_div">
              <template v-for="(item) in navListArr_2">
                <div :key="item.id" class="nav_list_item">
                  <el-input v-model="item.title" :minlength="2" :maxlength="6" placeholder="请输入2-6个字的导航"></el-input>
                </div>
              </template>
            </div>
          </div>
          <span class="name_xiala_tag" slot="reference">
            <i class="el-icon-more"></i>
          </span>
        </el-popover>
      </div>
    </div>

    <!-- 子路由显示  -->
    <div class="mainBox" v-if="routerViewShow">
      <router-view :workData="detailData"></router-view>
    </div>

    <!-- 申请加入工作室弹窗 -->
    <JoinStudio ref="joinStudioRef" @onCallBack="getWorkDetail" title="申请加入工作室" />
  </div>
</template>

<script>
import JoinStudio from "@/components/joinStudio/index.vue";
import * as researchApi from '@/api/researchApi.js';
import { deepClone } from "@/utils/utils.js";
import { localGet, localSet, localRemove } from "@/utils/utils.js";
export default {
  components: { JoinStudio },
  data() {
    return {
      activeIndex: "",
      detailData: {
        label: "",
      }, //详情数据
      isToken: false,
      toAuditData: {},
      navListArr_1: [],
      navListArr_2: [],
      is_hidden_nav: "",
      teaching_studio_id: "",
      nav_userId: "",
      routerViewShow: true,
      examination_details: {},
      unserInfoData: null,
    };
  },
  created() {
    let query = this.$route.query;
    if (query && query.is_hidden_nav) {
      this.is_hidden_nav = query.is_hidden_nav;
    };
    if (query && (query.teaching_studio_id || query.studioId)) {
      this.teaching_studio_id = query.teaching_studio_id || query.studioId;
      localStorage.setItem("studioId", this.teaching_studio_id);
    } else {
      this.teaching_studio_id = localStorage.getItem("studioId");
    };
    this.nav_userId = localStorage.getItem("id");
    this.init();
    this.toAuditFn();
    this.getNavList();
    this.get_my_partake();

    if (query.is_show_head == 1) {
      this.routerViewShow = false
    }
    this.activeIndex = this.$route.path;
  },
  watch: {
    $route(route) {
      this.activeIndex = route.path;
      console.log(route.query.teaching_studio_id)
      this.teaching_studio_id = route.query.teaching_studio_id || route.query.studioId || localStorage.getItem("studioId")
      this.init();
      this.toAuditFn();
      this.getNavList();
      this.get_my_partake();
    },
  },
  methods: {
    // 查看详情
    handleToInfo() {
      this.$router.push({
        path: "/performanceDetails",
        query: {
          teaching_studio_appraisal_id: this.examination_details.teaching_studio_appraisal_id,
          teaching_studio_id: this.teaching_studio_id,
        },
      });
    },
    // 获取横幅
    get_my_partake() {
      let unserInfoData = localGet("unserInfoData");
      if (unserInfoData && unserInfoData.id && unserInfoData.token) {
        this.unserInfoData = unserInfoData;
      } else {
        this.unserInfoData = null;
      };
      if (!this.unserInfoData) {
        return false
      };
      let params = {
        teaching_studio_id: this.teaching_studio_id,
      };
      researchApi.jx_api_TeachingStudioAppraisal_my_partake(params).then(res => {
        let resData = res.data.data;
        this.examination_details = resData;
      })
    },
    // 还原默认导航
    restoreNavList() {
      let params = {
        teaching_studio_id: this.teaching_studio_id,
        id: this.nav_userId,
      };
      researchApi.get_activity_TeachingStudio_edit_reception_div(params).then(res => {
        this.$message.success("操作成功")
        this.getNavList();
      })
    },
    // 保存编辑导航
    saveNavList() {
      let navListArr_2 = deepClone(this.navListArr_2);
      try {
        navListArr_2.forEach(item => {
          if (!item.title || !item.title.length) {
            throw new Error("导航不能为空");
          }
        });
      } catch (error) {
        this.$message.error(error.message);
        return false;
      };
      let params = {
        teaching_studio_id: this.teaching_studio_id,
        id: this.nav_userId,
        navList: navListArr_2,
      };
      researchApi.get_activity_TeachingStudio_edit_reception_list(params).then(res => {
        this.$message.success("操作成功")
        this.getNavList();
      })
    },
    // 导航列表
    getNavList() {
      let params = {
        teaching_studio_id: this.teaching_studio_id,
        id: this.nav_userId,
      };
      researchApi.get_activity_TeachingStudio_reception_list_nav(params).then(res => {
        let tempArr = res.data.data;
        this.navListArr_1 = deepClone(tempArr);
        this.navListArr_2 = deepClone(tempArr);
      })
    },
    // 待审核数据
    toAuditFn() {
      let params = {
        teaching_studio_id: this.teaching_studio_id,
        id: this.nav_userId,
      };
      researchApi.get_activity_TeachingStudio_idnexTeachingStudio(params).then(res => {
        this.toAuditData = res.data.data;
      })
    },
    //跳转详情
    handlePage(num) {
      if (num == 1) {
        this.$router.push({
          path: "/TearmAudit",
          query: {
            teaching_studio_id: this.teaching_studio_id,
          },
        });
      }
      if (num == 2) {
        this.$router.push({
          path: "/LiveManage",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 3) {
        this.$router.push({
          path: "/RecordManage",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 4) {
        this.$router.push({
          path: "/ActivityManage",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 5) {
        this.$router.push({
          path: "/ResourceList",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 6) {
        this.$router.push({
          path: "/ArticleList",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 7) {
        this.$router.push({
          path: "/ResearchAchievement",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 8) {
        this.$router.push({
          path: "/ResearchTopic",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
      if (num == 9) {
        this.$router.push({
          path: "/ReportList",
          query: {
            teaching_studio_id: this.teaching_studio_id,
            type: 2,
          },
        });
      }
    },
    init() {
      this.activeIndex = this.$route.path;
      this.isToken = localStorage.getItem("token") == null ? false : true;
      this.getWorkDetail();
    },
    //获取工作室详情
    getWorkDetail() {
      if (this.teaching_studio_id) {
        this.$axios.get("/index/TeachingStudio/TeachingStudioInfo", {
          params: {
            id: this.teaching_studio_id,
            user_id: this.nav_userId,
          },
        }).then((res) => {
          this.detailData = res.data.data;
          //存个变量判断是否加入工作室了
          localStorage.setItem("joinStudio", this.detailData.role);
        });
      } else {
        setTimeout(() => {
          this.getWorkDetail();
        }, 1000);
      }
    },
    // 工作室管理
    toWorkAdmin() {
      this.$router.push({
        path: "/StudioAdminIndex",
        query: {
          teaching_studio_id: this.teaching_studio_id,
        },
      });
    },
    //跳转教师简介
    toIntroduction() {
      this.$router.push("/WorkRoomInduction");
    },
    handleSelect(key, keyPath) {
      this.$router.push({
        path: key,
        query: {
          teaching_studio_id: this.teaching_studio_id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.StudioIndex {
  width: 100%;
  // padding-bottom: 20px;
  min-height: 0 !important;

  .banner {
    height: 260px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    // background: url("https://dckj.ks3-cn-guangzhou.ksyun.com/cusn/20211008/3d068552-0968-4544-9578-0761bc0e9851.png") repeat-x 0% 0%;

    .UserInfo {
      height: 100%;

      .lBox {
        display: flex;
        align-items: flex-start;
        flex: 1;
        max-width: 950px;

        //左边头像
        .acvtor {
          width: 96px;
          height: 96px;

          img {
            width: 96px;
            height: 96px;
            border-radius: 4px;
            object-fit: fill;
          }
        }

        //右边个人信息
        .txtBox {
          margin-left: 33px;

          //第一行
          .Row1 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h4 {
              max-width: 340px;
              font-size: 24px;
              line-height: 32px;
              font-weight: 700;
              text-align: left;
              color: #ffffff;
            }

            .tabs_1 {
              border-radius: 5px;
              margin-left: 10px;
              color: #fff;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgba(255, 255, 255, 1);
              box-sizing: border-box;
              border-radius: 4px;
              padding: 2px 5px;
            }

            .tabs_2 {
              margin-left: 10px;
              height: 24px;
              background: #1794cd;
              border-radius: 4px;
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              border: 1px solid #fff;
              padding: 2px 5px;
            }

            .tabs_3 {
              margin-left: 10px;
              min-width: 80px;
              height: 24px;
              background: #ff8201;
              border-radius: 4px;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              padding: 2px 5px;
            }

            .tabs_4 {
              margin-left: 10px;
              display: flex;

              p {
                border: 1px solid #52b876;
                padding: 2px 5px;
                background: #fff;
                border-radius: 4px;
                font-size: 16px;
                display: flex;
                font-weight: bold;
                color: #52b876;
                margin-right: 10px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          //  第二行
          .Row2 {
            display: flex;
            align-items: center;
            margin-top: 16px;
            margin-bottom: 22px;
            cursor: pointer;

            &.active {
              margin-bottom: 12px;
            }

            p {
              max-width: 750px;
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #ffffff;
              line-height: 24px;
            }

            span {
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #fcff00;
              line-height: 24px;
            }
          }

          //第三行
          .Row3 {
            width: 150px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            background: #ff8201;
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            font-weight: bold;
            border: 1px solid #ff8201;

            &.wait {
              background-color: #d4b08c;
            }
          }

          .Row4 {
            display: flex;
            margin-bottom: 24px;

            .Row_item_4 {
              color: #fff;
              font-size: 16px;
              line-height: 16px;
              font-weight: bold;
              margin-right: 32px;
            }
          }
        }
      }

      .rBox_1 {
        display: flex;
        align-items: center;

        .txt_left,
        .txt_right {
          padding: 0 30px;

          p:nth-of-type(1) {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            color: #ffffff;
          }

          p:nth-of-type(2) {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
            line-height: 24px;
          }
        }

        .txt_right {
          border-left: 1px dashed #fff;
          padding: 0 0px 0 30px;
        }
      }

      .rBox_2 {
        // display: flex;
        align-items: center;
        justify-content: center;
        border-left-width: 43px;
        width: 388px;
        height: 220px;
        background: rgba(210, 230, 253, .8);
        border-radius: 8px;
        box-sizing: border-box;
        position: relative;
        padding: 12px;
        margin-top: 20px;

        .rBox_nav_2 {
          overflow: hidden;
          font-size: 18px;
          font-weight: bold;
          z-index: 20;
          color: rgba(70, 99, 140, 1);
          line-height: 18px;
          margin-top: 8px;
          margin-bottom: 18px;
        }

        .rBox_list_2 {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          box-sizing: border-box;
          align-items: center;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 1) 100%);
          padding-bottom: 10px;
          border-radius: 8px;

          .rBox_item_2 {
            display: flex;
            width: 108px;
            height: 40px;
            border-radius: 8px;
            font-weight: bold;
            margin-right: 6px;
            margin-left: 7px;
            cursor: pointer;
            color: #fff;
            flex-wrap: wrap;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;

            p {
              font-size: 16px;
              text-align: center;

              &:first-child {
                font-size: 14px;
              }
            }
          }

          .rBox_item_span_2 {
            font-weight: bold;
            padding-left: 6px;
          }

          .rBox_color_1 {
            background: #0DAC9E;
            margin-top: 8px;
          }

          .rBox_color_2 {
            background: #4ECAA6;
            margin-top: 8px;
          }

          .rBox_color_3 {
            background: #1BD2C5;
            margin-top: 8px;
          }

          .rBox_color_8 {
            background: #1EB8F7;
            margin-top: 8px;
          }

          .rBox_color_4 {
            background: #F4B74D;
            margin-top: 8px;
          }

          .rBox_color_5 {
            background: #486DEB;
            margin-top: 8px;
          }

          .rBox_color_6 {
            background: #9F6ADB;
            margin-top: 8px;
          }

          .rBox_color_7 {
            background: #C765DF;
            margin-top: 8px;
          }

          .rBox_color_9 {
            background: #F2895A;
            margin-top: 8px;
          }
        }
      }
    }
  }

  //导航样式
  .navBox {
    height: 60px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-width: 1px 0;

    .middleBox {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .name_xiala_tag {
        width: 32px;
        height: 22px;
        background: rgba(243, 250, 255, 1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3489ff;
      }

      .el-menu-item {
        font-size: 16px;
        padding: 0 !important;
        margin-right: 60px;
      }

      .el-menu-item:hover {
        background: #fff !important;
      }

      .is-active {
        border-bottom: 3px solid #3489ff !important;
        font-weight: 600;

        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
<style lang="less">
.popper_class_edit_nav_box {
  box-sizing: border-box !important;
  padding: 0 !important;
  width: 288px !important;
  background: rgba(255, 255, 255, 1) !important;
  border: 1px solid rgba(235, 238, 245, 1) !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1) !important;

  .nav_title_div {
    height: 90px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;
    border: 1px solid #ebeef5;

    .nav_name {
      font-size: 16px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 16px;
    }

    .nav_res_1_btn {
      line-height: 16px;
      font-size: 16px;
      cursor: pointer;
      font-weight: normal;
      color: rgba(254, 112, 25, 1);
    }

    .nav_res_2_btn {
      width: 64px;
      height: 30px;
      background: rgba(254, 112, 25, 1);
      border-radius: 4px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tips_title {
      margin-top: 8px;
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }
  }

  .nav_list_div {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 24px;
    padding-right: 24px;

    .nav_list_item {
      box-sizing: border-box;
      padding-top: 4px;
      padding-bottom: 4px;
      border-bottom: 1px dashed #ebeef5;
    }

    .nav_list_item:last-child {
      border: none;
    }
  }
}

.Studio_Index_nav {
  .navBox {
    display: none !important;
  }
}

.Row_div {
  display: flex;
  align-items: center;

  .Row5 {
    display: flex;
    flex-wrap: nowrap;
    max-width: 400px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 4px;
    color: #ff8201;
    cursor: pointer;
    border: 1px solid #ff8201;

    p {
      flex: 1;
      padding: 0 10px;
      background: #fef5eb;
      font-weight: bold;
    }

    span {
      padding: 0 5px;
      color: #fff;
      background: #ff8201;
      font-weight: bold;
    }
  }
}
</style>
