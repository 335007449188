<template>
  <div class="Activity">
    <div class="TopClass">
      <div class="select">
        <span class="title mr50">教研活动</span>
        <el-radio-group style="margin-top: -5px;" v-model="form.search_status" size="small" @change="init">
          <el-radio-button v-for="(item, index) in selectList" :key="index" :label="item.id">{{ item.name
          }}</el-radio-button>
        </el-radio-group>
      </div>
      <span @click="toMore" class="cursor">更多>></span>
    </div>
    <!-- 内容部分 -->
    <div class="contents">
      <div class="ListItem" v-if="listData.length > 0">
        <!-- 遍历教研活动 -->
        <el-card class="activity_block cursor" shadow="hover" :body-style="{ padding: '0px' }"
          v-for="(item, index) in listData" :key="index" @click.native="activityDetails(item.id, item)">
          <div class="img">
            <img :src="item.cover_url" class="img-dom" />

            <span class="activity_status" :style="{ background: `${bgc[item.status]}` }">
              {{
                item.status == 2
                ? "进行中"
                : item.status == 1
                  ? "未开始"
                  : item.status == 3
                    ? "已结束"
                    : ""
              }}
            </span>
          </div>
          <p class="word1">{{ item.name }}</p>
          <div class="word_other1">
            <span v-if="item.type_name" class="sp1">{{ item.type_name }}</span>
            <span v-if="item.implement_type" class="sp2">{{
              item.implement_type
            }}</span>
            <span class="sp3">{{ item.user_count }}+参与</span>
          </div>
          <!-- 新增时间 -->
          <div class="addTime">
            <span class="titles">时间</span>
            <span class="dates">
              {{ item.timeStr }}
            </span>
          </div>
          <div class="fc word2">
            <div class="fc">
              <span class="mr4">由</span>
              <span class="ellipsis studio_name">{{ item.initiator_name }}四名工作室发起</span>
            </div>
            <!-- 1不可参与 2可参与 3已参与 -->
            <p class="join" v-if="item.permiss_status == 2 && item.status != 3">
              可参与
            </p>
            <p class="join unjoin" v-if="item.permiss_status == 3 && item.status != 2">
              已参与
            </p>
          </div>
        </el-card>
        <!-- 遍历end -->
      </div>
      <CommonEmpty hVal="300px" v-else />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      active: 0,
      selectList: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "未开始",
        },
        {
          id: 2,
          name: "进行中",
        },
        {
          id: 3,
          name: "已结束",
        },
      ],
      form: {
        limit: 4,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
        search_type: "",
        search_implement_type: "",
        search_status: "", //活动状态 1进行中 2未开始 3已结束
      },
      bgc: {
        1: "#3489fe",
        2: "#ff8201",
        3: "#ddd",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getActivity();
    },
    //四名讲堂列表
    getActivity() {
      this.$axios
        .get("teachingStudioActivity/list", {
          params: this.form,
        })
        .then((res) => {
          this.listData = res.data.data.data;
          this.listData.map(e => {
            let arr1 = e.start_date.split("-")
            let arr2 = e.end_date.split("-")
            e.timeStr = `${arr1[1]}月${arr1[2]}日 - ${arr2[1]}月${arr2[2]}日`
          })
        });
    },
    //跳转更多活动
    toMore() {
      this.$router.push("/TrainActivityIndex");
    },
    activityDetails(id, item) {
      this.$router.push({
        name: "basicinfo",
        query: {
          activityId: id,
          is_from: 2,
          is_show_head: 1,
          teaching_studio_id: item.teaching_studio_id || "",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Activity {
  width: 1106px;
  height: 433px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 20px;
  padding: 26px 26px;

  //顶头标题
  .TopClass {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select {
      display: flex;
      cursor: pointer;

      span:nth-of-type(1) {
        font-size: 16px;
        font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        margin-right: 32px;
      }

      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }

      .noClass {
        height: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        padding: 0 20px;
      }

      .activeClass {
        height: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #3489fe;
        padding: 0 20px;
        position: relative;

        // border-bottom: 1px solid #3489FE;
        &::before {
          content: "";
          position: absolute;
          width: 30px;
          height: 2px;
          background: #3489fe;
          left: 50%;
          top: 22px;
          transform: translateX(-50%);
        }
      }
    }

    span:nth-of-type(1) {
      font-size: 16px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
    }
  }

  //内容部分
  .contents {
    display: flex;
    margin-top: 24px;

    //活动样式
    .ListItem {
      display: flex;

      .activity_block {
        width: 248px;
        margin-right: 21px;
        margin-bottom: 20px;
        position: relative;

        .img {
          width: 248px;
          height: 149px;
          display: flex;
          flex-direction: column-reverse;
          position: relative;

          .img-dom {
            width: 248px;
            height: 149px;
          }

          .activity_status {
            width: 120px;
            height: 60px;
            font-size: 14px;
            color: #fff;
            padding-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            box-sizing: border-box;
            transform: rotate(45deg);
            position: absolute;
            right: -45px;
            top: -15px;
          }
        }

        .word1 {
          width: 220px;
          margin-left: 20px;
          margin-top: 11px;
          margin-bottom: 11px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .word2 {
          padding: 20px 0;
          margin: 0 20px;
          color: #666666;
          font-size: 14px;
          border-top: 1px dashed #ebebeb;

          .studio_name {
            width: 130px;
            color: #3489fe;
          }

          .join {
            width: 50px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #ff8201;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            margin-left: 18px;

            &.unjoin {
              background-color: #3489fe;
            }
          }
        }

        .word_other1 {
          margin-left: 20px;
          margin-bottom: 15px;
          font-size: 14px;

          .sp1 {
            color: #3489fe;
            padding-right: 12px;
            margin-right: 12px;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 4px;
              width: 1px;
              height: 12px;
              background-color: #ebebeb;
            }
          }

          .sp2 {
            font-size: 14px;
            padding-right: 12px;
            margin-right: 12px;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 4px;
              width: 1px;
              height: 12px;
              background-color: #ebebeb;
            }
          }

          .sp3 {
            color: #a9a9a9;
          }
        }

        .addTime {
          display: flex;
          // justify-content: center;
          align-items: center;
          margin: 20px 0;
          margin-left: 20px;
          box-sizing: border-box;
          margin-right: 20px;

          .titles {
            width: 38px;
            height: 20px;
            background: #FF8201;
            border-radius: 2px 0 0 2px;
            color: #fff;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
          }

          .dates {
            height: 20px;
            color: #FF8201;
            font-size: 14px;
            border: 1px solid #FF8201;
            box-sizing: border-box;
            padding: 0 5px;
          }
        }

        .word_other2 {
          margin-left: 30px;
          margin-bottom: 15px;
          display: flex;

          .member {
            font-size: 14px;
          }

          .hand {
            width: 50px;
            height: 20px;
            background: #ff8201;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 700;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
          }
        }
      }

      .live_block {
        width: 335px;
        height: 299px;

        .img {
          height: 188px;
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          position: relative;

          .writer {
            position: absolute;
            width: 335px;
            height: 32px;
            background: rgba(0, 0, 0, 0.5);

            p {
              color: #fffffe;
              line-height: 32px;
              text-align: right;
              margin-right: 20px;
            }
          }
        }

        .word1 {
          margin-left: 30px;
          margin-top: 31px;
          margin-bottom: 20px;
          font-weight: 700;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .word2 {
          margin-left: 30px;
          color: #666666;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span:nth-child(1) {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
</style>