<template>
  <div class="questionbrowsing study-active-introduce">
    <div class="title">{{ resourceData.name }}</div>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="one_box">
        <span class="link-date">环节时间：{{ resourceData.start_date }} 至 {{ resourceData.end_date }}</span>

        <el-popover placement="top" width="" trigger="click" v-if="isEdit">
          <el-date-picker v-model="activityInfoTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeTImeEvent">
          </el-date-picker>
          <div class="change_time_div_btn" slot="reference" @click="openTImeSelectBox">
            更改时间
          </div>
        </el-popover>
        <el-dialog title="" :visible.sync="time_visible" width="500px" top="20vh">
          <div style="text-align: center;">
            <el-button type="primary" @click="handleSync(2)" v-if="activityInfoData.audit_status == 1">确定</el-button>
            <template v-else>
              <el-button type="primary" @click="handleSync(1)">确认并同步活动状态</el-button>
              <el-button type="primary" @click="handleSync(2)">不同步活动状态</el-button>
            </template>
            <el-button @click="time_visible = false">取消</el-button>
          </div>
        </el-dialog>

        <div v-if="resourceData.status ==1" class="conduct nostart"> 未开始 </div>
        <div v-if="resourceData.status ==2" class="conduct going"> 进行中 </div>
        <div v-if="resourceData.status ==3" class="conduct end"> 已结束 </div>
        <EndLink :id="id" :is_from="is_from" :status="resourceData.status" @success="endLinkSuccess" v-if="isEdit"></EndLink>
      </div>
      <div class="two">
        <span>环节类型：问卷</span>
        <span>开展方式：{{
            resourceData.implement_type == "1" ? "线上" : "线下"
          }}</span>
        <span>参与人数:{{ resourceData.user_count }}人</span>
      </div>
      <div class="public-line mb30"></div>
      <div class="three">环节描述</div>
      <div class="four">
        <!-- <p>{{ resourceData.description }}</p> -->
        <div v-bind:class="{ expansion: textActive }" class="show_text_all">
          <div class="rich_text_div_img" v-html="resourceData.description"></div>
        </div>
      </div>
      <div class="public-line mb30"></div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->

      <template v-if="subjectShow && resourceData.implement_type == 1">
        <Subject :data="resourceData" appName="问卷" :is_from="+is_from" @onSubmit="onSubmit" v-if="resourceData.status != 1 || (resourceData.user_id == userId || resourceData.teaching_group_user_id == userId)"></Subject>
        <p class="noData" v-else>该活动暂未开始</p>
      </template>

      <!-- 线下区域 -->
      <UnderLineDetail :is_from="+is_from" :resourceData="resourceData" v-else-if="resourceData.implement_type == 2"
        @getResourceData="getResourceData" :activityId="+activityId" :linkId="+id" />
    </div>
    <!-- 结束文案对话框 -->
    <el-dialog :visible.sync="writeVisible" width="25%" :show-close="false">
      <div class="copy_write">
        <div class="write_one">
          <span>{{ copywriting }}</span>
        </div>
        <div class="write_two">
          <el-button type="primary" @click="closecopy">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Subject from "./components/subject/subject.vue";
import UnderLineDetail from './components/underlineDetail/index.vue'
import * as researchApi from '@/api/researchApi.js';
import EndLink from "./components/endLink/endLink.vue";
export default {
  components: {
    Subject,
    UnderLineDetail,
    EndLink
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      time_visible: false,
      time_reqObj: {},
      activityInfoTime: [],
      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 资源浏览数据
      resourceData: {},
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",

      //线下表单
      xianxiaform: {
        name: "",
        accessoryId: "",
        url: "",
      },
      // 文本是否隐藏
      textActive: true,
      //分割的问卷数据
      divisionData: [],
      //展现的分割的问卷的数据
      showdivisondata: [],
      // 文案数据
      copywriting: "",
      // 控制文案数据显示与否
      writeVisible: false,
      subjectShow: false, //是否显示题目组件
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 点击更改时间
    openTImeSelectBox() {
      this.activityInfoTime = [`${this.resourceData.start_date}`, `${this.resourceData.end_date}`]
    },
    // 同步迟环节
    async handleSync(num) {
      try {
        let time_reqObj = this.time_reqObj;
        time_reqObj['is_sync_status'] = num;
        time_reqObj['is_personid'] = this.is_from;
        let res_1 = await researchApi.get_activity_activitylink_update_start_end_date(time_reqObj);
        this.time_visible = false;
        if (res_1.data.data.status) {
          this.$set(this.resourceData, "status", res_1.data.data.status);
          this.$set(this.resourceData, "start_date", res_1.data.data.start_date);
          this.$set(this.resourceData, "end_date", res_1.data.data.end_date);
        };
        this.$message.success("操作成功");
        this.$parent.$refs.linkNav.init();
      } catch (error) {
        this.time_visible = false;
      }
    },
    // 确定更改l环节时间
    changeTImeEvent(e) {
      if (e) {
        let time_reqObj = {
          id: this.id,
          start_date: e[0],
          end_date: e[1],
        };
        this.time_visible = true;
        this.time_reqObj = time_reqObj;
      }
    },
    // 初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("user_name");
      this.userId = window.localStorage.getItem("id");
      this.getResourceData();
    },
    //初始化获取问卷数据
    getResourceData() {
      if (this.is_from == 1) {
        this.$axios.get(`activity/${this.activityId}/activityLink/${this.id}`).then((res) => {
          var resdata = res.data;
          this.resourceData = resdata.data;
          this._questionnaire();
        });
      } else {
        this.$axios.get(`TeachingStudioactivity/${this.activityId}/activityLink/${this.id}`).then((res) => {
          this.resourceData = res.data.data;
          this._questionnaire();
          // this.resourceData.status = "2"
        });
      }
    },
    // 问卷结束文案接口
    _questionnaire() {
      this.$axios.get("default/questionnaire").then((res) => {
        this.copywriting = res.data.data.closings_txt;
        Object.assign(this.resourceData, { closingstxt: this.copywriting });
        this.subjectShow = true;
      });
    },
    // 分割数组函数
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    // 处理页数改变函数
    handlepage(val) {
      this.showdivisondata = this.divisionData[val - 1];
    },
    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    // 点击关闭文案对话框进行的函数
    closecopy() {
      this.writeVisible = false;
    },
    //问卷提交回调
    onSubmit() {
      this.getResourceData();
    },
    //结束环节
    endLinkSuccess(status) {
      this.$set(this.resourceData, "status", status);
      this.$parent.$refs.linkNav.init();
    },
  },
  computed: {
    //判断是否能编辑
    isEdit() {
      let uid = localStorage.getItem('id');
      if (uid == this.resourceData.user_id) {
        return true
      } else {
        return false
      }
    },
  },
};
</script>

<style scoped lang="less">
.questionbrowsing {
  width: 1116px;
  //   height: 2157px;
  background: #ffffff;
  border: NaNpx solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 0px 4px 7px 0px #f5f5f5;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    font-size: 20px;
    font-weight: 700;
    margin: 49px 0px 45px 0px;
    display: flex;
    justify-content: center;
  }
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    margin: 0 30px;
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .one_box {
      display: flex;
      margin: 41px 0px 23px;
      align-items: center;
      padding-right: 30px;
      .conduct {
        width: 51px;
        height: 18px;
        background: #ff8201;
        border-radius: 9px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 18px;
      }
      .going {
        background: #ff8201;
      }
      .nostart {
        background: #3489fe;
      }
      .end {
        background: #cecece;
      }
    }
    .two {
      color: #000000;
      margin-bottom: 39px;
      span {
        margin-right: 56px;
      }
    }
    .three {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 23px;
    }
    .four {
      margin-bottom: 25px;
      // p {
      //   position: relative;
      //   line-height: 28px;
      //   // max-height: 40px;
      //   overflow: hidden;
      //   width: 986px;
      //   height: 81px;
      // }
      // p::after {
      //   content: "...";
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      //   padding-left: 40px;
      //   background: -webkit-linear-gradient(left, transparent, #fff 55%);
      //   background: -o-linear-gradient(right, transparent, #fff 55%);
      //   background: -moz-linear-gradient(right, transparent, #fff 55%);
      //   background: linear-gradient(to right, transparent, #fff 55%);
      // }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 28px;
    }
    .seven {
      .test_block {
        margin-bottom: 40px;
        .block_one {
          margin-bottom: 30px;
          p {
            font-weight: 700;
            color: #1a1a1a;
            line-height: 34px;
            margin-right: 20px;
          }
        }
        .block_two {
          // margin-left: 20px;
        }
      }
    }
    .eight {
      display: flex;
      justify-content: center;
      .save {
        width: 148px;
        height: 42px;
        background: #ff8201;
        border-radius: 6px;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .page_style {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}
.copy_write {
  .write_one {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .write_two {
    display: flex;
    flex-direction: row-reverse;
  }
}
.option {
  .el-radio,
  .el-radio__input {
    white-space: normal;
    line-height: 20px;
  }
  .space {
    margin-bottom: 28px;
  }
}
.new_option {
  width: 955px;
  margin-bottom: 28px;
  .el-checkbox,
  .el-checkbox__input {
    white-space: normal;
  }
  /deep/ .el-checkbox__label {
    display: inline;
    line-height: 30px;
  }
  //   /deep/ .el-checkbox__input {
  // 	display: inline-grid;
  //     white-space: pre-line;
  //     word-wrap: break-word;
  //     overflow: hidden;
  //     line-height: 20px;
  //  }
}
.multiple {
  .el-checkbox-group {
    font-size: 14px;
  }
}
.noData {
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 15px;
}
</style>