<template>
  <div class="collaborationbrowsing">
    <div class="title">环节一：环节名称环节名称环节名称环节名称环节名称</div>
    <div class="hengxian"></div>
    <div class="main_content">
      <div class="one">
        <span>环节时间：2019-5-1 13:00 至 2019-8-1</span>
        <div class="conduct">进行中</div>
      </div>
      <div class="two">
        <span>环节类型：资源浏览</span>
        <span>开展方式：线上</span>
        <span>参与人数:1050人</span>
      </div>
      <div class="three">环节描述</div>
      <div class="four">
        <p>
          该课程目的是为公共卫生人员提供有关重症监护严重急性呼吸道病毒感染临床知识和实用技能，包括重症肺炎、急性呼吸道窘迫综合症（ARDS）；败血症和败血性休克等临床诊疗和预防管理。旨在掌握和了解从患者入住到出院诊疗流程和护理知识、技能和辅助工具应用，特别是医护人员自我保护措施PPE规则。同时，专设有科普视频，帮助公众和非医学专业背景共卫生知识，增长自身防护意识和预防应急能力败血症和败血性休克等临床诊疗和预防管理。旨在掌握和了解从患者入住到出院诊疗流程和护理知识、技能和辅助工具应用，特别是医护人员自我保护措施PPE规则。同时，专设有科普视频，帮助公众和非医学专业背景共卫生知识，增长自身防护意识和预防应急能力
        </p>
      </div>
      <!-- <div class="five cursor">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div> -->
      <div class="six">环节总结</div>
      <div class="seven">
        <Editor></Editor>
      </div>
      <div class="eight">
        <div class="eight_one cursor">
          <i class="el-icon-top"></i>
          <span>上传附件</span>
        </div>
        <p>支持扩展名：doc .docx .pdf .jpg .xlxs .xls</p>
      </div>
      <div class="nine">
        <el-button type="warning" class="commond_button">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.collaborationbrowsing {
  width: 1116px;
  //   height: 2157px;
  background: #ffffff;
  border: NaNpx solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 0px 4px 7px 0px #f5f5f5;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    font-size: 20px;
    font-weight: 700;
    margin: 49px 0px 45px 282px;
  }
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    margin-left: 30px;
    .one {
      display: flex;
      margin: 41px 0px 23px;
      align-items: center;
      .conduct {
        width: 51px;
        height: 18px;
        background: #ff8201;
        border-radius: 9px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 18px;
      }
    }
    .two {
      color: #000000;
      margin-bottom: 39px;
      span {
        margin-right: 56px;
      }
    }
    .three {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 23px;
    }
    .four {
      margin-bottom: 25px;
      p {
        position: relative;
        line-height: 28px;
        // max-height: 40px;
        overflow: hidden;
        width: 986px;
        height: 81px;
      }
      p::after {
        content: "...";
        position: absolute;
        bottom: 0;
        right: 0;
        padding-left: 40px;
        background: -webkit-linear-gradient(left, transparent, #fff 55%);
        background: -o-linear-gradient(right, transparent, #fff 55%);
        background: -moz-linear-gradient(right, transparent, #fff 55%);
        background: linear-gradient(to right, transparent, #fff 55%);
      }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 24px;
    }
    .seven {
      margin-right: 30px;
      margin-bottom: 26px;
      /deep/ .ql-editor {
        height: 200px;
      }
    }
    .eight {
      display: flex;
      align-items: center;
      align-items: center;
      margin-bottom: 52px;
      .eight_one {
        width: 114px;
        height: 34px;
        background: #3489fe;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin-right: 16px;
        i {
          margin-top: 3px;
          margin-right: 8px;
        }
      }
      p {
        color: #9a9ea5;
      }
    }
    .nine {
      display: flex;
      justify-content: center;
    }
  }
}
</style>