<template>
  <div class="noticeIndex">
    <div class="w_layout noticeList">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>工作室公告</span>
        </p>
      </div>
      <!-- 内容 -->
      <div class="mainContent">
        <div class="topT">
          <p>工作室公告</p>
          <el-button v-if="joinStudio != 'false'" @click="goPushNotice" type="primary" size="medium"
            style="margin-top: -5px;">新增公告</el-button>
        </div>
        <div v-if="total > 0">
          <div class="demoBox">
            <!-- //循环 -->
            <div v-for="(item, index) in listData" :key="index" class="forBox" @click="toDetail(item.id)">
              <div class="about" v-if="item.top == 1">
                置顶
              </div>
              <div class="oneBox">
                <p class="ellipsis" :title="item.title">{{ item.title }}</p>
              </div>
              <div class="twoBox">
                <div class="df">
                  <span class="sp ellipsis" :title="item.user_name">发布人：{{ item.user_name }}</span>
                  <div class="wzBox">
                    <span>{{ item.view_count }}人浏览</span>
                  </div>
                </div>
                <div class="wzBox">
                  <span>{{ item.create_time }}</span>
                </div>
              </div>
              <!-- <div class="oneBox">
                <p class="ellipsis" :title="item.title">{{ item.title }}</p>
              </div>
              <div class="twoBox">
                <span class="sp ellipsis" :title="item.user_name">{{ item.user_name }}</span>
                <div class="wzBox">
                  <span>{{ item.view_count }}人浏览</span>
                </div>
                <div class="wzBox">
                  <span>{{ item.create_time }}</span>
                </div>
              </div> -->
            </div>
          </div>
          <div v-show="total" class="pagina_div" style="height:100px">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
        <CommonEmpty v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      form: {
        limit: 12,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
        search_name: "",
      },
      listData: [],
      joinStudio: false,
    };
  },
  created() {
    this.joinStudio = localStorage.getItem("joinStudio")
    this.init();
  },
  methods: {
    goPushNotice() {
      this.$router.push({
        path: "/PushNotice"
      })
    },
    init() {
      //公告列表
      this.$axios
        .get("index/teachingStudioNotice/listsTeachingStudioNotice", {
          params: this.form,
        })
        .then((res) => {
          this.listData = res.data.data.data;
          this.total = res.data.data.total;
          console.log(res, "公告");
        });
    },

    //跳转详情
    toDetail(id) {
      let routeData = this.$router.resolve({
        path: "/noticeDetail",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    //   跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.noticeIndex {
  // background: #f7f7f7;
  padding-bottom: 50px;

  .noticeList {
    .title {
      width: 232px;
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    //内容
    .mainContent {
      width: 100%;
      background: #ffffff;
      border-radius: 6px;
      padding: 20px;
      box-sizing: border-box;

      .topT {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        p {
          text-align: left;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 10px;
          padding-left: 10px;
        }
      }

      .demoBox {
        .forBox {
          border-radius: 6px;
          border-bottom: 1px dashed #ebebeb;
          cursor: pointer;
          padding: 15px 20px;
          position: relative;

          .about {
            position: absolute;
            width: 40px;
            top: 0;
            right: 0;
            text-align: center;
            border-radius: 0 5px 0 5px;
            background: #ff0607;
            color: #fff;
            font-size: 14px;
            padding: 2px 5px;
          }

          &:hover {
            color: #3489ff;
            background: #eef6ff;

            .oneBox {

              p {
                font-weight: bold;
              }
            }
          }

          .oneBox {
            width: 100%;
            margin-bottom: 15px;

            p {
              font-size: 18px;
            }
          }

          .twoBox {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .sp {
              color: #8590a6;
              font-size: 14px;
              font-weight: 400;
            }

            .wzBox {
              white-space: nowrap;
              color: #8590a6;
              font-size: 14px;
              font-weight: 400;
              margin-left: 40px;

            }
          }
        }
      }

      .block {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
  }
}
</style>