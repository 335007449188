<template>
  <div class="mainBox">
    <div class="formBox">
      <el-form :model="form" :rules="rules" ref="form" label-width="140px" class="demo-ruleForm">
        <el-form-item label="直播封面：" required>
          <div class="toCenter">
            <div :class="activeClass0 == index ? 'box_img1' : 'box_img2'" @click="selectOnePhoto(index, item.photo_url)"
              v-for="(item, index) in selectPhoto" :key="index">
              <img :src="item.photo_url" alt="" />
              <i class="el-icon-check dg"></i>
            </div>

            <div class="upImg">
              <!-- 金山云组件上传图片 -->
              <dc-upload class="avatar-uploader" ref="upload" style="position: relative" :dcAutoUpload="true"
                :show-file-list="false" dcdom="ceshi" :dcConfig="dcConfig" @dc-success="dcSuccess"
                @dc-progress="dcProgressTrue = false" v-if="isUpOk" accept=".jpg, .png, .gif, .swf, .bmp, .jpeg, .psd">
                <!-- 上传后图片div -->
                <div v-if="coverUrl" class="avatar">
                  <img :src="coverUrl" />
                </div>
                <!-- 没上传div -->
                <div v-else class="jh">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <p>自定义封面</p>
                </div>
              </dc-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="直播主题：" prop="title">
          <el-input v-model="form.title" style="width: 600px" placeholder="最多20个字..." maxlength="20"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="直播标签：" prop="label">
          <el-input @input="change($event)" v-model="form.label" style="width: 600px"
            placeholder="请点击输入直播噱头、吸引人卖点等，最多50个字"></el-input>
        </el-form-item>
        <el-form-item label="直播时间：" prop="live_date">
          <el-date-picker v-model="form.start_date" type="date" :disabled="editStatus != 1" placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 250px; margin-right: 10px">
          </el-date-picker>
          <el-time-picker type="fixed-time" placeholder="选择开始时间" :disabled="editStatus != 1" v-model="form.start_time" value-format="HH:mm"
            format="HH:mm" style="width: 150px; margin-right: 5px" @change="change_start"></el-time-picker>
          <span>- </span>

          <el-time-picker type="fixed-time" placeholder="选择结束时间" :disabled="editStatus != 1" v-model="form.end_time" value-format="HH:mm"
            format="HH:mm" style="width: 150px; margin-right: 5px"></el-time-picker>
        </el-form-item>
        <el-form-item label="直播介绍：" prop="presentation">
          <Editor v-model="form.presentation" @blur="editorBlur"></Editor>
        </el-form-item>
        <el-form-item label="学段学科：" prop="checkListData">
          <el-cascader-multi v-model="checkListData" :data="listData" style="width: 200px" @change="changeListData">
          </el-cascader-multi>
        </el-form-item>
        <el-form-item label="教研资源：" prop="resources">
          <div class="df">
            <new-addresource :ifsingle="false" @ouput-value="getResource">
            </new-addresource>
            <span class="ml20 fz16" style="color: rgba(154, 158, 165, 1)">添加资源后，在直播过程中可调用添加的资源文件。</span>
          </div>
          <div class="listBOx">
            <div class="listItem" v-for="(item, index) in form.resources" :key="index">
              <!-- 左边 -->
              <div class="Item-left df">
                <p class="ellipsis">{{ item.title }}</p>
                <span class="hongse ml20 cp" @click="deteleOne(index)">删除</span>
              </div>
              <div class="Item-right">
                <el-checkbox v-for="items in downData" :key="items.id" v-model="item.permission"
                  :checked="item.permission == 1 ? true : false">{{ items.name }}</el-checkbox>
                <!-- <el-radio-group v-model="item.permission">
                  <el-radio v-for="items in downData" :key="items.id" :label="items.id">{{
                    items.name
                  }}</el-radio>
                </el-radio-group> -->
                <span class="ml30 mr30 c-999">|</span>
                <el-checkbox @change="setIsRecomend(index)" v-model="item.is_recommend">置顶</el-checkbox>
              </div>
            </div>
            <el-checkbox v-model="all_recommend" @change="changeall">允许下载资源文件</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="直播发起人：" prop="initiator_type" v-if="ishiddenSponsor">
          <el-radio-group v-model="form.initiator_type">
            <el-radio v-for="item in radioList" :key="item.id" :label="item.id" @change="radioChange(item.id)"
              class="mr20">
              {{ item.name }}
            </el-radio>
          </el-radio-group>
          <!-- //筛选 -->
          <div class="jyz" v-if="form.initiator_type == 2">
            <!-- //教研组 -->
            <el-radio-group v-model="form.group_id" @change="groupChange">
              <el-radio v-for="item in radioList1" :key="item.id" :label="item.id">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="jyz" v-if="form.initiator_type == 3">
            <!-- //工作室 -->
            <el-radio-group v-model="form.teaching_studio_id" @change="studioChange">
              <el-radio v-for="item in radioList1" :key="item.id" :label="item.id">
                {{ item.teaching_studio_name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="主持人：" prop="anchor_user_id">
          <el-select v-model="form.anchor_user_id" :disabled="editStatus != 1" filterable placeholder="请选择主持人" @change="change()">
            <el-option v-for="(item, index) in teachingGroupMemberList" :key="index" :label="item.realname"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="直播方式：" prop="live_type">
          <el-select v-model="form.live_type" :disabled="editStatus != 1" filterable placeholder="请选择直播方式" @change="liveTypeOptionChange">
            <el-option v-for="(item, index) in liveTypeOption" :key="index" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="回放设置：" prop="playbackSetup" v-if="form.live_type == 1">
          <div class="flex_align_center">
            <div>
              <el-select v-model="form.live_upload" :disabled="editStatus != 1" filterable placeholder="请选择回放设置" @change="playbackOptionChange">
                <el-option v-for="(item, index) in playbackOption" :key="index" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="flex_align_center ellipsis ml20" v-if="form.live_upload == 1">
              直播结束后
              <el-input v-model="form.live_upload_time" :disabled="editStatus != 1" placeholder="请输入" style="width: 80px"
                class="ml15 mr15"></el-input>
              分钟后自动上传
            </div>
          </div>
        </el-form-item>

        <el-form-item label="录播视频 :" prop="timeFn3" v-if="form.live_type == 2">
          <dc-upload v-if="!resource_video_data.length && isUpOk" class="upload-demo" ref="upload" :dcAutoUpload="true"
            :limit="1" dcdom="ceshi01" :dcConfig="dcConfig" @dc-success="dcFile" accept=".mp4,.MP4">
            <el-button slot="trigger" size="small" type="primary">选取视频文件</el-button>
            <div slot="tip" class="el-upload__tip">
              <span style="color: red">*</span>
              <span>请上传mp4格式视频，限制1个</span>
            </div>
          </dc-upload>
          <div class="videoBox" v-if="resource_video_data.length">
            <div class="forItemBox" v-for="(item, index) in resource_video_data" :key="index">
              <div class="one ellipsis_1"> {{ item.resource_name }} </div>
              <div class="two"> <span @click="clearVideo()">删除</span> </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button class="width148" @click="toBack0()">取消</el-button>
          <el-button class="width148" type="primary" style="background: rgba(255, 130, 1, 1); border: none"
            @click="submitForm('form')">保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!--教研会议选择指定成员弹窗 开始-->
    <!-- <meetingTearmTable ref="meetingTearmTable" v-model="meetingTearmTableShow" :tearmCheckedList="meetingTearmCheckedList"
      @tearmTableChecked="meetingTearmTableChecked"></meetingTearmTable> -->
    <!--教研会议选择指定成员弹窗 结束-->

    <UserSelectTable v-if="meetingTearmTableShow" :isShowTable="meetingTearmTableShow"
      :tearmCheckedList="meetingTearmCheckedList" :group_id="form.group_id"
      :teaching_studio_id="form.teaching_studio_id" :is_personid="form.is_personid" @confirmEvent="confirmEvent">
    </UserSelectTable>
  </div>
</template>
<script>
import meetingTearmTable from "../createActivity/components/tearmTable/tearmTable.vue";
import UserSelectTable from "../createActivity/components/UserSelectTable/UserSelectTable.vue";
export default {
  components: {
    meetingTearmTable,
    UserSelectTable,
  },
  data() {
    const validateLiveDate = (rule, value, callback) => {
      if (
        this.form.start_date == "" ||
        this.form.start_time == "" ||
        this.form.end_time == ""
      ) {
        callback(new Error("请选择直播时间"));
      } else {
        callback();
      }
    };
    const validateResources = (rule, value, callback) => {
      if (this.form.resources.length == 0) {
        callback(new Error("请选择教研资源"));
      } else {
        callback();
      }
    };
    const validateCheckListData = (rule, value, callback) => {
      if (this.checkListData.length == 0) {
        callback(new Error("请选择学段学科"));
      } else {
        callback();
      }
    };
    const validatePlaybackSetup = (rule, value, callback) => {
      console.log(this.form.live_upload);
      if (this.form.live_upload == "") {
        callback(new Error("请选择回放设置"));
      } else if (this.form.live_upload == 1 && this.form.live_upload_time == "") {
        callback(new Error("请输入上传时间"));
      } else {
        callback();
      }
    };
    const validateInitiator = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择直播发起人"))
      } else if (value == 2) {
        if (!this.form.group_id) {
          callback(new Error("直播发起人选择了教研组，请选择指定的教研组。"))
        } else {
          callback()
        }
      } else if (value == 3) {
        if (!this.form.teaching_studio_id) {
          callback(new Error("直播发起人选择了四名工作室，请选择指定的四名工作室。"))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const verifyFn_3 = (rule, value, callback) => {
      if (!this.resource_video_data || !this.resource_video_data.length) {
        callback(new Error("请选择上传视频"))
      } else {
        callback()
      }
    };
    return {
      all_recommend: false,
      isUpOk: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      //是否显示金山云组件标识
      isOk: false,
      //控制三张图变量
      activeClass0: 0,
      coverUrl: "",
      // 三张图片数组
      selectPhoto: [
        {
          id: 5886214,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/c3e45abc-6fa9-421d-8c62-25c976a8c44c.png",
        },
        {
          id: 5886215,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/5c130c6c-d3f4-4216-b9eb-57e7d681b74b.png",
        },
        {
          id: 5886216,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/b2156170-4de4-42c3-8c99-b2973de7360a.png",
        },
      ],
      form: {
        cover_url:
          "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/c3e45abc-6fa9-421d-8c62-25c976a8c44c.png",
        title: "",
        label: "",
        start_date: "",
        start_time: "",
        end_time: "",
        presentation: "",
        user_id: "",
        group_id: "",
        resources: [],
        subjects: [],

        anchor_user_id: "",
        live_type: 1,
        live_upload: 1,
        live_upload_time: 15,
        permiss_range: 1,
        sign_up: 2,
        start_time: "",
        end_time: "",
        teaching_studio_id: "",
        is_personid: "",
        initiator_type: "",
        video_resource_id: ""
      },
      //级联列表数据
      listData: [],
      //选中的学科数组
      selectId: [],
      //选中的数据
      checkListData: [],
      //教研组列表
      radioList1: [],
      //允许下载文件
      checked: true,
      //教研组表单
      groupForm: {
        limit: 10,
        page: 1,
        my_type: 1,
        audit_status: "",
      },
      // 定义可查看
      downData: [
        // {
        //   id: 1,
        //   name: "仅查看",
        // },
        {
          id: 2,
          name: "可下载",
        },
      ],
      radioList: [
        // {
        //   id: 1,
        //   name: "个人",
        // },
        {
          id: 2,
          name: "教研组",
        },
        {
          id: 3,
          name: "四名工作室",
        },
      ],
      liveTypeOption: [
        {
          name: "常规直播",
          value: 1,
        },
        {
          name: "录播直播",
          value: 2,
        },
      ],
      playbackOption: [
        {
          name: "自动上传",
          value: 1,
        },
        {
          name: "手动上传",
          value: 2,
        },
      ],
      rules: {
        title: [{ required: true, message: "请输入直播主题", trigger: "blur" }],
        label: [{ required: true, message: "请输入直播标签", trigger: "blur" }],
        live_date: [{ required: true, validator: validateLiveDate, trigger: "change" }],
        presentation: [{ required: true, message: "请输入直播介绍", trigger: "blur" }],
        checkListData: [
          { required: true, validator: validateCheckListData, trigger: "change" },
        ],
        resources: [{ required: true, validator: validateResources, trigger: "change" }],

        anchor_user_id: [
          {
            required: true,
            message: "请选择主持人",
            trigger: "change",
          },
        ],
        live_type: [
          {
            required: true,
            message: "请选择直播方式",
            trigger: "change",
          },
        ],
        live_upload: [
          {
            required: true,
            message: "请选择回放设置",
            trigger: "change",
          },
        ],
        live_upload_time: [
          {
            required: true,
            message: "请设置上传时间",
            trigger: "change",
          },
        ],
        permiss_range: [
          {
            required: true,
            message: "请选择允许谁参与",
            trigger: "change",
          },
        ],
        sign_up: [
          {
            required: true,
            message: "请选择允许其他人报名",
            trigger: "change",
          },
        ],
        playbackSetup: [
          {
            required: true,
            validator: validatePlaybackSetup,
            trigger: "blur",
          },
        ],
        initiator_type: [{
          required: true,
          validator: validateInitiator,
          trigger: 'change'
        }],
        timeFn3: [{ required: true, validator: verifyFn_3, trigger: 'change' }],
      },
      meetingTearmTableShow: false, //教研会议指定成员表格弹窗
      meetingTearmCheckedList: [], //教研会议指定成员列表
      meetingTearmTableChoiceNumber: 0, //教研会议指定成员选中数量
      teachingGroupMemberList: [],
      ishiddenSponsor: false,
      // 视频数据
      resource_video_data: [],
      editStatus: 1,
    };
  },
  created() {
    if (this.$route.query.groupId) {
      this.form.group_id = this.$route.query.groupId;
      this.form.is_personid = 1;
      this.form.initiator_type = 2;
      this.ishiddenSponsor = false;
    } else if (this.$route.query.studioId) {
      this.form.teaching_studio_id = this.$route.query.studioId;
      this.form.is_personid = 2;
      this.form.initiator_type = 3;
      this.ishiddenSponsor = false;
    } else {
      this.ishiddenSponsor = true;
    }
    if (this.$route.query.editStatus) {
      this.editStatus = this.$route.query.editStatus;
    }
    //从缓存中得到用户id
    this.form.user_id = localStorage.getItem("id");
    // 初始化金山云地址
    this._getAddress();
    //初始化学段信息
    this.getStageList();
    //初始化教研组
    this.getGroupList();
    if (this.$route.query.id) {
      this.getLiveInfo();
    }
    this.getApiTeachingGroupMember();
  },
  methods: {
    change() {
      this.$forceUpdate()
    },
    // 自动增加 3小时
    change_start(e) {
      if (!e) {
        return false;
      }
      let hour = e.split(":")[0];
      let minute = e.split(":")[1];
      hour = Number(hour) + 3;
      let end_time = hour > 23 ? "23:59" : `${hour < 10 ? "0" : ""}${hour}:${minute}`;
      this.$set(this.form, "end_time", end_time);
    },
    //获取直播信息
    getLiveInfo() {
      let live_id = this.$route.query.id;
      this.$axios.get("live/edit_info", { params: { id: live_id, is_personid: this.form.is_personid } }).then((res) => {
        let list = res.data.data;
        this.form.id = list.id;
        this.form.cover_url = list.cover_url;
        this.form.label = list.label;
        this.form.title = list.title;
        this.form.start_date = list.start_date;
        this.form.start_time = list.start_time;
        this.form.end_time = list.end_time;
        this.form.presentation = list.presentation;
        this.form.initiator_type = list.initiator_type;
        this.form.user_id = list.user_id;
        this.form.group_id = list.group_id;
        this.form.anchor_user_id = list.anchor_user_id;
        this.form.live_type = list.live_type;
        this.form.live_upload = list.live_upload;
        this.form.live_upload_time = list.live_upload_time;
        if (list.video_resource_id) {
          this.resource_video_data = [
            {
              id: list.video_resource_id,
              resource_name: list.video_resource_name,
              url: list.video_resource_url
            }
          ];
        }
        if (this.selectPhoto.indexOf(this.form.cover_url) == -1) {
          this.selectPhoto[3] = {
            id: 0,
            photo_url: this.form.cover_url,
          };
          this.activeClass0 = 3;
        }
        if (this.form.resources.length == 0) {
          //判断资源form表单长度为0
          //资源
          list.resource_data.forEach((item) => {
            //每循环一次就加一个
            this.form.resources.push({
              resource_id: item.resource_id,
              is_recommend: item.is_recommend == 2 ? true : false,
              permission: item.permission,
              title: item.resource_name,
            });
          });
        }

        //学段学科返回total
        this.checkListData = list.subject_data.map((item) => {
          let arr = [];
          arr.push(item.stage_id);
          arr.push(item.subject_id);
          return arr;
        });
      });
    },
    // 移除资源信息(完成)
    deteleOne(id) {
      this.form.resources.splice(id, 1);
    },
    changeall() {
      if (this.all_recommend) {
        this.form.resources.forEach((item) => {
          item.permission = false;
        });
      } else {
        this.form.resources.forEach((item) => {
          item.permission = true;
        });
      }
    },
    //选择第一张图片（完成）
    selectOnePhoto(index, urlImg) {
      this.activeClass0 = index;
      this.form.cover_url = urlImg;
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      this.isUpOk = false;
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      this.form.cover_url = resData.Location;
      this.selectPhoto[3] = {
        id: res.data.data.id,
        photo_url: resData.Location,
      };
      this.activeClass0 = 3;
      this.isUpOk = true;
    },
    //级联勾选数据的组件（完成）
    changeListData(val) {
      this.selectId = val;
    },
    //直播组件的数据（完成）
    getResource(data) {
      this.form.resources = [];
      //选中资源自动赋值表单
      data.forEach((item) => {
        this.form.resources.push({
          resource_id: item.id,
          permission: 1,
          is_recommend: false,
          title: item.title,
        });
      });
      this.$refs["form"].validateField("resources");
    },
    //获取学科学段信息（完成）
    async getStageList() {
      let res = await this.$axios.get("/stage/subject");
      //赋值级联列表
      this.listData = JSON.parse(
        JSON.stringify(res.data.data)
          .replace(/id/g, "value")
          .replace(/name/g, "label")
          .replace(/subject/g, "children")
          .replace(/childrenId/g, "value")
          .replace(/childrenName/g, "label")
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    setIsRecomend(idx) {
      let num = 0;
      this.form.resources.forEach((item, index) => {
        if (item.is_recommend == true) {
          num += 1;
        }
        console.log(num);
        if (num > 3) {
          this.$message.error("只能优先展示3条资源");
          this.form.resources[idx].is_recommend = false;
        }
      });
    },
    //获取教研组列表
    getGroupList(type = "") {
      if (this.$route.query.groupId || type == 2) {
        this.$axios.get("/TeacherGroup/get_my_list", {
          params: {
            my_type: 1,
            audit_status: ''
          }
        }).then((res) => {
          this.radioList1 = res.data.data.data
        })
      }
      if (this.$route.query.studioId || type == 3) {
        this.$axios.get("/user/TeachingStudioList", {
          params: {
            user_id: localStorage.getItem("id"),
            page: 1,
            limit: 99999
          }
        }).then((res) => {
          this.radioList1 = res.data.data.data
        })
      }
      // this.$axios
      //   .get("TeacherGroup/get_my_list", {
      //     params: this.groupForm,
      //   })
      //   .then((res) => {
      //     this.radioList1 = res.data.data.data;
      //   });
    },
    //当为教研组时候获取教研组id（完成）
    radioChange(id) {
      // let gid = this.$route.query.groupId;
      // this.form.group_id = gid;
      this.form.group_id = ""
      this.form.teaching_studio_id = ""
      this.getGroupList(id)
      this.form.is_personid = id - 1
      this.teachingGroupMemberList = []
      this.form.anchor_user_id = ""
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        let form = JSON.parse(JSON.stringify(this.form));
        this.selectId.forEach((list, index) => {
          if (list) {
            //每循环一次就给数组加一个空对象
            form.subjects.push({
              stage_id: "",
              subject_id: "",
            });
            //内循环
            list.forEach((item, idx) => {
              if (idx === 0) {
                form.subjects[index].stage_id = item;
              } else {
                form.subjects[index].subject_id = item;
              }
            });
          }
        });
        form.resources.forEach((item) => {
          item.permission = item.permission ? 1 : 2
          if (item.is_recommend) {
            item.is_recommend = 2;
          } else {
            item.is_recommend = 1;
          }
        });
        form.permiss_users = this.meetingTearmCheckedList;
        if (form.id) {
          this.$axios.post("live/updateLive", form).then((res) => {
            this.$message.success("修改成功");
            this.toBack0();
          });
        } else {
          this.$axios.post("live/insertLive", form).then((res) => {
            this.$message.success("创建成功");
            //课程id
            let kid = res.data.data.id;
            let is_personid = "";
            if (this.$route.query.groupId || this.form.initiator_type == 2) {
              is_personid = 1;
            }
            if (this.$route.query.studioId || this.form.initiator_type == 3) {
              is_personid = 2;
            }
            this.$router.push({
              path: "/livedetail",
              query: {
                liveid: kid,
                is_personid: is_personid
              },
            });
          });
        }
      });
    },
    //返回
    toBack0() {
      // let gid = this.$route.query.groupId;
      // if (gid) {
      //   this.$router.push(`/groupDetailLive?groupId=${gid}`);
      // } else {
      //   this.$router.push(`/groupDetailLive?groupId=${gid}`);
      // }
      this.$router.go(-1);
    },
    getApiTeachingGroupMember() {
      let apiUrl = "";
      let params = {
        limit: 99999,
        page: 1,
      };
      if (this.form.is_personid == 1) {
        params.group_id = this.form.group_id
        apiUrl = "/get_appoint_user";
      }
      if (this.form.is_personid == 2) {
        params.teaching_studio_id = this.form.teaching_studio_id
        apiUrl = "/get_appoint_user_teachingstudio";
      }
      if (!apiUrl) {
        return false
      }
      this.$axios
        .get(apiUrl, {
          params: params
        })
        .then((res) => {
          this.teachingGroupMemberList = res.data.data.data;
          if (!this.form.anchor_user_id) {
            setTimeout(() => {
              this.form.anchor_user_id = Number(localStorage.getItem('id'))
            }, 300);
          }
        });
    },
    //教研会议选择指定成员回调
    meetingTearmTableChecked(list) {
      this.meetingTearmCheckedList = list;
      this.meetingTearmTableChoiceNumber = this.meetingTearmCheckedList.length;
    },
    //选择指定成员
    confirmEvent(isOk, list) {
      if (isOk && Array.isArray(list)) {
        this.meetingTearmCheckedList = list;
        this.meetingTearmTableChoiceNumber = this.meetingTearmCheckedList.length;
      }
      this.meetingTearmTableShow = false;
      //this.$refs['form'].validateField('permiss_users'); //单独校验
    },
    editorBlur() {
      this.$refs["form"].validateField("presentation");
    },
    groupChange() {
      this.form.is_personid = 1;
      this.getApiTeachingGroupMember();
    },
    studioChange() {
      this.form.is_personid = 2;
      this.getApiTeachingGroupMember();
    },
    meetingTearmTable() {
      if (!this.form.group_id && !this.form.teaching_studio_id) {
        this.$message.warning("请选择允许谁参与");
        return false;
      }
      this.meetingTearmTableShow = true;
    },
    //回放设置选项变更
    playbackOptionChange() {
      this.form.live_upload_time = "";
      this.$refs["form"].validateField("playbackSetup");
    },
    //直播方式选项变更
    liveTypeOptionChange() {
      this.form.video_resource_id = "";
    },
    // 清除视频
    clearVideo() {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.resource_video_data = [];
      }).catch(() => {
      });
    },
    // 上传视频
    async dcFile(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      let etag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", { params: { etag: etag } });
      this.resource_video_data = [{
        id: res.data.data.id,
        resource_name: res.data.data.name,
        url: res.data.data.url,
      }];
      this.form.video_resource_id = this.resource_video_data[0].id;
      this.$refs['form'].validateField('timeFn3'); //单独校验
    },
  },
};
</script>

<style lang="scss" scoped>
.mainBox {
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  min-height: 1227px;

  .formBox {
    width: 95%;
    margin: 0 auto;

    .toCenter {
      display: flex;

      //三张图片样式
      .box_img1 {
        .dg {
          z-index: 1;
          position: absolute;
          margin-left: -100px;
          margin-top: 30px;
          font-size: 38px;
          color: #f7f7f7;
        }

        background: #000;
        width: 164px;
        height: 92px;
        margin-right: 5px;
        //

        img {
          position: relative;
          z-index: 2;
          width: 164px;
          height: 92px;
          opacity: 0.6;
        }
      }

      // 没有打钩的样式
      .box_img2 {
        .dg {
          display: none;
        }

        width: 164px;
        height: 92px;
        margin-right: 5px;

        img {
          position: relative;
          z-index: 2;
          width: 164px;
          height: 92px !important;
        }
      }

      //图片上传样式
      .upImg {
        margin-left: 10px;

        // 上传图片样式
        .avatar-uploader {

          //  上传图片样式
          .avatar {
            width: 164px;
            height: 92px;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 92px;
            }
          }

          //没上传图片样式
          .jh {
            width: 164px;
            height: 92px;
            box-sizing: border-box;
            border: 1px dashed#EBEBEB;
            position: relative;

            .avatar-uploader-icon {
              font-size: 20px;
              margin-top: 20px;
            }

            p {
              position: absolute;
              font-size: 14px;
              color: rgba(51, 51, 51, 1);
              width: 164px;
              text-align: center;
              left: 0;
              bottom: 10px;
            }
          }
        }

        //添加一张图片之后隐藏后续框样式
        .disabled .el-upload--picture-card {
          display: none;
        }
      }
    }

    .listBOx {
      margin-top: 20px;

      .listItem {
        background: rgba(238, 242, 251, 1);
        border-radius: 2px;
        width: 100%;
        display: flex;
        margin-top: 8px;
        padding: 0 10px;

        //  左边样式
        .Item-left {
          width: calc(100% - 350px);

          p {
            max-width: 370px;
            overflow: hidden;
          }
        }

        //  右边样式
        .Item-right {
          width: 350px;

          span {
            color: rgba(154, 158, 165, 1);
          }
        }
      }
    }

    .jyz {
      border: 1px solid rgba(236, 236, 236, 1);
      width: 824px;
      border-radius: 4px;
      padding: 20px 30px;

      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 4px;
        background-color: skyblue;
        background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
      }

      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ededed;
        border-radius: 4px;
      }

      /deep/.el-radio {
        //width: 20%;
        margin: 20px 15px;
        white-space: normal;
      }
    }
  }
}

/deep/.permiss {
  display: flex;

  .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
  }
}

.participation-box {
  display: flex;

  .box-left {
    margin-right: 15px;
  }

  .box-right {
    flex: 1;
    overflow: hidden;

    .participation-people {
      font-size: 14px;
      color: #606266;
      cursor: pointer;
      display: inline;

      span {
        color: #f56c6c;
        margin: 0 4px;
      }
    }
  }
}

.videoBox {
  width: 60%;
  margin-top: 5px;
  padding: 0 10px;
  background: rgba(238, 242, 251, 1);
  border-radius: 4px;

  .forItemBox {
    display: flex;

    .one {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .two {
      cursor: pointer;

      span {
        margin-left: 10px;
        color: #409eff;
      }
    }
  }
}
</style>
