<template>
  <el-dialog width="380px" custom-class="login-dialog" :visible.sync="dialogLoginVisible" @close="dialogLoginClose" v-if="dialogLoginVisible">
    <Login></Login>
  </el-dialog>
</template>

<script>
import DialogLogin from "./dialogLogin.js";
export default DialogLogin;
</script>

<style lang="scss" scoped>
@import "./dialogLogin.scss";
</style>