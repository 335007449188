<template>
  <div class="GroupCreateLive">
    <div class="newCourseNav">
      <p>
        当前位置：个人中心 > 我的直播 >
        <span style="color: #ff8201">修改直播</span>
      </p>
    </div>
    <div class="mainBox">
      <div class="formBox">
        <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
          <el-form-item label="直播封面：" required>
            <div class="toCenter">
              <div :class="activeClass0 == index ? 'box_img1' : 'box_img2'" @click="selectOnePhoto(index, item.photo_url)"
                v-for="(item, index) in selectPhoto" :key="index">
                <img :src="item.photo_url" alt="" />
                <i class="el-icon-check dg"></i>
              </div>

              <div class="upImg">
                <!-- 金山云组件上传图片 -->
                <dc-upload class="avatar-uploader" ref="upload" style="position: relative" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi"
                  :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="isUpOk">
                  <!-- 上传后图片div -->
                  <div v-if="this.coverUrl" class="avatar">
                    <img :src="this.coverUrl" />
                  </div>
                  <!-- 没上传div -->
                  <div v-else class="jh">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <p>自定义封面</p>
                  </div>
                </dc-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="直播主题：" prop="title">
            <el-input v-model="form.title" style="width: 600px" placeholder="最多50个字..."></el-input>
          </el-form-item>
          <el-form-item label="直播标签：" prop="label">
            <el-input v-model="form.label" style="width: 600px" placeholder="请点击输入直播噱头、吸引人卖点等，最多50个字"></el-input>
          </el-form-item>
          <el-form-item label="直播时间：" required>
            <el-date-picker v-model="form.start_date" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
              style="width: 250px; margin-right: 10px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="选择开始时间" v-model="form.start_time" value-format="HH:mm" format="HH:mm"
              style="width: 150px; margin-right: 5px" @change="change_start"></el-time-picker>
            <span>- </span>

            <el-time-picker type="fixed-time" placeholder="选择结束时间" v-model="form.end_time" value-format="HH:mm" format="HH:mm"
              style="width: 150px; margin-right: 5px"></el-time-picker>
          </el-form-item>
          <el-form-item label="直播介绍：" required>
            <Editor v-model="form.presentation"></Editor>
          </el-form-item>
          <el-form-item label="学段学科：" required class="mt120">
            <el-cascader-multi v-model="checkListData" :data="listData" style="width: 200px" @change="changeListData">
            </el-cascader-multi>
          </el-form-item>
          <el-form-item label="教研资源：" required>
            <div class="df">
              <new-addresource :ifsingle="false" @ouput-value="getResource">
              </new-addresource>
              <span class="ml20 fz16" style="color: rgba(154, 158, 165, 1)">添加资源后，在直播过程中可调用添加的资源文件。</span>
            </div>
            <div class="listBOx">
              <div class="listItem" v-for="(item, index) in saveList" :key="index">
                <!-- 左边 -->
                <div class="Item-left df">
                  <p class="ellipsis">{{ item.title }}</p>
                  <span class="hongse ml20 cp" @click="deteleOne(index)">删除</span>
                </div>
                <div class="Item-right">
                  <el-radio-group v-model="form.resources[index].permission">
                    <el-radio v-for="items in downData" :key="items.id" :label="items.id">{{ items.name }}</el-radio>
                  </el-radio-group>
                  <span class="ml30 mr30 c-999">|</span>
                  <el-checkbox v-model="form.resources[index].is_recommend">优先显示至列表</el-checkbox>
                </div>
              </div>
              <el-checkbox v-model="all_recommend" @change="changeall">允许下载资源文件</el-checkbox>
            </div>
          </el-form-item>
          <el-form-item label="直播发起人：" required>
            <el-radio-group v-model="form.initiator_type">
              <el-radio v-for="item in radioList" :key="item.id" :value="item.id" :label="item.id" @click.native="radioChange(item.id)" class="mr20">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
            <!-- //筛选 -->
            <div class="jyz" v-if="this.isGroup == true">
              <!-- //教研组 -->
              <el-radio-group v-model="form.group_id">
                <el-radio v-for="item in radioList1" :key="item.id" :value="item.id" :label="item.id">
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item style="text-align: center">
            <el-button class="width148" @click="toBack0()">取消</el-button>
            <el-button class="width148" type="primary" @click="submitForm('form')" style="background: rgba(255, 130, 1, 1); border: none">保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //设置一个url设置图片
      coverUrl: "",
      //表单数据源
      form: {
        id: "",
        cover_url: "",
        title: "",
        start_date: "",
        start_time: "",
        end_time: "",
        presentation: "",
        initiator_type: "",
        user_id: "",
        group_id: 0,
        /*存放资源id和资源权限
             resources:[{
                          resource_id:1,
                          permission:1    资源权限：1：仅查看 2：可下载
                       }]
             
             */
        resources: [],
        /*存放学段id和学科id
             resources:[{
                          stage_id:1,
                          subject_id:1    资源权限：1：仅查看 2：可下载
                       }]
             
             */
        subjects: [],
      },

      //级联列表数据
      listData: [],
      //选中的学科数组
      selectId: [],

      //选中的数据
      checkListData: [],

      //是否显示金山云组件标识
      isOk: false,
      //控制三张图变量
      activeClass0: -1,

      // 三张图片数组
      selectPhoto: [
        {
          id: 5886214,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/c3e45abc-6fa9-421d-8c62-25c976a8c44c.png",
        },
        {
          id: 5886215,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/5c130c6c-d3f4-4216-b9eb-57e7d681b74b.png",
        },
        {
          id: 5886216,
          photo_url:
            "https://dckj.ks3-cn-guangzhou.ksyun.com/test/20201110/b2156170-4de4-42c3-8c99-b2973de7360a.png",
        },
      ],

      //------未修改如下
      // 创建时间
      dataTime: "",
      selectPeople: [],
      permiss_groups: [],
      // 定义可查看

      downData: [
        {
          id: "1",
          name: "仅查看",
        },
        {
          id: "2",
          name: "可下载",
        },
      ],
      //定义个数组存放资源返回
      saveList: [],
      cover_url: "",
      isUpOk: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      radioList: [
        {
          id: 1,
          name: "个人",
        },
        {
          id: 2,
          name: "教研组",
        },
      ],

      //   允许下载文件
      checked: true,
    };
  },
  watch: {
    //监听上传图片是否变化
    coverUrl() {
      this.activeClass0 = -1;
    },
    //监听form.cover_url
    form: {
      handler(list) {
        this.selectPhoto.forEach((item, index) => {
          //循环判断form表单中url是否和默认图片url相同，相同则设置打钩
          if (list.cover_url == item.photo_url) {
            //将默认图片的下标赋值
            this.activeClass0 = index;
          }
        });
      },
      deep: true,
    },
  },

  created() {
    //从缓存中得到用户id
    this.form.user_id = localStorage.getItem("id");
    // 初始化金山云地址
    this._getAddress();
    //初始化学段信息
    this.getStageList();
    //初始化直播信息
    this.getLiveInfo();
  },

  methods: {
    // 自动增加 3小时
    change_start(e) {
      if (!e) {
        return false;
      };
      let hour = e.split(":")[0];
      let minute = e.split(":")[1];
      hour = Number(hour) + 3;
      let end_time = `${hour}:${minute}`;
      this.$set(this.form, "end_time", end_time);
    },
    //获取直播信息
    getLiveInfo() {
      let live_id = this.$route.query.id;
      this.$axios.get("live/edit_info", { params: { id: live_id } }).then((res) => {
        let list = res.data.data;
        this.form.id = list.id;
        this.form.cover_url = list.cover_url;
        this.form.title = list.title;
        this.form.start_date = list.start_date;
        this.form.start_time = list.start_time;
        this.form.end_time = list.end_time;
        this.form.presentation = list.presentation;
        this.form.initiator_type = list.initiator_type;
        this.form.user_id = list.user_id;
        this.form.group_id = list.group_id;

        //判断资源form表单长度为0
        if (this.form.resources.length == 0) {
          //资源
          list.resource_data.forEach((item) => {
            //每循环一次就加一个
            this.form.resources.push({
              resource_id: item.resource_id,

              permission: item.permission.toString(),
            });
            //每循环一次就加一个
            this.saveList.push({
              title: item.resource_name,
            });
          });
        }

        //学段学科返回total
        this.checkListData = list.subject_data.map((item) => {
          let arr = [];
          arr.push(item.stage_id);
          arr.push(item.subject_id);
          return arr;
        });
      });
    },

    //修改直播函数 async
    GetAllData() {
      //当这个学段学科的长度为0,才进行循环
      if (this.form.subjects.length == 0) {
        this.selectId.forEach((list, index) => {
          //每循环一次就给数组加一个空对象
          this.form.subjects.push({
            stage_id: "",
            subject_id: "",
          });
          //内循环
          list.forEach((item, idx) => {
            if (idx === 0) {
              this.form.subjects[index].stage_id = item;
            } else {
              this.form.subjects[index].subject_id = item;
            }
          });
        });
      }
      //判断

      if (this.activeClass0 == -1) {
        this.form.cover_url = this.coverUrl;
      }
      this.$axios.post("live/updateLive", this.form).then((res) => {
        this.$message.success("编辑成功");
        //课程id
        this.$router.push(`/livedetail?liveid=${this.form.id}`);
      });
    },

    //级联勾选数据的组件（完成）
    changeListData(val) {
      this.selectId = val;
    },
    //直播组件的数据（完成）
    getResource(data) {
      //选中资源自动赋值表单
      data.forEach((item) => {
        //  该数组是否含有该资源id，有则不push,没有则push
        this.form.resources.push({ resource_id: item.id, permission: "1" });
      });

      //判断显示
      if (this.saveList.length == 0) {
        this.saveList = data;
      } else {
        data.forEach((item) => {
          this.saveList.push(item);
        });
      }
    },
    //获取学科学段信息（完成）
    async getStageList() {
      let res = await this.$axios.get("/stage/subject");
      //赋值级联列表
      this.listData = JSON.parse(
        JSON.stringify(res.data.data)
          .replace(/id/g, "value")
          .replace(/name/g, "label")
          .replace(/subject/g, "children")
          .replace(/childrenId/g, "value")
          .replace(/childrenName/g, "label")
      );
    },

    //当为教研组时候获取教研组id（完成）
    radioChange(id) {
      if (id == 1) {
        this.form.group_id = 0;
      }
    },
    // 移除资源信息(完成)
    deteleOne(id) {
      this.saveList.splice(id, 1);
      this.form.resources.splice(id, 1);
      //  this.form.resource_ids.splice(id,1)
    },

    //  ------未改

    //选择第一张图片（完成）
    selectOnePhoto(index, urlImg) {
      // index为数组下标----
      //  赋值操作
      this.activeClass0 = index;
      //得到id
      this.form.cover_url = urlImg;
    },
    //返回
    toBack0() {
      let gid = this.$route.query.groupId;
      if (gid) {
        this.$router.push(`/groupDetailLive?groupId=${gid}`);
      } else {
        this.$router.go(-1);
      }
    },

    //   跳转直播详情页
    Details() {
      this.$router.push({
        path: "/liveBroadcast",
      });
    },

    // /移除选中
    deletePeople(id) {
      //  移除一条数据
      this.selectPeople.splice(id, 1);
    },

    // -----------------------------金山云不用改
    //上传失败的时候会出现Bug，故在上传失败的时候也进行一下判断
    error: function (err, file, fileList) {
      this.$message.error("上传失败");
      if (fileList.length >= 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      // 上传成功就关掉
      this.isUpOk = false;
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      //把本地文件地址给cover_url变量
      // this.form.cover_url = resData.Location;
      this.coverUrl = resData.Location;
      this.isUpOk = true;
    },
    // 上传资源成功回调
    async dcFile01(data) {
      this.loading = true;
      var etagString = data.upload.CompleteMultipartUploadResult.ETag;

      var param = {
        etag: etagString.slice(1, etagString.length - 1),
      };
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: param.etag,
        },
      });

      //定义一个数组，再把etage码请求返回的id和文件类型信息存入对象中，在push进saveList数组里
      // data是相应文件信息.text等
      //资源地址： data.upload.CompleteMultipartUploadResult.Location
      this.saveList.push({
        id: res.data.data.id,
        filename: data.filename,
        img_url: data.upload.CompleteMultipartUploadResult.Key,
        uploadtime: this.dataTime,
        loadLocation: data.upload.CompleteMultipartUploadResult.Location,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.GroupChangeLive {
  width: 100%;
  //  display: flex;
  //  justify-content: center;
  background: #f7f7f7;
  padding-bottom: 50px;
  .newLiveMain {
    padding: 0;
    margin: 0;
    .newCourseNav {
      width: 1100px;
      margin-left: 265px;
      line-height: 70px;
      height: 70px;
      background: #f7f7f7;
      font-size: 14px;
    }
    .newLiveBox {
      width: 1200px;
      background: #ffffff;
      margin: 0 auto;

      .el-form {
        //num=2
        .el-textarea__inner {
          height: 150px;
          overflow-y: auto;
          /* overflow-y: auto;兼容ie  */
        }
        //  教研组样式
        .jyz {
          border: 1px solid#ccc;
          overflow-y: scroll;
          width: 300px;
          height: 100px;
          margin-left: 100px;
          .OneBox {
            margin: 10px 10px;
          }
        }
        //  谁允许
        .Twolist {
          border: 1px solid#ccc;
          overflow-y: scroll;
          width: 300px;
          height: 100px;
          margin-left: 100px;
          padding: 5px 5px;
        }
        //  指定人员样式
        .Threelist {
          border: 1px solid #ccc;
          overflow-y: scroll;
          width: 800px;
          height: 100px;
          display: flex;
          .item {
            width: 20%;
            height: 100%;
            border-right: 1px solid #ccc;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            font-size: 13px;

            div {
              width: 100px;
              border-radius: 4px;
              line-height: 40px;
              text-align: center;
            }
            div:nth-of-type(1) {
              color: white;
              background: #3489fe;
              cursor: pointer;
            }
            div:nth-of-type(2) {
              border: 1px solid #ccc;
              cursor: pointer;
            }
          }
          .item1 {
            padding: 5px 5px;
            display: flex;
            flex-wrap: wrap;
            .item_box {
              display: flex;
              margin-right: 10px;
              background-color: rgba(64, 158, 255, 0.1);
              padding: 0 10px;
              height: 32px;
              line-height: 30px;
              font-size: 12px;
              color: #409eff;
              border-radius: 4px;
              box-sizing: border-box;
              border: 1px solid rgba(64, 158, 255, 0.2);
              white-space: nowrap;
              position: relative;
              .wz {
                cursor: pointer;
                span {
                  text-align: center;
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .toCenter {
          display: flex;
          //三张图片样式
          .box_img1 {
            .dg {
              z-index: 1;
              position: absolute;
              margin-left: -100px;
              margin-top: 30px;
              font-size: 38px;
              color: #f7f7f7;
            }
            background: #000;
            width: 164px;
            height: 92px;
            margin-right: 5px;
            //

            img {
              position: relative;
              z-index: 2;
              width: 100%;
              opacity: 0.6;
            }
          }

          // 没有打钩的样式
          .box_img2 {
            .dg {
              display: none;
            }
            width: 164px;
            height: 92px;
            margin-right: 5px;
            img {
              position: relative;
              z-index: 2;
              width: 100%;
            }
          }
          //图片上传样式
          .upImg {
            margin-left: 10px;
            // 上传图片样式
            .avatar-uploader {
              //  上传图片样式
              .avatar {
                width: 164px;
                height: 92px;
                box-sizing: border-box;
                img {
                  width: 100%;
                  height: 92px;
                }
              }
              //没上传图片样式
              .jh {
                width: 164px;
                height: 92px;
                box-sizing: border-box;
                border: 1px dashed#EBEBEB;
                .avatar-uploader-icon {
                  font-size: 30px;
                  margin-top: 35px;
                }
              }
            }

            //添加一张图片之后隐藏后续框样式
            .disabled .el-upload--picture-card {
              display: none;
            }
          }
        }

        //文件列表上传样式
        .upload-demo1 {
          .listItem {
            width: 100%;
            display: flex;
            margin-top: 5px;
            padding: 5px 5px;
            cursor: pointer;
            //  左边样式
            .Item-left {
              width: 75%;
            }
            //  右边样式
            .Item-right {
              span {
                margin-left: 10px;
                color: #409eff;
              }
            }
          }
          .listItem:hover {
            box-shadow: #ccc 1px 1px 1px 1px;
          }
        }
      }
      //下一步样式
      .nextCourseDetail {
        width: 100%;
        //头部
        .nextTopCourse {
          display: flex;
          margin-bottom: 10px;
          // 左边图片
          .TopLeft {
            width: 40%;
            height: 243px;
            border: 1px solid #cccc;
            margin-right: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            img {
              width: 100%;
            }
          }
          //头部右边
          .TopRight {
            width: 60%;
            height: 243px;
            margin: auto 0;
            .titleTop {
              height: 60px;
              display: flex;
              align-items: center;
              h3 {
                line-height: 28px;
                margin-right: 15px;
              }
              div {
                margin-right: 5px;
              }
            }
            //  便签
            .bq {
              display: flex;
              height: 60px;
              margin-bottom: 10px;
              align-items: center;
              .two {
                width: 50px;
                height: 20px;
                border-radius: 10px;
                border: 1px solid #999999;
                font-size: 12px;
                line-height: 20px;
                text-align: center;
                color: #999999;
                margin-left: 20px;
              }
              div {
                margin-right: 10px;
                font-size: 15px;
                span {
                  color: #999999;
                }
              }
            }
            .bq2 {
              height: 60px;
              span {
                color: #999999;
              }
            }
            //最后一行
            .bq_last {
              font-size: 15px;
              height: 100px;
              span {
                color: #999999;
              }
            }
          }
          //  按钮
          #wzt {
            font-size: 14px;
            color: red;
          }
        }
        //标题
        .title_bottom {
          display: flex;
          justify-content: space-between;
          span {
            font-weight: bold;
            font-size: 20px;
          }
          span {
            margin-right: 195px;
          }
        }
        //内容
        .nextContentBox {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          margin-top: 10px;
          width: 100%;
          margin-bottom: 20px;
          h4 {
            margin-bottom: 20px;
            margin-top: 20px;
          }
          //  左边
          .n_Left {
            width: 1045px;
            height: 659px;
            margin-right: 20px;
            border: 1px solid #cccc;
          }
          //  右边
          .n_right {
            width: 1045px;
            // border: 1px solid #cccc;
            display: flex;
            flex-wrap: wrap;
            padding: 10px 10px;
            .iBox {
              width: 500px;
              border: 1px solid #cccc;
              height: 60px;
              display: flex;
              margin-left: 15px;
              margin-bottom: 10px;
              .box1 {
                img {
                  width: 60px;
                  height: 60px;
                }
              }
              .box2 {
                margin-top: 10px;
                margin-left: 10px;
                p {
                  color: #999999;
                }
              }
              .box3 {
                margin-top: 15px;
                left: 530px;
                padding-left: 100px;
                span {
                  color: #1890ff;
                  margin-right: 20px;
                  font-size: 15px;
                }
              }
            }
          }
        }
        // 底部评论
        .discussBox {
          width: 1045px;
          // 循环遍历
          .forItem {
            width: 935px;
            display: flex;
            .forItem_left {
              width: 50px;
              height: 50px;
              background: red;
              border-radius: 50%;
              img {
                width: 100%;
              }
            }
            // 右边内容
            .forItem_right {
              margin-left: 10px;
              .TitleTop {
                font-size: 14px;
                span:nth-of-type(1) {
                  color: #fa8c15;
                  margin-right: 5px;
                }
                span:nth-of-type(2) {
                  color: #9a9ea5;
                }
              }
              p {
                font-size: 14px;
              }
              .btn1 {
                display: flex;
                font-size: 14px;
                color: #9a9ea5;
                margin-left: 700px;
                div {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      .newLiveNav {
        line-height: 50px;
        height: 50px;
        border-bottom: 1px solid #ebebeb;
      }
      .newLiveMiddle {
        display: flex;
        justify-content: center;
        width: 100%;
        .el-form {
          width: 945px;
          .el-radio {
            line-height: 40px;
          }
        }

        .main_img {
          display: flex;
          margin-top: 50px;
          margin-bottom: 30px;
          margin-left: 20px;
          position: relative;
          .item1 {
            width: 130px;
            height: 130px;
            border: 1px solid black;
            img {
              width: 100%;
            }
          }
          .uploads {
            width: 100px;
            height: 100px;
            position: absolute;
            left: 138px;
            top: 20px;
            span {
              color: #3489fe;
            }
          }
        }
      }
      .items {
        text-align: center;
        margin-left: -40px;
        margin-top: 100px;
        h4 {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        p {
          span {
            color: #3489fe;
            font-size: 15px;
          }
          span:nth-of-type(1) {
            margin-left: 18px;
          }
        }
      }
    }
  }
}
</style>