export default {
  props: {},
  data() {
    return {
      //问题参数
      problem: {
        type: "",
        title: "",
        description: "",
      },
      //问题类型选项
      problemTypeOption: [
        {
          name: "问卷",
          value: 1,
        },
        {
          name: "投票",
          value: 2,
        },
      ],
      //问题类型验证
      problemRules: {
        type: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入说明",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getSubjectSubmitDescriptionDefault();
    this.getLocalRecord();
    //获取创建类型
    if (this.$route.query.type) {
      this.problem.type = Number(this.$route.query.type);
      this.problemTypeChange(this.problem.type);
    }
  },
  mounted() {},
  methods: {
    //获取本地历史记录
    getLocalRecord() {
      if (sessionStorage.getItem("problem_basic_info_data")) {
        let data = JSON.parse(sessionStorage.getItem("problem_basic_info_data"));
        this.problem.title = data.title;
        this.problem.description = data.description;
      }
    },
    //获取提交成功后的文案默认值
    async getSubjectSubmitDescriptionDefault() {
      const res = await this.$axios.get("/default/questionnaire");
      sessionStorage.setItem(
        "problem_subject_submit_description",
        res.data.data.closings_txt
      );
    },
    //创建按钮   type=1创建，type=2使用历史题目创建
    createButton(type, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          sessionStorage.setItem(
            "problem_basic_info_data",
            JSON.stringify(this.problem)
          );
          if (type == 1) {
            sessionStorage.setItem("problem_subject_data", "");
          }
          this.$emit("submitData", type);
        }
      });
    },
    //取消按钮
    cancelButton() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    },
    //问卷类型选择回调
    problemTypeChange(val) {
      this.$emit("problemType", val);
    },
  },
  watch: {
    //监听问卷说明
    "problem.description"() {
      this.$refs["problem"].validateField("description");
    },
  },
};
