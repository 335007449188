<template>
  <div class="public_main_bgc">
    <div class="public_main_div GroupIndex public_css_module">
      <div class="bannerSty">
        <el-carousel trigger="click" height="348px">
          <el-carousel-item v-for="(item, index) in 1" :key="index">
            <img class="item_img_img" src="@/assets/banner_img/jiaoyanzu_banner.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="title_box">
        <div class="title_name">推荐教研组</div>
        <div class="title_btn" @click="goMoreGroup()">查看更多>></div>
      </div>
      <div class="recommend_box">
        <CommonEmpty v-if="!recommend_list.length" hVal="100%"></CommonEmpty>
        <div class="recommend_item" :class="{ 'active': index >= 3 }" v-for="(item, index) in recommend_list" :key="index">
          <div class="title_logo" :class="{ 'cp': index >= 3 }" @click="toGroupDetail(item, index, 1)">
            <div class="title_img_box">
              <img :src="item.cover_url" alt="">
            </div>
            <div class="title_right_name">
              <div class="title_name_box ellipsis-1">{{ item.name || '-' }}</div>
              <template v-if="index < 3">
                <div class="title_name_tag active">
                  <span class="title_name_span">组长：{{ item.username || '-' }}</span>
                  <span>成员：{{ item.user_count || '0' }}</span>
                </div>
                <div class="title_name_list pdActive">
                  <div class="title_name_item ellipsis-1">{{ item.label || '-' }}</div>
                </div>
              </template>
              <template v-if="index >= 3">
                <div class="title_name_tag">
                  <span class="title_name_span">组长：{{ item.username || '-' }}</span>
                  <span>成员：{{ item.user_count || '0' }}</span>
                </div>
                <div class="title_name_list pdActive">
                  <div class="title_name_item active_1 ellipsis-1">{{ item.type_text }}</div>
                  <div class="title_name_item active_2 ellipsis-1">{{ item.stage_name }}{{ item.subject_name }}</div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="false" class="course_list">
            <template v-for="(ite_2, inde_2) in item.resource">
              <div v-if="inde_2 < 3" class="course_item" :key="inde_2">
                <div class="course_img_box">
                  <img :src="ite_2.mime | fileTypeMini" alt="">
                </div>
                <div class="course_name ellipsis-1">{{ ite_2.resource_name || '-' }}</div>
              </div>
            </template>
          </div>
          <div class="course_list">
            <div class="course_item course_div">
              <div class="course_name active ellipsis-1" @click="handlePageEvent(item.live.go_url)">
                【直播推荐】{{ item.live.name || '-' }}
              </div>
            </div>
            <div class=" course_item course_div">
              <div class="course_name active ellipsis-1" @click="handlePageEvent(item.activity.go_url)">
                【活动推荐】{{ item.activity.name || '-' }}
              </div>
            </div>
            <div class="course_item course_div">
              <div class="course_name active ellipsis-1" @click="handlePageEvent(item.resource.go_url)">
                【资源推荐】{{ item.resource.name || item.resource.title?item.resource.title:'-' }}
              </div>
            </div>
          </div>
          <template v-if="index < 3">
            <div class="tag_box">
              <div class="tag_text">{{ item.stage_name }}{{ item.subject_name }}</div>
              <div class="tag_span"></div>
              <div class="tag_text">活动：{{ item.activity_count || 0 }}</div>
              <div class="tag_span"></div>
              <div class="tag_text">资源：{{ item.result_count || 0 }}</div>
            </div>
            <div class="btn_box" @click="toGroupDetail(item)">进入教研组</div>
          </template>
        </div>
      </div>

      <div class="title_box">
        <div class="title_name">教研直播</div>
        <div class="title_btn" @click="goMoreListEvent(2)">查看更多>></div>
      </div>
      <div class="list_box_1">
        <CommonEmpty v-if="!live_list.length" hVal="100%"></CommonEmpty>
        <template v-for="(item, index) in live_list">
          <PublicTtemDiv :key="index" :navActive="2" :currentIndex="index" :item="item" :isColorFFF="index == 0">
          </PublicTtemDiv>
        </template>
      </div>

      <div class="title_box active">
        <div class="title_name">教研活动</div>
        <div class="title_btn" @click="goMoreListEvent(1)">查看更多>></div>
      </div>
      <div class="list_box_1 list_box_2">
        <CommonEmpty v-if="!activity_list.length" hVal="100%"></CommonEmpty>
        <template v-for="(item, index) in activity_list">
          <PublicTtemDiv :key="index" :navActive="1" :currentIndex="index" :item="item" :isColorFFF="index == 0">
          </PublicTtemDiv>
        </template>
      </div>

      <div class="title_box active">
        <div class="title_name">教学视频</div>
        <div class="title_btn" @click="goMoreListEvent(3)">查看更多>></div>
      </div>
      <div class="list_box_1 list_box_3">
        <CommonEmpty v-if="!course_list.length" hVal="100%"></CommonEmpty>
        <template v-for="(item, index) in course_list">
          <PublicTtemDiv :key="index" :navActive="3" :currentIndex="index" :item="item" :isColorFFF="index == 0">
          </PublicTtemDiv>
        </template>
      </div>

      <div class="class_main">
        <!-- 研修成果 -->
        <div class="keyan_div">
          <div class="title_box active">
            <div class="title_name">研修成果</div>
            <!-- <div class="title_btn">查看更多>></div> -->
          </div>
          <div class="keyan_list">
            <CommonEmpty v-if="!research_list.length" hVal="100%"></CommonEmpty>
            <div class="keyan_item_box" v-for="(item, index) in research_list" :key="index">
              <new-viewonline :viewSrc="item.view_url" :teaching_studio_user_resource_id="item.teaching_studio_user_resource_id"
                :resourceId="item.r_resource_id || item.resource_id" :fileExt="item.mime || item.ext" :itemData="item" :fileTitle="item.title"
                :fileSize="item.size" :fileTime="item.create_time">
                <div class="keyan_item_div">
                  <div class="keyan_left_img_box">
                    <img :src="item.mime | fileTypeSmall" alt="">
                  </div>
                  <div class="keyan_right_box">
                    <div class="keyan_title ellipsis-1">{{ item.title || '-' }}</div>
                    <div class="keyan_tag_box">
                      <div class="keyan_tag_text">{{ item.sources_name || '-' }}</div>
                      <div class="keyan_tag_span"></div>
                      <div class="keyan_tag_text">{{ item.pharse_name || '-' }}</div>
                      <div class="keyan_tag_span"></div>
                      <div class="keyan_tag_text">{{ item.subject_name || '-' }}</div>
                      <i class="keyan_tag_icon el-icon-view"></i>
                      <div class="keyan_tag_num">{{ item.watch_number || 0 }}</div>
                      <i class="keyan_tag_icon active el-icon-star-off"></i>
                      <div class="keyan_tag_num">{{ item.star_avg || 0 }}</div>
                    </div>
                  </div>
                </div>
              </new-viewonline>
            </div>
          </div>
        </div>
        <!-- 教研组排行 -->
        <div class="educationSort_div">
          <div class="title_box active">
            <div class="title_name">教研组排行</div>
            <!-- <div class="title_btn">查看更多>></div> -->
          </div>
          <div class="educationSort_box active">
            <div class="educationSort_list">
              <CommonEmpty v-if="!teacherSortListArr.length" hVal="100%"></CommonEmpty>
              <div class="educationSort_item" v-for="(item, index) in teacherSortListArr" :key="index" @click="handlePageInfo_2(item)">
                <div class="educationSort_img_box">
                  <div v-if="index > 2" class="educationSort_img_sort">{{ index + 1 }}</div>
                </div>
                <div class="educationSort_name ellipsis-1"> {{ item.name || '-' }} </div>
                <div class="educationSort_num"> {{ item.view_count || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="class_main">
        <!-- 话题研讨 -->
        <div class="keyan_div">
          <div class="title_box">
            <div class="title_name">话题研讨</div>
            <div class="title_btn" @click="goMoreTopic">查看更多>></div>
          </div>
          <div class="keyan_list">
            <CommonEmpty v-if="!groupGambitListArr.length" hVal="100%"></CommonEmpty>
            <div class="keyan_item_box" v-for="(item, index) in groupGambitListArr" :key="index" @click="toTopiDetail(item)">
              <div class="keyan_item_div">
                <div class="keyan_left_img_box">
                  <img src="@/assets/imgFIle_3/home_huatiicon.png" alt="">
                </div>
                <div class="keyan_right_box">
                  <div class="keyan_title ellipsis-1">{{ item.title || '-' }}</div>
                  <div class="keyan_tag_box">
                    <div class="keyan_tag_text ellipsis-1">{{ item.teacher_group_name || '-' }}</div>
                    <div class="keyan_tag_span"></div>
                    <div class="keyan_tag_text ellipsis-1">{{ item.stage_text || '-' }}</div>
                    <div class="keyan_tag_span"></div>
                    <div class="keyan_tag_text ellipsis-1">{{ item.subject_text || '-' }}</div>
                    <i class="keyan_tag_icon el-icon-view"></i>
                    <div class="keyan_tag_num">{{ item.visit_count || 0 }}</div>
                    <i class="keyan_tag_icon active el-icon-star-off"></i>
                    <div class="keyan_tag_num">{{ item.reply_count || 0 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 教研组动态 -->
        <div class="educationSort_div">
          <div class="title_box">
            <div class="title_name">教研组动态</div>
            <!-- <div class="title_btn">查看更多>></div> -->
          </div>
          <div class="educationSort_box active_2">
            <div class="educationDontai_div">
              <div class="educationDontai_list active">
                <CommonEmpty v-if="!teacherListArr.length" hVal="100%"></CommonEmpty>
                <div class="educationDontai_item" v-for="(item, index) in teacherListArr" :key="index">
                  <div class="educationDontai_info fxaicjcsb">
                    <div class="educationDontai_img_box mgr14">
                      <img :src="item.photo_url" alt="">
                    </div>
                    <div class="fx1">
                      <div class="name_box fxaic">
                        <div class="name_name ftfz16 ftlh16 ftc333 ellipsis_1 fx1 fttaL">{{ item.username || "-" }}
                        </div>
                        <div class="name_date ftfz16 ftlh16 ftc999">{{ item.create_time || "-" }}</div>
                      </div>
                      <div class="name_info mgt12 fttaL ftfz14 ftc666">{{ item.group_name || "-" }}</div>
                    </div>
                  </div>
                  <div class="educationDontai_mesg fxaicjcsb mgt10" @click="handlePageInfo_1(item)">
                    <div class="shanjiaoxin"></div>
                    <div class="tag_box fxcenter mgr8 pdL7 pdr7">{{ item.commet || "-" }}</div>
                    <div class="tag_name_box ellipsis_1 fx1">{{ item.title || "-" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import * as researchApi from '@/api/researchApi.js';
export default {
  name: "GroupIndex",
  data() {
    return {
      recommend_list: [],
      live_list: [],
      activity_list: [],
      course_list: [],
      research_list: [],
      groupGambitListArr: [],
      teacherListArr: [],
      teacherSortListArr: [],
    }
  },
  computed: {},

  methods: {
    // 进入详情
    handlePageEvent(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    // 跳转详情
    handlePageInfo_1(item) {
      if (item.go_url) {
        window.open(item.go_url, "_blank");
      }
      return
      // 直播
      if (item.type == 1) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + item.go_url)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          window.open(item.go_url, "_blank");
        }
      };
      // 活动
      if (item.type == 4) {
        window.open(item.go_url, "_blank");
      };
      // window.open(item.go_url, "_self");
    },
    // 跳转详情
    handlePageInfo_2(item) {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/groupdetailshouye?groupId=${item.id}`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        let routeData = this.$router.resolve({
          path: `/groupdetailshouye?groupId=${item.id}`,
        });
        window.open(routeData.href, "_blank");
      }
    },
    //跳转更多热门话题
    goMoreTopic() {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/community`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push("/community");
      }
    },
    //教研组推荐更多跳转
    goMoreGroup() {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/GroupListOld`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push("/GroupListOld");
      }
    },
    // 跳转更多
    goMoreListEvent(num) {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/researchCenter?navActive=${num}`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/researchCenter",
          query: {
            navActive: num,
          },
        });
      }
    },
    //跳转教研组详情
    toGroupDetail(item, index, num) {
      if (num == 1) {
        if (index && index >= 3) {
          if (sessionStorage.getItem('is_hide') == 1) {
            let hostes = location.origin
            let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/groupdetailshouye?groupId=${item.id}`)
            window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
          } else {
            this.$router.push({
              path: "/groupdetailshouye",
              query: {
                groupId: item.id,
              },
            });
          }
        }
      } else {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/groupdetailshouye?groupId=${item.id}`)
          window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push({
            path: "/groupdetailshouye",
            query: {
              groupId: item.id,
            },
          });
        }
      }
    },
    //跳转到话题详情
    toTopiDetail(item) {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/topicDetails?id=${item.id}`)
        window.open(`${localStorage.getItem("yun_url")}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/topicDetails",
          query: {
            id: item.id,
          },
        });
      }
    },
    // 推荐教研组
    getTeacherGroupIndexList() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 6;
      researchApi.get_TeacherGroupIndex_list(params).then(res => {
        let resdata = res.data.data;
        // resdata.forEach(item => {
        //   this.$set(item, 'resource', item.data_resource)
        // });
        this.recommend_list = resdata;
      })
    },
    // 教研直播
    getTeacherGroupLive() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 4;
      researchApi.get_TeacherGroup_live(params).then(res => {
        let resdata = res.data.data;
        // resdata.data.forEach(item => {
        //   this.$set(item, 'resource', item.TeacherGroup_live_field)
        // });
        this.live_list = resdata.data;
      })
    },
    // 教研活动
    getTeacherGroupActivity() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 4;
      researchApi.get_TeacherGroup_activity(params).then(res => {
        let resdata = res.data.data;
        // resdata.data.forEach(item => {
        //   this.$set(item, 'resource', item.activity_resource)
        // });
        this.activity_list = resdata.data;
      })
    },
    // 教学视频
    getTeacherGroupCourse() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 4;
      researchApi.get_TeacherGroup_course(params).then(res => {
        let resdata = res.data.data;
        // resdata.data.forEach(item => {
        //   this.$set(item, 'resource', item.activity_resource)
        // });
        this.course_list = resdata.data;
      })
    },
    // 研修成果
    getTeacherGroupResourceList() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 10;
      researchApi.get_TeacherGroup_resource_list(params).then(res => {
        let resdata = res.data.data;
        this.research_list = resdata.data;
      })
    },
    // 话题研讨
    get_TeacherGroup_groupGambitLists() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 10;
      researchApi.get_TeacherGroup_groupGambit_lists(params).then(res => {
        let resdata = res.data.data;
        this.groupGambitListArr = resdata.data;
      })
    },
    // 教研组动态
    get_TeacherGroupListTeacherGroupUserLog() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 9;
      researchApi.get_TeacherGroup_ListTeacherGroupUserLog(params).then(res => {
        let resdata = res.data.data;
        this.teacherListArr = resdata.data;
      })
    },
    // 教研组排行
    get_TeacherGroupListTeacherGroupViewCount() {
      let params = {};
      params['page'] = 1;
      params['limit'] = 5;
      researchApi.get_TeacherGroup_listTeacherGroupViewCount(params).then(res => {
        let resdata = res.data.data;
        this.teacherSortListArr = resdata.data;
      })
    },
  },

  destroyed() {
  },

  mounted() {
    // 推荐教研组
    this.getTeacherGroupIndexList();
    // 教研直播
    this.getTeacherGroupLive();
    // 教研活动
    this.getTeacherGroupActivity();
    // 教学视频
    this.getTeacherGroupCourse();
    // 研修成果
    this.getTeacherGroupResourceList();
    // 话题研讨
    this.get_TeacherGroup_groupGambitLists();
    // 教研组动态
    this.get_TeacherGroupListTeacherGroupUserLog();
    // 教研组排行
    this.get_TeacherGroupListTeacherGroupViewCount();
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
.GroupIndex {
  padding-top: 20px;
  box-sizing: border-box;
  padding-bottom: 40px;

  div,
  span {
    box-sizing: border-box;
  }
}

.bannerSty {
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 348px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;
    border-radius: 10px 0 0 10px;

    .textList {
      > p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      > div {
        flex-wrap: wrap;

        > p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      border-radius: 10px 0 10px 0;
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}

.title_box {
  padding-top: 40px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    padding-top: 20px;
  }

  .title_name {
    font-size: 19px;
    line-height: 19px;
    font-weight: bold;
    color: rgba(55, 54, 55, 1);
  }

  .title_btn {
    padding-left: 10px;
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
    color: #7f889c;
    cursor: pointer;
  }
}

.recommend_box {
  display: flex;
  flex-wrap: wrap;
  min-height: 340px;

  .recommend_item {
    width: 453px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 44px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    min-height: 340px;

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
    }

    .title_logo {
      display: flex;
      align-items: center;

      .title_img_box {
        width: 150px;
        height: 84px;
        margin-right: 16px;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
        }
      }

      .title_right_name {
        flex: 1;

        .title_name_box {
          text-align: left;
          font-size: 16px;
          overflow: hidden;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }
      }

      .title_name_tag {
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: 14px;
        line-height: 14px;
        color: rgba(102, 102, 102, 1);

        &.active {
          margin-top: 10px;
          color: #fff;
        }

        .title_name_span {
          margin-right: 30px;
        }
      }

      .title_name_list {
        display: flex;
        margin-top: 20px;

        &.pdActive {
          margin-top: 12px;
        }

        .title_name_item {
          margin-left: 8px;
          height: 24px;
          background-color: #00a1cd;
          border-radius: 4px;
          font-size: 14px;
          line-height: 24px;
          color: rgba(255, 255, 255, 1);
          padding: 0 8px;
          text-align: left;

          &.active_1 {
            color: #0493ff;
            background-color: #e5f4ff;
          }

          &.active_2 {
            color: #51b875;
            background-color: #edf7f1;
          }
        }

        .title_name_item:nth-child(1) {
          margin-left: 0px;
        }
      }
    }

    .course_div {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 0 !important;
      border-radius: 15px;
      padding-left: 8px;
      box-sizing: content-box;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .course_list {
      margin-top: 10px;

      .course_item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;

        .course_img_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;

          > img {
            width: 20px;
            height: 20px;
          }
        }

        .course_name {
          text-align: left;
          font-size: 14px;
          color: #fff;
          margin-left: 10px;

          &.active {
            margin-left: 0px;
          }
        }
      }
    }

    .tag_box {
      display: flex;
      align-items: center;
      margin-top: 14px;

      .tag_text {
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        color: #fcff00;
      }

      .tag_span {
        margin: 0 24px;
        height: 12px;
        width: 1px;
        background-color: #fff;
      }
    }

    .btn_box {
      width: 160px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(211, 245, 255, 1);
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 24px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(1, 168, 236, 1);
      cursor: pointer;
    }
  }

  .recommend_item.active {
    padding-top: 22px !important;
    margin-bottom: 0 !important;
    min-height: 234px;

    .title_name_box {
      color: #111a38 !important;
    }

    .course_name {
      color: #666666 !important;
    }

    .course_list {
      margin-top: 20px !important;
    }
  }

  .recommend_item:nth-child(1) {
    background: linear-gradient(
      0deg,
      rgba(0, 166, 235, 1) 0%,
      rgba(17, 198, 242, 1) 100%
    );

    .title_name_item {
      background-color: #00a1cd !important;
    }

    .btn_box {
      color: #00a1cd !important;
    }
  }

  .recommend_item:nth-child(2) {
    background: linear-gradient(
      0deg,
      rgba(21, 200, 188, 1) 0%,
      rgba(16, 215, 145, 1) 100%
    );

    .title_name_item {
      background-color: #02b591 !important;
    }

    .btn_box {
      color: #02b591 !important;
    }
  }

  .recommend_item:nth-child(3) {
    background: linear-gradient(
      0deg,
      rgba(255, 112, 104, 1) 0%,
      rgba(255, 154, 95, 1) 100%
    );

    .title_name_item {
      background-color: #ee6452 !important;
    }

    .btn_box {
      color: #ee6452 !important;
    }
  }

  .recommend_item:nth-of-type(3n) {
    margin-right: 0px;
  }
}

.list_box_1 {
  display: flex;
  flex-wrap: wrap;

  /deep/ .PublicTtem_Div:nth-child(1) {
    .list_item {
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .img_div {
        padding: 10px;
        padding-bottom: 4px;

        .img_box {
          border-radius: 8px 0 0 0;
          border: 1px solid #fff;
          overflow: hidden;
        }
      }
    }
  }
}

.list_box_1 {
  /deep/ .PublicTtem_Div:nth-child(1) {
    .list_item {
      background-image: url("~@/assets/imgFIle_3/jiaoyanzu_zhibo_bg.png");
    }
  }
}

.list_box_2 {
  /deep/ .PublicTtem_Div:nth-child(1) {
    .list_item {
      background-image: url("~@/assets/imgFIle_3/jiaoyanzu_huodong_bg.png");
    }
  }
}

.list_box_3 {
  /deep/ .PublicTtem_Div:nth-child(1) {
    .list_item {
      background-image: url("~@/assets/imgFIle_3/jiaoyanzu_kechen_bg.png");
    }
  }
}

.class_main {
  display: flex;
  justify-content: space-between;
}

.keyan_div {
  width: 1045px;

  .keyan_list {
    background-color: #fff;
    width: 100%;
    height: 394px;
    border-radius: 8px;
    padding: 16px 0px;
    overflow: hidden;
  }

  .keyan_item_box {
    width: 50%;
    display: inline-block;
  }

  .keyan_item_div {
    width: 100%;
    height: 72px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    .keyan_left_img_box {
      width: 40px;
      height: 40px;
      margin-right: 15px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .keyan_right_box {
      flex: 1;

      .keyan_title {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: rgba(17, 26, 56, 1);
        text-align: left;

        &:hover {
          color: #1e6fff;
        }
      }

      .keyan_tag_box {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .keyan_tag_text {
          font-size: 14px;
          line-height: 14px;
          font-weight: normal;
          color: #666;
        }

        .keyan_tag_icon {
          margin-left: 40px;
          margin-right: 5px;

          &.active {
            margin-left: 10px;
          }
        }

        .keyan_tag_span {
          width: 1px;
          height: 12px;
          background-color: #dcdfe6;
          margin: 0 14px;
        }

        .keyan_tag_icon,
        .keyan_tag_num {
          font-size: 14px;
          line-height: 14px;
          color: #909399;
        }
      }
    }
  }
}

.educationSort_div {
  width: 335px;

  .educationSort_box {
    width: 100%;
    height: 394px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    background-size: 319px 159px;
    background-repeat: no-repeat;
    background-position: 8px 8px;
    overflow: hidden;
    position: relative;

    &.active {
      padding: 8px;
      background-image: url("~@/assets/imgFIle_3/jiaoyanzu_phb_bg.png");
    }

    &.active_2 {
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .educationSort_list {
      margin-top: 105px;
      width: 100%;
      background-color: #fff;
      border-radius: 8px 8px 0 0;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .educationSort_item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 16px;
      cursor: pointer;

      .educationSort_img_box {
        width: 22px;
        height: 22px;
        margin-right: 16px;
        background-size: 100% 100%;
      }

      .educationSort_img_sort {
        width: 100%;
        height: 100%;
        background-color: #ebe7e7;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #8590a6;
        border-radius: 50%;
      }

      .educationSort_name {
        text-align: left;
        font-size: 16px;
        line-height: 18px;
        color: #333;
        flex: 1;
      }

      .educationSort_num {
        font-size: 16px;
        line-height: 18px;
        color: #1e6fff;
        font-weight: bold;
        margin-left: 10px;
      }

      &:nth-child(1) .educationSort_img_box {
        background-image: url("~@/assets/imgFIle_3/jin.png");
      }

      &:nth-child(2) .educationSort_img_box {
        background-image: url("~@/assets/imgFIle_3/yin.png");
      }

      &:nth-child(3) .educationSort_img_box {
        background-image: url("~@/assets/imgFIle_3/tong.png");
      }
    }

    .educationDontai_div {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
    }

    @keyframes marquee {
      0% {
        top: 0;
      }

      100% {
        top: -220%;
      }
    }

    .educationDontai_list {
      padding-left: 20px;
      padding-right: 20px;
      position: absolute;

      &.active {
        animation: marquee 20s linear infinite;

        &:hover {
          animation-play-state: paused;
        }
      }
    }

    .educationDontai_item {
      margin-bottom: 22px;

      .educationDontai_img_box {
        width: 50px;
        height: 50px;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .educationDontai_mesg {
        padding: 8px 10px;
        background: rgba(244, 245, 247, 1);
        border-radius: 8px;
        position: relative;
        cursor: pointer;

        .tag_box {
          height: 26px;
          background: rgba(253, 246, 236, 1);
          border: 1px solid rgba(250, 236, 216, 1);
          border-radius: 4px;
          font-size: 14px;
          color: rgba(230, 162, 60, 1);
        }

        .tag_name_box {
          font-size: 16px;
          color: rgba(78, 110, 169, 1);
        }

        .shanjiaoxin {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid rgba(244, 245, 247, 1);
          position: absolute;
          top: -8px;
          left: 15px;
        }
      }
    }
  }
}
</style>