<template>
  <div class="public_main_bgc">
    <div class="bannerSty" style="height: 368px;">
      <img style="width: 100%;height: 100%;" src="@/assets/banner_img/aserviceImg/banner.png" alt="" />
    </div>
    <div class="bgWidth2">
      <div class="public-width">
        <div class="public-width">
          <div class="mainBox4 pt20">
            <div class="titleImg">
              <img src="@/assets/banner_img/aservice4/title.png" alt="">
            </div>
            <div class="peixunBox1">
              <h4>一、名家四名讲座：</h4>
              <p class="mt20 mb20">邀请教学成果奖获得者或教学成果奖评委，聚焦名教师、名校（园）长、名班主任、名书记成长的瓶颈问题，
                开展四名名家讲座，引领教师成名成家，服务内容如下：
              </p>
              <div class="mingjia">
                <div>教学成果奖培育、提炼与申报</div>
                <div></div>
                <div></div>
                <div>课堂教学实践与创新</div>
                <div>名家四名成长之路</div>
                <div></div>
              </div>

              <h4 class="mt50 mb20">二、教育科研能力提升工程：</h4>
              <div class="dianDiv mb15">
                <div></div>认证做法
              </div>

              <div class="renzBox">
                <div class="renTop">
                  <div class="remLeft">
                    认证模式
                    <span class="ml20">( 可在教育主管部门组织下开展，亦可由教师自由选择认证 )</span>
                  </div>
                  <div class="remRight"></div>
                </div>
                <div class="renBom">
                  <div>
                    <p>能力选学</p>
                    <img src="@/assets/banner_img/aservice4/right1.png" alt="">
                    <p>培训学习</p>
                    <img src="@/assets/banner_img/aservice4/right1.png" alt="">
                    <p>研究实践</p>
                    <img src="@/assets/banner_img/aservice4/right1.png" alt="">
                    <p>材料提交</p>
                    <img src="@/assets/banner_img/aservice4/right1.png" alt="">
                    <p>评价认证</p>
                  </div>
                </div>
              </div>

              <div class="renzBox mt20">
                <div class="renTop renTop2">
                  <div class="remLeft">
                    培训模式
                    <span class="ml20">( 以教师学时认证为目标开展 )</span>
                  </div>
                  <div class="remRight"></div>
                </div>
                <div class="renBom renBom2">
                  <div class="renBom21">
                    <p class="bigP">常规模式：</p>
                    <p>前测诊断</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>自主选学</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>培训学习</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>考核评价</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>学时认证</p>
                  </div>
                  <div class="renBom22">
                    <p class="bigP">融合“能力认证”：</p>
                    <p>前测诊断</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>自主选学</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>培训学习</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>研究实践</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>材料提交</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>评价认证</p>
                    <img src="@/assets/banner_img/aservice4/right2.png" alt="">
                    <p>学时认证</p>
                  </div>
                </div>
              </div>

              <div class="dianDiv mt20 mb15">
                <div></div>能力体系
              </div>

              <div class="txtBox">
                <div>
                  <div>
                    <img src="@/assets/banner_img/aservice4/icon05.png" alt="">
                  </div>
                  <div>
                    <div class="secor1">活动目的</div>
                    <div>发展高质量教育，培养高素质人才。</div>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="@/assets/banner_img/aservice4/icon06.png" alt="">
                  </div>
                  <div>
                    <div class="secor2">活动主题</div>
                    <div>教学改革、课程改革、教育治理、学生发展、教师发展等。</div>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="@/assets/banner_img/aservice4/icon04.png" alt="">
                  </div>
                  <div>
                    <div class="secor1">宣传方式</div>
                    <div>一个渠道投稿，八个渠道传播。通过媒体报道、录播直播、视频回放等形式，带动区域教师参与研讨，共享成果。</div>
                  </div>
                </div>
              </div>

              <div class="dianDiv mt20 mb15">
                <div></div>培训内容
              </div>

              <div class="neirong">
                <div>
                  <h2>全员班</h2>
                  <p class="bigs">定位：</p>
                  <p>侧重教师的科研知识积累</p>
                  <p class="bigs">目标：</p>
                  <p>旨在帮助学员掌握课题研究的基本流程、方法技巧</p>
                </div>
                <div>
                  <h2>骨干班</h2>
                  <p class="bigs">定位：</p>
                  <p>侧重教师的科研能力内化</p>
                  <p class="bigs">目标：</p>
                  <p>旨在帮助学员“会做”小课题研究，解决教学问题，提升教育科研能力</p>
                  <p></p>
                </div>
                <div>
                  <h2>高研班</h2>
                  <p class="bigs">定位：</p>
                  <p>侧重教师的科研成果升华</p>
                  <p class="bigs">目标：</p>
                  <p>旨在帮助学员“做好”课题研究，学会凝练教学思想，形成特色教学风格</p>
                  <p></p>
                </div>
              </div>

              <div class="dianDiv mt20 mb15">
                <div></div>特色亮点
              </div>

              <div class="lingdian">
                <div>
                  <p>精品课程资源</p>
                  <p>基于教育科研能力体系、“大教科研”理念</p>
                </div>
                <div>
                  <p>贴近教师需求</p>
                  <p>结合“五课”教育实践场景进行案例式讲解</p>
                </div>
                <div>
                  <p>分层分类培训</p>
                  <p>基于不同成长阶段教师需求，直击发展痛点</p>
                </div>
                <div>
                  <p>线上线下融合</p>
                  <p>结合内容特点，采取丰富而适合的培训方式</p>
                </div>
              </div>

              <h4 class="mb20">三、数据素养能力提升培训：</h4>
              <p class="mb30">聚焦“经验式教学”“模糊教学”的问题，通过培训，帮助教师从传统经验型教学转变为基于数据的精准教学，
                全面提升教师的数据素养，推动教育教学改革的落地。
              </p>

              <div class="shuju">
                <h2>提升数据素养水平，推动教育教学从“经验主义”走向“数据主义”</h2>
                <div class="shujuUl mt40">
                  <div>
                    <h4>教育大数据基础认知</h4>
                    <p>教育大数据的战略与内涵</p>
                    <p>教育大数据采集</p>
                    <p>教育大数据分析</p>
                    <p>走向数据驱动的教学</p>
                    <div class="bomBox">
                      理解教育大数据的内涵价值与应用模式
                    </div>
                    <div class="aboutBox">
                      <img src="@/assets/banner_img/aservice4/01.png" alt="">
                    </div>
                  </div>
                  <div>
                    <h4>教师数据素养内涵动态与框架</h4>
                    <p>数据素养与信息素养的关系</p>
                    <p>教师数据素养的内涵与价值</p>
                    <p>教师数据素养发展国际动态</p>
                    <p>中小学教师数据素养发展现状</p>
                    <p>中小学教师数据素养框架</p>
                    <div class="bomBox">
                      理解教育大数据的内涵，把握国际教师数据素养发展动态
                    </div>
                    <div class="aboutBox">
                      <img src="@/assets/banner_img/aservice4/02.png" alt="">
                    </div>
                  </div>
                  <div>
                    <h4>教育数据的处理与分析</h4>
                    <p>常用数据分析指标</p>
                    <p>Excel数据分析</p>
                    <p>SPSS统计分析</p>
                    <p>教育数据挖掘工具</p>
                    <div class="bomBox">
                      掌握常用数据分析指标及教育数据处理、分析
                    </div>
                    <div class="aboutBox">
                      <img src="@/assets/banner_img/aservice4/03.png" alt="">
                    </div>
                  </div>
                  <!-- 二行 -->
                  <div>
                    <h4>教育数据可视化</h4>
                    <p>教育数据可视化简介</p>
                    <p>教育数据图表类型及应用</p>
                    <p>教育数据可视化工具</p>
                    <div class="bomBox">
                      掌握数据可视化展现
                    </div>
                    <div class="aboutBox">
                      <img src="@/assets/banner_img/aservice4/04.png" alt="">
                    </div>
                  </div>
                  <div>
                    <h4>教育数据意识<br>伦理与思维</h4>
                    <p>教育数据意识</p>
                    <p>教育数据伦理</p>
                    <p>教育数据思维</p>
                    <div class="bomBox">
                      形成良好的教育数据意识与教育数据思维，遵守数据伦理规范
                    </div>
                    <div class="aboutBox">
                      <img src="@/assets/banner_img/aservice4/05.png" alt="">
                    </div>
                  </div>
                  <div>
                    <h4>教师数据素养<br>提升案例实训</h4>
                    <p>平板电脑班级学生视力数据跟踪分析</p>
                    <p>学生学业质量（考试成绩）数据分析</p>
                    <p>网络学习空间应用数据分析</p>
                    <p>学生综合评价数据分析</p>
                    <div class="bomBox">
                      掌握真实教育教学情境中的数据，综合处理、分析与解读能力
                    </div>
                    <div class="aboutBox">
                      <img src="@/assets/banner_img/aservice4/06.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="shujuUl2">
                  <div>
                    <div class="imgBox">
                      <img src="@/assets/banner_img/aservice4/icon01.png" alt="">
                    </div>
                    <div class="txt2Box">
                      <p class="mb10">国内教育大数据领域权威专家、</p>
                      <p>一线实践者授课</p>
                    </div>
                  </div>
                  <div>
                    <div class="imgBox">
                      <img src="@/assets/banner_img/aservice4/icon02.png" alt="">
                    </div>
                    <div class="txt2Box">
                      <p class="mb10">配套教材</p>
                      <p>论文资料</p>
                    </div>
                  </div>
                  <div>
                    <div class="imgBox">
                      <img src="@/assets/banner_img/aservice4/icon03.png" alt="">
                    </div>
                    <div class="txt2Box">
                      <p class="mb10">每个章节配套</p>
                      <p>作业与考试</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.reds {
  color: #ea1e70;
}

.bgWidth1 {
  background: #ebf5fc;
}

.bgWidth2 {
  background: #fff;
}

.bgWidth3 {
  height: 720px;
  background: url('~@/assets/banner_img/aserviceImg/bg3.png') no-repeat;
  background-size: 100% 100%;
}

.bgWidth4 {
  height: 680px;
  background: url('~@/assets/banner_img/aserviceImg/jidi.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
}


.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}


.mainBox4 {
  .titleImg {
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      height: 76px;
    }
  }

  .peixunBox1 {
    h4 {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .mingjia {
      display: flex;
      flex-wrap: wrap;

      >div {
        width: 640px;
        height: 100px;
        background-size: 100% 100%;

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5) {
          background: #145bb9;
          font-size: 24px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          line-height: 100px;
        }

        &:nth-child(2) {
          background: url('~@/assets/banner_img/aservice4/pic01.png') no-repeat;
        }

        &:nth-child(3) {
          background: url('~@/assets/banner_img/aservice4/pic02.png') no-repeat;
        }

        &:nth-child(4) {
          background: #ea1e70;
        }

        &:nth-child(6) {
          background: url('~@/assets/banner_img/aservice4/pic03.png') no-repeat;
        }
      }
    }

    .dianDiv {
      display: flex;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(20, 91, 185, 1);
      align-items: center;

      >div {
        width: 6px;
        height: 6px;
        background: #145bb9;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    .renzBox {
      .renTop {
        width: 783px;
        height: 64px;
        display: flex;
        background: rgba(20, 91, 185, 1);
        justify-content: space-between;

        .remLeft {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 64px;
          padding-left: 27px;

          span {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: rgba(255, 255, 255, 1);
          }
        }

        .remRight {
          width: 0;
          height: 0;
          border-left: 0px solid transparent;
          border-right: 45px solid transparent;
          border-bottom: 64px solid #145bb9;
          margin-right: -45px;
        }
      }

      .renTop2 {
        width: 497px;
        background: #ea1e70;

        .remRight {
          border-bottom: 64px solid #ea1e70;
        }
      }

      .renBom {
        width: 100%;
        padding: 0 27px;
        box-sizing: border-box;
        background: rgba(235, 241, 255, 1);
        border: 1px solid rgba(20, 91, 185, 1);

        >div {
          display: flex;
          align-items: center;
          height: 88px;

          p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            color: rgba(51, 51, 51, 1);
          }

          img {
            margin-left: 50px;
            margin-right: 50px;
          }
        }
      }

      .renBom2 {
        border: 1px solid #EA1E70;
        background: #fde8f0;

        .renBom21 {
          border-bottom: 1px solid #F0BED1;

          .bigP {
            font-weight: bold;
            color: #EA1E70;
            margin-right: 10px;
          }
        }

        .renBom22 {
          .bigP {
            font-weight: bold;
            color: #EA1E70;
            margin-right: 10px;
          }

          img {
            margin-left: 20px;
            margin-right: 20px;
          }
        }
      }
    }

    .txtBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      >div {
        display: flex;
        width: 49%;
        height: 112px;
        margin-bottom: 20px;
        box-sizing: border-box;

        img {
          width: 50%;
        }

        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }

        &:nth-child(2) {
          >div {
            &:first-child {
              background: #ea1e70;
            }
          }
        }

        >div {
          &:first-child {
            width: 112px;
            background: #145bb9;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:last-child {
            background: #EBF1FF;
            padding: 24px;
            width: calc(100% - 164px);

            >div {
              font-size: 18px;
              font-family: Microsoft YaHei;
              color: rgba(51, 51, 51, 1);

              &:first-child {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: rgba(51, 51, 51, 1);
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .neirong {
      display: flex;
      justify-content: space-between;

      >div {
        width: 405px;
        height: 324px;
        background-size: 100% 100%;
        padding: 62px 32px;
        box-sizing: border-box;

        h2 {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 8px;
        }

        .bigs {
          font-weight: bold;
        }

        &:nth-child(1) {
          background: url('~@/assets/banner_img/aservice4/bg1.png') no-repeat;
        }

        &:nth-child(2) {
          background: url('~@/assets/banner_img/aservice4/bg2.png') no-repeat;
        }

        &:nth-child(3) {
          background: url('~@/assets/banner_img/aservice4/bg3.png') no-repeat;
        }
      }
    }

    .lingdian {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      >div {
        width: 624px;
        height: 112px;
        background: rgba(20, 91, 185, 1);
        margin-bottom: 32px;
        padding: 23px;
        box-sizing: border-box;

        p {
          font-size: 20px;
          font-family: Microsoft YaHei;
          color: rgba(255, 255, 255, 1);

          &:first-child {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }

        &:nth-child(4) {
          background: rgba(234, 30, 112, 1);
        }
      }
    }

    .shuju {
      width: 100%;
      height: 1162px;
      background-size: 100% 100%;
      background: url('~@/assets/banner_img/aservice4/bg4.png') no-repeat;
      padding-top: 60px;

      h2 {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.28);
        text-align: center;
      }

      .shujuUl {
        padding: 0 64px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >div {
          width: 384px;
          height: 420px;
          background: rgba(20, 91, 185, 1);
          position: relative;
          padding: 40px;
          box-sizing: border-box;

          &:nth-child(2n) {
            background: rgba(234, 30, 112, 1);

            .bomBox {
              background: rgba(240, 64, 135, 1);
            }
          }

          h4 {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            margin-bottom: 30px;
          }

          p {
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            margin-bottom: 10px;
          }

          .bomBox {
            position: absolute;
            width: 376px;
            height: 80px;
            background: rgba(37, 109, 204, 1);
            bottom: 4px;
            left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 18px;
            font-family: Microsoft YaHei;
            text-align: center;
            padding: 0 20px;
            box-sizing: border-box;
          }

          .aboutBox {
            position: absolute;
            right: 0;
            bottom: 80px;
          }
        }
      }

      .shujuUl2 {
        display: flex;
        justify-content: space-between;
        padding: 0 64px;
        box-sizing: border-box;

        >div {
          width: 384px;
          height: 100px;
          background: rgba(86, 93, 103, 1);
          display: flex;
          align-items: center;

          &:nth-child(2) {
            background: rgba(95, 104, 115, 1);
          }

          .imgBox {
            margin-left: 24px;
            img {
              width: 72px;
              height: 72px;
            }
          }

          .txt2Box {
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgba(255, 255, 255, 1);
            margin-left: 17px;
          }
        }
      }
    }
  }
}
</style>