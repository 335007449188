<template>
  <el-dialog
    title="问卷信息"
    width="1200px"
    top="30px"
    :close-on-click-modal="false"
    :before-close="dialogHandleClose"
    :visible="dialogShow"
  >
    <el-form
      ref="problemInfo"
      :rules="problemInfoRules"
      :model="problemInfo"
      label-width="100px"
      class="problemInfo-form"
    >
      <el-form-item label="问卷标题：" prop="title">
        <el-input
          placeholder="请输入问卷标题"
          v-model="problemInfo.title"
        ></el-input>
      </el-form-item>
      <el-form-item label="问卷说明：" prop="description">
        <Editor v-model="problemInfo.description"></Editor>
      </el-form-item>
    </el-form>
    <div class="bottom-box">
      <el-button @click="cancelButton()">取消</el-button>
      <el-button type="primary" @click="defineButton('problemInfo')">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import EditInfo from "./editInfo.js";
export default EditInfo;
</script>

<style lang="less" scoped>
@import "./editInfo.less";
</style>