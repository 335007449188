<template>
  <!-- //四名工作室简介 -->
  <div class="introduction">
    <div class="w_layout ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>工作室成员</span>
        </p>
      </div>
    </div>
    <!-- //底部灰色部分 -->
    <div class="hsBox">
      <div class="w_layout Contents">
        <!-- 工作室成员 -->
        <div class="workTearm" id="work_tearm_box_id">
          <div class="topName">
            <p class="mb20">工作室成员</p>
            <span v-for="(item, index) in selectList" :key="index" :class="active == index ? 'yes' : 'no'"
              @click="changColor(index, item.id)">
              {{ item.name }}（{{ item.num }}）
            </span>
          </div>
          <!-- 表格 -->
          <div class="tableList">
            <el-table :data="tearmList" class="abc" :header-cell-style="{ background: '#61A4FF', color: '#fff' }"
              :row-class-name="tableRowClassName">
              <el-table-column prop="name" label="排行" align="center" width="100">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="title" label="姓名" align="center">
                <template slot-scope="scope">
                  <div class="df titimg">
                    <img :src="scope.row.photo_url" alt="">
                    <p>{{ scope.row.realname }}</p>
                    <span v-if="scope.row.is_role_admin == 1">管理员</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="sex_str" label="性别" align="center" width="130" />
              <el-table-column prop="role_name" label="角色" align="center" width="150" />
              <el-table-column prop="stage_name" label="学段" align="center" width="130" />
              <el-table-column prop="subject_name" label="学科" align="center" width="130" />
              <el-table-column prop="organization_name" label="所属单位" sortable align="center" width="220" />
              <el-table-column prop="contribute_count" label="贡献值" align="center" width="150" />
            </el-table>
          </div>
          <div class="block">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="form.page" :page-size="form.limit" layout="prev,pager,next,jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //成员人数
      peopleDetail: "",

      form1: {
        search_role_studio_id: "", // 空为全部 1为工作室管理员 2为骨干成员 3为网络学员
        limit: 10,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
      },

      //详情
      detail: "",
      total: 0,
      form: {
        limit: 16,
        page: 1,
        search_role_studio_id: "", //空为全部 1为工作室管理员 2为骨干成员 3为网络学员
        search_role_studio_child_id: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      active: 0,
      selectList: [],
      //成员列表
      tearmList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    },
    pageRoll() {
      if (this.$route.query && this.$route.query.domClass) {
        this.$nextTick(() => {
          let dom = document.getElementById('work_tearm_box_id');
          dom.scrollIntoView({
            behavior: 'smooth'
          });
        })
      };
    },
    //初始化
    init() {
      this.getDetailInfo();
      //获取工作室人数
      this.getPeople();
      this.getWorkTearm(); //获取成员
      this.pageRoll();
    },
    //工作室详情
    getDetailInfo() {
      this.$axios
        .get("index/TeachingStudio/TeachingStudioInfo", {
          params: {
            id: localStorage.getItem("studioId"),
            user_id: localStorage.getItem("id"),
          },
        })
        .then((res) => {
          this.detail = res.data.data;
        });
    },
    //获取工作室人数
    async getPeople() {
      let res = await this.$axios.get(
        "index/teachingStudioUser/listTeachingStudioUserRoleStudioCount",
        {
          params: this.form1,
        }
      );
      this.peopleDetail = res.data.data;
      this.selectList.push({ id: '', name: this.peopleDetail.role_name_0, num: this.peopleDetail.role_studio_0 })
      if (this.peopleDetail.role_studio_2) {
        this.selectList.push({ id: 12, name: this.peopleDetail.role_name_2, num: this.peopleDetail.role_studio_2 })
      }
      if (this.peopleDetail.role_studio_3) {
        this.selectList.push({ id: 13, name: this.peopleDetail.role_name_3, num: this.peopleDetail.role_studio_3 })
      }
      if (this.peopleDetail.role_studio_4) {
        this.selectList.push({ id: 5, name: this.peopleDetail.role_name_4, num: this.peopleDetail.role_studio_4 })
      }
      if (this.peopleDetail.role_studio_5) {
        this.selectList.push({ id: 1, name: this.peopleDetail.role_name_5, num: this.peopleDetail.role_studio_5 })
      }
      if (this.peopleDetail.role_studio_6) {
        this.selectList.push({ id: 2, name: this.peopleDetail.role_name_6, num: this.peopleDetail.role_studio_6 })
      }
      if (this.peopleDetail.role_studio_7) {
        this.selectList.push({ id: 3, name: this.peopleDetail.role_name_7, num: this.peopleDetail.role_studio_7 })
      }
      if (this.peopleDetail.role_studio_8) {
        this.selectList.push({ id: 4, name: this.peopleDetail.role_name_8, num: this.peopleDetail.role_studio_8 })
      }
    },
    //获取工作室成员
    getWorkTearm() {
      this.$axios
        .get("index/teachingStudioUser/listTeachingStudioUserRoleStudio", {
          params: this.form,
        })
        .then((res) => {
          this.tearmList = res.data.data.data;
          this.total = res.data.data.total;
        });
    },
    changColor(index, id) {
      this.active = index;
      if (id == "") {
        this.form.search_role_studio_id = "";
        this.form.search_role_studio_child_id = "";
      } else if (id > 10) {
        this.form.search_role_studio_id = id % 10;
        this.form.search_role_studio_child_id = "";
      } else if (index < 10) {
        this.form.search_role_studio_id = "";
        this.form.search_role_studio_child_id = id;
      }
      this.getWorkTearm();
    },
    // 每页几条的方法
    handleSizeChange(val) {
      //赋值给表单的每页多少条数值
      this.form.limit = val;
      //重新获取数据
      this.getWorkTearm();
    },
    //   跳转第几页的方法
    handleCurrentChange(val) {
      //赋值给表单当前页是第几页的数值
      this.form.page = val;
      //重新获取数据
      this.getWorkTearm();
    },
  },
};
</script>

<style lang="less" scoped>
.introduction {
  .ArrBox {

    //标题
    .title {
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      p {
        span {
          color: #ff8201;
        }
      }
    }
  }

  //底部灰色部分
  .hsBox {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 40px;
    box-sizing: border-box;

    .Contents {
      box-sizing: border-box;

      //工作室成员
      .workTearm {
        padding: 34px;
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        box-sizing: border-box;

        .topName {
          margin-bottom: 30px;

          p {
            font-size: 18px;
            font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
            margin-right: 39px;
            border: none;
          }

          span {
            cursor: pointer;
            font-size: 16px;
            margin-right: 20px;
          }

          .no {
            color: #666;
          }

          .yes {
            color: #3489FF;
            font-weight: bold;
          }


        }
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

/deep/.el-table {
  border-radius: 8px;
  border: 1px solid #eee;

  .cell {
    font-size: 16px;
  }

  .warning-row {
    background: #f5f7fa;
  }

  .success-row {
    background: #fff;
  }
}

.abc ::v-deep .el-table__body tr:hover>td {
  background-color: #fef5ec !important;
}

.titimg {
  align-items: center;
  padding-left: 50px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-right: 10px;
  }

  p {
    font-size: 16px;
    margin-right: 10px;
  }

  span {
    background: rgba(255, 128, 41, 1);
    border-radius: 4px;
    padding: 1px 5px;
    color: #fff;
  }
}
</style>