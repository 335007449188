<template>
  <div class="ArticleList">
    <div class="w_layout ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>教师文章</span>
        </p>
      </div>
      <div class="bottomBox">
        <div class="Art_left">
          <h4 class="ml10">文章分类</h4>
          <ul v-if="typeList.length > 0">
            <li v-for="(item, index) in typeList" :key="index" @click="setType(item.id)" class="ellipsis-1"
              :class="{ 'active': item.id == form.type }">
              <template v-if="index <= 5">
                <img v-if="item.id == form.type" :src="require('@/assets/images/index_img/iconBox/icon' + index + '_active.png')" alt="Local Image">
                <img v-else :src="require('@/assets/images/index_img/iconBox/icon' + index + '.png')" alt="Local Image">
              </template>
              <img v-else :src="require('@/assets/images/index_img/iconBox/icon7.png')" alt="Local Image">
              {{ item.name }}
            </li>
          </ul>
          <CommonEmpty v-else />
        </div>
        <div class="Art_right">
          <div class="df dfb mb10">
            <p class="topT">文章列表</p>
            <template v-if="detailData.role != 0">
              <div class="btnBox" @click="pushText()" v-if="workData.is_addUser == 1 || workData.role">
                新增文章
              </div>
            </template>
          </div>
          <div v-if="total > 0">
            <div class="demoBox">
              <!-- //循环 -->
              <div v-for="(item, index) in articleList" :key="index" class="fc forBox">
                <div class="imgBox cp" @click="toArticleDetail(item.id)"
                  :class="item.type == 1 ? 'imgbg1' : item.type == 2 ? 'imgbg2' : item.type == 3 ? 'imgbg3' : item.type == 4 ? 'imgbg4' : ''">
                  <p>{{ item.type_name }}
                  </p>
                  <div class="about" :class="item.status == 4 ? 'aboutFas' : ''"
                    v-if="item.status == 1 || item.status == 4">
                    {{ item.status == 1 ? '待审核' : '未通过' }}
                  </div>
                </div>
                <div class="txtBox cp" @click="toArticleDetail(item.id)">
                  <h4 class="ellipsis-1 mb20">
                    <span v-if="item.status == 3">
                      四名推荐
                    </span>
                    {{ item.name }}
                  </h4>
                  <!-- <p class="towp ellipsis-2 mt10 mb10">{{ item.abstract }}</p> -->
                  <!-- <div class="divtwo">
                    <p>{{ item.type_name }} / {{ item.nature_name }}</p>
                  </div> -->
                  <div class="iconBox mt10">
                    <p>
                      发布者：{{ item.username }}</p>
                    <p>
                      <img src="@/assets/images/index_img/iconBox/icon8.png" alt="">
                      发布时间：{{ item.start_date }}
                    </p>
                    <p>
                      <img src="@/assets/images/index_img/iconBox/icon9.png" alt="">
                      浏览量：{{ item.view_count }}
                    </p>
                    <p>
                      <img src="@/assets/images/index_img/iconBox/icon10.png" alt="">
                      评论：{{ item.reply_count }}
                    </p>
                  </div>
                </div>
                <div class="btnBoxs" v-if="item.user_id == user_id">
                  <div class="cursor mb15" @click="pushText(item.id)">编辑 ></div>
                  <div class="cursor" @click="delList(item.id)">删除 ></div>
                </div>
              </div>
            </div>

            <div v-show="total" class="pagina_div" style="height:100px">
              <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>

          </div>
          <CommonEmpty v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["workData"],
  data() {
    return {
      total: 0,
      form: {
        limit: 12,
        page: 1,
        teaching_studio_id: "",
        type: "",
      },
      articleList: [],
      typeList: [],
      detailData: {
        role: "",
        TeachingStudioinfo: {},
      },
      user_id: localStorage.getItem("id") || 0,
      iconList: [
        "icon1",
        "",
      ],
    };
  },
  methods: {
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    //跳转文章详情
    toArticleDetail(id) {
      let routeData = this.$router.resolve({
        path: "/ArticleDetail",
        query: {
          tid: id,
        },
      });
      window.open(routeData.href, '_blank');
    },
    //发布文章
    pushText(id) {
      if (id) {
        this.$router.push("/AddArticle?id=" + id);
        return
      }
      this.$router.push("/AddArticle");
    },
    setType(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "type", val);
      this.getArtiCleList();
    },
    //文章
    getArtiCleList() {
      this.$axios.get("TeachingStudioArticleType/IndexlistTeachingStudioArtic", { params: this.form, }).then((res) => {
        this.articleList = res.data.data.data;
        this.total = res.data.data.total;
      });
    },
    //删除
    delList(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.delApi,
        id,
      });
    },
    //删除接口
    delApi(id) {
      let params = {
        ids: [id],
        teaching_studio_id: localStorage.getItem('studioId')
      };
      this.$axios.delete("TeachingStudioArticle/del", {
        params
      }).then(() => {
        this.$message.success("删除成功");
        this.handleCurrentChange(1);
      });
    },
    //获取文章分类
    getArticleType() {
      let obj = {
        search_name: "",
        page: 1,
        limit: "",
        teaching_studio_id: this.form.teaching_studio_id,
      };
      this.$axios.get("TeachingStudioArticleType/get_ArticleType_list", { params: obj, }).then((res) => {
        let tempItem = {
          "id": "",
          "name": "全部",
          "title_status": "",
          "title_type": "",
          "top": 2
        };
        res.data.data.unshift(tempItem);
        this.typeList = res.data.data;
      });
    },
    // 每页几条的方法
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.getArtiCleList();
    },
    //   跳转第几页的方法
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.getArtiCleList();
    },
  },
  created() {
    this.form.teaching_studio_id = this.$route.query.studioId
      ? this.$route.query.studioId
      : localStorage.getItem("studioId");
    this.getArtiCleList();
    this.getArticleType();
    this.getAdminData();
  },
};
</script>

<style lang="less" scoped>
.ArticleList {
  .ArrBox {
    padding-bottom: 60px;

    .title {
      height: 48px;
      font-size: 14px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    .bottomBox {
      display: flex;
      align-items: flex-start;

      .Art_left {
        width: 274px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 20px;
        padding: 9px 0px;
        box-sizing: border-box;

        h4 {
          font-size: 18px;
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-weight: 700;
          color: #333333;
          box-sizing: border-box;
          padding-left: 20px;
        }

        ul {
          box-sizing: border-box;
          width: 234px;
          margin-left: 20px;
          cursor: pointer;
          height: 100%;

          &::-webkit-scrollbar {
            width: 5px;
            height: 1px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: skyblue;
            background-image: -webkit-linear-gradient(45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent);
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #ededed;
            border-radius: 10px;
          }

          li {
            border-radius: 4px;
            position: relative;
            width: 100%;
            height: 48px;
            line-height: 48px;
            padding-left: 10px;
            box-sizing: border-box;
            background: rgba(238, 246, 255, 1);
            margin-bottom: 4px;

            img {
              margin-bottom: -4px;
            }

            &:hover {
              color: #fff;
              background: #3489FF;
            }

            &.active {
              color: #fff;
              background: #3489FF;
            }
          }
        }
      }

      .Art_right {
        flex: 1;
        background: #ffffff;
        border-radius: 4px;
        padding: 20px 16px;
        box-sizing: border-box;

        .topT {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 10px;
          padding-left: 10px;
        }

        .demoBox {
          .forBox {
            padding: 10px;
            border-bottom: 1px dashed #ccc;

            &:hover {
              background-color: #eef6ff;
            }

            .imgBox {
              width: 200px;
              height: 100px;
              position: relative;
              background: url('~@/assets/images/index_img/wenzhang1.png') no-repeat;
              background-size: 100% 100%;
              border-radius: 8px;

              p {
                color: #fff;
                font-weight: bold;
                font-size: 22px;
                text-align: left;
                text-indent: 1em;
                line-height: 100px;

                &::before {
                  content: "";
                  left: 22px;
                  top: 70px;
                  position: absolute;
                  width: 26px;
                  height: 3px;
                  background: #fff;
                }
              }

              &.imgbg1 {
                background: url('~@/assets/images/index_img/wenzhang2.png') no-repeat;
              }

              &.imgbg2 {
                background: url('~@/assets/images/index_img/wenzhang3.png') no-repeat;
              }

              &.imgbg3 {
                background: url('~@/assets/images/index_img/wenzhang4.png') no-repeat;
              }

              &.imgbg4 {
                background: url('~@/assets/images/index_img/wenzhang5.png') no-repeat;
              }

              .about {
                position: absolute;
                top: 0;
                right: 0;
                background: #ff8201;
                color: #fff;
                font-size: 14px;
                padding: 2px 5px;
                border-radius: 0 5px 0 5px;

                &.aboutFas {
                  background: #ff0607;
                }
              }
            }

            .txtBox {
              width: calc(100% - 350px);
              margin-left: 24px;
              margin-right: 24px;

              h4 {
                span {
                  background: #ff8201;
                  color: #fff;
                  padding: 0 5px 0 8px;
                  font-size: 14px;
                  box-sizing: border-box;
                  border-radius: 6px;
                  margin-right: 10px;
                }
              }

              p {
                color: #666;
                font-size: 14px;
              }

              .towp {
                height: 38px;
              }

              .divtwo {
                display: flex;

                p {
                  background: rgba(229, 244, 255, 1);
                  border: 1px solid rgba(4, 147, 255, 1);
                  border-radius: 4px;
                  font-weight: normal;
                  color: rgba(4, 147, 255, 1);
                  padding: 2px 6px;
                }
              }

              .iconBox {
                display: flex;

                img {
                  margin-bottom: -4px;
                }

                p {
                  margin-right: 20px;
                }
              }
            }

            .btnBoxs {
              width: 100px;

              >div {
                width: 92px;
                height: 32px;
                background: #fef0f0;
                border: 1px solid #f78383;
                border-radius: 4px;
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f78383;
                text-align: center;
                line-height: 32px;

                &:first-child {
                  border: 1px solid #67c23a;
                  color: #67c23a;
                  background: #f0f9eb;
                }

                &:hover {
                  background: #f78383;
                  color: #fff;

                  &:first-child {
                    background: #67c23a;
                    color: #fff;
                  }
                }
              }
            }

          }
        }

        .block {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    }
  }
}

.btnBox {
  width: 80px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  background: #3489fe;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
}
</style>