import Vue from "vue";
let request = Vue.prototype.$axios

// 活动申报2023年12月7日-------------------------
// 首页申报列表
export function SalonReg(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonReg/index_one',
        method: 'get',
        params,
    })
};
// 首页申报详情
export function SalonRegshow(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonReg/show',
        method: 'get',
        params,
    })
};
// 申报
export function submission(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonRegUser/submission',
        method: 'put',
        params,
    })
};
// 取消申报
export function unsubmission(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonRegUser/unsubmission',
        method: 'put',
        params,
    })
};
// 提交申报表（上传申报文件）
export function set_form(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonRegUser/set_form',
        method: 'put',
        params,
    })
};
// 删除申报表（删除申报文件）
export function delete_form(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonRegUser/delete_form',
        method: 'delete',
        params,
    })
};
// 提交主题分享材料
export function push_material(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonRegUser/push_material',
        method: 'put',
        params,
    })
};
// 删除主题分享材料
export function delete_material(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioSalonRegUser/delete_material',
        method: 'delete',
        params,
    })
};
// 活动申报2023年12月7日-------------------------
// 四名活动列表
export function activityList(params) {
    return request({
        url: 'index/famousTeacher/activityReportTypeList',
        method: 'get',
        params,
    })
};

// 四名活动 详情
export function activityDetail(params) {
    return request({
        url: 'index/famousTeacher/activityReportDetail',
        method: 'get',
        params,
    })
};

// 点赞
export function postActiviinsertLike(params) {
    return request({
        url: 'index/famousTeacher/commentActivityReport/insertLike',
        method: 'post',
        data: params,
    })
};
// 收藏
export function putActivicollect(params) {
    return request({
        url: 'index/famousTeacher/commentActivityReport/collect',
        method: 'put',
        data: params,
    })
};

export function getactivityQr(params) {
    return request({
        url: 'index/famousTeacher/activityQr',
        method: 'get',
        params,
    })
};

// 专题列表
export function TopicList(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopic/lists',
        method: 'get',
        params,
    })
};

// 专题 详情
export function TeachingStudioTopicDetail(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopic/show',
        method: 'get',
        params,
    })
};

// 专题 收藏
export function putcollect(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicUserCollect/collect',
        method: 'put',
        params,
    })
};

// 直播评论接口
// 评论 列表
export function get_comment_list(params) {
    return request({
        url: 'teachingStudioTopicLiveReply/get_comment_list',
        method: 'get',
        params,
    })
};
export function get_reply_list(params) {
    return request({
        url: 'teachingStudioTopicLiveReply/get_reply_list',
        method: 'get',
        params,
    })
};

export function del_reply(params) {
    return request({
        url: `teachingStudioTopicLiveReply/del_reply/${params.id}`,
        method: 'delete',
        params
    })
}

export function postinsertLike(params) {
    return request({
        url: 'teachingStudioTopicLiveReply/insertLike',
        method: 'post',
        data: params,
    })
};

// 直播 收藏
export function postinsert(params) {
    return request({
        url: 'teachingStudioTopicLiveReply/insert_comment',
        method: 'post',
        data: params,
    })
};

export function postinsert_reply(params) {
    return request({
        url: 'teachingStudioTopicLiveReply/insert_reply',
        method: 'post',
        data: params,
    })
};

// 收藏直播列表
export function myLiveList(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLive/collect_lists',
        method: 'get',
        params,
    })
};

// 收藏专题列表
export function myTopicList(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopic/collect_lists',
        method: 'get',
        params,
    })
};

// 直播列表
export function LiveList(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLive/lists',
        method: 'get',
        params,
    })
};
// 直播 详情
export function activityLiveDetail(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLive/show',
        method: 'get',
        params,
    })
};

// 直播 收藏
export function collectionLive(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLiveUserCollect/collect',
        method: 'put',
        data: params,
    })
};

// 直播 点赞
export function likeLive(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLiveUserLike/like',
        method: 'put',
        data: params,
    })
};

// 预告列表
export function AdvanceList(params) {
    return request({
        url: 'index/famousTeacher/activityAdvanceList',
        method: 'get',
        params,
    })
};
// 四名预告详情
export function advanceDetail(params) {
    return request({
        url: 'index/famousTeacher/advanceDetail',
        method: 'get',
        params,
    })
};


// 四名风采列表
export function MienTypeList(params) {
    return request({
        url: 'index/famousTeacher/activityMienTypeList',
        method: 'get',
        params,
    })
};

// 四名风采详情
export function mienReportDetail(params) {
    return request({
        url: 'index/famousTeacher/mienReportDetail',
        method: 'get',
        params,
    })
};

// 点赞
export function postmsfcLike(params) {
    return request({
        url: 'index/famousTeacher/commentMien/insertLike',
        method: 'post',
        data: params,
    })
};
// 收藏
export function putmsfccollect(params) {
    return request({
        url: 'index/famousTeacher/commentMien/collect',
        method: 'put',
        data: params,
    })
};

export function getmsfcQr(params) {
    return request({
        url: 'index/famousTeacher/mienQr',
        method: 'get',
        params,
    })
};

// 四名工作室列表
export function TeachingList(params) {
    return request({
        url: 'index/TeachingStudio/listTeachingStudio',
        method: 'get',
        params,
    })
};


// 四名风采列表
export function partnerLists(params) {
    return request({
        url: 'index/famousTeacher/partnerLists',
        method: 'get',
        params,
    })
};

// 优质原创内容
export function gzsWeChatArticleList(params) {
    return request({
        url: 'index/famousTeacher/gzsWeChatArticleList',
        method: 'get',
        params,
    })
};

// 优质原创内容2
export function gzsgoodList(params) {
    return request({
        url: 'index/famousTeacher/gzsList',
        method: 'get',
        params,
    })
};

// 优质原创内容2
export function selectWeChatMaterialList(params) {
    return request({
        url: 'index/famousTeacher/selectWeChatMaterialList',
        method: 'get',
        params,
    })
};

// 优质原创内容详情
export function weChatMaterialDetail(params) {
    return request({
        url: 'index/famousTeacher/weChatMaterialDetail',
        method: 'get',
        params,
    })
};

// 评论接口
// 获取评论列表
export function commentList(params) {
    return request({
        url: 'index/famousTeacher/activityReportCommentList',
        method: 'get',
        params,
    })
};

// 回复评论列表
export function commentChildList(params) {
    return request({
        url: 'index/famousTeacher/activityReportCommentChildList',
        method: 'get',
        params,
    })
};

// 评论
export function postComment(params) {
    return request({
        url: 'index/famousTeacher/commentActivityReport/comment',
        method: 'post',
        data: params,
    })
};

// 回复
export function postChildComment(params) {
    return request({
        url: 'index/famousTeacher/commentActivityReport/replay',
        method: 'post',
        data: params,
    })
};

// 点赞
export function postNumLike(params) {
    return request({
        url: 'index/famousTeacher/commentActivityReport/numLike',
        method: 'post',
        data: params,
    })
};

// 删除回复
export function delComment(params) {
    return request({
        url: 'index/famousTeacher/commentActivityReport/replayDel',
        method: 'delete',
        params,
    })
};

// 风采评论接口
// 获取评论列表
export function commentList2(params) {
    return request({
        url: 'index/famousTeacher/mienReportCommentList',
        method: 'get',
        params,
    })
};

// 回复评论列表
export function commentChildList2(params) {
    return request({
        url: 'index/famousTeacher/mienReportCommentChildList',
        method: 'get',
        params,
    })
};

// 评论
export function postComment2(params) {
    return request({
        url: 'index/famousTeacher/commentMien/comment',
        method: 'post',
        data: params,
    })
};

// 回复
export function postChildComment2(params) {
    return request({
        url: 'index/famousTeacher/commentMien/replay',
        method: 'post',
        data: params,
    })
};

// 点赞
export function postNumLike2(params) {
    return request({
        url: 'index/famousTeacher/commentMien/numLike',
        method: 'post',
        data: params,
    })
};

// 删除回复
export function delComment2(params) {
    return request({
        url: 'index/famousTeacher/commentMien/replayDel',
        method: 'delete',
        params,
    })
};

// 获取h5直播二维码
export function getH5LiveQrcode(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLive/getQR',
        method: 'get',
        params,
    })
};

// 获取直播状态
export function getLiveStatus(params) {
    return request({
        url: 'index/teachingStudio/TeachingStudioTopicLive/getStatus',
        method: 'get',
        params,
    })
};