<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="
            height: 49px;
            display: flex;
            align-items: center;
          ">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">活动预告详情</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="mainCenter df dfb">
        <div class="mainLeft">
          <!-- 内容 -->
          <div class="mainBox">
            <div class="ulBox">
              <h4 class="tc fz22 mb14 ellipsis-2" style="color: #2A3037;">{{ dataObj.title }}</h4>
              <div class="flex">
                <p class="c666 mr30 fz14">发布时间：{{ dateTime(dataObj.create_time) }}</p>
                <p class="c666 fz14">浏览：{{ dataObj.view_num }}</p>
              </div>
              <!-- 其他 -->
              <div class="mt30">
                <p class="line-h30">活动时间：{{ dataObj.date }}</p>
                <p class="line-h30">活动形式：{{ dataObj.type == 1 ? '四名沙龙' : dataObj.type == 2 ? '四名论坛' : dataObj.type == 2 ?
                  '竞赛活动' : '四名伴我行'
                }}</p>
                <p class="line-h30">活动地点：{{ dataObj.address }}</p>
                <p class="line-h30 mt20">活动简介：</p>
              </div>
              <!-- 富文本 -->
              <div class="ueBox mt10 mb30">
                <p class="width892" v-html="dataObj.content"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="mainRight">
          <!-- 名称 -->
          <div class="titleBox df dfb">
            <div class="titleLeft">
              <p>热点活动</p>
            </div>
          </div>
          <div class="mainBox">
            <div class="ulBox">
              <template v-if="dataList2.length">
                <div class="liBox" v-for="(item, index) in dataList2" :key="index" @click="goMsDetails(item.id)">
                  <h4 class="ellipsis-1 mb12">
                    <img class="width16 h-22" src="@/assets/banner_img/hot_icon.png" alt="">
                    {{ item.title }}
                  </h4>
                  <div class="rightTop df dfb">
                    <div class="imgBox">
                      <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    </div>
                    <div class="txtbox">
                      <p class="ellipsis-3">{{ setText(item.content) }}</p>
                    </div>
                  </div>
                </div>
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      dataObj: {},
      dataList2: [],
    };
  },
  watch: { // 监听，当路由发生变化的时候执行
    $route(to, from) {
      this.getList1()
    },
  },
  created() {
    this.getList1()
    this.getList2()
  },
  methods: {
    getList1() {
      msAllApi.advanceDetail({ id: this.$route.query.id }).then(res => {
        this.dataObj = res.data.data
      })
    },
    getList2() {
      msAllApi.activityList({ sort: 'hot', limit: 8 }).then(res => {
        this.dataList2 = res.data.data.data
      })
    },
    // 跳转活动详情
    goMsDetails(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
    dateTime(now) {
      var date = new Date(now * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Y + M + D + h + m + s;
    }
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainCenter {
    .mainLeft {
      width: 956px;
      min-height: 500px;
      background: #ffffff;
      border-radius: 8px;
      padding: 40px;
      box-sizing: border-box;

      .mainBox {
        width: 100%;

        .ulBox {


          .gzBox {
            .gzLi {
              margin-right: 16px;
              height: 48px;
              background: rgba(238, 246, 255, 1);
              border-radius: 8px;
              padding: 0 18px;
              margin-bottom: 16px;

              >div {

                &:first-child {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;

                  img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                  }
                }

                &:last-child {
                  font-size: 16px;
                  color: #2a3037;
                }
              }
            }
          }
        }
      }
    }

    .mainRight {
      width: 308px;
      min-height: 500px;

      .titleBox {
        border-radius: 8px 8px 0 0;
        height: 72px;
        align-items: center;
        padding: 0 25px;
        background: #ffffff;

        .titleLeft {
          p {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2a3037;
          }
        }
      }

      .mainBox {

        .ulBox {
          background: #ffffff;
          padding: 0 8px 8px 8px;
          border-radius: 0 0 8px 8px;

          .liBox {
            width: 292px;
            height: 134px;
            background: rgba(238, 246, 255, 1);
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 16px;
            box-sizing: border-box;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            .rightTop {
              .imgBox {
                width: 111px;
                height: 62px;

                img {
                  border-radius: 4px;
                }
              }

              .txtbox {
                width: 140px;

                h4 {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #2a3037;
                }

                p {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: normal;
                  color: #666666;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>