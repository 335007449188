<template>
  <div class="public_css_module personal_right_box message_page">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的消息</div>
        <div :class="activeClass == index ? 'active' : 'noClass'" v-for="(item, index) in list"
          @click="handleTableEvent(item.id, index)" :key="index">
          {{ item.name }}
          <span>({{ index == 0 ? mesgObj.category_2 || 0 : mesgObj.category_1 || 0 }})</span>
        </div>
      </div>
    </div>

    <div class="main_div">
      <div v-if="num == 1" class="content_div content_2_div">
        <!-- <div class="tag_tab_box">
          <div class="tag_tab_item" :class="{ 'tag_tab_active': form.search_category == 1 }" @click="handleTableEvent(1)">
            通知类({{ mesgObj.category_1 || 0 }})
          </div>
          <div class="tag_tab_item" :class="{ 'tag_tab_active': form.search_category == 2 }" @click="handleTableEvent(2)">
            审核类({{ mesgObj.category_2 || 0 }})
          </div>
        </div> -->
        <div class="content_top content_2_top">
          <el-checkbox v-model="isAllSelected_1" @change="selectAllChange_1">全选</el-checkbox>
          <div class="btn_box_1" @click="deleteMsgFn_1"> 删除</div>
          <div class="btn_box_2" @click="readMsgFn_1"> 标记已读</div>
        </div>
        <CommonEmpty v-if="!getList_1.length" hVal="150px"></CommonEmpty>

        <div class="content_item_message" v-for="(item, index) in getList_1" :key="index">
          <div class="content_select_box">
            <el-checkbox v-model="item.isSelected" @change="selectChange_1"></el-checkbox>
          </div>
          <div class="content_msg_div">
            <div class="content_msg_title" :class="{ 'activbox': item.is_real == 0 }"
              @click="changeFont(item.id, item)">
              {{ item.title }}
              <p v-if="item.content && item.title != item.content">{{ item.content }}</p>
              <div v-if="item.is_real == 0" class="content_msg_weidu"></div>
            </div>
            <div class="content_msg_time" :class="{ 'activbox': item.is_real == 0 }">{{ item.create_time }}</div>
          </div>
        </div>

        <div v-show="total1" class="pagina_div">
          <el-pagination background @size-change="handleSizeChange_2" @current-change="handleCurrentChange_2"
            :current-page="form.page" :page-sizes="[10, 20, 30, 40, 50]" :page-size="form.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="total1">
          </el-pagination>
        </div>
      </div>

      <div v-if="num == 2" class="content_div">
        <!-- 头部 -->
        <div class="content_top">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
            @change="handleCheckAllChange">全选</el-checkbox>
          <el-button size="medium" style="margin-left: 20px" @click="deleteMSG">删除</el-button>
          <el-button size="medium" style="margin-left: 20px" @click="readMSG">标记已读</el-button>
        </div>
        <div class="box_item">
          <CommonEmpty v-if="!getInteractList.length" hVal="100%"></CommonEmpty>
          <div class="content_bottom" v-for="(item, index) in getInteractList" :key="index">
            <el-checkbox-group v-model="ArryList" @change="handleCheckedCitiesChange">
              <el-checkbox :label="item.user_id" :key="index">
                <span></span>
              </el-checkbox>
            </el-checkbox-group>

            <!-- 头像 -->
            <div class="two">
              <img :src="item.photo_url" alt="" />
              <!-- 1代表未读 -->
              <!-- <div class="two_status" v-if="item.status == 1">
                          {{item.sum}}
                      </div> -->
            </div>
            <!-- 内容 -->
            <div class="three" @click="getPersonlMsg(item.user_id, item.username)">
              <div class="one1">
                <div class="two1">
                  <span>{{ item.username }}</span>
                  <div v-if="Number(item.sum)" class="wzi">{{ item.sum }}</div>
                </div>
                <!-- 时间 -->
                <div class="box-w">
                  {{ item.create_time }}
                </div>
              </div>
              <!-- 信息 -->
              <p>{{ item.content }}</p>
            </div>
            <!-- 信息数 -->
          </div>
        </div>
        <div v-show="total2" class="pagina_div">
          <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
            :current-page="form1.page" :page-sizes="[10, 20, 30, 40, 50]" :page-size="form1.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="total2">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 私信详情 -->
    <div class="pl10 pr10 pt10 pb10">
      <div v-if="num == 3" class="messageInfo">
        <div class="m_title">
          <div class="one">私信</div>
          <div class="one1">{{ user_name }}</div>
        </div>
        <!-- 内容 -->
        <div class="Box_middle" ref="Box_middle">
          <!-- 判断样式  forBox样式是话题人-->
          <CommonEmpty v-if="!msgList.length" hVal="100%"></CommonEmpty>
          <div class="forBox" v-for="(item, index) in msgList" :key="index">
            <!-- 循环部分 -->
            <!-- 时间 -->
            <div class="time_box">
              {{ item.create_time }}
            </div>
            <!-- 不是自己的 -->
            <div class="one_box" v-if="item.user_id != uid">
              <div class="boxItem1">
                <img :src="item.photo_url" alt="" />
              </div>
              <div class="boxItem2">
                <p>{{ item.content }}</p>
              </div>
            </div>
            <!-- 自己 -->
            <div v-if="item.user_id == uid" class="two_box">
              <div class="box2Item2">
                <p>{{ item.content }}</p>
              </div>
              <div class="box2Item1">
                <img :src="item.photo_url" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="input_text">
          <div class="i_box">
            <!-- 输入框 -->
            <el-input type="textarea" :rows="10" class="text_" placeholder="请输入内容" v-model="form3.content"
              resize="none">
            </el-input>
            <el-button type="primary" style="background: #fa8c15; border: 1px solid #fa8c15"
              @click="sendMsg()">发表</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/utils/store.js'
import { mapState } from 'vuex';
export default {
  data() {
    return {
      mesgObj: {
        category_1: 0,
        category_2: 0,
      },
      user_name: "",
      //选中id
      select_user_id: [],
      uid: "",
      //私信内容lsit
      msgList: "",
      value1: "",
      total1: 0,
      total2: 0,
      activeClass: 0,
      form2: {
        //对面聊天人id
        user_id: "",
        limit: "",
        page: "",
      },
      list: [
        {
          id: 2,
          name: "工作室消息",
        },
        {
          id: 1,
          name: "系统通知公告",
        },
      ],
      checkedCities: [],
      isIndeterminate: false,
      checkAll: false,
      isAllSelected_1: false,
      currentPage4: 0,
      //  互动和系统信息切换变量
      num: 1,
      //下拉框
      options: [
        {
          value: 1,
          label: "已读",
        },
        {
          value: 2,
          label: "未读",
        },
      ],
      value: "",
      // 下拉框end
      checked: true,
      //系统
      form: {
        limit: 10,
        page: 1,
        search_category: 2,
      },
      //  私信
      form1: {
        limit: 10,
        page: 1,
      },
      //  定义一个数组用来装私信id
      ArryList: [],
      //  定义一个数组用来装系统id
      selectedArrIds_1: [],
      //  系统遍历数组
      getList_1: [],
      //互动信息
      getInteractList: [],
      //私信内容
      form3: {
        receive_user_id: "",
        content: "",
      },
    };
  },
  computed: {
    ...mapState([
      'msgObj',
    ]),
  },
  created() {
    this.uid = localStorage.getItem("id");
    this.init();
    this.getMessage();
  },
  methods: {
    // 获取未读tiaoshu
    getMessage() {
      store.dispatch('getMessage');
    },
    //标记已读私信信息
    async readMSG() {
      let res = await this.$axios.post("private/real_message", {
        select_user_id: this.ArryList,
      });
      this.interact();
      this.getMessage();
    },
    //个人私信全选
    handleCheckAllChange(val) {
      let arr0 = [];
      this.getInteractList.forEach((item) => {
        arr0.push(item.user_id);
      });

      // 左边的是是否选中的
      this.ArryList = val ? arr0 : [];
      this.isIndeterminate = false;
    },
    //私信单选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.getInteractList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.getInteractList.length;
    },
    //删除系统信息
    async deleteMsgFn_1() {
      if (!this.selectedArrIds_1.length) {
        this.$message.warning("请选择数据");
        return false;
      };
      let ids = this.selectedArrIds_1.toString();
      await this.$axios.delete(`messageDel/${ids}`);
      this.init();
      this.getMessage();
      this.$message.success("删除成功");
    },
    // 点击标记已读
    async changeFont(id, item) {
      await this.$axios.post("message/set_real", { ids: id, })
      this.$set(item, "is_real", 1);
      this.getMessage();
      if (item.go_url) {
        window.open(item.go_url, "_blank");
      }
    },
    //  系统信息初始化数据
    async init() {
      this.getMegCount();
      let res = await this.$axios.get("/message/list", {
        params: this.form,
      });
      this.getList_1 = res.data.data.data;
      this.getList_1.forEach((item) => {
        this.$set(item, "isSelected", false);
      });
      this.total1 = res.data.data.total;
      this.selectedArrIds_1 = [];
      this.selectAllChange_is_ok();
      if (this.num != 1) {
        this.num = 1;
      };
    },
    // 获取数量
    getMegCount() {
      this.$axios.get("/message/count", {
        params: this.form,
      }).then(res_1 => {
        let resData = res_1.data.data;
        this.mesgObj = resData;
      })
    },
    //全选标记已读系统信息
    async readMsgFn_1() {
      if (!this.selectedArrIds_1.length) {
        this.$message.warning("请选择数据");
        return false;
      };
      let ids = this.selectedArrIds_1.toString();
      await this.$axios.post("message/set_real", { ids: ids, });
      this.init();
      this.getMessage();
      this.$message.success("操作成功");
    },
    //信息单选
    selectChange_1(value) {
      let list_2 = this.getList_1.filter(item_1 => {
        return item_1.isSelected;
      });
      let tempArr = list_2.map(item1 => {
        return item1.id;
      });
      this.selectedArrIds_1 = tempArr || [];
      this.selectAllChange_is_ok();
    },
    // 判断第一个全选
    selectAllChange_is_ok() {
      if (this.selectedArrIds_1.length == this.getList_1.length && this.selectedArrIds_1.length) {
        this.isAllSelected_1 = true;
      } else {
        this.isAllSelected_1 = false;
      };
    },
    //系统信息全选
    selectAllChange_1(e) {
      this.getList_1.forEach(item_1 => {
        this.$set(item_1, "isSelected", e);
      });
      this.selectChange_1();
    },
    //发送私信回复
    async sendMsg() {
      if (!this.form3.content) {
        this.$message.warning("内容不能为空");
        return false;
      }
      //  拿到接收人的id
      this.form3.receive_user_id = this.form2.user_id;
      let res = await this.$axios.post("private/sendMessage", this.form3);
      this.getPersonlMsg(this.form3.receive_user_id);
      //  清空私信框
      this.form3.content = "";
    },
    //初始化私信
    async getPersonlMsg(userId, username) {
      this.getMessage();
      //赋值
      this.user_name = username;
      // 得到私信人Id
      this.form2.user_id = userId;
      let res = await this.$axios.get("private/get_info", {
        params: this.form2,
      });
      this.msgList = res.data.data.data;
      //最后一步才显示
      this.num = 3;
      this.$nextTick(() => {
        let scrollElem = this.$refs.Box_middle;
        scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
      });
    },
    //删除私信
    async deleteMSG() {
      if (this.ArryList.length != 0) {
        let res = this.$axios.post("private/del_message", {
          select_user_id: this.ArryList,
        });
        this.interact();
        //  this.$message.success("删除成功");
      } else {
        this.$message.warning("请选择数据");
      }

      // this.num=2;
    },
    getItem: function (index) {
      this.activeClass = index - 1;
      if (index == 1) {
        // 初始化系统信息
        this.init();
        this.num = 1;
      } else if (index == 2) {
        //初始化interact()
        this.interact();
        this.num = 2;
      }
    },

    //初始私信信息
    async interact() {
      let res = await this.$axios.get("/private/get_list", {
        params: {
          limit: this.form1.limit,
          page: this.form1.page,
        },
      });
      this.getInteractList = res.data.data.data;
      this.total2 = res.data.data.total;
      this.num = 2;
      // let arr = this.getInteractList.map((item) => {
      //   return item.user_id;
      // });
      // //  将id赋值给数组
      // this.ArryList = arr;
    },
    handleTableEvent(num, index) {
      this.activeClass = index;
      this.$set(this.form, "search_category", num);
      this.$set(this.form, "page", 1);
      this.init();
    },
    //  系统分页
    //  每页几条的方法
    handleSizeChange_2(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.init();
    },
    // 跳转第几页的方法
    handleCurrentChange_2(val) {
      this.$set(this.form, "page", val);
      this.init();
    },
    // 私信分页
    //  每页几条的方法
    handleSizeChange1(val) {
      this.$set(this.form1, "page", 1);
      this.$set(this.form1, "limit", val);
      this.interact();
    },
    // 跳转第几页的方法
    handleCurrentChange1(val) {
      this.$set(this.form1, "page", val);
      this.interact();
    },
  },
};
</script>

<style lang="less" scoped>
.message_page {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

//主要内容部分
.main_div {
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 0;

  .content_div {
    padding: 38px 32px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;

    .content_top {
      margin-bottom: 28px;
    }

    .content_2_top {
      padding-left: 32px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .box_item {
      .one {
        width: 40px;
      }

      .content_bottom {
        border-bottom: 1px dashed #ececec;
        padding-bottom: 17px;
        cursor: pointer;
        margin-top: 20px;
        display: flex;
        align-items: center;

        .one {
          margin-right: 15px;
        }

        .two {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          margin-right: 15px;

          img {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
          }

          .two_status {
            z-index: 2;
            position: absolute;
            left: 510px;
            top: 435px;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background: #ff1c51;
            border-radius: 50%;
          }
        }

        .three {
          width: 100%;
          margin: auto 0;

          .one1 {
            display: flex;
            justify-content: space-between;

            .two1 {
              span {
                font-size: 14px;
                font-weight: bold;
              }

              display: flex;

              div {
                margin-top: 2px;
                text-align: center;
                line-height: 14px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid #ff1c51;
                background: #ff1c51;
                color: #ffffff;
                font-size: 12px;
              }
            }

            .box-w {
              color: #999999;
            }
          }

          p {
            margin-bottom: 5px;
            font-size: 15px;
          }

          p:nth-of-type(1) {
            color: #1890ff;
          }

          p:nth-of-type(3) {
            color: #999999;
          }
        }
      }
    }
  }

  .content_2_div {
    padding: 38px 0px;
    padding-top: 10px;
  }
}

// 私信内容
.messageInfo {
  width: 100%;
  border-radius: 4px;
  border: 1px solid#ECECEC;
  overflow: hidden;

  .m_title {
    width: 100%;
    border-radius: 4px;
    display: flex;
    height: 60px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: #ececec;
    align-items: center;

    .one {
      margin-left: 20px;
      width: 10%;
      font-size: 16px;
      color: #333333;
    }

    .one1 {
      width: 75%;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .Box_middle {
    height: 600px;
    padding: 0 20px;
    overflow-y: scroll;

    /* 定义滚动条样式 */
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background-color: skyblue;
      background-image: -webkit-linear-gradient(45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent);
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #ededed;
      border-radius: 10px;
    }

    .forBox {

      //  时间
      .time_box {
        margin: 0 auto;
        width: 180px;
        text-align: center;
        height: 25px;
        line-height: 25px;
        margin-top: 30px;
        border-radius: 10px;
        background: #eff1f4;
        border: 1px solid #eff1f4;
      }

      //  循环
      .one_box {
        display: flex;
        padding-bottom: 30px;
        // 头像
        margin-top: 20px;

        .boxItem1 {
          width: 60px;
          height: 60px;
          margin-left: 10px;
          margin-right: 30px;
          border-radius: 50%;

          img {
            border-radius: 50%;
            width: 60px;
            height: 60px;
          }
        }

        // 内容
        .boxItem2 {
          border-radius: 10px;
          border: 1px solid rgba(52, 137, 254, 0.1);
          position: relative;
          background: rgba(52, 137, 254, 0.1);

          p {
            height: 100%;
            font-size: 16px;
            color: #333;
            line-height: 24px;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;
          }

          &:before {
            content: "";
            position: absolute;
            left: -25px;
            top: 15px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            /*left和right是对立面，px要一样。px越小，三角形就越窄。 */
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            border-right: 15px solid rgba(52, 137, 254, 0.1);
          }
        }
      }

      //  循环
      .two_box {
        display: flex;
        justify-content: end;
        padding-bottom: 30px;
        // 头像
        margin-top: 20px;

        .box2Item1 {
          width: 60px;
          height: 60px;
          margin-left: 28px;
          margin-right: 10px;
          border-radius: 50%;

          img {
            border-radius: 50%;
            width: 60px;
            height: 60px;
          }
        }

        // 内容
        .box2Item2 {
          border-radius: 10px;
          border: 1px solid rgba(52, 137, 254, 0.4);
          position: relative;
          background: rgba(52, 137, 254, 0.4);

          p {
            height: 100%;
            font-size: 16px;
            color: #333;
            line-height: 24px;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;
          }

          &::after {
            content: "";
            position: absolute;
            left: 100%;
            top: 15px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            /*left和right是对立面，px要一样。px越小，三角形就越窄。 */
            border-bottom: 10px solid transparent;
            border-left: 10px solid rgba(52, 137, 254, 0.4);
            border-right: 15px solid transparent;
          }
        }
      }
    }
  }

  // 输入框
  .input_text {
    box-sizing: border-box;
    width: 100%;
    height: 260px;
    background: #ececec;
    padding: 16px 15px;

    .i_box {
      box-shadow: 1px 1px 1px 1px white;
      width: 100%;
      height: 220px;
      margin: 0 auto;
      background: #ffffff;

      /deep/.el-textarea__inner {
        height: 160px;
        border: 0;
      }

      .text_ {
        box-sizing: border-box;
        border: none;
        width: 100%;
        //height: 78%;
        margin-bottom: 10px;
      }

      .el-button {
        margin-left: 93%;
      }
    }
  }
}

.content_item_message {
  display: flex;
  align-items: center;
  padding-right: 30px;
  box-sizing: border-box;

  &:hover {
    background-color: #f3faff;
    // .content_msg_title {
    //   color: #3489fe !important;
    // }
  }

  .content_select_box {
    display: flex;
    align-items: center;
    padding-left: 32px;
    width: 84px;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .content_msg_div {
    flex: 1;
    padding: 24px 0px;
    border-bottom: 1px dashed #ececec;

    .content_msg_title {
      font-size: 16px;
      line-height: 20px;
      color: #666;
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-weight: normal;
    }

    .content_msg_weidu {
      top: 2px;
      left: -10px;
      position: absolute;
      width: 7px;
      height: 7px;
      background: rgba(250, 29, 82, 1);
      border-radius: 50%;
    }

    .content_msg_time {
      font-size: 16px;
      line-height: 16px;
      margin-top: 14px;
      font-weight: normal !important;
      color: #666;
    }

    .activbox {
      font-weight: bold;
      color: #333;
    }
  }
}

.btn_box_1 {
  cursor: pointer;
  text-align: center;
  line-height: 34px;
  width: 88px;
  height: 34px;
  border-radius: 4px;
  margin-left: 20px;
  background-color: #ffeded;
  color: #ff4b4b;
  border: 1px solid #ffeded;
}

.btn_box_2 {
  cursor: pointer;
  text-align: center;
  line-height: 34px;
  width: 88px;
  height: 34px;
  border-radius: 4px;
  margin-left: 20px;
  background-color: #e4f8e7;
  color: #36af44;
  border: 1px solid #e4f8e7;
}

.tag_tab_box {
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid #cccccc;
  margin-left: 30px;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  .tag_tab_item {
    width: 160px;
    height: 40px;
    background-color: #e6efff;
    border-radius: 20px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    font-weight: normal;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    margin-right: 30px;
  }

  .tag_tab_active {
    background-color: #128bfb;
    color: #fff;
  }
}
</style>
