<template>
  <!-- //四名工作室简介 -->
  <div class="introduction">
    <div class="w_layout ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：首页 >
          <span>四名工作室</span>
        </p>
      </div>
      <!-- 教师信息 -->
      <div class="teachInfo">
        <div class="one">
          <!-- //头像 -->
          <div class="imgBox">
            <img :src="detail.photo_url" alt="" />
          </div>
          <h4>{{ detail.teaching_studio_name }}</h4>
          <div class="mainBox" v-if="detail.label">
            <p v-for="(item, index) in detail.label.split(',')" :key="index">{{ item }}</p>
          </div>
          <!-- 虚线 -->
          <div class="btnBox">
            <p>
              <span>学段学科：</span>
              <span>{{ detail.stage_name }}{{ detail.subject_name }}</span>
            </p>
            <p>
              <span>设立单位：</span>
              <span>{{ detail.organization_name }}</span>
            </p>
            <!-- <p>
            <span>所在地区：</span> -->
            <!-- <span>小学语文</span>s -->
            <!--</p> -->
          </div>
        </div>
        <div class="two">
          <div class="bj">
            <div class="wz">主持人简介</div>
            <div class="rBox"></div>
          </div>
          <!-- 文字 -->
          <div class="wzBox">
            <p v-html="detail.teacher_synopsis" class="rich_text_div_img" style="margin-left: 10px"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- //底部灰色部分 -->
    <div class="hsBox">
      <div class="w_layout Contents">
        <div class="AboutRoom">
          <h4>关于工作室</h4>
          <p v-html="detail.studio_synopsis" class="rich_text_div_img"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //成员人数
      peopleDetail: "",

      form1: {
        search_role_studio_id: "", // 空为全部 1为工作室管理员 2为骨干成员 3为网络学员
        limit: 10,
        page: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
      },

      //详情
      detail: "",
      total: 0,
      form: {
        limit: 16,
        page: 1,
        search_role_studio_id: "", //空为全部 1为工作室管理员 2为骨干成员 3为网络学员
        search_role_studio_child_id: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      active: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    pageRoll() {
      if (this.$route.query && this.$route.query.domClass) {
        this.$nextTick(() => {
          let dom = document.getElementById('work_tearm_box_id');
          dom.scrollIntoView({
            behavior: 'smooth'
          });
        })
      };
    },
    //初始化
    init() {
      this.getDetailInfo();
      //获取工作室人数
      this.pageRoll();
    },
    //工作室详情
    getDetailInfo() {
      this.$axios
        .get("index/TeachingStudio/TeachingStudioInfo", {
          params: {
            id: localStorage.getItem("studioId"),
            user_id: localStorage.getItem("id"),
          },
        })
        .then((res) => {
          this.detail = res.data.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.introduction {
  .ArrBox {

    //标题
    .title {
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    //教师信息
    .teachInfo {
      display: flex;
      justify-content: left;
      align-items: center;
      background: #fff;
      border-radius: 4px;

      .one {
        width: 338px;
        height: 443px;
        padding: 0 36px;

        //头像
        .imgBox {
          width: 118px;
          height: 118px;
          margin: 0 auto;
          margin-top: 56px;
          margin-bottom: 32px;

          img {
            width: 118px;
            height: 118px;
          }
        }

        h4 {
          text-align: center;
          margin-bottom: 16px;
        }

        .mainBox {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          height: 25px;
          overflow: hidden;

          p {
            background: #b8f3ef;
            padding: 2px 6px;
            margin: 0 2px;
            border-radius: 4px;
          }
        }

        // 下面盒子
        .btnBox {
          width: 256px;
          border-top: 1px dashed #e0e0e0;
          margin-top: 19px;
          padding-top: 38px;

          p {
            text-align: left;

            margin-bottom: 10px;

            span:nth-of-type(1) {
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;
            }

            span:nth-of-type(1) {
              font-size: 16px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;
            }
          }
        }
      }

      .two {
        width: 1067px;
        height: 443px;
        border-left: 1px solid #f0f0f0;
        box-sizing: border-box;

        .bj {
          display: flex;
          align-items: center;
          padding: 0 51px;
          padding-top: 41px;

          .wz {
            width: 96px;
            height: 32px;
            background: #3489fe;
            text-align: center;
            line-height: 32px;
            color: #fff;
          }

          .rBox {
            width: 888px;
            height: 30px;
            background: #ebf3ff;
          }
        }

        .wzBox {
          width: 981px;
          margin: 0 auto;
          margin-top: 38px;
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  //底部灰色部分
  .hsBox {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 40px;
    box-sizing: border-box;

    .Contents {
      padding-top: 26px;
      box-sizing: border-box;

      .AboutRoom {
        box-sizing: border-box;
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        padding: 34px;

        p {
          margin: 29px 0;
        }
      }

      //工作室成员
      .workTearm {
        padding: 34px;
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        margin-top: 26px;
        box-sizing: border-box;

        .topName {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .no {
            cursor: pointer;
            color: #999999;
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
          }

          .yes {
            color: #3489fe;
            border-bottom: 1px solid #3489fe;
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
          }

          span:nth-of-type(1) {
            font-size: 18px;
            font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
            margin-right: 39px;
            border: none;
          }
        }

        .contentList {
          display: flex;
          flex-wrap: wrap;

          //循环的盒子
          .forBox {
            width: 315px;
            height: 90px;
            background: #ffffff;
            border: 1px solid #ebebeb;
            border-radius: 7px;
            margin-bottom: 24px;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            box-sizing: border-box;

            &:nth-child(4n) {
              margin-right: 0;
            }

            .lImg {
              width: 50px;
              height: 50px;
              margin-right: 10px;

              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
            }

            .rInfo {
              flex: 1;
              overflow: hidden;

              .df {
                justify-content: space-between;
                max-height: 40px;
                margin-bottom: 9px;

                div {
                  &:first-child {
                    flex: 1;
                    overflow: hidden;
                  }

                  &:last-child {
                    p {
                      background: #f8efe6;
                      border: 1px solid #ff8201;
                      color: #ff8201;
                      padding: 2px 4px;
                      box-sizing: border-box;
                      font-size: 14px;
                      border-radius: 4px;
                      max-width: 170px;
                    }
                  }
                }
              }

              .common {
                font-size: 14px;
                color: #9a9ea5;
              }

              p:nth-of-type(1) {
                display: flex;
                flex-wrap: wrap;

                span:nth-of-type(1) {
                  color: #333333;
                }

                span:nth-of-type(2) {
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  background: #f8efe6;
                  border: 1px solid #ff8201;
                  color: #ff8201;
                  border-radius: 2px;
                  margin-left: 14px;
                  font-size: 12px;
                }
              }

              p:nth-of-type(2) {
                width: 169px;
                height: 12px;
                font-size: 14px;
                color: #9a9ea5;
              }
            }
          }
        }
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>