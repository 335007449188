<template>
  <div class="CertificateDetail public_main_bgc">
    <div class="titleBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 > 成果展示 >
          <span>{{ honorData.achievement_type == 1 ? '成果展示' : '研究课题' }}详情</span>
        </p>
      </div>
      <!-- 详情 -->
      <div class="detailPage">
        <div class="about" v-if="honorData.status == 1 && joinStudio == 'true'">
          <el-button size="mini" type="primary" @click="PassWay(2)">审核通过</el-button>
          <el-button size="mini" type="danger" @click="noPass">审核不通过</el-button>
        </div>
        <h4>{{ honorData.name }}</h4>
        <div v-if="honorData.achievement_type == 1" class="inf_div">
          <p class="inf_item_item">成果类型：{{ honorData.certificat_type_name }}</p>
          <p class="inf_item_item">成果级别：{{ honorData.certificat_level_str }}</p>
          <p class="inf_item_item">获奖人/贡献者： {{ honorData.usernames }}</p>
          <p class="inf_item_item"> 浏览：{{ honorData.view_count }}人</p>
          <p class="inf_item_item" style="color: rgb(240, 95, 95);">
            {{ honorData.status == 1 ? '待审核' : honorData.status == 3 ? '未通过' : '' }}
            <el-popover v-if="honorData.status == 3" placement="top-start" title="" width="200" trigger="hover"
              :content="honorData.refusal">
              <i slot="reference" class="el-icon-question"></i>
            </el-popover>
          </p>
        </div>
        <div v-if="honorData.achievement_type == 2" class="inf_div">
          <p class="inf_item_item">课题主持人：{{ honorData.usernames }}</p>
          <p class="inf_item_item">课题编号：{{ honorData.topic_num }}</p>
          <p class="inf_item_item">研究周期：{{ honorData.cycle_start_date }} 至 {{ honorData.cycle_end_date }}</p>
          <p class="inf_item_item">课题级别：{{ honorData.certificat_level_str }}</p>
          <p class="inf_item_item">浏览：{{ honorData.view_count }}人</p>
          <p class="inf_item_item" style="color: rgb(240, 95, 95);">
            {{ honorData.status == 1 ? '待审核' : honorData.status == 3 ? '未通过' : '' }}
            <el-popover v-if="honorData.status == 3" placement="top-start" title="" width="200" trigger="hover"
              :content="honorData.refusal">
              <i slot="reference" class="el-icon-question"></i>
            </el-popover>
          </p>
        </div>
        <!-- 横线 -->
        <div class="wb">
          {{ honorData.synopsis }}
        </div>
        <!-- //图片 -->
        <div class="photo mb10" v-for="item in honorData.resource_data" :key="item.id">
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>
    <!-- //不通过弹框 -->
    <el-dialog title="不通过的理由" :visible.sync="dialogVisible" width="30%">
      <el-input type="textarea" maxlength="100" show-word-limit :rows="5" placeholder="请输入审核不通过的理由" resize="none"
        v-model="form1.textarea">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="PassWay(3)">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      honorData: "",
      // 1四名工作室首页进入，2个人中心四名工作室进入
      is_from: 1,
      dialogVisible: false,
      //审核表单
      form1: {
        textarea: "",
        status: "", //2 审核通过 3审核不通过
        teaching_studio_id: localStorage.getItem("studioId"),
        id: "", //审核id
      },
      joinStudio: "false"
    };
  },
  created() {
    this.joinStudio = localStorage.getItem("joinStudio")
    //初始化
    this.init();
  },
  methods: {
    //不通过
    noPass() {
      this.form1.id = this.$route.query.id; //审核id
      this.dialogVisible = true;
    },
    //通过
    PassWay(type) {
      this.form1.status = type; //2 审核通过 3审核不通过
      this.form1.id = this.$route.query.id; //审核id
      this.form1.refusal = this.form1.textarea
      this.$axios
        .put("TeachingStudioCertificate/status", this.form1)
        .then(() => {
          if (type == 2) {
            this.$message.success("审核通过");
          } else {
            this.$message.success("审核不通过");
            this.dialogVisible = false;
          }
          this.$router.go(0)
        });
    },

    init() {
      this.is_from = this.$route.query.is_from;
      //获取详情
      this.getDetail();
    },
    getDetail() {
      let obj = {
        id: this.$route.query.id,
        teaching_studio_id: this.$route.query.studioId
          ? this.$route.query.studioId
          : localStorage.getItem("studioId"),
      };
      this.$axios
        .get("TeachingStudioCertificate/get_info", {
          params: obj,
        })
        .then((res) => {
          this.honorData = res.data.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.CertificateDetail {
  padding-bottom: 10px;

  .titleBox {
    width: 1400px;
    margin: 0 auto;

    .title {
      height: 48px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    .detailPage {
      min-height: 1000px;
      background: #ffffff;
      border-radius: 6px;
      padding: 72px 100px;
      position: relative;

      .about {
        position: absolute;
        right: 3px;
        top: 3px;
        background: #f00;
        padding: 10px;
        background: rgba(234, 241, 247, 1);
        border-radius: 0 8px 0 8px;
      }

      h4 {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 32px;
      }

      .wb {
        width: 100%;
        text-align: left;
        padding: 20px;
        box-sizing: border-box;
      }

      .photo {
        width: 100%;
        height: 867px;

        img {
          object-fit: contain;
          width: 100%;
          height: 867px;
        }
      }
    }
  }
}

.detail {
  background-color: #fff;
  padding: 40px 60px 60px;
  width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;

  .preeditor {
    width: 102px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background: #ff8201;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
  }

  .info {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 26px;
    margin-bottom: 30px;
  }

  .reson {
    margin-bottom: 24px;

    >p {
      color: #ff0607;
      font-weight: 600;
      padding-bottom: 18px;
    }

    >div {
      line-height: 24px;
    }
  }

  h4 {
    height: 32px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    color: #333333;
    line-height: 32px;
    padding-bottom: 40px;
  }

  .wb {
    background-color: #f7f7f7;
    padding: 24px 24px 24px 18px;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .photo {
    img {
      width: 100%;
      display: block;
    }
  }
}

.inf_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }

  .inf_item_item {
    margin: 0 20px;
  }
}

/deep/.el-dialog__header {
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgb(190, 216, 253) 100%);
}
</style>