<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="
            height: 49px;
            display: flex;
            align-items: center;
          ">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">搜索结果</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="mainCenter">
        <el-alert class="mt10 mb10" :title="'搜索“ ' + keyword + ' ”关键词共搜索出 ' + allTotal + ' 条数据'" type="success"
          effect="dark">
        </el-alert>
        <div class="mainLeft">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="'工作室(' + dataList1.total + ')'" name="taba" v-if="dataList1.total">
              <ListTwo :list="dataList1.data" :is_who="3" />
              <el-pagination v-if="dataList1.total" class="tc mt30 mb30" background @current-change="pageChange1"
                :current-page.sync="form1.page" :page-size="form1.limit" layout="total, prev, pager, next"
                :total="dataList1.total">
              </el-pagination>
            </el-tab-pane>

            <el-tab-pane :label="'论坛活动(' + dataList2.total + ')'" name="tabb" v-if="dataList2.total">
              <div class="ulBox2" v-if="dataList2.total">
                <div class="liBox df dfb cp" v-for="(item, index) in dataList2.data" :key="index"
                  @click="goDetails2(item.id)">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>

                  <div class="txtbox df dfb">
                    <div class="width800">
                      <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
                      <p class="ellipsis-3 fz14">{{ setText(item.content) }}</p>
                      <div class="df mt30">
                        <p class="fz14 c666 mr30 flex_align_center">
                          {{ item.type_str }}
                        </p>
                        <p class="fz14 c666 mr30 flex_align_center">
                          <img class="width20 h-16 mr5" src="@/assets/banner_img/liulan.png" alt="">
                          浏览：{{ item.view_num || 0 }}
                        </p>
                        <p class="fz14 c666 mr30 flex_align_center">
                          <img class="width20 h-16 mr5" src="@/assets/banner_img/pinglun.png" alt="">
                          评论：{{ item.num_comment || 0 }}
                        </p>
                      </div>
                    </div>
                    <div class="width120">
                      <p class="tr fz14 c999">
                        {{ item.create_time }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              <el-pagination v-if="dataList2.total" class="tc mt30 mb30" background @current-change="pageChange2"
                :current-page.sync="form2.page" :page-size="form2.limit" layout="total, prev, pager, next"
                :total="dataList2.total">
              </el-pagination>
            </el-tab-pane>

            <el-tab-pane :label="'活动直播(' + dataList3.total + ')'" name="tabc" v-if="dataList3.total">
              <div class="ulBox3 df dfw" v-if="dataList3.total">
                <div class="liBox cp mb1" v-for="(item, index) in dataList3.data" :key="index"
                  @click="goDetails3(item.id)">
                  <div class="imgbox">
                    <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                    <div class="imgAbout1 tc line-h20"
                      :class="item.status == 1 ? 'bg1' : item.status == 2 ? 'bg2' : item.playback_resource_url ? 'bg3' : 'bg4'">
                      {{ item.status == 1 ? '预告' : item.status == 2 ? '直播中' : item.playback_resource_url ? "回放" : "已结束" }}
                    </div>
                    <div class="imgAbout2 df line-h24">
                      <div class="flex mt3 mr5">
                        <i class="el-icon-caret-right"></i>
                      </div>
                      <p>{{ item.start_date }} <i class="ml3 mr3"></i> {{ item.start_time.substring(0, 5) }}~{{
                        item.end_time.substring(0, 5) }}</p>
                    </div>
                  </div>
                  <div class="txtBox mt10">
                    <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
                    <div class="lableBox df mb10">
                      <p v-for="(item, index) in item.label.split(',')" :key="index">{{ item }}</p>
                    </div>
                    <div class="df dfb">
                      <p>{{ item.type == 1 ? '四名沙龙' : item.type == 2 ? '四名论坛' : item.type == 2 ? '竞赛活动' : '四名伴我行' }}</p>
                      <p>浏览人次：{{ item.view_count }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              <el-pagination v-if="dataList3.total" class="tc mt30 mb30" background @current-change="pageChange3"
                :current-page.sync="form3.page" :page-size="form3.limit" layout="total, prev, pager, next"
                :total="dataList3.total">
              </el-pagination>
            </el-tab-pane>
            <el-tab-pane :label="'公益行(' + dataList4.total + ')'" name="tabd" v-if="dataList4.total">
              <div class="ulBox2" v-if="dataList4.total">
                <div class="liBox df dfb cp" v-for="(item, index) in dataList4.data" :key="index"
                  @click="goDetails4(item.id)">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>

                  <div class="txtbox df dfb">
                    <div class="width800">
                      <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
                      <p class="ellipsis-3 fz14">{{ setText(item.content) }}</p>
                      <div class="df mt30">
                        <p class="fz14 c666 mr30 flex_align_center">
                          <img class="width20 h-16 mr5" src="@/assets/banner_img/liulan.png" alt="">
                          浏览：{{ item.view_num || 0 }}
                        </p>
                        <p class="fz14 c666 mr30 flex_align_center">
                          <img class="width20 h-16 mr5" src="@/assets/banner_img/pinglun.png" alt="">
                          评论：{{ item.num_comment || 0 }}
                        </p>
                      </div>
                    </div>
                    <div class="width120">
                      <p class="tr fz14 c999">
                        {{ item.create_time }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              <el-pagination v-if="dataList4.total" class="tc mt30 mb30" background @current-change="pageChange4"
                :current-page.sync="form4.page" :page-size="form4.limit" layout="total, prev, pager, next"
                :total="dataList4.total">
              </el-pagination>
            </el-tab-pane>
            <el-tab-pane :label="'四名风采(' + dataList5.total + ')'" name="tabe" v-if="dataList5.total">
              <div class="ulBox2" v-if="dataList5.total">
                <div class="liBox df dfb cp" v-for="(item, index) in dataList5.data" :key="index"
                  @click="goDetails5(item.id)">
                  <div class="imgBox">
                    <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                    <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  </div>

                  <div class="txtbox df dfb">
                    <div class="width800">
                      <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
                      <p class="ellipsis-3 fz14">{{ setText(item.content) }}</p>
                      <div class="df mt30">
                        <p class="fz14 c666 mr30 flex_align_center">
                          <img class="width20 h-16 mr5" src="@/assets/banner_img/liulan.png" alt="">
                          浏览：{{ item.view_num || 0 }}
                        </p>
                        <p class="fz14 c666 mr30 flex_align_center">
                          <img class="width20 h-16 mr5" src="@/assets/banner_img/pinglun.png" alt="">
                          评论：{{ item.num_comment || 0 }}
                        </p>
                      </div>
                    </div>
                    <div class="width120">
                      <p class="tr fz14 c999">
                        {{ item.create_time }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
              <el-pagination v-if="dataList5.total" class="tc mt30 mb30" background @current-change="pageChange5"
                :current-page.sync="form5.page" :page-size="form5.limit" layout="total, prev, pager, next"
                :total="dataList5.total">
              </el-pagination>
            </el-tab-pane>
          </el-tabs>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
import ListTwo from "@/components/CommonList/ListTwo.vue";
export default {
  components: {
    ListTwo,
  },
  data() {
    return {
      activeName: 'taba',
      keyword: "",
      form1: {
        search_name: "",
        search_stage_id: "",
        search_subject_id: "",
        provinces_cities_counties: "",
        all_web_id: 2,
        is_homepage: 2,
        page: 1,
        limit: 9,
      },
      dataList1: {},

      form2: {
        keyword: "",
        type1: 0,
        hidden_type: 4,
        page: 1,
        limit: 10,
      },
      dataList2: {},

      form3: {
        keyword: "",
        search_type: "",
        search_status: "",
        page: 1,
        limit: 15,
      },
      dataList3: {},

      form4: {
        keyword: "",
        type1: 4,
        type2: 0,
        page: 1,
        limit: 10,
      },
      dataList4: {},

      form5: {
        keyword: "",
        type1: 4,
        page: 1,
        limit: 10,
      },
      dataList5: {},
    };
  },
  computed: {
    allTotal: function () {
      return this.dataList1.total + this.dataList2.total + this.dataList3.total + this.dataList4.total + this.dataList5.total
    }
  },
  watch: {
    $route() {
      if (this.$route.query.selName) {
        this.getAllList()
        setTimeout(() => {
          this.changeActive()
        }, 1000);
      }
    }
  },
  created() {
    this.getAllList()
    setTimeout(() => {
      this.changeActive()
    }, 1000);
  },
  methods: {
    handleClick(tab, event) {
      this.form1.page = 1
      this.form2.page = 1
      this.form3.page = 1
      this.form4.page = 1
      this.form5.page = 1
    },
    changeActive() {
      if (this.dataList1.total) {
        this.activeName = 'taba'
        return
      } else if (this.dataList2.total) {
        this.activeName = 'tabb'
        return
      } else if (this.dataList3.total) {
        this.activeName = 'tabc'
        return
      } else if (this.dataList4.total) {
        this.activeName = 'tabd'
        return
      } else if (this.dataList5.total) {
        this.activeName = 'tabe'
        return
      }
    },
    getAllList() {
      if (this.$route.query.selName) {
        this.keyword = this.$route.query.selName
      }
      this.form1.search_name = this.keyword
      this.form2.keyword = this.keyword
      this.form3.keyword = this.keyword
      this.form4.keyword = this.keyword
      this.form5.keyword = this.keyword
      this.$nextTick(() => {
        this.getList1()
        this.getList2()
        this.getList3()
        this.getList4()
        this.getList5()
      })
    },
    getList1() {
      this.$axios
        .get("index/TeachingStudio/listTeachingStudio", { params: this.form1 })
        .then((res) => {
          this.dataList1 = res.data.data;
        });
    },
    pageChange1(val) {
      this.form1.page = val
      this.getList1()
    },
    getList2() {
      msAllApi.activityList(this.form2).then(res => {
        this.dataList2 = res.data.data
      })
    },
    pageChange2(val) {
      this.form2.page = val
      this.getList2()
    },
    // 跳转活动详情
    goDetails2(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },
    getList3() {
      msAllApi.LiveList(this.form3).then(res => {
        this.dataList3 = res.data.data
      })
    },
    pageChange3(val) {
      this.form3.page = val
      this.getList3()
    },
    // 跳转活动详情
    goDetails3(id) {
      this.$router.push({
        name: "MsLiveDetails",
        query: { id: id }
      })
    },
    getList4() {
      msAllApi.activityList(this.form4).then(res => {
        this.dataList4 = res.data.data
      })
    },
    pageChange4(val) {
      this.form4.page = val
      this.getList4()
    },
    // 跳转活动详情
    goDetails4(id) {
      this.$router.push({
        name: "MsDetails",
        query: { id: id }
      })
    },

    getList5() {
      msAllApi.MienTypeList(this.form5).then(res => {
        this.dataList5 = res.data.data
      })
    },
    pageChange5(val) {
      this.form5.page = val
      this.getList5()
    },
    // 跳转活动详情
    goDetails5(id) {
      this.$router.push({
        name: "MsElegDetails",
        query: { id: id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainCenter {
    background: #ffffff;
    padding: 10px 30px;
    min-height: 500px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;

    .mainLeft {
      width: 100%;

      .ulBox2 {
        .liBox {
          padding: 12px;
          border-radius: 8px;

          &:hover {
            background: rgba(238, 246, 255, 1);

            h4 {
              color: #3489ff;
            }
          }

          .imgBox {
            width: 250px;
            height: 141px;

            img {
              border-radius: 8px;

            }
          }

          .txtbox {
            width: 920px;
          }
        }
      }

      .ulBox3 {
        .liBox {
          width: 243px;
          height: 248px;
          background: rgba(255, 255, 255, 1);
          border-radius: 8px;
          padding: 12px;
          box-sizing: border-box;
          margin-right: 1px;

          &:nth-child(5n) {
            margin-right: 0;
          }

          .imgbox {
            width: 219px;
            height: 133px;
            border-radius: 8px;
            position: relative;

            img {
              border-radius: 8px;
            }

            .imgAbout1 {
              position: absolute;
              top: 0;
              left: 0;
              width: 52px;
              height: 20px;
              border-radius: 8px 0 8px 0;
              font-size: 12px;
              font-weight: bold;
              color: #ffffff;

              &.bg1 {
                background: #409eff;
              }

              &.bg2 {
                background: #51B875;
              }

              &.bg3 {
                background: #ff7a29;
              }

              &.bg4 {
                background: #999;
              }
            }

            .imgAbout2 {
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.5);
              width: 100%;
              height: 24px;
              color: #fff;
              font-size: 14px;
              justify-content: center;

              >div {
                width: 14px;
                height: 14px;
                border: 1px solid #fff;
                border-radius: 50%;
              }
            }

          }

          .txtBox {
            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }

            .lableBox {
              height: 23px;
              flex-wrap: wrap;
              overflow: hidden;

              p {
                padding: 2px 6px;
                background: #e5f4ff;
                border-radius: 4px;
                margin-right: 10px;
                text-align: center;
                color: #0493ff;
                font-size: 14px;
              }
            }

            div {
              p {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
              }
            }
          }
        }
      }

    }

  }
}
</style>