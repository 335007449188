// 填空题
export default {
  props: {
    //弹窗显示
    value: {
      type: Boolean,
      default: false,
    },
    //题目数据
    subjectData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogShow: false, //是否显示弹窗
      //选择题参数
      subject: {
        title: "",
        type: 2,
        is_required: 1,
        options: [],
      },
      //选择题验证
      subjectRules: {
        title: [{ required: true, message: "请输入题干", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    //弹窗关闭的回调
    dialogHandleClose() {
      this.$emit("input", false);
    },
    //取消按钮
    cancelButton() {
      this.$emit("input", false);
    },
    //确认按钮
    defineButton(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subject.options = [];
          this.matchingWrite(this.subject.title, this.subject.options);
          this.$emit("onSubject", this.subject);
          this.$emit("input", false);
        }
      });
    },
    //匹配填空（已改）
    matchingWrite(text, options) {
      const pattern = /_(?!_)/g;
      const list = text.match(pattern);
      // console.log(list,"数据1111111111111");
      //匹配不到数据
      if(list != null){
        list.forEach((item, index) => {
          options.push({
            content: "填空" + (index + 1),
          });
        });
      }else{
        options.push({
          content: "填空" + 1,
        });
      }
    },
  },
  watch: {
    //监听是否显示弹窗
    value(val) {
      // console.log(val,"sjiajiji1j");
      this.dialogShow = val;
      if (!this.dialogShow) {
        this.subject = {
          title: "",
          type: 2,
          is_required: 1,
          options: [],
        };
        setTimeout(() => {
          this.$refs["subject"].clearValidate();
        }, 1000);
      }
    },
    //监听题目数据
    subjectData(e) {
      if (this.dialogShow) {
        this.subject = e;
      }
    },
    //监听问卷说明
    "subject.title"() {
      this.$nextTick(() => {
        this.$refs["subject"].validateField("title");
      });
    },
  },
};
