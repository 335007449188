<template>
  <div class="groupdetailtalk">
    <div class="content_one">
      <div class="one_left">话题研讨</div>
      <div class="one_right" v-if="isToken">
        <p class="btn2" v-if="openFlat  == 1 " @click="GroupAuditTearm()">审核组员创建的</p>
        <i class="el-icon-plus"></i>
        <p class="cursor" @click="addTalk()">发起话题</p>
      </div>
    </div>
    <div class="content_two">
      <CommonEmpty v-if="!talkList.length" hVal="100%"></CommonEmpty>
      <div class="two_block" v-for="(item, index) in talkList" :key="index">
        <div class="one">
          <p class="one_left cursor" @click="enterDetails(item.id)">
            #{{ item.title }}
          </p>
          <div class="one_right">
            <div class="right_block cursor" @click="talkDelete(item)" v-if="item.user_id == userId || item.group_user_id == userId">
              <i class="el-icon-delete"></i>
              <span>删除</span>
            </div>
            <div class="right_block">
              <i class="el-icon-view"></i>
              <span>浏览{{ item.visit_count || 0 }}</span>
            </div>
            <div class="right_block">
              <i class="el-icon-chat-dot-square"></i>
              <span>回复{{ item.reply_count || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="two">
          <p>{{ item.username }}于{{ item.create_time }}发起</p>
          <p>最新回复时间：{{ item.reply_time || "-" }}</p>
        </div>
      </div>
      <div v-if="talk.total" class="pagin">
        <el-pagination background :current-page="talk.page" :page-size="talk.limit" layout="prev, pager, next, jumper" :total="talk.total"
          @current-change="currentChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isToken: false,

      openFlat: 0,
      userId: 0, //用户id
      talkList: [], //话题研讨列表
      talk: {
        //话题研讨参数
        limit: 10,
        page: 1,
        total: 0,
        select_group_id: "",
      },
    };
  },
  created() {
    this.isToken = localStorage.getItem('token') != null ? true : false;
    this.openFlat = localStorage.getItem('AuditFlat');
    this.talk.select_group_id = this.$route.query.groupId
      ? this.$route.query.groupId
      : 0;
    this.userId = localStorage.getItem("id") ? localStorage.getItem("id") : 0;
    this.getTalkList();
  },
  methods: {
    //审核
    GroupAuditTearm() {
      this.$router.push({
        path: '/GroupTearmAudit',
        query: {
          groupId: this.$route.query.groupId,
          type: '4',
          navCurrentType: 'talk',
        }
      })
    },
    //获取话题研讨列表
    async getTalkList() {
      const res = await this.$axios.get("/groupGambit/listAll", {
        params: this.talk,
      });
      this.talkList = res.data.data.data;
      this.talk.total = res.data.data.total;
    },
    //删除话题研讨
    async delTalk(id, userId) {
      try {
        await this.$axios.delete(`/gambit/${id}/${userId}`);
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getTalkList();
      } catch (error) {
      }
    },
    //当前页码改变
    currentChange(val) {
      this.talk.page = val;
    },
    //话题研讨删除
    talkDelete(e) {
      if (e.user_id == this.userId || e.group_user_id == this.userId) {
        this.$confirm("此操作将删除该话题, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.delTalk(e.id, this.userId);
        })
          .catch(() => { });
      }
    },
    //进入详情
    enterDetails(id) {
      this.$router.push({ path: "/topicDetails", query: { id: id } });
    },
    //发起话题
    addTalk() {
      this.$router.push({
        path: "/sendTopic",
        query: { groupId: this.talk.select_group_id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.groupdetailtalk {
  //   border: 1px solid pink;
  background: #ffffff;
  border-radius: 6px;
  padding: 40px 30px 60px 31px;
  .content_one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .one_left {
      font-weight: 700;
    }
    .one_right {
      color: #3489fe;
      display: flex;
      align-items: center;
      //审核行
      .btn2 {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #3489fe;
        margin-right: 30px;
        span {
          color: #ff0607;
          font-weight: bold;
        }
      }
      i {
        margin-right: 10px;
      }
    }
  }
  .content_two {
    .two_block {
      height: 111px;
      border-bottom: 1px dashed #ececec;
      .one {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px;
        .one_left {
          font-weight: 700;
          width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .one_right {
          display: flex;
          .right_block {
            margin-left: 30px;
            display: flex;
            align-items: center;
            color: #8590a6;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      .two {
        color: #999999;
        display: flex;
        p:nth-child(1) {
          margin-right: 60px;
        }
      }
    }
  }
}
</style>