<template>
  <div class="public_main_bgc">
    <div class="bannerSty">
      <el-carousel trigger="click" height="320px">
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/shouye_banner.mingshi.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="item_img_img" src="@/assets/banner_img/mingshi_banner.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="public-width pb15">
      <!-- 团队风采 -->
      <div class="public6box mt16">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>团队风采</p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goMsElegList">更多></p>
          </div>
        </div>
        <div class="public6main df dfb">
          <div class="main6Left cp" v-if="dataList1.length" @click="goMsElegDetails(dataList1[0].id)">
            <div class="imgBox">
              <img class="imgbig" v-if="dataList1[0].thumb" :src="dataList1[0].thumb" alt="">
              <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
            </div>
            <div class="txtBox">
              <p class="ellipsis-1 mb5">{{ dataList1[0].title }}</p>
              <p class="ellipsis-2">{{ setText(dataList1[0].content) }}</p>
            </div>
          </div>
          <el-empty class="main6Left" :image-size="50" v-else description="暂无数据"></el-empty>

          <div class="main6Right df" v-if="dataList1.length">
            <template v-for="(item, index) in dataList1">
              <div class="cp" :key="index" v-if="index >= 1" @click="goMsElegDetails(item.id)">
                <div class="imgBox">
                  <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                  <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
                  <div class="aboutBox">
                    <p class="ellipsis-1">{{ item.title }}</p>
                  </div>
                  <div class="bigBgBox">
                    <h4 class="ellipsis-1 mb10">{{ item.title }}</h4>
                    <p class="ellipsis-3">{{ setText(item.content) }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <el-empty class="main6Right" :image-size="50" v-else description="暂无数据"></el-empty>
        </div>
      </div>

      <!-- 榜样力量 -->
      <div class="public5box mt26">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>榜样力量</p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goMsElegList">更多></p>
          </div>
        </div>
        <div class="public1main">
          <div class="df" v-if="dataList2.length">
            <div class="df dfb cp" v-for="(item, index) in dataList2" :key="index" @click="goMsElegDetails(item.id)">
              <div class="imgBox">
                <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
              </div>
              <div class="txtbox">
                <h4 class="ellipsis-1 mb6">{{ item.title }}</h4>
                <p class="ellipsis-3">{{ setText(item.content) }}</p>
              </div>
            </div>
          </div>
          <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
        </div>
      </div>

      <!-- 最美教师 -->
      <div class="public5box mt26">
        <!-- 大标题 -->
        <div class="titleBox">
          <div class="titleLeft">
            <p>最美教师</p>
          </div>
          <div class="titleRight">
            <p class="cp" @click="goMsElegList">更多></p>
          </div>
        </div>
        <div class="public5main" v-if="dataList3.length">
          <div class="df">
            <div class="cp" v-for="(item, index) in dataList3" :key="index" @click="goMsElegDetails(item.id)">
              <div class="imgBox">
                <img class="imgbig" v-if="item.thumb" :src="item.thumb" alt="">
                <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="">
              </div>
              <div class="txtBox">
                <h4 class="ellipsis-1 mb6">{{ item.title }}</h4>
                <p class="ellipsis-2 mt10">{{ setText(item.content) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="baise borderr8" v-else>
          <el-empty :image-size="50" description="暂无数据"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      dataList1: [],
      dataList2: [],
      dataList3: [],
    };
  },
  created() {
    // 1四名风采
    this.getList1()
    // 2榜样力量
    this.getList2()
    // 3最美教师
    this.getList3()
  },
  methods: {
    // 1四名风采
    getList1() {
      let param = {
        show_type: 2,
        type: 1,
        limit: 7,
      }
      msAllApi.MienTypeList(param).then(res => {
        this.dataList1 = res.data.data.data
      })
    },

    // 2榜样力量
    getList2() {
      let param = {
        show_type: 2,
        type: 3,
        limit: 9,
      }
      msAllApi.MienTypeList(param).then(res => {
        this.dataList2 = res.data.data.data
      })
    },

    // 3最美教师
    getList3() {
      let param = {
        show_type: 2,
        type: 2,
        limit: 10,
      }
      msAllApi.MienTypeList(param).then(res => {
        this.dataList3 = res.data.data.data
      })
    },
    goMsElegList() {
      this.$router.push({
        name: "MsElegList",
      })
    },

    // 四名风采详情
    goMsElegDetails(id) {
      this.$router.push({
        name: "MsElegDetails",
        query: { id: id }
      })
    },
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: left;
}

.bannerSty {
  position: relative;
  overflow: hidden;

  .banTxtBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 298px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 666;
    box-sizing: border-box;

    .textList {
      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 152, 34, 1);
        margin-top: 6px;
        margin-bottom: 7px;
      }

      >div {
        flex-wrap: wrap;

        >p {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          cursor: pointer;

          &:hover {
            color: rgba(255, 152, 34, 1);
          }
        }
      }
    }

    .bantitle {
      width: 112px;
      height: 32px;
      background: rgba(255, 152, 34, 1);
      font-size: 14px;
      line-height: 32px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      border: none;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .item_img_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

// 内容样式开始
.public6box {
  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }

  .public6main {

    .main6Left {
      width: 400px;
      height: 342px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;

      .imgBox {
        width: 384px;
        height: 216px;
        border-radius: 8px 8px 0 0;
        position: relative;

        img {
          border-radius: 8px 8px 0 0;
        }
      }

      .txtBox {
        padding: 18px 16px;
        box-sizing: border-box;
        width: 384px;
        height: 110px;
        border-radius: 0 0 8px 8px;
        background: rgba(87, 168, 239, 1);

        p {
          color: #ffffff;

          &:first-child {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
          }

          &:last-child {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: normal;
          }
        }
      }
    }

    .main6Right {
      width: 863px;
      height: 342px;
      flex-wrap: wrap;

      >div {
        width: 277px;
        height: 163px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        padding: 8px;
        box-sizing: border-box;
        margin-right: 16px;

        &:hover {
          .imgBox {
            .aboutBox {
              display: none;
            }

            .bigBgBox {
              display: block;
            }
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        .imgBox {
          width: 100%;
          height: 100%;
          position: relative;
          border-radius: 8px;

          img {
            border-radius: 8px;
          }

          .aboutBox {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 261px;
            height: 32px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 100%);
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 0 0 8px 8px;

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #ffffff;
              line-height: 32px;
            }
          }

          .bigBgBox {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(88, 184, 122, 0.92);
            border-radius: 8px;
            padding: 24px 16px;
            box-sizing: border-box;

            h4 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
              color: #ffffff;
            }
          }
        }

      }
    }
  }
}

.public5box {

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .titleLeft {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222427;
      }
    }

    .titleRight {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #667280;
        line-height: 29px;
      }
    }
  }

  .public1main {
    height: 380px;
    background: #fff;
    border-radius: 8px;
    padding: 24px 16px 0 16px;
    box-sizing: border-box;

    >div {
      flex-wrap: wrap;

      >div {
        width: 396px;
        height: 95px;
        border-radius: 8px;
        margin-right: 30px;
        box-sizing: border-box;
        margin-bottom: 23px;

        &:hover {
          .txtbox {
            h4 {
              color: #3489FF;
            }
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        .imgBox {
          width: 168px;
          height: 95px;
          border-radius: 8px;

          img {
            border-radius: 8px;
          }
        }

        .txtbox {
          width: 210px;
          padding-right: 20px;
          padding: 5px 0;

          h4 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #222427;
          }

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: normal;
            color: #666666;
          }
        }
      }
    }
  }

  .public5main {
    width: 100%;

    >div {
      flex-wrap: wrap;

      >div {
        width: 243px;
        height: 246px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        margin-right: 16px;
        margin-bottom: 16px;

        &:hover {
          box-shadow: 0 6px 14px 0 rgba(199, 208, 226, 0.68);
        }

        &:nth-child(5n) {
          margin-right: 0;
        }

        .imgBox {
          width: 100%;
          padding: 12px 12px 8px 12px;
          box-sizing: border-box;

          img {
            height: 133px;
            border-radius: 8px;
          }
        }

        .txtBox {
          padding: 5px 16px;
          box-sizing: border-box;
          width: 100%;

          p {
            color: #666666;

            &:first-child {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
            }

            &:last-child {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
</style>