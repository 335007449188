export default {
  props: {

  },
  data() {
    return {
      countdown: 10 //倒计时
    };
  },
  created() {
    sessionStorage.removeItem("problem_basic_info_data"); //删除基本信息
    sessionStorage.removeItem("problem_subject_data"); //删除题目数据
    sessionStorage.removeItem("problem_subject_submit_description"); //删除提交成功后的文案
    setInterval(() => {
      this.countdown--;
      if (this.countdown == 0) {
        window.close();
      }
    }, 1000);
  },
  mounted() {
  },
  methods: {
    //继续创建按钮
    createButton() {
      this.$emit('create', true)
    },
    //关闭页面
    closeButton() {
      window.close();
    }
  },
  watch: {

  }
};