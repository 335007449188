<template>
  <div class="groupdetailmember">
    <div class="content_one">
      <div>
        <span @click="getintoshenhe(1)" :class="{ active: pageType == 1 }"
          >教研组成员</span
        >
        <span
          @click="getintoshenhe(2)"
          :class="{ active: pageType == 2 }"
          v-if="leader_id == user_id"
          >审核加入成员</span
        >
        <!-- <el-button size="mini" type="info" plain @click="getintoshenhe" v-if="leader_id == user_id">
          审核加入成员( {{memberNum}} )<i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i>
        </el-button> -->
      </div>
      <div>
        <el-button
          type="primary"
          size="mini"
          @click="documentDownload()"
          v-if="pageType == 1 && isCreator"
        >
          导出
        </el-button>
      </div>
    </div>
    <template v-if="pageType == 1">
      <div class="content_two">
        <CommonEmpty v-if="!memeberData.length" hVal="100%"></CommonEmpty>
        <template v-if="false">
          <div class="two_block" v-for="(item, index) in memeberData" :key="index">
            <div class="block_left">
              <img :src="item.photo_url" />
            </div>
            <div class="block_right">
              <div class="block_right_left">
                <p>
                  <span>{{ item.username }}</span
                  ><span>{{ item.org_name }}</span>
                  <!-- <span>asda</span> -->
                  <el-tag
                    effect="dark"
                    size="medium"
                    v-if="item.role && item.role == '组长'"
                    >组长</el-tag
                  >
                </p>
                <p>{{ item.stage_name }}{{ item.subject_name }}</p>
              </div>
              <div class="block_right_right" style="margin-right: 10px">
                <el-popover width="230" trigger="click">
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入..."
                    v-model="item.message"
                  >
                  </el-input>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="send(item)"
                      >发送</el-button
                    >
                  </div>
                  <el-button
                    slot="reference"
                    size="mini"
                    type="primary"
                    plain
                    v-if="user_id != item.user_id"
                    >私信</el-button
                  >
                </el-popover>

                <el-button
                  size="mini"
                  type="info"
                  plain
                  style="margin-left: 10px"
                  @click="delMember(item)"
                  v-if="leader_id == user_id && user_id != item.user_id"
                  >移除</el-button
                >
              </div>
            </div>
          </div>
        </template>

        <template v-for="(item, index) in memeberData">
          <div :key="index" class="content_item_div">
            <div class="content_item_img">
              <img :src="item.photo_url" />
              <div v-if="item.role && item.role == '组长'" class="zhuangDiv">组长</div>
            </div>
            <div class="content_data">
              <div class="content_name">
                {{ item.username }}
                <el-popover width="230" trigger="click">
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入..."
                    v-model="item.message"
                  >
                  </el-input>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="send(item)"
                      >发送</el-button
                    >
                  </div>
                  <span slot="reference" v-if="user_id != item.user_id" class="sixin_div"
                    >私信</span
                  >
                </el-popover>
                <span
                  v-if="leader_id == user_id && user_id != item.user_id"
                  class="sixin_div"
                  @click="delMember(item)"
                  >移除</span
                >
              </div>
              <div class="content_subject">
                {{ item.stage_name }}{{ item.subject_name
                }}<span class="school_name"> | {{ item.org_name || "-" }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="total" class="pagin">
        <el-pagination
          background
          :current-page="page"
          :page-size="limit"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </template>
    <template v-if="pageType == 2">
      <GroupDetailExamine></GroupDetailExamine>
    </template>
  </div>
</template>

<script>
import GroupDetailExamine from "@/views/teachingGroup/GroupDetailExamine.vue";
export default {
  data() {
    return {
      pageType: 1,
      //私信框显示与否
      // privateVisble: false,
      // 当前教研组id
      group_id: "",
      // 每页条数
      limit: 10,
      // 当前页数
      page: 1,
      // 教研组成员数据
      memeberData: [],
      // 总数据数目
      total: 0,
      // 组长id
      leader_id: "",
      // 当前用户id
      user_id: "",
      isCreator: false,
    };
  },
  components: {
    GroupDetailExamine,
  },
  created() {
    this.init();
  },
  computed: {
    // memberNum() {
    //   return this.memeberData.length;
    // }
  },
  methods: {
    // 初始化函数
    init() {
      if (this.$route.query.pageType) {
        this.pageType = this.$route.query.pageType;
      }
      this.group_id = this.$route.query.groupId;
      this.user_id = window.localStorage.getItem("id");
      this.getmember();
      this.getDetail();
    },
    // 请求后台获取教研组成员数据
    getmember() {
      var obj = {
        params: {
          limit: this.limit,
          page: this.page,
          group_id: this.group_id,
        },
      };
      this.$axios.get("TeacherGroupUser/get_group_user", obj).then((res) => {
        var resdata = res.data;
        this.total = resdata.data.total;
        this.memeberData = resdata.data.data;
      });
    },
    //   切换当前页码时触发
    handleCurrentChange(val) {
      this.page = val;
      this.getmember();
    },
    // 请求后台发送私信
    send(item) {
      var obj = {
        receive_user_id: item.user_id,
        content: item.message,
      };
      this.$axios.post("private/sendMessage", obj).then((res) => {
        this.$message.success("发送成功");
        item.message = "";
      });
    },
    // 请求后台删除成员
    delMember(item) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let obj = {
          params: {
            group_id: this.group_id,
            user_id: item.user_id,
          },
        };
        this.$axios.delete("TeacherGroupUser/del_group_user", obj).then((res) => {
          this.$message.success("删除成功");
          this.init();
        });
      });
    },
    // 跳转进入审核页面
    getintoshenhe(num) {
      this.pageType = num;
      return;
      this.$router.push({
        name: "groupdetailexamine",
        query: {
          groupId: this.group_id,
        },
      });
    },
    // 请求后台获取教研组详情
    getDetail() {
      this.$axios.get(`group/${this.group_id}`).then((res) => {
        this.leader_id = res.data.data.user_id;
        if (localStorage.getItem("id") == this.leader_id) {
          this.isCreator = true;
        }
      });
    },
    documentDownload() {
      this.$axios
        .get("TeacherGroupUser/export_group_user", {
          params: {
            group_id: this.group_id,
            limit: 9999,
          },
        })
        .then((res) => {
          if (res.data.code == 900) {
            window.open(res.data.data.data.url);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.sixin_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 58px;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(52, 137, 255, 1);
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  color: rgba(52, 137, 255, 1);
  cursor: pointer;
}
.groupdetailmember {
  background: #ffffff;
  border-radius: 6px;
  padding: 40px 31px 60px 30px;
  .content_one {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    color: #333333;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: normal;
      color: rgba(102, 102, 102, 1);
      margin-right: 30px;
      cursor: pointer;
      &.active {
        font-weight: bold;
        color: #3489ff;
      }
    }
  }
  .content_two {
    display: flex;
    flex-wrap: wrap;
    .content_item_div {
      width: 320px;
      height: 100px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(235, 235, 235, 1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding-left: 18px;
      box-sizing: border-box;
      margin-right: 20px;
      margin-bottom: 20px;
      .content_item_img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
        overflow: hidden;
        img {
          width: 68px;
          height: 68px;
          border-radius: 50%;
        }
        .zhuangDiv {
          width: 68px;
          height: 68px;
          background: rgba(255, 130, 1, 1);
          border-radius: 50%;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          position: absolute;
          bottom: -42px;
        }
      }
      .content_data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content_name {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          color: rgba(255, 130, 1, 1);
        }

        .content_subject {
          margin-top: 10px;
          font-size: 14px;
          line-height: 14px;
          color: rgba(51, 51, 51, 1);
        }
        .school_name {
          color: #999999;
        }
      }
    }
    .two_block {
      display: flex;
      margin-bottom: 36px;
      .block_left {
        margin-right: 15px;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 68px;
          height: 68px;
        }
      }
      .block_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 897px;
        padding-bottom: 31px;
        border-bottom: 1px dashed#ECECEC;
        margin-top: 10px;
        .block_right_left {
          p:nth-child(1) {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            span:nth-child(1) {
              font-weight: 700;
              color: #ff8201;
              margin-right: 16px;
            }
            span:nth-child(2) {
              margin-right: 30px;
            }
          }
          p:nth-child(2) {
            font-size: 14px;
            color: #9a9ea5;
          }
        }
        .block_right_right {
        }
      }
    }
  }
}
</style>
