<template>
  <div class="CreateTest">

    <!-- 正文 -->
    <div class="TestBox">

      <div class="TestItem">
        <!-- 面包屑 -->
        <div class="TestNav">
          <p style="font-size:14px">
            <span style="color:#666666">当前位置：个人中心>我的试题库></span>
            <span style="color:#FF8201">创建试题</span>
          </p>
        </div>
        <div class="TestMiddle">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <!-- 第一行 -->

            <h4 style="margin-bottom:30px;margin-top:20px">试题属性</h4>
            <div style="display:flex">

              <el-form-item label="学段" prop="region" style="margin-left:-50px">
                <el-select v-model="ruleForm.region" placeholder="请选择学段">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年级" prop="region">
                <el-select v-model="ruleForm.region" placeholder="请选择年级">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="region">
                <span style="margin-right:10px">学科</span>
                <el-select v-model="ruleForm.region" placeholder="请选择学科">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>

            </div>
            <!-- 第一行end -->
            <!-- 第er行 -->


            <div style="display:flex">

              <el-form-item label="章节" prop="region" style="margin-left:-50px">
                <el-select v-model="ruleForm.region" placeholder="请选择章节">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="知识点" prop="region">
                <el-select v-model="ruleForm.region" placeholder="请选择知识点">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="region">
                <span style="margin-right:10px">题型</span>
                <el-select v-model="ruleForm.region" placeholder="请选择题型">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>

            </div>
            <!-- 第er行end -->
            <!-- 第san 行 -->
            <div style="display:flex">

              <el-form-item label="难度" prop="region" style="margin-left:-50px">
                <el-select v-model="ruleForm.region" placeholder="请选择难度">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="年份" prop="region">
                <el-select v-model="ruleForm.region" placeholder="请选择年份">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="region">
                <span style="margin-right:10px">地区</span>
                <el-select v-model="ruleForm.region" placeholder="请选择地区">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>

            </div>
            <!-- 第san行end -->
            <h4 style="margin-bottom:30px;margin-top:20px">试题编辑</h4>
            <div prop="desc" style="margin-bottom:20px">
              <div
                style="width:80px;height:30px;border:1px solid #3489FE;background-color:#3489FE;line-height:30px;text-align:center;color:#FFFFFF">
                <span>题干</span>
              </div>
              <el-input type="textarea" v-model="ruleForm.desc" style="width:700px;" rows=5></el-input>
            </div>
            <div>
              <!-- 表格 -->
              <div
                style="width:80px;height:30px;border:1px solid #00B5A3;background-color:#00B5A3;line-height:30px;text-align:center;color:#FFFFFF">
                <span>选项</span>
              </div>
              <el-table :data="tableData" border style="width:810px" :show-header="status">

                <el-table-column prop="item" width="90px">

                </el-table-column>

                <el-table-column prop="info" width="719px">
                </el-table-column>



              </el-table>
            </div>
            <!-- 表格end -->
            <!-- 答案 -->
            <div
              style="width:80px;height:30px;border:1px solid #FF8201;background-color:#FF8201;margin-top:20px;line-height:30px;text-align:center;color:#FFFFFF">
              <span>答案</span>
            </div>
            <div class="dh">
              <el-checkbox v-model="checked">A</el-checkbox>
              <el-checkbox v-model="checked">B</el-checkbox>
              <el-checkbox v-model="checked">C</el-checkbox>
              <el-checkbox v-model="checked">D</el-checkbox>
            </div>
            <!-- end   -->
            <!-- 解析 -->
            <div
              style="width:80px;height:30px;border:1px solid #FF8201;background-color:#FF8201;margin-top:20px;line-height:30px;text-align:center;color:#FFFFFF">
              <span>解析</span>
            </div>
            <el-input type="textarea" rows=5></el-input>
            <!-- end -->
            <div class="zt">
              <p>
                <span>最近编辑时间：2020-8-10 16:03:50</span>
                <span>编辑者账号：454646546</span>
                <span>编辑者用户名：李晓明</span>
              </p>
              <div class="zt-item">
                <span>试题状态：</span>
                <el-switch v-model="value2" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </div>
            </div>
            <div class="btn">
              <el-button type="primary" style="margin-right:150px">取消</el-button>
              <el-button>提交</el-button>
            </div>
          </el-form>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      //   开关变量
      value1: true,
      value2: true,
      //选项
      checked: false,
      status: false,
      // 活动下拉框
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }],
      //表格数组
      tableData: [{
        item: "A",
        info: "无作用力"

      },
      {
        item: "B",
        info: "无作用力"

      },
      {
        item: "C",
        info: "无作用力"

      }],
      // 活动下一步变量
      active: 1,
      // form表单数据
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入教研组名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],

        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写教研组简介', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {


  }
}
</script>

<style lang="less">
.CreateTest {
  width: 100%;
  display: flex;
  justify-content: center;

  .TestBox {
    padding: 0;
    margin: 0 auto;
    margin-bottom: 50px;

    .TestItem {
      width: 1045px;

      .TestNav {
        line-height: 50px;
        height: 50px;
        border-bottom: 1px solid #EBEBEB;
      }

      .TestMiddle {

        .el-form {
          margin: 0;
          width: 945px;

          .el-radio {
            line-height: 40px;
          }
        }

        .dh {
          width: 480px;
          height: 52px;
          border: 1px solid #EBEBEB;
          line-height: 52px;

          .el-checkbox {
            margin-left: 50px;
          }
        }

        .zt {
          p {
            margin-top: 10px;
            margin-bottom: 20px;

            span {
              margin-right: 26px;
            }
          }

          .zt-item {
            margin-bottom: 20px;
          }
        }

        .btn {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>