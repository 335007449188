<template>
  <div class="public_css_module course_div personal_right_box" v-if="is_hide == 1 ? showPage : true">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">教学视频</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'"
          @click="showData(item.id)">
          <span v-if="(item.id != 3 && item.id != 6)">{{ item.name }}</span>
          <el-dropdown trigger="click" v-if="item.id == 3" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">审核中</el-dropdown-item>
              <el-dropdown-item command="4">已通过</el-dropdown-item>
              <el-dropdown-item command="5">未通过</el-dropdown-item>
              <!-- <el-dropdown-item command="6">已过期</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="hover" v-if="item.id == 6" @command="handleCommand2">
            <span class="el-dropdown-link">
              {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="">全部</el-dropdown-item>
              <el-dropdown-item command="1">未开始</el-dropdown-item>
              <el-dropdown-item command="2">进行中</el-dropdown-item>
              <el-dropdown-item command="3">已结束</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="rightLink" @click="goAdd">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>创建课程</span>
      </div>
    </div>

    <div class="item_list_3_box" v-loading="listLoading">
      <div class="listBox_div">
        <template v-for="(item, index) in List">
          <PublicTtemDiv :key="index" :currentMargin="false" :activeBor="true" :handleBtn="true" :navActive="3"
            :currentIndex="index" :item="item" @editClick_1="editClick" @delClick_1="delClick" @pushClick="pushClick"
            @toDetailPage="toDetailPage" @course_RefreshPage="getCourseList()">
          </PublicTtemDiv>
        </template>
      </div>
      <CommonEmpty v-if="!listLoading && !List.length" hVal="200px"></CommonEmpty>

      <div v-show="total" class="pagina_div">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="form.page" :page-sizes="[12, 20, 30, 40, 50]" :page-size="form.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_hide: "",
      showPage: false,
      listLoading: true, //表格Loading
      // 筛选
      selectData: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 3,
          name: "我创建的",
        },
        {
          id: 4,
          name: "我协同的",
        },
        {
          id: 2,
          name: "我订阅的",
        },
        {
          id: 5,
          name: "待我审核",
        },
        {
          id: 6,
          name: "状态",
        },
      ],
      currentNum: 1,
      total: 0,
      uid: 0,
      // 课程列表
      List: [],
      form: {
        user_id: localStorage.getItem("id"),
        my_type: 1,
        page: 1,
        limit: 12,
        audit_status: "",
        search_status: ""
      },
      yun_url: ""
    };
  },

  created() {
    this.is_hide = sessionStorage.getItem('is_hide')
    let query = this.$route.query;
    if (query.pageType) {
      this.$set(this.form, "my_type", Number(query.pageType));
      this.currentNum = Number(query.pageType);
    };
    if (query.statusType) {
      this.$set(this.form, "audit_status", Number(query.statusType));
    };
    this.uid = localStorage.getItem("id");
    if (localStorage.getItem('yun_url')) {
      this.yun_url = localStorage.getItem('yun_url')
    }
    this.getCourseList();
  },
  computed: {
    Uid() {
      return localStorage.getItem("id");
    },
  },
  methods: {
    goAdd() {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/createCourse?is_personid=1`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push({
          path: "/createCourse?is_personid=1"
        })
      }
    },
    //发布课程
    pushClick(id, item) {
      this.$axios.put(`courses/${id}/publish?is_personid=${item.is_personid}`).then(() => {
        this.$message({
          message: "发布成功",
          type: "success",
        });
        //初始化课程列表
        this.getCourseList();
      });
    },
    //下拉
    handleCommand(type) {
      this.currentNum = 3;
      this.$set(this.form, "my_type", 3);
      this.$set(this.form, "page", 1);
      this.$set(this.form, "audit_status", type);
      this.getCourseList();
    },
    handleCommand2(type) {
      this.currentNum = 6;
      this.$set(this.form, "my_type", 1);
      this.$set(this.form, "page", 1);
      this.$set(this.form, "search_status", type);
      this.getCourseList();
    },
    //筛选
    showData(id) {
      if (id != 3 && id != 6) {
        this.currentNum = id;
        this.$set(this.form, "my_type", id);
        this.$set(this.form, "page", 1);
        this.$set(this.form, "audit_status", "");
        this.getCourseList();
      }
    },
    //跳转备课页面
    toFocusPage(id) {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/nextFocusCourse?id=${id}`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push(`/nextFocusCourse?id=${id}`);
      }
    },
    // 初始化课程
    async getCourseList() {
      this.listLoading = true;
      try {
        let res = await this.$axios.get("/courses/get_my_list", {
          params: this.form,
        });
        this.List = res.data.data.data;
        this.total = res.data.data.total;
        this.$nextTick(() => {
          this.showPage = true
          this.listLoading = false;
        });
      } catch (error) {
        this.List = [];
        this.total = 0;
        this.$nextTick(() => {
          this.showPage = true
          this.listLoading = false;
        });
      }
    },
    //跳转到编辑页
    editClick(id) {
      if (sessionStorage.getItem('is_hide') == 1) {
        let hostes = location.origin
        let nowUrl = encodeURIComponent(this.setHost(hostes) + `/#/updataCourseInfo?id=${id}`)
        window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
      } else {
        this.$router.push(`/updataCourseInfo?id=${id}`);
      }
    },
    //删除课程
    async delActivity_1(id, item) {
      try {
        let res = await this.$axios.delete("/courses/delCourse", {
          params: {
            id: id,
            is_personid: item.is_personid,
          },
        });
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getCourseList();
      } catch (error) {
      }
    },
    //删除课程
    delClick(id, item) {
      this.$confirm("此操作将删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_1(id, item);
      }).catch(() => { });
    },
    //跳转到详情页
    toDetailPage(id, tid, fid, item, studioId) {
      let is_from = "";
      localStorage.setItem("studioId", studioId);
      // group_id，其他：教研组活动详情,0：四名活动详情
      if (item.is_personid == 1) {
        is_from = 1;
      } else {
        is_from = 2;
      }
      //  group_id == 0 ? 2 : 1;
      // 常规4块
      if (tid == 1 || tid == 2 || tid == 3 || tid == 0) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/goclassdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/goclassdetail?id=${id}&is_from=${is_from}`);
        }
      } else if (tid == 4 && fid == 1) {
        // 线下听评课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/listeningcommentdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(
            `/listeningcommentdetail?id=${id}&is_from=${is_from}`
          );
        }
      } else if (tid == 4 && fid == 2) {
        // 线上听评课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/listeningcommentdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(
            `/listeningcommentdetail?id=${id}&is_from=${is_from}`
          );
        }
      } else if (tid == 5) {
        //集中备课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/preparelessons?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/preparelessons?id=${id}&is_from=${is_from}`);
        }
      }
    },
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.getCourseList();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.getCourseList();
    },
  },
};
</script>

<style lang="less" scoped>
.listBox_div {
  padding-top: 18px;
  padding-left: 34px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .list_item {
    width: 356px;
  }

  /deep/ .list_item {
    width: 356px;
  }
}

.course_div {
  width: 100%;
  background-color: #fff;
}</style>