<template>
  <div class="public_main_bgc" ref="main_cont">

    <StudioIndex v-if="is_show_head == 1"></StudioIndex>

    <div class="public_main_div fileViewPage">

      <div v-if="fileNav" class="page_nav_box">{{ fileNav || "-" }}</div>

      <div class="page_main">
        <div class="page_title">{{ fileTitle || "-" }}</div>
        <div class="page_tag">
          <span>文档格式: {{ fileExt || "-" }}</span>
          <span>文件大小: {{ fileSize || "-" }}</span>
          <span>上传时间: {{ fileTime || "-" }}</span>
          <div v-if="unserInfoData && (file_info.is_teaching_studio_user_resource == 1)" class="collect_div"
            @click="handleCollectEvent">
            <img v-if="file_info.is_collect == 1" src="@/assets/images/shoucang_had.png" alt="" />
            <img v-if="file_info.is_collect != 1" src="@/assets/images/shoucang.png" alt="" />
            <span style="margin-right: 10px;">收藏</span>
          </div>
          <el-button size="mini" type="primary" @click="downdownUrl">下载</el-button>
        </div>

        <div v-if="unserInfoData" class="page_box">

          <!-- 文档 -->
          <div v-if="doc_DialogShow" class="outside_div" v-loading="iframe_loading" element-loading-text="资源加载中"
            element-loading-background="rgba(0, 0, 0, 0.5)">
            <iframe :src="viewSrc" width="100%" height="100%" seamless frameborder="0" @load="loadFrameEvent"
              @error="loadFrameEvent"></iframe>
          </div>

          <!-- 图片 -->
          <el-image v-if="image_DialogShow" class="outside_div" :src="viewSrc" fit="contain" @load="loadFrameEvent"
            @error="loadFrameEvent" v-loading="iframe_loading" element-loading-text="资源加载中"
            element-loading-background="rgba(0, 0, 0, 0.5)">>
          </el-image>

          <!-- 视频 -->
          <div v-if="video_DialogShow" class="outside_div">
            <Ckplayer :videoSrc="viewSrc" :videoLoop="true" :videoAutoplay="false"></Ckplayer>
            <!-- <video :src="viewSrc" type="video/mp4" poster="false.png" autoplay controls loop
              style="width: 100%; height: 100%; border: 1px solid #ebeef5;">
            </video> -->
          </div>

          <audio v-if="Audio_DialogShow" controls="controls" controlsList="nodownload" oncontextmenu="return false"
            class="outside_yinpin">
            <source :src="viewSrc" type="audio/mp3" />
          </audio>

        </div>

        <div v-if="!unserInfoData" class="page_box page_login_div">
          <CommonEmpty hVal="200px" TextVal="请先登录"></CommonEmpty>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import elementResizeDetectorMaker from "element-resize-detector"
import { fileSizeFilter } from "@/utils/utils.js";
import { localGet, localSet, localRemove } from "@/utils/utils.js";
import { isHasStudioIdFn } from "@/utils/utils.js";
import * as researchApi from "@/api/researchApi.js";
export default {
  // name: "",
  data() {
    return {
      downUrl: "",
      unserInfoData: null,

      // 音频
      Audio_DialogShow: false,
      // 视频
      video_DialogShow: false,
      // 图片
      image_DialogShow: false,
      // 文档
      doc_DialogShow: false,
      iframe_loading: false,

      fileNav: "",
      viewSrc: "",
      fileExt: "",
      fileSize: "",
      fileTitle: "",
      fileTime: "",
      resourceId: "",
      teaching_studio_user_resource_id: "",
      is_show_head: null,
      file_info: {},
    }
  },
  computed: {},

  components: {},

  methods: {
    //下载附件
    downdownUrl() {
      window.open(this.downUrl);
    },
    // 收藏按钮
    handleCollectEvent() {
      let req_obj = {};
      req_obj['id'] = this.file_info.teaching_studio_user_resource_id;
      req_obj['teaching_studio_user_resource_id'] = this.teaching_studio_user_resource_id;
      researchApi.getUser_activitylink_resource_post_collect_resource(req_obj).then(res => {
        this.$message.success("操作成功");
        this.getResourceInfo();
      })
    },
    // 资源详情
    getResourceInfo() {
      if (!this.resourceId) {
        return false;
      };
      let req_obj = {};
      req_obj['id'] = this.resourceId;
      req_obj['teaching_studio_user_resource_id'] = this.teaching_studio_user_resource_id;
      req_obj['is_check_collect'] = 1;
      researchApi.getUser_tencent_resource_info_show(req_obj).then(res => {
        this.file_info = res.data.data;
      })
    },
    // 关闭预览弹框
    closeDialogEvent() {
      this.Audio_DialogShow = false;
      this.video_DialogShow = false;
      this.image_DialogShow = false;
      this.doc_DialogShow = false;
      this.iframe_loading = false;
    },
    // iframe加载完成
    loadFrameEvent() {
      this.iframe_loading = false;
    },
    // 展示
    handler_open() {
      if (!this.viewSrc) {
        this.$message({
          message: 'view_url 预览链接为空，无法预览',
          type: 'error'
        });
        return false;
      };
      let ext = this.fileExt;
      if (this.isTypeImage(ext)) {
        this.image_DialogShow = true;
        this.iframe_loading = true;
      } else if (this.isTypeDoc(ext)) {
        this.doc_DialogShow = true;
        this.iframe_loading = true;
      } else if (this.isTypeVideo(ext)) {
        this.video_DialogShow = true;
      } else if (this.isTypeAudio(ext)) {
        this.Audio_DialogShow = true;
      } else {
        this.$message({
          type: 'info',
          message: `暂不支持查看该类型文件，请下载后观看`
        })
      }
    },
    //判断是否图片
    isTypeImage(ext) {
      return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
    },
    // 判断是否是文档
    isTypeDoc(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].includes(ext.toLowerCase());
    },
    // 判断是否是视频
    isTypeVideo(ext) {
      return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
    },
    // 是否 音频
    isTypeAudio(ext) {
      return ["mp3", "wav", "wma", "flac", "midi", "ra", "ape", "aac", "cda"].includes(ext.toLowerCase());
    },
  },

  destroyed() {
  },

  mounted() {
    this.erd = elementResizeDetectorMaker();
    this.$nextTick(() => {
      this.erd.listenTo(this.$refs.main_cont, element => {
        if (sessionStorage.getItem("is_hide") == 1) {
          window.parent.postMessage(
            {
              type: "hashH",
              data: element.offsetHeight,
            },
            "*"
          );
        }
      });
    });
    let query = this.$route.query;
    console.log(query);
    if (query && query.is_show_head) {
      if (isHasStudioIdFn(this)) {
        this.is_show_head = query.is_show_head;
      }
    };
    if (query && query.downUrl) {
      this.downUrl = query.downUrl;
    };
    if (query && query.fileTitle) {
      this.fileTitle = query.fileTitle;
    };
    if (query && query.fileExt) {
      this.fileExt = query.fileExt;
    };
    if (query && query.fileSize) {
      let file_Size = query.fileSize;
      if (/^\d+$/.test(file_Size)) {
        file_Size = fileSizeFilter(file_Size);
      };
      this.fileSize = file_Size;
    };
    if (query && query.fileTime) {
      this.fileTime = query.fileTime;
    };
    if (query && query.fileNav) {
      this.fileNav = query.fileNav;
    };
    if (query && query.viewSrc) {
      this.viewSrc = decodeURIComponent(query.viewSrc);
    };
    if (query && query.resourceId) {
      this.resourceId = query.resourceId;
    };
    if (query && query.teaching_studio_user_resource_id) {
      this.teaching_studio_user_resource_id = query.teaching_studio_user_resource_id;
    };
    let unserInfoData = localGet("unserInfoData");
    if (unserInfoData && unserInfoData.id && unserInfoData.token) {
      this.unserInfoData = unserInfoData;
      this.handler_open();
      this.getResourceInfo();
    } else {
      this.unserInfoData = null;
    };
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
.fileViewPage {
  padding-top: 20px;
  padding-bottom: 20px;
}

.page_nav_box {
  font-size: 14px;
  font-weight: normal;
  color: rgba(102, 102, 102, 1);
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.page_main {
  width: 100%;
  background-color: #fff;
  background: rgba(255, 255, 255, 1);
  padding: 30px;
  box-sizing: border-box;
  padding-top: 26px;
}

.page_title {
  font-size: 18px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
}

.page_tag {
  margin-top: 8px;
  font-size: 16px;
  font-weight: normal;
  color: rgba(102, 102, 102, 1);
  display: flex;
  align-items: center;

  >span {
    margin-right: 40px;
  }
}

.collect_div {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  >img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  >span {
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    margin-left: 8px;
  }
}

.page_box {
  margin-top: 16px;
  width: 100%;
  height: 900px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
}

.outside_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outside_yinpin {
  margin-top: 30px;
  width: 100%;
}

.page_login_div {
  display: flex;
  justify-content: center;
  color: #333;
  font-size: 26px;
  padding-top: 30px;
}
</style>