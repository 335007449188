<template>
  <div class="personInfo">
    <!-- 个人信息-->
    <div class="wz">
      <h4>更换手机</h4>
    </div>
    <!-- 内容-->
    <div class="main">
      <!-- 主体 -->
      <div class="item" v-if="num == 0">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">

          <el-form-item label="原手机号码" prop="name" style="margin-top:50px">
            <span style="font-size:18px">{{ unserInfoData.phone }}</span>
          </el-form-item>

          <el-form-item label="验证码" prop="name" style="margin-left:-28px;margin-top:50px">
            <el-input v-model="ruleForm.name" style="width:200px;margin-right:20px"></el-input>
            <el-button type="primary" plain>获取验证码</el-button>
          </el-form-item>
          <el-form-item style="margin-top:50px">
            <el-button type="primary" @click="to_next">下一步</el-button>
          </el-form-item>
          <div style="margin-top:50px">
            <p>
              <span>常见问题：</span><br><br>
              <span>
                1.无法获取短信验证码怎么办？
              </span><br>
              <span style="color:#9A9EA5"><br>
                短信到达需要1~2分钟，若您长时间未收到确认短信，建议你重发验证码。
              </span><br><br>
            </p>
            <p>
              <span>
                2.手机卡号遗失，无法使用手机接收短信验证码怎么办？
              </span><br><br>
              <span style="color:#9A9EA5">
                你可以通过客服申请来取消原有手机的认证消息，然后重新进行认证。
              </span>
            </p>
          </div>
        </el-form>


      </div>
      <!-- end -->
      <!-- 主体 -->
      <div class="item" v-if="num == 1">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">

          <el-form-item label="新手机号" prop="name" style="margin-top:50px;margin-left:-25px">
            <el-input v-model="ruleForm.name" style="width:200px;"></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="name" style="margin-left:-28px;margin-top:50px">
            <el-input v-model="ruleForm.name" style="width:200px;margin-right:20px"></el-input>
            <el-button type="primary" plain>获取验证码</el-button>
          </el-form-item>
          <el-form-item style="margin-top:50px">
            <el-button type="primary">确定</el-button>
          </el-form-item>
          <div style="margin-top:50px">
            <p>
              <span>常见问题：</span><br><br>
              <span>
                1.无法获取短信验证码怎么办？
              </span><br>
              <span style="color:#9A9EA5"><br>
                短信到达需要1~2分钟，若您长时间未收到确认短信，建议你重发验证码。
              </span><br><br>
            </p>
            <p>
              <span>
                2.手机卡号遗失，无法使用手机接收短信验证码怎么办？
              </span><br><br>
              <span style="color:#9A9EA5">
                你可以通过客服申请来取消原有手机的认证消息，然后重新进行认证。
              </span>
            </p>
          </div>
        </el-form>


      </div>
      <!-- end -->
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    to_next() {
      this.num = 1
    }
  },
  data() {
    return {
      unserInfoData: {},
      //   切换下一步
      num: 0,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
    }
  },
  created() {
    this.unserInfoData = JSON.parse(localStorage.getItem("unserInfoData"))
  },

}
</script>

<style lang="less" scoped>
.personInfo {
  .wz {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: bold;
      color: #3489FE;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .btn {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  //主要内容部分
  .main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

  }
}
</style>