<template>
  <div class="groupdetaillive">
    <div class="content_one">
      <div class="one_left">教学视频</div>
      <div class="one_right" v-if="(group_status != 3 && group_status != 4)">
        <!-- （<span>3</span>） -->
        <p class="btn2" v-if="openFlat == 1" @click="GroupAuditTearm()">
          审核组员创建的
        </p>
        <i class="el-icon-plus"></i>
        <p @click="CreateCourseData()">创建研修视频</p>
      </div>
    </div>

    <div class="content_two">
      <CommonEmpty v-if="!ArryList.length" hVal="100%"></CommonEmpty>
      <template v-for="(item, index) in ArryList">
        <PublicTtemDiv :key="index" :activeBor="true" :handleBtn="true" :navActive="3" :isGroupModule="1" :currentIndex="index" :item="item"
          @editClick_1="editClick_1" @delClick_1="delClick_1" @pushClick="pushClick" @toDetailPage="toDetailPrepare" @course_RefreshPage="getList()"
          @toPageEvent_7="onGoDetail">
        </PublicTtemDiv>
      </template>
    </div>

    <div v-if="total" class="pagin">
      <el-pagination background :current-page="ListData.page" :page-size="ListData.limit" layout="prev, pager, next, jumper" :total="total"
        @current-change="currentChange"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      openFlat: 0,
      // 缓存组的状态
      group_status: 0,
      uid: 0,
      ListData: {
        limit: 12,
        page: 1,
        search_group_id: "",
      },
      ArryList: [],
    };
  },
  created() {
    this.openFlat = localStorage.getItem("AuditFlat");
    this.group_status = localStorage.getItem("group_status");
    this.uid = localStorage.getItem("id");
    this.init();
  },
  methods: {
    //跳转到详情页 ,tid为类型id,1是线下，2是线上
    toDetailPage(id, tid, type) {
      // 常规4块  tid == 0
      if (type != 5 && type != 4) {
        this.$router.push(`/goclassdetail?id=${id}`);
      } else if (type == 4 && tid == 1) {
        // 线下听评课
        this.$router.push(`/listeningcommentdetail?id=${id}`);
      } else if (type == 4 && tid == 2) {
        // 线上听评课
        this.$router.push(`/listeningcommentdetail?id=${id}`);
      } else if (type == 5) {
        this.$router.push(`/preparelessons?id=${id}`);
      }
    },
    // 去详情
    onGoDetail(item) {
      this.toDetailPage(item.id, item.teaching_form, item.type);
    },
    //发布课程
    pushClick(id, item) {
      this.$axios.put(`courses/${id}/publish?is_personid=${item.is_personid}`).then(() => {
        this.$message({
          message: "发布成功",
          type: "success",
        });
        //初始化课程列表
        this.getList();
      });
    },
    //删除课程
    async delActivity_1(id, item) {
      try {
        let res = await this.$axios.delete("/courses/delCourse", {
          params: {
            id: id,
            is_personid: item.is_personid,
          },
        });
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList();
      } catch (error) {
      }
    },
    //删除课程
    delClick_1(id, item) {
      this.$confirm("此操作将删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delActivity_1(id, item);
      }).catch(() => { });
    },
    //当前页码改变
    currentChange(val) {
      this.live.page = val;
    },
    //审核
    GroupAuditTearm() {
      this.$router.push({
        path: "/GroupTearmAudit",
        query: {
          groupId: this.$route.query.groupId,
          type: "3",
          navCurrentType: 'course',
        },
      });
    },
    //跳转到编辑页
    editClick_1(id) {
      let gid = this.$route.query.groupId;
      this.$router.push(`/updataCourseInfo?id=${id}&groupId=${gid}`);
    },
    //备课
    toDetailPrepare(id, tid, fid, item, studioId) {
      let is_from = "";
      localStorage.setItem("studioId", studioId);
      // group_id，其他：教研组活动详情,0：四名活动详情
      if (item.is_personid == 1) {
        is_from = 1;
      } else {
        is_from = 2;
      }
      //  group_id == 0 ? 2 : 1;
      // 常规4块
      if (tid == 1 || tid == 2 || tid == 3 || tid == 0) {
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/goclassdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/goclassdetail?id=${id}&is_from=${is_from}`);
        }
      } else if (tid == 4 && fid == 1) {
        // 线下听评课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/listeningcommentdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(
            `/listeningcommentdetail?id=${id}&is_from=${is_from}`
          );
        }
      } else if (tid == 4 && fid == 2) {
        // 线上听评课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/listeningcommentdetail?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(
            `/listeningcommentdetail?id=${id}&is_from=${is_from}`
          );
        }
      } else if (tid == 5) {
        //集中备课
        if (sessionStorage.getItem('is_hide') == 1) {
          let hostes = location.origin
          let nowUrl = encodeURIComponent(this.setHost(hostes) + `/preparelessons?id=${id}&is_from=${is_from}`)
          window.open(`${this.yun_url}#/home/pF?url_1=${nowUrl}`)
        } else {
          this.$router.push(`/preparelessons?id=${id}&is_from=${is_from}`);
        }
      }
    },
    // 跳转创建研修视频
    CreateCourseData() {
      this.$router.push({
        path: "/groupCreteCourse",
        query: {
          groupId: this.$route.query.groupId,
          is_personid: 1,
        },
      });
    },
    // 初始化数据
    init() {
      this.getList();
    },
    // 得到教研活动列表
    async getList() {
      // 从路由中得到id
      let gid = this.$route.query.groupId;
      this.ListData.search_group_id = gid;
      // 
      let res = await this.$axios.get("courses/get_group_list", {
        params: {
          limit: this.ListData.limit,
          page: this.ListData.page,
          search_group_id: this.ListData.search_group_id,
        },
      });
      this.ArryList = res.data.data.data;
      this.total = res.data.data.total;
      console.log(res, "数据");
    },
  },
};
</script>

<style lang="less" scoped>
.groupdetaillive {
  //   border: 1px solid pink;
  background: #ffffff;
  border-radius: 6px;
  padding-bottom: 40px;
  margin-bottom: 60px;
  padding-left: 30px;
  overflow: hidden;
  //   padding-right: 30px;
  .content_one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;
    padding-right: 23px;
    .one_left {
      font-weight: 700;
    }
    .one_right {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #3489fe;
      i {
        margin-right: 10px;
      }
      //审核行
      .btn2 {
        font-size: 16px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #3489fe;
        margin-right: 30px;
        span {
          color: #ff0607;
          font-weight: bold;
        }
      }
    }
  }

  .content_three {
    margin-bottom: 30px;
    display: flex;
    .three_one {
      width: 90px;
      height: 32px;
      background: rgba(52, 137, 254, 0.1);
      border-radius: 16px;
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active {
      background: #3489fe;
      color: #fffefe;
    }
  }
}
.content_two {
  display: flex;
  flex-wrap: wrap;
}
.activity_block {
  width: 248px;
  // height: 259px;
  // height: 323px;
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  // 编辑按钮样式
  .list-dropdown {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    .list-dropdown-icon {
      font-size: 26px;
      color: #ff8201;
    }
  }
  .img_itemlist {
    height: 140px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    img {
      height: 140px;
    }
    .writer {
      width: 100%;
      height: 32px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 14px;
        color: #fffffe;
      }
    }
  }
  .word1 {
    margin-left: 30px;
    margin-top: 23px;
    margin-bottom: 15px;
    font-weight: 700;
    // width: 254px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .word2 {
    margin-left: 30px;
    margin-bottom: 20px;
    color: #666666;
    font-size: 14px;
    span:nth-child(1) {
      margin-right: 30px;
    }
  }
  .word_other1 {
    margin-left: 30px;
    margin-bottom: 15px;
    span:nth-child(1) {
      margin-right: 35px;
      font-size: 14px;
      color: #3489fe;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #3489fe;
      margin-right: 35px;
    }
    span:nth-child(3) {
      font-size: 14px;
      color: #3489fe;
    }
  }
  .word_other2 {
    margin-left: 30px;
    margin-bottom: 15px;
    display: flex;
    .member {
      font-size: 14px;
    }
    .hand {
      width: 50px;
      height: 20px;
      background: #ff8201;
      border-radius: 10px;
      font-size: 12px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }
}
</style>