<template>
  <!-- 备课 -->
  <div class="NextListingOnline">
    <!-- 头部 -->
    <div class="nextBox">
      <!-- 左边图片 -->
      <div class="CourseOff_left">
        <img :src="form.cover_url" alt="" />
      </div>
      <!-- 右边 -->
      <div class="TopRight">
        <!-- 标题 -->
        <div class="titleTop">
          <h3>
            {{ form.name }}
          </h3>
        </div>
        <!-- 便签 -->
        <div class="bq">
          <div>
            课程分类：<span>{{ form.course_type }}</span>
          </div>
          <div>授课形式：<span>线上</span></div>
          <div>
            学段学科：<span>{{ form.stage_name }} &nbsp;&nbsp; {{ form.subject_name }}</span>
          </div>
          <div>
            年级：<span>{{ form.grade_name }}</span>
          </div>
        </div>
        <!-- 最后一行 -->
        <div class="bq_last">
          <div></div>
          <p>
            适用章节：<span style="margin-right: 20px">{{
              form.chapter_name
            }}</span>
            授课老师：<span style="margin-right: 20px">{{ form.teacher }}</span>
            教材版本：<span>{{ form.edition_name }}</span>
          </p>
          <p style="margin-top: 20px">
            评课时间：<span>{{ form.lesson_start_date }} &nbsp;&nbsp;
              {{ form.lesson_start_time }}&nbsp; - &nbsp;
              {{ form.lesson_end_date }} &nbsp;&nbsp; {{ form.lesson_end_time }}
            </span>
          </p>
        </div>
        <el-button type="primary" @click="PushOffLine(form.id)">发布课程</el-button>
        <el-button type="success" @click="UpdataCourseOff(form.id, form.group_id)">修改课程</el-button>
        <el-button type="info" @click="deleteCourse(form.id, form.group_id)">删除课程</el-button>
      </div>
    </div>
    <!-- 内容 -->
    <div class="dbBox">
      <div class="boxContent">
        <div class="Box_top">
          <h3>授课说明</h3>
          <div class="topContent">
            <p class="rich_text_div_img" v-html="form.presentation"></p>
          </div>
        </div>

        <div class="detailBox">
          <h3>授课视频</h3>
          <h3>授课资源</h3>
        </div>
        <div class="Box_bottom">
          <!-- 左边视频 -->
          <div class="forResource_left">
            <video width="100%" height="100%" :src="mp4Url" controls>
              <source type="video/mp4" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <div class="forResource">
            <!-- 循环部分 -->
            <div class="ResourceItem" v-for="(item, index) in form.class_resource" :key="index">
              <!-- 左边图片 -->
              <div class="Re_left">
                <img src="../../../../assets/images/word.png" alt="" />
              </div>
              <!-- 右边内容 -->
              <div class="Re_right">
                <p>{{ item.resource_name }}</p>
                <div class="Re_text">
                  <div>
                    上传时间：<span>{{ item.create_time }}</span>
                  </div>
                  <div>
                    <span style="color: #3489fe; margin-right: 10px">查看</span>
                    <span><a :href="item.resource_url">下载 </a></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LuBoDetail, SendCourse } from "@/api/teacherStudio";
export default {
  data() {
    return {
      form: "",
      //视频路径
      mp4Url: "",
      // 1教研组模块，2四名工作室模块
      is_personid: 1,
    };
  },
  created() {
    if (this.$route.query.is_personid) {
      this.is_personid = this.$route.query.is_personid
    };
    this.init();
  },
  methods: {
    async init() {
      //从路由得到id
      let cid = this.$route.query.id;
      this.is_personid = this.$route.query.is_personid || 1;
      if (this.is_personid == 1) {
        let res = await this.$axios.get(`/course/get_info/${cid}?is_personid=${this.is_personid}`);
        this.form = res.data.data;
        //得到视频id
        this.mp4Url = this.form.video_resource.resource_url;
      } else {
        LuBoDetail({ id: cid, is_personid: this.is_personid }).then((res) => {
          this.form = res.data;
          this.mp4Url = res.data.video_resource.resource_url;
        });
      }
    },
    //跳转到修改线下听评课
    UpdataCourseOff(c_id, c_groupId) {
      this.$router.push({
        path: '/createCourse',
        query: {
          id: c_id,
          is_personid: this.form.is_personid,
          groupId: this.form.group_id,
        },
      });
    },
    // 发布线上听评课课程，跳到详情页
    async PushOffLine(id) {
      this.$confirm("是否发布课程，发布后不可修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //发送删除请求
          if (this.is_personid == 1) {
            p = this.$axios.put(`courses/${id}/publish`).then((res) => {
              this.$message({
                type: "success",
                message: "发布成功!",
              });
              setTimeout(() => {
                this.$router.push(`/listeningcommentdetail?id=${id}`);
              }, 1000);
            });
          } else {
            p = SendCourse(id).then((res) => {
              this.$message({
                type: "success",
                message: "发布成功!",
              });
              setTimeout(() => {
                this.$router.push(`/RecordManage`);
              }, 1000);
            });
          }
        })
        .catch(() => {
          //   this.$message({
          //     type: "info",
          //     message: "已取消发布",
          //   });
        });
    },

    //删除
    deleteCourse(id) {
      this.$confirm("删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //发送删除请求
          let p = null;
          if (this.is_personid == 1) {
            p = this.$axios.delete("courses/delCourse", {
              params: {
                id: id,
              },
            });
          } else {
            p = this.$axios.delete(
              "index/TeachingStudioCourse/deleteTeachingStudioCourse",
              {
                params: {
                  id: id,
                },
              }
            );
          }
          p.then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less">
//num=2
.NextListingOnline {
  width: 100%;
  //头部
  .nextBox {
    width: 80%;
    height: 335px;
    margin-left: 10%;
    display: flex;
    margin-bottom: 10px;
    // 左边图片
    .CourseOff_left {
      width: 50%;
      height: 293px;
      margin-right: 60px;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 293px;
      }
    }
    //头部右边
    .TopRight {
      font-size: 18px;
      width: 75%;
      margin: auto 0;
      .titleTop {
        height: 30px;
        display: flex;
        margin-bottom: 10px;
        h3 {
          line-height: 28px;
          margin-right: 15px;
        }
        div {
          margin-right: 5px;
        }
      }
      //  便签
      .bq {
        font-size: 18px;
        display: flex;
        height: 30px;
        margin-bottom: 10px;
        div {
          margin-right: 20px;
          span {
            color: #999999;
          }
        }
      }
      //最后一行
      .bq_last {
        font-size: 18px;
        height: 100px;
        span {
          color: #999999;
        }
      }
    }
    //  按钮
    #wzt {
      font-size: 14px;
      color: red;
    }
  }
  .dbBox {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f7f7f7;
    .boxContent {
      .detailBox {
        h3 {
          margin-bottom: 10px;
        }
        margin-top: 20px;
        width: 78%;
        display: flex;
        justify-content: space-between;
      }
      width: 80%;
      margin-left: 10%;
      padding-bottom: 50px;
      h4 {
        color: #999999;
        margin-bottom: 20px;
      }

      .Box_top {
        h3 {
          margin-bottom: 10px;
        }
        .topContent {
          margin-top: 30px;
          box-sizing: border-box;
          margin: 0;
          padding: 15px 15px;
          background: #ffffff;
          width: 100%;
        }
      }
      .Box_bottom {
        display: flex;
        justify-content: space-between;
        //    左边视频
        .forResource_left {
          width: 70%;
          height: 659px;
          box-sizing: border-box;
          margin: 0;
          border: 1px solid #cccc;
        }
        h4 {
          margin-top: 20px;
        }
        .forResource {
          height: 659px;
          box-sizing: border-box;
          margin: 0;
          padding: 15px 15px;
          background: #ffffff;
          width: 27%;
          //循环
          .ResourceItem {
            display: flex;
            .Re_left {
              img {
                width: 60px;
                height: 50px;
              }
            }
            .Re_right {
              margin: auto 0;
              color: #999999;
              p {
                margin-left: 20px;
              }
              .Re_text {
                display: flex;
                margin-left: 20px;
                div {
                  font-size: 14px;
                  span:nth-of-type(1) {
                    margin-left: 10px;
                  }
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>