<template>
  <div class="public_main_bgc">
    <div class="GroupCreateLive">
      <div class="newCourseNav">
        <p>
          当前位置：个人中心 > 我的直播 >
          <span style="color: #ff8201">创建直播</span>
        </p>
      </div>
      <CreateLive></CreateLive>
    </div>
  </div>
</template>

<script>
import CreateLive from "../../views/teachingGroup/GroupCreate/createLive.vue";
export default {
  components: {
    CreateLive,
  },
  data() {
    return {};
  },
  created() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.GroupCreateLive {
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 20px;

  .newCourseNav {
    line-height: 48px;
    height: 48px;
    font-size: 14px;
  }
}
</style>