<template>
  <div id="app">
    <router-view />
    <!-- <iframe id="iframe" :src="ucUrl + '?type='" hidden></iframe> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ucUrl: "https://uc.dc-it.cn",
    }
  },
  created() {
    let hostName = location.host
    // 河源东源
    if (hostName.indexOf("hydyjy.teachercloud.com.cn") != -1) {
      localStorage.setItem("yun_url", 'https://hydy.teachercloud.com.cn/')
      // 阳江
    } else if (hostName.indexOf("jy-yj.teachercloud.com.cn") != -1) {
      localStorage.setItem("yun_url", 'https://yj.teachercloud.com.cn/')
    } else {
      localStorage.setItem("yun_url", 'https://tpdp.dc-it.cn/')
    }
    if (!localStorage.getItem("ucAppList")) {
      this._getAppList()
    }
  },
  methods: {
    _getAppList() {
      let hostName = location.host
      let domes = 'https://uc.dc-it.cn'
      if (hostName.indexOf("teachercloud.com.cn") != -1) {
        domes = "https://dcuc.teachercloud.com.cn"
      }
      $.get(`${domes}/app`, {
      }, function (res) {
        console.log(res.data);
        localStorage.setItem("ucAppList", JSON.stringify(res.data))
      })
    }
  }
}
</script>
<style>

</style>
