<template>
  <div class="BoxItems public_main_bgc">
    <div class="noticelist">
      <!-- 面包屑 -->
      <div class="navList">
        <p>
          当前位置:
          <span @click="toList()">首页</span>
          ><span v-if="type == 'notice'">通知公告</span><span v-if="type == 'news'">教研资讯</span>
        </p>
      </div>
      <div class="w_d center" style="margin: 0 auto">
        <div class="left">
          <div class="one">
            <span class="boxaa">资讯中心</span>
          </div>
          <div class="mt10" :class="type == 'notice' ? 'activeNew' : 'one'">
            <!-- 通知公告 -->
            <div class="word cursor">
              <i class="el-icon-bell"></i>
              <span @click="noticeButton()">通知公告</span>
            </div>
          </div>
          <div class="mt10" :class="type == 'news' ? 'activeNew' : 'one'">
            <!-- 教研咨询 -->
            <div class="word cursor">
              <i class="el-icon-document"></i>
              <span @click="newsButton()">教研资讯</span>
            </div>
          </div>
        </div>
        <!-- 显示部分 -->
        <div class="right" v-if="type == 'notice'">
          <CommonEmpty v-if="!getList.length" hVal="100%"></CommonEmpty>
          <div class="word" v-for="(item, index) in getList" :key="index">
            <p class="one cursor" @click="goOther(item.id)">{{ item.title }}</p>
            <div class="two">
              <i class="el-icon-view"></i>
              <span>{{ item.view_count }}人浏览</span>
            </div>
            <div class="two">
              <i class="el-icon-time"></i>
              <span>{{ item.create_time }}</span>
            </div>
          </div>
          <div class="pagin">
            <el-pagination background :current-page="form.page" :page-size="form.limit" layout="prev, pager, next, jumper" :total="form.total"
              @current-change="currentChangeNotice"></el-pagination>
          </div>
        </div>
        <!-- 显示部分end -->
        <!-- 显示部分1 -->
        <div class="right" v-if="type == 'news'">
          <CommonEmpty v-if="!getInforList.length" hVal="100%"></CommonEmpty>
          <div class="word" v-for="(item, index) in getInforList" :key="index">
            <p class="one cursor" @click="goOther(item.id)">{{ item.title }}</p>
            <div class="two">
              <i class="el-icon-view"></i>
              <span>{{ item.view_count }}人浏览</span>
            </div>
            <div class="two">
              <i class="el-icon-time"></i>
              <span>{{ item.create_time }}</span>
            </div>
          </div>
          <div class="pagin">
            <el-pagination background :current-page="list.page" :page-size="list.limit" layout="prev, pager, next, jumper" :total="list.total"
              @current-change="currentChangeInfor"></el-pagination>
          </div>
        </div>
        <!-- 显示部分end -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      form: {
        total: 0,
        limit: 10,
        page: 1,
      },
      list: {
        total: 0,
        limit: 10,
        page: 1,
      },
      // 信息列表
      getList: "",
      // 咨询列表
      getInforList: "",
    };
  },
  created() {
    this.type = this.$route.query.type ? this.$route.query.type : "";
    if (this.$route.query.type == "notice") {
      //通知列表
      this.getMoreNotice();
    }
    if (this.$route.query.type == "news") {
      //资讯列表
      this.getMoreInfor();
    }
  },
  methods: {
    //路由
    toList() {
      this.$router.push("/");
    },
    //通知列表
    async getMoreNotice() {
      let res = await this.$axios.get("/moreNotices", {
        params: this.form,
      });
      const data = res.data.data;
      this.getList = data.data;
      this.form.total = data.total;
    },
    //资讯列表
    async getMoreInfor() {
      let res = await this.$axios.get("/moreInformations", {
        params: this.list,
      });
      const data = res.data.data;
      this.getInforList = data.data;
      this.list.total = data.total;
    },
    //通知当前页码改变
    currentChangeNotice(val) {
      this.form.page = val;
      this.getMoreNotice();
    },
    //资讯当前页码改变
    currentChangeInfor(val) {
      this.list.page = val;
      this.getMoreInfor();
    },
    //通知按钮
    noticeButton() {
      this.$router.push({
        path: "/noticelist",
        query: { type: "notice" },
      });
    },
    //资讯按钮
    newsButton() {
      this.$router.push({
        path: "/noticelist",
        query: { type: "news" },
      });
    },
    async goOther(id) {
      this.$router.push({
        path: "/NoticesDetail",
        query: {
          type: this.type,
          id: id,
        },
      });
    },
  },
  watch: {
    $route(to, from) {
      this.type = to.query.type ? to.query.type : "";
      if (this.type == "notice") {
        this.form.page = 1;
        this.getMoreNotice();
      }
      if (this.type == "news") {
        this.list.page = 1;
        this.getMoreInfor();
      }
    },
  },
};
</script>

<style scoped lang="less">
.BoxItems {
  width: 100%;
  // background-color: #f7f7f7;
  .noticelist {
    width: 80%;
    margin: 0 auto;
    // display: flex;
    // justify-content: center;
    .navList {
      width: 1400px;
      font-size: 14px;
      height: 48px;
      line-height: 48px;
      margin: 0 auto;
      p {
        color: rgba(153, 153, 153, 1);
        span:last-child {
          color: #ff8201;
        }
        span:first-of-type {
          cursor: pointer;
          &:hover {
            color: #3489fe;
          }
        }
      }
    }
    .center {
      // border: 1px solid black;
      margin-top: 30px;
      margin-bottom: 60px;
      display: flex;
      // justify-content: space-between;

      .left {
        width: 268px;
        height: 180px;
        background: #ffffff;
        margin-right: 20px;
        border-radius: 6px;
        // 导航样式

        .one {
          height: 50px;
          background: #ffffff;
          // border-bottom: 1px solid #ebebeb;
          display: flex;
          align-items: center;
          .word {
            margin-left: 29px;
            span {
              margin-left: 13px;
            }
          }
          .word:hover {
            color: #3489fe;
          }
        }
        .activeNew {
          height: 50px;
          display: flex;
          align-items: center;
          color: #3489fe;
          margin-top: 10px;
          padding: 0 10px;
          .word {
            padding-left: 20px;
            width: 100%;
            height: 100%;
            line-height: 50px;
            background: rgba(52, 137, 255, 0.2);
            border-radius: 6px;
            span {
              margin-left: 13px;
            }
          }
          // &::before {
          //   content: "";
          //   width: 3px;
          //   height: 50px;
          //   background: rgba(52, 137, 255, 0.8);
          // }
        }
        .one:nth-child(1) {
          background: #3489fe;
          display: flex;
          justify-content: center;
          border-radius: 6px 6px 0 0;
          .boxaa {
            font-size: 16px;
            color: #ffffff;
          }
        }
        .one:nth-child(2) {
        }
      }
      .right {
        margin-bottom: 15px;
        width: 1112px;
        min-height: 600px;
        background: #ffffff;
        border-radius: 6px;
        padding: 30px 30px 60px;
        .word {
          box-sizing: border-box;
          height: 56px;
          border-bottom: 1px dashed #ececec;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .one {
            width: 560px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .one:hover {
            color: #3489fe;
          }
          .two {
            color: #8590a6;
            span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>