export default {
  props: {
    //题目
    subject: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
  filters: {
    //题型过滤
    subjectType(type, single) {
      if (type == 1) {
        if (single == 1) {
          return "单选题";
        } else {
          return "多选题";
        }
      }
      if (type == 2) {
        return "填空题";
      }
      if (type == 3) {
        return "排序题";
      }
      if (type == 4) {
        return "量表题";
      }
    },
  },
};
