<template>
  <div class="public_css_module item_list_2_box">
    <template v-for="(item, index) in list">
      <div class="item_2_item cp" :class="{ active: (index + 1) % currentActive == 0 }" :key="index"
        @click="toDetailPage(item.id)">
        <div class="liBox"
          :class="item.provinces_cities_counties == 1 ? 'gzsheng' : item.provinces_cities_counties == 2 ? 'gzshi' : item.provinces_cities_counties == 3 ? 'gzqu' : 'other'">
          <div class="item_2_user_box">
            <div class="item_2_img">
              <img :src="item.photo_url" alt="" />
              <!-- <div v-if="item.my_type_text" class="tios_2_box_div"> {{item.my_type_text || ""}} </div> -->
              <div v-if="item.my_type_text == '我创建的'" class="tios_div_box_1_div">我创建的 </div>
              <div v-if="item.my_type_text == '我参与的'" class="tios_div_box_1_div active">我参与的 </div>
            </div>
            <div class="item_2_msg fx1">
              <div class="title_2_div ellipsis_1">
                <span class="ellipsis-1">{{ item.teaching_studio_name || "-" }}</span>
                <span class="ellipsis-1">{{ item.stage_name }}{{ item.subject_name }}</span>
              </div>
              <div class="name_2_tab_box">
                <div class="name_box fxaic">
                  <div class="name_name fwb">{{ item.host_type_str }}</div>
                </div>
                <div class="name_box fxaic">
                  <div class="name_name">
                    {{ item.org_name }}
                  </div>
                  <el-dropdown pdown trigger="hover" v-if="(item.is_go_examine_role == 1) || (item.is_my_create == 1)">
                    <span class="name_xiala_1_tag" @click.stop="">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="item.is_go_examine_role == 1" divided
                        @click.native="studio_to_page(item, 1)">
                        <i class="el-icon-position"></i>去审核
                      </el-dropdown-item>
                      <el-dropdown-item v-if="item.is_my_create == 1" divided @click.native="onManager(item.id)">
                        <i class="el-icon-edit-outline"></i>管理
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="item_2_resource_box fxaic">
            <div class="item_2_resource_item active">
              成员: {{ item.user_count || "0" }}人
            </div>
            <div class="item_2_resource_item active">
              <!-- 资源总量: {{ item.live_course_article_count || "0" }} -->
              资源总量: {{ item.live_course_resource_article_count || "0" }}
            </div>
            <div class="item_2_resource_item">
              访问: {{ item.view_count || "0" }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    // 1我的四名工作室，2个人中心，3工作室全部列表
    is_who: {
      type: Number,
      default: 1,
    },
    currentActive: {
      type: [Number, String],
      default: "3",
    },
  },
  data() {
    return {
      user_id: localStorage.getItem("id"),
    };
  },
  methods: {
    studio_to_page(item, num) {
      this.onManager(item.id)
    },
    //跳转到详情
    toDetailPage(id) {
      // localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/TeachLectureIndex",
        query: {
          id: id,
          teaching_studio_id: id,
        },
      });
    },
    //跳转到编辑页
    onManager(id) {
      localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/AdminHome",
        query: {
          teaching_studio_id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item_list_2_box {
  display: flex;
  flex-wrap: wrap;

  .item_2_item {
    width: 32%;
    height: 165px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    // background-color: #f1f6f9;
    // border: 1px solid #f1f6f9;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .liBox {
      &.gzsheng {
        background-image: url("~@/assets/banner_img/gzsheng.png");
        background-size: 100% 100%;
      }

      &.gzshi {
        background-image: url("~@/assets/banner_img/gzshi.png");
        background-size: 100% 100%;
      }

      &.gzqu {
        background-image: url("~@/assets/banner_img/gzqu.png");
        background-size: 100% 100%;
      }

      &.other {
        background: #EBF1FF;

        .item_2_resource_box {
          background: #E2E5EB;
        }
      }

      &:hover {
        box-shadow: 0 2px 20px 0 rgba(89, 142, 174, 0.31);
      }

      &.active {
        margin-right: 0;
      }

      // &:nth-child(3n) {
      //   margin-right: 0;
      // }
      // &:nth-child(1) {
      //   margin-top: 10px;
      // }
      // &:nth-child(2) {
      //   margin-top: 10px;
      // }
      // &:nth-child(3) {
      //   margin-top: 10px;
      // }
      .item_2_user_box {
        display: flex;
        align-items: center;
        padding: 22px;
        padding-right: 10px;
        box-sizing: border-box;
      }

      .item_2_img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 16px;
        position: relative;
        overflow: hidden;

        >img {
          width: 80px;
          height: 80px;
          border-radius: 8px;
        }

        .tios_2_box_div {
          bottom: 0;
          position: absolute;
          text-align: center;
          height: 24px;
          width: 100%;
          background: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          line-height: 24px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
        }

        .tios_div_box_1_div {
          padding: 0 !important;
          margin: 0 !important;
          position: absolute;
          width: 80px;
          height: 22px;
          bottom: 0;
          left: 0;
          background-color: #3489ff;
          font-size: 14px;
          line-height: 22px;
          font-weight: normal;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          color: rgba(255, 255, 255, 1);

          &.active {
            background-color: #52b876;
          }
        }
      }

      .title_2_div {
        width: 270px;
        display: flex;

        span {
          &:first-child {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            max-width: 140px;
          }

          &:last-child {
            margin-left: 10px;
            padding: 0 5px;
            font-size: 14px;
            font-weight: normal;
            color: rgba(81, 184, 117, 1);
            border: 1px solid rgba(81, 184, 117, 1);
            border-radius: 4px;
            max-width: 70px;
          }
        }
      }

      .item_2_msg {
        height: 80px;
        display: flex;
        flex-direction: column;
      }

      .name_2_tab_box {
        flex: 1;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        position: relative;

        .name_box {
          .name_name {
            margin-top: 8px;
            width: 50%;
            font-size: 14px;
            line-height: 14px;
            color: rgba(153, 153, 153, 1);
          }
        }

        .el-dropdown {
          position: absolute;
          bottom: -10px;
          right: -10px;
        }

        .name_xiala_1_tag {
          width: 50px;
          text-align: right;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .item_2_resource_box {
        width: 100%;
        height: 40px;

        .item_2_resource_item {
          width: 50%;
          font-size: 16px;
          line-height: 40px;
          font-weight: normal;
          color: rgba(17, 26, 56, 1);
          text-align: center;

          &.active {
            border-right: 1px solid #fff;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>