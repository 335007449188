export default {
  props: {
    //弹窗显示
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogShow: false,//是否显示弹窗
      //问题信息参数
      problemInfo: {
        title: "",
        description: ""
      },
      //问题信息验证
      problemInfoRules: {
        title: [
          { required: true, message: "请输入问卷标题", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        description: [
          { required: true, message: "请输入问卷说明", trigger: "blur" }
        ],
      },
      problemBasicInfoData: {}//基本信息
    };
  },
  created() {

  },
  mounted() {
  },
  methods: {
    //获取基本信息
    getBasicInfoData() {
      this.problemBasicInfoData = JSON.parse(sessionStorage.getItem("problem_basic_info_data"))
      this.problemInfo.title = this.problemBasicInfoData.title
      this.problemInfo.description = this.problemBasicInfoData.description
    },
    //弹窗关闭的回调
    dialogHandleClose() {
      this.$emit('input', false)
    },
    //取消按钮
    cancelButton() {
      this.$emit('input', false)
    },
    //确认按钮
    defineButton(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.problemBasicInfoData.title = this.problemInfo.title
          this.problemBasicInfoData.description = this.problemInfo.description
          sessionStorage.setItem("problem_basic_info_data", JSON.stringify(this.problemBasicInfoData))
          this.$emit('input', false)
        }
      })
    }
  },
  watch: {
    //监听是否显示弹窗
    value(val) {
      this.dialogShow = val
      if (this.dialogShow) {
        this.getBasicInfoData()
      }
    },
    //监听问卷说明
    "problemInfo.description"() {
      this.$nextTick(() => {
        this.$refs["problemInfo"].validateField("description")
      })
    }
  }
};