import SubjectList from "../subjectList/subjectList.vue";
export default {
  components: {
    SubjectList,
  },
  props: {},
  data() {
    return {
      //题目参数
      subject: {
        title: "",
        description: "",
        type: "",
        topics: [],
      },
    };
  },
  created() {
    this.subject.topics = JSON.parse(
      sessionStorage.getItem("problem_subject_data")
    );
    const e = JSON.parse(sessionStorage.getItem("problem_basic_info_data"));
    this.subject.title = e.title;
    this.subject.description = e.description;
    this.subject.type = e.type;
  },
  mounted() {},
  methods: {
    //返回按钮
    returnButton() {
      this.$emit("goBack", true);
    },
  },
  watch: {},
  filters: {
    //题型过滤
    subjectType(type, single) {
      if (type == 1) {
        if (single == 1) {
          return "单选题";
        } else {
          return "多选题";
        }
      }
      if (type == 2) {
        return "填空题";
      }
      if (type == 3) {
        return "排序题";
      }
      if (type == 4) {
        return "量表题";
      }
    },
  },
};
