import EditInfo from "./components/editInfo/editInfo.vue";
import ChoiceSubject from "./components/choiceSubject/choiceSubject.vue";
import WriteSubject from "./components/writeSubject/writeSubject.vue";
import SortSubject from "./components/sortSubject/sortSubject.vue";
import GaugeSubject from "./components/gaugeSubject/gaugeSubject.vue";
import SubjectList from "../subjectList/subjectList.vue";
export default {
  components: {
    EditInfo,
    ChoiceSubject,
    WriteSubject,
    SortSubject,
    GaugeSubject,
    SubjectList,
  },
  props: {},
  data() {
    return {
      //题目类型选项
      subjectTypeOption: [],
      //题目参数
      subject: {
        title: "",
        details: "",
        type: "",
        topics: [],
        submit_doc: "",
      },
      editInfoShow: false, //是否显示编辑基本信息弹窗
      choiceSubjectShow: false, //是否显示选择题弹窗
      writeSubjectShow: false, //是否显示填空题弹窗
      sortSubjectShow: false, //是否显示排序题弹窗
      gaugeSubjectShow: false, //是否显示量表题弹窗
      subjectData: {}, //题目数据
    };
  },
  created() {
    //问卷
    if (this.$route.query.type == 1) {
      this.subjectTypeOption = [
        {
          name: "选择题",
          value: 1,
        },
        {
          name: "填空题",
          value: 2,
        },
        {
          name: "排序题",
          value: 3,
        },
        {
          name: "量表题",
          value: 4,
        },
      ];
    }
    //投票
    if (this.$route.query.type == 2) {
      this.subjectTypeOption = [
        {
          name: "选择题",
          value: 1,
        },
      ];
    }
    this.getBasicInfoData();
    this.getSubjectData();
    this.getSubjectSubmitDescription();
  },
  mounted() {},
  methods: {
    //获取基本信息
    getBasicInfoData() {
      const e = JSON.parse(sessionStorage.getItem("problem_basic_info_data"));
      this.subject.title = e.title;
      this.subject.details = e.description;
      this.subject.type = e.type;
    },
    //获取题目
    getSubjectData() {
      this.subject.topics = sessionStorage.getItem("problem_subject_data")
        ? JSON.parse(sessionStorage.getItem("problem_subject_data"))
        : [];
    },
    //获取提交成功后的文案
    getSubjectSubmitDescription() {
      this.subject.submit_doc = sessionStorage.getItem(
        "problem_subject_submit_description"
      );
      sessionStorage.removeItem("problem_subject_submit_description"); //删除提交成功后的文案
    },
    //题弹窗保存回调
    onSubject(e) {
      if (e.hasOwnProperty("index")) {
        const index = e.index;
        delete e.index;
        this.subject.topics[index] = e;
      } else {
        this.subject.topics.push(e);
      }
    },
    //编辑基本信息按钮
    editInfoButton() {
      this.editInfoShow = true;
    },
    //添加题目
    addSubject(e) {
      if (e.value == 1) {
        this.choiceSubjectShow = true;
      }
      if (e.value == 2) {
        this.writeSubjectShow = true;
      }
      if (e.value == 3) {
        this.sortSubjectShow = true;
      }
      if (e.value == 4) {
        this.gaugeSubjectShow = true;
      }
    },
    //编辑选项
    editOptionButton(e, index) {
      e.index = index;
      this.subjectData = e;
      if (e.type == 1) {
        this.choiceSubjectShow = true;
      }
      if (e.type == 2) {
        this.writeSubjectShow = true;
      }
      if (e.type == 3) {
        this.sortSubjectShow = true;
      }
      if (e.type == 4) {
        this.gaugeSubjectShow = true;
      }
    },
    //复制选项
    copyOptionButton(e) {
      this.subject.topics.push(JSON.parse(JSON.stringify(e)));
    },
    //删除选项
    delOptionButton(index) {
      this.$confirm("此操作将删除该题目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.subject.topics.splice(index, 1);
        })
        .catch(() => {});
    },
    //上移选项
    upOptionButton(item, index) {
      this.subject.topics.splice(index - 1, 0, item);
      this.subject.topics.splice(index + 1, 1);
    },
    //下移选项
    downOptionButton(item, index) {
      this.subject.topics.splice(index + 2, 0, item);
      this.subject.topics.splice(index, 1);
    },
    //预览按钮
    previewButton() {
      sessionStorage.setItem(
        "problem_subject_data",
        JSON.stringify(this.subject.topics)
      );
      sessionStorage.setItem(
        "problem_subject_submit_description",
        this.subject.submit_doc
      );
      this.$emit("preview", true);
    },
    //取消按钮
    cancelButton() {
      this.$emit("goBack", true);
    },
    //提交按钮
    submitButton() {
      if (this.subject.topics.length > 0) {
        this.subject.topics = [this.subject.topics];
        //新增问卷/投票接口
        this.$axios
          .post("/questionnaire/foundQues", this.subject)
          .then((res) => {
            if (res.data.code == 900) {
              this.$emit("submitData", true);
            }
          });
      } else {
        this.$message({
          message: "暂无题目，请添加。",
          type: "warning",
        });
      }
    },
  },
  watch: {
    //监听是否显示编辑基本信息弹窗
    editInfoShow(val) {
      if (!val) {
        this.getBasicInfoData();
      }
    },
    //监听是否显示选择题弹窗
    choiceSubjectShow(val) {
      if (!val) {
        this.subjectData = {};
      }
    },
    //监听是否显示填空题弹窗
    writeSubjectShow(val) {
      if (!val) {
        this.subjectData = {};
      }
    },
    //监听是否显示排序题弹窗
    sortSubjectShow(val) {
      if (!val) {
        this.subjectData = {};
      }
    },
    //监听是否显示量表题弹窗
    gaugeSubjectShow(val) {
      if (!val) {
        this.subjectData = {};
      }
    },
  },
};
