<template>
  <div class="public_main_bgc">
    <div class="public-width pb15">
      <div class="main_box">
        <div class="box_content">
          <div class="bread" style="
            height: 49px;
            display: flex;
            align-items: center;
          ">
            <div class="bread_content" style="margin: 0 !important">
              <span class="span_one">当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/">
                  <span class="c-b">首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item to="/weChatList">
                  <span class="c-b">优质原创内容</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                  <span class="c-b">文章详情</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <div class="mainCenter df dfb">
        <div class="mainLeft">
          <div class="ulBox">
            <div class="liBox cp" v-for="(item, index) in tableData.article_list" :key="index">
              <div class="top" v-if="index == 0">
                <div class="topLeft" @click="changeActive(index)"
                  :class="active == 0 ? 'activeSty' : active == 1 ? 'active2' : ''">
                  <img v-if="item.thumb" class="imgBig" :src="item.thumb" alt="">
                  <div class="aboutTxt">
                    <p class="ellipsis-1">{{ item.title || '标题' }}</p>
                  </div>
                </div>
              </div>
              <div class="bom df" v-else>
                <div class="bomLeft fsbc" @click="changeActive(index)" :class="active == index ? 'activeSty' : ''">
                  <div class="txtBox">
                    <p class="ellipsis-2">{{ item.title || '标题' }}</p>
                  </div>
                  <div class="imgBox">
                    <img v-if="item.thumb" class="imgBig" :src="item.thumb" alt="">
                    <i v-else class="el-icon-picture-outline fz58 c999 pubborder"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="bommitSty pl10 pr10 pb10">
              <div class="df dfb pl5 pr5">
                <div class="imgBox">
                  <img :src="tableData.photo_url" alt="">
                </div>
                <div class="txtBox">
                  <p class="fz14 ellipsis-1 c333">{{ tableData.teaching_studio_name }}</p>
                  <p class="fz14 ellipsis-1 c999 mt3 mb3">{{ tableData.create_time }}</p>
                  <p class="fz14 ellipsis-1 c333">推送至{{ tableData.account_name_str }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainRight">
          <h4>{{ tableData.article_list[active].title }}</h4>
          <div class="txt2 df dfc mt20 mb20">
            <p class="mr20 c999">{{ tableData.article_list[active].author }}</p>
            <p class="ml20 c999">{{ tableData.article_list[active].create_time }}</p>
          </div>
          <div class="conton">
            <p v-html="format(tableData.article_list[active].content)"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as  msAllApi from "@/api/teacherStudio/msAllApi"
export default {
  data() {
    return {
      tableData: {
        article_list: [
          {
            edit_id: "",
            title: "",
            content: "",
            thumb: "",
            author: "",
            digest: "",
          },
        ]
      },
      active: 0,
    };
  },
  watch: { // 监听，当路由发生变化的时候执行
    $route(to, from) {
      this.getList1()
    },
  },
  created() {
    this.getList1()
  },
  methods: {
    getList1() {
      msAllApi.weChatMaterialDetail({ group_id: this.$route.query.id }).then(res => {
        this.tableData = res.data.data
        console.log(this.tableData);
      })
    },
    changeActive(val) {
      this.active = val
    },
    format(html) {
      var GRT = [
        // img 样式
        ['img', "max-width:100%;height:auto;"],
      ];
      for (let i = 0; i < GRT.length; i++) {
        html = html.replace(new RegExp('<' + GRT[i][0] + '>|<' + GRT[i][0] + ' (.*?)>', 'gi'), function (word) {
          // 分析 dom 上是否带有 style=""
          if (word.indexOf('style=') != -1) {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)style="(.*?)"(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1style="$2 ' + GRT[i][1] + '"$3$4>');
          } else {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1 style="' + GRT[i][1] + '$2">');
          }
        });
      }
      return html;
    },
  },

  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.public-width {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  .main_box {
    width: 100%;

    .box_content {
      box-sizing: border-box;
      margin: 0 auto;
      width: 1400px;

      .top {
        width: 100%;
        height: 76px;
        line-height: 76px;
        font-size: 14px;

        span:nth-of-type(2) {
          color: #ff8201;
        }
      }
    }
  }

  .mainCenter {
    border-radius: 8px;
    min-height: 800px;

    .mainLeft {
      min-height: 500px;
      box-sizing: border-box;

      .ulBox {
        background: #fff;
        border-radius: 8px;

        .liBox {

          .top {
            display: flex;

            .topLeft {
              position: relative;
              width: 330px;
              height: 184px;
              border-top: 1px solid #ccc;
              border-left: 1px solid #ccc;
              border-right: 1px solid #ccc;

              img {
                width: 100%;
                height: 184px;
              }

              .aboutTxt {
                position: absolute;
                bottom: 0;
                left: 0;
                background: linear-gradient(0deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .3));
                width: 100%;
                height: 32px;
                line-height: 32px;
                color: #fff;
                padding: 0 10px;
                box-sizing: border-box;
              }

            }

            .activeSty {
              box-shadow: 0 0 10px 1px #077be3;
            }

            .active2 {
              box-shadow: 0px 10px 10px -10px #077be3;
            }
          }

          .bom {

            .bomLeft {
              width: 330px;
              height: 80px;
              padding: 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #ccc;
              border-left: 1px solid #ccc;
              border-right: 1px solid #ccc;

              .txtBox {
                width: 240px;
              }

              .imgBox {
                width: 60px;
                height: 60px;

                img {
                  width: 100%;
                  height: 60px;
                }
              }
            }

            .activeSty {
              box-shadow: 0 0 10px 1px #077be3;
            }
          }
        }

        .bommitSty {
          width: 330px;
          border-left: 1px solid #e4e8eb;
          border-right: 1px solid #e4e8eb;
          border-bottom: 1px solid #e4e8eb;
          border-radius: 0 0 8px 8px;
          box-sizing: border-box;

          >div {
            background: rgba(238, 246, 255, 1);
            height: 80px;
            padding: 0 15px;
            align-items: center;

            .imgBox {
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
              }
            }

            .txtBox {
              width: calc(100% - 50px);
            }
          }
        }
      }
    }

    .mainRight {
      width: calc(100% - 350px);
      min-height: 500px;
      padding: 40px 20px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 8px;

      h4 {
        text-align: center;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(42, 48, 55, 1);
      }

      .txt2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: rgba(102, 102, 102, 1);
      }

      .conton {
        padding: 10px 30px;
        overflow: auto;
      }

    }
  }
}
</style>